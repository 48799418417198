import AvatarComponent from '@Common/Components/Avatar'
import Loading from '@Common/Components/Loading'
import { useRecentVisit } from '@Common/Queries/GetQueries/RecentVisits'
import AddIcon from '@mui/icons-material/Add'
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material'
import { formatDistanceToNow } from 'date-fns'
import { MutableRefObject } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { getVisitorName, displayAvatarBasedOnName } from 'src/BuyerInsightsUtil'

interface RecentVisitProps {
    action: string | undefined
    assetId: string | undefined
    trackButtonRef: MutableRefObject<undefined>
}

const RecentVisits = (props: RecentVisitProps) => {
    const recentVisits = useRecentVisit(props.assetId)
    const navigate = useNavigate()
    if (recentVisits?.isLoading) {
        return <Loading></Loading>
    }
    const getForZeroResults = (data: {
        recentVisits: Array<any>
        trackLinks: Array<any>
    }) => {
        if (data.trackLinks.length !== 0) {
            return (
                <Button>
                    <Link to={`/search/viewAsset/${props.assetId}/track`}>
                        View all links
                    </Link>
                </Button>
            )
        } else {
            return (
                <Button
                    startIcon={<AddIcon fontSize="small"></AddIcon>}
                    onClick={() => {
                        props.trackButtonRef.current.click()
                    }}
                >
                    Create a link to get activity
                </Button>
            )
        }
    }
    return (
        <>
            {!['EDIT', 'FULL_ACCESS', 'VIEW'].includes(props?.action) && (
                <div className="px-3">You do not have access to this page</div>
            )}
            <div className="h-full w-full px-3">
                {recentVisits &&
                    recentVisits?.data?.recentVisits.length == 0 ? (
                    <div className="pt-6 flex justify-center">
                        {getForZeroResults(recentVisits?.data)}
                    </div>
                ) : (
                    <>
                        <Table className="w-full">
                            <TableBody>
                                {recentVisits?.data.recentVisits.map(
                                    (visit, index) => {
                                        const buyerDetails = visit.TrackSession.AssetTrackSession?.buyerDetails;
                                        const email = visit.TrackSession.AssetTrackSession.email;
                                        const location = `${buyerDetails?.ipData?.city}, ${buyerDetails?.ipData?.country_name}`
                                        return (
                                            <TableRow
                                                sx={{
                                                    borderBottom:
                                                        'solid 1px rgba(255, 255, 255, 0.1)',
                                                }}
                                                key={index}
                                                className="overflow-hidden
                                            hover:cursor-pointer hover:bg-hoverBackground"
                                                onClick={() => {
                                                    navigate(
                                                        `/search/viewAsset/${props.assetId}/visit/${visit.id}`
                                                    )
                                                }}
                                            >
                                                <TableCell
                                                    scope="row"
                                                >
                                                    <div className="flex items-center flex-row">
                                                        {displayAvatarBasedOnName(getVisitorName(email, buyerDetails), index)}
                                                        <div className="flex flex-col overflow-hidden w-full pl-2">
                                                            <Typography
                                                                    className="text-ellipsis whitespace-nowrap overflow-hidden"
                                                                    color={'text.primary'}>
                                                                    {getVisitorName(email, buyerDetails)}
                                                            </Typography>
                                                            <div className='w-40 overflow-hidden truncate'>
                                                                <Tooltip title={visit.TrackLink
                                                                                    .TrackLinkProperties
                                                                                    .name || ''}>
                                                                    <Typography
                                                                        variant="caption"
                                                                        fontWeight={300}
                                                                        color={
                                                                            'text.disabled'
                                                                        }
                                                                        className="overflow-hidden text-ellipsis whitespace-nowrap"
                                                                    >
                                                                        {
                                                                            visit.TrackLink
                                                                                .TrackLinkProperties
                                                                                .name
                                                                        }
                                                                    </Typography>
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell className='flex flex-col'>
                                                    {location && (
                                                        <div className='flex flew-row'>
                                                            <LocationOnOutlinedIcon color='disabled'></LocationOnOutlinedIcon>
                                                            <div className='flex w-20'>
                                                                <Tooltip title={location}>
                                                                    <Typography color="text.disabled" className='truncate' >{location}</Typography>
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className='flex-1 text-right'>
                                                    <Tooltip
                                                        title={formatDistanceToNow(
                                                            new Date(
                                                                visit.visitTime
                                                            ),
                                                            {
                                                                addSuffix: true,
                                                            }
                                                        )}
                                                    >
                                                        <Typography
                                                            className="text-ellipsis whitespace-nowrap overflow-hidden"
                                                            color={'text.secondary'}
                                                        >
                                                            {formatDistanceToNow(
                                                                new Date(
                                                                    visit.visitTime
                                                                ),
                                                                {
                                                                    addSuffix: true,
                                                                }
                                                            )}
                                                        </Typography>
                                                    </Tooltip>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                )}
                            </TableBody>
                        </Table>
                        <div className="flex justify-end pt-3">
                            <Button
                                onClick={() => {
                                    navigate(
                                        `/search/viewAsset/${props.assetId}/visit`
                                    )
                                }}
                            >
                                View all visits
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </>
    )
}

export default RecentVisits
