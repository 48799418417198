import useDebounce from '@Common/hooks/useDebounce'
import useRoles from '@Common/Queries/GetQueries/GetRoles'
import useUpdateUser from '@Common/Queries/MutationQueries/UpdateUser'
import Loading from '@Common/Components/Loading'
import TablePagination from '@mui/material/TablePagination'
import {
    Autocomplete,
    Button,
    InputAdornment,
    Popover,
    TextField,
    Typography,
} from '@mui/material'
import {
    DataGridPro as DataGrid,
    GridColDef,
    GridRenderEditCellParams,
} from '@mui/x-data-grid-pro'
import { useImmer } from 'use-immer'
import useUsers from './useUsers'
import Search from '@mui/icons-material/Search'
import { Person, PersonAddAlt1 } from '@mui/icons-material'
import { useRef } from 'react'
import { emailRegex } from 'src/Track/Components/TrackProperties'
import useAddUsers from '@Common/Queries/MutationQueries/AddUsers'
import useRemoveUser from './useRemoveUser'
import Modal from '@Common/Components/Modals/Modal'
import useInit from '@Common/Queries/GetQueries/useInit'
import toast from 'react-hot-toast'
interface TableData {
    name: string
    role: string
    email: string
    id: string
}
export default function Members() {
    const [filter, setFilter] = useImmer({
        searchTerm: '',
        currentPage: 0,
        pageSize: 10,
    })
    const [members, setMembers] = useImmer({
        showAddMembers: false,
        emails: [] as string[],
        removeUserWarning: false,
        removeUser: '',
        showAddError: '',
        role: '',
    })
    const removeUser = useRemoveUser({
        onSuccess: () => {
            setMembers((draft) => {
                draft.removeUserWarning = false
            })
        },
    })

    const debouncedFilter = useDebounce(filter)
    const users = useUsers(debouncedFilter)
    const anchorEl = useRef()
    const roles = useRoles()
    const pageSize = 10
    const inviteUsers = useAddUsers({
        onSuccess: () => {
            setMembers((draft) => {
                draft.showAddMembers = false
            })
        },
    })

    const init = useInit()
    const userRoles = init.data.user.UserRole.map((userRole) =>
        userRole.role.role.toLowerCase()
    )
    const isAdmin =
        userRoles.includes(import.meta.env.VITE_SUPER_ADMIN_USER) ||
        userRoles.includes(import.meta.env.VITE_ADMIN_USER)

    const addUserRole = useRef()
    if (!users.data || !roles.data) {
        return <Loading />
    }
    return (
        <div className="w-full h-full p-6">
            <div className="flex justify-between py-4">
                <div className="flex gap-2 w-64">
                    <TextField
                        size="small"
                        className="w-48"
                        onChange={(event) => {
                            setFilter((draft) => {
                                draft.searchTerm = event.target.value
                            })
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    />
                    {users.isFetching && (
                        <div>
                            <Loading size="1.5rem"></Loading>
                        </div>
                    )}
                </div>
                {isAdmin && (
                    <Button
                        onClick={() => {
                            setMembers((draft) => {
                                draft.showAddMembers = true
                            })
                        }}
                        ref={anchorEl}
                        variant="contained"
                    >
                        <PersonAddAlt1 className="mr-2" /> Invite Members
                    </Button>
                )}
            </div>
            <MembersTable
                users={users.data.users}
                filter={debouncedFilter}
                roles={roles.data.options}
                onUserDelete={(userId: string) => {
                    setMembers((draft) => {
                        draft.removeUserWarning = true
                        draft.removeUser = userId
                    })
                }}
            />
            <TablePagination
                rowsPerPageOptions={[pageSize]}
                colSpan={3}
                component="div"
                className="text-xs text-lightTextDisabled border-none overflow-hidden"
                count={users.data.count}
                rowsPerPage={filter.pageSize}
                page={filter.currentPage}
                onPageChange={(e, page) =>
                    setFilter({ ...filter, currentPage: page })
                }
                backIconButtonProps={{
                    className: 'stroke-slate-50',
                }}
                nextIconButtonProps={{
                    className: 'stroke-slate-50',
                }}
            />

            <Popover
                open={members.showAddMembers}
                anchorEl={anchorEl.current}
                onClose={() => {
                    setMembers((draft) => {
                        draft.showAddMembers = false
                    })
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div className="w-[450px] flex flex-col gap-2 h-auto p-4">
                    <Typography>Please press enter to add an email</Typography>
                    <Typography color="error">
                        {members.showAddError}
                    </Typography>
                    <div className="flex gap-2">
                        <Autocomplete
                            renderInput={(params) => (
                                <TextField {...params} label="Emails" />
                            )}
                            className="w-full"
                            value={members.emails}
                            onChange={(event, value, reason) => {
                                if (reason === 'createOption') {
                                    if (
                                        emailRegex.test(value[value.length - 1])
                                    ) {
                                        setMembers((draft) => {
                                            draft.emails = value
                                        })
                                    } else {
                                        toast.error(
                                            'Please enter a valid email'
                                        )
                                    }
                                }
                                if (reason === 'removeOption') {
                                    setMembers((draft) => {
                                        draft.emails = value
                                    })
                                }
                            }}
                            options={[]}
                            multiple={true}
                            freeSolo={true}
                        ></Autocomplete>
                        <Autocomplete
                            className="w-56"
                            options={roles.data.options}
                            getOptionLabel={(option) => {
                                return option.label
                            }}
                            onChange={(event, value) => {
                                setMembers((draft) => {
                                    draft.role =
                                        value != null ? value.value : value
                                })
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label="Roles" />
                            )}
                        ></Autocomplete>
                    </div>
                    <Button
                        variant="contained"
                        onClick={() => {
                            if (!members.role) {
                                setMembers((draft) => {
                                    draft.showAddError = 'Please select a role'
                                })
                                return
                            }
                            setMembers((draft) => {
                                draft.showAddError = ''
                            })
                            inviteUsers.mutate({
                                emails: members.emails,
                                role: members.role,
                            })
                        }}
                        disabled={inviteUsers.isLoading}
                    >
                        {inviteUsers.isLoading && (
                            <div className="mr-2">
                                <Loading size="1.5rem" />
                            </div>
                        )}
                        Add
                    </Button>
                </div>
            </Popover>
            <Modal
                isOpen={members.removeUserWarning}
                className="w-96 h-auto"
                onClose={() => {
                    setMembers((draft) => {
                        draft.removeUserWarning = false
                    })
                }}
                body={
                    <div>
                        {' '}
                        <p className="mb-4">
                            This action cannot be undone. This will permanently
                            delete all your assets and files associated with it
                        </p>
                        <div className="flex flex-col gap-2">
                            <Button
                                color="error"
                                onClick={() => {
                                    removeUser.mutate(members.removeUser)
                                }}
                                variant="outlined"
                                disabled={removeUser.isLoading}
                            >
                                {removeUser.isLoading && (
                                    <div className="mr-2">
                                        <Loading size="1.5rem" />
                                    </div>
                                )}
                                Remove user
                            </Button>

                            <Button
                                onClick={() => {
                                    setMembers((draft) => {
                                        draft.removeUserWarning = false
                                    })
                                }}
                                variant="outlined"
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                }
            />
        </div>
    )
}

function CustomEditComponent(
    props: GridRenderEditCellParams<TableData> & {
        roles: any
        filter: any
        onUserDelete: (userId: string) => void
    }
) {
    const updateUser = useUpdateUser()
    const init = useInit()
    const userRoles = init.data.user.UserRole.map((userRole) =>
        userRole.role.role.toLowerCase()
    )
    const isAdmin =
        userRoles.includes(import.meta.env.VITE_SUPER_ADMIN_USER) ||
        userRoles.includes(import.meta.env.VITE_ADMIN_USER)

    return (
        <Autocomplete
            sx={{
                width: '150px',
            }}
            isOptionEqualToValue={(option, value) => {
                return option.label === value
            }}
            onChange={(event, value) => {
                if (value.value === 'remove') {
                    props.onUserDelete(props.row.id)
                    return
                }
                updateUser.mutate({
                    id: props.row.id,
                    body: {
                        role: value.value,
                    },
                    role: value?.name,
                    filter: props.filter,
                })
                console.log('Row: ', JSON.stringify(props.row))
            }}
            renderOption={(props, option) => {
                const { className, ...rest } = props

                return (
                    <li
                        className={`${className} ${
                            option.value === 'remove' ? 'text-red-500' : ''
                        }  `}
                        {...rest}
                    >
                        {option.label}
                    </li>
                )
            }}
            defaultValue={props.value}
            renderInput={(params) => <TextField {...params} label="Roles" />}
            options={props.roles.concat([
                {
                    label: 'Remove',
                    value: 'remove',
                },
            ])}
        />
    )
}
function MembersTable({
    users,
    roles,
    filter,
    onUserDelete,
}: {
    users: any[]
    roles: any
    filter: any
    onUserDelete: (userId: string) => void
}) {
    const init = useInit()
    const userRoles = init.data.user.UserRole.map((userRole) =>
        userRole.role.role.toLowerCase()
    )
    const isAdmin =
        userRoles.includes(import.meta.env.VITE_SUPER_ADMIN_USER) ||
        userRoles.includes(import.meta.env.VITE_ADMIN_USER)

    const data: TableData[] = users.map(({ name, email, id, UserRole }) => ({
        name,
        id,
        email,
        role: UserRole[0].role.role,
    }))
    const columns: GridColDef[] = [
        {
            headerName: 'Name',
            field: 'name',
            minWidth: 400,
        },
        {
            headerName: 'Email',
            field: 'email',
            minWidth: 300,
        },
        {
            headerName: 'Role',
            field: 'role',

            renderEditCell: (params: GridRenderEditCellParams) => {
                return (
                    <CustomEditComponent
                        filter={filter}
                        onUserDelete={onUserDelete}
                        {...params}
                        roles={roles}
                    />
                )
            },
            editable: isAdmin,
            width: 150,
        },
    ]
    return (
        <DataGrid
            experimentalFeatures={{ newEditingApi: true }}
            rows={data}
            columns={columns}
        />
    )
}
