import { useQuery, useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'

export default function useDeleteSitePermission(props?: any) {
    const queryClient = useQueryClient()
    return useMutation<
        any,
        Error,
        { siteId: string; sitePermissionId: string }
    >(
        (obj) =>
            axios.delete(
                `${APi_URL}/site/${obj.siteId}/permission/${obj.sitePermissionId}`,
                { headers: { 'Content-Type': 'application/json' } }
            ),
        {
            onSuccess: (data: any, obj: any) => {
                if (props && props.onSuccess) {
                    props.onSuccess(data)
                }
            },
            onMutate: (obj) => {
                queryClient.cancelQueries(['sites', obj.siteId])
                queryClient.setQueryData(['sites', obj.siteId], (oldData) => {
                    const permissionIndex = oldData.SitePermission.findIndex(
                        (permission) => {
                            return permission.id === obj.sitePermissionId
                        }
                    )
                    oldData.SitePermission.splice(permissionIndex, 1)
                    return oldData
                })
            },
            onSettled: (data, error, obj) => {
                queryClient.invalidateQueries(['sites', obj.siteId])
            },
        }
    )
}
