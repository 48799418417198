import logo from './assets/Portal.png'
import useTitle from '@Common/hooks/useTitle'
import { API_URL } from '@Common/Types/configs/urlConfig'
import { Button, Link, TextField } from '@mui/material'
import { Login as LoginRequest } from '@Server/login'
import { ResendCode } from '@Server/resendCode'

import Loading from '@Common/Components/Loading'
import axios from 'axios'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'
import {
    Link as LinkRoute,
    useNavigate,
    useSearchParams,
} from 'react-router-dom'
import { AuthTemplate } from './AuthTemplate'

export function ForgotPassword() {
    const navigate = useNavigate()
    const { handleSubmit, control, setError, formState } = useForm({})
    let [searchParams, setSearchParams] = useSearchParams()

    const mutation = useMutation(
        (login: LoginRequest) => {
            return axios.post(`${API_URL}/forgotPassword`, login)
        },
        {
            onSuccess: (result, request) => {
                toast.success(
                    'Verification code sent to your registed email address'
                )
                navigate(`/confirmForgotPassword?email=${request.username}`)
            },
            onError: (error) => {
                toast.error(JSON.stringify(error.data))
            },
        }
    )

    const onSubmit = (data) => mutation.mutate(data)
    useTitle('Forgot password - Portal')

    return (
        <AuthTemplate
            onSubmit={handleSubmit(onSubmit)}
            body={
                <>
                    <Controller
                        name="username"
                        control={control}
                        render={({ field }) => (
                            <TextField size="small" {...field} label="Email" />
                        )}
                    />

                    <Button
                        disabled={mutation.isLoading}
                        type="submit"
                        variant="contained"
                    >
                        {mutation.isLoading && (
                            <div className="mr-2">
                                <Loading size="1.5rem" />
                            </div>
                        )}
                        Reset password
                    </Button>
                    <p>
                        Back to {'  '}
                        <Link component={LinkRoute} to="/login">
                            Login
                        </Link>{' '}
                    </p>
                </>
            }
            title={
                ' Enter your Email below and we will send a message to reset your password'
            }
        />
    )
}
