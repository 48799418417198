import Loading from '@Common/Components/Loading'
import Modal from '@Common/Components/Modals/Modal'
import { Users } from '@Common/Components/Select/Users'
import {
    Add,
    AddOutlined,
    ArrowDownward,
    ArrowUpward,
    CallMadeRounded,
    DeleteForeverOutlined,
    DeleteOutlineOutlined,
    MoreVertOutlined,
    OpenInFullOutlined,
    OpenInNewOutlined,
} from '@mui/icons-material'
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControlLabel,
    FormGroup,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Popover,
    TextField,
    Typography,
} from '@mui/material'
import {
    DataGridPro as DataGrid,
    GridColDef,
    GridRowsProp,
    GridSortModel,
} from '@mui/x-data-grid-pro'
import { format } from 'date-fns/esm'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Filter, FilterOptions } from 'src/Contacts/Filter'
import { useImmer } from 'use-immer'
import { ActiveChip } from './ActiveChip'
import { useAccounts } from './useAccounts'
import { useAddAccounts } from './useAddAccounts'
import { useDeleteAccounts } from './useDeleteAccount'
import { checkDomain } from '@Common/ValidationCheck'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'

interface Account {
    name: string
    domain?: string
    owner?: string
}
export function Accounts() {
    const [account, setAccount] = useImmer({
        createAccount: false,
        newAccounts: [{}] as Account[],
    })
    const [validationError, setValidationError] = useImmer({
        errorAccount: [],
        errorDomain: [],
    })
    const sendDataToGTM = useGTMDispatch()
    const addAccount = useAddAccounts({
        onSuccess: () => {
            setAccount((draft) => {
                draft.createAccount = false
                draft.newAccounts = [
                    {
                        name: '',
                        domain: '',
                        owner: '',
                    },
                ]
            })
            sendDataToGTM({
                event: 'account_created',
            })
        },
    })
    return (
        <div className="flex-1 flex  flex-col overflow-hidden h-full">
            <div className="flex justify-between items-center p-5 w-full">
                <div>
                    <Typography variant="h5">Accounts</Typography>
                </div>
                <div className="flex h-8 gap-2">
                    <Button
                        variant="contained"
                        onClick={() => {
                            setAccount((draft) => {
                                draft.createAccount = true
                                draft.newAccounts = [{}]
                            })
                        }}
                        startIcon={<AddOutlined />}
                    >
                        Add Account
                    </Button>
                </div>
            </div>
            <Table
                addAccount={() => {
                    setAccount((draft) => {
                        draft.createAccount = true
                    })
                }}
            />
            <Modal
                className="w-[800px] h-[600px]"
                onClose={() => {
                    setAccount((draft) => {
                        draft.createAccount = false
                        draft.newAccounts = [
                            {
                                name: '',
                                domain: '',
                                owner: '',
                            },
                        ]
                    })
                    setValidationError((draft) => {
                        draft.errorAccount = []
                        draft.errorDomain = []
                    })
                }}
                isOpen={account.createAccount}
                title="Add Accounts"
                body={
                    <div className="p-4">
                        {account.newAccounts.map((newAccount, index) => {
                            return (
                                <div
                                    key={index}
                                    className="flex gap-4 justify-between mb-4"
                                >
                                    <div className="w-full">
                                        <TextField
                                            label="Account name (Required)"
                                            required
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            onChange={(e) => {
                                                setValidationError((draft) => {
                                                    draft.errorAccount[index] =
                                                        false
                                                })
                                                setAccount((draft) => {
                                                    draft.newAccounts[
                                                        index
                                                    ].name = e.target.value
                                                })
                                            }}
                                            value={newAccount.name}
                                        ></TextField>
                                        {validationError.errorAccount[
                                            index
                                        ] && (
                                            <Typography
                                                color="error"
                                                className="p-2"
                                            >
                                                Account name is required
                                            </Typography>
                                        )}
                                    </div>
                                    <div className="w-full">
                                        <TextField
                                            value={newAccount.domain}
                                            fullWidth
                                            size="small"
                                            variant="outlined"
                                            onChange={(e) => {
                                                setValidationError((draft) => {
                                                    draft.errorDomain[index] =
                                                        false
                                                })
                                                setAccount((draft) => {
                                                    draft.newAccounts[
                                                        index
                                                    ].domain = e.target.value
                                                })
                                            }}
                                            label="Domain (Required)"
                                            required
                                        ></TextField>
                                        {validationError.errorDomain[index] && (
                                            <Typography
                                                color="error"
                                                className="p-2"
                                            >
                                                Valid domain is required
                                            </Typography>
                                        )}
                                    </div>
                                    <div className="w-full">
                                        <Users
                                            value={newAccount.owner}
                                            label="Owner"
                                            fullWidth
                                            size="small"
                                            onChange={(value) => {
                                                setAccount((draft) => {
                                                    draft.newAccounts[
                                                        index
                                                    ].owner = value?.value
                                                })
                                            }}
                                        ></Users>
                                    </div>
                                </div>
                            )
                        })}
                        <div>
                            <Button
                                startIcon={<AddOutlined />}
                                onClick={() => {
                                    setAccount((draft) => {
                                        draft.newAccounts.push({} as Account)
                                    })
                                }}
                            >
                                Add more
                            </Button>
                        </div>
                    </div>
                }
                footer={
                    <>
                        <Button
                            variant="contained"
                            disabled={addAccount.isLoading}
                            onClick={() => {
                                let validationError = false
                                account.newAccounts.map((value, index) => {
                                    if (
                                        Object.keys(value).length === 0 ||
                                        !value.hasOwnProperty('name') ||
                                        value.name.length === 0
                                    ) {
                                        setValidationError((draft) => {
                                            draft.errorAccount[index] = true
                                        })
                                        validationError = true
                                    }
                                    if (
                                        Object.keys(value).length === 0 ||
                                        !value.hasOwnProperty('domain') ||
                                        value.domain.length === 0 ||
                                        !checkDomain(value.domain)
                                    ) {
                                        setValidationError((draft) => {
                                            draft.errorDomain[index] = true
                                        })
                                        validationError = true
                                    }
                                })
                                if (!validationError) {
                                    addAccount.mutate({
                                        data: account.newAccounts,
                                    })
                                }
                            }}
                        >
                            {addAccount.isLoading && (
                                <div className="mr-2">
                                    <Loading size="1.25rem" />
                                </div>
                            )}
                            Add Accounts
                        </Button>
                    </>
                }
            />
        </div>
    )
}
interface QueryOption {
    sortModel: GridSortModel
}
function Table({ addAccount }) {
    const [queryOptions, setQueryOptions] = useState<QueryOption>({
        sortModel: [],
    })
    const navigate = useNavigate()

    const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
        // Here you save the data you need from the sort model
        setQueryOptions({ sortModel: [...sortModel] })
    }, [])
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 15,
    })
    const [rowOptions, setRowOptions] = useImmer({
        targetEl: null as null | HTMLButtonElement,
        open: false,
        deleteModal: false,
        rowId: null as null | string,
    })
    const [account, setAccount] = useImmer({
        filter: false,
        filterColumns: [] as { id: string; label: string; type: string }[],
        filterOptions: {} as { [key: string]: FilterOptions },
    })
    const filterButton = useRef(null)

    const accounts = useAccounts({
        ...paginationModel,
        ...queryOptions,
        filters: Object.keys(account.filterOptions).reduce((acc, key) => {
            acc[key] = account.filterOptions[key].values
            return acc
        }, {} as Record<string, string | string[]>),
    })
    const [rowCountState, setRowCountState] = useState(
        accounts?.data?.rows?.count || 0
    )
    const [hover, setHover] = useImmer<string | null | undefined>(null)
    const deleteAccount = useDeleteAccounts({
        onSuccess: () => {
            setRowOptions((draft) => {
                draft.deleteModal = false
            })
        },
    })
    useEffect(() => {
        setRowCountState((prevRowCountState) =>
            accounts?.data?.rows?.count !== undefined
                ? accounts?.data?.rows?.count
                : prevRowCountState
        )
    }, [accounts?.data?.rows?.count, setRowCountState])

    if (accounts.isLoading) {
        return <Loading />
    }
    const columns: GridColDef[] = accounts.data.userTable.UserTableMeta.map(
        (meta: any, index: number): GridColDef => {
            return {
                field: meta.columnType === 'USER' ? `${meta.id}_data` : meta.id,
                headerName: meta.columnName,
                width: 165,
                flex: 1,
                renderCell: (params) => {
                    return (
                        <div className="relative flex w-full h-full pl-1.5 items-center">
                            {' '}
                            {index === 0 && hover === params.row.id && (
                                <div className="absolute top-3.5 -left-2">
                                    <Button
                                        sx={{
                                            padding: '0px',
                                            minWidth: '10px',
                                        }}
                                        onClick={(e) => {
                                            setRowOptions((draft) => {
                                                draft.targetEl = e.target
                                                draft.open = true
                                                draft.rowId = params.row.id
                                            })

                                            e.stopPropagation()
                                        }}
                                        size="small"
                                    >
                                        <div className="pr-3">
                                            <MoreVertOutlined
                                                sx={{
                                                    fontSize: 18,
                                                    color: 'black',
                                                }}
                                            />
                                        </div>
                                    </Button>
                                </div>
                            )}
                            <Typography>{params.value}</Typography>
                            {index === 0 && hover === params.row.id && (
                                <div className="absolute top-3 bg-white right-0">
                                    <Button
                                        size="small"
                                        className="drop-shadow-md bg-white hover:drop-shadow-md"
                                        sx={{
                                            '&.MuiButtonBase-root:hover': {
                                                bgcolor: '#2a47740a',
                                            },
                                        }}
                                    >
                                        Open
                                    </Button>
                                </div>
                            )}
                        </div>
                    )
                },
                disableReorder: index === 0 ? true : false,
            }
        }
    )
    function getColumn(field: string) {
        const column = columns.find((column: any) => column.field === field)
        return column?.headerName
    }
    const statsColumns: GridColDef[] = [
        {
            field: 'totalContacts',
            headerName: 'Contacts',
            width: 150,
            sortable: false,
            flex: 1,
        },
        {
            field: 'lastEngagementTime',
            headerName: 'Last engaged',
            width: 150,
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        {params.value
                            ? format(new Date(params.value), 'dd MMM yyyy')
                            : '-'}
                    </>
                )
            },
        },
        {
            field: 'linkCount',
            headerName: 'Links shared',
            width: 150,
            sortable: false,
            flex: 1,
        },
        {
            field: 'visits',
            headerName: 'Visits',
            width: 150,
            flex: 1,
            sortable: false,
        },
    ]
    const rows: GridRowsProp = accounts.data.rows.data.map(
        (account: any, index: number) => {
            return { id: index, ...account }
        }
    )
    return (
        <div className="flex-1 flex w-full flex-col h-full">
            <div className="w-full pb-2 px-4">
                <div className="flex gap-4">
                    {queryOptions.sortModel && queryOptions.sortModel[0] && (
                        <>
                            <ActiveChip
                                icon={
                                    queryOptions.sortModel[0] &&
                                    queryOptions.sortModel[0].sort ===
                                        'desc' ? (
                                        <ArrowDownward color="primary" />
                                    ) : (
                                        <ArrowUpward color="primary" />
                                    )
                                }
                                onClick={() => {
                                    setQueryOptions({
                                        ...queryOptions,
                                        sortModel: [
                                            {
                                                ...queryOptions.sortModel[0],
                                                sort:
                                                    queryOptions.sortModel[0]
                                                        .sort === 'desc'
                                                        ? 'asc'
                                                        : 'desc',
                                            },
                                        ],
                                    })
                                }}
                                variant="outlined"
                                label={`${getColumn(
                                    queryOptions.sortModel[0].field
                                )} : ${
                                    queryOptions.sortModel[0].sort === 'desc'
                                        ? 'Descending'
                                        : 'Ascending'
                                }`}
                                onDelete={() => {
                                    setQueryOptions({ sortModel: [] })
                                }}
                            ></ActiveChip>
                            <Divider
                                orientation="vertical"
                                className="my-2"
                                flexItem
                            />
                        </>
                    )}
                    {account.filterColumns.length > 0 && (
                        <div className="flex  gap-2">
                            {account.filterColumns.map((column) => {
                                return (
                                    <Filter
                                        onDelete={() => {
                                            setAccount((draft) => {
                                                const index =
                                                    account.filterColumns.findIndex(
                                                        (item) =>
                                                            item.id ===
                                                            column.id
                                                    )
                                                delete draft.filterOptions[
                                                    column.id
                                                ]
                                                draft.filterColumns.splice(
                                                    index,
                                                    1
                                                )
                                            })
                                        }}
                                        onApply={(option) => {
                                            setAccount((draft) => {
                                                draft.filterOptions[column.id] =
                                                    option
                                            })
                                        }}
                                        options={
                                            account.filterOptions[column.id]
                                        }
                                        columnName={column.label}
                                        columnId={column.id}
                                        columnType={column.type}
                                    />
                                )
                            })}
                        </div>
                    )}
                    <div>
                        <Button
                            ref={filterButton}
                            onClick={() => {
                                setAccount((draft) => {
                                    draft.filter = true
                                })
                            }}
                            size="small"
                            startIcon={<AddOutlined />}
                            variant="plain"
                        >
                            Filter
                        </Button>
                    </div>
                    {accounts.isLoading && (
                        <div>
                            <Loading size="1.25rem" />
                        </div>
                    )}
                </div>
                <Popover
                    id={'filter-popover'}
                    open={account.filter}
                    anchorEl={filterButton.current}
                    onClose={() => {
                        setAccount((draft) => {
                            draft.filter = false
                        })
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div className="p-4">
                        <FormGroup>
                            {accounts.data.userTable.UserTableMeta.map(
                                (meta: any) => {
                                    return (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        account.filterColumns.findIndex(
                                                            (item) =>
                                                                item.id ===
                                                                meta.id
                                                        ) > -1
                                                    }
                                                    onChange={() => {
                                                        setAccount((draft) => {
                                                            const index =
                                                                account.filterColumns.findIndex(
                                                                    (item) =>
                                                                        item.id ===
                                                                        meta.id
                                                                )
                                                            if (index > -1) {
                                                                draft.filterColumns.splice(
                                                                    index,
                                                                    1
                                                                )
                                                            } else {
                                                                draft.filterColumns.push(
                                                                    {
                                                                        id: meta.id,
                                                                        label: meta.columnName,
                                                                        type: meta.columnType,
                                                                    }
                                                                )
                                                            }
                                                            draft.filter = false
                                                        })
                                                    }}
                                                />
                                            }
                                            label={meta.columnName}
                                        />
                                    )
                                }
                            )}
                        </FormGroup>
                    </div>
                </Popover>
            </div>
            <div className="px-5 w-full pb-5 overflow-auto h-5/6">
                <DataGrid
                    rows={rows}
                    columns={[...columns, ...statsColumns]}
                    loading={accounts.isLoading}
                    rowCount={rowCountState}
                    sortingMode="server"
                    sx={{
                        border: 'none',
                    }}
                    paginationMode="server"
                    slots={{
                        noRowsOverlay: () => (
                            <div className="w-full flex justify-center p-4">
                                <Button onClick={addAccount}>
                                    Click here to add your first account
                                </Button>
                            </div>
                        ),
                        noResultsOverlay: () => <div>No Result</div>,
                    }}
                    pagination
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        console.log(newRowSelectionModel)
                        navigate(`/accounts/${newRowSelectionModel[0]}`)
                    }}
                    disableColumnMenu
                    componentsProps={{
                        row: {
                            onMouseEnter: (event) => {
                                const id = event.currentTarget.dataset.id
                                if (rowOptions.open) return
                                setHover(id)
                            },
                            onMouseLeave: () => {
                                if (rowOptions.open) return
                                setHover(null)
                            },
                        },
                    }}
                    sortModel={queryOptions.sortModel}
                    paginationModel={paginationModel}
                    pageSizeOptions={[]}
                    onPaginationModelChange={setPaginationModel}
                    onSortModelChange={handleSortModelChange}
                />
            </div>
            <Popover
                id="mouse-over-popover"
                open={rowOptions.open}
                anchorEl={rowOptions.targetEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClose={() => {
                    setRowOptions((draft) => {
                        draft.open = false
                    })
                }}
                disableRestoreFocus
            >
                <MenuList>
                    <MenuItem
                        onClick={() => {
                            setRowOptions((draft) => {
                                draft.deleteModal = true
                                draft.open = false
                            })
                        }}
                    >
                        <ListItemIcon>
                            <DeleteOutlineOutlined />
                        </ListItemIcon>
                        <ListItemText>Delete</ListItemText>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            navigate(`/accounts/${rowOptions.rowId}`)
                        }}
                    >
                        <ListItemIcon>
                            <CallMadeRounded />
                        </ListItemIcon>
                        <ListItemText>Open</ListItemText>
                    </MenuItem>
                </MenuList>
            </Popover>
            <Dialog
                open={rowOptions.deleteModal}
                keepMounted
                onClose={() => {
                    setRowOptions((draft) => {
                        draft.deleteModal = false
                    })
                }}
            >
                <DialogTitle>Delete account</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Do you want to delete this account permanently?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            if (!rowOptions.rowId) return
                            deleteAccount.mutate({
                                id: rowOptions.rowId,
                            })
                        }}
                        disabled={deleteAccount.isLoading}
                        startIcon={
                            deleteAccount.isLoading && (
                                <Loading size="1.25rem" />
                            )
                        }
                        variant="contained"
                        color="error"
                    >
                        Delete
                    </Button>
                    <Button
                        onClick={() => {
                            setRowOptions((draft) => {
                                draft.deleteModal = false
                            })
                        }}
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
