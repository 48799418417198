import Loading from '@Common/Components/Loading'
import { useDealRoom } from '@Common/Queries/Dealroom/GetDealRoom'
import { Divider } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useDealRoomColor } from 'src/Stores/DealRoomTheme'
import { DealRoomHead } from './DealRoomHead'
import DealRoomPage from './DealRoomPage'
import DealRoomPanel from './DealRoomPanel'

export interface DealRoomProps {
    action: string
    id: string
    access: string
    status: string
    code: string
    DealRoomProperties: {
        name: string
    }
    DealRoomPermission: Array<{
        permission: string
        DealRoomGuestEmailPermission: {
            permission: string
        }
        DealRoomUserPermission: {
            User: {
                name: string
            }
            userId: string
        }
        DealRoomGroupPermission: {
            Group: {
                name: string
            }
            groupId: string
        }
    }>
}

export const DealRoom = () => {
    const color = useDealRoomColor((state) => state.color)
    const setColor = useDealRoomColor((state) => state.setColor)
    const { id } = useParams()
    const navigate = useNavigate()
    if (!id) {
        navigate('/dealroom')
    }

    const dealroom = useDealRoom({
        id: id,
        onSuccess: (data) => {
            setColor(data.dealRoom.theme)
        },
    })
    if (dealroom.isLoading) {
        return <Loading></Loading>
    }
    if (dealroom.error) {
        return (
            <div className="w-full h-full flex justify-center">
                Something went wrong
            </div>
        )
    }

    return (
        <div className="flex w-full h-full flex-col">
            <DealRoomHead
                id={id}
                dealroom={dealroom.data.dealRoom}
            ></DealRoomHead>

            <Divider className="w-full h-1"></Divider>
            <div className="w-full h-full flex flex-1 overflow-hidden">
                <DealRoomPage></DealRoomPage>
                <DealRoomPanel></DealRoomPanel>
            </div>
        </div>
    )
}
