export const imageSpec = {
    group: 'inline',
    inline: true,
    draggable: true,
    attrs: {
        src: {},
        alt: { default: null },
        title: { default: null },
        id: { default: null },
    },
    parseDOM: [
        {
            tag: 'img[src]',
            getAttrs(dom) {
                return {
                    src: dom.getAttribute('src'),
                    title: dom.getAttribute('title'),
                    alt: dom.getAttribute('alt'),
                    id: dom.getAttribute('uploadId'), /// added
                }
            },
        },
    ],
    toDOM(node) {
        const { src, alt, title, id } = node.attrs
        return ['img', { src, alt, title, id }]
    },
}
