import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useMutation } from 'react-query'
import { useImmer } from 'use-immer'
import PageHearder from '../Common/Components/PageHeader/PageHeader'
import AssetUpload from './AssetUpload'
import AssetsDropZone from './Components/DragDrop/AssetsDropZone'
import { v4 as uuidv4 } from 'uuid'
import AddAsset from '@Common/Queries/MutationQueries/AddAsset'
import useAddAsset from '@Common/Queries/MutationQueries/AddAsset'
import useFile from '@Common/Queries/MutationQueries/file'
import useUploadFile from '@Common/Queries/MutationQueries/uploadFile'
import { WritableDraft } from 'immer/dist/internal'
import useTitle from '@Common/hooks/useTitle'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import useLocalStorage from '@Common/hooks/useLocalStorage'
import { Permission } from '@Common/Permission'
export enum AssetStatus {
    'initial',
    'uploading',
    'uploaded',
    'error',
    'completed',
}
interface Asset {
    file: File
    fileDetails?: FileDetails
    status: AssetStatus
    assetDetails?: any
    error?: string
    id: string
    progress?: number
}
interface FileDetails {
    fileName: string
    fileId: string
}
interface AddAssetProps {
    setSuggestedAssets: (suggestedAssets: Asset[]) => void
    isSearch: boolean
}
const AddAssets: React.FC<AddAssetProps> = (props) => {
    const [assetsFiles, setAssetFiles] = useImmer<Asset[]>([])
    const [fileDetails, setFileDetails] = useState<string>('')
    const [theme, setTheme] = useLocalStorage('theme', {
        tenant: {
            name: '',
        },
    })
    const sendDataToGTM = useGTMDispatch()
    useTitle(`${theme?.tenant?.name ? theme?.tenant?.name : ''} - Add Asset `)
    const onAddAssetSuccess = (res: any, variables: { id: string }) => {
        setAssetFiles((draft) => {
            const asset = draft.find((asset) => asset.id === variables.id)

            if (asset) {
                asset.status = AssetStatus.completed
                asset.assetDetails = res.data
            }
        })
        sendDataToGTM({
            event: 'asset_add',
            asset_id: res.data.id,
            asset_name: res.data.name,
        })
        const asset = assetsFiles.find((asset) => asset.id === variables.id)
        if (!asset) {
            return
        }
        setFileDetails(res.data.id)
    }

    const onErrorAddAsset = (err: any, variables: any) => {
        setAssetFiles((draft) => {
            const asset = draft.find((asset) => asset.id === variables.id)
            if (asset) {
                asset.status = AssetStatus.error
                asset.error = 'Create asset not successful'
            }
        })
    }

    const onErrorAsset = (err: any, variables: any) => {
        console.log(JSON.stringify(err))
        setAssetFiles((draft) => {
            const asset = draft.find((asset) => asset.id === variables.id)
            if (asset) {
                asset.status = AssetStatus.error
                asset.error =
                    err.response.status === 422
                        ? 'File Type not supported'
                        : 'Create asset not successful'
            }
        })
    }

    const option = (obj: any) => {
        return {
            onUploadProgress: (progressEvent: { loaded: any; total: any }) => {
                const { loaded, total } = progressEvent
                var percent = Math.floor((loaded * 100) / total)
                setAssetFiles((draft) => {
                    const asset = draft.find((asset) => asset.id === obj.id)
                    if (asset) {
                        asset.progress = percent
                    }
                })
            },
            headers: { 'Content-Type': 'multipart/form-data' },
        }
    }

    const onSaveAsset = async (data: any, val: any) => {
        const file = val.file
        const formData = new FormData()
        formData.append('file', file)
        formData.append('fields', data.data.file)
        const obj = {
            url: data?.data?.preSignedUrl,
            body: file,
            data: data.data.file,
            file: file,
            id: val.id,
        }

        if (data.data.preSignedUrl) {
            uploadFile(obj)
        }
    }

    const onUploadSuccess = (res: any, variables: any) => {
        setAssetFiles((draft) => {
            const asset = draft.find((asset) => asset.id === variables.id)
            if (asset) {
                asset.status = AssetStatus.uploaded
                asset.fileDetails = variables.data.id
            }
        })
        const body = {
            name: variables?.file?.name,
            shareable: false,
            fileId: variables?.data?.id,
            assetTypeId: '1',
            access: Permission.VIEW,
        }
        addAsset({
            ...variables,
            fileDetails: variables.data.id,
            body: body,
        })
    }

    const onUploadError = (err: any, variables: any) => {
        setAssetFiles((draft) => {
            const asset = draft.find((asset) => asset.id === variables.id)
            if (asset) {
                asset.status = AssetStatus.error
                if (err.response.data.search('EntityTooLarge') >= 0) {
                    asset.error = 'File exceeded the 50MB size limit'
                    return
                }
                asset.error = 'Upload file not successful'
            }
        })
    }

    const {
        mutate: addAsset,
        isSuccess: isSuccessUpdateAsset,
        isError: isErrorUpdateAsset,
        isLoading: isLoadingUpdateAsset,
        data: UpdateAssetData,
    } = useAddAsset({ onSuccess: onAddAssetSuccess, onError: onErrorAddAsset })
    const {
        mutateAsync: saveFile,
        isSuccess: isSuccessSaveFile,
        isError: isErrorSaveFile,
        isLoading: isLoadingSaveFile,
        data: saveFileData,
    } = useFile({ onSuccess: onSaveAsset, onError: onErrorAsset })
    const {
        mutate: uploadFile,
        isSuccess: isSuccessUploadFile,
        isError: isErrorUploadFile,
        isLoading: isLoadingUploadFile,
        data: UpdateUploadFile,
    } = useUploadFile({
        onSuccess: onUploadSuccess,
        option: option,
        onError: onUploadError,
    })

    const handleFiles = (files: File[]) => {
        setAssetFiles((draft) => {
            files.forEach((file) => {
                draft.push({
                    file,
                    status: AssetStatus.initial,
                    id: uuidv4(),
                })
            })
        })
    }

    function handleFileRemove(id: string) {
        setAssetFiles((draft) => {
            const fileIndex = draft.findIndex(
                (assetFile) => assetFile.id === id
            )
            draft.splice(fileIndex, 1)
        })
        setFileDetails('')
    }

    useEffect(() => {
        async function uploadFiles() {
            const unUploadedFiles = assetsFiles.filter(
                (assetFile) => assetFile.status === AssetStatus.initial
            )
            setAssetFiles((draft) => {
                draft.forEach((assetFile) => {
                    if (assetFile.status === AssetStatus.initial) {
                        assetFile.status = AssetStatus.uploading
                    }
                })
            })
            const saveFiles = unUploadedFiles.map((assetFile) => {
                return saveFile(assetFile)
            })
            await Promise.all(saveFiles)
        }
        uploadFiles()
    }, [assetsFiles])

    return (
        <>
            {!props.isSearch && <PageHearder title="Add Assets" />}
            <main className=" flex-1 overflow-hidden bg-gray-200">
                <div className=" h-full">
                    {assetsFiles.length <= 0 && (
                        <AssetsDropZone onFiles={handleFiles} bodyFull={true} />
                    )}
                    {assetsFiles.length > 0 && (
                        <AssetUpload
                            onRemoveFile={handleFileRemove}
                            onFiles={handleFiles}
                            assetsFiles={assetsFiles}
                            setSuggestedAssets={props.setSuggestedAssets}
                            setFileDetails={setFileDetails}
                            fileDetails={fileDetails}
                            isSearch={props.isSearch}
                        />
                    )}
                </div>
            </main>
        </>
    )
}

export default AddAssets
