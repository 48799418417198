import { useQuery, useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'
import { UpdateUserRole } from '@Server/updateUserRole'

export default function useUpdateUser() {
    const queryClient = useQueryClient()
    return useMutation<
        any,
        Error,
        { body: UpdateUserRole; id: string; filter: any; role: string }
    >(
        (obj) =>
            axios.put(`${APi_URL}/users/${obj.id}`, obj.body, {
                headers: { 'Content-Type': 'application/json' },
            }),
        {
            onSuccess: (data: any, obj: any) => {
                queryClient.invalidateQueries(['Users'])
            },
            onMutate: (obj) => {
                const oldUsers = queryClient.getQueryData(['Users', obj.filter])
                queryClient.setQueryData(['Users', obj.filter], (old) => {
                    const index = old.users.findIndex((user) => {
                        return user.id === obj.id
                    })
                    old.users[index].UserRole[0] = {
                        ...old.users[index].UserRole[0],
                        role: {
                            ...old.users[index].UserRole[0].role,
                            role: obj.role,
                        },
                    }
                    return old
                })
                return { oldUsers }
            },
            onError: (err: any, obj, context: any) => {
                queryClient.setQueryData(
                    ['Users', obj.filter],
                    context.oldUsers
                )
            },
        }
    )
}
