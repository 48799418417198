import { Dialog, Transition } from '@headlessui/react'
import axios from 'axios'
import { Fragment, useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import Select from 'react-select'
import DropDown from '../../../../Common/Components/dropdown/DropDown'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid'
import { API_URL, APi_URL } from '@Common/Types/configs/urlConfig'
import SuccessModal from '@Common/Components/Modals/SuccessModal'
import ErrorModal from '@Common/Components/Modals/ErrorModal'
import useAssetType from '@Common/Queries/GetQueries/GetAssetTypeData'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import PrimaryButton from '@Common/Components/Button/PrimaryButton'
import SecondaryButton from '@Common/Components/Button/SecondaryButton'
import Loading from '@Common/Components/Loading'
import { Button } from '@mui/material'

interface IProps {
    modalTitle?: string
    NewAsset: boolean | ((prevState: boolean) => boolean)
    asset?: any
    isOpen: (arg0: boolean) => void
    version: boolean
}

const Modal: React.FC<IProps> = ({
    NewAsset,
    asset,
    isOpen,
    modalTitle,
    version,
}) => {
    const [text, setText] = useState<string>('')
    const [typeSearchResult, setTypeSearchResult] = useState<any>([])
    const [done, setDone] = useState(false)
    const [error, setError] = useState(false)
    const [message, setMessage] = useState('')
    const {
        data: assetTypeData,
        error: assetTypeError,
        isSuccess: isAssetTypeSuccess,
    } = useAssetType()
    const sendDataToGTM = useGTMDispatch()

    const { mutate: clickHandle, isLoading: loadingData } = useMutation<
        any,
        Error
    >(
        async () => {
            if (NewAsset) {
                const body = {
                    type: typeSearchResult.value,
                    description: text,
                }

                const data = await axios.post(`${APi_URL}/requests/new`, body, {
                    headers: {
                        'Content-Type': 'application/json',
                        timeZone:
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                    },
                })
                sendDataToGTM({
                    event: 'raise_request_asset_required',
                    action: 'raise',
                    request_id: data.data.id,
                })
                return data
            } else {
                const body = {
                    description: text,
                }

                const data = await axios.post(
                    `${APi_URL}/requests/asset/${
                        version ? asset.assetId : asset.id
                    }/permission`,
                    body,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            timeZone:
                                Intl.DateTimeFormat().resolvedOptions()
                                    .timeZone,
                        },
                    }
                )
                sendDataToGTM({
                    event: 'raise_request_asset_access',
                    action: 'raise',
                    request_id: data.data.id,
                })
                return data
            }
        },
        {
            onSuccess: (res: any) => {
                setDone(true)
            },
            onError: (err: any) => {
                setError(true)
                setMessage('Something went wrong')
            },
        }
    )

    if (done) {
        return <SuccessModal isOpen={isOpen} />
    }
    if (error) {
        return <ErrorModal isOpen={isOpen} message={message} />
    }

    return (
        <>
            <Transition appear show={true} as={Fragment}>
                <Dialog
                    as="div"
                    className={style.dialogContainer}
                    onClose={() => {}}
                >
                    <div className={style.subContainer}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className={style.dialogOverlay} />
                        </Transition.Child>

                        <span className={style.span} aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className={style.dialogTitleContainer}>
                                <Dialog.Title
                                    as="h2"
                                    className={style.dialogTitle}
                                >
                                    {modalTitle}
                                </Dialog.Title>
                                {!loadingData ? (
                                    <>
                                        <div className="mt-1">
                                            <p className={style.pTag}>
                                                {NewAsset ? (
                                                    <i>
                                                        By clicking raise, you
                                                        are requesting the
                                                        support of the asset
                                                        management team to find
                                                        an asset that best suits
                                                        your requirement
                                                    </i>
                                                ) : (
                                                    <i>
                                                        By clicking raise, you
                                                        are requesting the asset
                                                        management team for
                                                        access to the asset.
                                                        Upon approval, you will
                                                        recieve an email.
                                                    </i>
                                                )}
                                            </p>
                                        </div>

                                        <div className="mt-3">
                                            {NewAsset ? (
                                                <>
                                                    <h4
                                                        className={`${style.assetName} hidden  `}
                                                    >
                                                        Asset Type
                                                    </h4>
                                                    <Select
                                                        defaultValue="all"
                                                        options={
                                                            assetTypeData?.option
                                                        }
                                                        onChange={
                                                            setTypeSearchResult
                                                        }
                                                        className={`${style.select} hidden`}
                                                        placeholder="Select Asset Type"
                                                        classNamePrefix="Select Asset Type"
                                                    />
                                                    <p
                                                        className={
                                                            style.descriptionContainer
                                                        }
                                                    >
                                                        Request Description
                                                        {/* <i>(optional)</i> */}
                                                    </p>
                                                    <textarea
                                                        required
                                                        className={style.input}
                                                        style={{
                                                            resize: 'none',
                                                        }}
                                                        onChange={(e: any) =>
                                                            setText(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <h4
                                                        className={
                                                            style.assetName
                                                        }
                                                    >
                                                        Asset Name
                                                    </h4>
                                                    <h3
                                                        className={
                                                            style.assetNm
                                                        }
                                                    >
                                                        {asset?.name &&
                                                            asset?.name}
                                                        {asset?.file?.name}
                                                    </h3>
                                                    <p
                                                        className={
                                                            style.descriptionContainer
                                                        }
                                                    >
                                                        Request Description
                                                        <i>(optional)</i>
                                                    </p>
                                                    <textarea
                                                        required
                                                        className={style.input}
                                                        style={{
                                                            resize: 'none',
                                                        }}
                                                        onChange={(e: any) =>
                                                            setText(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </>
                                            )}
                                        </div>

                                        <div className={style.btnContainer}>
                                            <Button
                                                variant="contained"
                                                className="w-36"
                                                onClick={() => {
                                                    clickHandle()
                                                }}
                                            >
                                                Raise
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    isOpen(false)
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </>
                                ) : (
                                    <Loading />
                                )}
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default Modal

const style = {
    dialogContainer:
        'fixed inset-0 z-40 overflow-y-auto bg-opacity-50 bg-gray-500',
    subContainer: 'min-h-screen px-4 text-center',
    dialogOverlay: 'fixed inset-0',
    span: 'inline-block h-screen align-middle',
    dialogTitleContainer:
        'inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl',
    dialogTitle: 'text-lg font-medium leading-6 text-textColor',
    closeBtn:
        'inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500',
    btnCancel:
        'py-2 px-7 rounded-sm border text-approveBtn border-activeBorderColor font-bold text-sm ml-2',
    btnRaise:
        'py-2 px-8 bg-approveBtn rounded-sm text-white text-sm font-bold ml-3',
    pTag: 'text-xs text-gray-400',
    assetName: 'text-sm text-gray-500',
    assetNm: 'text-textColor text-base mt-3',
    descriptionContainer: 'text-sm text-gray-500 mt-3',
    input: 'h-20 border-2 outline-none w-full mt-1 p-2',
    btnContainer: 'mt-6 flex flex-row gap-4',
    select: 'basic-multi-select border-none outline-none whitespace-nowrap text-14px font-normal font-Segoe  max-w-max',
    btnApprove:
        'py-2 px-6 bg-gradient-to-r from-primary to-gradient rounded-sm text-white text-sm font-bold ml-4 ',
    btnReject:
        'py-2 px-5 rounded-sm border text-primary border-gradient font-bold text-sm ',
}
