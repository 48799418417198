import CircularProgress from '@mui/material/CircularProgress'


interface LoadingProps {
    size?: string
}
export default function Loading(props: LoadingProps) {
    return (
        <div className="flex w-full h-full justify-center items-center">
            <CircularProgress {...props} color="primary" />
        </div>
    )
}
