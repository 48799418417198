import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import portal from './../Icons/portal.png'
export const OutlookWelcome = () => {
    const navigate = useNavigate()
    return (
        <div className="flex w-full h-full justify-center">
            <div className="flex flex-col w-10/12 items-center justify-center">
                <div className="flex flex-row">
                    <img src={portal} width='90px' alt='LIFEX_logo'></img>
                </div>
                <div className="flex flex-col py-16 justify-center items-center text-center">
                    <Typography variant="h4">
                        Influence B2B buying decisions
                    </Typography>

                    <Typography variant="body1" className="pt-3">
                        LIFEX Portal is your platform to crush competition, win
                        more deals, and accelerate revenue growth
                    </Typography>
                </div>
                <Button
                    className="w-52 rounded-none"
                    size="large"
                    variant="contained"
                    onClick={() => {
                        navigate('/login?previousUrl=/plugin?origin=outlook')
                    }}
                >
                    GET STARTED
                </Button>
            </div>
        </div>
    )
}
