import { Tooltip, Typography } from "@mui/material";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AppleSvg from './Icons/apple_logo.svg'
import WindowsSvg from './Icons/windows_logo.svg'
import LinuxSvg from './Icons/linux_logo.svg'
import AndroidSvg from './Icons/android_logo.svg'
import IosSvg from './Icons/ios_logo.svg'
import ChromeSvg from './Icons/chromeos_logo.svg'
import UbuntuSvg from './Icons/ubuntu_logo.svg'
import AvatarComponent from "@Common/Components/Avatar";

export function getVisitorName(email: string | null, json: object): string {
    if (email) return email;

    else if (json && json.operatingSystem) {
        return json.operatingSystem + " visitor";
    }
    return 'Anonymous visitor'
}

export function getImageFromName(name: string) {
    switch(name){
        case "Windows visitor": return WindowsSvg;
        case "MacOS visitor": return  AppleSvg;
        case "Linux OS visitor": return LinuxSvg;
        case "Android OS visitor": return AndroidSvg;
        case "ChromeOS visitor": return ChromeSvg;
        case "Ubuntu OS visitor": return UbuntuSvg;
        case "iOS visitor": return IosSvg;
        default: return null;
    }    
}

export function displayAvatarBasedOnName(name:string, id:string){
    const imageURL = getImageFromName(name)
    if(imageURL || name === 'Anonymous visitor'){
        return(
            <AvatarComponent
                name={name}
                src={imageURL}
                sx={{
                    color: "rgb(100,100,100)",
                    bgcolor: "rgb(255,255,255)",
                    border: "solid 0.06rem rgb(100,100,100)",
                    padding: 1,
                    fontSize: 20,
                    fontWeight: 500
                }}
                id={id}
            ></AvatarComponent>
        )
    }
    return (<AvatarComponent
            name={name}
            id={id}
        ></AvatarComponent>)
}

export function NameDisplay(props: {
    name: string,
    city: string,
    country: string
}) {
    const location = `${props.city}, ${props.country}`
    return (
        <div className="flex flex-row space-x-2 items-center ">
            <Typography color="text.secondary" fontWeight={500}>
                {props.name}
            </Typography>
            {props.city && props.country && (
                <div className='flex flew-row w-1/2 items-center text-gray-400'>
                    <LocationOnOutlinedIcon></LocationOnOutlinedIcon>
                    <Tooltip title={location}>
                        <Typography>{location}</Typography>
                    </Tooltip>
                </div>
            )}
        </div>
    )
}