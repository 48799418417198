import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import toast from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'

export const useDeleteDealroomPermission = () => {
    const queryClient = useQueryClient()
    return useMutation(
        async (data: any) =>
            await axios.delete(
                `${API_URL}/dealroom/${data.dealroomId}/permission/${data.dealroomPermissionId}`
            ),
        {
            onSuccess: (data) => {
                toast.success('Permission deleted successfully')
                queryClient.invalidateQueries(['dealroom', data.data.id])
            },
            onError: (err) => {
                console.log(err)
                toast.error('Error in deleting permission')
            },
            onMutate: (data) => {
                queryClient.cancelQueries(['dealroom', data.dealroomId])
                queryClient.setQueryData(
                    ['dealroom', data.dealroomId],
                    (oldData) => {
                        const permissionIndex =
                            oldData.dealRoom.DealRoomPermission.findIndex(
                                (permission) => {
                                    return (
                                        permission.id ===
                                        data.dealroomPermissionId
                                    )
                                }
                            )
                        oldData.dealRoom.DealRoomPermission.splice(
                            permissionIndex,
                            1
                        )
                        return oldData
                    }
                )
            },
        }
    )
}
