import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useQuery } from 'react-query'

export const useWorkspaceData = () => {
    return useQuery(['dashboard', 'workspace'], async () => {
        const result = await axios.get(`${API_URL}/dashboard/workspace`, {
            headers: {
                'Content-type': 'application/json',
            },
        })
        return result.data
    })
}
