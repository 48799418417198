import Loading from '@Common/Components/Loading'
import PopoverComponent from '@Common/Components/PopoverComponent'
import useGetStatus from '@Common/Queries/Dealroom/GetAllStatus'
import useUsers from '@Common/Queries/GetQueries/GetUsers'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { Button, Divider, Typography } from '@mui/material'
import { useImmer } from 'use-immer'
import { CreateDealRoom } from './Components/CreateDealRoom'
import { DealRoomTable } from './DealRoomTable'

const DealRoomSearch = () => {
    const [creatDealRoom, setCreateDealRoom] = useImmer({
        open: false,
        anchorEl: null,
    })
    const status = useGetStatus()
    const users = useUsers()

    if (users.isLoading || status.isLoading) {
        return <Loading></Loading>
    }

    return (
        <>
            <PopoverComponent
                state={creatDealRoom}
                setState={setCreateDealRoom}
            >
                <CreateDealRoom setState={setCreateDealRoom}></CreateDealRoom>
            </PopoverComponent>
            <div className=" flex w-full flex-col gap-6 flex-1 overflow-hidden">
                <div className="flex w-full flex-col">
                    <div className="px-6 flex justify-between w-full h-16 items-center">
                        <Typography variant="h5">Digital room</Typography>
                        <div>
                            <Button
                                data-testid="dealroom-create-btn"
                                startIcon={<AddOutlinedIcon></AddOutlinedIcon>}
                                variant="contained"
                                onClick={(e) => {
                                    setCreateDealRoom((draft) => {
                                        draft.open = true
                                        draft.anchorEl = e.currentTarget
                                    })
                                }}
                            >
                                New room
                            </Button>
                        </div>
                    </div>
                    <Divider></Divider>
                </div>
                <div className="h-full overflow-auto px-6">
                    <DealRoomTable></DealRoomTable>
                </div>
            </div>
        </>
    )
}

export default DealRoomSearch
