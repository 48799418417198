import useTitle from '@Common/hooks/useTitle'
import React from 'react'
import { useQuery } from 'react-query'

import useDebounce from '@Common/hooks/useDebounce'
import { APi_URL } from '@Common/Types/configs/urlConfig'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import SearchPage from '@Search/components/SearchPage'
import axios from 'axios'

import Loading from '@Common/Components/Loading'
import useCustomAttribute from '@Common/Queries/GetQueries/GetCustomAttributes'
import useGroups from '@Common/Queries/GetQueries/GetGroupsData'
import useGetTableConfiguration from '@Common/Queries/GetQueries/GetTableConfiguration'
import useUsers from '@Common/Queries/GetQueries/GetUsers'
import useInit from '@Common/Queries/GetQueries/useInit'
import { useOutletContext } from 'react-router-dom'

interface SearchScreenProps {
    showOptions?: boolean
    isModal?: boolean
    addSearchedAssset?: any
    location: string
    onClickOfRow?: any
    isCardView?: boolean
}

const SearchScreen: React.FC<SearchScreenProps> = (props) => {
    const init = useInit()
    useTitle(
        `${init.data?.tenant?.name ? init.data?.tenant?.name : ''} - Search `
    )
    const { isModal, isCardView = true, showOptions = true } = props
    const [filter, setFilter] = useOutletContext()

    const debouncedFilter = useDebounce(filter, 200)
    const sendDataToGTM = useGTMDispatch()
    const { data: customAttributesData, isLoading: isLoadingCustomAttributes } =
        useCustomAttribute()

    const { data: usersData, isLoading: isLoadingUsers } = useUsers()
    const groups = useGroups()

    const search = useQuery<any, Error, object>(
        ['search', debouncedFilter],
        async () =>
            await axios.post(`${APi_URL}/search`, debouncedFilter, {
                headers: { 'Content-Type': 'application/json' },
            }),
        {
            keepPreviousData: true,
            onSuccess: () => {
                sendDataToGTM({
                    event: 'item_search_for',
                    search_text: debouncedFilter.searchTerm,
                })
            },
        }
    )

    const { data: tableConfiguration, isLoading: isLoadingTableConfiguration } =
        useGetTableConfiguration()
    return (
        <>
            <div className="h-full w-full overflow-hidden">
                {search.isLoading ||
                isLoadingTableConfiguration ||
                isLoadingCustomAttributes ||
                isLoadingUsers ? (
                    <Loading />
                ) : (
                    <SearchPage
                        showOptions={showOptions}
                        isCardView={isCardView}
                        search={search}
                        isModal={isModal ? isModal : false}
                        filter={filter}
                        setFilter={setFilter}
                        addSearchedAssset={props.addSearchedAssset}
                        location={props.location}
                        onClickOfRow={props.onClickOfRow}
                    ></SearchPage>
                )}
            </div>
        </>
    )
}
export default SearchScreen
