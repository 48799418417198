import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'
import { ISnippetsParams } from '@elgorditosalsero/react-gtm-hook/dist/models/GoogleTagManager'
import { Outlet } from 'react-router-dom'

export default function AuthHolder() {
    function getGTMEnvironment() {
        if (import.meta.env.VITE_GTM_AUTH) {
            return {
                environment: {
                    gtm_auth: import.meta.env.VITE_GTM_AUTH,
                    gtm_preview: import.meta.env.VITE_GTM_PREVIEW,
                },
            }
        }
        return {}
    }
    const gtmParams: ISnippetsParams = {
        id: import.meta.env.VITE_GTM_ID,
        ...getGTMEnvironment(),
        dataLayerName: 'user',
    }
    return (
        <GTMProvider state={gtmParams}>
            <Outlet />
        </GTMProvider>
    )
}
