import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import isEmpty from 'lodash.isempty'

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
    [`& .${autocompleteClasses.listbox}`]: {
        backgroundColor: '#fffff',
        padding: 0,
        [`& .${autocompleteClasses.option}`]: {
            '&[aria-selected="true"]': {
                backgroundColor: 'transparent',
            },
            [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
                {
                    backgroundColor: theme.palette.action.hover,
                },
        },
    },
    [`&.${autocompleteClasses.popperDisablePortal}`]: {
        position: 'relative',
    },
    [`&.${autocompleteClasses.root}`]: {
        padding: 0,
    },
}))
const StyledInput = styled(TextField)(({ theme }) => ({
    padding: 10,
    '& .MuiOutlinedInput-root': {
        paddingTop: 2,
        paddingBottom: 2,
        minHeight: 48,
        backgroundColor: 'white',
    },
    '& input': {
        fontSize: 14,
    },
}))

function PopperComponent(props) {
    const { disablePortal, anchorEl, open, ...other } = props
    return <StyledAutocompletePopper {...other} />
}
export default function PopoverMultiSelect({
    options,
    onChange,
    value,
    labelName,
}) {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
    const checkedIcon = <CheckBoxIcon fontSize="small" />
    return (
        <Autocomplete
            open
            multiple
            isOptionEqualToValue={(option, value) => {
                return option.label === value.label
            }}
            ListboxProps={{ style: { maxHeight: '200px' } }}
            limitTags={1}
            defaultValue={!isEmpty(value) ? value : []}
            value={!isEmpty(value) ? value : []}
            onChange={onChange}
            disableCloseOnSelect
            disablePortal={true}
            PopperComponent={PopperComponent}
            renderTags={(value, getTagProps) => {
                return (
                    <>
                        <div className="pt-1 flex flex-col">
                            {value.map((option, index) => (
                                <div key={index} className="pb-1">
                                    <Chip
                                        {...getTagProps({ index })}
                                        label={option[`${labelName}`]}
                                        size="small"
                                        className="w-32 text-Roboto whitespace-nowrap overflow-hidden text-ellipsis"
                                    />
                                </div>
                            ))}
                        </div>
                    </>
                )
            }}
            renderOption={(props, option, { selected }) => (
                <li
                    {...props}
                    className="p-0 w-full overflow-hidden whitespace-nowrap text-ellipsis px-2"
                >
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        checked={selected}
                    />
                    <span className="my-auto text-Roboto text-sm">
                        {option[`${labelName}`]}
                    </span>
                </li>
            )}
            options={options}
            getOptionLabel={(option) => option[`${labelName}`]}
            renderInput={(params) => (
                <StyledInput
                    ref={params.InputProps.ref}
                    autoFocus
                    {...params}
                />
            )}
        />
    )
}
