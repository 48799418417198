import { useQuery, useQueryClient } from 'react-query'
import axios from 'axios'
import { API_URL } from '@Common/Types/configs/urlConfig'

const getWorkspaces = async () => {
    const { data } = await axios.get(`${API_URL}/workspaces`)
    return data
}

export default function useWorkspaces() {
    const queryClient = useQueryClient()

    return useQuery(['workspaces'], () => getWorkspaces())
}
