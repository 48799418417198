import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'

export function useDeleteAccounts(props?: { onSuccess: () => void }) {
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: (request: { id: string }) => deleteAccounts(request),
        onSuccess: () => {
            queryClient.invalidateQueries('accounts')
            props.onSuccess()
        },
    })
    return mutation
}
async function deleteAccounts(request: { id: string }) {
    return await axios.delete(`${API_URL}/accounts/${request.id}`)
}
