import Loading from '@Common/Components/Loading'
import useFeatureFlag from '@Common/hooks/useFeatureFlag'
import useTitle from '@Common/hooks/useTitle'
import useInit from '@Common/Queries/GetQueries/useInit'
import CreateWorkspace from './CreateWorkspace'
import ProvideName from './ProvideName'
import Workspace from './Workspace'

export default function UserOnboarding() {
    const init = useInit()
    useTitle('Welcome to Portal by LIFEX')
    const workspace = useFeatureFlag('workspace_type')
    if (init.isLoading) {
        return <Loading />
    }
    if (workspace) {
        switch (init.data.user.onBoarding) {
            case 'INIT': {
                return <ProvideName />
            }
            case 'NAME': {
                return <Workspace />
            }
            case 'CREATE_WORKSPACE': {
                return <CreateWorkspace />
            }
        }
    }

    return <></>
}
