import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close'
import { Popper, Button, TextField, Typography, Paper, IconButton, } from '@mui/material';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import Loading from '@Common/Components/Loading';
import postNewAssetRequest from './NewAssetRequestApi';
import { FeedbackState } from './SearchPage';

interface IProps {
  feedback: FeedbackState,
  setFeedback: any
}

const FeedBackPopper: React.FC<IProps> = ({
  feedback,
  setFeedback,
}) => {
  const [text, setText] = useState<string>('')
  const sendDataToGTM = useGTMDispatch()
  const data = postNewAssetRequest();

  return (
    <Popper open={feedback.open} placement="top-end" anchorEl={feedback.anchorEl} className='w-80 h-56 py-4 border bg-white border-gray-200 shadow-lg rounded flex flex-col justify-evenly'>
        <div className='flex px-6 py-1 justify-between items-center'>
          <Typography className='font-bold'>
              What are you looking for?
          </Typography>
          <IconButton>
          <CloseIcon className="text-lg" onClick={() => {
            setFeedback({
              ...feedback,
              open: false,
              anchorEl: null
            })
            localStorage.setItem("popupClosedOrSubmitted", "true")
          }}>

          </CloseIcon>
          </IconButton>
        </div>
        <div className='px-6 py-2  w-full'>
          <TextField 
              variant='outlined'
              multiline
              placeholder='I am looking for ...'
              rows={4}
              className='w-full'
              onChange={(e: any) =>
                setText(
                    e.target.value
                )
            }
            />
        </div>
        <div className='flex px-6 justify-end'>
          <Button disabled={text.length==0} variant="text" onClick={() => {       
                data.mutate({
                  text: text,
                  feedback: feedback,
                  setFeedback: setFeedback,
                  sendDataToGTM: sendDataToGTM
                });
                localStorage.setItem("popupClosedOrSubmitted", "true")
          }}>
              
              <div className="pr-2 pt-1">
                            {data.isLoading && <Loading size="1rem" />}
                        </div>
                        Submit
          </Button>
        </div>
    </Popper>
  );
};

export default FeedBackPopper;


