import { Autocomplete, Button, Chip, TextField } from '@mui/material'
import { useImmer } from 'use-immer'

import Loading from '@Common/Components/Loading'
import useLocalStorage from '@Common/hooks/useLocalStorage'
import useUsers from '@Common/Queries/GetQueries/GetUsers'
import useInit from '@Common/Queries/GetQueries/useInit'
import useSearchGroups from '@Common/Queries/GetQueries/useSearchGroup'
import { APi_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { SelectInfo } from '../SelectInfo'
import { Asset } from '../ViewAsset'
import { getPermissions } from './permissions'

export function AddMember({
    asset,
    onClose,
}: {
    asset: Asset
    onClose: () => void
}) {
    const [permission, setPermission] = useImmer({
        members: [] as any[],
        permission: 'FULL_ACCESS',
        message: '',
    })
    const [search, setSearch] = useImmer({
        searchTerm: '',
    })
    const users = useUsers()
    const assetPermission = useAssetPermission({
        onSuccess: () => {
            onClose()
        },
    })
    const userOptions = users.data?.data
        .filter((user) => {
            const existingUser = asset.AssetPermission.find((permission) => {
                if (!permission.AssetUserPermission) {
                    return false
                }
                return permission.AssetUserPermission.userId === user.id
            })
            return !existingUser
        })
        .map((user) => ({
            name: user.name,
            type: 'Users',
            id: user.id,
        }))

    const groups = useSearchGroups(search)
    const groupOptions = groups.data?.data.groups
        .filter((group) => {
            const existingUser = asset.AssetPermission.find((permission) => {
                if (!permission.AssetGroupPermission) {
                    return false
                }
                return permission.AssetGroupPermission.groupId === group.id
            })
            return !existingUser
        })
        .map((group) => ({
            name: group.name,
            type: 'Groups',
            id: group.id,
        }))
    const [theme] = useLocalStorage('theme', {})
    const init = useInit()
    const permissions = getPermissions(
        'add',
        init?.data.tenant?.TenantConfig?.type
    )
    return (
        <div className="flex flex-col gap-5">
            <div className="flex gap-2">
                <Autocomplete
                    options={userOptions.concat(groupOptions)}
                    getOptionLabel={(option) => option?.name}
                    groupBy={(option) => option?.type}
                    multiple
                    loading={groups.isLoading || users.isLoading}
                    className="w-56"
                    onChange={(e: any, newValue: any[], reason) => {
                        if (
                            reason === 'selectOption' ||
                            reason === 'removeOption'
                        ) {
                            setPermission((draft) => {
                                draft.members = newValue
                            })
                            return
                        }
                    }}
                    renderTags={(value: readonly unknown[], getTagProps) =>
                        value.map((option: unknown, index: number) => (
                            <Chip
                                variant="outlined"
                                color={
                                    option.type === 'Users'
                                        ? 'success'
                                        : 'secondary'
                                }
                                label={option.name}
                                {...getTagProps({ index })}
                            />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            onChange={(e) => {
                                setSearch((draft) => {
                                    draft.searchTerm = e.target.value
                                })
                            }}
                            {...params}
                        />
                    )}
                />
                <SelectInfo
                    variant="standard"
                    className="w-24"
                    value={permission.permission}
                    onChange={(e) => {
                        setPermission((draft) => {
                            draft.permission = e.target.value as string
                        })
                    }}
                    items={permissions}
                ></SelectInfo>
            </div>
            {/* <TextField
                label="Add a message"
                onChange={(e) => {
                    setPermission((draft) => {
                        draft.message = e.target.value
                    })
                }}
                multiline
                className="w-full"
            /> */}
            <div className="flex justify-end">
                <Button className="mr-4" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    disabled={assetPermission.isLoading}
                    variant="contained"
                    onClick={() => {
                        assetPermission.mutate({
                            assetId: asset.id,
                            body: permission,
                        })
                    }}
                >
                    <div className="pr-2">
                        {assetPermission.isLoading && <Loading size="1rem" />}
                    </div>
                    Invite
                </Button>
            </div>
        </div>
    )
}

interface AddAssetPermission {
    assetId: string
    body: {
        members: {
            id: string
            type: 'Users' | 'Groups'
        }[]
        permission: string
    }
}
export function useAssetPermission(props?: any) {
    const queryClient = useQueryClient()
    return useMutation<any, any, AddAssetPermission>(
        async (obj) =>
            await axios.post(
                `${APi_URL}/assets/${obj.assetId}/permission`,
                obj.body,
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            ),
        {
            onSuccess: async (data: any, obj) => {
                await queryClient.invalidateQueries([
                    'assets',
                    obj.assetId,
                    'details',
                ])
                await queryClient.invalidateQueries([
                    'assets',
                    obj.assetId,
                    'permissions',
                ])
                if (props && props.onSuccess) props.onSuccess(data, obj)
            },
            onMutate: async (data) => {},
            onError: (data: any, obj: any, context: any) => {
                if (props.onError) props.onError(data, obj)
            },
            onSettled: (data: any, error, obj) => {},
        }
    )
}
