import { schema } from 'prosemirror-schema-basic'
import { assetNode } from './AssetNode'
import { Schema } from 'prosemirror-model'
import { addListNodes } from 'prosemirror-schema-list'
import { imageSpec } from './ImageNode'

const nodes = schema.spec.nodes
const nodeAdded = nodes.append({
    asset: assetNode.asset,
    image: imageSpec,
})

export const siteSchema = new Schema({
    nodes: addListNodes(nodeAdded, 'paragraph block*', 'block'),
    marks: schema.spec.marks,
})
