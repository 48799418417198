import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'

export const useDeleteDealRoomTrackEmail = (id: string) => {
    const queryClient = useQueryClient()
    return useMutation(
        (permissionId: string) =>
            axios.delete(
                `${API_URL}/dealroom/${id}/trackPermission/${permissionId}`,
                {
                    headers: {
                        'Content-type': 'application/json',
                    },
                }
            ),
        {
            onMutate: (data) => {
                queryClient.cancelQueries(['dealroom', id, 'track', 'email'])
                const email: Array<{
                    permission: string
                    DealRoomGuestEmailPermission: {
                        email: string
                    }
                }> = queryClient.getQueryData([
                    'dealroom',
                    id,
                    'track',
                    'email',
                ])
                const permissionIndex = email.findIndex((e) => e.id === data)
                if (permissionIndex === -1) {
                    return
                }
                email.splice(permissionIndex, 1)
                queryClient.setQueryData(
                    ['dealroom', id, 'track', 'email'],
                    email
                )
            },
            onSuccess: () => {
                queryClient.invalidateQueries([
                    'dealroom',
                    id,
                    'track',
                    'email',
                ])
            },
        }
    )
}
