import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useQuery } from 'react-query'

export const useVisitDetailsById = (id: string) => {
    return useQuery(['visit', id], async () => {
        const result = await axios.get(`${API_URL}/trackLink/visit/${id}`)
        return result.data
    })
}
