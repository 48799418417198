import { useDealroomHistory } from '@Common/Queries/Dealroom/GetDealroomHistory'
import { Box, Popover, Typography } from '@mui/material'

export const DealRoomHistory = (props: {
    code: any
    open: any
    setOpen: any
    anchorEl: any
}) => {
    const { id, open, setOpen, anchorEl } = props
    const dealroomHistory = useDealroomHistory(id)

    return (
        <Popover
            open={open}
            PaperProps={{
                style: {
                    width: '320px',
                    height: '328px',
                    zIndex: 2,
                },
            }}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            onClose={() => {
                setOpen((draft) => {
                    draft.openHistory = false
                })
            }}
            className="overflow-hidden"
        >
            <Box className="flex flex-col h-full w-full overflow-hidden">
                <Typography
                    variant="body2"
                    className="flex font-medium h-10"
                    sx={{ p: 2, fontWeight: 500 }}
                >
                    History
                </Typography>
                <div className="flex-1 flex-col overflow-y-auto">
                    {dealroomHistory.data?.data?.map((value, index) => {
                        return (
                            <Typography
                                sx={{ paddingX: 2, paddingY: 1 }}
                                key={index}
                            >
                                <p className="font-medium">
                                    {value.action.actionName}
                                </p>
                                <Typography variant="body2">
                                    {value.action.action}
                                </Typography>
                            </Typography>
                        )
                    })}
                </div>
            </Box>
        </Popover>
    )
}
