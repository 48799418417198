import TypographyComponent from './TypographyComponent'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { Updater } from 'use-immer'
import classNames from 'classnames'
import { Button, Chip, Tooltip } from '@mui/material'
import { twMerge } from 'tailwind-merge'
import CommentsEditor from '@Common/Components/Editor/CommentsEditor'
import { text } from 'stream/consumers'
import { useRef } from 'react'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import useAddAssetComments from '@Common/Queries/MutationQueries/AddAssetComments'

interface DisplayCommentEditorProps {
    id: string
    isVisible: Record<string, { visible: boolean; anchor: any }>
    setIsVisible: Updater<{ [x: string]: { visible: boolean; anchor: any } }>
    users: {
        data: Record<string, unknown>[]
        option: { value: string; name: string; label: string }[]
    }
    asset: {
        id: string
        name: string
    }
}

const DisplayCommentEditor = (props: DisplayCommentEditorProps) => {
    const { id, isVisible, setIsVisible, users, asset } = props
    const viewRef = useRef<any>()
    const sendDataToGTM = useGTMDispatch()
    const addComment = useAddAssetComments()

    return (
        <div
            className={classNames(
                `flex-1 h-full hover:cursor-pointer mb-4 overflow-hidden`
            )}
            onClick={(e) =>
                setIsVisible((draft) => {
                    draft['comments'] = {
                        visible: true,
                        anchor: e.currentTarget,
                    }
                })
            }
        >
            {isVisible &&
            isVisible['comments'] &&
            isVisible['comments'].anchor != null ? (
                <div>
                    <CommentsEditor
                        users={users}
                        ref={viewRef}
                        style={{ 'padding-left': '4px' }}
                    ></CommentsEditor>
                    <div className="flex pt-2 justify-end">
                        <Button
                            size="small"
                            className="mr-2"
                            onClick={() => {
                                setIsVisible((draft) => {
                                    draft[id] = {
                                        visible: false,
                                        anchor: null,
                                    }
                                })
                                // const state = viewRef.current.view.state

                                // const tr = state.tr.replaceWith(
                                //     0,
                                //     state.doc.content.size,
                                //     [
                                //         {
                                //             type: 'paragraph',

                                //             content: [
                                //                 {
                                //                     type: 'text',
                                //                     text: '',
                                //                 },
                                //             ],
                                //         },
                                //     ]
                                // )
                                // viewRef.current?.view.dispatch(tr)
                                // viewRef.current?.view.hasFocus()
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            size="small"
                            className="mr-2"
                            variant="contained"
                            onClick={() => {
                                const state = viewRef?.current.view.state
                                const tr = state.tr.replaceWith(
                                    0,
                                    state.doc.content.size,
                                    [
                                        {
                                            type: 'paragraph',

                                            content: [
                                                {
                                                    type: 'text',
                                                    text: '',
                                                },
                                            ],
                                        },
                                    ]
                                )
                                viewRef.current.view.dispatch(tr)
                                sendDataToGTM({
                                    event: 'asset_add_comment',
                                    asset_id: asset.id,
                                    asset_name: asset.name,
                                })
                                addComment.mutate({
                                    comment: JSON.stringify(
                                        viewRef.current.view.state
                                    ),
                                    assetId: asset.id,
                                })
                            }}
                        >
                            Post
                        </Button>
                    </div>
                </div>
            ) : (
                    
                <TypographyComponent
                    value={'Add a comment'}
                    className={'text-xs py-2 pl-3 hover:bg-hoverTextBackground'}
                    color={'text.disabled'}
                ></TypographyComponent>
            )}
        </div>
    )
}
export default DisplayCommentEditor
