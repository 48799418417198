import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'
import { ChangePassword } from '@Server/changePassword'
export default function useChangePassword(props: {
    onSuccess: () => void
    onError: (message: string) => void
}) {
    const queryClient = useQueryClient()
    return useMutation<any, Error, ChangePassword>(
        (obj) =>
            axios.post(`${APi_URL}/user/changePassword`, obj, {
                headers: { 'Content-Type': 'application/json' },
            }),
        {
            onSuccess: (data: any, obj: any) => {
                props.onSuccess()
            },
            onError: (error) => {
                console.log('Error: ', JSON.stringify(error))
                const response = error.response.data.error.message
                if (response === 'LimitExceededException') {
                    props.onError(
                        'Number of attempts to change password has exceeded. Try again after some time'
                    )
                }
                if (response === 'InvalidPasswordException') {
                    props.onError(
                        'Invalid New password. \n Password should contain a lower case letter, upper case letter, number, minimum 8 characters, a special character'
                    )
                }
                if (response === 'NotAuthorizedException') {
                    props.onError(
                        'Invalid Old password. Try again with right password'
                    )
                }
            },
        }
    )
}
