import { Link, useParams } from 'react-router-dom'
import OutlineSecondaryButton from '@Common/Components/Button/OutlineSecondary'
import PrimaryButton from '@Common/Components/Button/PrimaryButton'

import GlobalModal from './common/modal/GlobalModal'

import ViewAssetDetails from '@Common/Queries/GetQueries/GetAsset'
import { useImmer } from 'use-immer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import makeIcon from '@Common/utils/MakeIcon'
import useApproveAssetRequiredRequest from '@Request/query/useApproveAssetRequiredRequest'
import useUserApproveAssetRequiredRequest from '@Request/query/useUserApproveAssetRequiredRequest'
import useAssetList from '@Common/Queries/GetQueries/GetAssetsList'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import { Button } from '@mui/material'
interface NewAssetRequest {
    description: string
    id: string
    status: string
    NewAssetRequests: {
        suggestedAsset: string
    }
}
interface ApproveRequestDetailsProps {
    data: NewAssetRequest
    isAdmin: boolean
    isSameUser: boolean
}
function Asset(props: { assetId: string }) {
    const assetDetail = ViewAssetDetails({ id: props.assetId })

    if (
        assetDetail.isLoading ||
        assetDetail.isError ||
        !assetDetail.data ||
        assetDetail.data.action === 'NO_ACCESS'
    ) {
        return <div></div>
    } else {
        return (
            <Link to={`/search/viewAsset/${assetDetail.data.id}`}>
                <div className="pb-4 flex space-x-4 items-center">
                    <FontAwesomeIcon
                        icon={makeIcon(assetDetail.data.LatestFile.name).name}
                        color={makeIcon(assetDetail.data.LatestFile.name).color}
                        size="2x"
                    />
                    <p>{assetDetail.data.name}</p>
                </div>
            </Link>
        )
    }
}
export default function ApproveRequestDetails(
    props: ApproveRequestDetailsProps
) {
    const { data, isAdmin, isSameUser } = props
    const [suggestedAssets, setSuggestedAssets] = useImmer([] as Asset[])
    const [isModalOpen, setModalOpen] = useImmer({
        isOpen: false,
        type: '',
    })
    const sendDataToGTM = useGTMDispatch()

    const assetList = useAssetList()
    const approveAssetRequiredRequest = useApproveAssetRequiredRequest()
    const userApproveAssetRequiredRequest = useUserApproveAssetRequiredRequest()
    function getActionPanel() {
        if (isAdmin && data.status !== 'CLOSED') {
            return (
                <div className="py-5 gap-2 flex">
                    <Button
                        variant="contained"
                        onClick={() => {
                            sendDataToGTM({
                                event: 'asset_required_request',
                                action: 'search',
                                request_id: data.id,
                            })
                            setModalOpen((draft) => {
                                draft.isOpen = true
                                draft.type = 'search'
                            })
                        }}
                    >
                        Attach
                    </Button>
                    <Button
                        onClick={() => {
                            sendDataToGTM({
                                event: 'asset_required_request',
                                action: 'upload',
                                request_id: data.id,
                            })
                            setModalOpen((draft) => {
                                draft.isOpen = true
                                draft.type = 'add'
                            })
                        }}
                    >
                        Upload
                    </Button>
                </div>
            )
        }
        return <></>
    }
    return (
        <>
            <div className="flex max-w-[672px]">{data.description}</div>
            {getActionPanel()}
            {data.NewAssetRequests?.suggestedAsset
                ?.split(',')
                .map((assetId) => {
                    return <Asset key={assetId} assetId={assetId} />
                })}
            {!data.NewAssetRequests?.suggestedAsset &&
                suggestedAssets.map((asset) => {
                    return (
                        <div className="flex gap-2 items-center" key={asset.id}>
                            <FontAwesomeIcon
                                icon={makeIcon(asset.name).name}
                                color={makeIcon(asset.name).color}
                                size="1x"
                            />
                            {asset.name}
                        </div>
                    )
                })}
            <hr className="-mx-5" />
            {data.status !== 'CLOSED' && (isSameUser || isAdmin) && (
                <div className="py-5 gap-2 flex">
                    <Button
                        onClick={() => {
                            if (isAdmin) {
                                approveAssetRequiredRequest.mutate({
                                    requestsId: data.id,
                                    assetIds: suggestedAssets.map(
                                        (asset) => asset.id
                                    ),
                                })
                                sendDataToGTM({
                                    event: 'asset_required_request',
                                    action: 'CLOSED',
                                    request_id: data.id,
                                })

                                return
                            }

                            userApproveAssetRequiredRequest.mutate({
                                requestsId: data.id,
                            })
                        }}
                    >
                        Close Request
                    </Button>
                </div>
            )}
            {data.status === 'CLOSED' && (
                <div className="text-green-500 flex items-center h-16">
                    {' '}
                    CLOSED
                </div>
            )}
            {isModalOpen.isOpen && (
                <GlobalModal
                    modalName={isModalOpen.type}
                    setIsOpen={setModalOpen}
                    setSuggestedAssets={setSuggestedAssets}
                />
            )}
        </>
    )
}

const style = {
    container: 'w-full  outline-none px-5 rounded-3px bg-white mb-5',

    mainContainer: ' h-full   bg-bgColor  px-10 py-5 overflow-auto',
}
