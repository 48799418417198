import DialogComponent from '@Common/Components/DialogComponent'
import { Typography } from '@mui/material'

const DownloadSearchResult = ({ isOpen, setIsOpen }) => {
    return (
        <DialogComponent
            body={
                <Typography color={'text.primary'}>
                    Maximum allowed rows for download is 1000. Downloading the
                    latest 1000 results.
                </Typography>
            }
            open={isOpen}
            setOpen={setIsOpen}
            success={{
                text: 'Ok',
            }}
        ></DialogComponent>
    )
}

export default DownloadSearchResult

const style = {
    dialogContainer:
        'fixed inset-0 z-10 overflow-y-auto bg-opacity-50 bg-gray-500',
    subContainer: 'min-h-screen px-4 text-center',
    dialogOverlay: 'fixed inset-0',
    span: 'inline-block h-screen align-middle',
    dialogTitleContainer:
        'inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl',
    dialogTitle: 'text-lg font-medium leading-6 text-textColor',
    pTag: 'text-sm text-gray-600 mt-3',
    input: 'h-20 border-2 outline-none w-full mt-1 p-2',
    btnContainer: 'mt-6 flex flex-row',
    select: 'basic-multi-select border-none outline-none whitespace-nowrap text-14px font-normal font-Segoe  max-w-max',
    btnApprove: 'py-2 px-5 rounded-sm text-primary font-bold text-sm',
}
