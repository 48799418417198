import TypographyComponent from './TypographyComponent'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { Updater } from 'use-immer'
import classNames from 'classnames'
import { Chip, Tooltip } from '@mui/material'
import { twMerge } from 'tailwind-merge'
import { getRandomColor } from '@Common/Color/colors'

interface EditableComponentProps {
    id: string
    value?: string | Array<{ value: string; id: string }>
    children: any
    visible: Record<string, { visible: boolean; anchor: any }>
    setIsVisible: Updater<{ [x: string]: { visible: boolean; anchor: any } }>
    defaultValue: string
    textClassName?: string
    fontSize?: number
    className?: string
    type?: string
    options?: Array<{
        id: string
        value: string
        color: string
        attributeId: string
    }>
}
export const EditableComponent = (props: EditableComponentProps) => {
    const {
        id,
        value,
        children,
        visible,
        setIsVisible,
        defaultValue,
        fontSize,
        type,
        options,
    } = props
    const handleClickAway = (attributeId: string) => {
        setIsVisible((draft) => {
            draft[attributeId] = {
                visible: false,
                anchor: null,
            }
        })
    }
    const textValue = value
        ? typeof value !== 'object' && value.trim && value.trim() != ''
            ? value
            : defaultValue
        : defaultValue
    const color = value
        ? typeof value !== 'object' && value.trim && value.trim() != ''
            ? 'text.primary'
            : 'text.disabled'
        : 'text.disabled'
    return (
        <>
            <div
                className={classNames(
                    `flex-1 h-full w-full hover:cursor-pointer ${props.className}`,
                    {
                        'overflow-hidden':
                            visible &&
                            !visible[id]?.visible &&
                            type &&
                            type != 'chip',
                    }
                )}
                onClick={(e) =>
                    setIsVisible((draft) => {
                        draft[id] = {
                            visible: true,
                            anchor: e.currentTarget,
                        }
                    })
                }
            >
                {visible && visible[id]?.visible ? (
                    <ClickAwayListener
                        onClickAway={(e) => {
                            handleClickAway(id)
                        }}
                    >
                        <div>{children}</div>
                    </ClickAwayListener>
                ) : type && type === 'chip' && value ? (
                    <div className="py-4 pl-3 flex flex-wrap hover:bg-hoverTextBackground">
                        {value?.map(
                            (
                                eachValue: { value: string; id: string },
                                index: number
                            ) => (
                                <div key={index} className="pb-1 pr-1">
                                    <Tooltip title={eachValue.value}>
                                        <Chip
                                            label={eachValue.value}
                                            sx={{
                                                backgroundColor:
                                                    options?.find(
                                                        (e) =>
                                                            e.id ===
                                                            eachValue.id
                                                    )?.color ||
                                                    getRandomColor(),
                                            }}
                                            size="small"
                                        ></Chip>
                                    </Tooltip>
                                </div>
                            )
                        )}
                    </div>
                ) : (
                    <TypographyComponent
                        value={textValue}
                        className={`${twMerge(
                            props.textClassName,
                            'pl-2 hover:bg-hoverTextBackground'
                        )}`}
                        color={color}
                    ></TypographyComponent>
                )}
            </div>
        </>
    )
}
export default EditableComponent
