import Loading from '@Common/Components/Loading'
import { useDashboardLinks } from '@Common/Queries/Dashboard/DashboardLinks'
import { Breadcrumbs, TablePagination, Typography } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { formatDistanceToNow } from 'date-fns'
import { Link, useNavigate } from 'react-router-dom'
import { formattedDate } from 'src/ViewAssetScreen/util'
import { useImmer } from 'use-immer'

const DashboardLinks = () => {
    const [page, setPage] = useImmer(0)
    const pageSize = 10
    const links = useDashboardLinks(page, pageSize)
    const navigate = useNavigate()
    if (links.isLoading) {
        return <Loading></Loading>
    }
    const columns: GridColDef[] = [
        {
            headerName: 'Link name',
            field: 'trackName',
            width: 288,
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Typography color="text.secondary" fontWeight={500}>
                        {params.value}
                    </Typography>
                )
            },
        },

        {
            headerName: 'Asset',
            field: 'assetName',
            disableColumnMenu: true,
            minWidth: 320,
            flex: 1,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div className="flex items-center gap-2">
                        <div
                            className={`w-20 h-11 overflow-hidden border border-solid border-borderColor rounded`}
                        >
                            <img
                                src={params.row.thumbnailUrl}
                                loading="eager"
                                className="w-full h-auto"
                            ></img>
                        </div>
                        <Typography color="text.secondary" fontWeight={300}>
                            {params.row.assetName}
                        </Typography>
                    </div>
                )
            },
        },
        {
            headerName: 'Created date',
            field: 'createdAt',
            width: 192,
            sortable: false,
            disableColumnMenu: true,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Typography color="text.secondary" fontWeight={300}>
                        {params.formattedValue}
                    </Typography>
                )
            },
            valueFormatter: (params) => {
                return formattedDate(params.value)
            },
        },

        {
            headerName: 'Number of visits',
            field: 'numberOfVisits',
            disableColumnMenu: true,
            width: 192,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Typography color="text.secondary" fontWeight={300}>
                        {params.formattedValue}
                    </Typography>
                )
            },
            valueFormatter: (params) => {
                return params.value.toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                })
            },
        },
        {
            headerName: 'Last visit',
            field: 'lastEnagement',
            disableColumnMenu: true,
            width: 192,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Typography color="text.secondary" fontWeight={300}>
                        {params.formattedValue}
                    </Typography>
                )
            },
            valueFormatter: (params) => {
                return params.value?.updatedAt
                    ? formatDistanceToNow(new Date(params.value?.updatedAt), {
                          addSuffix: true,
                      })
                    : '--'
            },
        },
    ]
    const rows = links.data.trackLinks.map((link, id) => {
        return {
            id: link.id,
            index: id,
            assetId: link.AssetTrackLink.assetId,
            trackName: link.TrackLinkProperties.name,
            assetName: link.AssetTrackLink.Asset.name,
            createdAt: link.createdAt,
            numberOfVisits: link.TrackVisit.length,
            lastEnagement: link.TrackVisit[0],
            thumbnailUrl: link.thumbnailUrl,
        }
    })

    return (
        <div className="w-full h-full px-6 flex pt-4 flex-col overflow-hidden">
            <div className="h-8 w-full flex items-center">
                <Breadcrumbs>
                    <Link color="text.secondary" to="/dashboard">
                        Dashboard
                    </Link>
                    <Typography color="text.primary whitespace-nowrap text-ellipsis">
                        {'Links'}
                    </Typography>
                </Breadcrumbs>
            </div>
            <div className="w-full h-full overflow-auto">
                <DataGrid
                    rows={rows}
                    columns={columns}
                    rowHeight={64}
                    hideFooter={true}
                    onRowClick={(params) => {
                        navigate(
                            `/search/viewAsset/${params.row.assetId}/track`
                        )
                    }}
                    sx={{
                        border: 'none',
                        flex: 1,
                        '& .MuiDataGrid-row:hover': {
                            background: 'white',
                            cursor: 'pointer',
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 300,
                        },
                    }}
                ></DataGrid>
            </div>
            <TablePagination
                rowsPerPageOptions={[pageSize]}
                colSpan={3}
                component="div"
                className="text-xs text-lightTextDisabled border-none overflow-hidden"
                count={links.data.count}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={(e, page) => setPage(page)}
                backIconButtonProps={{
                    className: 'stroke-slate-50',
                }}
                nextIconButtonProps={{
                    className: 'stroke-slate-50',
                }}
            />
        </div>
    )
}
export default DashboardLinks
