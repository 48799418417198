import DialogComponent from '@Common/Components/DialogComponent'
import { useActiveDealroom } from '@Common/Queries/Dealroom/ActiveDealRoom'
import { useArchiveDealroom } from '@Common/Queries/Dealroom/ArchiveDealroom'
import { useDeleteDealroom } from '@Common/Queries/Dealroom/DeleteDealroom'
import useInit from '@Common/Queries/GetQueries/useInit'
import ArchiveIcon from '@mui/icons-material/Archive'
import DeleteIcon from '@mui/icons-material/Delete'
import HistoryIcon from '@mui/icons-material/History'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined'
import {
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Popover,
} from '@mui/material'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import DialogContentText from '@mui/material/DialogContentText'
import Typography from '@mui/material/Typography'
import React, { useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useImmer } from 'use-immer'
import { DealRoomPermission } from './DealRoomPermission'
import { DealRoomProps } from './Dealroom'
import { DealRoomHistory } from './DealroomHistory'
import DealRoomShare from './DealRoomShare'
import { useDealRoomTrackEmails } from '@Common/Queries/Dealroom/GetTrackEmails'

export const DealRoomHead = (props: {
    id: string
    dealroom: DealRoomProps
}) => {
    const { id, dealroom } = props
    const navigate = useNavigate()

    const archiveDealroom = useArchiveDealroom()
    const activeDealroom = useActiveDealroom()
    const deleteDealroom = useDeleteDealroom()

    const init = useInit()
    const dealRoomTrack = useDealRoomTrackEmails(id)

    const [menu, setMenu] = useImmer({
        open: false,
        anchorEl: null,
    })
    const [access, setAccess] = useImmer({
        openAccess: false,
        anchorEl: null,
    })
    const [history, setHistory] = useImmer({
        openHistory: false,
        anchorEl: null,
    })
    const shareRef = useRef(null)

    const [openDelete, setOpenDelete] = useImmer(false)
    const [openArchive, setOpenArchive] = useImmer(false)
    const [share, setShare] = useImmer(false)

    const handleMoreVertClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setMenu((draft) => {
            draft.open = true
            draft.anchorEl = event.currentTarget
        })
    }
    const handleHistory = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenu((draft) => {
            draft.open = false
            draft.anchorEl = null
        })
        setHistory((draft) => {
            draft.openHistory = true
            draft.anchorEl = event.currentTarget
        })
    }
    const handleAccess = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenu((draft) => {
            draft.open = false
            draft.anchorEl = null
        })
        setAccess((draft) => {
            draft.anchorEl = event.currentTarget
            draft.openAccess = true
        })
    }
    const handleArchice = () => {
        setMenu((draft) => {
            draft.open = false
            draft.anchorEl = null
        })
        setOpenArchive(true)
    }
    const handleActive = () => {
        setMenu((draft) => {
            draft.open = false
            draft.anchorEl = null
        })
        setOpenArchive(true)
    }
    const handleDelete = () => {
        setMenu((draft) => {
            draft.open = false
            draft.anchorEl = null
        })
        setOpenDelete(true)
    }
    const properties = dealroom.DealRoomProperties

    return (
        <>
            <Popover
                open={access.openAccess}
                anchorEl={access.anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClose={() => {
                    setAccess((draft) => {
                        draft.openAccess = false
                    })
                }}
            >
                <DealRoomPermission dealRoom={dealroom}></DealRoomPermission>
            </Popover>
            <DialogComponent
                open={openArchive}
                setOpen={setOpenArchive}
                PaperProps={{
                    sx: {
                        width: '444px',
                    },
                }}
                title={dealroom.status === 'ACTIVE' ? 'Archive' : 'Active'}
                body={
                    <DialogContentText>
                        You are about to{' '}
                        {dealroom.status === 'ACTIVE' ? 'archive' : 'active'}{' '}
                        {properties.name}
                    </DialogContentText>
                }
                cancel={{
                    text: 'Cancel',
                    variant: 'text',
                }}
                success={{
                    text: dealroom.status === 'ACTIVE' ? 'Archive' : 'Active',
                    color: 'success',
                    variant: 'text',
                }}
                handleChange={() => {
                    if (dealroom.status === 'ACTIVE') {
                        archiveDealroom.mutate({
                            dealRoomId: dealroom.id,
                        })
                        setOpenArchive(false)
                    } else {
                        activeDealroom.mutate({
                            dealRoomId: id,
                        })
                        setOpenArchive(false)
                    }
                }}
            ></DialogComponent>

            <DialogComponent
                open={openDelete}
                setOpen={setOpenDelete}
                PaperProps={{
                    sx: {
                        width: '444px',
                    },
                }}
                title={'Delete'}
                body={
                    <DialogContentText>
                        Are you sure. You want to delete {properties.name}
                    </DialogContentText>
                }
                cancel={{
                    text: 'Cancel',
                    variant: 'text',
                }}
                success={{
                    text: 'Delete',
                    color: 'error',
                    variant: 'text',
                }}
                handleChange={() => {
                    deleteDealroom.mutate({
                        dealRoomId: id,
                    })
                    navigate('/dealroom')
                }}
            ></DialogComponent>

            <DealRoomHistory
                id={id}
                open={history.openHistory}
                setOpen={setHistory}
                anchorEl={history.anchorEl}
            />
            <Menu
                open={menu.open}
                anchorEl={menu.anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={() => {
                    setMenu((draft) => {
                        draft.open = false
                    })
                }}
            >
                <MenuItem onClick={(e) => handleHistory(e)}>
                    <div className="flex flex-row pr-2">
                        <div>
                            <ListItemIcon>
                                <HistoryIcon fontSize="small" />
                            </ListItemIcon>
                        </div>
                        <div className="flex-1">History</div>
                    </div>
                </MenuItem>
                {['FULL_ACCESS', 'EDIT'].includes(dealroom.action) &&
                    (dealroom.status === 'ACTIVE' ? (
                        <MenuItem
                            data-testid="dealroom-archive-btn"
                            onClick={() => handleArchice()}
                        >
                            <div className="flex flex-row pr-2">
                                <div>
                                    <ListItemIcon>
                                        <ArchiveIcon fontSize="small" />
                                    </ListItemIcon>
                                </div>
                                <div className="flex-1"> Move to archive</div>
                            </div>
                        </MenuItem>
                    ) : (
                        <MenuItem
                            data-testid="dealroom-archive-btn"
                            onClick={() => handleActive()}
                        >
                            <div className="flex flex-row pr-2">
                                <div>
                                    <ListItemIcon>
                                        <ArchiveIcon fontSize="small" />
                                    </ListItemIcon>
                                </div>
                                <div className="flex-1">Move to active</div>
                            </div>
                        </MenuItem>
                    ))}
                {['FULL_ACCESS', 'EDIT'].includes(dealroom.action) && (
                    <MenuItem
                        onClick={handleDelete}
                        data-testid="dealroom-delete-btn"
                    >
                        <div className="flex flex-row pr-2">
                            <div>
                                <ListItemIcon>
                                    <DeleteIcon fontSize="small" />
                                </ListItemIcon>
                            </div>
                            <div className="flex-1">Delete</div>
                        </div>
                    </MenuItem>
                )}
            </Menu>
            <Popover
                open={share}
                anchorEl={shareRef.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClose={() => setShare(false)}
            >
                <DealRoomShare
                    id={props.id}
                    code={props.dealroom.code}
                    setOpen={setShare}
                    dealroom={dealroom}
                ></DealRoomShare>
            </Popover>
            <div className="flex w-full h-16 justify-between px-6 py-4 items-center">
                <Breadcrumbs>
                    <Link color="text.secondary" to="/dealroom">
                        Dealroom
                    </Link>
                    <Typography
                        color="text.primary"
                        className="overflow-hidden whitespace-nowrap text-ellipsis"
                    >
                        {properties.name}
                    </Typography>
                    {dealroom.status === 'ARCHIVED' && (
                        <Typography color="success">(Archived)</Typography>
                    )}
                </Breadcrumbs>

                <div className="flex gap-1">
                    <IconButton
                        size="small"
                        ref={shareRef}
                        data-testid="dealroom-share-btn"
                    >
                        <ShareOutlinedIcon
                            fontSize="small"
                            onClick={(e) => {
                                setShare(true)
                            }}
                        ></ShareOutlinedIcon>
                    </IconButton>
                    {['FULL_ACCESS', 'EDIT'].includes(dealroom.action) && (
                        <IconButton
                            size="small"
                            onClick={handleAccess}
                            data-testid="dealroom-permission-btn"
                        >
                            <PersonOutlinedIcon fontSize="small"></PersonOutlinedIcon>
                        </IconButton>
                    )}
                    <IconButton
                        size="small"
                        onClick={handleMoreVertClick}
                        data-testid="dealroom-more-btn"
                    >
                        <MoreVertOutlinedIcon fontSize="small"></MoreVertOutlinedIcon>
                    </IconButton>
                </div>
            </div>
        </>
    )
}
