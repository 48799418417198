import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'
import { CreateWorkspace } from '@Server/createWorkspace'
import { clearUserData } from '@Common/Components/Feature/useFeatures'

export default function useCreateAndJoinWorkspace(props?: {
    onSuccess: () => void
}) {
    const queryClient = useQueryClient()
    return useMutation<any, Error, CreateWorkspace>(
        (obj) =>
            axios.post(`${APi_URL}/workspace/createAndJoin`, obj, {
                headers: { 'Content-Type': 'application/json' },
            }),
        {
            onSuccess: (result) => {
                clearUserData()
                props?.onSuccess()
                window.location.href = '/'
            },
        }
    )
}
