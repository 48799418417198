import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useQuery } from 'react-query'

export default function useApprovalData(props: any) {
    return useQuery(
        ['approval', 'search', props],
        async () => {
            const result = await axios.post(
                `${API_URL}/approvals/search`,
                props,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            return result.data
        },
        {
            keepPreviousData: true,
        }
    )
}
