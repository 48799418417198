import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import useInit from '../GetQueries/useInit'

export const useCreateDealRoomSubComments = (dealRoomId: string) => {
    const queryClient = useQueryClient()
    const init = useInit()
    return useMutation(
        (data: { comment: string; commentId: string }) =>
            axios.post(
                `${API_URL}/dealroom/${dealRoomId}/comment/${data.commentId}`,
                { comment: data.comment },
                {
                    headers: {
                        'Content-type': 'application/json',
                    },
                }
            ),
        {
            onMutate: (data) => {
                queryClient.cancelQueries(['dealroom', dealRoomId, 'comments'])
                const comments: Array<any> = queryClient.getQueryData([
                    'dealroom',
                    dealRoomId,
                    'comments',
                ])
                if (!comments) return
                const commentIndex = comments.findIndex(
                    (e) => e.id === data.commentId
                )
                if (commentIndex === -1) {
                    return
                }
                const comment = comments[commentIndex]
                const children = []
                children.push({
                    User: {
                        name: init.data.user.name,
                        id: init.data.user.id,
                    },
                    updatedAt: new Date(),
                    comment: JSON.parse(data.comment),
                    children: [],
                    parentId: null,
                })
                comment.children = children
                comments[commentIndex] = comment
                queryClient.setQueryData(
                    ['dealroom', dealRoomId, 'comments'],
                    comments
                )
            },

            onSuccess: (data) => {
                queryClient.invalidateQueries([
                    'dealroom',
                    data.data.id,
                    'comments',
                ])
                queryClient.invalidateQueries(['dealroom', 'search'])
            },
        }
    )
}
