import { useQuery } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'
import { UserIcon } from '@heroicons/react/outline'

const getTheme = async () => {
    const { data } = await axios.get(`${APi_URL}/theme`, {
        headers: { 'Content-Type': 'application/json' },
    })
    return data
}

export default function useTheme(props?: { onSuccess: (data: any) => void }) {
    return useQuery('theme', () => getTheme(), {
        onSuccess: props?.onSuccess,
    })
}
