import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import { Fragment } from 'react'
import { UseQueryResult } from 'react-query'
import { useAccounts } from 'src/Accounts/useAccounts'
import { useContacts } from 'src/Contacts/useContacts'
import { useImmer } from 'use-immer'
interface UserDropDownProps {
    label?: string
    value?: string
    defaultValue: string | Array<any>
    disableCloseOnSelect?: boolean
    multiple?: boolean
    freeSolo?: boolean
    open?: boolean
    onChange?: (value: string | string[] | null, reason: string) => void
}
function getOptions(accounts: UseQueryResult<any, unknown>) {
    if (accounts.isLoading) {
        return []
    }
    const userTableMeta = accounts.data.userTable.UserTableMeta
    const primary = userTableMeta.find(
        (column) => column.columnName === 'Contact'
    )
    const options = accounts.data.rows.data.map((row) => ({
        value: row.id,
        label: row[primary.id],
    }))
    return options
}

export function Contacts(props: UserDropDownProps) {
    const [inputValue, setInputValue] = useImmer('')
    const accounts = useContacts({ pageSize: 10, page: 0, search: inputValue })
    return (
        <Autocomplete
            className="w-auto"
            loading={accounts.isLoading}
            open={props.open}
            multiple={props.multiple}
            getOptionLabel={(option) =>
                option.label !== undefined ? option.label : option
            }
            value={props.value}
            defaultValue={
                props.defaultValue
                    ? props.multiple
                        ? [props.defaultValue]
                        : props.defaultValue
                    : props.multiple
                    ? []
                    : null
            }
            options={getOptions(accounts)}
            filterOptions={(x) => x}
            disableCloseOnSelect={props.disableCloseOnSelect}
            freeSolo={props.freeSolo}
            onChange={(e, newValue, reason) => {
                if (props.onChange) {
                    props.onChange(newValue, reason)
                }
            }}
            onInputChange={(e, newInputValue) => {
                setInputValue(newInputValue)
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder="Enter email and hit enter"
                    label={props.label}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <Fragment>
                                {accounts.isLoading || accounts.isFetching ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </Fragment>
                        ),
                    }}
                />
            )}
        />
    )
}
