import Loading from '@Common/Components/Loading'
import CreateGroupModal from '@Common/Components/Modals/CreateGroupModal'
import GroupDropDown from '@Common/Components/Modals/GroupDropDown'
import ValidationModal from '@Common/Components/ValidationModal'
import Pagination from '@Common/Pagination'
import useInit from '@Common/Queries/GetQueries/useInit'
import useDeleteGroup from '@Common/Queries/MutationQueries/DeleteGroup'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { useImmer } from 'use-immer'

const Groups = (props: any) => {
    const [openDropDown, setopenDropDown] = useState({
        isOpen: false,
        index: 0,
    })

    const [manageGroup, setManageGroup] = useState({
        isOpen: false,
        data: {},
        type: '',
    })

    const [conformingDeletion, setConformingDeletion] = useState({
        deleteFor: '',
        message: '',
        isOpen: false,
        data: {},
        type: '',
        approve: false,
    })

    const [inputData, setInputData] = useState({
        name: '',
        description: '',
        users: [],
        isOpen: false,
        message: '',
        type: 'alert',
    })
    const [selected, setSelected] = useImmer({
        selectAll: false,
        selectGlobal: false,
        selectedRow: [],
    })
    const groupsData = props.groupsData

    //const { data: groupsData, refetch: groups, isLoading: groupsLoading } = useGroups();
    const deleteGroup = useDeleteGroup()

    const dateDisplay = (dates: any) => {
        const date = new Date(dates)
        return format(date, 'MMM dd, yyyy')
    }

    const userCheckingGroups = (data: object, details: object, i: any) => {
        switch (i >= 0) {
            case i < 3:
                return (
                    <div className="bg-gray-300 self-center flex justify-center h-10 w-10 rounded-full mr-1">
                        <span
                            className="text-black self-center flex uppercase"
                            data-tip={details.user.name}
                            data-delay-show={'200'}
                        >
                            {details.user.name.slice(0, 2)}
                        </span>
                    </div>
                )
            case i == 4:
                return (
                    <>
                        <div className="bg-gray-300 self-center flex justify-center h-10 w-10 rounded-full mr-1  ">
                            <span
                                className="text-black self-center flex"
                                data-tip={data.GroupUsers.map(
                                    (
                                        users: { user: { name: any } },
                                        i: number
                                    ) => {
                                        if (i >= 3) {
                                            return users.user.name
                                        }
                                    }
                                )}
                                data-delay-show={'200'}
                            >
                                +
                                {data?.GroupUsers?.length > 3 &&
                                    data?.GroupUsers?.length - 3}
                            </span>
                        </div>
                    </>
                )
        }
    }

    useEffect(() => {
        // for deleting the groups

        if (conformingDeletion.approve) {
            deleteGroup.mutate(conformingDeletion.data.id)
        }
    }, [conformingDeletion.approve])

    const loadingPage = () => {
        return (
            <div className="w h m-auto">
                <Loading />
            </div>
        )
    }
    const init = useInit()

    const userRoles = init.data.user.UserRole.map((userRole) =>
        userRole.role.role.toLowerCase()
    )

    const isAdmin =
        userRoles.includes(import.meta.env.VITE_SUPER_ADMIN_USER) ||
        userRoles.includes(import.meta.env.VITE_ADMIN_USER)

    return (
        <>
            {manageGroup.isOpen && (
                <CreateGroupModal
                    setManageGroup={setManageGroup}
                    manageGroup={manageGroup}
                />
            )}
            {conformingDeletion.isOpen && (
                <ValidationModal
                    setConformingDeletion={setConformingDeletion}
                    conformingDeletion={conformingDeletion}
                />
            )}
            {props.groupsLoading ? (
                loadingPage()
            ) : (
                <div className={`${style.tableContainer} `}>
                    <div className={style.tableHead}>
                        <div
                            className={`${style.tableRowCol} tracking-wider pl-3 col-span-1`}
                        >
                            <h6 className={style.contentRowCol}>Group Name</h6>
                        </div>
                        <div className={style.tableRowCol}>
                            <h6 className={style.contentRowCol}>Description</h6>
                        </div>
                        <div className={style.tableRowCol}>
                            <h6
                                className={`${style.contentRowCol} whitespace-nowrap `}
                            >
                                Date
                            </h6>
                        </div>
                        <div className={`${style.tableRowCol} ml-2`}>
                            <h6 className={style.contentRowCol}>Users</h6>
                        </div>
                        <div className={`${style.tableRowCol}`}>
                            <h6 className={style.contentRowCol}></h6>
                        </div>
                    </div>
                    <div className={`${style.bodyContainer} `}>
                        {groupsData && groupsData.count == 0 && (
                            <div className="text-red-500">
                                No groups found matching the criteria
                            </div>
                        )}
                        {groupsData &&
                            groupsData.groups?.map((data: any, i: any) => (
                                <div
                                    className={`${style.tableRow} ${
                                        i % 2 === 0 && 'bg-tableBackgroundColor'
                                    }`}
                                    key={i}
                                >
                                    <div
                                        className={`${style.nameContainer}`}
                                        onClick={() => {}}
                                    >
                                        <span className="self-center lg:truncate xl:text-ellipsis">
                                            {data.name}
                                        </span>
                                    </div>
                                    <div
                                        className={style.assetType}
                                        onClick={() => {}}
                                    >
                                        <span className="self-center lg:truncate xl:text-ellipsis">
                                            {data.description}
                                        </span>
                                    </div>
                                    <div
                                        className={style.createdDate}
                                        onClick={() => {}}
                                    >
                                        <span className="self-center">
                                            {dateDisplay(data.createdAt)}
                                        </span>
                                    </div>
                                    <div
                                        className={style.ownerName}
                                        onClick={() => {}}
                                    >
                                        {data?.GroupUsers?.length > 0 &&
                                            data?.GroupUsers.map(
                                                (details: object, index: any) =>
                                                    userCheckingGroups(
                                                        data,
                                                        details,
                                                        index
                                                    )
                                            )}
                                    </div>
                                    {isAdmin && (
                                        <div
                                            className={style.statusContainer}
                                            onClick={() => {
                                                openDropDown.isOpen =
                                                    !openDropDown.isOpen
                                                openDropDown.index = i
                                                setopenDropDown({
                                                    ...openDropDown,
                                                })
                                            }}
                                        >
                                            <GroupDropDown
                                                i={i}
                                                openDropDown={openDropDown}
                                                setConformingDeletion={
                                                    setConformingDeletion
                                                }
                                                conformingDeletion={
                                                    conformingDeletion
                                                }
                                                data={data}
                                                setManageGroup={setManageGroup}
                                                manageGroup={manageGroup}
                                            />
                                        </div>
                                    )}
                                </div>
                            ))}
                    </div>
                    <Pagination
                        currentPage={props.filter.currentPage}
                        setFilter={props.setFilter}
                        total={groupsData?.count}
                        pageSize={10}
                        setSelected={setSelected}
                        resultsPerPage={10}
                    />

                    <ReactTooltip place="bottom" effect="solid" />
                </div>
            )}
        </>
    )
}

export default Groups

// const style = {

// }

const style = {
    mainContainer:
        ' h-full flex flex-col overflow-hidden bg-bgColor px-4 pt-4 rounded-md font-Segoe',
    display: ' h-full flex flex-col overflow-hidden rounded-md ',
    // mainContainer: "flex flex-col px-2 h-full overflow-hidden ",
    subContainer: ' h-full px-4 sm:-mx-6 lg:-mx-8',
    tableMainContainer:
        'align-middle inline-block min-w-full h-full sm:px-4 lg:px-4',
    tableHeader: ' h-full  sm:rounded-lg',
    tableContainer: 'min-w-full flex flex-col h-full overflow-hidden',
    head: 'px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider',
    tableDiv: 'px-6 py-2  cursor-pointer',
    individualContentContainer: 'flex items-center pl-3',
    img: 'flex-shrink-0 h-26px w-30px cursor-pointer pl-3',
    name: ' font-Segoe font-semibold  text-themeColor cursor-pointer truncate',
    type: ' font-Segoe whitespace-nowrap font-normal text-themeColor cursor-pointer',
    common: 'px-6 py-2  font-Segoe font-normal text-themeColor cursor-pointer',
    download: 'px-6 py-2  text-right  cursor-pointer',
    aTag: 'text-indigo-600 hover:text-indigo-900 pr-2 cursor-pointer',
    didnot: 'text-12px font-Segoe font-normal ml-12 text-didnt cursor-pointer',
    cloudIcon: 'w-4 h-4 text-green-600',
    lockIcon: 'w-4 h-4 text-red-600',
    p: 'text-base text-red-600 mt-4 ml-3',

    tableHead:
        'h-5 mb-1 pb-8  grid grid-cols-5 gap-3 bg-white py-3 rounded-3px border-b',
    tableRowCol: 'columns-md text-left w-full',
    contentRowCol:
        'text-14px font-normal text-lightTextColor capitalize font-semibold tracking-wider',
    main: 'flex flex-col px-10 mt-6 bg-white ',
    subMain: '-my-2  sm:-mx-6 lg:-mx-8',
    contentContainer: '  border-b border-gray-200 sm:rounded-lg',
    discloser: 'flex flex-row justify-around pb-3 w-full ',
    paragraph: 'text-sm text-gray-700 font-medium',
    statusContainer:
        'columns-md whitespace-nowrap text-left pl-3 w-full py-2 flex w-full align-middle justify-center flex-row  font-Segoe text-themeColor',
    indicator: 'w-4 h-4  rounded-xl mr-3',
    disclosurePanel:
        'px-4 pt-4 pb-2 bg-toggleBgColor   grid grid-cols-2  flex flex-row justify-start rounded',
    requestTypeTxt: 'text-sm  font-medium',
    disclosurePanelContainer: 'mr-10',
    addNoteLabel: 'text-gray-400 mb-2 text-sm',
    addNoteInput: 'bg-white border rounded outline-none h-20 w-full px-2',
    btnContainer: 'flex flex-row mt-8',
    btnApprove:
        'py-1 px-3 text-white bg-gradient-to-r from-primary to-gradient  rounded-sm text-sm font-semibold ',
    btnReject:
        'py-1 px-4 rounded-sm border bg-white text-primary border-gradient font-semibold text-sm ml-2',
    caseDetailsContainer: 'px-16 ',
    caseSeatilsTxt: 'text-gray-400 text-sm',
    caseDetailsContent:
        'mt-3  overflow-auto text-justify h-20 text-sm text-gray-600',
    title: 'text-sm text-gray-600 mt-3',

    bodyContainer: 'bg-white mb-2  w-full flex-none h-2/3 overflow-auto',
    tableRow:
        'grid grid-cols-5 gap-3  tracking-wider  w-full bg-white mb-2  w-full flex-1 overflow-auto relative overflow-visible',
    nameContainer:
        ' text-left w-full flex lg:truncate xl:text-ellipsis xl:overflow-hidden w-full  py-1 col-span-1 pl-3',
    assetName:
        ' font-Segoe font-semibold  text-themeColor cursor-pointer  xl:text-ellipsis xl:overflow-hidden',
    assetType:
        ' xl:whitespace-nowrap flex text-left w-full pl-1 py-2  font-Segoe lg:truncate xl:text-ellipsis font-normal text-themeColor cursor-pointer',
    createdDate:
        'xl:whitespace-nowrap text-left w-full pl-2 py-2  flex align-middle  font-Segoe whitespace-nowrap font-normal text-themeColor cursor-pointer',
    ownerName:
        'xl:whitespace-nowrap text-left flex flex-row w-full py-2  font-Segoe text-themeColor cursor-pointer pl-4 ',
    lockRDownload: 'xl:whitespace-nowrap text-center w-full  py-2',

    loading:
        'w-full h-full flex  justify-center items-center bg-white shadow-md mb-4',
    searchTable:
        'w-full h-full flex flex-col overflow-hidden pt-2 bg-white shadow-md mb-4 rounded-3px',
    select: 'basic-multi-select border-none outline-none whitespace-nowrap text-14px font-normal font-Segoe  max-w-max rounded-3px',
    totalSearch:
        'text-searchPlaceholderColor whitespace-nowrap  pt-3 pb-2 text-14px font-Segoe font-normal self-center ml-auto',
}
