import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { APi_URL } from "@Common/Types/configs/urlConfig";

export default function useDeleteGroup() {
    const queryClient = useQueryClient()
    return useMutation<any, Error, object>((obj: any) => axios.delete(
        `${APi_URL}/groups/${obj}`,
        obj),
        {
            onSuccess: (data: any, obj: any) => {
                queryClient.invalidateQueries("Groups")
            },
        });
}