import React, { useEffect, useState } from 'react'
import { useLocation, Outlet, useNavigate, NavLink } from 'react-router-dom'
import PageHeader from '@Common/Components/PageHeader/PageHeader'
import Select from 'react-select'
import useCustomAttribute from '@Common/Queries/GetQueries/GetCustomAttributes'
import useCustomAttributeType from '@Common/Queries/GetQueries/GetCustomAttributesType'
import useDeleteCustomAttributeValue from '@Common/Queries/MutationQueries/DeleteCustomAttributeValue'
import useCustomAttributeField from '@Common/Queries/MutationQueries/updateCustomAttributeField'
import useDeleteCustomAttribute from '@Common/Queries/MutationQueries/DeleteCustomAttribute'
import useCreateCustomAttrValues from '@Common/Queries/MutationQueries/CreateCutomAttrValues'
import useCustomAttributeMutate from '@Common/Queries/MutationQueries/CreateNewCustomAttr'
import useCustomAttributeValue from '@Common/Queries/GetQueries/GetCustomAttributeValue'
import useCustomAttributeValues from '@Common/Queries/MutationQueries/GetCustomAttributeValue'
import ErrorModal from '@Common/Components/Modals/ErrorModal'
import ValidationModal from '@Common/Components/ValidationModal'
import { useImmer } from 'use-immer'
import toast from 'react-hot-toast'
import Loading from '@Common/Components/Loading'
import { Button, Checkbox, TextField, Tooltip, Typography } from '@mui/material'
import useInit from '@Common/Queries/GetQueries/useInit'
import InfoIcon from '@mui/icons-material/Info'

const CustomAttributeList: React.FC = (props: any) => {
    const [data, setData] = useState({})
    const [active, setActive] = useState<number>()
    const [addValue, setAddValue] = useState(false)

    const [NewAttValueInput, setNewAttValueInput] = useState('')
    const [conformingDeletion, setConformingDeletion] = useState({
        deleteFor: '',
        message: '',
        isOpen: false,
        data: {},
        type: '',
        approve: false,
    })
    const [customAttributesDetails, setCustomAttributesDetails] = useImmer({
        name: '',
        typeId: '',
        sensitive: false,
    })
    const [isOpen, setIsOpen] = useState(false)
    const [message, setMessage] = useState('')
    const {
        data: customAttributesData,
        refetch: customAttribute,
        isLoading: customAttrData,
    } = useCustomAttribute()
    const {
        data: customAttributesTypeData,
        refetch: customAttributeType,
        isLoading: customAttrType,
    } = useCustomAttributeType({})
    useEffect(() => {
        if (
            conformingDeletion.approve &&
            conformingDeletion.deleteFor === 'attributeValue'
        ) {
            deleteCustomAttVal.mutate(conformingDeletion.data)
        } else if (
            conformingDeletion.approve &&
            conformingDeletion.deleteFor === 'customAttribute'
        ) {
            deleteCustomAtt.mutate(data)
        }
    }, [conformingDeletion.approve])

    useEffect(() => {
        if (props.newAttributeCreation) {
            setData({})
        }
        setCustomAttributesDetails({
            name: '',
            typeId: '',
            sensitive: false,
        })
        setShowCreateOption(false)
        setActive(null)
        customAttributeValue.reset()
    }, [props.newAttributeCreation])
    const { showCreateOption, setShowCreateOption } = props

    const deleteCustomAttrValue = (res: any) => {
        customAttributeValue.mutate(res.data.attributeId)
        setNewAttValueInput('')
        conformingDeletion.approve = false
        conformingDeletion.data = {}
        conformingDeletion.type = ''
        setConformingDeletion({ ...conformingDeletion })
    }

    const createCustomAttValues = (res: any) => {
        customAttributeValue.mutate(res?.data[0]?.attributeId)
        setNewAttValueInput('')
        setAddValue(!addValue)
    }

    const createCustomAttributeValueError = () => {
        setIsOpen(true)
        setMessage('Something went wrong')
    }

    const createCustomAttError = () => {
        setIsOpen(true)
        setMessage('Something went wrong')
    }
    const createCustomAttrSuccess = (res: any) => {
        props.newCustomAttrValueDraft.data.map((values: any, i: any) => {
            const obj = {
                id: res?.data?.id,
                body: values,
            }
            createNewAttrValue.mutate(obj)
        })

        props.setNewCustomAttrValueDraft((draft: { data: never[] }) => {
            draft.data = []
        })

        setCustomAttributesDetails({
            name: '',
            typeId: '',
            sensitive: false,
        })
        toast.success('Custom attribute created successfully')
        props.setShowForm(false)
    }

    const updateCustomAttr = () => {
        setCustomAttributesDetails({
            name: '',
            typeId: '',
            sensitive: false,
        })
        toast.success('Custom attribute updated successfully')
        props.setShowForm(false)
    }

    const onDeleteCustomAttSuccess = () => {
        conformingDeletion.approve = false
        setConformingDeletion({ ...conformingDeletion })
        setCustomAttributesDetails({
            name: '',
            typeId: '',
            sensitive: false,
        })
        toast.success('Custom attribute deleted successfully')
    }

    const onDeleteCustomAttError = () => {
        conformingDeletion.approve = false
        setConformingDeletion({ ...conformingDeletion })
        toast.error('Error in deleting custom attribute')
    }

    const deleteCustomAttrValueError = () => {
        conformingDeletion.approve = false
        setConformingDeletion({ ...conformingDeletion })
    }

    const createNewAttrValue = useCreateCustomAttrValues({
        onSuccess: createCustomAttValues,
        onError: createCustomAttributeValueError,
    })
    const createNewCustomAttr = useCustomAttributeMutate({
        onError: createCustomAttError,
        onSuccess: createCustomAttrSuccess,
    })
    const deleteCustomAttVal = useDeleteCustomAttributeValue({
        onSuccess: deleteCustomAttrValue,
        onError: deleteCustomAttrValueError,
    })
    const updateCustomAttField = useCustomAttributeField({
        onSuccess: updateCustomAttr,
    })
    const deleteCustomAtt = useDeleteCustomAttribute({
        onSuccess: onDeleteCustomAttSuccess,
        onError: onDeleteCustomAttError,
    })
    const customAttributeValue = useCustomAttributeValues()

    const onChangeHandlerTypes = (events: any, type: any) => {
        if (
            events.label === 'Multi-select' ||
            events.label === 'Single-select'
        ) {
            setShowCreateOption(true)
        } else if (events.label === 'Text') {
            setShowCreateOption(false)
        } else {
            // customAttributesDetails.typeId = events.value
            // setCustomAttributesDetails({ ...customAttributesDetails })
            setShowCreateOption(false)
        }
        //customAttributesDetails.typeId = events.value
        setCustomAttributesDetails((draft) => {
            draft.typeId = events.value
        })
    }
    const init = useInit()

    const userRoles = init.data.user.UserRole.map((userRole) =>
        userRole.role.role.toLowerCase()
    )
    const isAdmin =
        userRoles.includes(import.meta.env.VITE_SUPER_ADMIN_USER) ||
        userRoles.includes(import.meta.env.VITE_ADMIN_USER)

    const onAttributeClicked = (customAttr: any, i: any) => {
        if (isAdmin) {
            setData(customAttr)
            props.setShowForm(true)
            props.setAttr(false)
            setCustomAttributesDetails((draft) => {
                return {
                    name: customAttr.name,
                    typeId: customAttr.typeId,
                    sensitive: customAttr.sensitive,
                }
            })

            const value = customAttributesTypeData?.option.find(
                (attr: any) => attr.value === customAttr.typeId
            )
            customAttributeValue.mutate(customAttr.id)
            if (
                value?.label === 'Multi-select' ||
                value?.label === 'Single-select'
            ) {
                setShowCreateOption(true)
            } else if (
                value?.label === 'Date' ||
                value?.label === 'Number' ||
                value?.label === 'Tag'
            ) {
                setShowCreateOption(false)
            } else {
                setShowCreateOption(false)
            }

            setActive(i)
        }
    }

    const valueManagement = () => {
        if (customAttributesDetails.name === '') {
            conformingDeletion.isOpen = true
            conformingDeletion.message = 'Attribute name is mandatory'
            conformingDeletion.data = {}
            conformingDeletion.type = 'nameValidate'
            setConformingDeletion({ ...conformingDeletion })
            return
        }
        if (!props.newAttributeCreation) {
            const body = {
                id: data.id,
                body: customAttributesDetails,
            }
            updateCustomAttField.mutate(body)
        } else {
            createNewCustomAttr.mutate(customAttributesDetails)
        }
    }

    const loadingPage = () => {
        return (
            <div className=" m-auto">
                <Loading />
            </div>
        )
    }

    const removeNewlyCreatedAttr = (values: any, i: any) => {
        const finalData = props.newCustomAttrValueDraft?.data?.filter(
            (data) => values.value !== data.value
        )

        props.setNewCustomAttrValueDraft((draft: { data: never[] }) => {
            draft.data = finalData
        })
    }

    const onAddBtnClicked = () => {
        // if (NewAttValueInput === "") {
        const body = {
            value: NewAttValueInput,
            default: false,
        }
        const obj = {
            id: data.id,
            body: body,
        }
        if (props.newAttributeCreation) {
            props.setNewCustomAttrValueDraft((draft: any) => {
                draft.data.push(body)
            })
            setNewAttValueInput('')
        } else {
            createNewAttrValue.mutate(obj)
        }
        // }
    }

    return (
        <>
            {conformingDeletion.isOpen && (
                <ValidationModal
                    setConformingDeletion={setConformingDeletion}
                    conformingDeletion={conformingDeletion}
                />
            )}
            {isOpen && <ErrorModal isOpen={setIsOpen} message={message} />}
            {customAttrData || customAttrType ? (
                loadingPage()
            ) : (
                <div className="grid grid-cols-1 lg:grid-cols-4 gap-10 w-full h-full overflow-hidden mb-2">
                    {
                        <div className="lg:col-span-2 mt-4 overflow-auto">
                            {customAttributesData?.data?.map(
                                (customAttr: any, i: number | undefined) => (
                                    <div
                                        key={i}
                                        onClick={() =>
                                            onAttributeClicked(customAttr, i)
                                        }
                                        className={`cursor-pointer ${
                                            i === active
                                                ? 'border-l-4 border-blue-600 bg-toggleBtn'
                                                : 'border-l-4 border-white'
                                        } h-15 py-3 self-center`}
                                    >
                                        <p className="self-center pl-1">
                                            {customAttr.name}
                                        </p>
                                    </div>
                                )
                            )}
                        </div>
                    }
                    {props.showForm && (
                        <div className="lg:col-span-2 mt-2 pr-3 overflow-auto w-full h-full">
                            <div className="flex">
                                <span className="pr-4 whitespace-nowrap self-center text-sm">
                                    Attribute Name
                                </span>
                                <input
                                    onChange={(e) => {
                                        setCustomAttributesDetails((draft) => {
                                            draft.name = e.target.value
                                        })
                                    }}
                                    className="border h-9 w-full ml-1 rounded pl-2"
                                    value={customAttributesDetails.name}
                                />
                            </div>
                            <div className="flex mt-4">
                                <span className="pr-4 whitespace-nowrap self-center text-sm">
                                    Attribute Type
                                </span>
                                <Select
                                    isDisabled={
                                        props.newAttributeCreation
                                            ? false
                                            : true
                                    }
                                    value={
                                        customAttributesTypeData?.option.find(
                                            (attr: any) =>
                                                attr.value ===
                                                customAttributesDetails?.typeId
                                        ) || null
                                    }
                                    className="w-full ml-3"
                                    options={customAttributesTypeData?.option}
                                    onChange={onChangeHandlerTypes}
                                />
                            </div>

                            {showCreateOption && (
                                <>
                                    <div className="border border-activeBorderColor flex flex-col rounded-sm h-auto p-4 mt-4">
                                        <div className="flex w-full h-full flex-col">
                                            <div className="flex h-7">
                                                <p className="text-xs mr-8  text-xs ">
                                                    Dropdown options
                                                </p>
                                                {(createNewAttrValue.isLoading ||
                                                    deleteCustomAttVal.isLoading) && (
                                                    <div>
                                                        <Loading size="1.5rem" />
                                                    </div>
                                                )}
                                            </div>
                                            <div className="flex flex-col h-full w-full ">
                                                {!props.newAttributeCreation &&
                                                    customAttributeValue?.data?.data?.map(
                                                        (values: any, id) => (
                                                            <div
                                                                className="w-full flex my-1  pl-2 pr-3 self-center"
                                                                key={id}
                                                            >
                                                                <p className="self-center ">
                                                                    {
                                                                        values.value
                                                                    }
                                                                </p>
                                                                <p
                                                                    className="ml-auto  self-center cursor-pointer text-gray-500"
                                                                    onClick={() => {
                                                                        conformingDeletion.deleteFor =
                                                                            'attributeValue'
                                                                        conformingDeletion.isOpen =
                                                                            true
                                                                        conformingDeletion.message =
                                                                            'This value may be linked to assets.Are you sure you want to delete this value? '
                                                                        conformingDeletion.data =
                                                                            values
                                                                        conformingDeletion.type =
                                                                            'delete'
                                                                        setConformingDeletion(
                                                                            {
                                                                                ...conformingDeletion,
                                                                            }
                                                                        )
                                                                    }}
                                                                >
                                                                    x
                                                                </p>
                                                            </div>
                                                        )
                                                    )}
                                                {props.newAttributeCreation &&
                                                    props.newCustomAttrValueDraft?.data?.map(
                                                        (
                                                            values: any,
                                                            i: any
                                                        ) => (
                                                            <div
                                                                className="w-full flex my-1 pl-2 pr-3 self-center"
                                                                key={i}
                                                            >
                                                                <p className="self-center ">
                                                                    {
                                                                        values.value
                                                                    }
                                                                </p>
                                                                <p
                                                                    className="ml-auto text-base self-center cursor-pointer text-gray-500"
                                                                    onClick={() => {
                                                                        removeNewlyCreatedAttr(
                                                                            values,
                                                                            i
                                                                        )
                                                                    }}
                                                                >
                                                                    x
                                                                </p>
                                                            </div>
                                                        )
                                                    )}
                                            </div>
                                        </div>
                                        <div className="flex flex-col">
                                            {customAttributeValue.isLoading ? (
                                                <div className="w-full h-full m-auto">
                                                    <Loading size="1.5rem" />
                                                </div>
                                            ) : (
                                                <>
                                                    {addValue && (
                                                        <>
                                                            <TextField
                                                                size="small"
                                                                onChange={(e) =>
                                                                    setNewAttValueInput(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                                value={
                                                                    NewAttValueInput
                                                                }
                                                                placeholder={`Enter value for ${customAttributesDetails.name}`}
                                                            />
                                                            <div className="flex gap-2 my-2">
                                                                <Button
                                                                    variant="contained"
                                                                    onClick={() => {
                                                                        onAddBtnClicked()
                                                                    }}
                                                                >
                                                                    Add
                                                                </Button>
                                                                <Button
                                                                    variant="contained"
                                                                    onClick={() => {
                                                                        setAddValue(
                                                                            !addValue
                                                                        )
                                                                    }}
                                                                >
                                                                    Cancel
                                                                </Button>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            {!addValue && (
                                                <Button
                                                    className="mt-2"
                                                    variant="outlined"
                                                    onClick={() => {
                                                        setAddValue(!addValue)
                                                    }}
                                                >
                                                    + Add option
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="flex items-center pt-2 gap-2">
                                <Checkbox
                                    className="px-0"
                                    size="small"
                                    checked={customAttributesDetails.sensitive}
                                    onChange={(e) =>
                                        setCustomAttributesDetails((draft) => {
                                            draft.sensitive = e.target.checked
                                        })
                                    }
                                ></Checkbox>
                                <Typography>
                                    Restrict access to admins and full access
                                    users
                                </Typography>
                                <Tooltip title="Upon checking this box, this attribute has a restricted view. Workspace admins can view and modify this attribute. At a document level, any user with Full Access to the document can view and modify it. Use this option if you want to capture, but protect sensitive data from all users.">
                                    <InfoIcon
                                        color="disabled"
                                        fontSize="small"
                                    ></InfoIcon>
                                </Tooltip>
                            </div>

                            <div className="my-6 text-right ">
                                <Button
                                    variant="outlined"
                                    onClick={() => valueManagement()}
                                >
                                    Save
                                </Button>
                                <Button
                                    className="py-1 px-4 ml-3  bg-white border border-approveBtn text-approveBtn text-sm rounded-sm"
                                    onClick={() => {
                                        conformingDeletion.deleteFor =
                                            'customAttribute'
                                        conformingDeletion.isOpen = true
                                        conformingDeletion.message =
                                            'This attribute may be linked to assets.Are you sure you want to delete this custom attribute?'
                                        conformingDeletion.data = data
                                        conformingDeletion.type = 'delete'
                                        setConformingDeletion({
                                            ...conformingDeletion,
                                        })
                                        // deleteCustomAtt.mutate(data)
                                    }}
                                >
                                    Delete
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

export default CustomAttributeList

const style = {
    mainContainer:
        ' h-full flex flex-col overflow-hidden bg-bgColor px-4 pt-4 rounded-md font-Segoe',
    display: ' h-full flex flex-col overflow-hidden rounded-md ',
}
