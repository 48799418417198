import { TabPanel } from '@Common/Components/TabPanel'
import useSaveSiteImage from '@Common/Queries/MutationQueries/SitesImageUpload'
import { Button, Tab, Tabs, TextField } from '@mui/material'
import { useRef } from 'react'
import { Updater, useImmer } from 'use-immer'
import { EditorState } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import useSaveSiteImageUsingUrl from '@Common/Queries/MutationQueries/UploadImageUsingUrl'
import { v4 as uuidv4 } from 'uuid'
import useSaveFile from '@Common/Queries/MutationQueries/SaveFile'
import useUploadFile from '@Common/Queries/MutationQueries/uploadFile'

interface UploadProps {
    editor: EditorState
    view: EditorView
    setImage: Updater<{ open: boolean; anchorEl: any }>
}

const EditorImageUpload = (props: UploadProps) => {
    const { view, editor, setImage } = props
    const [imageTab, setImageTab] = useImmer(0)
    const [url, setUrl] = useImmer('')
    const [uploadId, setUploadId] = useImmer(uuidv4())
    const uploadFile = useUploadFile({
        onSuccess: (data, variables) => {
            const { key, bucket } = variables.url.fields
            const url = variables.url.url.split('/')
            replaceImage(view, `${url[0]}//${url[3]}.${url[2]}/${key}`)
        },
        onError: () => {
            deletePlaceHolder()
        },
        option: () => {
            return {
                headers: { 'Content-Type': 'multipart/form-data' },
            }
        },
    })
    const saveSiteImage = useSaveSiteImage({
        onSuccess: (data) => {
            uploadFile.mutate({
                url: data.data.url,
                body: imageRef?.current?.files[0],
            })
        },
        onError: () => {
            deletePlaceHolder()
        },
    })
    function findImagePosition() {
        const positions: Array<{ node: Node; pos: number }> = []

        view.state.doc.descendants((node, pos) => {
            console.log(node.type.name, node.attrs)
            if (node.type.name === 'image' && node.attrs.id === uploadId) {
                positions.push({ node, pos })
            }
        })
        return positions
    }
    const applyPlaceHolder = () => {
        const tr = view?.state.tr
        if (!tr.selection.empty) tr.deleteSelection()
        const node = view.state.schema.nodes.image.create({
            src: '/assets/loading.gif',
            id: uploadId,
        })
        view.dispatch(
            tr.replaceWith(tr.selection.from, tr.selection.from, node)
        )
    }
    const deletePlaceHolder = () => {
        const pos = findImagePosition()
        if (!pos.length) {
            return
        }
        view.dispatch(view.state.tr.deleteRange(pos, pos))
    }

    const replaceImage = (viewer: EditorView, url: string) => {
        const pos = findImagePosition()
        if (!pos.length) {
            return
        }
        let tr = viewer.state.tr.setMeta('addToHistory', false)

        pos.forEach(({ node, pos }) => {
            tr = tr.setNodeMarkup(pos, view.state.schema.nodes.image, {
                ...node.attrs,
                id: null,
                src: url || null,
            })
        })
        viewer.dispatch(tr)
    }

    const saveSiteImageUsingUrl = useSaveSiteImageUsingUrl({
        onSuccess: (data) => {
            replaceImage(view, data.data.url)
        },
        onError: () => {
            deletePlaceHolder()
        },
    })
    const handleTabChange = (e, number) => {
        setImageTab((draft) => {
            return number
        })
    }
    const imageRef = useRef()
    function startImageUpload(file: File) {
        applyPlaceHolder()
        saveSiteImage.mutate({
            fileName: file.name,
            fileType: file.type,
        })
    }
    const imageUploaded = () => {
        if (imageRef?.current?.files?.length) {
            startImageUpload(imageRef?.current?.files[0])
        }
    }
    return (
        <div className="p-2 w-96">
            <div>
                <Tabs value={imageTab} onChange={handleTabChange}>
                    <Tab label="Upload" />
                    <Tab label="Embed link" />
                </Tabs>
            </div>
            <TabPanel value={imageTab} index={0}>
                <div className="p-2 flex">
                    <input
                        id="image-upload"
                        type="file"
                        className="hidden"
                        ref={imageRef}
                        multiple={false}
                        onChange={imageUploaded}
                    ></input>
                    <Button
                        variant="contained"
                        size="small"
                        className="w-full"
                        onClick={() => {
                            imageRef?.current?.click()
                            setImage((draft) => {
                                return {
                                    open: false,
                                    anchorEl: null,
                                }
                            })
                        }}
                    >
                        Upload Image
                    </Button>
                </div>
            </TabPanel>
            <TabPanel value={imageTab} index={1}>
                <div className="p-2  flex flex-col">
                    <TextField
                        placeholder="Paste the image link"
                        size="small"
                        onChange={(e) => {
                            setUrl(e.target.value)
                        }}
                        className="w-full py-2"
                    ></TextField>
                    <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => {
                            setImage((draft) => {
                                return {
                                    open: false,
                                    anchorEl: null,
                                }
                            })
                            applyPlaceHolder()
                            saveSiteImageUsingUrl.mutate(url)
                        }}
                    >
                        Embed image
                    </Button>
                </div>
            </TabPanel>
        </div>
    )
}

export default EditorImageUpload
