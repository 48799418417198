import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { APi_URL } from "@Common/Types/configs/urlConfig";


export default function useThirdPartyDelete(props:any) {
    const queryClient = useQueryClient()
    return useMutation<any, Error, object>((val: any) => axios.delete(
        `${APi_URL}/webhooks/${val.id}`,
        { headers: { 'Content-Type': 'application/json' } }),
        {
            onSuccess: ()=>{
                queryClient.invalidateQueries(["thirdPartyData"])
                props.onSuccess
            },
            onError:props.onError
        });
}