import { APi_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
interface ApproveAccessRequestProps {
    requestsId: string
}
export default function useApproveAssetRequiredRequest(props?: any) {
    const queryClient = useQueryClient()
    return useMutation<any, any, ApproveAccessRequestProps>(
        (obj: ApproveAccessRequestProps) =>
            axios.post(
                `${APi_URL}/requests/${obj.requestsId}/newAsset/user`,
                { status: 'CLOSED' },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        timeZone:
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                    },
                }
            ),
        {
            onSuccess: async (data: ApproveAccessRequestProps, obj: any) => {
                await queryClient.invalidateQueries([
                    'requests',
                    obj.requestsId,
                ])
                if (props.onSuccess) props.onSuccess(data, obj)
            },
            onMutate: async (data: ApproveAccessRequestProps) => {
                await queryClient.cancelQueries(['requests', data.requestsId])
                const previousComments = queryClient.getQueryData([
                    'requests',
                    data.requestsId,
                ])
                queryClient.setQueryData(
                    ['requests', data.requestsId],
                    (old: any) => {
                        old.status = 'CLOSED'
                        return old
                    }
                )
                return { previousComments }
            },
            onError: (data: any, obj: any, context: any) => {
                queryClient.setQueryData(
                    ['requests', obj.requestsId],
                    context.previousComments
                )

                if (props.onError) props.onError(data, obj)
            },
            onSettled: (data: any) => {
                queryClient.invalidateQueries([
                    'requests',
                    data.data.requestsId,
                ])
            },
        }
    )
}
