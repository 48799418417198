import { useQuery } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'

const getAssetFileVersion = async (id: any) => {
    const { data } = await axios.get(`${APi_URL}/assets/${id}/versions`)
    return data
}

export default function useAssetFileVersion(id: any) {
    return useQuery(['assets', id, 'versions'], () => getAssetFileVersion(id))
}
