import {
    CancelOutlined,
    DoneOutline,
    LinkOutlined,
    ShareOutlined,
    VisibilityOutlined,
} from '@mui/icons-material'
import { Typography } from '@mui/material'
import { compareDesc, differenceInDays, format, startOfDay } from 'date-fns'
import prettyMilliseconds from 'pretty-ms'
import { getImageFromName, getVisitorName } from 'src/BuyerInsightsUtil'


export function Activity({ contact }: { contact: any }) {
    const eventGroup = contact.trackLinks
        .flatMap((link: any) => {
            const visits = link.trackLink.TrackVisit.map((visit: any) => {
                return {
                    visitDuration: visit.visitDuration,
                    type: 'visit',
                    totalPagesViewed: visit.totalPagesViewed,
                    downloaded: visit.downloaded,
                    name: link.trackLink.TrackLinkProperties.name,
                    image: 'Awdaa',
                    assetName: link.trackLink.AssetTrackLink.Asset.name,
                    assetId: link.trackLink.AssetTrackLink.assetId,
                    thumbnailFileName:
                        link.trackLink.AssetTrackLink.Asset.LatestFile
                            .thumbnailFileName,
                    updatedAt: visit.updatedAt,
                    visitTime: visit.visitTime,
                    contacts: link.trackLink.TrackLinkContact.map(
                        (contact) => contact.email
                    ).join(', '),
                    email: visit.TrackSession.AssetTrackSession.email,
                    buyerDetails: visit.TrackSession.AssetTrackSession?.buyerDetails,
                    city: visit.TrackSession.AssetTrackSession.buyerDetails?.ipData?.city,
                    operatingSystem: visit.TrackSession.AssetTrackSession.buyerDetails?.operatingSystem,
                    country: visit.TrackSession.AssetTrackSession.buyerDetails?.ipData?.country_name
                }
            })
            return [
                {
                    type: 'link',
                    updatedAt: link.trackLink.createdAt,
                    assetId: link.trackLink.AssetTrackLink.assetId,
                    name: link.trackLink.TrackLinkProperties.name,
                    assetName: link.trackLink.AssetTrackLink.Asset.name,
                    contacts: link.trackLink.TrackLinkContact.map(
                        (contact) => contact.email
                    ).join(', '),
                    thumbnailFileName:
                        link.trackLink.AssetTrackLink.Asset.LatestFile
                            .thumbnailFileName,
                    totalVisits: link.trackLink.TrackVisit.length,
                },
                ...visits,
            ]
        })
        .sort((a: any, b: any) => {
            return compareDesc(
                new Date(a.visitTime || a.updatedAt),
                new Date(b.visitTime || b.updatedAt)
            )
        })
        .reduce((acc: any, curr: any) => {
            const date = new Date(
                startOfDay(new Date(curr.visitTime || curr.updatedAt))
            ).toDateString()
            if (!acc[date]) {
                acc[date] = []
            }
            acc[date].push(curr)
            return acc
        }, {})
    return (
        <div className="p-6 flex gap-4 flex-col ">
            {Object.keys(eventGroup).map((key: any) => {
                const diff = differenceInDays(
                    startOfDay(new Date()),
                    new Date(key)
                )
                return (
                    <>
                        <Typography variant="subtitle2">
                            {diff === 0
                                ? 'Today'
                                : diff === 1
                                ? 'Yesterday'
                                : format(new Date(key), 'MMMM dd, yyyy')}
                        </Typography>
                        {eventGroup[key].map((event: any, index: number) =>
                            event.type === 'link' ? (
                                <div className="relative">
                                    {' '}
                                    <ShareActivity event={event} />
                                    {index + 1 < eventGroup[key].length && (
                                        <div className="absolute left-3 bottom-[-20px]   h-6 w-[2px] bg-gray-300"></div>
                                    )}
                                </div>
                            ) : (
                                <div className="relative">
                                    <VisitActivity event={event} />
                                    {index + 1 < eventGroup[key].length && (
                                        <div className="absolute left-3  bottom-[-20px] h-6 w-[2px] bg-gray-300"></div>
                                    )}
                                </div>
                            )
                        )}
                    </>
                )
            })}
        </div>
    )
}
function ShareActivity({ event }) {
    return (
        <div className="flex gap-5 flex-1">
            <div className="flex ">
                <ShareIcon />
            </div>
            <div className="flex p-2 flex-col gap-2 flex-1 rounded-md shadow-sm border-l-blue-400 border-l-4">
                <div>Shared</div>
                <div className="h-11 flex flex-1 justify-between">
                    <div className="flex gap-4">
                        <div className="w-20">
                            <img src={event.thumbnailFileName} />
                        </div>
                        <div className="flex flex-col justify-center">
                            <div>{event.assetName}</div>
                            <div>
                                <Typography variant="body2">
                                    {event.name} | {event.contacts}
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-end">
                        <Typography variant="body2">
                            Total visits: {event.totalVisits}
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    )
}
function VisitActivity({ event }: { event: any }) {
    const imageURL = getImageFromName(getVisitorName(event.email, event.buyerDetails));
    return (
        <div className="flex gap-5 flex-1">
            <div className="flex ">
                <VisitIcon />
            </div>
            <div className="flex border-l-green-400 rounded-md border-l-4 p-2 shadow-sm flex-1 flex-col gap-2">
                <div className='flex flex-row justify-between'>
                    <div>Visited</div>
                    {
                        <div className='flex flex-row justify-end space-x-1'>
                        {
                             imageURL && (<img src = {imageURL} className='invert-[.65]'></img>)
                        }
                        {
                            event.city && event.country && (
                                <Typography variant="caption" color={'text.disabled'}>{`${event.city}, ${event.country}`}</Typography>
                            )
                        }
                        </div>
                    }
                </div>
                <div className="h-11 flex flex-1  justify-between">
                    <div className="flex gap-4">
                        <div className="w-20">
                            <img src={event.thumbnailFileName} />
                        </div>
                        <div className="flex flex-col justify-center">
                            <div>{event.assetName}</div>
                            <div>
                                <Typography
                                    variant="body2"
                                    className="whitespace-nowrap overflow-hidden w-48 text-ellipsis"
                                >
                                    {event.name} | {event.contacts}
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-5 items-end">
                        <Typography variant="body2">
                            Pages viewed: {event.totalPagesViewed}
                        </Typography>
                        <Typography variant="body2">
                            Total time spent:{' '}
                            {prettyMilliseconds(event.visitDuration)}
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    )
}
function ShareIcon() {
    return (
        <Typography variant="h5" className="text-blue-400  flex items-center">
            <ShareOutlined />
        </Typography>
    )
}
function VisitIcon() {
    return (
        <Typography variant="h5" className="text-green-400 flex items-center">
            <VisibilityOutlined />
        </Typography>
    )
}
