import { APi_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { InviteUsers } from '@Server/inviteUsers'
export default function useAddUsers(props: { onSuccess: () => void }) {
    const queryClient = useQueryClient()
    return useMutation<any, Error, InviteUsers>(
        (obj) => axios.post(`${APi_URL}/users`, obj),
        {
            onSuccess: (data: any, obj: any) => {
                queryClient.invalidateQueries(['Users'])
                props.onSuccess()
            },
        }
    )
}
