import logo from './assets/Portal.png'
import useTitle from '@Common/hooks/useTitle'
import { API_URL } from '@Common/Types/configs/urlConfig'
import { Button, TextField } from '@mui/material'
import { Login as LoginRequest } from '@Server/login'
import { ResendCode } from '@Server/resendCode'

import Loading from '@Common/Components/Loading'
import axios from 'axios'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AuthTemplate } from './AuthTemplate'

export function Verify() {
    const navigate = useNavigate()
    const { handleSubmit, control, setError, formState } = useForm({})
    let [searchParams, setSearchParams] = useSearchParams()
    const email = searchParams.get('email')
    useEffect(() => {
        if (!email) {
            navigate('/signup')
        }
    })
    const mutation = useMutation(
        (login: LoginRequest) => {
            return axios.post(`${API_URL}/verify`, login)
        },
        {
            onSuccess: (result) => {
                toast.success('Your account is verified')
                navigate(`/login`)
            },
            onError: (error) => {
                toast.error(
                    'Invalid verification code provided, please try again.'
                )
            },
        }
    )
    const resend = useMutation(
        (resend: ResendCode) => {
            return axios.post(`${API_URL}/resend`, resend)
        },
        {
            onSuccess: (result) => {},
            onError: (error) => {
                toast.error(
                    'Invalid verification code provided, please try again.'
                )
            },
        }
    )

    const onSubmit = (data) => mutation.mutate({ ...data, username: email })
    useTitle('Verify Email - Portal')

    return (
        <AuthTemplate
            title={
                ' Verify your email by entering the code we sent to your email address'
            }
            body={
                <>
                    <Controller
                        name="code"
                        control={control}
                        render={({ field }) => (
                            <TextField size="small" {...field} label="Code" />
                        )}
                    />

                    <Button
                        disabled={mutation.isLoading}
                        type="submit"
                        variant="contained"
                    >
                        {mutation.isLoading && (
                            <div className="mr-2">
                                <Loading size="1.5rem" />
                            </div>
                        )}
                        Verify
                    </Button>
                    <p>
                        Didn't receive a link?{'  '}
                        <Button
                            onClick={() => {
                                resend.mutate({
                                    username: email,
                                })
                            }}
                        >
                            Send a new link
                        </Button>{' '}
                    </p>
                </>
            }
            onSubmit={handleSubmit(onSubmit)}
        />
    )
}
