import { useQuery } from "react-query";
import axios from "axios";
import { APi_URL } from "@Common/Types/configs/urlConfig";


const getAssetType = async () => {
  const { data } = await axios.get(
    `${APi_URL}/assetTypes`,
    { headers: { 'Content-Type': 'application/json' } }
  )
  return {
    data: data,
    option: data.map((e: any, i: any) => {
      return {
        label: e.name,
        value: e.id,
      }
    })
  };
};

export default function useAssetType() {
  return useQuery("assetType", getAssetType);
}
