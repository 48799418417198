import React from 'react'

export default function useDebounce<T>(
    value: T,
    delay = 500,
    onChange?: (value: T) => void
) {
    const [debouncedValue, setDebouncedValue] = React.useState(value)

    React.useEffect(
        () => {
            const handler: NodeJS.Timeout | null = setTimeout(() => {
                if (value === debouncedValue) return
                if (onChange) onChange(value)
                setDebouncedValue(value)
            }, delay)

            // Cancel the timeout if value changes (also on delay change or unmount)
            return () => {
                clearTimeout(handler)
            }
        },
        [value, delay] // Only re-call effect if value or delay changes
    )

    return debouncedValue
}
