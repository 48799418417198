function redirectPage() {
    const currentPage = location.pathname + location.search
    let redirectUrl = '/login'
    if (currentPage === '/plugin?origin=outlook') {
        redirectUrl = '/plugin/welcome'
    }

    let params = new URLSearchParams()
    if (currentPage !== '/') {
        params.append('previousUrl', currentPage)
        redirectUrl = redirectUrl + '?' + params.toString()
    }
    window.parent.postMessage(
        {
            name: 'logout',
        },
        '*'
    )
    window.location.href = window.location.origin + redirectUrl
}

export { redirectPage }
