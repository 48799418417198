import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { TransitionProps } from '@mui/material/transitions'
import Fade from '@mui/material/Fade'
import { twMerge } from 'tailwind-merge'

import { forwardRef, Fragment, ReactNode, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import { Divider, IconButton, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
interface ModalProps {
    isOpen: boolean
    className?: string
    onClose: () => void
    title?: string
    body: ReactNode
    footer?: ReactNode
}
const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
) {
    return <Fade ref={ref} {...props} />
})
export default function Modal({
    isOpen,
    onClose,
    title,
    body,
    footer,
    className,
}: ModalProps) {
    return (
        <>
            <Dialog
                open={isOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={onClose}
                fullWidth
                maxWidth={'xl'}
                PaperProps={{
                    className: twMerge('h-full', className),
                }}
                scroll={'paper'}
            >
                {title && (
                    <DialogTitle className="text-lg bg-gray-100 border-b-2 border-gray-200 font-medium leading-6 text-gray-900">
                        <div className="flex justify-between items-center">
                            <Typography variant="h6">{title}</Typography>
                            <div>
                                <IconButton
                                    onClick={() => {
                                        onClose()
                                    }}
                                >
                                    <Close />
                                </IconButton>
                            </div>
                        </div>
                    </DialogTitle>
                )}
                <DialogContent className="h-full">{body}</DialogContent>
                <Divider />
                <DialogActions>{footer}</DialogActions>
            </Dialog>
        </>
    )
}
