import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'

export function useUpdateAccount() {
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: (request: any) => updateAccount(request),
        onSuccess: (data, request) => {
            queryClient.invalidateQueries('accounts')
        },
        onMutate: (request) => {
            queryClient.setQueryData(
                ['account', request.recordId],
                (oldData) => {
                    return {
                        ...oldData,
                        detail: {
                            ...oldData.detail,
                            [request.columnId]: request.value,
                            [`${request.columnId}_data`]: request.value.label,
                        },
                    }
                }
            )
        },
    })
    return mutation
}
async function updateAccount(request) {
    return await axios.post(`${API_URL}/accounts/${request.recordId}`, {
        ...request,
        value: request.value?.label ? request.value.value : request.value,
    })
}
