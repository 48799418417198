import { format, parseISO } from 'date-fns'

export function raisedBy(userId: any, usersData: any) {
    if (!userId) {
        return
    }
    const name = usersData?.data.find((e) => {
        return e.id === userId
    })
    if (!name) {
        return 'NULL'
    }
    return name.name
}
export function formattedDate(date: string) {
    if (!date) {
        return
    }
    const parsedFormat = parseISO(date)
    return format(parsedFormat, 'MMM dd, yyyy')
}
export function formattedRequest(type: any[], request: any) {
    const requestName = type.find((e) => e.value === request)
    if (!requestName) {
        return 'NULL'
    }
    return requestName.label
}
export function documentName(
    AssetApproveRequest: { asset: { name: string } } | null,
    AssetPermissionRequests: { asset: { name: string } }
) {
    if (AssetApproveRequest !== null) {
        return AssetApproveRequest.asset.name
    }
    return AssetPermissionRequests.asset.name
}
