import { MenuItem, Select, SelectProps } from '@mui/material'
export type Items = Array<{
    value: string
    label: string
    info?: string
    className?: string
}>
export interface SelectInfoProps extends SelectProps {
    items: Items
}
export function SelectInfo({ items, ...rest }: SelectInfoProps) {
    return (
        <Select
            renderValue={(value) => {
                const index = items.findIndex((item) => item.value === value)
                const item = items[index]
                if (!item) {
                    return ''
                }
                return <div>{item.label}</div>
            }}
            size="small"
            {...rest}
        >
            {items.map((item, index) => {
                return (
                    <MenuItem value={item.value} key={index}>
                        <div className={`${item.className}`}>
                            <div className="pb-1">{item.label}</div>
                            {item.info && (
                                <div className="text-xs text-gray-400">
                                    {item.info}
                                </div>
                            )}
                        </div>
                    </MenuItem>
                )
            })}
        </Select>
    )
}
