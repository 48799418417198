import Loading from '@Common/Components/Loading'
import { Accounts } from '@Common/Components/Select/Accounts'
import { useCreateDealRoom } from '@Common/Queries/Dealroom/CreateDealRoom'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import { Button, TextField } from '@mui/material'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { authManager } from 'src/AuthManager'
import { Updater, useImmer } from 'use-immer'

export const CreateDealRoom = (props: {
    setState: Updater<{
        open: boolean
        anchorEl: any
    }>
}) => {
    const opportunityRef = useRef()
    const nameRef = useRef()
    const navigate = useNavigate()
    const sendDataToGTM = useGTMDispatch()
    const createDealRoomQuery = useCreateDealRoom({
        onSuccess: (data) => {
            props.setState((draft) => {
                draft.open = false
                draft.anchorEl = null
            })
            sendDataToGTM({
                event: 'digital_room_created',
                digital_room_ID: data.data.id,
            })
            navigate(`/dealroom/${data.data.id}`)
        },
    })
    const [error, setError] = useImmer(false)

    const createDealRoom = () => {
        if (
            nameRef?.current?.value === null ||
            nameRef?.current?.value.trim() === ''
        ) {
            setError(true)
            return
        }
        if (!error) {
            createDealRoomQuery.mutate({
                name: nameRef?.current?.value.trim(),
            })
        }
    }
    return (
        <div className="p-4 flex flex-col gap-4">
            <TextField
                sx={{
                    width: 288,
                }}
                error={error}
                inputRef={nameRef}
                onChange={(e) => {
                    if (
                        nameRef?.current?.value != null ||
                        nameRef?.current?.value.trim() != ''
                    ) {
                        setError(false)
                    }
                }}
                size="small"
                variant="outlined"
                placeholder="Room title"
                helperText="Required"
            ></TextField>
            <Button
                variant="outlined"
                onClick={() => {
                    createDealRoom()
                }}
                disabled={createDealRoomQuery.isLoading}
            >
                Create
                <div className="pr-2 pt-1">
                    {createDealRoomQuery.isLoading && <Loading size="1rem" />}
                </div>
            </Button>
        </div>
    )
}
