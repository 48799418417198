import Loading from '@Common/Components/Loading'
import { TabPanel } from '@Common/Components/TabPanel'
import { useSummaryData } from '@Common/Queries/Dashboard/SummaryData'
import { useUserData } from '@Common/Queries/Dashboard/UserData'
import { useWorkspaceData } from '@Common/Queries/Dashboard/WorkspaceData'
import useInit from '@Common/Queries/GetQueries/useInit'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import LinkIcon from '@mui/icons-material/Link'
import ScheduleIcon from '@mui/icons-material/Schedule'
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined'
import {
    Avatar,
    Button,
    Divider,
    Tab,
    Tabs,
    Tooltip,
    Typography,
} from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import classNames from 'classnames'
import prettyMilliseconds from 'pretty-ms'
import { Link, useNavigate } from 'react-router-dom'
import { formattedDate } from 'src/ViewAssetScreen/util'
import { useImmer } from 'use-immer'
import newAssetImg from './../assets/NewAsset.svg'
import { getVisitorName, NameDisplay, displayAvatarBasedOnName } from 'src/BuyerInsightsUtil'

export const Dashboard = () => {
    const navigate = useNavigate()
    const [tabs, setTabs] = useImmer(0)
    const init = useInit()
    const summaryData = useSummaryData()
    const workspaceData = useWorkspaceData()
    const userData = useUserData()

    if (
        init.isLoading ||
        summaryData.isLoading ||
        workspaceData.isLoading ||
        userData.isLoading
    ) {
        return <Loading></Loading>
    }

    const mostUsedDataCol: GridColDef[] = [
        {
            headerName: 'Documents',
            field: 'assetName',
            flex: 1,
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div className="flex items-center gap-3">
                        <div
                            className={`w-20 h-11 border border-solid border-borderColor rounded overflow-hidden`}
                        >
                            <img
                                src={params.row.thumbnailUrl}
                                loading="eager"
                                className="w-full h-auto"
                            ></img>
                        </div>
                        <Typography color="text.primary">
                            {params.value}
                        </Typography>
                    </div>
                )
            },
        },
        {
            headerName: 'Links created',
            field: 'count',
            width: 105,
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            valueFormatter: (params) => {
                return params.value.toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                })
            },
            renderCell: (params) => {
                return (
                    <Typography color={'text.secondary'} fontWeight={400}>
                        {params.formattedValue}
                    </Typography>
                )
            },
        },
    ]
    const recentlyAddedDataCol: GridColDef[] = [
        {
            headerName: 'Documents',
            field: 'assetName',
            flex: 1,
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div className="flex items-center gap-4 py-3">
                        <div
                            className={`w-20 h-11 border border-solid border-borderColor rounded overflow-hidden`}
                        >
                            <img
                                src={params.row.thumbnailUrl}
                                loading="eager"
                                className="w-full h-auto"
                            ></img>
                        </div>
                        <div className="relative">
                            {params.row.createdAt === params.row.updatedAt && (
                                <img
                                    src={newAssetImg}
                                    className="absolute -top-2 -left-2"
                                />
                            )}
                            <Typography color="text.primary">
                                {params.value}
                            </Typography>
                        </div>
                    </div>
                )
            },
        },
        {
            headerName: 'Last updated',
            field: 'updatedAt',
            width: 105,
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            valueFormatter: (params) => {
                return formattedDate(params.value)
            },
            renderCell: (params) => {
                return (
                    <Typography color={'text.secondary'} fontWeight={400}>
                        {params.formattedValue}
                    </Typography>
                )
            },
        },
    ]

    const recentVisitDataCol: GridColDef[] = [
        {
            headerName: 'Visitor/link name',
            field: 'visitorName',
            disableColumnMenu: true,
            flex: 1,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div className="flex w-full overflow-hidden gap-2">
                        {displayAvatarBasedOnName(params.value, params.row.index)}
                        <div className="flex-1 flex flex-col w-full">
                            <NameDisplay
                                city={params.row.city}
                                name={params.value}
                                country={params.row.country}
                            ></NameDisplay>
                            <div className="flex gap-2 flex-1 w-full">
                                <Tooltip title={params.row.linkName}>
                                    <Typography
                                        color="text.secondary"
                                        variant="caption"
                                        sx={{
                                            maxWidth:
                                                params.row.contact != ''
                                                    ? '33%'
                                                    : '80%',
                                        }}
                                        className={
                                            'overflow-hidden whitespace-nowrap text-ellipsis'
                                        }
                                    >
                                        {params.row.linkName}
                                    </Typography>
                                </Tooltip>
                                {params.row.contact != '' && (
                                    <>
                                        <Divider
                                            className="h-full w-1"
                                            orientation="vertical"
                                        ></Divider>
                                        <Tooltip title={params.row.contact}>
                                            <Typography
                                                color="text.secondary"
                                                variant="caption"
                                                className="overflow-hidden whitespace-nowrap text-ellipsis"
                                            >
                                                {params.row.contact}
                                            </Typography>
                                        </Tooltip>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )
            },
        },
        {
            headerName: 'Documents',
            field: 'assetName',
            disableColumnMenu: true,
            flex: 1,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div className="flex items-center gap-3 py-3">
                        <div
                            className={`w-20 h-11 border border-solid border-borderColor rounded overflow-hidden`}
                        >
                            <img
                                src={params.row.thumbnailUrl}
                                loading="eager"
                                className="w-full h-auto "
                            ></img>
                        </div>
                        <Typography color="text.secondary" fontWeight={400}>
                            {params.value}
                        </Typography>
                    </div>
                )
            },
        },
        {
            headerName: 'Time spent',
            field: 'timeSpent',
            disableColumnMenu: true,
            sortable: false,
            flex: 1,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Typography color={'text.secondary'} fontWeight={400}>
                        {params.formattedValue}
                    </Typography>
                )
            },
            valueFormatter: (params) => {
                return prettyMilliseconds(params.value)
            },
        },
        {
            headerName: 'Last engaged date',
            field: 'lastEngagement',
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <Typography color={'text.secondary'} fontWeight={400}>
                        {params.formattedValue}
                    </Typography>
                )
            },
            valueFormatter: (params) => {
                return formattedDate(params.value)
            },
        },
    ]

    const newLinkDataCol: GridColDef[] = [
        {
            headerName: 'Link name',
            field: 'trackLinkName',
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            flex:1,
            renderCell: (params) => {
                return (
                    <Typography color={'text.primary'}>
                        {params.value}
                    </Typography>
                )
            },
        },
        {
            headerName: 'Documents',
            field: 'assetName',
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <div className="flex items-center gap-3 py-3">
                        <div
                            className={`w-20 h-11 border border-solid border-borderColor rounded overflow-hidden`}
                        >
                            <img
                                src={params.row.thumbnailUrl}
                                loading="eager"
                                className="w-full h-auto"
                            ></img>
                        </div>
                        <Typography color="text.secondary" fontWeight={400}>
                            {params.value}
                        </Typography>
                    </div>
                )
            },
        },
        {
            headerName: 'Created date',
            field: 'createdAt',
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            flex:1,
            renderCell: (params) => {
                return (
                    <Typography color={'text.secondary'} fontWeight={400}>
                        {params.formattedValue}
                    </Typography>
                )
            },
            valueFormatter: (params) => {
                return formattedDate(params.value)
            },
        },
        {
            headerName: 'Number of visits',
            field: 'numberOfVisits',
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            flex:1,
            renderCell: (params) => {
                return (
                    <Typography color={'text.secondary'} fontWeight={400}>
                        {params.formattedValue}
                    </Typography>
                )
            },
            valueFormatter: (params) => {
                return params.value
                    ? params.value.toLocaleString('en-US', {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                    })
                    : '00'
            },
        },
        {
            headerName: 'Last engaged date',
            field: 'lastEngagedDate',
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            flex:1,
            renderCell: (params) => {
                return (
                    <Typography color={'text.secondary'} fontWeight={400}>
                        {params.formattedValue}
                    </Typography>
                )
            },
            valueFormatter: (params) => {
                return formattedDate(params.value)
            },
        },
    ]

    const SummarySection = ({ text, data, icon, index }) => {
        return (
            <div className="w-full flex px-3">
                <div className="flex w-full gap-3">
                    <Avatar
                        sx={{
                            background: '#FAFAFA',
                        }}
                    >
                        {icon}
                    </Avatar>

                    <div className="flex flex-col">
                        <Typography color={'text.secondary'} fontWeight={500}>
                            {text}
                        </Typography>
                        <Typography variant="h6">
                            {data ? data : '--'}
                        </Typography>
                    </div>
                </div>
                {index != 3 && (
                    <Divider
                        orientation="vertical"
                        sx={{
                            border: '1px solid #F3F4F6',
                        }}
                    ></Divider>
                )}
            </div>
        )
    }
    const rows = userData.data.recentVisits.map(e => {
        return {
            ...e,
            visitorName: getVisitorName(e.visitorName, e.buyerDetails)
        }
    })

    return (
        <div className="w-full h-full flex py-4 px-6 flex-col gap-4 overflow-x-hidden">
            <div className="h-8 w-full flex items-center">
                <Typography variant="h5">Dashboard</Typography>

            </div>

            <div className="flex gap-4 flex-col">
                <Typography
                    variant="caption"
                    color="text.primary"
                >{`${init.data.tenant.name} (last 30 days)`}</Typography>
            </div>
            <div className="w-full flex border border-dividerColor py-4">
                <SummarySection
                    data={summaryData.data.workspace.totalDocuments.toLocaleString(
                        'en-US',
                        {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                        }
                    )}
                    index={0}
                    text={'Documents added'}
                    icon={
                        <DescriptionOutlinedIcon
                            color="disabled"
                            fontSize="small"
                        ></DescriptionOutlinedIcon>
                    }
                ></SummarySection>
                <SummarySection
                    data={summaryData.data.workspace.totalLinks.toLocaleString(
                        'en-US',
                        {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                        }
                    )}
                    index={1}
                    text={'Links created'}
                    icon={
                        <LinkIcon color="disabled" fontSize="small"></LinkIcon>
                    }
                ></SummarySection>
                <SummarySection
                    data={summaryData.data.workspace.totalVisits.toLocaleString(
                        'en-US',
                        {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                        }
                    )}
                    text={'Number of visits'}
                    index={2}
                    icon={
                        <VisibilityOutlined
                            color="disabled"
                            fontSize="small"
                        ></VisibilityOutlined>
                    }
                ></SummarySection>
                <SummarySection
                    data={
                        summaryData.data.workspace.avgEngagementTime
                            ? prettyMilliseconds(
                                summaryData.data.workspace.avgEngagementTime,
                                {
                                    secondsDecimalDigits: 0,
                                }
                            )
                            : '--'
                    }
                    text={'Avg engagement time'}
                    icon={
                        <ScheduleIcon
                            color="disabled"
                            fontSize="small"
                        ></ScheduleIcon>
                    }
                    index={3}
                ></SummarySection>
            </div>
            <div className="w-full gap-4 grid grid-cols-2">
                <div className="flex flex-col border border-dividerColor px-4">
                    <div className="py-4 px-2">
                        <Typography color={'text.secondary'} fontWeight={500}>
                            Most used documents
                        </Typography>
                    </div>
                    <DataGrid
                        autoHeight
                        columns={mostUsedDataCol}
                        rows={workspaceData.data.mostUsedDocuments}
                        onRowClick={(params) => {
                            navigate(`/search/${params.id}`)
                        }}
                        components={{
                            NoRowsOverlay: () => (
                                <div className="h-full flex justify-center items-center p-4">
                                    ------
                                </div>
                            ),
                            ColumnResizeIcon: () => {
                                return null
                            },
                        }}
                        hideFooter={true}
                        rowHeight={64}
                        sx={{
                            border: 'none',
                            '& .MuiDataGrid-columnHeaderTitle': {
                                fontWeight: 300,
                            },
                            '&	.MuiDataGrid-withBorderColor': {
                                borderBottom: '1px solid #f3f4f6',
                                ':focus': {
                                    border: 'none',
                                },
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                borderBottom: '1px solid #E5E7EB',
                            },
                            '.MuiDataGrid-row:hover': {
                                cursor: 'pointer',
                            },
                            '.MuiDataGrid-main': {
                                paddingX: 1,
                                paddingY: 1,
                            },
                        }}
                    ></DataGrid>
                </div>

                <div className="flex flex-col border border-dividerColor px-4 overflow-hidden">
                    <div className="py-4 px-2">
                        <Typography color={'text.secondary'} fontWeight={500}>
                            Recently added documents
                        </Typography>
                    </div>
                    <div>
                        <DataGrid
                            autoHeight
                            onRowClick={(params) => {
                                navigate(`/search/${params.id}`)
                            }}
                            columns={recentlyAddedDataCol}
                            rows={workspaceData.data.recentlyAddedDocuments}
                            components={{
                                NoRowsOverlay: () => (
                                    <div className="h-full flex justify-center items-center p-4">
                                        <Button
                                            data-testid="dashboard-recentlyadded-btn"
                                            LinkComponent={Link}
                                            onClick={() => {
                                                navigate('/search')
                                            }}
                                        >
                                            Click here to start uploading
                                            documents.
                                        </Button>
                                    </div>
                                ),
                                ColumnResizeIcon: () => {
                                    return null
                                },
                            }}
                            sx={{
                                border: 'none',
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    fontWeight: 300,
                                },
                                '&	.MuiDataGrid-withBorderColor': {
                                    borderBottom: '1px solid #f3f4f6',
                                    ':focus': {
                                        border: 'none',
                                    },
                                },
                                '& .MuiDataGrid-columnHeaders': {
                                    borderBottom: '1px solid #E5E7EB',
                                },
                                '.MuiDataGrid-row:hover': {
                                    cursor: 'pointer',
                                },
                                '.MuiDataGrid-main': {
                                    paddingX: 1,
                                    paddingY: 1,
                                },
                            }}
                            rowHeight={64}
                            hideFooter={true}
                        ></DataGrid>
                    </div>
                </div>
            </div>
            <div className="">
                <Typography variant="caption" color="text.primary">
                    {'My activity'}
                </Typography>
            </div>
            <div className="w-full flex flex-col gap-2 border border-dividerColor px-2">
                <div className="flex justify-between border-b border-dividerColor">
                    <Tabs
                        value={tabs}
                        onChange={(e, newValue) => {
                            setTabs(newValue)
                        }}
                    >
                        <Tab
                            data-testid="dashboard-recentvisits-tab"
                            label="Recent Visits"
                            value={0}
                        />
                        <Tab
                            data-testid="dashboard-newlinks-tab"
                            label="New links"
                            value={1}
                        />
                    </Tabs>
                    <Button
                        data-testid="dashboard-viewall-btn"
                        size="small"
                        onClick={() => {
                            if (tabs === 0) {
                                navigate(`/visits`)
                            } else {
                                navigate('links')
                            }
                        }}
                    >
                        View all
                    </Button>
                </div>
                <TabPanel index={0} value={tabs} className="w-full pt-2 px-5">
                    <DataGrid
                        autoHeight
                        columns={recentVisitDataCol}
                        rows={rows}
                        components={{
                            NoRowsOverlay: () => (
                                <div className="flex justify-center p-4">
                                    <Typography color={'text.disabled'}>
                                        No visits recorded
                                    </Typography>
                                </div>
                            ),
                            ColumnResizeIcon: () => {
                                return null
                            },
                        }}
                        hideFooter={true}
                        rowHeight={64}
                        sx={{
                            border: 'none',
                            '& .MuiDataGrid-columnHeaderTitle': {
                                fontWeight: 300,
                            },
                            '&	.MuiDataGrid-withBorderColor': {
                                borderBottom: '1px solid #f3f4f6',
                                ':focus': {
                                    border: 'none',
                                },
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                borderBottom: '1px solid #E5E7EB',
                            },
                            '.MuiDataGrid-row:hover': {
                                background: 'none',
                            },
                            '& .MuiDataGrid-row.Mui-selected': {
                                background: 'none',
                                ':hover': {
                                    background: 'none',
                                },
                            },
                        }}
                    ></DataGrid>
                </TabPanel>
                <TabPanel index={1} value={tabs} className="w-full pt-2 px-5">
                    <DataGrid
                        autoHeight
                        columns={newLinkDataCol}
                        rows={userData.data.newLinks}
                        hideFooter={true}
                        rowHeight={64}
                        components={{
                            NoRowsOverlay: () => (
                                <div className="flex justify-center p-4">
                                    <Typography color={'text.disabled'}>
                                        Create your first link
                                    </Typography>
                                </div>
                            ),
                            ColumnResizeIcon: () => {
                                return null
                            },
                        }}
                        sx={{
                            border: 'none',
                            '& .MuiDataGrid-columnHeaderTitle': {
                                fontWeight: 300,
                            },
                            '&	.MuiDataGrid-withBorderColor': {
                                borderBottom: '1px solid #f3f4f6',
                                ':focus': {
                                    border: 'none',
                                },
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                borderBottom: '1px solid #E5E7EB',
                            },
                            '.MuiDataGrid-row:hover': {
                                background: 'none',
                            },
                            '& .MuiDataGrid-row.Mui-selected': {
                                background: 'none',
                                ':hover': {
                                    background: 'none',
                                },
                            },
                        }}
                    ></DataGrid>
                </TabPanel>
            </div>
        </div>
    )
}
