import HelpWidget from '@Common/Components/HelpWidget'
import { PermanentLeftNav } from '@Common/Components/SideNav/PermanentLeftNav'
import { usePreviousPage } from '@Common/utils/previousPageCheck'
import useUserWorkspaces from './Onboard/query/useUserWorkspaces'
import useWorkspaces from './Onboard/query/useWorkspaces'

import Loading from '@Common/Components/Loading'
import useCustomAttribute from '@Common/Queries/GetQueries/GetCustomAttributes'
import useGetTableConfiguration from '@Common/Queries/GetQueries/GetTableConfiguration'
import useUsers from '@Common/Queries/GetQueries/GetUsers'
import useInit from '@Common/Queries/GetQueries/useInit'
import useTheme from '@Common/Queries/GetQueries/useTheme'
import useSearch from '@Common/Queries/MutationQueries/Search'
import { createContext, lazy } from 'react'
import { toast } from 'react-hot-toast'
import { Outlet, useNavigate } from 'react-router-dom'
import { useImmer } from 'use-immer'
import { v4 as uuidv4 } from 'uuid'
import { AssetStatus } from './AddAssets'
const UserOnboarding = lazy(() => import('./Onboard/UserOnboarding'))
export const MultipleUploadContext = createContext({
    assetsFiles: [],
    setAssetsFiles: {},
    handleFiles: {},
})
export function App() {
    const navigate = useNavigate()

    const [assetsFiles, setAssetsFiles] = useImmer<
        {
            file: File
            status: AssetStatus
            id: string
            error?: string
        }[]
    >([])

    const init = useInit()
    const workspaces = useWorkspaces()
    useUserWorkspaces()
    useUsers()
    useTheme()
    useCustomAttribute()
    if (init.isLoading) {
        return <Loading />
    }
    if (init.error) {
        return (
            <div className="flex justify-center items-center h-full">
                <h2>Encountered unexpected error. Please try again</h2>
            </div>
        )
    }
    const [filter, setFilter] = useImmer({
        searchTerm: '',
        filter: {
            assetName: '',
            projectId: [],
            ownerId: [],
            assetType: [],
            createdAt: { startDate: '', endDate: '' },
            updatedAt: { startDate: '', endDate: '' },
            customAttributeFilter: {},
        },
        currentPage: 0,
        pageSize: 12,
    } as Record<string, unknown>)
    const handleFiles = (files: File[]) => {
        const filesArray = Array.from(files)
        const fileData = [...assetsFiles]
        fileData.splice(0, fileData.length)
        filesArray.forEach((file) => {
            if (file.size / 1000000 > 20) {
                if (filesArray.length === 1) {
                    toast.error('Uploaded file exceeds the size limit of 20 MB')
                    return
                }
                fileData.push({
                    file,
                    status: AssetStatus.error,
                    error: 'Uploaded file exceeds the size limit of 20 MB',
                    id: uuidv4(),
                })
            }
            fileData.push({
                file,
                status: AssetStatus.initial,
                id: uuidv4(),
            })
        })
        setAssetsFiles((draft) => {
            return fileData
        })
    }

    return (
        <>
            <HelpWidget />
            <MultipleUploadContext.Provider
                value={{
                    assetsFiles: assetsFiles,
                    setAssetsFiles: setAssetsFiles,
                    handleFiles: handleFiles,
                }}
            >
                <div className="flex h-full w-full">
                    <PermanentLeftNav />
                    <Outlet context={[filter, setFilter]} />
                </div>
            </MultipleUploadContext.Provider>
        </>
    )
}
