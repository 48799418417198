import ClickAwayListener from '@mui/material/ClickAwayListener'
import isEmpty from 'lodash.isempty'
import { useImmer } from 'use-immer'

const NumberFilter = ({
    columnData,
    value,
    setFilter,
    setFilterList,
    handleClickAway,
}) => {
    const handleChange = (event, type) => {
        setFilterList((draft) => {
            const index = draft.findIndex(
                (e) => e.filterColumn.label === columnData.label
            )

            const data = draft[index].value ? draft[index].value : {}
            data[type] = event.target.value
            draft[index] = {
                filterColumn: columnData,
                value: data,
            }
        })
        setFilter((draft) => {
            const customFilter =
                draft.filter.customAttributeFilter.hasOwnProperty(columnData.id)
                    ? draft.filter.customAttributeFilter[columnData.id]
                    : {}
            if (event.target.value === '') {
                delete draft.filter.customAttributeFilter[columnData.id][type]
                draft.currentPage = 0
            } else {
                customFilter[type] = event.target.value
                draft.filter.customAttributeFilter[columnData.id] = customFilter
                draft.currentPage = 0
            }
        })
    }
    return (
        <>
            <ClickAwayListener
                onClickAway={() => handleClickAway(columnData.label)}
            >
                <div className="bg-white p-2">
                    <label className="text-neutral-400">{'Range'}</label>
                    <div className="flex my-1 w-full space-between">
                        <label className="text-neutral-400 mx-1">{'>'}</label>
                        <input
                            defaultValue={value ? value['from'] : ''}
                            type="number"
                            onChange={(e: any) => handleChange(e, 'from')}
                            className="border mx-3 focus:outline-none w-full"
                        ></input>
                    </div>{' '}
                    <div className="flex my-1 w-full space-between">
                        <label className="text-neutral-400 mx-1">{'<'}</label>
                        <input
                            type="number"
                            defaultValue={value ? value['to'] : ''}
                            onChange={(e: any) => handleChange(e, 'to')}
                            className="border mx-3 focus:outline-none w-full"
                        ></input>
                    </div>
                </div>
            </ClickAwayListener>
        </>
    )
}
export default NumberFilter
