import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import toast from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'

export const useUpdateDealRoom = () => {
    const queryClient = useQueryClient()
    return useMutation(
        (dealRoomData: {
            id: string
            data: {
                dealRoomName?: string
                description?: string
                opportunity?: string
                access?: string
                accountId?: string
                theme?: string
                dealStatus?: string
                flag?: boolean
                download? : boolean
            }
        }) => {
            return axios.put(
                `${API_URL}/dealroom/${dealRoomData.id}`,
                dealRoomData.data,
                {
                    headers: {
                        'Content-type': 'application/json',
                    },
                }
            )
        },
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries(['dealroom', data.data.id])
                toast.success('Settings updated succefully')
            },
            onMutate: (data) => {
                queryClient.cancelQueries(['dealroom', data.id])
                const dealroomData:
                    | {
                          dealRoom: any
                          thumbnail: Array<{ id: string; thumbnailUrl: string }>
                      }
                    | undefined = queryClient.getQueryData([
                    'dealroom',
                    data.id,
                ])
                if (!dealroomData) {
                    return
                }
                let properties = dealroomData.dealRoom.DealRoomProperties

                properties.name = data.data.dealRoomName
                    ? data.data.dealRoomName
                    : properties.name
                properties.description = data.data.description
                    ? data.data.description
                    : properties.description
                properties.accountId = data.data.accountId
                    ? data.data.accountId
                    : properties.account
                properties.opportunity = data.data.opportunity
                    ? data.data.opportunity
                    : properties.opportunity
                properties.public = data.data.flag
                    ? data.data.flag
                    : properties.public

                queryClient.setQueryData(['dealroom', data.id], {
                    dealRoom: {
                        ...dealroomData.dealRoom,
                        access: data.data.access
                            ? data.data.access
                            : dealroomData.dealRoom.access,
                        dealRoomStatus: data.data.dealStatus
                            ? data.data.dealStatus
                            : dealroomData.dealRoom.dealRoomStatus,
                        DealRoomProperties: properties,
                        theme: data.data.theme
                            ? data.data.theme
                            : dealroomData.dealRoom.theme,
                    },
                    thumbnail: dealroomData.thumbnail,
                })
            },
            onError: () => {},
        }
    )
}
