import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useQuery } from 'react-query'

export const useSummaryData = () => {
    return useQuery(['dashboard', 'summary'], async () => {
        const result = await axios.get(`${API_URL}/dashboard/summary`, {
            headers: {
                'Content-type': 'application/json',
            },
        })
        return result.data
    })
}
