import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useQuery } from 'react-query'

export const useDashboardVisits = (page: number, pageSize: number) => {
    return useQuery(['dashboard', 'visits', page], async () => {
        const result = await axios.post(
            `${API_URL}/dashboard/visit`,
            {
                currentPage: page,
                pageSize: pageSize,
            },
            {
                headers: {
                    'Content-type': 'application/json',
                },
            }
        )
        return result.data
    })
}
