import { Autocomplete, Button, Chip, TextField } from '@mui/material'
import { useImmer } from 'use-immer'

import Loading from '@Common/Components/Loading'
import useUsers from '@Common/Queries/GetQueries/GetUsers'
import useSearchGroups from '@Common/Queries/GetQueries/useSearchGroup'
import {
    Items,
    SelectInfo,
} from './../../ViewAssetScreen/Components/SelectInfo'
import useLocalStorage from '@Common/hooks/useLocalStorage'
import { Site } from './Permission'
import { useSitePermission } from '@Common/Queries/MutationQueries/AddSitePermission'
import { getPermissions } from './../../ViewAssetScreen/Components/Permission/permissions'
import useInit from '@Common/Queries/GetQueries/useInit'

export function AddMember({
    site,
    onClose,
}: {
    site: Site
    onClose: () => void
}) {
    const [permission, setPermission] = useImmer({
        members: [] as any[],
        permission: 'FULL_ACCESS',
        message: '',
    })
    const [search, setSearch] = useImmer({
        searchTerm: '',
    })
    const users = useUsers()
    const init = useInit()
    const sitePermission = useSitePermission({
        onSuccess: () => {
            onClose()
        },
    })
    const userOptions = users.data?.data
        .filter((user) => {
            const existingUser = site.SitePermission.find((permission) => {
                if (!permission.SiteUserPermission) {
                    return false
                }
                return permission.SiteUserPermission.userId === user.id
            })
            return !existingUser
        })
        .map((user) => ({
            name: user.name,
            type: 'Users',
            id: user.id,
        }))

    const groups = useSearchGroups(search)
    const groupOptions = groups.data?.data.groups
        .filter((group) => {
            const existingUser = site.SitePermission.find((permission) => {
                if (!permission.SiteGroupPermission) {
                    return false
                }
                return permission.SiteGroupPermission.groupId === group.id
            })
            return !existingUser
        })
        .map((group) => ({
            name: group.name,
            type: 'Groups',
            id: group.id,
        }))
    const [theme] = useLocalStorage('theme', {})
    const permissions = getPermissions(
        'add',
        init?.data.tenant?.TenantConfig?.type
    )
    return (
        <div className="flex flex-col gap-5">
            <div className="flex gap-2">
                <Autocomplete
                    options={userOptions.concat(groupOptions)}
                    getOptionLabel={(option) => option?.name}
                    groupBy={(option) => option?.type}
                    multiple
                    loading={groups.isLoading || users.isLoading}
                    className="w-56"
                    onChange={(e: any, newValue: any[], reason) => {
                        if (
                            reason === 'selectOption' ||
                            reason === 'removeOption'
                        ) {
                            setPermission((draft) => {
                                draft.members = newValue
                            })
                            return
                        }
                    }}
                    renderTags={(value: readonly unknown[], getTagProps) =>
                        value.map((option: unknown, index: number) => (
                            <Chip
                                variant="outlined"
                                color={
                                    option.type === 'Users'
                                        ? 'success'
                                        : 'secondary'
                                }
                                label={option.name}
                                {...getTagProps({ index })}
                            />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            onChange={(e) => {
                                setSearch((draft) => {
                                    draft.searchTerm = e.target.value
                                })
                            }}
                            {...params}
                        />
                    )}
                />
                <SelectInfo
                    variant="standard"
                    className="w-24"
                    value={permission.permission}
                    onChange={(e) => {
                        setPermission((draft) => {
                            draft.permission = e.target.value as string
                        })
                    }}
                    items={permissions}
                ></SelectInfo>
            </div>
            {/* <TextField
                label="Add a message"
                onChange={(e) => {
                    setPermission((draft) => {
                        draft.message = e.target.value
                    })
                }}
                multiline
                className="w-full"
            /> */}
            <div className="flex justify-end">
                <Button className="mr-4" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    disabled={sitePermission.isLoading}
                    variant="contained"
                    onClick={() => {
                        sitePermission.mutate({
                            siteId: site.id,
                            body: permission,
                        })
                    }}
                >
                    <div className="pr-2">
                        {sitePermission.isLoading && <Loading size="1rem" />}
                    </div>
                    Invite
                </Button>
            </div>
        </div>
    )
}
