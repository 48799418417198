import { useImmer } from 'use-immer'
import PopoverMultiSelect from '@Common/Components/PopoverMultiSelect'
const SelectFilter = ({ options, onChange, value }) => {
    return (
        <>
            <PopoverMultiSelect
                onChange={onChange}
                options={options}
                value={value || []}
                labelName="label"
            />
        </>
    )
}

export default SelectFilter
