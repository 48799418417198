import { useDealRoom } from '@Common/Queries/Dealroom/GetDealRoom'
import { useUpdateDealRoom } from '@Common/Queries/Dealroom/UpdateDealRoom'
import { Divider, Grid, TextField, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useImmer } from 'use-immer'
import DealRoomThemeGrid from './DealRoomThemeGrid'

const DealRoomEditRoom = () => {
    const { id } = useParams()
    const dealroom = useDealRoom({ id: id })

    const updateDealRoom = useUpdateDealRoom()

    const [settings, setSettings] = useImmer({
        name: dealroom?.data?.dealRoom?.DealRoomProperties.name,
        description: dealroom?.data?.dealRoom?.DealRoomProperties.description,
        opportunity: dealroom?.data?.dealRoom?.DealRoomProperties.opportunity,
        accountId: dealroom?.data?.dealRoom?.DealRoomProperties.accountId,
        theme: dealroom?.data?.dealRoom?.theme,
    })

    return (
        <div className="flex flex-col w-full h-full gap-5 overflow-hidden">
            <div className="flex flex-col">
                <Typography variant="subtitle2" className="p-4">
                    Edit room
                </Typography>
                <Divider className="w-full"></Divider>
            </div>
            <div className="flex flex-col w-full h-full gap-5 overflow-auto">
                <div className="flex flex-col w-full gap-5 px-5">
                    <TextField
                        className="w-full"
                        data-testid="dealroom-title-input"
                        variant="outlined"
                        placeholder="Room title"
                        disabled={dealroom.data.dealRoom.action === 'VIEW'}
                        defaultValue={
                            dealroom?.data?.dealRoom?.DealRoomProperties.name
                        }
                        value={settings.name}
                        onChange={(e) => {
                            setSettings((draft) => {
                                draft.name = e.target.value
                            })
                        }}
                        onBlur={(event) => {
                            updateDealRoom.mutate({
                                id: id,
                                data: {
                                    dealRoomName: event.target.value,
                                },
                            })
                        }}
                    ></TextField>
                    <TextField
                        className="w-full"
                        placeholder="Description"
                        variant="outlined"
                        data-testid="dealroom-description-input"
                        multiline
                        maxRows={4}
                        defaultValue={
                            dealroom?.data?.dealRoom?.DealRoomProperties
                                .description
                        }
                        value={settings.description}
                        onChange={(e) => {
                            setSettings((draft) => {
                                draft.description = e.target.value
                            })
                        }}
                        onBlur={(event) => {
                            updateDealRoom.mutate({
                                id: id,
                                data: {
                                    description: event.target.value,
                                },
                            })
                        }}
                        disabled={dealroom.data.dealRoom.action === 'VIEW'}
                    ></TextField>
                </div>
                <div className="flex flex-col w-full gap-3 px-5">
                    <Typography variant="body2" color={'text.secondary'}>
                        Select background
                    </Typography>
                    <DealRoomThemeGrid
                        id={id}
                        updateDealRoom={updateDealRoom}
                    />
                </div>
            </div>
        </div>
    )
}
export default DealRoomEditRoom
