import {
    Divider,
    ListItemText,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
} from '@mui/material'
import Button from '@mui/material/Button'
import { RefObject, useRef } from 'react'

import AvatarComponent from '@Common/Components/Avatar'
import Loading from '@Common/Components/Loading'
import useSwitchWorkspace from '@Common/Components/SideNav/useSwitchWorkspace'
import useInit from '@Common/Queries/GetQueries/useInit'
import { Add, ArrowDropDown, ExpandMore } from '@mui/icons-material'
import {
    Link,
    Outlet,
    useMatches,
    useNavigate,
    useOutletContext,
} from 'react-router-dom'
import useUserWorkspaces from 'src/Onboard/query/useUserWorkspaces'
import { useImmer } from 'use-immer'
import useSaveFile from '@Common/Queries/MutationQueries/SaveFile'
import { startOfToday } from 'date-fns'
import { toast } from 'react-hot-toast'
import useFile from '@Common/Queries/MutationQueries/file'
import useUploadFile from '@Common/Queries/MutationQueries/uploadFile'
import useAddAsset from '@Common/Queries/MutationQueries/AddAsset'
import { AssetStatus } from 'src/Upload/AssetUploadInput'
import { Permission } from '@Common/Permission'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import { redirectPage } from '@Common/utils/previousPageCheck'
import Avatar from '@Common/Avatar'

export function PluginIndex() {
    const button = useRef<HTMLButtonElement>(null)
    const [tenantMenu, setTenantMenu] = useImmer({ show: false })
    const [upload, setUpload] = useImmer<{
        file?: File
        status?: AssetStatus
        progress?: number
        error?: string
    }>({})
    const navigate = useNavigate()
    const sendDataToGTM = useGTMDispatch()
    const onUploadProgress = (progressEvent: { loaded: any; total: any }) => {
        const { loaded, total } = progressEvent
        const percent = Math.floor((loaded * 100) / total)
        setUpload((draft) => {
            draft.progress = percent
        })
    }
    const init = useInit()
    const matches = useMatches()

    const onSaveAsset = async (data: any, val: any) => {
        const file = val.file
        const formData = new FormData()

        formData.append('file', file)
        formData.append('fields', data.data.file)
        const obj = {
            url: data?.data?.preSignedUrl,
            body: file,
            data: data.data.file,
            file: file,
            id: val.id,
        }

        if (data.data.preSignedUrl) {
            uploadFile(obj)
        }
    }

    const onAddAssetSuccess = (res: any, variables: { id: string }) => {
        var params = new URLSearchParams()
        const assetId = res.data.id
        params.append('fileId', assetId)
        const configUrl = '/plugin/configuration?' + params.toString()
        navigate(configUrl)
    }
    const onErrorAddAsset = (err: any, variables: any) => {
        setUpload((draft) => {
            draft.status = AssetStatus.error
            draft.error = 'Create asset not successful'
        })
    }

    const onUploadSuccess = (res: any, variables: any) => {
        setUpload((draft) => {
            draft.status = AssetStatus.uploaded
        })

        const body = {
            name: variables?.file?.name,
            shareable: false,
            fileId: variables?.data?.id,
            assetTypeId: '1',
            access: Permission.VIEW,
        }
        sendDataToGTM({
            event: 'asset_add',
            asset_id: res.data.id,
            asset_name: res.data.name,
        })
        addAsset({
            ...variables,
            fileDetails: variables.data.id,
            body: body,
        })
    }

    const onErrorAsset = (err: any, variables: any) => {
        console.log(JSON.stringify(err))
        setUpload((draft) => {
            draft.status = AssetStatus.error
            draft.error =
                err.response.status === 422
                    ? 'File Type not supported'
                    : 'Create asset not successful'
        })
    }
    const option = (obj: any) => {
        return {
            onUploadProgress: (progressEvent: { loaded: any; total: any }) => {
                const { loaded, total } = progressEvent
                var percent = Math.floor((loaded * 100) / total)
                setUpload((draft) => {
                    draft.progress = percent
                })
            },
            headers: { 'Content-Type': 'multipart/form-data' },
        }
    }
    const onUploadError = (err: any, variables: any) => {
        setUpload((draft) => {
            draft.status = AssetStatus.error
            if (err.response.data.search('EntityTooLarge') >= 0) {
                draft.error = 'File exceeded the 50MB size limit'
                return
            }
            draft.error = 'Upload file not successful'
        })
    }
    const getUploadLink = useFile({
        onSuccess: onSaveAsset,
        onError: onErrorAsset,
    })
    const { mutate: uploadFile } = useUploadFile({
        onSuccess: onUploadSuccess,
        option: option,
        onError: onUploadError,
    })
    const { mutate: addAsset } = useAddAsset({
        onSuccess: onAddAssetSuccess,
        onError: onErrorAddAsset,
    })

    const getTitle = () => {
        if (matches.length < 0) {
            return ''
        }
        return matches[matches.length - 1].handle?.name
    }

    const supportedTrackFormat = ['pdf', 'ppt', 'pptx', 'doc', 'docx']
    function allowTrack(filename: string) {
        const expression = /\.([0-9a-z]+)(?:[\?#]|$)/
        const result = filename.toLowerCase().match(expression)
        return (
            result &&
            result[1] &&
            supportedTrackFormat.findIndex((format) => format === result[1]) >=
                0
        )
    }

    const handleFileUpload = (file: File) => {
        if (file.size / 1000000 > 20) {
            toast.error('File is larger than 50MB are not supported')
            return
        }
        if (!allowTrack(file.name)) {
            toast.error('This file format is not supported')
            return
        }
        const formData = new FormData()
        formData.append('file', file)
        getUploadLink.mutate({ file })

        setUpload((draft) => {
            draft.file = file
            draft.status = AssetStatus.uploading
        })
        navigate('/plugin/upload')
    }
    if (init.isLoading) {
        return <Loading />
    }
    return (
        <div className=" flex w-full flex-col h-full">
            <div className="flex flex-row w-full border-b border-gray-400 bg-gray-100 p-2 h-16">
                <div className="flex w-full h-full font-medium text-gray-600 pl-5 items-center">
                    {getTitle()}
                </div>

                <div className="flex text-gray-600 items-center ">
                    <div className="flex-1 items-center float-right"></div>
                </div>
                
            </div>
            <div className="flex-1 overflow-hidden">
                <Outlet context={{ handleFileUpload, upload }} />
            </div>
        </div>
    )
}
type ContextType = {
    handleFileUpload: (file: File) => void
    upload: {
        file?: File | undefined
        status: AssetStatus
        progress?: number | undefined
        error?: string | undefined
    }
}

export function useUpload() {
    return useOutletContext<ContextType>()
}
