const supportedPreviewFormat = [
	'pdf',
	'png',
	'svg',
	'jpeg',
	'jpg',
	'ppt',
	'pptx',
	'doc',
	'docx',
]
const supportedTrackFormat = ['pdf', 'ppt', 'pptx', 'doc', 'docx']
export function allowPreview(url: string) {
	const expression = /\.([0-9a-z]+)(?:[\?#]|$)/
	const result = url.toLowerCase().match(expression)
	return (
		result &&
		result[1] &&
		supportedPreviewFormat.findIndex((format) => format === result[1]) >= 0
	)
}
export function allowTrack(url: string) {
	const expression = /\.([0-9a-z]+)(?:[\?#]|$)/
	const result = url.toLowerCase().match(expression)
	return (
		result &&
		result[1] &&
		supportedTrackFormat.findIndex((format) => format === result[1]) >= 0
	)
}
