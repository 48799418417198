import { schema } from 'prosemirror-schema-basic'
import { Plugin } from 'prosemirror-state'
import { ProseMirror, useProseMirror } from 'use-prosemirror'

import { assetNode } from '@Common/Schema'
import autocomplete, {
    ActionKind,
    FromTo,
    Options,
} from 'prosemirror-autocomplete'
import { buildInputRules, exampleSetup } from 'prosemirror-example-setup'
import { InputRule, inputRules } from 'prosemirror-inputrules'
import { Fragment, Node, Slice } from 'prosemirror-model'
import { EditorView } from 'prosemirror-view'
import { forwardRef, useCallback, useEffect } from 'react'
import { Updater, useImmer } from 'use-immer'
import './SitesEditor.css'
import { Suggestions } from './Suggestions'

import useSearch from '@Common/Queries/GetQueries/SiteSearchAssets'
import usePostContent from '@Common/Queries/MutationQueries/PostSiteContent'
import { nodeViews, siteSchema } from '@Common/Schema'
import throttle from 'lodash.throttle'
import { useParams } from 'react-router-dom'
import { canEdit } from 'src/ViewAssetScreen/util'
import { CustomMenuBar } from './CustomMenuBar'

interface SitesEditorProps {
    content: {
        doc: Node
        selection: Selection
    }
    setSavedStatus?: Updater<string>
    editable: boolean
    showMenu?: boolean
    action?: string
}
export default forwardRef(function SitesEditor(props: SitesEditorProps, ref) {
    const nodes = schema.spec.nodes
    const nodeAdded = nodes.append({
        asset: assetNode.asset,
    })

    const { id } = useParams()

    const postContent = usePostContent({
        onSuccess: () => props?.setSavedStatus('Saved'),
        onMutate: () => props?.setSavedStatus('Saving...'),
    })

    const [picker, setPicker] = useImmer({
        view: null as EditorView | null,
        open: false,
        current: 0,
        filter: '',
        suggestionLength: 0,

        anchorEl: null,
        range: null as FromTo | null,
        addThumbnail: false,
    })
    useEffect(() => {
        if (picker.addThumbnail) {
            if (suggestion.data.assets.length > 0) {
                const item = suggestion.data.assets[picker.current]
                const view = ref?.current?.view
                if (!view) return
                const node = view.state.schema.nodes.asset.create({
                    id: item.id,
                    fileId: item.fileId,
                })

                const tr = view.state.tr

                    .deleteRange(picker.range.from, picker.range.to)
                    .insert(picker.range.from, node)
                view.dispatch(tr)
                setPicker((draft) => {
                    draft.addThumbnail = false
                })
            } else {
                setPicker((draft) => {
                    draft.open = false
                    draft.addThumbnail = false
                })
            }
        }
    }, [picker.addThumbnail])

    const handleSearch = (action) => {
        let searchStr = ''
        if (action.filter) {
            searchStr = action.filter
        }
        setPicker((draft) => {
            return {
                open: true,
                current: 0,
                filter: searchStr,
                anchorEl: action.view?.dom.querySelector('.autocomplete'),
                view: action.view,
                range: action.range,
                addThumbnail: false,
            }
        })
    }

    const showSuggestion = (action) => {
        setPicker((draft) => {
            return {
                view: action.view,
                range: action.range,
                anchorEl: action.view?.dom.querySelector('.autocomplete'),
                open: true,
                current: 0,
            }
        })
    }
    const closeSuggestion = () => {
        setPicker((draft) => {
            return {
                open: false,
            }
        })
    }
    const addThumbnail = () => {
        console.log(picker)
        setPicker((draft) => {
            draft.addThumbnail = true
        })
    }

    const options: Options = {
        triggers: [{ name: 'mention', trigger: /(@)$/ }],
        reducer: function (action) {
            console.log(action.kind)
            switch (action.kind) {
                case ActionKind.open:
                    showSuggestion(action)
                    return true
                case ActionKind.close:
                    closeSuggestion()
                    return true
                case ActionKind.filter:
                    handleSearch(action)
                    return true
                case ActionKind.up:
                    setPicker((draft) => {
                        let curr = draft.current
                        if (curr === 0) return
                        curr -= 1
                        curr += 10
                        curr %= 10
                        draft.current = curr
                    })
                    return true
                case ActionKind.down:
                    setPicker((draft) => {
                        let current = draft.current
                        if (current === draft.suggestionLength - 1) return
                        console.log(current)
                        current += 1
                        current %= 10
                        draft.current = current
                    })
                    return true
                case ActionKind.enter:
                    addThumbnail()

                    return true

                default:
                    break
            }
        },
    }

    const handleDocumentChange = (doc, transaction) => {
        postContent.mutate({
            siteId: id,
            content: { doc: doc },
        })
    }
    const debouncedSave = useCallback(
        throttle(handleDocumentChange, 3000, {}),
        []
    )
    const suggestion = useSearch({
        body: {
            searchTerm: picker.filter,
            filter: {
                assetName: '',
                projectId: [],
                ownerId: [],
                assetType: [],
                createdAt: { startDate: '', endDate: '' },
                updatedAt: { startDate: '', endDate: '' },
                customAttributeFilter: {},
            },
            currentPage: 0,
            pageSize: 10,
        },
        onSuccess: (result) => {
            setPicker((draft) => {
                draft.suggestionLength = result.assets.length
            })
        },
    })
    const HTTP_LINK_REGEX =
        /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/
    const linkify = function (fragment: Fragment): Fragment {
        var linkified: Node[] = []
        fragment.forEach(function (child: Node) {
            if (child.isText) {
                const text = child.text as string
                let pos = 0,
                    match

                while ((match = HTTP_LINK_REGEX.exec(child.text.slice(pos)))) {
                    var start = match.index
                    var end = start + match[0].length
                    var link = child.type.schema.marks['link']

                    if (start > 0) {
                        linkified.push(child.cut(pos, start))
                    }

                    const urlText = text.slice(start, end)
                    linkified.push(
                        child
                            .cut(start, end)
                            .mark(
                                link
                                    .create({ href: urlText })
                                    .addToSet(child.marks)
                            )
                    )
                    pos = end
                }

                // copy over whatever is left
                if (pos < text.length) {
                    linkified.push(child.cut(pos))
                }
            } else {
                const link = linkify(child.content)
                linkified.push(child.copy(link))
            }
        })

        return Fragment.fromArray(linkified)
    }
    const linkPlugin = new Plugin({
        props: {
            transformPasted: (slice: Slice) => {
                return new Slice(
                    linkify(slice.content),
                    slice.openStart,
                    slice.openEnd
                )
            },
        },
    })

    const plugins = [
        ...autocomplete(options),
        linkPlugin,
        buildInputRules(siteSchema),
        inputRules({
            rules: [
                new InputRule(
                    /^(---|—-|___\s|\*\*\*\s)$/,
                    (state, match, start, end) => {
                        const $para = state.doc.resolve(start)
                        let tr
                        if ($para.after() == $para.end(-1)) {
                            // if the paragraph is the last child of its parent, then insert a
                            // new paragraph
                            tr = state.tr.replaceWith(start, end, [
                                state.schema.nodes.horizontal_rule.create(),
                                state.schema.nodes.paragraph.create(),
                            ])
                        } else {
                            tr = state.tr.replaceWith(
                                start,
                                end,
                                state.schema.nodes.horizontal_rule.create()
                            )
                        }

                        return tr
                    }
                ),
            ],
        }),

        ...exampleSetup({
            schema: siteSchema,
            menuBar: false,
        }),
    ]

    const [state, setState] = useProseMirror({
        schema: siteSchema,
        plugins: plugins,
        doc: props.content?.doc
            ? Node.fromJSON(siteSchema, props.content?.doc)
            : Node.fromJSON(siteSchema, {
                  type: 'doc',
                  content: [
                      {
                          type: 'heading',
                          attrs: { level: 1 },
                          content: [{ text: 'Title', type: 'text' }],
                      },
                  ],
              }),
    })

    return (
        <>
            {props.showMenu && canEdit(props.action) && (
                <CustomMenuBar
                    editor={state}
                    view={ref?.current?.view}
                    schema={siteSchema}
                    setPicker={setPicker}
                ></CustomMenuBar>
            )}
            <ProseMirror
                ref={ref}
                state={state}
                editable={() => props.editable}
                nodeViews={nodeViews}
                onChange={(state) => {
                    setState(state)
                    if (props.editable) {
                        debouncedSave(state.doc, state.tr)
                    }
                }}
                className={`sites ${
                    props.editable || props.editable === undefined
                        ? 'siteseditable'
                        : 'noteditable'
                }`}
            />
            <Suggestions
                picker={picker}
                editorRef={ref}
                schema={siteSchema}
                result={suggestion}
            ></Suggestions>
        </>
    )
})
