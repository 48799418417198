import { useQuery, useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'

export default function useDeletePermission(props?: any) {
    const queryClient = useQueryClient()
    return useMutation<
        any,
        Error,
        { assetId: string; assetPermissionId: string }
    >(
        (obj) =>
            axios.delete(
                `${APi_URL}/assets/${obj.assetId}/permission/${obj.assetPermissionId}`,
                { headers: { 'Content-Type': 'application/json' } }
            ),
        {
            onSuccess: (data: any, obj: any) => {
                if (props && props.onSuccess) {
                    props.onSuccess(data)
                }
            },
            onMutate: (obj) => {
                queryClient.cancelQueries(['assets', obj.assetId, 'details'])
                queryClient.setQueryData(
                    ['assets', obj.assetId, 'details'],
                    (oldData) => {
                        const permissionIndex =
                            oldData.AssetPermission.findIndex((permission) => {
                                return permission.id === obj.assetPermissionId
                            })
                        oldData.AssetPermission.splice(permissionIndex, 1)
                        return oldData
                    }
                )
            },
            onSettled: (data, error, obj) => {
                queryClient.invalidateQueries([
                    'assets',
                    obj.assetId,
                    'details',
                ])
            },
        }
    )
}
