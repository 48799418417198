import AvatarComponent from '@Common/Components/Avatar'
import Loading from '@Common/Components/Loading'
import useRequestData from '@Request/query/GetRequestData'
import useUsers from '@Common/Queries/GetQueries/GetUsers'
import useInit from '@Common/Queries/GetQueries/useInit'
import useDebounce from '@Common/hooks/useDebounce'
import useTitle from '@Common/hooks/useTitle'
import {
    Button,
    Chip,
    InputAdornment,
    OutlinedInput,
    TablePagination,
    Typography,
} from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { useImmer } from 'use-immer'
import SearchIcon from '@mui/icons-material/Search'
import FeedBackPopper from '@Search/components/FeedbackPopper'
import DoneIcon from '@mui/icons-material/Done'
import { formattedDate, raisedBy } from './components/common/Functions'
import useLocalStorage from '@Common/hooks/useLocalStorage'
import { useNavigate } from 'react-router-dom'
import logo from '../assets/cognition-purple.png'

export default function RequestPage() {
    const navigate = useNavigate()
    const [filter, setFilter] = useImmer({
        searchText: '',
        requestType: 'ALL',
        requestStatus: 'ALL',
        type: 'ALL',
        currentPage: 0,
        pageSize: 15,
    })

    const [theme, setTheme] = useLocalStorage('theme', {
        tenant: {
            name: '',
        },
    })

    const [open, setOpen] = useImmer(false)
    const [feedback, setFeedback] = useImmer({
        open: false,
        anchorEl: null,
    })
    const columns: GridColDef[] = [
        {
            headerName: 'Request',
            field: 'request',
            minWidth: 150,
            flex: 1,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <Typography className="w-50 truncate">
                        {params.row.request}
                    </Typography>
                )
            },
        },
        {
            headerName: 'Raised by',
            field: 'raisedBy',
            minWidth: 150,
            flex: 1,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <div className="flex flex-row">
                        <AvatarComponent
                            sx={{
                                width: 24,
                                height: 24,
                                fontSize: 12,
                            }}
                            name={params.row.raisedBy}
                        ></AvatarComponent>
                        <div className="pl-2 w-40 truncate">
                            {params.row.raisedBy}
                        </div>
                    </div>
                )
            },
        },
        {
            headerName: 'Raised on',
            field: 'raisedOn',
            minWidth: 150,
            flex: 1,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
        },
        {
            headerName: 'Status',
            field: 'status',
            minWidth: 150,
            flex: 1,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                return <Typography>{params.row.status}</Typography>
            },
        },
    ]

    const init = useInit()
    const debouncedFilter = useDebounce(filter)
    const requestedData = useRequestData(debouncedFilter)

    useTitle(`${theme?.tenant?.name ? theme?.tenant?.name : ''} - Requests `)

    const usersData = useUsers()
    if (requestedData.isLoading || init.isLoading || usersData.isLoading) {
        return <Loading></Loading>
    }

    const userRole = init.data.user.UserRole.filter((value) => {
        return value.role.role !== 'User'
    })

    const rows = requestedData.data.requests.map((value) => {
        return {
            id: value.id,
            request: value.description,
            raisedBy: raisedBy(value.requestedUserId, usersData.data),
            raisedOn: formattedDate(value.updatedAt),
            status: value.status,
        }
    })

    return (
        <>
            <div className="flex flex-col w-full overflow-hidden">
                <div className="flex items-center px-6 pb-4">
                    <div className="flex-1 flex items-center">
                        <div className="w-44">
                            <img src={logo} />
                        </div>
                        <div className="flex gap-2">
                            <Typography variant={'h5'}>|</Typography>
                            <Typography variant={'h5'}>Requests</Typography>
                        </div>
                    </div>

                    <div className="pr-4">
                        <OutlinedInput
                            data-testid="request-searchbar"
                            type="text"
                            placeholder="Search"
                            size="small"
                            onInput={(e: any) =>
                                setFilter((draft) => {
                                    draft.searchText = e.target.value
                                })
                            }
                            defaultValue={filter.searchText}
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            }
                        />
                    </div>
                    <div>
                        <Button
                            data-testid="request-raiserequest-btn"
                            variant="contained"
                            color="primary"
                            onClick={(e) => {
                                setFeedback((draft) => {
                                    draft.open = true
                                    draft.anchorEl = e.currentTarget
                                })
                            }}
                        >
                            {'Raise Request'}
                        </Button>
                        <FeedBackPopper
                            feedback={feedback}
                            setFeedback={setFeedback}
                        ></FeedBackPopper>
                    </div>
                </div>

                <div className="h-full flex flex-col overflow-hidden px-6">
                    <div className="flex py-4">
                        {userRole.length !== 0 && (
                            <Chip
                                data-testid="request-isopen-chip"
                                label="Is open"
                                size="medium"
                                sx={{
                                    borderRadius: 1,
                                }}
                                clickable
                                color={open ? 'primary' : 'default'}
                                variant="outlined"
                                onClick={() => {
                                    setFilter((draft) => {
                                        draft.requestStatus = open
                                            ? 'ALL'
                                            : 'OPEN'
                                        draft.currentPage = 0
                                    })

                                    setOpen(!open)
                                }}
                                icon={open ? <DoneIcon /> : null}
                            />
                        )}
                    </div>

                    <DataGrid
                        rows={rows}
                        columns={columns}
                        hideFooter={true}
                        sx={{
                            border: 0,
                            '.MuiDataGrid-row:hover': {
                                cursor: 'pointer',
                            },
                        }}
                        onRowClick={(params) => {
                            navigate(`/requests/${params.id}`)
                        }}
                        slots={{
                            noRowsOverlay: () => (
                                <div className="flex justify-center p-4">
                                    No results found
                                </div>
                            ),
                        }}
                    ></DataGrid>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        colSpan={3}
                        component="div"
                        className="text-xs text-lightTextDisabled border-none overflow-hidden"
                        count={requestedData.data.count}
                        rowsPerPage={filter.pageSize}
                        page={filter.currentPage}
                        onPageChange={(event, newPage) => {
                            setFilter((draft) => {
                                draft.currentPage = newPage
                            })
                        }}
                        backIconButtonProps={{
                            className: 'stroke-slate-50',
                        }}
                        nextIconButtonProps={{
                            className: 'stroke-slate-50',
                        }}
                    />
                </div>
            </div>
        </>
    )
}
