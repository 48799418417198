import AvatarComponent from '@Common/Components/Avatar'
import useUsers from '@Common/Queries/GetQueries/GetUsers'
import { Button, Typography } from '@mui/material'

import useDeleteComments from '@Request/query/useDeleteComment'
import { formatDistanceToNow } from 'date-fns'
import { FC } from 'react'
import CommentsEditor from '@Common/Components/Editor/CommentsEditor'

export interface EntityOption {
    name: 'assets' | 'requests'
    id: string
}
const Comments: FC<{
    comments: {
        commentId: string
        assetId: string
        Comment: {
            user: {
                id: string
                name: string
            }
            comment: string
            createdAt: string
        }
    }[]
    option: EntityOption
    isAssetApprove: boolean
    assetRequestDetails?: {
        assetId: string
        requestId: string
        fileId: string
        assetName: string
    }
}> = ({ comments, option, isAssetApprove, assetRequestDetails }) => {
    const user = useUsers()
    const deleteMutation = useDeleteComments()
    return (
        <div className="flex flex-col max-w-[672px]">
            {comments.map((comment, id) => (
                <div key={comment.commentId} className="w-full ">
                    <div className="flex">
                        <AvatarComponent
                            name={comment.Comment.user.name}
                            className="my-auto"
                            id={id}
                        ></AvatarComponent>
                        <div className="flex pl-4 w-full justify-between pb-1">
                            <div className="flex flex-col">
                                <Typography
                                    className={
                                        'text-sm my-auto pr-4 font-medium'
                                    }
                                >
                                    {comment.Comment.user.name}
                                </Typography>
                                <Typography
                                    className={'text-xs my-auto'}
                                    color="text.disabled"
                                >
                                    {formatDistanceToNow(
                                        new Date(comment.Comment.createdAt),
                                        {
                                            addSuffix: true,
                                        }
                                    )}
                                </Typography>
                            </div>
                            <Button
                                onClick={() =>
                                    deleteMutation.mutate({
                                        commentId: comment.commentId,
                                        entityOption: option,
                                    })
                                }
                                size="small"
                            >
                                Delete
                            </Button>
                        </div>
                    </div>

                    <div className="max-h-40 overflow-auto comment">
                        {' '}
                        <CommentsEditor
                            users={user.data}
                            style={{ margin: '0px', paddingLeft: '48px' }}
                            editable={false}
                            content={comment.Comment.comment}
                        />
                    </div>
                    {isAssetApprove &&
                        comment.Comment.user.id ===
                            '32i0uv0s5663u3c1mfur7esrjv' &&
                        (comment.Comment.comment ===
                            'Sanitisation Process: Attribute Detection - Completed' ||
                            comment.Comment.comment ===
                                'Attribute  Detection  completed') && (
                            <div className="mb-2">
                                <a
                                    target="_blank"
                                    className="ml-4 underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                                    href={`https://d3ezovze23gnjl.cloudfront.net?assetid=${assetRequestDetails?.assetId}&fileId=${assetRequestDetails?.fileId}&requestid=${assetRequestDetails?.requestId}&assetName=${assetRequestDetails?.assetName}`}
                                    rel="noreferrer"
                                >
                                    Click here to sanitize the file
                                </a>
                            </div>
                        )}
                </div>
            ))}
        </div>
    )
}

export default Comments
