import Loading from '@Common/Components/Loading'
import PageHeader from '@Common/Components/PageHeader/PageHeader'
import useFeatureFlag from '@Common/hooks/useFeatureFlag'
import useLocalStorage from '@Common/hooks/useLocalStorage'
import useTitle from '@Common/hooks/useTitle'
import useInit from '@Common/Queries/GetQueries/useInit'
import { Tab, Tabs } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useImmer } from 'use-immer'
import Attributes from './components/customAttribute/Attributes'
import ManageGroups from './components/ManageGroups'
import Members from './Members'
import MyAccount from './MyAccount'
import Workspace from './Workspace'
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}
const Setting: React.FC = () => {
    const [theme, setTheme] = useLocalStorage('theme', {
        tenant: {
            name: '',
        },
    })
    useTitle(`${theme?.tenant?.name ? theme?.tenant?.name : ''} - Setting `)
    const init = useInit()
    const workspace = useFeatureFlag('workspace_type')
    const [tabs, setTabs] = useImmer({
        index: 0,
    })
    if (init.isLoading) {
        return <Loading></Loading>
    }
    return (
        <div className="w-full flex flex-col h-full">
            <PageHeader title="Settings" />
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'rgba(0, 0, 0, 0.12)',
                }}
            >
                <Tabs
                    value={tabs.index}
                    onChange={(event, newValue) => {
                        setTabs((draft) => {
                            draft.index = newValue
                        })
                    }}
                >
                    <Tab value={0} label="My account" {...a11yProps(0)}></Tab>
                    {workspace && (
                        <Tab
                            value={1}
                            label="Workspace"
                            {...a11yProps(1)}
                        ></Tab>
                    )}
                    <Tab value={2} label="Members" {...a11yProps(2)}></Tab>
                    <Tab value={3} label="Groups" {...a11yProps(3)}></Tab>
                    <Tab value={4} label="Attributes" {...a11yProps(4)}></Tab>
                </Tabs>
            </Box>
            <TabPanel value={tabs.index} index={0}>
                <MyAccount />
            </TabPanel>
            {workspace && (
                <TabPanel value={tabs.index} index={1}>
                    <Workspace />
                </TabPanel>
            )}
            <TabPanel
                children={<Members />}
                value={tabs.index}
                index={2}
            ></TabPanel>
            <TabPanel value={tabs.index} index={3}>
                <ManageGroups />
            </TabPanel>
            <TabPanel value={tabs.index} index={4}>
                <Attributes />
            </TabPanel>
        </div>
    )
}

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            className="h-full w-full p-4"
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </div>
    )
}

export default Setting
