import ColumnsMenuIcon from '@Search/components/ColumnsMenuIcon'
import {
    Column,
    ColumnDef,
    ColumnOrderState,
    ColumnResizeMode,
    ColumnSizingState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    Header,
    useReactTable,
    VisibilityState,
} from '@tanstack/react-table'

import useFeatureFlag from '@Common/hooks/useFeatureFlag'
import { Chip, Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import AssetNameCell from '@Search/components/AssetNameCell'
import classNames from 'classnames'
import { WritableDraft } from 'immer/dist/internal'
import isEmpty from 'lodash.isempty'
import { FC } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Link, useNavigate } from 'react-router-dom'
import { Updater, useImmer } from 'use-immer'
import useCustomAttribute from '@Common/Queries/GetQueries/GetCustomAttributes'
import { searchDataIntoViewAsset } from '@Search/utils/searchTableUpdater'
import { useQueryClient } from 'react-query'
import useUsers from '@Common/Queries/GetQueries/GetUsers'
import useGroups from '@Common/Queries/GetQueries/GetGroupsData'
import { Asset } from 'src/ViewAssetScreen/Components/ViewAsset'

interface TableProperties {
    showOptions: boolean
    data: any
    columns: any
    filter: Record<string, unknown>
    setFilter: Updater<Record<string, unknown>>
    totalCount: number
    noResults?: any
    tableConfiguration?: Record<string, any>
    filterList?: Record<string, unknown>
    tableConfigCallback?: any
    isModal?: boolean
    addSearchedProperties?: any
    onClickOfRow?: any
    asset: { fileId: string; assetId: string; data: Asset }
    setAsset: Updater<{ fileId: string; assetId: string; data: Asset }>
    columnState: any
    setColumnState: any
    usersData: any
}

interface DragItem {
    index: number
    id: string
    type: string
}

const DraggableColumnHeader: FC<{
    header: Header<unknown, unknown>
    table: any
    columnResizeMode: ColumnResizeMode
    findColumn: any
    moveColumn: any
}> = ({ header, table, columnResizeMode, findColumn, moveColumn }) => {
    const { columnOrder, columnSizingInfo } = table.getState()
    const originalIndex = findColumn(header.column.id).index
    const [{ isDragging }, dragRef, previewRef] = useDrag({
        type: 'column',
        item: () => {
            return {
                id: header.column.id,
                originalIndex,
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
            console.log('Hover', item.id, header.column.id)
            const { id: droppedId, originalIndex } = item
            const didDrop = monitor.didDrop()
            if (!didDrop) {
                moveColumn(droppedId, originalIndex, table)
            }
        },

        canDrag:
            header.column.columnDef.isDraggable &&
            !header.column.getIsResizing(),
    })
    const [{ isOver, canDrop }, dropRef] = useDrop({
        accept: 'column',
        hover({ id: draggedId }: { id: string; originalIndex: number }) {
            if (
                !header.column.columnDef.isDraggable ||
                header.column.getIsResizing()
            ) {
                return
            }

            if (draggedId !== header.column.id) {
                const { index: overIndex } = findColumn(header.column.id)
                moveColumn(draggedId, overIndex, table)
            }
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
        }),
    })

    return (
        <TableCell
            ref={dropRef}
            colSpan={header.colSpan}
            style={{
                opacity: isDragging ? 0.5 : 1,
                width: header.getSize(),
                height: '36px',
            }}
            scope="col"
            size="small"
            className={classNames('p-0 bg-white', {
                'bg-gray-200': isOver && canDrop,
            })}
        >
            <div
                className="flex m-0 w-full h-full"
                style={{ width: header.getSize() }}
            >
                <div className="flex w-full flex-1 h-full hover:bg-hoverBackground">
                    <div
                        className="flex hover:cursor-pointer w-full"
                        ref={dragRef}
                    >
                        <Typography
                            ref={previewRef}
                            color="text.disabled"
                            className="font-normal text-sm p-4"
                        >
                            {header.isPlaceholder
                                ? null
                                : flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                  )}
                        </Typography>
                    </div>
                </div>
                {header.column.getCanResize() && (
                    <div
                        {...{
                            onMouseDown: header.getResizeHandler(),
                            className: classNames(
                                'absolute h-10 right-0 w-1 right-0 top-1 bg-gray-300 cursor-col-resize opacity-0 hover:opacity-100',
                                {
                                    'bg-filterButtonColor opacity-100':
                                        header.column.getIsResizing(),
                                }
                            ),
                            style: {
                                transform:
                                    columnResizeMode === 'onChange' &&
                                    header.column.getIsResizing()
                                        ? `translateX(${columnSizingInfo.deltaOffset}px)`
                                        : '',
                            },
                        }}
                    ></div>
                )}
            </div>
        </TableCell>
    )
}
export default function TableComponent(props: TableProperties) {
    const {
        showOptions,
        data,
        columns,
        filter,
        setFilter,
        noResults,
        tableConfiguration,
        tableConfigCallback,
        asset,
        columnState,
        setColumnState,
        usersData,
    } = props
    const navigate = useNavigate()

    const customAttribute = useCustomAttribute()
    const users = usersData
    const queryClient = useQueryClient()

    const [isHover, setIsHover] = useImmer(-1)
    if (
        tableConfiguration &&
        tableConfiguration.columnConfig &&
        !isEmpty(tableConfiguration.columnConfig)
    )
        if (tableConfiguration.columnConfig.hiddenColumns.length > 0) {
            tableConfiguration.columnConfig.hiddenColumns.forEach((e) => {
                if (!columnState.columnVisibility.hasOwnProperty(e)) {
                    setColumnState((draft) => {
                        draft.columnVisibility[e] = false
                    })
                }
            })
        }

    const onColumnVisibilityChange = (d) => {
        let columnVisibility: WritableDraft<VisibilityState>
        if (typeof d === 'function') {
            columnVisibility = d(columnState.columnVisibility)
        } else {
            columnVisibility = d
        }
        setColumnState((draft) => {
            draft.columnVisibility = columnVisibility
        })
        const columnConfig: { [x: string]: any } = {
            hiddenColumns: [],
            columns: {},
        }

        columnConfig.hiddenColumns = Object.keys(columnVisibility).filter(
            (e: string) => columnVisibility[e] === false
        )
        columnConfig.columns = table.getAllLeafColumns().map((e, ind) => {
            return {
                id: e.columnDef.id,
                size: e.getSize(),
                enableHiding: e.getCanHide(),
                order: ind,
            }
        })
        tableConfigCallback(columnConfig)
    }
    const onColumnOrderChange = (d) => {
        let columnOrder: WritableDraft<ColumnOrderState>
        if (typeof d === 'function') {
            columnOrder = d(columnState.columnOrder)
        } else {
            columnOrder = d
        }
        setColumnState((draft) => {
            draft.columnOrder = columnOrder
        })
        const columnConfig: { [x: string]: any } = {
            hiddenColumns: [],
            columns: {},
        }

        columnConfig.hiddenColumns = Object.keys(
            columnState.columnVisibility
        ).filter((e: string) => columnState.columnVisibility[e] === false)
        columnConfig.columns = table.getAllLeafColumns().map((e, ind) => {
            return {
                id: e.columnDef.id,
                size: e.getSize(),
                enableHiding: e.getCanHide(),
                order: ind,
            }
        })
        tableConfigCallback(columnConfig)
    }
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setFilter((draft) => {
            draft.currentPage = newPage
        })
    }

    const onColumnSizingChange = (d) => {
        let columnSizing: WritableDraft<ColumnSizingState>
        if (typeof d === 'function') {
            columnSizing = d(columnState.columnSizing)
        } else {
            columnSizing = d
        }
        setColumnState((draft) => {
            draft.columnSizing = columnSizing
        })
        const columnConfig: { [x: string]: any } = {
            hiddenColumns: [],
            columns: {},
        }

        columnConfig.hiddenColumns = Object.keys(
            columnState.columnVisibility
        ).filter((e: string) => columnState.columnVisibility[e] === false)
        columnConfig.columns = table.getAllLeafColumns().map((e, ind) => {
            return {
                id: e.columnDef.id,
                size: e.getSize(),
                enableHiding: e.getCanHide(),
                order: ind,
            }
        })
        tableConfigCallback(columnConfig)
    }
    const findColumn = (id: string) => {
        const columnOrderState = [...columnState.columnOrder]
        const column = columnOrderState.filter((column) => column.id === id)[0]
        return {
            column,
            index: columnOrderState.indexOf(column),
        }
    }
    const moveColumn = (id: string, destIndex: number, table: any) => {
        const columnOrderState = [...columnState.columnOrder]
        const sourceIndex = columnOrderState.findIndex(
            (column) => column.id === id
        )

        columnOrderState.splice(
            destIndex,
            0,
            columnOrderState.splice(sourceIndex, 1)[0] as string
        )
        table.setColumnOrder(columnOrderState)
    }

    const table = useReactTable({
        data: data,
        columns: columnState.columnOrder,
        manualPagination: true,
        manualSorting: true,
        columnResizeMode: 'onChange',
        state: {
            filter,
            columnOrder: columnState.columnOrder,
            columnVisibility: columnState.columnVisibility,
            columnSizing: columnState.columnSizing,
        },
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onGlobalFilterChange: setFilter,
        onColumnVisibilityChange: onColumnVisibilityChange,
        onColumnOrderChange: onColumnOrderChange,
        onColumnSizingChange: onColumnSizingChange,
        pageCount: Math.ceil(props.totalCount / 10),
        autoResetExpanded: false,
        debugTable: true,
        debugHeaders: true,
        debugColumns: true,
        enableColumnResizing: true,
    })
    const columnsShowHide = table.getAllColumns().map((column) => {
        return {
            label: column.columnDef.header,
            value: column.columnDef.header,
            column: column,
        }
    })

    const getCellData = (
        id: number,
        row,
        cell: {
            column: Column<unknown, unknown>
            getContext: any
            getValue: any
        },
        isHover: number,
        isModal: boolean | undefined
    ) => {
        const value = cell.getValue()
        if (cell.column.columnDef.id === 'Asset Name') {
            return (
                <div className="flex">
                    <div className="flex pr-2">
                        {isModal && (
                            <input
                                type={'checkbox'}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    props.addSearchedAssset(e, row.original)
                                }}
                            ></input>
                        )}
                    </div>
                    <AssetNameCell
                        showOptions={showOptions}
                        row={row}
                        isHover={isHover === id}
                        isModal={props.isModal}
                        setAsset={props.setAsset}
                    ></AssetNameCell>
                </div>
            )
        } else if (
            ['Single-select', 'Multi-select', 'Tag'].includes(
                cell.column.columnDef.type
            ) &&
            value
        ) {
            const tooltip = value.map((e) => e.value).join(', ')
            return (
                <Tooltip title={tooltip || ''}>
                    <div className="flex gap-2">
                        {value.map((cellValue, id) => {
                            return (
                                <Chip
                                    key={id}
                                    size="small"
                                    label={cellValue.value}
                                    sx={{ background: cellValue.color }}
                                ></Chip>
                            )
                        })}
                    </div>
                </Tooltip>
            )
        } else {
            return (
                <Tooltip title={value || ''}>
                    <Typography className="font-normal">{value}</Typography>
                </Tooltip>
            )
        }
    }
    function getNoResult() {
        if (!filter.currentPage && !filter.searchTerm) {
            return (
                <TableBody className="flex h-full justify-center">
                    <span className="flex justify-center text-xs text-gray-700 py-4">
                        Upload your first document
                    </span>
                </TableBody>
            )
        }
        return (
            <TableBody className="flex justify-center">{noResults}</TableBody>
        )
    }

    return (
        <>
            {data.length > 0 && (
                <TableContainer
                    component={Paper}
                    className="bg-white min-w-screen w-full shadow-none rounded-none flex-1 pb-6"
                >
                    <Table
                        stickyHeader
                        size="small"
                        className="table-fixed w-max min-w-full"
                    >
                        <TableHead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <TableRow
                                    key={headerGroup.id}
                                    className="bg-slate-50"
                                >
                                    {headerGroup.headers.map((header, ind) => (
                                        <DraggableColumnHeader
                                            key={header.id}
                                            header={header}
                                            table={table}
                                            columnResizeMode={'onEnd'}
                                            findColumn={findColumn}
                                            moveColumn={moveColumn}
                                        />
                                    ))}
                                    <TableCell>
                                        {flexRender(
                                            <ColumnsMenuIcon
                                                columnOptions={columnsShowHide}
                                                table={table}
                                                setColumnState={setColumnState}
                                                columnState={columnState}
                                                filter={filter}
                                                setFilter={setFilter}
                                                filterList={props.filterList}
                                                tableConfigCallback={
                                                    props.tableConfigCallback
                                                }
                                            ></ColumnsMenuIcon>,
                                            null
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableHead>
                        <TableBody className="overflow-auto">
                            {table.getRowModel().rows.map((row, id) => {
                                return (
                                    <TableRow
                                        component={'tr'}
                                        key={id}
                                        hover
                                        selected={
                                            asset.assetId === row.original.id
                                        }
                                        onPointerEnter={() => {
                                            setIsHover(id)
                                        }}
                                        onPointerLeave={() => {
                                            setIsHover(-1)
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            if (props.onClickOfRow) {
                                                props.onClickOfRow(row.original)
                                            } else {
                                                props.setAsset((draft) => {
                                                    draft['fileId'] =
                                                        row.original.fileId
                                                    draft['assetId'] =
                                                        row.original.id
                                                })
                                                if (
                                                    !queryClient.getQueryData([
                                                        'assets',
                                                        row?.original?.id,
                                                        'details',
                                                    ])
                                                ) {
                                                    const data: Asset =
                                                        searchDataIntoViewAsset(
                                                            row.original,
                                                            customAttribute.data,
                                                            users.data
                                                        )
                                                    props.setAsset((draft) => {
                                                        draft['data'] = data
                                                    })
                                                }
                                                navigate(
                                                    `/search/${row.original.id}`
                                                )
                                            }
                                        }}
                                    >
                                        {row
                                            .getVisibleCells()
                                            .map((cell, index) => {
                                                return (
                                                    <TableCell
                                                        key={cell.id}
                                                        className="overflow-hidden text-ellipsis whitespace-nowrap p-0"
                                                        style={{
                                                            width: cell.column.getSize(),
                                                            maxWidth:
                                                                cell.column
                                                                    .columnDef
                                                                    .size,
                                                            height: 48,
                                                        }}
                                                    >
                                                        <div
                                                            className="py-2 px-4"
                                                            style={{
                                                                width: cell.column.getSize(),
                                                            }}
                                                        >
                                                            {getCellData(
                                                                id,
                                                                row,
                                                                cell,
                                                                isHover,
                                                                props.isModal
                                                            )}
                                                        </div>
                                                    </TableCell>
                                                )
                                            })}
                                        <TableCell className="px-3 py-1"></TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {data.length <= 0 && getNoResult()}
        </>
    )
}
