const configuration = {
    client_id: `${import.meta.env.VITE_COGNITO_CLIENT_ID}`,
    redirect_uri: `${
        import.meta.env.VITE_COGNITO_CALLBACK_URL
    }/authentication/callback`,
    response_type: 'code',
    post_logout_redirect_uri: 'http://localhost:1234/',
    scope: 'openid aws.cognito.signin.user.admin',
    authority: `${import.meta.env.VITE_COGNITO_AUTHORITY}`,
    silent_redirect_uri: 'http://localhost:1234/authentication/silent_callback',
    automaticSilentRenew: true,
    loadUserInfo: true,
    metadata: {
        end_session_endpoint: `${
            import.meta.env.VITE_COGNITO_DOMAIN
        }/logout?client_id=${
            import.meta.env.VITE_COGNITO_CLIENT_ID
        }&redirect_uri=${
            import.meta.env.VITE_COGNITO_CALLBACK_URL
        }&response_type=code`,
        authorization_endpoint: `${
            import.meta.env.VITE_COGNITO_DOMAIN
        }/oauth2/authorize`,
        token_endpoint: `${import.meta.env.VITE_COGNITO_DOMAIN}/oauth2/token`,
        issuer: `${import.meta.env.VITE_COGNITO_AUTHORITY}`,
        userinfo_endpoint: `${
            import.meta.env.VITE_COGNITO_DOMAIN
        }/oauth2/userInfo`,
    },
}

export default configuration
