import { useQuery,useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { APi_URL } from "@Common/Types/configs/urlConfig";


export default function useCustomAttributeValues() {
  const queryClient = useQueryClient()
    return useMutation<any, Error,object>((id:any) => axios.get(
      `${APi_URL}/customAttributes/${id}`,
      { headers: { 'Content-Type': 'application/json' } }),{
        onSuccess:(res)=>{
          queryClient.invalidateQueries(["customAttribute"])
        }
      });
  }

  