import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { Updater, useImmer } from 'use-immer'
import CustomAttributes from './CustomAttributes'

import AvatarComponent from '@Common/Components/Avatar'
import { TabPanel } from '@Common/Components/TabPanel'
import useFeatureFlag from '@Common/hooks/useFeatureFlag'
import useAssetComments from '@Common/Queries/GetQueries/GetAssetComments'
import useAssetPermission from '@Common/Queries/GetQueries/GetAssetPermission'
import useInit from '@Common/Queries/GetQueries/useInit'
import useDownload from '@Common/Queries/MutationQueries/getFileDownload'
import useUpdateAsset from '@Common/Queries/MutationQueries/UpdateAsset'
import { Box, Button, Tab } from '@mui/material'
import Tabs from '@mui/material/Tabs'
import { allowTrack } from '@Search/utils/allowAssetOperations'
import { useRef } from 'react'
import { canEdit, formattedDate, getUser, summaryEnabled } from './../util'
import Comments from './Comments'
import DisplayCommentEditor from './DisplayCommentEditor'
import EditableComponent from './EditableComponent'
import RecentVisits from './RecentVisits'
import ViewAssetTopActions from './ViewAssetTopActions'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import { useGenerateSummary } from '@Common/Queries/ViewAsset/GenerateSummary'
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined'
export interface ViewAssetScreenProps {
    asset?: Asset
    ownerData?: {
        data: Record<string, unknown>[]
        option: { value: string; name: string; label: string }[]
    }
    customAttributes: {
        id: string
        type: string
        name: string
        sensitive: boolean
    }[]
    location?: string
    setAsset?: Updater<{ assetId: string; fileId: string; data: Asset }>
    setOpen?: Updater<boolean>
}
export interface AssetFile {
    id: string
    fileId: string
    assetId: string
    createdAt: string
    file: {
        id: string
        fileName: string
        name: string
        userId: string
    }
}
export interface AssetPermission {
    id: string
    AssetGroupPermission: Record<string, string>
    AssetUserPermission: Record<string, string>
    permission: string
}

export interface Asset {
    id: string
    action: string
    description: string
    ownerId: string
    createdAt: string
    name: string
    projectId: string
    access: string
    shareable: boolean
    fileId: string
    AssetPermission: AssetPermission[]
    AssetFile: Array<AssetFile>
    AssetCustomAttribute: Array<Record<string, unknown>>
    UserInputCustomAttributes: Array<Record<string, unknown>>
}
const ViewAsset = (props: ViewAssetScreenProps) => {
    const asset = props.asset
    const assetName = asset?.name

    const {
        isSuccess,
        isError,
        isLoading: d,
        data: downloadUrl,
    } = useDownload({ id: asset?.fileId })
    const permission = useAssetPermission(asset?.id)
    const [isVisible, setIsVisible] = useImmer<{
        [x: string]: { visible: boolean; anchor: any }
    }>({})
    const [summary, setSummary] = useImmer(summaryEnabled(asset))
    const comments = useAssetComments(asset?.id)
    const init = useInit()
    const sendDataToGTM = useGTMDispatch()
    const updateAsset = useUpdateAsset({
        updateFunction: (previousAsset, data) => {
            return {
                ...previousAsset,
                name: data.body.name,
                description: data.body.description,
            }
        },
        onSuccess: (data) => {
            sendDataToGTM({
                event: 'asset_edit',
                asset_id: asset?.id,
                asset_name: assetName,
                attribute_name:
                    data.data.name != assetName ? 'name' : 'description',
            })
        },
    })
    const featureFlag = useFeatureFlag('asset_track_link')
    const openAiFlag = useFeatureFlag('open_ai')
    const summaryGeneration = useGenerateSummary({
        mutate: () => {
            setSummary((draft) => {
                draft.message =
                    'Summary generation under process. Feel free to work on other things while we are on it'
            })
        },
    })
    const [tabs, setTabs] = useImmer(
        featureFlag && allowTrack(downloadUrl || '') ? 0 : 1
    )
    const updateAssetRequestBody = (value: Asset) => {
        const body = {
            id: asset?.id,
            body: {
                name: value.name,
                description: value.description,
            },
        }
        updateAsset.mutate(body)
    }

    const onChangeAssetName = (event) => {
        const value = event.target.value.trim()
        if (value.length > 0) {
            asset.name = value
            updateAssetRequestBody(asset)
        }
    }
    const onChangeDescription = (event) => {
        const value = event.target.value.trim()

        updateAssetRequestBody({
            ...asset,
            description: value,
        })
    }

    const generateSummary = () => {
        summaryGeneration.mutate(asset?.id)
    }
    const trackButtonRef = useRef()
    const handleKeyDown = (event, column, value, onChange) => {
        if (event.key === 'Escape') {
            event.preventDefault()
            event.currentTarget.blur()
            event.target.value = value
            setIsVisible((draft) => {
                draft[column].visible = false
            })
        }
        if (event.key === 'Enter') {
            if (onChange) {
                onChange(event)
            }
            event.currentTarget.blur()
            setIsVisible((draft) => {
                draft[column].visible = false
            })
        }
    }

    return (
        <>
            <ViewAssetTopActions
                disabled={false}
                asset={asset}
                trackButtonRef={trackButtonRef}
                downloadUrl={downloadUrl}
                users={props.ownerData}
                location={props.location}
                setAsset={props.setAsset}
                setOpen={props.setOpen}
            ></ViewAssetTopActions>
            <Typography className="text-xs pb-3 px-6" color="text.disabled">
                {`Created by ${getUser(
                    asset?.ownerId,
                    props.ownerData
                )} on ${formattedDate(asset.createdAt)}`}
            </Typography>
            <div className="pb-3 max-w-[672px] px-6">
                {canEdit(asset?.action) ? (
                    <EditableComponent
                        value={assetName}
                        visible={isVisible}
                        setIsVisible={setIsVisible}
                        id={'name'}
                        defaultValue={''}
                        textClassName="text-2xl  -ml-2 line-clamp-2"
                    >
                        <TextField
                            className="w-full text-2xl"
                            size="small"
                            maxRows={2}
                            multiline
                            variant="filled"
                            onBlur={onChangeAssetName}
                            defaultValue={assetName || ''}
                            inputProps={{
                                style: {
                                    padding: 0,
                                    fontSize: 24,
                                },
                            }}
                            onKeyDown={(e) =>
                                handleKeyDown(
                                    e,
                                    'name',
                                    assetName,
                                    onChangeAssetName
                                )
                            }
                        ></TextField>
                    </EditableComponent>
                ) : (
                    <Typography className="text-2xl break-words	 line-clamp-2">
                        {asset?.name || ''}
                    </Typography>
                )}
            </div>
            <div className="w-full pb-4 max-w-[672px] px-6">
                {canEdit(asset?.action) ? (
                    <EditableComponent
                        value={asset?.description}
                        visible={isVisible}
                        setIsVisible={setIsVisible}
                        id={'description'}
                        defaultValue={'Add a description'}
                        textClassName={'text-sm  -ml-2 line-clamp-2'}
                    >
                        <TextField
                            className="w-full text-sm"
                            multiline
                            size="small"
                            variant="filled"
                            maxRows={2}
                            defaultValue={asset.description || ''}
                            inputProps={{
                                style: {
                                    padding: 0,
                                    fontSize: 14,
                                },
                            }}
                            onBlur={onChangeDescription}
                            onKeyDown={(e) =>
                                handleKeyDown(
                                    e,
                                    'description',
                                    asset.description,
                                    onChangeDescription
                                )
                            }
                        ></TextField>
                    </EditableComponent>
                ) : (
                    <Typography className="text-2xl break-words	 line-clamp-2">
                        {asset?.description || ''}
                    </Typography>
                )}
            </div>

            {summary.show && openAiFlag && (
                <div className="flex gap-2 px-6 flex-col items-start">
                    <Button
                        disabled={!summary.enabled}
                        onClick={() => {
                            setSummary((draft) => {
                                draft.enabled = false
                            })
                            generateSummary()
                        }}
                        startIcon={
                            <AutoAwesomeOutlinedIcon></AutoAwesomeOutlinedIcon>
                        }
                    >
                        Generate summary
                    </Button>
                    {summary.message && (
                        <Typography color={'text.disabled'} fontSize={12}>
                            {summary.message}
                        </Typography>
                    )}
                </div>
            )}
            <Tabs
                value={tabs}
                onChange={(e, newValue) => {
                    setTabs(newValue)
                }}
                sx={{
                    borderBottom: 1,
                    borderColor: 'rgba(0, 0, 0, 0.12)',
                }}
                variant={
                    props.location != 'viewAsset' ? 'fullWidth' : 'standard'
                }
            >
                {featureFlag && allowTrack(downloadUrl || '') && (
                    <Tab label="Recent Visits" value={0} />
                )}
                <Tab label="Attributes" value={1} />
                <Tab label="Comments" value={2} />
            </Tabs>
            {featureFlag && allowTrack(downloadUrl || '') && (
                <TabPanel
                    index={0}
                    value={tabs}
                    className="w-full overflow-hidden"
                >
                    <RecentVisits
                        action={asset?.action}
                        assetId={asset?.id}
                        trackButtonRef={trackButtonRef}
                    ></RecentVisits>
                </TabPanel>
            )}
            <TabPanel index={1} value={tabs} className="px-6 pt-6">
                <CustomAttributes
                    asset={asset}
                    customAttributes={props.customAttributes}
                    isVisible={isVisible}
                    setIsVisible={setIsVisible}
                    handleKeyDown={handleKeyDown}
                ></CustomAttributes>
            </TabPanel>
            <TabPanel index={2} value={tabs} className="px-6 pt-6">
                <div className="w-full flex max-w-[672px] py-4  overflow-hidden">
                    <AvatarComponent
                        name={init.data.user.name}
                    ></AvatarComponent>
                    <DisplayCommentEditor
                        id={'comments'}
                        isVisible={isVisible}
                        setIsVisible={setIsVisible}
                        users={props.ownerData}
                        asset={{
                            id: asset?.id,
                            name: asset?.name,
                        }}
                    ></DisplayCommentEditor>
                </div>
                {comments.isSuccess && (
                    <Comments
                        comments={comments.data}
                        option={{
                            name: 'assets',
                            id: asset.id,
                        }}
                        isAssetApprove={false}
                    ></Comments>
                )}
            </TabPanel>
        </>
    )
}

export default ViewAsset
