import Avatar from '@mui/material/Avatar'

function stringToColor(string: string) {
    let hash = 0
    let i

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash)
    }

    let color = '#'

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff
        color += `00${value.toString(16)}`.slice(-2)
    }
    /* eslint-enable no-bitwise */

    return color
}

export const AvatarComponent = ({
    name = '',
    sx = {},
    src = "",
    variant = 'circular',
}: {
    name: string
    sx?: { height?: number; width?: number; fontSize?: number; border?: string; color?: string; bgcolor?: string; padding?: number; fontWeight?: number }
    src?: string | undefined
    variant?: string
}) => {
    return (
        <Avatar
            variant={variant}
            src = {src}
            sx={{
                bgcolor: stringToColor(name ? name : ""),
                width: 40,
                height: 40,
                fontSize: 16,
                ...sx,
            }}
        >
            {name ? name[0].toUpperCase() : ''}
        </Avatar>
    )
}
export default AvatarComponent
