interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
    className?: string
}

export const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, className } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            className={`h-full flex-1 ${className}`}
        >
            {value === index && <div className="h-full">{children}</div>}
        </div>
    )
}
