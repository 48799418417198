import Loading from '@Common/Components/Loading'
import useDebounce from '@Common/hooks/useDebounce'
import useLocalStorage from '@Common/hooks/useLocalStorage'
import useTitle from '@Common/hooks/useTitle'
import useUsers from '@Common/Queries/GetQueries/GetUsers'
import useInit from '@Common/Queries/GetQueries/useInit'
import { API_URL } from '@Common/Types/configs/urlConfig'
import SearchIcon from '@mui/icons-material/Search'
import {
    Button,
    Chip,
    InputAdornment,
    OutlinedInput,
    TablePagination,
    Typography,
} from '@mui/material'
import { UpdateAssetApproveRequest } from '@Server/updateAssetApproveRequest'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { RequestFilter } from 'src'
import { Updater, useImmer } from 'use-immer'
import useApprovalData from '@Request/query/GetApprovalData'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { format, parseISO } from 'date-fns'
import AvatarComponent from '@Common/Components/Avatar'
import DoneIcon from '@mui/icons-material/Done'
import logo from '../assets/cognition-purple.png'
import {
    documentName,
    formattedDate,
    formattedRequest,
    raisedBy,
} from './components/common/Functions'
import { useNavigate } from 'react-router-dom'

export default function ApprovalScreen(props: {
    filter: RequestFilter
    setFilter: Updater<RequestFilter>
}) {
    const { filter, setFilter } = props
    const navigate = useNavigate()
    const columns: GridColDef[] = [
        {
            headerName: 'Approval',
            field: 'requestType',
            minWidth: 150,
            flex: 1,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
        },
        {
            headerName: 'Document Name',
            field: 'documentName',
            minWidth: 150,
            flex: 1,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
        },
        {
            headerName: 'Raised by',
            field: 'raisedBy',
            minWidth: 150,
            flex: 1,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <div className="flex flex-row">
                        <AvatarComponent
                            sx={{
                                width: 24,
                                height: 24,
                                fontSize: 12,
                            }}
                            name={params.row.raisedBy}
                        ></AvatarComponent>
                        <div className="pl-2 w-40 truncate">
                            {params.row.raisedBy}
                        </div>
                    </div>
                )
            },
        },
        {
            headerName: 'Raised on',
            field: 'raisedOn',
            minWidth: 150,
            flex: 1,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
        },
        {
            headerName: 'Status',
            field: 'status',
            minWidth: 150,
            flex: 1,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                return <Typography>{params.row.status}</Typography>
            },
        },
    ]

    const queryClient = useQueryClient()

    const type = [
        {
            label: 'All Type',
            value: 'ALL',
        },
        {
            label: 'Asset Approval',
            value: 'ASSET_APPROVAL',
        },
        {
            label: 'Permission Approval',
            value: 'PERMISSION_APPROVAL',
        },
        {
            label: 'Asset Request',
            value: 'REQUEST',
        },
    ]

    const debouncedFilter = useDebounce(filter)
    const init = useInit()
    const usersData = useUsers()
    const approvalData = useApprovalData(debouncedFilter)

    const [selected, setSelected] = useImmer({
        isOpen: true,
        myRequest: false,
        isAssetApproval: true,
        selectAll: false,
        selectedRow: [],
    })
    const [theme, setTheme] = useLocalStorage('theme', {
        tenant: {
            name: '',
        },
    })
    useTitle(`${theme?.tenant?.name ? theme?.tenant?.name : ''} - Approvals `)

    const assetApproveBulk = useMutation(
        (params: UpdateAssetApproveRequest) => {
            return axios.post(`${API_URL}/requests/assetApprove`, params, {
                headers: {
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                },
            })
        },
        {
            onSuccess: () => {
                setSelected((draft) => {
                    draft.selectAll = false
                    draft.selectedRow = []
                })
                queryClient.invalidateQueries(['approval', 'search'])
            },
        }
    )

    const assetPermissionBulk = useMutation(
        (params: UpdateAssetApproveRequest) => {
            return axios.post(`${API_URL}/requests/assetPermission`, params, {
                headers: {
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                },
            })
        },
        {
            onSuccess: () => {
                setSelected((draft) => {
                    draft.selectAll = false
                    draft.selectedRow = []
                })
                queryClient.invalidateQueries(['approval', 'search'])
            },
        }
    )

    const bulkApprovalEnabled = selected.selectedRow.length > 0

    if (approvalData.isLoading || init.isLoading || usersData.isLoading) {
        return <Loading></Loading>
    }

    const userRole = init.data.user.UserRole.filter((value) => {
        return value.role.role !== 'User'
    })

    const rows = approvalData.data.requests.map((value) => {
        return {
            id: value.id,
            requestType: formattedRequest(type, value.type),
            documentName: documentName(
                value.AssetApproveRequest,
                value.AssetPermissionRequests
            ),
            raisedBy: raisedBy(value.requestedUserId, usersData.data),
            raisedOn: formattedDate(value.updatedAt),
            status: value.status,
        }
    })
    return (
        <>
            <div className="flex flex-col w-full overflow-hidden">
                <div className="flex items-center px-6 pb-4">
                    <div className="flex-1 flex items-center">
                        <div className="w-44 ">
                            <img src={logo} />
                        </div>
                        <div className="flex gap-2">
                            <Typography variant={'h5'}>|</Typography>
                            <Typography variant={'h5'}>Approvals</Typography>
                        </div>
                    </div>
                    <div>
                        <OutlinedInput
                            data-testid="approval-searchbar"
                            type="text"
                            placeholder="Search"
                            size="small"
                            onInput={(e: any) =>
                                setFilter((draft) => {
                                    draft.searchText = e.target.value
                                })
                            }
                            defaultValue={filter.searchText}
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            }
                        />
                    </div>
                </div>

                <div className="h-full flex flex-col overflow-hidden px-6">
                    <div className="flex flex-row py-4">
                        <div className="flex flex-1">
                            <div className="pr-2">
                                {userRole.length !== 0 && (
                                    <Chip
                                        data-testid="approval-isopen-chip"
                                        label="Is open"
                                        size="medium"
                                        sx={{
                                            borderRadius: 1,
                                            border: selected.isOpen
                                                ? 'none'
                                                : 'default',
                                            backgroundColor: selected.isOpen
                                                ? 'blue.light'
                                                : 'none',
                                        }}
                                        clickable
                                        color={
                                            selected.isOpen
                                                ? 'primary'
                                                : 'default'
                                        }
                                        variant="outlined"
                                        onClick={() => {
                                            setFilter((draft) => {
                                                draft.requestStatus =
                                                    selected.isOpen
                                                        ? 'ALL'
                                                        : 'OPEN'
                                                draft.currentPage = 0
                                            })
                                            setSelected((draft) => {
                                                draft.isOpen = !selected.isOpen
                                            })
                                        }}
                                        icon={
                                            selected.isOpen ? (
                                                <DoneIcon />
                                            ) : null
                                        }
                                    />
                                )}
                            </div>
                            <div className="pr-2">
                                {userRole.length !== 0 && (
                                    <Chip
                                        data-testid="approval-isassetapproval-chip"
                                        label="Is asset approval"
                                        sx={{
                                            borderRadius: 1,
                                            border: selected.isAssetApproval
                                                ? 'none'
                                                : 'default',
                                            backgroundColor:
                                                selected.isAssetApproval
                                                    ? 'blue.light'
                                                    : 'none',
                                        }}
                                        size="medium"
                                        clickable
                                        color={
                                            selected.isAssetApproval
                                                ? 'primary'
                                                : 'default'
                                        }
                                        variant="outlined"
                                        onClick={(e) => {
                                            setFilter((draft) => {
                                                draft.type =
                                                    selected.isAssetApproval
                                                        ? 'ALL'
                                                        : 'ASSET_APPROVAL'
                                                draft.currentPage = 0
                                            })
                                            setSelected((draft) => {
                                                draft.isAssetApproval =
                                                    !selected.isAssetApproval
                                            })
                                        }}
                                        icon={
                                            selected.isAssetApproval ? (
                                                <DoneIcon />
                                            ) : null
                                        }
                                    />
                                )}
                            </div>
                            <div>
                                {userRole.length !== 0 && (
                                    <Chip
                                        data-testid="approval-myrequests-chip"
                                        label="My requests"
                                        sx={{
                                            borderRadius: 1,
                                            border: selected.myRequest
                                                ? 'none'
                                                : 'default',
                                            backgroundColor: selected.myRequest
                                                ? 'blue.light'
                                                : 'none',
                                        }}
                                        size="medium"
                                        clickable
                                        color={
                                            selected.myRequest
                                                ? 'primary'
                                                : 'default'
                                        }
                                        variant="outlined"
                                        onClick={(e) => {
                                            setFilter((draft) => {
                                                draft.requestType =
                                                    selected.myRequest
                                                        ? 'ALL'
                                                        : 'OWN'
                                                draft.currentPage = 0
                                            })
                                            setSelected((draft) => {
                                                draft.myRequest =
                                                    !selected.myRequest
                                            })
                                        }}
                                        icon={
                                            selected.myRequest ? (
                                                <DoneIcon />
                                            ) : null
                                        }
                                    />
                                )}
                            </div>
                        </div>
                        <div>
                            {userRole.length !== 0 && (
                                <Button
                                    data-testid="approval-bulkapprove-btn"
                                    disabled={
                                        !bulkApprovalEnabled ||
                                        assetApproveBulk.isLoading ||
                                        assetPermissionBulk.isLoading
                                    }
                                    variant="contained"
                                    onClick={() => {
                                        assetApproveBulk.mutate({
                                            requestIds: selected.selectedRow,
                                            status: 'APPROVED',
                                            reason: 'bulk Approved',
                                        })
                                    }}
                                >
                                    {(assetApproveBulk.isLoading ||
                                        assetPermissionBulk.isLoading) && (
                                        <div className="mr-2">
                                            <Loading size="1.25rem" />
                                        </div>
                                    )}
                                    Bulk Approve
                                </Button>
                            )}
                        </div>
                    </div>

                    {/* need this popover later when requested by filter is implemented*/}

                    {/* <Popover
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={requestedBy.open}
                            anchorEl={requestedBy.anchorEl}
                        >
                            <MenuList>
                                {usersList.map((value) => {
                                    return (
                                        <MenuItem
                                            onClick={() => {
                                                setRequestedBy((draft) => {
                                                    draft.open = false
                                                })
                                            }}
                                        >
                                            <div className="flex flex-row">
                                                <div>
                                                    <Avatar
                                                        name={value.email}
                                                    ></Avatar>
                                                </div>
                                                <div className="flex flex-col">
                                                    <div>{value.name}</div>
                                                    <div>{value.email}</div>
                                                </div>
                                            </div>
                                        </MenuItem>
                                    )
                                })}
                            </MenuList>
                        </Popover> */}

                    <DataGrid
                        rows={rows}
                        columns={columns}
                        checkboxSelection
                        onRowClick={(params) => {
                            navigate(`/requests/${params.id}`)
                        }}
                        slots={{
                            noRowsOverlay: () => (
                                <div className="flex justify-center p-4">
                                    No results found
                                </div>
                            ),
                        }}
                        sx={{
                            border: 0,
                            '.MuiDataGrid-row:hover': {
                                cursor: 'pointer',
                            },
                        }}
                        onRowSelectionModelChange={(value) => {
                            setSelected((draft) => {
                                draft.selectedRow = value
                            })
                        }}
                        hideFooter={true}
                    ></DataGrid>

                    <TablePagination
                        rowsPerPageOptions={[]}
                        colSpan={3}
                        component="div"
                        className="text-xs text-lightTextDisabled border-none overflow-hidden"
                        count={approvalData.data.count}
                        rowsPerPage={filter.pageSize}
                        page={filter.currentPage}
                        onPageChange={(event, newPage) => {
                            setFilter((draft) => {
                                draft.currentPage = newPage
                            })
                        }}
                        backIconButtonProps={{
                            className: 'stroke-slate-50',
                        }}
                        nextIconButtonProps={{
                            className: 'stroke-slate-50',
                        }}
                    />
                </div>
            </div>
        </>
    )
}
