import { EntityOption } from '@Common/Components/Comment'
import { APi_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
interface DeleteCommentProps {
    commentId: string
    entityOption: EntityOption
}
export default function useDeleteComments(props?: any) {
    const queryClient = useQueryClient()
    return useMutation<any, any, DeleteCommentProps>(
        (obj: DeleteCommentProps) =>
            axios.delete(`${APi_URL}/comments/${obj.commentId}`, {
                headers: { 'Content-Type': 'application/json' },
            }),
        {
            onSuccess: async (data: any, obj: any) => {
                await queryClient.invalidateQueries([
                    'comments',
                    obj.entityOption.name,
                    obj.entityOption.id,
                ])
                if (props && props.onSuccess) props.onSuccess(data, obj)
            },
            onMutate: async (data: DeleteCommentProps) => {
                await queryClient.cancelQueries([
                    'comments',
                    data.entityOption.name,
                    data.entityOption.id,
                ])
                const previousComments = queryClient.getQueryData([
                    'comments',
                    data,
                ])
                queryClient.setQueryData(
                    ['comments', data.entityOption.name, data.entityOption.id],
                    (old) =>
                        old.filter(
                            (comment: any) =>
                                comment.commentId !== data.commentId
                        )
                )
                return { previousComments }
            },
            onError: (data: any, obj: any, context: any) => {
                queryClient.setQueryData(
                    ['comments', data.entityOption.name, data.entityOption.id],
                    context.previousComments
                )

                if (props.onError) props.onError(data, obj)
            },
            onSettled: (data: any, error, obj) => {
                queryClient.invalidateQueries([
                    'comments',
                    obj.entityOption.name,
                    obj.entityOption.id,
                ])
            },
        }
    )
}
