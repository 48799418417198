import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useMutation, useQuery } from 'react-query'

export default function useSaveSiteImage(props?: any) {
    return useMutation(
        (obj: { fileName: string; fileType: string }) =>
            axios.get(`${API_URL}/site/upload/image`, {
                params: { fileName: obj.fileName, type: obj.fileType },
            }),
        {
            onSuccess(data, variables, context) {
                if (props.onSuccess) {
                    props?.onSuccess(data, variables)
                }
            },
        }
    )
}
