import Loading from '@Common/Components/Loading'
import useGetStatus from '@Common/Queries/Dealroom/GetAllStatus'
import { useSearchForDealRoom } from '@Common/Queries/Dealroom/SearchDealRoom'
import useUsers from '@Common/Queries/GetQueries/GetUsers'
import useDebounce from '@Common/hooks/useDebounce'
import Badge from '@mui/material/Badge'
import Typography from '@mui/material/Typography'
import {
    DataGridPro,
    FilterColumnsArgs,
    GetColumnForNewFilterArgs,
    GridColDef,
    GridFilterModel,
    GridLogicOperator,
    GridToolbarContainer,
    GridToolbarQuickFilter,
    getGridDateOperators,
    getGridSingleSelectOperators,
    getGridStringOperators,
} from '@mui/x-data-grid-pro'
import { useImmer } from 'use-immer'
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined'
import Chip from '@mui/material/Chip'
import { formattedDate, formattedDateWithTime } from 'src/ViewAssetScreen/util'
import InputAdornment from '@mui/material/InputAdornment'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import SearchIcon from '@mui/icons-material/Search'
import { useAccounts } from 'src/Accounts/useAccounts'
import { getOptions } from '@Common/Components/Select/Accounts'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import { Avatar, Box } from '@mui/material'

export const DealRoomTable = () => {
    const [filter, setFilter] = useImmer<{
        page: number
        pageSize: number
        items: Array<any>
        status: string
        logicOperator: GridLogicOperator.And | undefined
        quickFilterLogicOperator: GridLogicOperator.Or | undefined
        quickFilterValues: Array<string>
    }>({
        page: 0,
        pageSize: 25,
        items: [],
        status: 'ACTIVE',
        logicOperator: GridLogicOperator.And,
        quickFilterLogicOperator: GridLogicOperator.Or,
        quickFilterValues: [],
    })
    const [hover, setHover] = useImmer(null)

    const debouncedFilter = useDebounce(filter, 200)
    const dealRoomSearch = useSearchForDealRoom(debouncedFilter)
    const [status, setStatus] = useImmer('ACTIVE')
    const dealStatus = useGetStatus()
    const users = useUsers()
    const accounts = useAccounts({ pageSize: 10, page: 0, search: '' })

    const navigate = useNavigate()
    if (
        dealRoomSearch.isLoading ||
        users.isLoading ||
        dealStatus.isLoading ||
        accounts.isLoading
    ) {
        return <Loading></Loading>
    }
    const accountOptions = getOptions(accounts)
    const onFilterChange = (filterModel: GridFilterModel) => {
        const items = filterModel.items.filter((e) => {
            if (e.value) {
                return true
            }
            return false
        })
        setFilter((draft) => {
            draft.logicOperator = filterModel.logicOperator
            draft.quickFilterLogicOperator =
                filterModel.quickFilterLogicOperator
            draft.quickFilterValues = filterModel.quickFilterValues
            draft.items = items
        })
    }

    const stringFilterOperator = getGridStringOperators().filter(
        (operator) =>
            operator.value === 'contains' ||
            operator.value === 'starts with' ||
            operator.value === 'ends with'
    )
    const singleSelectOperator = getGridSingleSelectOperators().filter(
        (operator) => operator.value === 'is'
    )
    const dateOperator = getGridDateOperators().filter(
        (operator) => operator.value === 'is'
    )
    const filterColumns = ({
        field,
        columns,
        currentFilters,
    }: FilterColumnsArgs) => {
        // remove already filtered fields from list of columns
        const filteredFields = currentFilters?.map((item) => item.field)
        return columns
            .filter(
                (colDef) =>
                    colDef.filterable &&
                    (colDef.field === field ||
                        !filteredFields.includes(colDef.field))
            )
            .map((column) => column.field)
    }
    const getColumnForNewFilter = ({
        currentFilters,
        columns,
    }: GetColumnForNewFilterArgs) => {
        const filteredFields = currentFilters?.map(({ field }) => field)
        const columnForNewFilter = columns
            .filter(
                (colDef) =>
                    colDef.filterable && !filteredFields.includes(colDef.field)
            )
            .find((colDef) => colDef.filterOperators?.length)
        return columnForNewFilter?.field ?? null
    }
    const getColor = (status: string) => {
        if (
            [
                'Proposal',
                'Prospecting',
                'Negotiation',
                'Qualification',
            ].includes(status)
        ) {
            return 'warning'
        } else if (['Closed lost'].includes(status)) {
            return 'error'
        }
        return 'success'
    }

    const colums: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            type: 'string',
            sortable: false,
            hideable: false,
            flex: 0.5,
            minWidth: 250,
            filterOperators: stringFilterOperator,
            renderCell: (params) => {
                return (
                    <div className="w-full  flex items-center justify-between relative">
                        <Typography>
                            {params.row.DealRoomProperties.name}
                        </Typography>
                        {hover === params.row.id && (
                            <div className="absolute right-1 flex gap-2">
                                <Button
                                    data-testid={`dealroom-${params.row.id}-open-btn`}
                                    className="drop-shadow-md bg-white hover:drop-shadow-md hover:bg-white"
                                    sx={{
                                        '&.MuiButtonBase-root:hover': {
                                            bgcolor: 'white',
                                        },
                                    }}
                                    onClick={() => {
                                        navigate(`/dealroom/${params.row.id}`)
                                    }}
                                >
                                    Open
                                </Button>
                            </div>
                        )}
                    </div>
                )
            },
        },
        {
            field: 'accountId',
            headerName: 'Account',
            type: 'singleSelect',
            sortable: false,
            width: 192,
            valueOptions: accountOptions,
            filterOperators: singleSelectOperator,
            renderCell: (params) => {
                const account = params.row.DealRoomProperties.accountId
                if (account != null) {
                    const label = accountOptions.find((e) => {
                        return (
                            e.value === params.row.DealRoomProperties.accountId
                        )
                    })

                    if (label !== -1 && label !== undefined) {
                        return (
                            <Typography fontWeight={300}>
                                {label.label}
                            </Typography>
                        )
                    } else {
                        return <Typography>{'--'}</Typography>
                    }
                }
                return <Typography>{'--'}</Typography>
            },
        },
        {
            field: 'createdBy',
            headerName: 'Created By',
            minWidth: 220,
            type: 'singleSelect',
            sortable: false,
            filterOperators: singleSelectOperator,
            valueFormatter(cellValue) {
                const user = users.data?.data.find(
                    (e) => e.id === cellValue.value
                )
                if (user === undefined) {
                    return 'Unknown user'
                }
                return user.name
            },
            valueOptions: users.data?.option,
            renderCell: (cellValue) => {
                return (
                    <Box
                        className="flex gap-2 items-center"
                        sx={{
                            height: '100%',
                            width: '100%',
                        }}
                    >
                        <Avatar
                            sx={{
                                width: 24,
                                height: 24,
                                color: 'rgb(100,100,100)',
                                bgcolor: 'white',
                                border: 'solid 0.06rem rgb(100,100,100)',
                                padding: 1,
                                fontSize: 12,
                            }}
                        >
                            {cellValue.formattedValue[0].toUpperCase()}
                        </Avatar>
                        <Box
                            sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {cellValue.formattedValue}
                        </Box>
                    </Box>
                )
            },
        },
        {
            field: 'opportunity',
            headerName: 'Opportunity',
            width: 192,
            sortable: false,
            type: 'string',
            filterOperators: stringFilterOperator,
            renderCell: (params) => {
                return (
                    <Typography>
                        {params.row.DealRoomProperties.opportunity
                            ? params.row.DealRoomProperties.opportunity
                            : '--'}
                    </Typography>
                )
            },
        },
        {
            field: 'recent activity',
            headerName: 'Activity (Last 7 days)',
            width: 192,
            sortable: false,
            filterable: false,

            renderCell: (params) => {
                const documents = params.row.DealRoomSection.filter(
                    (e) => e.status === 'ACTIVE'
                ).reduce((sum, value) => {
                    const sectionAsset = value.DealRoomSectionAsset.filter(
                        (e) => e.status === 'ACTIVE'
                    )
                    sum = sum + sectionAsset.length
                    return sum
                }, 0)
                const chat = params.row.DealRoomComment.length
                const visits = params.row.DealRoomVisit.length
                return (
                    <div className="flex gap-6">
                        <Badge badgeContent={documents}>
                            {documents > 0 && (
                                <DescriptionOutlinedIcon
                                    fontSize="small"
                                    color="disabled"
                                />
                            )}
                        </Badge>
                        <Badge badgeContent={visits}>
                            {visits > 0 && (
                                <TrendingUpOutlinedIcon
                                    color="disabled"
                                    fontSize="small"
                                />
                            )}
                        </Badge>
                        <Badge badgeContent={chat}>
                            {chat > 0 && (
                                <ChatBubbleOutlineOutlinedIcon
                                    color="disabled"
                                    fontSize="small"
                                ></ChatBubbleOutlineOutlinedIcon>
                            )}
                        </Badge>
                    </div>
                )
            },
        },
        {
            field: 'lastEngaged',
            headerName: 'Last engaged',
            type: 'date',
            width: 192,
            filterOperators: dateOperator,
            renderCell: (params) => {
                return (
                    <Box
                        sx={{
                            height: '100%',
                            width: '100%',
                        }}
                    >
                        {params.row.DealRoomSession.length > 0 ? (
                            <Box
                                className="flex flex-col justify-center"
                                sx={{
                                    height: '100%',
                                    width: '100%',
                                }}
                            >
                                <Box
                                    className="font-medium"
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    {params.row.DealRoomSession[0].email}
                                </Box>
                                <Typography
                                    className="font-normal"
                                    variant="caption"
                                    fontSize={12}
                                >
                                    {formattedDateWithTime(
                                        params.row.DealRoomSession[0].updatedAt
                                    )}
                                </Typography>
                            </Box>
                        ) : (
                            '--'
                        )}
                    </Box>
                )
            },
        },
        {
            field: 'dealRoomStatus',
            headerName: 'Deal Status',
            width: 192,
            valueOptions: dealStatus.data?.option,
            type: 'singleSelect',
            sortable: false,
            filterOperators: singleSelectOperator,
            renderCell: (params) => {
                if (params.row.dealRoomStatus === null) {
                    return <Typography>{'--'}</Typography>
                }
                const status = params.row.DealRoomStatus.value
                const color = getColor(status)
                return (
                    <Chip
                        color={color}
                        variant="outlined"
                        label={status}
                    ></Chip>
                )
            },
        },
        {
            field: 'createdAt',
            headerName: 'Created At',
            width: 192,
            type: 'date',
            sortable: false,
            filterOperators: dateOperator,
            valueFormatter(cellValue) {
                return formattedDate(cellValue.value)
            },
            renderCell: (cellValue) => {
                return <Typography>{cellValue.formattedValue}</Typography>
            },
        },
    ]
    const CustomToolbar = () => {
        return (
            <GridToolbarContainer className="w-full flex justify-between">
                <GridToolbarQuickFilter
                    placeholder="Search"
                    variant="outlined"
                    size="small"
                    data-testid="dealroom-search-input"
                    debounceMs="2000"
                    InputProps={{
                        autoComplete: 'off',
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                ></GridToolbarQuickFilter>
                <ToggleButtonGroup
                    color="primary"
                    value={status}
                    size="small"
                    exclusive
                    onChange={(_event, newValue: string) => {
                        if (newValue != null) {
                            setStatus(newValue)
                            setFilter((draft) => {
                                draft.status = newValue
                            })
                        }
                    }}
                    sx={{
                        border: '1px solid rgba(0, 0, 0, 0.12)',
                    }}
                >
                    <ToggleButton
                        value={'ACTIVE'}
                        data-testid="dealroom-filter-active-btn"
                        sx={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                        }}
                    >
                        Active
                    </ToggleButton>
                    <ToggleButton
                        value={'ARCHIVED'}
                        data-testid="dealroom-filter-archive-btn"
                    >
                        Archived
                    </ToggleButton>
                </ToggleButtonGroup>
            </GridToolbarContainer>
        )
    }

    return (
        <DataGridPro
            rows={dealRoomSearch?.data?.dealRoom}
            columns={colums}
            filterMode="server"
            onRowClick={(params) => {
                navigate(`/dealroom/${params.row.id}`)
            }}
            initialState={{
                columns: {
                    columnVisibilityModel: {
                        opportunity: false,
                        createdAt: false,
                    },
                },
                filter: {
                    filterModel: {
                        items: filter.items,
                        logicOperator:
                            filter.logicOperator === 'and'
                                ? GridLogicOperator.And
                                : GridLogicOperator.Or,
                        quickFilterLogicOperator:
                            filter.quickFilterLogicOperator === 'and'
                                ? GridLogicOperator.And
                                : GridLogicOperator.Or,
                        quickFilterValues: filter.quickFilterValues,
                    },
                },
            }}
            onFilterModelChange={onFilterChange}
            slots={{
                toolbar: CustomToolbar,
                noRowsOverlay: () => (
                    <div className="flex justify-center items-center p-4">
                        {filter.items.length === 0 &&
                        filter.quickFilterValues.length === 0
                            ? 'Create your first deal room.'
                            : 'No matching Dealroom.'}
                    </div>
                ),
                noResultsOverlay: () => (
                    <div className="flex justify-center items-center p-4">
                        No results found.
                    </div>
                ),
            }}
            slotProps={{
                filterPanel: {
                    filterFormProps: {
                        filterColumns,
                    },
                    getColumnForNewFilter,
                },
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
                row: {
                    onMouseEnter: (event) => {
                        const id = event.currentTarget.dataset.id
                        setHover(id)
                    },
                    onMouseLeave: () => setHover(null),
                },
            }}
            paginationMode="server"
            pageSizeOptions={[10]}
            paginationModel={filter}
            pagination={true}
            rowCount={dealRoomSearch.data.count}
            onPaginationModelChange={(obj) => {
                setFilter((draft) => {
                    draft.page = obj.page
                })
            }}
            sx={{
                border: 'none',
                flex: 1,
                '& .MuiDataGrid-row:hover': {
                    cursor: 'pointer',
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 300,
                },
            }}
        ></DataGridPro>
    )
}
