import isEmpty from 'lodash.isempty'
import Input from '@mui/material/Input'
import ClickAwayListener from '@mui/material/ClickAwayListener'
const TextFilter = ({
    columnData,
    setFilter,
    setFilterList,
    value,
    handleClickAway,
}) => {
    const handleChange = (event) => {
        setFilterList((draft) => {
            const index = draft.findIndex(
                (e) => e.filterColumn.label === columnData.label
            )

            draft[index] = {
                filterColumn: columnData,
                value: event.target.value,
            }
        })
        if (columnData.id === 'Asset Name') {
            setFilter((draft) => {
                draft.filter.assetName = event.target.value
                draft.currentPage = 0
            })
        } else {
            setFilter((draft) => {
                const customFilter = draft.filter.customAttributeFilter
                if (
                    event.target.value.trim() === '' &&
                    customFilter.hasOwnProperty(columnData.id)
                )
                    delete customFilter[columnData.id]
                else customFilter[columnData.id] = event.target.value
                draft.currentPage = 0
            })
        }
    }

    return (
        <>
            <ClickAwayListener
                onClickAway={() => handleClickAway(columnData.label)}
            >
                <div className="bg-white p-2">
                    <Input
                        onChange={handleChange}
                        className="overflow-hidden text-wrap w-full focus:outline-none text-sm bg-white "
                        value={!value || isEmpty(value) ? '' : value}
                        placeholder="Search"
                    ></Input>
                </div>
            </ClickAwayListener>
        </>
    )
}
export default TextFilter
