import { useQuery } from "react-query";
import axios from "axios";
import { APi_URL } from "@Common/Types/configs/urlConfig";


const getCustomAttributeType = async () => {
    const { data } = await axios.get(
        `${APi_URL}/customAttributes/types`,
        { headers: { 'Content-Type': 'application/json' } }
    )
    return {
        data: data,
        option: data.map((ele: any) => {
            return {
                label:ele.type,
                value:ele.id
            }
        })
    };
};

export default function useCustomAttributeType(props:any) {
    return useQuery("customAttributeType", getCustomAttributeType,{
        onSuccess:props.onSuccess
    });
}
