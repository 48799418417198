import { APi_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useQuery } from 'react-query'

const getAllStatus = async () => {
    const { data } = await axios.get(`${APi_URL}/dealroom/status`, {
        headers: { 'Content-Type': 'application/json' },
    })
    return {
        data: data,
        option: data.map((status: any) => ({
            value: status.id,
            label: status.value,
        })),
    }
}

export default function useGetStatus() {
    return useQuery(['dealroom', 'status'], getAllStatus)
}
