import Avatar from '@Common/Avatar'
import useUsers from '@Common/Queries/GetQueries/GetUsers'
import useInit from '@Common/Queries/GetQueries/useInit'
import { Button } from '@mui/material'
import Editor from '@Request/components/Editor'
import { AssetApproveRequestDetails } from '@Request/components/RequestDetails'
import useDeleteComments from '@Request/query/useDeleteComment'
import { formatDistanceToNow } from 'date-fns'
import OutlineLinkButton from './Button/OutlineLinkButton'
interface Comment {
    Comment: {
        id: string
        user: { name: string; id: string }
        userId: string
        comment: string
        updatedAt: string
        createdAt: string
        requestsId: string
    }
}
export interface EntityOption {
    name: 'assets' | 'requests'
    id: string
}
interface CommentProps {
    comments: Comment[]
    isAssetApprove: boolean
    entityOption: EntityOption
    assetRequestDetails?: undefined | AssetApproveRequestDetails
}

function Comment(props: CommentProps) {
    const deleteMutation = useDeleteComments()
    const init = useInit()
    const userQuery = useUsers()

    return (
        <div className="flex flex-col pb-4">
            {props.comments.map(({ Comment: comment }) => {
                return (
                    <div key={comment.id} className="pb-8">
                        <div className="flex">
                            <div className="flex">
                                <Avatar name={comment.user.name} />
                            </div>
                            <div>
                                <div className="flex gap-4 ml-4 justify-center items-center">
                                    <div className="text-gray-700">
                                        {comment.user.name}
                                    </div>
                                    <div className="text-gray-400">
                                        {formatDistanceToNow(
                                            new Date(comment.createdAt),
                                            {
                                                addSuffix: true,
                                            }
                                        )}
                                    </div>
                                    {init.data.user.id === comment.user.id && (
                                        <div className="">
                                            <Button
                                                variant="text"
                                                color="error"
                                                onClick={() =>
                                                    deleteMutation.mutate({
                                                        commentId: comment.id,
                                                        entityOption:
                                                            props.entityOption,
                                                    })
                                                }
                                            >
                                                Delete
                                            </Button>
                                        </div>
                                    )}
                                </div>
                                <div className="ml-4 text-gray-700">
                                    <Editor
                                        users={userQuery}
                                        style={{ margin: '0px' }}
                                        editable={false}
                                        content={comment.comment}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-4">
                            {props.isAssetApprove &&
                                comment.userId ===
                                    '32i0uv0s5663u3c1mfur7esrjv' &&
                                (comment.comment ===
                                    'Sanitisation Process: Attribute Detection - Completed' ||
                                    comment.comment ===
                                        'Attribute  Detection  completed') && (
                                    <Button
                                        LinkComponent="a"
                                        target="_blank"
                                        href={`https://d3ezovze23gnjl.cloudfront.net?assetid=${props.assetRequestDetails?.assetId}&fileId=${props.assetRequestDetails?.fileId}&requestid=${props.assetRequestDetails?.requestId}&assetName=${props.assetRequestDetails?.assetName}`}
                                        rel="noreferrer"
                                    >
                                        Click here to sanitize the file
                                    </Button>
                                )}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
export default Comment
