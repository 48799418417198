import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { toast } from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router'

export const useArchiveDealroom = () => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    return useMutation(
        (data: {
            dealRoomId : string
        }) =>
            axios.put(`${API_URL}/dealroom/archive/${data.dealRoomId}`, data, {
                headers: {
                    'Content-type': 'application/json',
                },
            }),
        {
            onSuccess: (data) => {
                toast.success('Dealroom successfully archived')
                queryClient.invalidateQueries(['dealroom', data.id])
                queryClient.invalidateQueries(['dealroom', 'search'])
            },
            onMutate: (data) => {
                const dealRoom = queryClient.getQueryData([
                    'dealroom',
                    data.dealRoomId,
                ])
                if (!dealRoom) {
                    return
                }

                queryClient.setQueryData(['dealroom', data.dealRoomId], {
                    dealRoom: {
                        ...dealRoom.dealRoom,
                        status: 'ARCHIVED',
                    },
                    thumbnail: dealRoom.thumbnail,
                })
            },
            onError: () => {
                toast.error('Error in archiving the dealroom')
            },
        }
    )
}
