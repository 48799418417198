import Loading from '@Common/Components/Loading'
import useGetAsset from '@Common/Queries/GetQueries/GetAsset'
import useCustomAttribute from '@Common/Queries/GetQueries/GetCustomAttributes'
import useUsers from '@Common/Queries/GetQueries/GetUsers'
import useInit from '@Common/Queries/GetQueries/useInit'
import { useNavigate, useParams } from 'react-router-dom'
import { Updater } from 'use-immer'
import ViewAsset, { Asset } from './Components/ViewAsset'

const ViewAssetScreen = (props: {
    asset?: { assetId: string; fileId: string; data: Asset }
    location?: string
    setAsset?: Updater<{ assetId: string; fileId: string }>
    setOpen?: Updater<boolean>
}) => {
    const { id } = useParams()

    const navigate = useNavigate()

    const asset = useGetAsset({
        id: props?.asset?.assetId || id,
        data: props?.asset?.data,
    })

    const users = useUsers()
    const { data: customAttributesData, isLoading: isLoadingCustomAttributes } =
        useCustomAttribute()
    const init = useInit()

    return (
        <>
            <div className="w-full h-full overflow-auto pb-6">
                {!asset.isLoading &&
                !init.isLoading &&
                !users.isLoading &&
                !init.isLoading &&
                !isLoadingCustomAttributes ? (
                    asset?.data?.action === 'NO_ACCESS' || asset.isError ? (
                        navigate('/search')
                    ) : (
                        <ViewAsset
                            asset={asset?.data}
                            ownerData={users.data}
                            customAttributes={customAttributesData?.data}
                            location={props.location}
                            setAsset={props.setAsset}
                            setOpen={props.setOpen}
                        ></ViewAsset>
                    )
                ) : (
                    <Loading></Loading>
                )}
            </div>
        </>
    )
}

export default ViewAssetScreen
