import { Popover } from '@mui/material'
import { FC } from 'react'
import { Updater } from 'use-immer'

const PopoverComponent: FC<{
    children: any
    state: { open: boolean; anchorEl: any }
    setState: Updater<{ open: boolean; anchorEl: any }>
}> = ({ state, children, setState }) => {
    const handleClose = () => {
        setState((draft) => {
            return {
                open: false,
                anchorEl: null,
            }
        })
    }
    return (
        <Popover
            open={state.open}
            anchorEl={state.anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            onClose={handleClose}
        >
            {children}
        </Popover>
    )
}
export default PopoverComponent
