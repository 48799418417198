import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { APi_URL } from "@Common/Types/configs/urlConfig";


export default function useCustomAttributeField(props: any) {
  const queryClient = useQueryClient()
  return useMutation<any, Error,object>((obj: any) => axios.put(
    `${APi_URL}/customAttributes/${obj.id}`,
    obj.body,
    { headers: { 'Content-Type': 'application/json' } }),
    {
      onSuccess: (data: any, obj: any) => {
        queryClient.invalidateQueries(["customAttribute"])
        props.onSuccess(data)
        // queryClient.invalidateQueries(["assets", obj.assetId, "details"])
      }
    });
}