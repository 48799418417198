import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { APi_URL } from "@Common/Types/configs/urlConfig";


export default function useDeleteCustomAttribute(props:any) {
    const queryClient = useQueryClient()
    return useMutation<any, Error, object>((val: any) => axios.delete(
        `${APi_URL}/customAttributes/${val.id}`,
        { headers: { 'Content-Type': 'application/json' } }),
        {
            onSuccess: (data:any,obj:any) => {
                props.onSuccess(data)
                queryClient.invalidateQueries(["customAttribute"])
            },
            onError:props.onError
        });
}

