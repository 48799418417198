import { AddCustomAttributeToAsset } from '@Common/Queries/MutationQueries/AddCustomAttributeToAsset'
import { RemoveCustomAttributeFromAsset } from '@Common/Queries/MutationQueries/RemoveCustomAttributeFromAsset'
import isEmpty from 'lodash.isempty'

const constructCustomAttributes = (
    assetCustomAttribute: Array<{
        id: string
        assetAttributeValues: Array<{
            id: string
            attributeValue: {
                id: string
            }
        }>
    }>
) => {
    const customAttributes = assetCustomAttribute.reduce(
        (accumulator: { [x: string]: Array<string> }, currentValue) => {
            let value: string[] = []
            if (currentValue.id in accumulator) {
                value = accumulator[currentValue.id]
            }
            value.concat(
                currentValue.assetAttributeValues.map(
                    (e) => e.attributeValue.id
                )
            )
            accumulator[currentValue.id] = value
            return accumulator
        },
        {}
    )
    return customAttributes
}

const constructUserDefineCustomAttr = (
    userDefinedAttr: Array<{
        attributeId: string
        value: string
    }>
) => {
    const customAttributes = userDefinedAttr.reduce(
        (accumulator: { [x: string]: string }, currentValue) => {
            accumulator[currentValue.attributeId] = currentValue.value
            return accumulator
        },
        {}
    )
    return customAttributes
}
const constructPermission = (
    assetPermissions: Array<{
        permission: string
        AssetUserPermission: {
            userId: string
        }
        AssetGroupPermission: {
            groupId: string
        }
    }>
) => {
    const permissions = assetPermissions.reduce((accumulator, currentValue) => {
        if (currentValue.AssetUserPermission != null) {
            return {
                permission: currentValue.permission,
                type: 'USER',
                entityId: currentValue.AssetUserPermission.userId,
            }
        } else if (currentValue.AssetGroupPermission != null) {
            return {
                permission: currentValue.permission,
                type: 'GROUP',
                entityId: currentValue.AssetGroupPermission.groupId,
            }
        }
    }, [])
    return permissions
}
export const searchUpdaterFunctionForUpload = (asset) => {
    const predefinedCustomAttr = constructCustomAttributes(
        asset.AssetCustomAttribute
    )
    const userInputCustomAttr = constructUserDefineCustomAttr(
        asset.UserInputCustomAttributeValue
    )
    const permissions = constructPermission(asset.AssetPermission)
    const searchData = {
        ...asset,
        filename: asset.LatestFile.fileName,
        fileId: asset.LatestFile.id,
        permissions: permissions,
        customAttributes: { ...predefinedCustomAttr, ...userInputCustomAttr },
        action: 'FULL_ACCESS',
    }
    return searchData
}

export const constructAssetLevelCustomAttribute = (
    customAttributeFromSearch: {
        [x: string]: string | string[]
    },
    customAttribute
) => {
    const assetCustomAttribute = []
    const userDefineCustomAttribute = []
    for (const assetAttribute in customAttributeFromSearch) {
        const attribute = customAttribute.data.find(
            (e) => e.id === assetAttribute
        )
        if (attribute === undefined) {
            return
        }
        //if it is single select multi select or tags it comes as array of values
        if (typeof customAttributeFromSearch[assetAttribute] == 'object') {
            const attributeValues = attribute.option.filter((value) => {
                return customAttributeFromSearch[assetAttribute].some((e) => {
                    return e === value.id
                })
            })

            assetCustomAttribute.push({
                ...attribute,
                assetAttributeValues: attributeValues.map((e) => {
                    return {
                        attributeValue: e,
                    }
                }),
            })
        } else {
            userDefineCustomAttribute.push({
                ...attribute,
                assetAttributeValues: {
                    value: customAttributeFromSearch[assetAttribute],
                },
            })
        }
    }
    return {
        AssetCustomAttribute: assetCustomAttribute,
        UserInputCustomAttributes: userDefineCustomAttribute,
    }
}

export const searchDataIntoViewAsset = (asset, customAttribute, users) => {
    const assetCustomAttributeData = constructAssetLevelCustomAttribute(
        asset.customAttributes,
        customAttribute
    )
    //const permission = constructAssetLevelPermission(asset.)

    const viewAssetData = {
        ...asset,
        AssetPermission: asset.permissions,
        ...assetCustomAttributeData,
        AssetProgress: [
            {
                assetId: asset.id,
                statusType: 'CONTENT',
                status: 'INIT',
            },
        ],
    }
    return viewAssetData
}

export const updateSearchWithCustomAttributeAddition = (
    prevAsset: {
        data: {
            assets: Array<{
                customAttributes: { [x: string]: string[] | string }
            }>
            count: number
        }
    },
    data: AddCustomAttributeToAsset
) => {
    const assetIndex = prevAsset.data.assets.findIndex(
        (e) => e.id == data.assetId
    )
    const assets: Array<{
        customAttributes: { [x: string]: string[] | string }
    }> = Object.assign([], prevAsset.data.assets)
    const asset = Object.assign({}, assets[assetIndex])
    const assetCustomAttribute = Object.assign({}, asset.customAttributes)

    if (data.attributeValue) {
        const attribute = !isEmpty(assetCustomAttribute)
            ? Object.assign([], assetCustomAttribute[data.attribute.id])
            : []
        if (data.attribute.type === 'Single-select') {
            attribute.splice(0, 1)
        }
        attribute.push(data.attributeValue.id as string)

        assetCustomAttribute[data.attribute.id as string] = attribute
    } else if (data.value) {
        let value = data.value
        if (typeof data.value === 'object') {
            value = new Date(data.value).toISOString()
        }
        assetCustomAttribute[data.attribute.id as string] = value
    }
    asset.customAttributes = assetCustomAttribute

    assets[assetIndex] = asset
    return {
        data: {
            count: prevAsset.data.count,
            assets: assets,
        },
    }
}

export const updateSearchWithCustomAttributeRemoval = (
    prevAsset: {
        data: {
            assets: Array<{
                customAttributes: { [x: string]: string[] | string }
            }>
            count: number
        }
    },
    data: RemoveCustomAttributeFromAsset
) => {
    const assetIndex = prevAsset.data.assets.findIndex(
        (e) => e.id == data.assetId
    )
    const assets: Array<{
        customAttributes: { [x: string]: string[] | string }
    }> = Object.assign([], prevAsset.data.assets)
    const asset = Object.assign({}, assets[assetIndex])

    const assetCustomAttribute = Object.assign({}, asset.customAttributes)

    // if (data.attributeValue) {
    //     const attribute = !isEmpty(assetCustomAttribute)
    //         ? Object.assign([], assetCustomAttribute[data.attribute.id])
    //         : []
    //     const index = attribute.findIndex((e) => e == data.attributeValue.id)
    //     attribute.splice(index, 1)
    //     asset.customAttributes[data.attribute.id as string] = attribute
    // } else if (data.value) {
    //     delete assetCustomAttribute[data.attribute.id]
    // }

    if (['Date', 'Number', 'Text'].includes(data.attribute.type)) {
        delete assetCustomAttribute[data.attribute.id]
    } else {
        const attribute = !isEmpty(assetCustomAttribute)
            ? Object.assign([], assetCustomAttribute[data.attribute.id])
            : []
        const index = attribute.findIndex((e) => e == data.attributeValue?.id)
        attribute.splice(index, 1)
        assetCustomAttribute[data.attribute.id as string] = attribute
    }

    asset.customAttributes = assetCustomAttribute
    assets[assetIndex] = asset
    return {
        data: {
            count: prevAsset.data.count,
            assets: assets,
        },
    }
}

export const deleteAssetFromSearch = (prevData, data) => {
    const index = prevData.findIndex((e) => e.id == data)
    prevData.splice(index, 1)
    return prevData
}

export const updateAssetInSearch = (prevData, data) => {
    const index = prevData.findIndex((e) => e.id == data.id)
    const assets = prevData[index]
    if (data.body.name) {
        assets.name = data.body.name
    }
    prevData[index] = assets
    return prevData
}
