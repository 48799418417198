import { useQuery, useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'
import { Navigate } from 'react-router-dom'

export default function useDeleteSite(props?: any) {
    const queryClient = useQueryClient()
    return useMutation<any, Error, string>(
        (val: string) =>
            axios.delete(`${APi_URL}/site/${val}`, {
                headers: { 'Content-Type': 'application/json' },
            }),
        {
            onSuccess: (data: any, obj: any) => {
                queryClient.invalidateQueries({
                    queryKey: ['sites', 'search'],
                    refetchActive: true,
                })
                queryClient.invalidateQueries(['sites', data.id])
                if (props.onSuccess) {
                    props.onSuccess()
                }
            },
            onMutate: async (val: string) => {
                await queryClient.cancelQueries(['sites', val])
                await queryClient.setQueriesData(
                    ['sites', 'search'],
                    (previous) => {
                        const prevData = Object.assign([], previous.sites)
                        const index = prevData.findIndex((e) => e.id == val)
                        prevData.splice(index, 1)
                        return {
                            sites: prevData,
                            count: prevData.length,
                        }
                    }
                )
            },
            onError: (data: any, obj: any) => {
                if (props.onError) {
                    props.onError(data, obj)
                }
            },
        }
    )
}
