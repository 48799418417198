import { APi_URL } from "@Common/Types/configs/urlConfig"
import axios from "axios"
import toast from "react-hot-toast"
import { useMutation } from "react-query"

const postNewAssetRequest = () => useMutation<any, any, any>(
  async (params) => {

    const {text, feedback, setFeedback, sendDataToGTM} = params;

    const data = await axios.post(`${APi_URL}/requests/new`, {
      description: text,
    }, {
        headers: {
            'Content-Type': 'application/json',
            timeZone:
                Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
    })
    sendDataToGTM({
        event: 'raise_request_asset_required',
        action: 'raise',
        request_id: data.data.id,
    })
    if(data){
      setFeedback({
        ...feedback,
        open: false,
        anchorEl: null
      })
    }
},
  {
      onSuccess: (res: any) => {
        toast.success("Someone from the central asset team will help you with the submission");

      },
      onError: (err: any) => {
        toast.error("Somthing went wrong ! Raise Request Failed !");
      },
  }
)

export default postNewAssetRequest;