import { ExternalLinkIcon } from '@heroicons/react/outline'
import { Link, useParams } from 'react-router-dom'
import OutlineSecondaryButton from '@Common/Components/Button/OutlineSecondary'
import PrimaryButton from '@Common/Components/Button/PrimaryButton'
import useApproveAccessRequest from '@Request/query/useApproveAccessRequest'
import useUserApproveAccessRequest from '@Request/query/useUserApproveRequest'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import { Button } from '@mui/material'
interface ApproveRequestDetailsProps {
    data: any
    isAdmin: boolean
    isSameUser: boolean
}

export default function ApproveRequestDetails(
    props: ApproveRequestDetailsProps
) {
    const { data, isAdmin, isSameUser } = props
    const approveAccessRequest = useApproveAccessRequest()
    const userApproveAccessRequest = useUserApproveAccessRequest()
    const sendDataToGTM = useGTMDispatch()

    function handleAction(status: string) {
        approveAccessRequest.mutate({
            requestsId: data.id,
            status,
        })
    }
    function getActionPanel() {
        if (data.status === 'CLOSED') {
            return (
                <>
                    <hr className="-mx-5" />
                    <div
                        className={`h-16 flex items-center ${
                            data.AssetPermissionRequests.resolution ===
                            'APPROVED'
                                ? 'text-green-500'
                                : 'text-red-500'
                        }`}
                    >
                        {data.AssetPermissionRequests.resolution}
                    </div>
                </>
            )
        }
        if (isAdmin) {
            if (data.status !== 'CLOSED') {
                return (
                    <>
                        <hr className="-mx-5" />
                        <div className="py-5 gap-2 flex">
                            <Button
                                variant="contained"
                                onClick={() => {
                                    handleAction('APPROVED')
                                    sendDataToGTM({
                                        event: 'access_request_action',
                                        action: 'APPROVE',
                                        request_id: data.id,
                                    })
                                }}
                            >
                                Approve
                            </Button>
                            <Button
                                color="error"
                                onClick={() => {
                                    handleAction('REJECT')
                                    sendDataToGTM({
                                        event: 'access_request_action',
                                        action: 'REJECT',
                                        request_id: data.id,
                                    })
                                }}
                            >
                                Reject
                            </Button>
                        </div>
                    </>
                )
            }
        }
        if (isSameUser) {
            return (
                <>
                    <hr className="-mx-5" />{' '}
                    <div className="py-5 gap-2 flex">
                        <Button
                            variant="contained"
                            onClick={() => {
                                userApproveAccessRequest.mutate({
                                    requestsId: data.id,
                                    status: 'CLOSED',
                                })
                            }}
                        >
                            Close Request
                        </Button>
                    </div>
                </>
            )
        }
    }
    return (
        <>
            <Link
                to={`/search/viewAsset/${data.AssetPermissionRequests.assetId}`}
            >
                <div className="text-lg pb-6 hover:underline flex items-center	">
                    {data.AssetPermissionRequests.asset.name}
                    <ExternalLinkIcon className="h-5 pl-4 text-blue-500" />
                </div>
            </Link>
            {getActionPanel()}
        </>
    )
}

const style = {
    container: 'w-full  outline-none px-5 rounded-3px bg-white mb-5',

    mainContainer: ' h-full   bg-bgColor  px-10 py-5 overflow-auto',
}
