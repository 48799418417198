import { useAddDealroomPermission } from '@Common/Queries/Dealroom/AddDealroomPermission'
import { useDeleteDealroomPermission } from '@Common/Queries/Dealroom/DeleteDealroomPermission'
import { useDealRoom } from '@Common/Queries/Dealroom/GetDealRoom'
import { useUpdateDealroomPermission } from '@Common/Queries/Dealroom/UpdateDealroomPermission'
import useInit from '@Common/Queries/GetQueries/useInit'
import Search from '@mui/icons-material/Search'
import { Button, InputAdornment, TextField } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { getPermissions } from 'src/Sites/permission'
import { SelectInfo } from 'src/ViewAssetScreen/Components/SelectInfo'
import { useImmer } from 'use-immer'
import { AddMemberDealroom } from './AddMemberDealroom'
import { DealRoomProps } from './DealRoom'
import { useUpdateDealRoom } from '@Common/Queries/Dealroom/UpdateDealRoom'
function isSingleFullAccess(dealroom) {
    let fullPermissions = 0
    if (dealroom.action === 'FULL_ACCESS') {
        fullPermissions = fullPermissions + 1
    }
    const permissions = dealroom.DealRoomPermission.reduce(
        (acc, permission) => {
            if (permission.permission === 'FULL_ACCESS') {
                return acc + 1
            }
            return acc
        },
        fullPermissions
    )
    return permissions === 1
}

export const DealRoomPermission = (props: { dealRoom: DealRoomProps }) => {
    const { id } = useParams()
    const navigate = useNavigate()
    if (!id) {
        navigate('/dealroom')
    }

    const deletePermission = useDeleteDealroomPermission()
    const updatePermission = useUpdateDealroomPermission()
    const addPermission = useAddDealroomPermission()

    const [addMember, setAddMember] = useImmer({
        enabed: false,
    })
    const init = useInit()
    const tenantType: string = init?.data.tenant?.TenantConfig?.type
    const memberPermissions = getPermissions('member', tenantType)
    const permissions = getPermissions('asset', tenantType)
    const updateDealRoom = useUpdateDealRoom()

    const disableFullAccess = isSingleFullAccess(props.dealRoom)
    const filteredPermission = props.dealRoom.DealRoomPermission.filter(
        (e) => e.DealRoomGuestEmailPermission === null
    )
    return (
        <div className="p-6 w-96 flex flex-col gap-5">
            <div className="text-gray-400 font-medium ">
                Access & Permission
            </div>
            {!addMember.enabed && (
                <>
                    <div className="flex items-center justify-between">
                        <TextField
                            size="small"
                            variant="outlined"
                            onClick={() => {
                                setAddMember((draft) => {
                                    draft.enabed = true
                                })
                            }}
                            className="text-sm w-64"
                            placeholder="Add members"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button variant="contained">Invite</Button>
                    </div>

                    <div className="flex flex-col gap-2">
                        <div
                            className={`flex justify-between ${
                                disableFullAccess &&
                                props.dealRoom?.action === 'FULL_ACCESS'
                                    ? 'text-gray-300'
                                    : ''
                            }`}
                        >
                            <div className="flex items-center">Everyone</div>
                            <SelectInfo
                                className="w-28"
                                disabled={
                                    disableFullAccess &&
                                    props.dealRoom.action === 'FULL_ACCESS'
                                }
                                value={props.dealRoom.access}
                                onChange={(e) => {
                                    updateDealRoom.mutate({
                                        id: id,
                                        data: {
                                            access: e.target.value,
                                        },
                                    })
                                }}
                                items={permissions}
                            ></SelectInfo>
                        </div>
                        {filteredPermission.map((permission, id: number) => {
                            const isDisabled =
                                disableFullAccess &&
                                permission.permission === 'FULL_ACCESS'
                            return (
                                <div
                                    className={`flex justify-between ${
                                        isDisabled
                                            ? 'text-gray-300 cursor-not-allowed'
                                            : ''
                                    }`}
                                    key={id}
                                >
                                    <div className="flex items-center">
                                        {permission.DealRoomUserPermission !==
                                        null
                                            ? permission.DealRoomUserPermission
                                                  .User.name
                                            : permission.DealRoomGroupPermission
                                                  ?.Group?.name}
                                    </div>
                                    <SelectInfo
                                        className="w-28 "
                                        disabled={isDisabled}
                                        value={permission.permission}
                                        onChange={(e) => {
                                            if (e.target.value === 'REMOVE') {
                                                deletePermission.mutate({
                                                    dealroomId:
                                                        props.dealRoom.id,
                                                    dealroomPermissionId:
                                                        permission.id,
                                                })
                                                return
                                            }
                                            updatePermission.mutate({
                                                dealroomId: props.dealRoom.id,
                                                dealroompermissionId:
                                                    permission.id,
                                                body: {
                                                    permission: e.target.value,
                                                },
                                            })
                                        }}
                                        items={memberPermissions}
                                    ></SelectInfo>
                                </div>
                            )
                        })}
                    </div>
                </>
            )}
            {addMember.enabed && (
                <AddMemberDealroom
                    dealroom={props.dealRoom}
                    onClose={() => {
                        setAddMember((draft) => {
                            draft.enabed = false
                        })
                    }}
                ></AddMemberDealroom>
            )}{' '}
        </div>
    )
}
