import { useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon } from '@heroicons/react/outline'

function ModalAPI(props: any) {
    const [isOpen, setIsOpen] = useState(props.isOpenAPI)

    return (
        <Transition
            show={isOpen}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
        >
            <Dialog
                onClose={() => {
                    setIsOpen(false)
                    props.setIsOpenAPI(false)
                }}
                className="fixed z-10 inset-0 overflow-y-auto"
            >
                <div className="flex items-center justify-center min-h-screen">
                    <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

                    <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                        <div className="flex flex-row">
                            <div className="pr-3">
                                <ExclamationIcon
                                    className="h-6 w-6 text-red-600"
                                    aria-hidden="true"
                                />
                            </div>
                            <div>
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-red-600"
                                >
                                    {/* {props.eFile.name} */}
                                    File Size Too Large
                                </Dialog.Title>
                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        Each File Size limit is 10 MB
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="mt-4 ">
                            <button
                                type="button"
                                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-black-900 bg-blue-300 border border-transparent rounded-md hover:bg-blue-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                onClick={() => {
                                    setIsOpen(false)
                                    props.setIsOpenAPI(false)
                                }}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}
export default ModalAPI
