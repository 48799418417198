import { useQuery } from 'react-query'
import axios from 'axios'
import { API_URL } from '@Common/Types/configs/urlConfig'

const getCommentsByAssetId = async (assetId: string) => {
    const { data } = await axios.get(`${API_URL}/comments/assets/${assetId}`)
    return data
}

export default function useAssetComments(assetId: string) {
    return useQuery(['comments', 'assets', assetId], () =>
        getCommentsByAssetId(assetId)
    )
}
