import React from 'react'

import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Checkbox, Fade, IconButton, Menu, MenuItem } from '@mui/material'
import { Column, ColumnDef, Table } from '@tanstack/react-table'
import classNames from 'classnames'
import { Updater } from 'use-immer'
interface ColumnsMenuIconProps {
    columnOptions: Column<Record<string, unknown>>
    table: Table<unknown>
    filter: Record<string, unknown>
    setFilter: Updater<Record<string, unknown>>
    filterList: Record<string, any>
    columnState: {
        columnVisibility: Record<string, boolean>
        columnOrder: ColumnDef<unknown, any>[] | string[]
    }
    setColumnState: Updater<{
        columnVisibility: Record<string, boolean>
        columnOrder: ColumnDef<unknown, any>[] | string[]
    }>
    tableConfigCallback: any
}

export default function ColumnsMenuIcon(props: ColumnsMenuIconProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const menuOpen = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const { columnOptions, columnState, setColumnState } = props

    const hideOrShowAllElements = (flag: string) => {
        if (flag === 'hide') {
            props.table.toggleAllColumnsVisible(false)
        } else {
            props.table.toggleAllColumnsVisible(true)
        }
    }

    const showHideColumns = (event, column) => {
        column.column.toggleVisibility(event.target.checked)
    }
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" color="inherit" />
    const checkedIcon = <CheckBoxIcon fontSize="small" />
    return (
        <>
            <div className="min-w-8 max-w-screen">
                <IconButton className="rounded-md " onClick={handleClick}>
                    <MoreVertIcon color="disabled"></MoreVertIcon>
                </IconButton>
            </div>
            <Menu
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleClose}
                TransitionComponent={Fade}
                PaperProps={{
                    className: 'max-h-60 w-52 px-3 oevrflow-y-scroll',
                }}
            >
                <div
                    key="displayedColumns"
                    className="relative flex flex-row justify-between text-xs  pb-2"
                >
                    <span className="text-lightGrayTextColor">
                        Displayed Columns
                    </span>

                    <button
                        className={classNames('text-primaryButton bg-white', {
                            hidden:
                                Object.values(
                                    columnState.columnVisibility
                                ).filter((e) => e === true).length === 1 &&
                                columnState.columnVisibility['Asset Name'] ===
                                    true,
                        })}
                        onClick={() => {
                            handleClose()
                            hideOrShowAllElements('hide')
                        }}
                    >
                        Hide All
                    </button>
                </div>
                {columnOptions.map(
                    (col: { column: any }, ind: number) =>
                        col.column.getIsVisible() && (
                            <MenuItem
                                key={ind}
                                className="flex p-0 hover:cursor-pointer"
                                onClick={(e) => showHideColumns(e, col)}
                            >
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    size="small"
                                    checked
                                    disabled={!col.column.getCanHide()}
                                ></Checkbox>
                                <label
                                    className={classNames(
                                        'text-sm text-black-900 hover:cursor-pointer',
                                        {
                                            'text-black-900':
                                                col.column.getCanHide(),
                                            'text-lightGrayTextColor':
                                                !col.column.getCanHide(),
                                        }
                                    )}
                                >
                                    {col.column.columnDef.header}
                                </label>
                            </MenuItem>
                        )
                )}
                {Object.values(columnState.columnVisibility).includes(
                    false
                ) && (
                    <div className="my-2">
                        <hr></hr>
                        <div
                            key="hiddenColumn"
                            className="relative flex flex-row justify-between text-xs py-2"
                        >
                            <span className="block text-lightGrayTextColor">
                                Hidden Columns
                            </span>
                            <button
                                className="text-primaryButton bg-white"
                                onClick={() => {
                                    handleClose()
                                    hideOrShowAllElements('show')
                                }}
                            >
                                Show All
                            </button>
                        </div>
                        {columnOptions.map(
                            (col: { column: any }, ind: number) =>
                                !col.column.getIsVisible() && (
                                    <MenuItem
                                        key={ind}
                                        className="flex p-0 hover:cursor-pointer"
                                        onClick={(e) => {
                                            showHideColumns(e, col)
                                        }}
                                    >
                                        <Checkbox size="small"></Checkbox>
                                        <label
                                            className={classNames(
                                                'text-sm text-black-900 hover:cursor-pointer'
                                            )}
                                        >
                                            {col.column.columnDef.header}
                                        </label>
                                    </MenuItem>
                                )
                        )}
                    </div>
                )}
            </Menu>
        </>
    )
}
