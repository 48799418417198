import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { v4 as uuid } from 'uuid'
export const useCreateSection = (props?: {
    onSuccess?: any
    userId: string
}) => {
    const queryClient = useQueryClient()
    const id = uuid()
    return useMutation(
        (dealRoomId: string) =>
            axios.post(
                `${API_URL}/dealroom/${dealRoomId}/section`,
                {
                    id: id,
                },
                {
                    headers: {
                        'Content-type': 'application/json',
                    },
                }
            ),
        {
            onSuccess: (data) => {
                if (props?.onSuccess) {
                    props.onSuccess()
                }
                queryClient.invalidateQueries(['dealroom', data.data.id])
            },
            onMutate: (data) => {
                queryClient.cancelQueries(['dealroom', data])
                const dealroomData:
                    | {
                          dealRoom: any
                          thumbnail: Array<{ id: string; thumbnailUrl: string }>
                      }
                    | undefined = queryClient.getQueryData(['dealroom', data])
                if (!dealroomData) {
                    return
                }
                const dealroomSection = dealroomData.dealRoom.DealRoomSection
                dealroomSection.push({
                    DealRoomSectionAsset: [],
                    createdAt: new Date(),
                    createdBy: props?.userId,
                    dealRoomId: data,
                    id: id,
                    name: 'Untitled',
                    status: 'ACTIVE',
                    updatedAt: new Date(),
                })

                queryClient.setQueryData(['dealroom', data], {
                    dealRoom: {
                        ...dealroomData.dealRoom,
                        DealRoomSection: dealroomSection,
                    },
                    thumbnail: dealroomData.thumbnail,
                })
            },
        }
    )
}
