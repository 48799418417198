import useInit from '@Common/Queries/GetQueries/useInit'
import { APi_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
export interface CreateComment {
    requestsId: string
    comment: string
}
export default function useAddComments(props?: any) {
    const queryClient = useQueryClient()
    const init = useInit()
    return useMutation<any, any, CreateComment>(
        (obj: CreateComment) =>
            axios.post(
                `${APi_URL}/comments/requests/${obj.requestsId}`,
                { comment: obj.comment, type: 'prosemirror' },
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            ),
        {
            onSuccess: (data: CreateComment, obj: any) => {
                if (props?.onSuccess) props.onSuccess(data, obj)
            },
            onMutate: async (data: CreateComment) => {
                await queryClient.cancelQueries(['comments', data.requestsId])
                const previousComments = queryClient.getQueryData([
                    'comments',
                    'requests',
                    data.requestsId,
                ])
                queryClient.setQueryData(
                    ['comments', 'requests', data.requestsId],
                    (old) => [
                        ...old,
                        {
                            Comment: {
                                ...data,
                                createdAt: new Date(),
                                user: {
                                    name: init.data.user.name,
                                    id: init.data.user.id,
                                },
                            },
                        },
                    ]
                )
                return { previousComments }
            },
            onError: (err: any, obj: any, context: any) => {
                queryClient.setQueryData(
                    ['comments', 'requests', obj.requestsId],
                    context.previousComments
                )

                if (props.onError) props.onError(err, obj)
            },
            onSettled: (data: any) => {
                queryClient.invalidateQueries([
                    'comments',
                    'requests',
                    data.data.requestsId,
                ])
            },
        }
    )
}
