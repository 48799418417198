import { useQuery } from 'react-query'
import axios from 'axios'
import { API_URL } from '@Common/Types/configs/urlConfig'

export default function useVisits(
    assetId: string,
    body: {
        currentPage: number
        pageSize: number
    }
) {
    return useQuery(
        ['assets', 'visits', assetId, body],
        async () => {
            const result = await axios.post(
                `${API_URL}/trackLink/asset/visit/${assetId}`,
                body
            )
            return result.data
        },

        {
            keepPreviousData: true,
        }
    )
}
