import Loading from '@Common/Components/Loading'
import { useDealRoom } from '@Common/Queries/Dealroom/GetDealRoom'
import { VIEWER_DOMAIN } from '@Common/Types/configs/urlConfig'
import { Button } from '@mui/material'
import { Link } from 'react-router-dom'
import DealRoomShare from 'src/DealRoom/Components/DealRoomShare'
import { Updater } from 'use-immer'

export const PluginDealRoomShare = (props: {
    id: string
    code: string
    setOpen: Updater<any>
}) => {
    const params = new URLSearchParams(window.location.search)
    const id = params.get('id')
    const code = params.get('code')
    const source = params.get('source')
    const dealroom = useDealRoom({ id: id })
    if (!id) {
        return null
    }
    if (dealroom.isLoading) {
        return <Loading />
    }
    return (
        <div className="flex flex-col h-full w-full">
            <div className="flex-1">
                <DealRoomShare
                    id={id}
                    code={code}
                    source={source}
                    dealroom={dealroom.data.dealRoom}
                />
            </div>
            <div className="flex w-full justify-between  h-16 border-t border-gray-300 items-center p-3">
                <div>
                    <Link to="/plugin">
                        <Button>Back</Button>
                    </Link>
                </div>
                <div className="">
                    <Button
                        variant="contained"
                        onClick={() => {
                            window.parent.postMessage(
                                {
                                    name: 'dealroom_link',
                                    link: `https://${VIEWER_DOMAIN}/dealroom/${code}`,
                                    dealroom:
                                        dealroom.data.dealRoom
                                            .DealRoomProperties.name,
                                },
                                '*'
                            )
                        }}
                    >
                        Insert link
                    </Button>
                </div>
            </div>
        </div>
    )
}
