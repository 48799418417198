import useUpdateUser from '@Common/Queries/MutationQueries/UpdateUser'
import { Dialog, Transition } from '@headlessui/react'
import { Button } from '@mui/material'
import { Fragment } from 'react'
import toast from 'react-hot-toast'
import Select from 'react-select'
import { useImmer } from 'use-immer'
import OutlineSecondaryButton from '../Button/OutlineSecondary'
import PrimaryButton from '../Button/PrimaryButton'

const EditUserModal: React.FC = ({ setUserData, userData, rolesData }) => {
    const colourStyles = {
        menuList: (styles: any, { isDisabled }: any) => {
            return {
                ...styles,
                height: '105px',
            }
        },
    }
    const onSuccessUpdateUser = () => {
        toast.success('User successfully updated')
        setUserData((draft) => {
            draft.isOpen = false
        })
    }
    const {
        mutate: updateUser,
        isSuccess: isSuccessUpdateUser,
        isError: isErrorUpdateUser,
        isLoading: isLoadingUpdateUser,
    } = useUpdateUser({ onSuccess: onSuccessUpdateUser })
    const [error, setError] = useImmer('')
    const editUser = () => {
        if (userData.data.name === '') {
            setError('Name cannot be empty')
        } else if (userData.data.role.length == 0) {
            setError('Atleast one role must be retained')
        } else {
            const body = {
                id: userData.data.id,
                body: {
                    name: userData.data.name,
                    roles: userData.data.role.map((e) => e.value),
                },
            }
            updateUser(body)
        }
    }
    const rolesAddition = (newValue: any, actionMeta: any) => {
        if (actionMeta.action === 'select-option') {
            console.log('selected--', actionMeta.option)
            const role = {
                value: actionMeta.option.value,
                name: actionMeta.option.name,
                label: actionMeta.option.label,
            }
            setUserData((draft) => {
                draft.data.role.push(role)
            })
        } else if (actionMeta.action === 'remove-value') {
            setUserData((draft) => {
                const roleIndex = draft.data.role.findIndex(
                    (role) => role.value === actionMeta.removedValue.value
                )
                draft.data.role.splice(roleIndex, 1)
            })
        }
    }

    return (
        <>
            {
                <Transition appear show={true} as={Fragment}>
                    <Dialog
                        as="div"
                        className={style.dialogContainer}
                        onClose={() => {}}
                    >
                        <div className={style.subContainer} style={{}}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay
                                    className={style.dialogOverlay}
                                />
                            </Transition.Child>

                            <span className={style.span} aria-hidden="true">
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div className={style.dialogTitleContainer}>
                                    <Dialog.Title
                                        as="h2"
                                        className={style.dialogTitle}
                                    >
                                        {'Edit User'}
                                    </Dialog.Title>
                                    <div className="flex flex-col">
                                        <div className="flex w-full mt-5">
                                            <span className="text-groupLabel  font-Segoe w-1/3">
                                                User Name
                                            </span>
                                            <input
                                                value={userData.data.name}
                                                onChange={(e) => {
                                                    setUserData((draft) => {
                                                        draft.data.name =
                                                            e.target.value
                                                    })
                                                }}
                                                className="border border-searchFilterColor rounded-sm pl-1 py-1 w-2/3 "
                                            />
                                        </div>
                                        <div className="flex w-full mt-5">
                                            <span className="text-groupLabel  font-Segoe whitespace-nowrap w-1/3">
                                                Roles
                                            </span>
                                            <Select
                                                isMulti
                                                isClearable={false}
                                                name="permissionGroup"
                                                value={userData.data.role}
                                                onChange={rolesAddition}
                                                options={rolesData.options}
                                                styles={colourStyles}
                                                placeholder="Add roles ..."
                                                className="basic-multi-select bg-white rounded-md w-full absolute focus:outline-none focus:ring-gray-200 focus:border-gray-500 sm:text-sm w-2/3"
                                            />
                                        </div>
                                        {error != '' && (
                                            <p className="text-red-400 p-2">
                                                {error}
                                            </p>
                                        )}
                                        <div className={style.btnContainer}>
                                            <Button
                                                variant="contained"
                                                onClick={editUser}
                                            >
                                                Save
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                onClick={() => {
                                                    setUserData((draft) => {
                                                        draft.isOpen = false
                                                    })
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>
            }
        </>
    )
}

export default EditUserModal

const style = {
    dialogContainer: 'fixed inset-0  overflow-y-auto bg-opacity-50 bg-gray-500',
    subContainer: 'min-h-screen px-6 text-center',
    dialogOverlay: 'fixed inset-0',
    span: 'inline-block h-screen align-middle',
    dialogTitleContainer:
        'inline-block w-full max-w-lg p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl max-h-full',
    dialogTitle: 'text-lg font-medium leading-6 text-textColor',
    closeBtn:
        'inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500',
    btnCancel:
        'py-2 px-7 rounded-sm border text-approveBtn border-activeBorderColor font-bold text-sm ml-2',
    btnRaise:
        'py-2 px-8 bg-approveBtn rounded-sm text-white text-sm font-bold ml-3',
    pTag: 'text-xs text-gray-400',
    assetName: 'text-sm text-gray-500',
    assetNm: 'text-textColor text-base mt-3',
    descriptionContainer: 'text-sm text-gray-500 mt-3',
    input: 'h-20 border-2 outline-none w-full mt-1 p-2',
    btnContainer: 'mt-16  flex gap-4 flex-row',
    select: 'basic-multi-select border-none outline-none whitespace-nowrap text-14px font-normal font-Segoe  max-w-max',
    btnApprove:
        'py-2 px-6 bg-gradient rounded-sm text-white text-sm font-bold  ',
    btnReject:
        'py-2 px-5 rounded-sm border text-primary border-gradient font-bold text-sm ml-4',
}
