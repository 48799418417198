import { useQuery, useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'

export default function useUserTableConfiguration(props: any) {
    const queryClient = useQueryClient()
    return useMutation<any, Error, object>(
        (body: any) =>
            axios.post(`${APi_URL}/user/table-configuration`, body, {
                headers: { 'Content-Type': 'application/json' },
            }),
        {
            onSuccess: (data: any, obj: any) => {
                props.onSuccess(data)
                queryClient.invalidateQueries(['tableConfiguration'])
            },
            onError: props.onError,
        }
    )
}
