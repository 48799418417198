import Loading from '@Common/Components/Loading'
import makeIcon from '@Common/utils/fabricIcons'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import IconButton from '@mui/material/IconButton'
import useGetAsset from './GetAsset'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import useDownload from '@Common/Queries/MutationQueries/getFileDownload'
import { EditorView } from 'prosemirror-view'
import { Button } from '@mui/material'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
interface ThumbnailProps {
    id: string
    src: string
    fileId: string
    view: EditorView
}

const AssetThumbnail = (props: ThumbnailProps) => {
    const view = props.view
    const asset = useGetAsset({
        id: props.id,
    })
    const {
        isSuccess,
        isError,
        isLoading: d,
        data,
    } = useDownload({ id: props.fileId })

    if (asset.isLoading || d) {
        return <Loading></Loading>
    }
    const deleteNode = () => {
        view.state.doc.descendants((node, pos) => {
            if (
                node.type.name === 'asset' &&
                node.attrs.id === props.id &&
                node.attrs.fileId === props.fileId
            ) {
                const tr = view.state.tr.deleteRange(pos, pos + node.nodeSize)
                view.dispatch(tr)
                return
            }
        })
    }
    if (asset?.status === 'error' && asset.error.response.status === 404) {
        return (
            <div className="w-96 h-12  gap-4 justify-center flex items-center bg-[#F5F5F5]">
                <DescriptionOutlinedIcon
                    fontSize="small"
                    htmlColor="#9D9C9B"
                ></DescriptionOutlinedIcon>
                <span className="text-lightTextDisabled">
                    This asset has been deleted
                </span>
                <Button variant="text" onClick={() => deleteNode()}>
                    Delete
                </Button>
            </div>
        )
    }
    const onDownloadSuccess = () => {
        if (!data) {
            return
        }
        const link = document.createElement('a')
        link.setAttribute('download', 'download')
        link.setAttribute('href', data)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()

        document.body.removeChild(link)
    }

    const expression = /\.([0-9a-z]+)(?:[\?#]|$)/
    const result = asset?.data?.LatestFile.fileName
        .toLowerCase()
        .match(expression)
    console.log('The file type is', result)
    const fetchedUrl = asset?.data?.imageUrl
    const fileType = result[1]
    return !['png', 'svg', 'jpg', 'jpeg', 'csv', 'xls', 'xlsx'].includes(
        fileType
    ) ? (
        <a
            href={`/search/${asset?.data.id}`}
            target="_blank"
            className="inline-block"
        >
            <div
                style={{ backgroundImage: 'url(' + fetchedUrl + ')' }}
                className={`w-96 h-56 flex flex-col shadow-lg cursor-pointer shadow-gray-200 w-full bg-cover justify-end`}
            >
                <div
                    className="flex items-center justify-between p-2  w-full"
                    style={{
                        background:
                            'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #141414 100%)',
                    }}
                >
                    <span className="text-white text-sm">
                        {asset?.data?.name}
                    </span>
                    <IconButton>
                        <OpenInNewIcon
                            sx={{ color: '#ffffff' }}
                        ></OpenInNewIcon>
                    </IconButton>
                </div>
            </div>
        </a>
    ) : (
        <div className="w-96 h-12">
            <div className="flex justify-between items-center border-solid border-2 border-slate-300 rounded-md px-3">
                <span {...makeIcon(asset?.data?.LatestFile.fileName)}></span>
                <span
                    className="font-medium flex items-center justify-center text-sm"
                    color={'text.primary'}
                >
                    {asset?.data?.name}
                </span>
                <IconButton
                    onClick={() => {
                        onDownloadSuccess()
                    }}
                >
                    <FileDownloadOutlinedIcon></FileDownloadOutlinedIcon>
                </IconButton>
            </div>
        </div>
    )
}

export default AssetThumbnail
