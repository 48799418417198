import useInit from '@Common/Queries/GetQueries/useInit'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'
import { Button, Chip, Link, Tooltip, Typography } from '@mui/material'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { Updater, useImmer } from 'use-immer'
import { autoTagEnabled, canEdit, formattedDate, isFullAccess } from '../util'
import DateAttribute from './DateAttribute'
import EditableComponent from './EditableComponent'
import MultiSelectAttribute from './MultiSelectAttribute'
import SingleSelectAttribute from './SingleSelectAttribute'
import TextFieldAttribute from './TextFieldAttribute'
import { Asset } from './ViewAsset'
import { Link as RouterLink } from 'react-router-dom'
import { useGenerateTags } from '@Common/Queries/ViewAsset/GenerateTags'
import useFeatureFlag from '@Common/hooks/useFeatureFlag'
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined'

interface CustomAttributeProps {
    asset: Asset
    customAttributes: {
        id: string
        type: string
        name: string
        sensitive: boolean
        option: Array<{
            id: string
            value: string
            color: string
            attributeId: string
        }>
    }[]
    isVisible: Record<string, { visible: boolean; anchor: any }>
    setIsVisible: Updater<{ [x: string]: { visible: boolean; anchor: any } }>
    handleKeyDown: any
}
const CustomAttributes = (props: CustomAttributeProps) => {
    const { asset, isVisible, setIsVisible } = props

    const openAiFlag = useFeatureFlag('open_ai')
    const init = useInit()
    const [autoTag, setAutoTag] = useImmer(
        autoTagEnabled(asset, props.customAttributes)
    )
    const tagGeneration = useGenerateTags({
        mutate: () => {
            setAutoTag((draft) => {
                draft.message =
                    'Tag generation under process. Feel free to work on other things while we are on it'
            })
        },
    })
    const userRoles = init.data.user.UserRole.map((userRole) =>
        userRole.role.role.toLowerCase()
    )
    const isAdmin =
        userRoles.includes(import.meta.env.VITE_SUPER_ADMIN_USER) ||
        userRoles.includes(import.meta.env.VITE_ADMIN_USER)
    const getTypeForCustomAttribute = (attributeId: string) => {
        const customAttributeType = customAttributes.find(
            (e) => e.id === attributeId
        )
        return customAttributeType.type
    }
    let customAttributes = props.customAttributes
    customAttributes =
        isAdmin || isFullAccess(asset.action)
            ? customAttributes
            : customAttributes.filter((e) => e.sensitive === false)

    const getCustomAttributeValueForView = (
        attributeId: string,
        attribute: {
            assetAttributeValues: { value: string }
        }
    ): string | undefined | [{ value: string; id: string }] => {
        if (!attribute) {
            return undefined
        }
        const type = getTypeForCustomAttribute(attributeId)
        switch (type) {
            case 'Date':
                return formattedDate(attribute.assetAttributeValues.value)
            case 'Text':
            case 'Number':
                return attribute.assetAttributeValues?.value
            case 'Single-select':
                if (!attribute.assetAttributeValues[0]) {
                    return undefined
                }
                return [
                    {
                        value: attribute.assetAttributeValues[0]?.attributeValue
                            ?.value,
                        id: attribute.assetAttributeValues[0]?.attributeValue
                            ?.id,
                    },
                ]
            case 'Multi-select':
            case 'Tag':
                return attribute.assetAttributeValues.map((e) => {
                    return {
                        value: e.attributeValue.value,
                        id: e.attributeValue.id,
                    }
                })
            default:
                return undefined
        }
    }
    const getCurrentValueForAsset = (
        attribute: {
            id: string
        },
        type: string
    ) => {
        if (!attribute) {
            return undefined
        }
        switch (type) {
            case 'Date':
                return attribute.assetAttributeValues
            case 'Text':
            case 'Number':
                return attribute.assetAttributeValues.value
            case 'Single-select':
                if (!attribute.assetAttributeValues[0]) {
                    return undefined
                }
                return {
                    value: {
                        ...attribute.assetAttributeValues[0]?.attributeValue,
                    },
                    assetCustomAttribute: attribute.assetAttributeValues[0],
                }
            case 'Multi-select':
            case 'Tag':
                return {
                    //value matching the options
                    value: attribute.assetAttributeValues
                        .map((e) => {
                            return e.attributeValue
                        })
                        .sort(),
                    assetCustomAttribute: attribute.assetAttributeValues,
                }
        }
    }

    const customAttributsForEdit = () => {
        const customAttributeData = [
            ...asset.AssetCustomAttribute,
            ...asset.UserInputCustomAttributes,
        ]
        const getCustomAttributeEditableComponent = (
            attribute: {
                id: string
                name: string
                type: string
                option: Array<{
                    id: string
                    value: string
                    color: string
                    attributeId: string
                }>
            },
            assetAttribute
        ) => {
            const type = getTypeForCustomAttribute(attribute.id)

            const assetValue:
                | string
                | {
                      value: Array<any>
                      assetCustomAttribute: Array<any>
                  }
                | undefined = getCurrentValueForAsset(assetAttribute, type)

            switch (type) {
                case 'Text':
                case 'Number':
                    return (
                        <TextFieldAttribute
                            value={assetValue ? assetValue : ''}
                            attribute={attribute}
                            assetId={asset.id}
                            type={type}
                            assetName={asset.name}
                            handleKeyDown={props.handleKeyDown}
                        ></TextFieldAttribute>
                    )
                case 'Date':
                    return (
                        <DateAttribute
                            value={assetValue ? assetValue.value : undefined}
                            attribute={attribute}
                            assetId={asset.id}
                            assetName={asset.name}
                            assetCustomAttribute={assetValue ? assetValue : {}}
                        ></DateAttribute>
                    )
                case 'Single-select':
                    return (
                        <SingleSelectAttribute
                            assetValue={assetValue ? assetValue.value : {}}
                            attribute={attribute}
                            assetCustomAttribute={
                                assetValue
                                    ? assetValue.assetCustomAttribute
                                    : {}
                            }
                            assetName={asset.name}
                            assetId={asset.id}
                            options={attribute.option}
                        ></SingleSelectAttribute>
                    )
                case 'Multi-select':
                case 'Tag':
                    return (
                        <MultiSelectAttribute
                            assetValue={assetValue ? assetValue.value : []}
                            assetCustomAttribute={
                                assetValue
                                    ? assetValue.assetCustomAttribute
                                    : []
                            }
                            attribute={attribute}
                            assetId={asset.id}
                            assetName={asset.name}
                            options={attribute.option}
                            isVisible={isVisible}
                        ></MultiSelectAttribute>
                    )

                default:
                    return <div key={attribute.id}>{''}</div>
            }
        }

        return (
            <div className="w-full">
                {customAttributes.length === 0 &&
                    (!isAdmin ? (
                        <Typography color="text.disabled">
                            Contact workspace admin to add custom properties
                        </Typography>
                    ) : (
                        <RouterLink to="/settings/Attributes">
                            <Link>Add asset property</Link>
                        </RouterLink>
                    ))}
                {autoTag.show && openAiFlag && (
                    <div className="flex gap-2 flex-col items-start">
                        <Button
                            disabled={!autoTag.enabled}
                            onClick={() => {
                                setAutoTag((draft) => {
                                    draft.enabled = false
                                })
                                tagGeneration.mutate(asset?.id)
                            }}
                            startIcon={
                                <AutoAwesomeOutlinedIcon></AutoAwesomeOutlinedIcon>
                            }
                        >
                            Autogenerate attributes
                        </Button>
                        {autoTag.message && (
                            <Typography color={'text.disabled'} fontSize={12}>
                                {autoTag.message}
                            </Typography>
                        )}
                    </div>
                )}
                {customAttributes.map((attribute, index: number) => {
                    const assetAttribute = customAttributeData.find(
                        (e) => e.id === attribute.id
                    )
                    const value = getCustomAttributeValueForView(
                        attribute.id,
                        assetAttribute
                    )
                    return (
                        <div key={index} className="flex w-full flex-col">
                            <div className="w-full flex">
                                <div className="w-36 h-full shrink-0">
                                    <Typography
                                        className="overflow-hidden whitespace-nowrap text-ellipsis h-full w-full text-sm font-medium py-4"
                                        color="text.disabled"
                                    >
                                        {attribute.name}
                                    </Typography>
                                </div>
                                <EditableComponent
                                    value={value}
                                    visible={isVisible}
                                    setIsVisible={setIsVisible}
                                    id={attribute.id}
                                    defaultValue={'Empty'}
                                    options={attribute.option}
                                    className={classNames(
                                        'text-sm w-full h-full',
                                        {
                                            'overflow-hidden':
                                                attribute.type !=
                                                    'Multi-select' &&
                                                attribute.type != 'Tag',
                                        }
                                    )}
                                    textClassName={
                                        'w-full whitespace-nowrap py-4 pl-3'
                                    }
                                    type={
                                        [
                                            'Multi-select',
                                            'Tag',
                                            'Single-select',
                                        ].includes(attribute.type)
                                            ? 'chip'
                                            : 'text'
                                    }
                                >
                                    {getCustomAttributeEditableComponent(
                                        attribute,
                                        assetAttribute
                                    )}
                                </EditableComponent>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }
    const customAttributsForView = () => {
        const customAttributeData: Record<string, unknown>[] = []
        if (asset.AssetCustomAttribute) {
            customAttributeData.push(...asset.AssetCustomAttribute)
        }
        if (asset.UserInputCustomAttributes) {
            customAttributeData.push(...asset.UserInputCustomAttributes)
        }

        return (
            <div className="w-full h-full">
                {customAttributes.length === 0 && (
                    <Typography color="text.disabled">
                        No attributes added
                    </Typography>
                )}
                {customAttributes.map((attribute, index) => {
                    const assetAttribute = customAttributeData.find(
                        (e) => e.id === attribute.id
                    )
                    const value = getCustomAttributeValueForView(
                        attribute.id,
                        assetAttribute
                    )
                    return (
                        <div key={index} className="flex min-h-12">
                            <div className="w-36">
                                <Typography
                                    className="py-3 overflow-hidden whitespace-nowrap text-ellipsis"
                                    color="text.disabled"
                                >
                                    {attribute.name}
                                </Typography>
                            </div>
                            <div className="flex-1">
                                {[
                                    'Single-select',
                                    'Multi-select',
                                    'Tag',
                                ].includes(attribute.type) && value ? (
                                    <div className="py-4 flex gap-1 flex-wrap pl-3">
                                        {value?.map(
                                            (
                                                eachValue: {
                                                    value: string
                                                    id: string
                                                },
                                                index: number
                                            ) => (
                                                <div
                                                    key={index}
                                                    className="pb-1"
                                                >
                                                    <Tooltip
                                                        title={eachValue.value}
                                                    >
                                                        <Chip
                                                            label={
                                                                eachValue.value
                                                            }
                                                            sx={{
                                                                backgroundColor:
                                                                    attribute.option?.find(
                                                                        (e) =>
                                                                            e.id ===
                                                                            eachValue.id
                                                                    )?.color ||
                                                                    '#E9E9E9',
                                                            }}
                                                            size="small"
                                                        ></Chip>
                                                    </Tooltip>
                                                </div>
                                            )
                                        )}
                                    </div>
                                ) : (
                                    <Typography
                                        className="py-3 px-3"
                                        color={
                                            value
                                                ? 'text.primary'
                                                : 'text.disabled'
                                        }
                                    >
                                        {value ? value : 'Empty'}
                                    </Typography>
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <>
            {canEdit(asset.action)
                ? customAttributsForEdit()
                : customAttributsForView()}
        </>
    )
}

export default CustomAttributes
