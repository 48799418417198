import AddAssest, { Asset } from '@AddAssets/index'
import PrimaryButton from '@Common/Components/Button/PrimaryButton'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { TransitionProps } from '@mui/material/transitions'
import SearchScreen from '@Search/components/SearchScreen'
import { forwardRef, Fragment } from 'react'
import { Updater, useImmer } from 'use-immer'
import Dialog from '@mui/material/Dialog'
import Fade from '@mui/material/Fade'
import { twMerge } from 'tailwind-merge/dist/lib/tailwind-merge'
import SearchModal from './SearchModal'

interface GobalModalProps {
    setSuggestedAssets: Updater<Asset[]>
    modalName: string
    setIsOpen: Updater<{
        isOpen: boolean
        type: string
    }>
}
const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
) {
    return <Fade ref={ref} {...props} />
})

export default function GlobalModal(props: GobalModalProps) {
    const [selectedAsset, setSelectedAsset] = useImmer([] as Asset[])

    const addSearchedAssset = (e: any, asset: Asset) => {
        if (e.target.checked) {
            setSelectedAsset((draft) => {
                draft.push(asset)
            })
        } else {
            setSelectedAsset((draft) => {
                const assetIndex = draft.findIndex(
                    (item) => item.id === asset.id
                )
                draft.splice(assetIndex, 1)
            })
        }
    }
    return (
        <>
            <Dialog
                open={true}
                TransitionComponent={Transition}
                onClose={() => {
                    props.setIsOpen((draft) => {
                        draft.isOpen = false
                    })
                }}
                fullWidth
                maxWidth={'xl'}
                scroll={'paper'}
            >
                <DialogTitle>
                    <div className="flex">
                        <header className="bg-white py-2 leading-5 px-3   cursor-default">
                            <div className=" mx-auto py-2 px-2 ">
                                <h1 className="  font-semibold font-Segoe text-18px">
                                    {props.modalName === 'add' && 'Add Assets'}
                                    {props.modalName === 'search' &&
                                        'Search And Attach'}
                                </h1>
                                <div className="w-10 h-1 bg-blue-600"></div>
                            </div>
                        </header>
                        <div className="justify-self-end cursor-pointer p-3">
                            <span
                                className="font-bold "
                                onClick={() => {
                                    props.setIsOpen((draft) => {
                                        draft.isOpen = false
                                    })
                                }}
                            >
                                X
                            </span>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    {props.modalName === 'add' && (
                        <div className="h-inherit pb-10">
                            <AddAssest
                                setSuggestedAssets={(
                                    suggestedAssets: Asset[]
                                ) => {
                                    props.setSuggestedAssets((draft) => {
                                        suggestedAssets.forEach(
                                            (suggestedAsset) => {
                                                const index = draft.findIndex(
                                                    (asset) =>
                                                        asset.id ===
                                                        suggestedAsset.id
                                                )
                                                if (index < 0) {
                                                    draft.push(suggestedAsset)
                                                }
                                            }
                                        )
                                    })
                                    props.setIsOpen((draft) => {
                                        draft.isOpen = false
                                    })
                                }}
                                isSearch={true}
                            />
                        </div>
                    )}
                    {props.modalName === 'search' && (
                        <div className="h-full flex flex-col min-h-0 ">
                            <div className="flex-grow">
                                <SearchScreen
                                    showOptions={false}
                                    isModal={true}
                                    addSearchedAssset={addSearchedAssset}
                                    isCardView={false}
                                />
                            </div>
                            <div className="w-full flex p-2 justify-end items-center">
                                <PrimaryButton
                                    onClick={(e) => {
                                        props.setSuggestedAssets((draft) => {
                                            selectedAsset.forEach(
                                                (suggestedAsset) => {
                                                    const index =
                                                        draft.findIndex(
                                                            (asset) =>
                                                                asset.id ===
                                                                suggestedAsset.id
                                                        )
                                                    if (index < 0) {
                                                        draft.push(
                                                            suggestedAsset
                                                        )
                                                    }
                                                }
                                            )
                                        })
                                        props.setIsOpen((draft) => {
                                            draft.isOpen = false
                                        })
                                    }}
                                >
                                    Attach Selected
                                </PrimaryButton>
                            </div>
                        </div>
                    )}
                </DialogContent>
            </Dialog>
        </>
    )
}

const style = {
    dialogContainer: '',
    subContainer: '',
    dialogOverlay: '',
    span: '',
    dialogTitleContainer: '',
    dialogTitle: '',
    closeBtn:
        'inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500',
    btnCancel:
        'py-2 px-7 rounded-sm border text-approveBtn border-activeBorderColor font-bold text-sm ml-2',
    btnRaise:
        'py-2 px-8 bg-approveBtn rounded-sm text-white text-sm font-bold ml-3',
    pTag: 'text-xs text-gray-400',
    assetName: 'text-sm text-gray-500',
    assetNm: 'text-textColor text-base mt-3',
    descriptionContainer: 'text-sm text-gray-500 mt-3',
    input: 'h-20 border-2 outline-none w-full mt-1',
    btnContainer: 'mt-6 flex flex-row',

    containers: 'w-full px-10 mt-10 outline-none',
    imgContainer: 'flex flex-row border-2 border-gray-300 px-5 rounded-lg py-3',
    inputs: 'justify-center  w-full px-4 outline-none text-base placeholder:text-gray-900',
    headerContainer:
        'flex flex-row mt-3 border-b border-b-gray-500 justify-between w-full',
    subContainers: 'ml-10 flex flex-row',
    tab: 'py-2.5 text-base leading-5 mx-4',
    selectedTab:
        'text-gray-800 font-semibold border-b-4  border-activeBorderColor',
    unSelectedTab: 'text-gray-400 font-medium border-b-4 border-transparent',
    totalSearch: 'text-gray-500 pl-12 pt-3 pb-2 text-sm ',
    panel: 'justify-center overflow-hidden',
    btnContainers: 'flex-1 w-full text-right',
    btn: 'pr-12 pt-2 text-gray-400 focus:text-textColor  ',
    filterDiv: 'flex flex-row pl-9',
    groupDiv: 'flex flex-row w-full',
}
