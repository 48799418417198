import Loading from '@Common/Components/Loading'
import useUpdateTrackLink from '@Common/Queries/MutationQueries/UpdateTrackLink'
import Button from '@mui/material/Button'
import { toast } from 'react-hot-toast'
import { Updater, useImmer } from 'use-immer'
import TrackProperties, { TrackLinkProperties } from './TrackProperties'
import useFeatureFlag from '@Common/hooks/useFeatureFlag'

interface TrackUpdateProps {
    assetId: string
    trackData: {
        id: string
        code: string
        enabled: boolean
        TrackLinkProperties: any
        AllowAccessToTrack: Array<any>
        TrackLinkContact: Array<any>
        RestrictAccessToTrack: Array<any>
    }
    state: {
        trackLinkProps: TrackLinkProperties
        setTrackLinkProps: Updater<TrackLinkProperties>
        showAdvancedControls: boolean
        setShowAdvancedControls: Updater<boolean>
    }
}
export const emailRegex = /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/

const TrackUpdate = (props: TrackUpdateProps) => {
    const { trackData } = props
    const {
        trackLinkProps,
        setTrackLinkProps,
        showAdvancedControls,
        setShowAdvancedControls,
    } = props.state

    const [loading, setLoading] = useImmer(false)
    const contacts = useFeatureFlag('contacts')
    const updateTrackLink = useUpdateTrackLink({
        onSuccess: () => {
            setLoading(false)
            toast.success('Track link updated successfully')
        },
        onError: () => {
            setLoading(true)
            toast.error('Track link could not be updated')
        },
    })
    const createOrUpdateTrackLink = () => {
        if (trackLinkProps.name.length === 0) {
            toast.error('Name cannot be empty')
        } else if (
            trackLinkProps.expires === true &&
            trackLinkProps.validTill === null
        ) {
            // this will make the text field in red which the user will know is error
            setTrackLinkProps((draft) => {
                draft.validTill = ''
            })
        } else if (
            trackLinkProps.passcodeRequired &&
            (!trackLinkProps.passcode ||
                trackLinkProps.passcode.trim().length <= 0)
        ) {
            toast.error('Passcode cannot be empty')
        } else if (
            trackLinkProps.accessAllow &&
            trackLinkProps.allowEmail.length <= 0
        ) {
            toast.error('Allow email cannot be empty')
        } else if (
            trackLinkProps.accessBlock &&
            trackLinkProps.blockEmail.length <= 0
        ) {
            toast.error('Block email cannot be empty')
        } else if (
            contacts &&
            (!trackLinkProps.contacts || trackLinkProps.contacts.length === 0)
        ) {
            toast.error('Contact is required')
        } else {
            const trackLinkProperties = { ...trackLinkProps }
            delete trackLinkProperties?.accessAllow
            delete trackLinkProperties?.accessBlock

            setLoading(true)
            updateTrackLink.mutate({
                trackId: trackData?.id,
                body: {
                    id: props.assetId,
                    enabled: trackData?.enabled,
                    type: 'ASSET',
                    trackLinkProperties: trackLinkProperties,
                },
            })
        }
    }

    return (
        <>
            <div className="h-full w-full flex flex-col justify-between">
                <TrackProperties
                    trackLinkProps={{
                        trackLinkProps: trackLinkProps,
                        setTrackLinkProps: setTrackLinkProps,
                        showAdvancedControls: showAdvancedControls,
                        setShowAdvancedControls: setShowAdvancedControls,
                    }}
                ></TrackProperties>
                <div className="gap-4 flex justify-end w-full p-2 border-t-2 border-gray-50">
                    <Button
                        variant="contained"
                        className="bg-primaryButton"
                        onClick={() => createOrUpdateTrackLink()}
                        disabled={loading}
                    >
                        <div className="pr-2 pt-1">
                            {loading && <Loading size="1rem" />}
                        </div>
                        Save controls
                    </Button>
                </div>
            </div>
        </>
    )
}
export default TrackUpdate
