import { useQuery } from "react-query";
import axios from "axios";
import { APi_URL } from "@Common/Types/configs/urlConfig";


const getThirdParty = async () => {
  const { data } = await axios.get(
    `${APi_URL}/webhooks`,
    { headers: { 'Content-Type': 'application/json' } }
  )
  return data;
};

export default function useThirdParty() {
  return useQuery("thirdPartyData", getThirdParty);
}
