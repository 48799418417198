import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import classNames from 'classnames'
import { AssetStatus } from './AssetUploadInput'
import { Updater } from 'use-immer'
import { Asset } from './AssetUploadInput'
import { useNavigate } from 'react-router-dom'

interface AssetsListProps {
    assetFilesList: Asset[]
    onRemoveFile: (id: string) => void
    fileDetails: string
    setViewAssetOpen: Updater<boolean>
    setAsset: Updater<Record<string, unknown>>
}

const AssetUploadLoading = (props: AssetsListProps) => {
    const { setViewAssetOpen, setAsset } = props
    const navigate = useNavigate()
    const getFileUploadIcon = (status: AssetStatus) => {
        let icon
        switch (status) {
            case AssetStatus.completed: {
                icon = <FileUploadOutlinedIcon color="success"></FileUploadOutlinedIcon>
                break
            }
            case AssetStatus.error: {
                icon = <FileUploadOutlinedIcon color="error"></FileUploadOutlinedIcon>
                break
            }
            default: {
                icon = <FileUploadOutlinedIcon color="primary"></FileUploadOutlinedIcon>
                break
            }
        }
        return icon
    }
    const getDoneIcon = (status: AssetStatus, id: string) => {
        if (status === AssetStatus.completed) {
            return <CheckIcon color="disabled"></CheckIcon>
        } else {
            return (
                <CloseIcon
                    color="disabled"
                    onClick={() => props.onRemoveFile(id)}
                ></CloseIcon>
            )
        }
    }
    const getStatus = (status: AssetStatus) => {
        if (status === AssetStatus.completed) {
            return 'Complete'
        } else if (status === AssetStatus.error) {
            return 'Failed'
        } else {
            return 'Loading'
        }
    }
    const getFileSize = (size: any, status: AssetStatus, error: string) => {
        if (status === AssetStatus.error) {
            return error
        }
        if (size === 0) {
            return '0 Bytes'
        }
        const k = 1024
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
        const i = Math.floor(Math.log(size) / Math.log(k))
        return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
    }

    return (
        <div className="w-full h-full pt-8">
            {props.assetFilesList.map((assetFile: Asset) => {
                const { file, status, progress, id, error, assetDetails } =
                    assetFile
                return (
                    <div
                        key={id}
                        className={classNames('bg-white', {
                            'cursor-pointer': status === AssetStatus.completed,
                        })}
                        onClick={(e) => {
                            if (status != AssetStatus.completed)
                                e.preventDefault()
                            else {
                                setAsset((draft) => {
                                    draft['assetId'] = assetDetails.id
                                    draft['fileId'] = assetDetails.fileId
                                    draft['data'] = assetDetails
                                })
                                setViewAssetOpen(true)
                            }
                        }}
                    >
                        <div className="flex hover:bg-hoverBackground">
                            <Avatar
                                className="bg-avatarBackground m-4"
                                sx={{
                                    height: 40,
                                    width: 40,
                                }}
                            >
                                {getFileUploadIcon(status)}
                            </Avatar>
                            <div className="w-full flex justify-between mt-4">
                                <div className="flex flex-col w-full">
                                    <Typography
                                        className={classNames(
                                            'text-primaryTextLight font-Roboto',
                                            {
                                                'text-errorMain':
                                                    status ===
                                                    AssetStatus.error,
                                            }
                                        )}
                                    >
                                        {status !== AssetStatus.error
                                            ? file.name
                                            : 'Upload failed'}
                                    </Typography>
                                    <div className="flex">
                                        <Typography
                                            className={classNames(
                                                'text-secondaryTextLight text-sm font-Roboto mr-1',
                                                {
                                                    'text-errorMain':
                                                        status ===
                                                        AssetStatus.error,
                                                }
                                            )}
                                        >
                                            {getFileSize(
                                                file.size,
                                                status,
                                                error
                                            )}
                                        </Typography>
                                        <Typography className="text-secondaryTextLight text-sm font-Roboto mr-1">
                                            {'.'}
                                        </Typography>
                                        <Typography className="text-secondaryTextLight text-sm font-Roboto">
                                            {getStatus(status)}
                                        </Typography>
                                    </div>

                                    <LinearProgress
                                        variant="determinate"
                                        value={progress || 0}
                                        color={
                                            status === AssetStatus.error
                                                ? 'error'
                                                : 'primary'
                                        }
                                        className={classNames('h-1 mt-4', {
                                            hidden:
                                                status ===
                                                AssetStatus.completed,
                                        })}
                                    />
                                </div>

                                {status === AssetStatus.completed ? (
                                    <IconButton className="h-12 w-12">
                                        <CheckIcon color="disabled"></CheckIcon>
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        className="h-12 w-12"
                                        onClick={() => props.onRemoveFile(id)}
                                    >
                                        <CloseIcon color="disabled"></CloseIcon>
                                    </IconButton>
                                )}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
export default AssetUploadLoading
