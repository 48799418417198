import { useQuery } from 'react-query'
import axios from 'axios'
import { API_URL } from '@Common/Types/configs/urlConfig'

const getRequestById = async (requestId: string) => {
    const { data } = await axios.get(`${API_URL}/requests/${requestId}`)
    return data
}

export default function useRequest(requestId: string) {
    return useQuery(['requests', requestId], () => getRequestById(requestId))
}
