import { clearUserData } from '@Common/Components/Feature/useFeatures'
import { APi_URL } from '@Common/Types/configs/urlConfig'
import { UpdateWorkspace } from '@Server/updateWorkspace'
import axios from 'axios'
import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'
export default function useLeaveWorkspace() {
    return useMutation<any, Error, UpdateWorkspace>(
        () => axios.post(`${APi_URL}/workspace/leaveWorkspace`),
        {
            onError: (error) => {
                if (error.response.status === 422) {
                    toast.error('To leave this workspace, assign an admin.')
                }
            },
            onSuccess: () => {
                clearUserData()
                window.location.href = '/'
            },
        }
    )
}
