import { Box, Paper, TablePagination, Typography } from '@mui/material'
import { formatDistanceToNow } from 'date-fns'
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom'
import { formattedDate } from '@Request/components/common/Functions'
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined'
import { useImmer } from 'use-immer'
import { useTotalDealroomVisits } from '@Common/Queries/Dealroom/DealRoomVisits'
import Loading from '@Common/Components/Loading'
import { getOptions } from '@Common/Components/Select/Accounts'
import { useAccounts } from 'src/Accounts/useAccounts'

export const DealRoomVisitsCard = () => {
    const [page, setPage] = useImmer(0)
    const pageSize = 10
    const accounts = useAccounts({ pageSize: 10, page: 0, search: '' })

    const dealroomVisitsData = useTotalDealroomVisits(page, pageSize)
    if (dealroomVisitsData.isLoading || accounts.isLoading) {
        return <Loading />
    }
    const accountOptions = getOptions(accounts)
    function getAccount(accountId: string) {
        if (accountId != null) {
            const accountName = accountOptions.find((event) => {
                return event.value === accountId
            })
            if (accountName !== undefined) {
                return accountName.label
            }
        }
        return '--'
    }

    let dealroomVisits = dealroomVisitsData.data.dealRooms.map((value) => {
        return {
            dealroomName: value.DealRoom.DealRoomProperties.name,
            accountId: value.DealRoom.DealRoomProperties.accountId,
            visitor: value.DealRoomSession.email,
            updatedAt: value.DealRoomSession.updatedAt,
        }
    })
    return (
        <div className="flex flex-col w-full h-full overflow-hidden pt-2">
            {dealroomVisitsData.data.count !== 0 ? (
                <div className="flex flex-col flex-1 w-full overflow-auto">
                    {dealroomVisits.map((data) => {
                        return (
                            <Box
                                sx={{
                                    '& > :not(style)': {
                                        m: 1,
                                        width: '95%',
                                        padding: '5px',
                                        border: '1px solid #F3F4F6',
                                    },
                                }}
                                className="flex flex-col w-full"
                            >
                                <Paper
                                    elevation={0}
                                    sx={{
                                        borderRadius: 2,
                                        boxShadow:
                                            '0 1.6px 3.6px 0 rgba(0,0,0,.132)',
                                    }}
                                >
                                    <div className="flex flex-col p-3 gap-y-1">
                                        <Typography variant="body2">
                                            {`${formatDistanceToNow(
                                                new Date(data.updatedAt),
                                                {
                                                    addSuffix: true,
                                                }
                                            )}, 
                                                ${formattedDate(
                                                    data.updatedAt
                                                )}`}
                                        </Typography>
                                        <Typography className="flex flex-row gap-x-2">
                                            <Typography>
                                                {data.visitor}
                                            </Typography>
                                            <Typography variant="body2">
                                                visited
                                            </Typography>
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            className="flex flex-row gap-x-2"
                                        >
                                            <MeetingRoomOutlinedIcon fontSize="small"></MeetingRoomOutlinedIcon>
                                            <Typography>
                                                {data.dealroomName}
                                            </Typography>
                                        </Typography>
                                        {data.accountName && (
                                            <Typography
                                                variant="body2"
                                                className="flex flex-row w-28 truncate"
                                            >
                                                {getAccount(data.accountId)}
                                            </Typography>
                                        )}
                                    </div>
                                </Paper>
                            </Box>
                        )
                    })}
                </div>
            ) : (
                <div className="flex-1 w-full h-full">
                    <Typography
                        variant="body2"
                        className="flex h-full justify-center text-center items-center"
                    >
                        No visits found
                    </Typography>
                </div>
            )}

            <TablePagination
                sx={{
                    height: '50px',
                }}
                rowsPerPageOptions={[pageSize]}
                colSpan={3}
                component="div"
                className="text-xs text-lightTextDisabled border-none overflow-hidden"
                count={dealroomVisitsData?.data?.count}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={(e, newPage) => setPage(newPage)}
                backIconButtonProps={{
                    className: 'stroke-slate-50',
                }}
                nextIconButtonProps={{
                    className: 'stroke-slate-50',
                }}
            />
        </div>
    )
}
