interface ButtonProps {
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    children: React.ReactNode;
}
export default function OutlineSecondaryButton(props: ButtonProps) {

    return <button
        type="button"
        onClick={props.onClick}
        className="flex items-center justify-center px-6 h-9  font-bold rounded hover:bg-gray-200 "
    >
        {props.children}
    </button>
}