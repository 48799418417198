import useAddCustomAttributeValuesToAsset, {
    AddCustomAttributeToAsset,
} from '@Common/Queries/MutationQueries/AddCustomAttributeToAsset'
import { FC } from 'react'
import { useImmer } from 'use-immer'
import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import ClearIcon from '@mui/icons-material/Clear'
import InputAdornment from '@mui/material/InputAdornment'
import { IconButton } from '@mui/material'
import useRemoveCustomAttFromAsset from '@Common/Queries/MutationQueries/RemoveCustomAttributeFromAsset'
import { formatDateFromCalendar } from '../util'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'

const DateAttribute: FC<{
    value: string
    attribute: {
        id: string
        name: string
        type: string
    }
    assetName: string
    assetCustomAttribute: {
        id: string
    }
    assetId: string
}> = ({ value, attribute, assetId, assetCustomAttribute, assetName }) => {
    const addCustomAttributeUpdaterFunction = (
        previousAsset: {
            AssetCustomAttribute: Array<{
                id: string
                name: string
                assetAttributeValues: Array<Record<string, unknown>>
            }>
            UserInputCustomAttributes: Array<{
                id: string
                name: string
                assetAttributeValues: Record<string, unknown>
            }>
        },
        currentValue: AddCustomAttributeToAsset
    ) => {
        let userCustomAttribute: Array<{
            assetAttributeValues: {
                assetId: string
                attributeId: string
                createdAt: Date
                value: string | undefined
            }
        }> = previousAsset.UserInputCustomAttributes
        const index = userCustomAttribute.findIndex(
            (e) => e.id === currentValue.attribute.id
        )
        let values: {
            assetId: string
            attributeId: string
            createdAt: Date
            value: string | undefined
        } = {
            assetId: currentValue.assetId,
            attributeId: currentValue.attribute.id,
            value: formatDateFromCalendar(currentValue?.value),
            createdAt: new Date(),
        }
        if (index != -1) {
            values = {
                ...userCustomAttribute[index].assetAttributeValues,
                value: formatDateFromCalendar(currentValue?.value),
            }
            userCustomAttribute[index] = {
                ...userCustomAttribute[index],
                assetAttributeValues: values,
            }
        } else {
            userCustomAttribute = [
                ...userCustomAttribute,
                { ...currentValue.attribute, assetAttributeValues: values },
            ]
        }
        const finalModifiedAsset = previousAsset

        finalModifiedAsset.UserInputCustomAttributes = userCustomAttribute

        return finalModifiedAsset
    }
    const removeCustomAttributeUpdaterFunction = (
        previousAsset: {
            AssetCustomAttribute: Array<{
                id: string
                name: string
                assetAttributeValues: Array<Record<string, unknown>>
            }>
            UserInputCustomAttributes: Array<{
                id: string
                name: string
                assetAttributeValues: Record<string, unknown>
            }>
        },
        currentValue: AddCustomAttributeToAsset
    ) => {
        const userCustomAttribute: Array<{
            assetAttributeValues: {
                assetId: string
                attributeId: string
                createdAt: Date
                value: string | undefined
            }
        }> = Object.assign([], previousAsset.UserInputCustomAttributes)
        const index = userCustomAttribute.findIndex(
            (e) => e.id === currentValue.attribute.id
        )
        if (index != -1) {
            userCustomAttribute.splice(index, 1)
        }
        return userCustomAttribute
    }
    const sendDataToGTM = useGTMDispatch()

    const addCustomAttributeValuesToAsset = useAddCustomAttributeValuesToAsset({
        addCustomAttributeUpdaterFunction: addCustomAttributeUpdaterFunction,
        onSuccess: () => {
            sendDataToGTM({
                event: 'asset_edit',
                asset_id: assetId,
                asset_name: assetName,
                attribute_name: attribute.name,
            })
        },
    })
    const removeCustomAttributeFromAsset = useRemoveCustomAttFromAsset({
        removeCustomAttributeUpdaterFunction:
            removeCustomAttributeUpdaterFunction,
        onSuccess: () => {
            sendDataToGTM({
                event: 'asset_edit',
                asset_id: assetId,
                asset_name: assetName,
                attribute_name: attribute.name,
            })
        },
    })
    const [dateAttr, setDateAttr] = useImmer({
        attributeId: attribute.id,
        value: value || null,
    } as Record<string, unknown>)
    const handleDateChange = (newDate: string) => {
        setDateAttr((draft) => {
            draft.value = newDate ? newDate : null
        })
        if (newDate)
            addCustomAttributeValuesToAsset.mutate({
                assetId: assetId,
                attribute: attribute,
                value: newDate,
            })
        else
            removeCustomAttributeFromAsset.mutate({
                assetId: assetId,
                attribute: attribute,
                id: assetCustomAttribute.id,
            })
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                value={dateAttr.value}
                onChange={(newValue) => {
                    handleDateChange(newValue)
                }}
                open={true}
                closeOnSelect={true}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="filled"
                        size="small"
                        className="w-full text-sm"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={(e) => {
                                            handleDateChange(e.target.value)
                                        }}
                                    >
                                        <ClearIcon fontSize="small"></ClearIcon>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
                components={{
                    OpenPickerIcon: 'null' as undefined,
                }}
            ></DatePicker>
        </LocalizationProvider>
    )
}
export default DateAttribute
