import React, { useRef } from 'react'
import { UploadIcon } from '@heroicons/react/outline'

const AssetsDropZone = (props: any) => {
    const fileInputRef: any = useRef()
    const preventDefault = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const dragOver = (e: any) => {
        preventDefault(e)
    }

    const dragEnter = (e: any) => {
        preventDefault(e)
    }

    const dragLeave = (e: any) => {
        preventDefault(e)
    }

    const fileDrop = (e: any) => {
        preventDefault(e)
        const files = e.dataTransfer.files
        if (files.length) {
            props.onFiles([...files])
        }
    }

    const filesSelected = () => {
        if (fileInputRef.current.files.length) {
            props.onFiles([...fileInputRef.current.files])
        }
    }


    return (
        <>
            <div
                className={`flex justify-center pt-2 pb-2 rounded-b-3px shadow-lg ${props.bodyFull ? 'h-full bg-white' : ' bg-yellow-100'
                    }`}
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}
            >
                <div className=" m-auto space-y-1 text-center">
                    <UploadIcon
                        className={`mx-auto h-12 w-12 text-gray-400 ${props.bodyFull ? 'show' : 'hidden'
                            }`}
                    />
                    <div className="flex text-sm text-gray-600">
                        <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                        >
                            <span>
                                <strong>Upload a file</strong>
                            </span>
                            <input
                                id="file-upload"
                                name="file-upload"
                                type="file"
                                className="sr-only"
                                ref={fileInputRef}
                                multiple
                                onChange={filesSelected}
                            />
                        </label>
                        <p className="pl-1">or drag and drop it here</p>
                    </div>
                    {/* <p className="text-xs text-gray-500">File up to 10MB</p> */}
                </div>
            </div>
        </>
    )
}

export default AssetsDropZone
