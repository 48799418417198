import { iconsByExtension } from '@Common/Types/configs/fabricIconConfig'
const makeIcon = (name: any) => {
    const ix = name.lastIndexOf('.')
    if (ix >= 0) {
        const extension = name.slice(ix + 1)
        if (
            extension &&
            iconsByExtension.hasOwnProperty(extension.toLowerCase())
        ) {
            return iconsByExtension[extension.toLowerCase()]
        }
    }
    return iconsByExtension['default']
}

export default makeIcon
