import { APi_URL } from '@Common/Types/configs/urlConfig'
import { UpdateUserName } from '@Server/updateUserName'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'

export default function useUpdateName(props?: any) {
    const queryClient = useQueryClient()
    return useMutation<any, any, UpdateUserName>(
        (obj) => axios.post(`${APi_URL}/updateName`, obj),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['init'])
            },
        }
    )
}
