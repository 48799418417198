import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useQuery } from 'react-query'

export const useRecentVisit = (assetId: string | undefined) => {
    if (!assetId) {
        return
    }
    return useQuery(['visits', assetId], async () => {
        const result = await axios.get(
            `${API_URL}/trackLink/asset/recentVisit/${assetId}`
        )
        return result.data
    })
}
