import { useQuery } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'
import { UserIcon } from '@heroicons/react/outline'

const getTableConfiguration = async () => {
    const { data } = await axios.get(`${APi_URL}/table-configuration`, {
        headers: { 'Content-Type': 'application/json' },
    })
    return data
}

export default function useGetTableConfiguration() {
    return useQuery(['tableConfiguration'], () => getTableConfiguration())
}
