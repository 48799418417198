import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useQuery } from 'react-query'

export function useContacts(options: any) {
    return useQuery(
        ['contacts', options],
        async () => {
            const response = await axios.post(
                `${API_URL}/contacts/search`,
                options
            )
            return response.data
        },
        {
            keepPreviousData: true,
        }
    )
}
