import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import SearchIcon from '@mui/icons-material/Search'
import { Updater } from 'use-immer'
import { DataGridPro as DataGrid, GridColDef } from '@mui/x-data-grid-pro'
import Typography from '@mui/material/Typography'
import Avatar from '@Common/Components/Avatar'
import LanguageIcon from '@mui/icons-material/Language'
import { formattedDate } from '../ViewAssetScreen/util'
import Chip from '@mui/material/Chip'
import { TablePagination } from '@mui/material'
import { useNavigate } from 'react-router-dom'
const SitesTable = (props: {
    filter: {
        searchTerm: string
        currentPage: number
        sort: string
        tenantId: string
    }
    setFilter: Updater<{
        searchTerm: string
        currentPage: number
        sort: string
        tenantId: string
    }>
    sites: {
        name: string
        iconUrl: string
        description: string
    }[]
}) => {
    const { filter, setFilter, sites } = props
    const navigate = useNavigate()
    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Site name',
            minWidth: 320,
            flex: 1,

            renderCell: (cellValue) => {
                return (
                    <div className="w-full  flex items-center">
                        <Avatar>
                            {cellValue.row.imageUrl || (
                                <LanguageIcon></LanguageIcon>
                            )}
                        </Avatar>
                        <Typography className="pl-2" color={'text.primary'}>
                            {cellValue.value}
                        </Typography>
                    </div>
                )
            },
        },
        // {
        //     field: 'totalEngagement',
        //     headerName: 'Total Engagement',
        //     width: 270,
        //     renderCell: (cellValue) => {
        //         return (
        //             <Typography className="font-light" color={'text.primary'}>
        //                 {cellValue.value}
        //             </Typography>
        //         )
        //     },
        //     valueFormatter: (params) => {
        //         const value = params.value / 60000
        //         return `${Math.round(value * 100) / 100} min`
        //     },
        // },
        // {
        //     field: 'lastEngagement',
        //     headerName: 'Last Engagement',
        //     width: 270,
        //     renderCell: (cellValue) => {
        //         return cellValue.value ? (
        //             <Chip
        //                 sx={{
        //                     backgroundColor: '#E8F5E9',
        //                 }}
        //             >
        //                 {cellValue.value}
        //             </Chip>
        //         ) : (
        //             <span>-</span>
        //         )
        //     },
        // },
        {
            field: 'lastEdited',
            headerName: 'Last edited',
            width: 500,

            renderCell: (cellValue) => {
                return (
                    <Typography className="font-light" color={'text.primary'}>
                        {cellValue.formattedValue}
                    </Typography>
                )
            },
            valueFormatter: (params) => {
                const value = params.value
                return formattedDate(value)
            },
        },
    ]
    return (
        <div className="flex flex-col w-full h-full">
            <div className="flex justify-between pb-6">
                <TextField
                    className="w-96"
                    size="small"
                    defaultValue={filter.searchTerm}
                    onChange={(e) =>
                        setFilter((draft) => {
                            draft.searchTerm = e.target.value.trim()
                            draft.currentPage = 0
                        })
                    }
                    placeholder="Search"
                    InputProps={{
                        className: 'p-0',
                        autoComplete: 'off',

                        startAdornment: (
                            <InputAdornment position="start" className="p-3.5">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                ></TextField>
                {/* <Select
                    className="w-56"
                    defaultValue={filter.sort}
                    onChange={(e) =>
                        setFilter((draft) => {
                            draft.sort = e.target.value.trim()
                            draft.currentPage = 0
                        })
                    }
                    size="small"
                >
                    <MenuItem value={'lastEdited'} defaultChecked>
                        Last Edited
                    </MenuItem>
                    <MenuItem value={'lastEngagement'}>
                        Last Engagement
                    </MenuItem>
                </Select> */}
            </div>
            <DataGrid
                sx={{
                    border: 'none',
                    flex: 1,
                }}
                columns={columns}
                rows={sites}
                pageSize={10}
                hideFooter={true}
                getRowClassName={(params) => `cursor-pointer`}
                rowsPerPageOptions={[10]}
                onRowClick={(params) => {
                    const row = params.row
                    navigate(`${row.id}`)
                }}
            ></DataGrid>
            <TablePagination
                rowsPerPageOptions={[10]}
                colSpan={3}
                component="div"
                className="text-xs text-lightTextDisabled border-none overflow-hidden"
                count={sites.length}
                rowsPerPage={10}
                page={filter.currentPage}
                onPageChange={(e, page) =>
                    setFilter((draft) => {
                        draft.currentPage = page
                    })
                }
                backIconButtonProps={{
                    className: 'stroke-slate-50',
                }}
                nextIconButtonProps={{
                    className: 'stroke-slate-50',
                }}
            ></TablePagination>
        </div>
    )
}
export default SitesTable
