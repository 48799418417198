import Loading from '@Common/Components/Loading'
import { TabPanel } from '@Common/Components/TabPanel'
import { useTrackDetailsById } from '@Common/Queries/Track/GetTrackDetails'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import IconButton from '@mui/material/IconButton'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { useNavigate, useParams } from 'react-router-dom'
import { Updater, useImmer } from 'use-immer'
import { PageLevelDetails } from './Components/PageLevelDetails'
import TrackUpdate from './Components/TrackUpdate'
import { TrackLinkProperties } from './Components/TrackProperties'
import { useEffect } from 'react'

export const TrackLevelDetails = (props: { setOpen: Updater<boolean> }) => {
    const { id, trackId } = useParams()
    if (!trackId || !id) {
        return <div></div>
    }
    const [tab, setTab] = useImmer(0)
    const navigate = useNavigate()
    const [trackLinkProps, setTrackLinkProps] = useImmer<TrackLinkProperties>({
        name: '',
        contacts: [],
        expires: false,
        validTill: null,
        allowDownload: false,
        emailRequired: false,
        passcodeRequired: false,
        passcode: '',
        emailAuthRequired: false,
        restrictAccess: false,
        accessAllow: false,
        accessBlock: false,
        allowEmail: [],
        blockEmail: [],
    })
    const [showAdvancedControls, setShowAdvancedControls] = useImmer(false)

    const trackData = useTrackDetailsById({
        id: trackId,
        onSuccess: (data) => {
            const trackProperties = data?.TrackLinkProperties
            const allowEmail = data?.AllowAccessToTrack.map((e) => e.value)
            const restrictEmail = data?.RestrictAccessToTrack.map(
                (e) => e.value
            )
            setTrackLinkProps((draft) => {
                return {
                    name: trackProperties.name,
                    passcode: trackProperties.passcode,
                    contacts: data?.TrackLinkContact.map((contact) => ({
                        value: contact.contactId,
                        label: contact.email,
                        status: contact.status,
                    })),
                    passcodeRequired: trackProperties.passcodeRequired,
                    emailRequired: trackProperties.emailRequired,
                    emailAuthRequired: trackProperties.emailAuthRequired,
                    expires: !!trackProperties.validTill,
                    validTill: trackProperties.validTill,
                    restrictAccess:
                        allowEmail.length > 0 || restrictEmail.length > 0,
                    allowDownload: trackProperties.allowDownload,
                    accessAllow: allowEmail.length > 0,
                    accessBlock: restrictEmail.length > 0,
                    allowEmail: allowEmail,
                    blockEmail: restrictEmail,
                }
            })
            setShowAdvancedControls(
                trackProperties.emailAuthRequired ||
                    trackProperties.emailRequired ||
                    trackProperties.passcodeRequired ||
                    allowEmail.length > 0 ||
                    restrictEmail.length > 0
            )
        },
    })
    useEffect(() => {
        if (trackData.data) {
            const trackProperties = trackData.data?.TrackLinkProperties
            const allowEmail = trackData.data?.AllowAccessToTrack.map(
                (e) => e.value
            )
            const restrictEmail = trackData.data?.RestrictAccessToTrack.map(
                (e) => e.value
            )
            setTrackLinkProps((draft) => {
                return {
                    name: trackProperties.name,
                    passcode: trackProperties.passcode,
                    contacts: trackData.data?.TrackLinkContact.map(
                        (contact) => ({
                            value: contact.contactId,
                            label: contact.email,
                            status: contact.status,
                        })
                    ),
                    passcodeRequired: trackProperties.passcodeRequired,
                    emailRequired: trackProperties.emailRequired,
                    emailAuthRequired: trackProperties.emailAuthRequired,
                    expires: !!trackProperties.validTill,
                    validTill: trackProperties.validTill,
                    restrictAccess:
                        allowEmail.length > 0 || restrictEmail.length > 0,
                    allowDownload: trackProperties.allowDownload,
                    accessAllow: allowEmail.length > 0,
                    accessBlock: restrictEmail.length > 0,
                    allowEmail: allowEmail,
                    blockEmail: restrictEmail,
                }
            })
            setShowAdvancedControls(
                trackProperties.emailAuthRequired ||
                    trackProperties.emailRequired ||
                    trackProperties.passcodeRequired ||
                    allowEmail.length > 0 ||
                    restrictEmail.length > 0
            )
        }
    }, [trackData.data])

    if (trackData.isLoading) {
        return <Loading></Loading>
    }

    return (
        <div className="h-full w-full flex flex-col">
            <div className="w-full py-2 flex justify-between px-6">
                <IconButton
                    onClick={() => {
                        props.setOpen(false)
                        navigate(`/search/viewAsset/${id}/track`)
                    }}
                    size="small"
                >
                    <KeyboardDoubleArrowRightIcon fontSize="small"></KeyboardDoubleArrowRightIcon>
                </IconButton>
            </div>

            <Tabs
                value={tab}
                onChange={(e, newValue) => setTab(newValue)}
                className=""
                variant="fullWidth"
                sx={{ borderBottom: 1, borderColor: 'divider' }}
            >
                <Tab label="Visitor sessions" />
                <Tab label="Link controls" />
            </Tabs>
            <div className="h-full overflow-auto">
                <TabPanel value={tab} index={0} className="pt-4 px-6">
                    <PageLevelDetails
                        data={trackData.data.TrackVisit}
                        totalPages={trackData.data.totalPages}
                    ></PageLevelDetails>
                </TabPanel>
                <TabPanel value={tab} index={1} className="pt-4 px-6">
                    <div className="h-full">
                        <TrackUpdate
                            trackData={trackData?.data}
                            assetId={id}
                            state={{
                                trackLinkProps: trackLinkProps,
                                setTrackLinkProps: setTrackLinkProps,
                                showAdvancedControls: showAdvancedControls,
                                setShowAdvancedControls:
                                    setShowAdvancedControls,
                            }}
                        ></TrackUpdate>
                    </div>
                </TabPanel>
            </div>
        </div>
    )
}
