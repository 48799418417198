import { Accounts } from '@Common/Components/Select/Accounts'
import { Contacts } from '@Common/Components/Select/Contacts'
import { Users } from '@Common/Components/Select/Users'
import { KeyboardArrowDown } from '@mui/icons-material'
import { Chip, Popover, TextField } from '@mui/material'
import { useRef } from 'react'
import { useImmer } from 'use-immer'
export interface FilterOptions {
    options: any
    label: string
    values: string[] | string | object
}
interface FilterProps {
    columnName: string
    columnId: string
    columnType: string
    onDelete: () => void
    onApply: (options: FilterOptions) => void
    options: FilterOptions
}

export function Filter(props: FilterProps) {
    const chip = useRef(null)
    const [filter, setFilter] = useImmer({
        open: false,
    })
    return (
        <div>
            <Chip
                label={`${props.columnName} ${
                    props.options ? props.options.label : ''
                }`}
                ref={chip}
                title={`${props.columnName} ${
                    props.options ? props.options.label : ''
                }`}
                deleteIcon={<KeyboardArrowDown />}
                onDelete={() => {
                    setFilter((draft) => {
                        draft.open = true
                    })
                }}
                className="max-w-[12rem]"
                variant="outlined"
                onClick={() => {
                    setFilter((draft) => {
                        draft.open = true
                    })
                }}
            />
            <Popover
                id={props.columnId}
                open={filter.open}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClose={() => {
                    setFilter((draft) => {
                        draft.open = false
                    })
                }}
                anchorEl={chip.current}
            >
                <div className="w-64 p-4">
                    {getFilterComponent(
                        props.columnType,
                        props.columnName,
                        props.onApply,
                        props.options
                    )}
                </div>
            </Popover>
        </div>
    )
}
function getFilterComponent(
    columnType: string,
    columnName: string,
    onApply: (options: FilterOptions) => void,
    options: FilterOptions
) {
    switch (columnType) {
        case 'LINK':
        case 'STRING':
            return (
                <TextField
                    value={options?.options}
                    onChange={(e) => {
                        const value = e.target.value
                        onApply({
                            options: value,
                            label: value,
                            values: {
                                search: value,
                            },
                        })
                    }}
                    size="small"
                    fullWidth
                />
            )
        case 'USER':
            return (
                <Users
                    label=""
                    size="small"
                    fullWidth={true}
                    multiple={true}
                    value={options?.options}
                    onChange={(e) => {
                        onApply({
                            options: e,
                            label: Array.isArray(e)
                                ? e.map((x: any) => x.label).join(', ')
                                : '',
                            values: Array.isArray(e)
                                ? e.map((x: any) => x.value)
                                : [],
                        })
                    }}
                />
            )
        case 'EMAIL':
            if (columnName === 'Contact') {
                return (
                    <Contacts
                        disableCloseOnSelect
                        multiple
                        value={options?.options}
                        onChange={(e) => {
                            onApply({
                                options: e,
                                label: Array.isArray(e)
                                    ? e.map((x: any) => x.label).join(', ')
                                    : '',
                                values: Array.isArray(e)
                                    ? e.map((x: any) => x.label)
                                    : [],
                            })
                        }}
                    />
                )
            }
            return (
                <TextField
                    value={options?.options}
                    onChange={(e) => {
                        const value = e.target.value
                        onApply({
                            options: value,
                            label: value,
                            values: {
                                search: value,
                            },
                        })
                    }}
                    size="small"
                    fullWidth
                />
            )
        case 'Account':
            return (
                <Accounts
                    multiple={true}
                    label={''}
                    value={options?.options || []}
                    onChange={(e) => {
                        onApply({
                            options: e,
                            label: Array.isArray(e)
                                ? e.map((x: any) => x.label).join(', ')
                                : '',
                            values: Array.isArray(e)
                                ? e.map((x: any) => x.value)
                                : [],
                        })
                    }}
                />
            )
        default:
            return <div>Unknown Filter</div>
    }
}
