import { APi_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { Site } from 'src/Sites/Components/Permission'

interface UpdateSitePermission {
    siteId: string
    permissionId: string
    body: {
        permission: string
    }
}
export function useUpdateSitePermission(props?: any) {
    const queryClient = useQueryClient()
    return useMutation<any, any, UpdateSitePermission>(
        async (obj) =>
            await axios.put(
                `${APi_URL}/site/${obj.siteId}/permission/${obj.permissionId}`,
                obj.body,
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            ),
        {
            onSuccess: async (data: any, obj) => {
                if (props && props.onSuccess) props.onSuccess(data, obj)
            },
            onMutate: async (obj) => {
                queryClient.cancelQueries(['sites', obj.siteId])
                queryClient.setQueryData(['sites', obj.siteId], (old: Site) => {
                    const permissionIndex = old.SitePermission.findIndex(
                        (permission) => {
                            return permission.id === obj.permissionId
                        }
                    )
                    const permission = old.SitePermission[permissionIndex]
                    permission.permission = obj.body.permission
                    old.SitePermission[permissionIndex] = permission
                    return old
                })
            },
            onError: (data: any, obj: any, context: any) => {
                if (props.onError) props.onError(data, obj)
            },
            onSettled: async (data: any, error, obj) => {
                await queryClient.invalidateQueries(['sites', obj.siteId])
            },
        }
    )
}
