import DialogComponent from '@Common/Components/DialogComponent'
import PopoverComponent from '@Common/Components/PopoverComponent'
import useAuditHistory from '@Common/Queries/GetQueries/AuditHistoryData'
import useDeleteAsset from '@Common/Queries/MutationQueries/DeleteAsset'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import { MenuItem, Tooltip } from '@mui/material'
import Breadcrumbs from '@mui/material/Breadcrumbs'

import Feature from '@Common/Components/Feature/Feature'
import DeleteIcon from '@mui/icons-material/Delete'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'

import TimelineIcon from '@mui/icons-material/Timeline'
import UploadFileIcon from '@mui/icons-material/UploadFile'

import useAssetFileVersion from '@Common/Queries/GetQueries/GetAssetVersion'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import Menu from '@mui/material/Menu'
import Typography from '@mui/material/Typography'
import Modal from '@Search/components/common/modal/LockAssetModal'
import Preview from '@Search/components/preview'
import { allowTrack } from '@Search/utils/allowAssetOperations'
import { FC, MutableRefObject, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { TrackLinkProperties } from 'src/Track/Components/TrackProperties'
import { Updater, useImmer } from 'use-immer'
import CreateTrackLink from '../../Track/CreateTrackLink'
import {
    canDelete,
    canDownloadAndView,
    canEdit,
    requestAccessForAsset,
} from '../util'
import AssetVersionUpdate from './../../Upload/AssetVersionUpdate'
import AuditTrail from './AuditTrail'
import Permission from './Permission'
import { useAssetPermission } from './Permission/AddMember'
import { Asset } from './ViewAsset'
import ViewVersionHistory from './ViewVersionHistory'

const ViewAssetTopActions: FC<{
    disabled: boolean
    asset: Asset | undefined
    downloadUrl?: string
    users:
        | {
              data: Record<string, unknown>[]
              option: { value: string; name: string; label: string }[]
          }
        | undefined
    location: string | undefined
    setAsset?: Updater<{ assetId: string; fileId: string; data: Asset }>
    setOpen?: Updater<boolean>
    trackButtonRef?: MutableRefObject<undefined>
}> = ({
    disabled,
    asset,
    downloadUrl,
    users,
    location,
    setAsset,
    setOpen,
    trackButtonRef,
}) => {
    const action = asset ? asset.action : 'NO_ACCESS'
    const fileInputRef: any = useRef()
    const onDownloadSuccess = () => {
        if (!downloadUrl) {
            return
        }

        const link = document.createElement('a')
        link.setAttribute('download', 'download')
        link.setAttribute('href', downloadUrl)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        sendDataToGTM({
            event: 'file_download',
            asset_id: asset.id,
            asset_name: asset.name,
        })
    }
    const {
        data: auditHistory,
        error: AuditHistoryError,
        isSuccess: isAuditHistorySuccess,
        refetch: auditHistoryDetails,
    } = useAuditHistory(asset.id)
    const permissionData = useAssetPermission(asset.id)
    const versionData = useAssetFileVersion(asset.id)
    const [preview, setPreview] = useImmer({ show: false, asset: {} as any })
    const [open, setIsOpen] = useImmer(false)
    const [anchorEl, setAnchorEl] = useImmer<null | HTMLElement>(null)

    const [menuOpen, setMenuOpen] = useImmer(false)
    const [dialogOpen, setDialogOpen] = useImmer(false)
    const [trackOpen, setTrackOpen] = useImmer({
        open: false,
        anchorEl: null,
    })
    const sendDataToGTM = useGTMDispatch()
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setMenuOpen(false)
    }
    const expression = /\.([0-9a-z]+)(?:[\?#]|$)/
    const url: string = downloadUrl || ''
    const result = url.toLowerCase().match(expression)
    const navigate = useNavigate()
    const deleteAsset = useDeleteAsset({
        onSuccess: () => {
            sendDataToGTM({
                event: 'asset_delete',
                asset_id: asset?.id,
                asset_name: asset?.name,
            })
        },
    })

    const deleteAssetConfirm = () => {
        deleteAsset.mutate(asset?.id)
        if (!deleteAsset.isError) {
            navigate('/search')
        }
    }
    const handleUploadClick = () => {
        fileInputRef.current.click()
    }

    const [versionOpen, setVersionOpen] = useImmer({
        open: false,
        anchorEl: null,
    })

    const [auditOpen, setAuditOpen] = useImmer({
        open: false,
        anchorEl: null,
    })
    const downloadView = canDownloadAndView(action)
    const supportedPreviewFormat = [
        'pdf',
        'png',
        'svg',
        'jpeg',
        'jpg',
        'ppt',
        'pptx',
        'doc',
        'docx',
    ]
    const handleDrawerClose = () => {
        if (setAsset) {
            setAsset((draft) => {
                return {
                    assetId: '',
                    fileId: '',
                }
            })
        }
        if (location === 'search') {
            navigate('/search')
        }
        if (setOpen) {
            setOpen(false)
        }
    }

    return (
        <>
            <Preview
                asset={{
                    ...asset,
                    assetUrl: url,
                }}
                closePreview={() => {
                    setPreview((draft) => {
                        draft.show = false
                    })
                }}
                isOpen={preview.show}
            />
            {open && (
                <Modal
                    isOpen={setIsOpen}
                    NewAsset={false}
                    asset={asset}
                    modalTitle={'Request For Access'}
                    version={false}
                />
            )}

            <div className="flex justify-between py-4 items-center  bg-white w-full overflow-hidden sticky top-0 px-6">
                {location !== 'viewAsset' ? (
                    <div className="flex">
                        <Tooltip title="Close">
                            <IconButton
                                disabled={disabled}
                                onClick={() => {
                                    handleDrawerClose()
                                }}
                                size="small"
                            >
                                <KeyboardDoubleArrowRightIcon fontSize="small"></KeyboardDoubleArrowRightIcon>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Expand">
                            <IconButton
                                disabled={disabled}
                                size="small"
                                onClick={() =>
                                    navigate(`/search/viewAsset/${asset.id}`)
                                }
                            >
                                <OpenInFullIcon fontSize="small"></OpenInFullIcon>
                            </IconButton>
                        </Tooltip>
                    </div>
                ) : (
                    <Breadcrumbs className="pt-2">
                        <Link color="text.secondary" to="/search">
                            Documents
                        </Link>
                        <Typography color="text.primary whitespace-nowrap text-ellipsis">
                            {asset.name}
                        </Typography>
                    </Breadcrumbs>
                )}
                <div className="flex gap-1">
                    <Tooltip title="Copy link">
                        <IconButton
                            size="small"
                            onClick={() => {
                                navigator.clipboard.writeText(
                                    `${
                                        import.meta.env
                                            .VITE_COGNITO_CALLBACK_URL
                                    }/search/viewAsset/${asset.id}`
                                )
                            }}
                        >
                            <ContentCopyIcon fontSize="small"></ContentCopyIcon>
                        </IconButton>
                    </Tooltip>
                    {downloadView && allowTrack(downloadUrl || '') && (
                        <Feature name="asset_track_link">
                            <Button
                                variant="contained"
                                ref={trackButtonRef}
                                disabled={disabled}
                                component={Button}
                                size="small"
                                onClick={(e) => {
                                    setTrackOpen((draft) => {
                                        return {
                                            open: true,
                                            anchorEl: e.currentTarget,
                                        }
                                    })
                                }}
                            >
                                Create link
                            </Button>
                        </Feature>
                    )}
                    {downloadView &&
                        result &&
                        result[1] &&
                        supportedPreviewFormat.findIndex(
                            (format) => format === result[1]
                        ) >= 0 && (
                            <Tooltip title="Preview">
                                <IconButton
                                    disabled={disabled}
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setPreview((draft) => {
                                            draft.show = true
                                        })
                                    }}
                                >
                                    <RemoveRedEyeOutlinedIcon fontSize="small"></RemoveRedEyeOutlinedIcon>
                                </IconButton>
                            </Tooltip>
                        )}
                    {downloadView && (
                        <Tooltip title="Download">
                            <IconButton
                                disabled={disabled}
                                size="small"
                                onClick={onDownloadSuccess}
                            >
                                <FileDownloadOutlinedIcon fontSize="small"></FileDownloadOutlinedIcon>
                            </IconButton>
                        </Tooltip>
                    )}
                    {action === 'FULL_ACCESS' && (
                        <Permission asset={asset} disabled={disabled} />
                    )}

                    {downloadView && (
                        <IconButton
                            disabled={disabled}
                            size="small"
                            onClick={(e) => {
                                setMenuOpen(true)
                                handleClick(e)
                            }}
                        >
                            <MoreVertOutlinedIcon fontSize="small"></MoreVertOutlinedIcon>
                        </IconButton>
                    )}
                    {requestAccessForAsset(action) && (
                        <Button
                            disabled={disabled}
                            color="error"
                            size="small"
                            onClick={(e: any) => {
                                e.stopPropagation()
                                setIsOpen(true)
                            }}
                        >
                            Request
                        </Button>
                    )}
                </div>
            </div>

            <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleClose}>
                {downloadView && (
                    <MenuItem
                        className="text-sm"
                        onClick={(e) => {
                            handleClose()
                            setVersionOpen((draft) => {
                                return {
                                    open: true,
                                    anchorEl: anchorEl,
                                }
                            })
                        }}
                    >
                        <TimelineIcon
                            fontSize="small"
                            color="action"
                            className="mr-3"
                        ></TimelineIcon>
                        Versions
                    </MenuItem>
                )}
                {canEdit(action) && (
                    <MenuItem
                        className="text-sm"
                        onClick={() => handleUploadClick()}
                    >
                        <AssetVersionUpdate
                            assetExists={asset}
                            fileInputRef={fileInputRef}
                            multiple={false}
                        ></AssetVersionUpdate>
                        <UploadFileIcon
                            fontSize="small"
                            color="action"
                            className="mr-3"
                        ></UploadFileIcon>
                        Update version
                    </MenuItem>
                )}
                {downloadView && (
                    <MenuItem
                        className="text-sm"
                        onClick={(e) => {
                            handleClose()
                            setAuditOpen((draft) => {
                                return {
                                    open: true,
                                    anchorEl: anchorEl,
                                }
                            })
                        }}
                    >
                        <ManageSearchIcon
                            fontSize="small"
                            color="action"
                            className="mr-3"
                        ></ManageSearchIcon>
                        Audit Trail
                    </MenuItem>
                )}
                {canDelete(action) && (
                    <MenuItem
                        className="text-sm"
                        onClick={() => setDialogOpen(true)}
                    >
                        <DeleteIcon
                            fontSize="small"
                            color="action"
                            className="mr-3"
                        ></DeleteIcon>
                        Delete
                    </MenuItem>
                )}
            </Menu>
            <DialogComponent
                open={dialogOpen}
                setOpen={setDialogOpen}
                title={'Delete Asset'}
                body={
                    <div>
                        <Typography>
                            Are you sure you want to delete this asset
                            permanently?
                        </Typography>
                    </div>
                }
                success={{
                    text: 'Yes',
                }}
                cancel={{
                    text: 'No',
                    variant: 'text',
                }}
                handleChange={deleteAssetConfirm}
            ></DialogComponent>
            <PopoverComponent state={auditOpen} setState={setAuditOpen}>
                <AuditTrail auditHistory={auditHistory}></AuditTrail>
            </PopoverComponent>
            <PopoverComponent state={versionOpen} setState={setVersionOpen}>
                <ViewVersionHistory
                    assetFiles={versionData.data}
                    isLoading={versionData.isLoading}
                    owner={users}
                ></ViewVersionHistory>
            </PopoverComponent>
            <PopoverComponent state={trackOpen} setState={setTrackOpen}>
                <CreateTrackLink
                    assetId={asset.id}
                    setOpen={setTrackOpen}
                    origin={'asset'}
                ></CreateTrackLink>
            </PopoverComponent>
        </>
    )
}
export default ViewAssetTopActions
