import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'
import { searchUpdaterFunctionForUpload } from '@Search/utils/searchTableUpdater'

export default function useAddAsset(props: any) {
    const queryClient = useQueryClient()
    return useMutation<any, Error, object>(
        (obj: any) =>
            axios.post(`${APi_URL}/assets`, obj.body, {
                headers: {
                    'Content-Type': 'application/json',
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                },
            }),
        {
            onSuccess: (data: any, obj: any) => {
                queryClient.setQueryData(
                    ['assets', data.data.id, 'details'],
                    data.data
                )
                const searchAsset = searchUpdaterFunctionForUpload(data.data)
                queryClient.setQueriesData(['search'], (previous) => {
                    const prevData = Object.assign({}, previous.data)
                    const assets = Object.assign([], previous.data.assets)
                    assets.unshift(searchAsset)
                    return {
                        data: {
                            assets: assets,
                            count: prevData.count + 1,
                        },
                    }
                })

                props.onSuccess(data, obj)
            },
            onError: (data: any, obj: any) => {
                props.onError(data, obj)
            },
        }
    )
}
