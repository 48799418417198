import useSite from '@Common/Queries/MutationQueries/SitesSearch'
import { Button, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import { useImmer } from 'use-immer'
import Loading from '@Common/Components/Loading'
import SitesTable from './SitesTable'
import useDebounce from '@Common/hooks/useDebounce'
import useCreateSite from '@Common/Queries/MutationQueries/CreateSite'
import { useNavigate } from 'react-router-dom'
import useTitle from '@Common/hooks/useTitle'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'

const Sites = () => {
    const [filter, setFilter] = useImmer({
        searchTerm: '',
        sort: 'lastEdited',
        currentPage: 0,
    })
    useTitle('Sites')
    const navigate = useNavigate()
    const debouncedFilter = useDebounce(filter, 200)
    const sites = useSite({ body: debouncedFilter })
    const sendDataToGTM = useGTMDispatch()

    const onSuccessNewSite = (data, obj) => {
        navigate(`/sites/${data.data.id}`)
        sendDataToGTM({
            event: 'site_created',
        })
    }
    const newSite = useCreateSite({
        onSuccess: onSuccessNewSite,
    })

    return (
        <>
            {sites.isLoading || newSite.isLoading ? (
                <Loading></Loading>
            ) : (
                <div className="flex w-full h-full">
                    <div className="p-6 w-full h-full flex flex-col">
                        <div className="flex justify-between pb-6">
                            <Typography fontSize={24}>Sites</Typography>
                            <Button
                                onClick={(e) => {
                                    newSite.mutate({
                                        name: 'Untitled',
                                        imageUrl: '',
                                    })
                                }}
                                variant="contained"
                                startIcon={<AddIcon></AddIcon>}
                            >
                                New Site
                            </Button>
                        </div>
                        {sites.data.count === 0 && filter.searchTerm === '' ? (
                            <div className="h-full flex flex-col justify-center items-center">
                                <Typography
                                    className="w-[610px] text-center pb-2"
                                    color={'text.disabled'}
                                >
                                    Sites are web pages where multiple assets
                                    can be shared along with context so as to
                                    engage the receiver effectively.
                                </Typography>
                                <Button
                                    onClick={(e) => {
                                        newSite.mutate({
                                            name: 'Untitled',
                                            imageUrl: '',
                                        })
                                    }}
                                >
                                    Create a New Site
                                </Button>
                            </div>
                        ) : (
                            <SitesTable
                                filter={filter}
                                setFilter={setFilter}
                                sites={sites.data.sites}
                            ></SitesTable>
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

export default Sites
