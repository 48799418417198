import Avatar from '@Common/Avatar'
import Loading from '@Common/Components/Loading'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import { PlusIcon } from '@heroicons/react/outline'
import { Button } from '@mui/material'
import { useQueryClient } from 'react-query'
import { Link, useNavigate } from 'react-router-dom'
import { useImmer } from 'use-immer'
import CreateWorkspace from './CreateWorkspace'
import useJoinWorkspace from './query/useJoinWorkspace'
import useWorkspaces from './query/useWorkspaces'

export default function Workspace() {
    const navigate = useNavigate()
    const sendDataToGTM = useGTMDispatch()

    const joinWorkspace = useJoinWorkspace({
        onSuccess: () => {
            sendDataToGTM({
                event: 'workspace_join',
            })
            navigate('/')
        },
    })
    const [createWorkspace, setCreateworkspace] = useImmer({
        show: false,
    })
    const [clikedWorkspace, setClikedWorkspace] = useImmer<{
        workspaceId?: Number
    }>({})
    const queryClient = useQueryClient()
    const workspaces = useWorkspaces()
    if (workspaces.isLoading) {
        return <Loading />
    }
    if (createWorkspace.show) {
        return (
            <CreateWorkspace
                cancel={() => {
                    setCreateworkspace((draft) => {
                        draft.show = false
                    })
                }}
            />
        )
    }
    return (
        <div className="flex bg-gray-50 justify-center items-center w-full h-full flex-col">
            {window.location.pathname === '/workspace' && (
                <div className="absolute top-4 right-3">
                    <Link to={'/search'}>Cancel</Link>
                </div>
            )}
            <div className="text-xl  font-medium">Join a workspace</div>
            <div className="p-6 text-gray-400">
                You can join workspaces. Pick a workspace, or create a new
                workspace
            </div>
            <div className="flex flex-col gap-4 mb-6">
                {workspaces.data.tenants.map((tenant) => {
                    return (
                        <div className="w-96 bg-white p-4">
                            <div className="flex justify-between">
                                <div className="flex gap-4">
                                    <Avatar name={tenant.name} />
                                    <div>
                                        <div className="mb-1">
                                            {tenant.name}
                                        </div>
                                        <div className="text-gray-500 text-sm">
                                            {tenant.users} Members
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            setClikedWorkspace((draft) => {
                                                draft.workspaceId = tenant.id
                                            })
                                            joinWorkspace.mutate({
                                                workspaceId: tenant.id,
                                            })
                                        }}
                                        disabled={joinWorkspace.isLoading}
                                    >
                                        {joinWorkspace.isLoading &&
                                            clikedWorkspace.workspaceId ===
                                                tenant.id && (
                                                <div className="mr-2">
                                                    <Loading size="1.5rem" />
                                                </div>
                                            )}{' '}
                                        Join
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <Button
                variant="outlined"
                className="px-28"
                onClick={() => {
                    setCreateworkspace((draft) => {
                        draft.show = true
                    })
                }}
            >
                <PlusIcon className="h-5" /> Create Workspace
            </Button>
        </div>
    )
}
