import '@Common/Styles/tailwind.css'
import ReactDOM from 'react-dom'
import { ReactQueryDevtools } from 'react-query/devtools'
import { App } from './App'

import Feature from '@Common/Components/Feature/Feature'
import FeatureProvider from '@Common/Components/Feature/FeatureProvider'
//import { ThemeProvider } from '@emotion/react'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { createTheme, StyledEngineProvider } from '@mui/material/styles'
import SearchScreen from '@Search/components/SearchScreen'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import Attributes from '@Setting/components/customAttribute/Attributes'
import ManageGroups from '@Setting/components/ManageGroups'
import ManageUsers from '@Setting/components/manageUsers'
import Integration from '@Setting/components/thirdPartyIntegration'
import axios from 'axios'
import { Toaster } from 'react-hot-toast'
import { QueryClient, QueryClientProvider } from 'react-query'
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
} from 'react-router-dom'
import { LicenseInfo } from '@mui/x-license-pro'
import { useImmer } from 'use-immer'
import { AuthenticationCallback } from './AuthenticationCallback'
import AuthHolder from './AuthHolder'
import { authManager } from './AuthManager'
import { ConfirmForgotPassword } from './ConfirmForgotPassword'
import { ForgotPassword } from './ForgotPassword'
import { PluginIndex } from './GmailPlugin'
import Search from './GmailPlugin/Search'
import { Login } from './Login'
import UserOnboarding from './Onboard/UserOnboarding'
import Workspace from './Onboard/Workspace'
import SecureApp from './SecureApp'
import Setting from './Setting'
import { Signup } from './signup'
import Sites from './Sites'
import RenderSite from './Sites/SiteOutlet'
import SitesPage from './Sites/SitePage'
import SitePreview from './Sites/SitePreview'
import AssetTrack from './Track/AssetTrack'
import MultipleUpload from './Upload/MultipleUpload'
import { Verify } from './Verify'
import ViewAssetScreen from './ViewAssetScreen/ViewAssetScreen'

import { Accounts } from './Accounts'
import { AccountDetails } from './Accounts/AccountDetails'
import { ChangePassword } from './ChangePassword'
import { Contacts } from './Contacts'
import { ContactDetails } from './Contacts/ContactDetails'
import { Dashboard } from './Dashboard'
import DashboardLinks from './Dashboard/DashboardLinks'
import { DashboardOutlet } from './Dashboard/DashboardOutlet'
import DashboardVisits from './Dashboard/DashboardVisits'
import Configuration from './GmailPlugin/Configuration'
import { Upload } from './GmailPlugin/Upload'
import { DriveUploadPage } from './Integration/DriveUploadPage'
import DealRoomSearch from './DealRoom'
import '@mui/x-data-grid/themeAugmentation'
import { DealRoom } from './DealRoom/Components/DealRoom'
import { PluginDriveUpload } from './Integration/PluginDriveUpload'
import ApprovalScreen from './Request'
import RequestPage from '@Request/RequestPage'
import RequestDetails from '@Request/components/RequestDetails'
import { PluginDealRoomShare } from './GmailPlugin/PluginDealRoomShare'
import { OutlookWelcome } from './Onboard/OutlookWelcome'
import { OutlookSidePanel } from './GmailPlugin/OutlookSidePanel'

const app = document.getElementById('app')
localStorage.setItem('popupClosedOrSubmitted', '')
axios.interceptors.response.use(
    function (config: any) {
        return config
    },
    function (error) {
        console.error(error)
        // Do something with request error
        if (error.response.status === 412) {
            localStorage.removeItem(`init-${authManager?._user?.sub}`)

            window.location.href = window.location.href
        }
        if (error.response.data.message === 'Unauthorized') {
            authManager.checkTokenExpiry()
        }
        return Promise.reject(error)
    }
)

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://74655e95a7fb42368d76153479d2e4e1@o1215165.ingest.sentry.io/6356053',
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    })

    if (import.meta.env.VITE_MUI_LICENSE_KEY)
        LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_LICENSE_KEY)
    else {
        console.log(
            'license key not found',
            import.meta.env.VITE_MUI_LICENSE_KEY
        )
    }
}

axios.interceptors.request.use(
    function (config: any) {
        if (config.url.search('/oauth2/token$') >= 0) {
            return config
        }
        const jwt = authManager._token
        const id_token = jwt?.identityToken
        const access_token = jwt?.accessToken

        // Do something before request is sent

        config.headers.Authorization = id_token
        config.headers.AAuthorization = access_token
        const initRaw = localStorage.getItem(`init-${authManager?._user?.sub}`)
        const init = initRaw ? JSON.parse(initRaw) : {}
        config.headers.tenantId = init.workspace
        return config
    },
    function (error) {
        console.error(error)
        // Do something with request error
        return Promise.reject(error)
    }
)

declare module '@mui/material/styles' {
    interface Palette {
        blue: Palette['primary']
        green: Palette['primary']
        orange: Palette['primary']
        pink: Palette['primary']
        brown: Palette['primary']
        purple: Palette['primary']
        black?: Palette['primary']
    }
    interface PaletteOptions {
        blue?: PaletteOptions['primary']
        green?: PaletteOptions['primary']
        orange?: PaletteOptions['primary']
        pink?: PaletteOptions['primary']
        brown?: PaletteOptions['primary']
        purple?: Palette['primary']
        black?: Palette['primary']
    }
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#2E66FC',
            light: '#2552CA',
        },
        secondary: {
            main: '#80ABCD',
            light: '#6689A4',
        },
        text: {
            primary: 'rgba(0, 0, 0, 0.87)',
            disabled: 'rgba(0, 0, 0, 0.38)',
            secondary: 'rgba(0, 0, 0, 0.6)',
        },
        action: {
            selected: 'rgba(0, 0, 0, 0.08)',
            disabled: 'rgba(0, 0, 0, 0.26)',
        },
        blue: {
            main: '#0023E5',
            light: '#EFF6FF',
            dark: '#5F00E2',
            contrastText: '#FFFFFF',
        },
        green: {
            main: '#007427',
            light: '#F0FDF4',
            dark: '#5F00E2',
            contrastText: '#FFFFFF',
        },
        pink: {
            main: '#CF005D',
            light: '#FDF2F8',
            dark: '#5F00E2',
            contrastText: '#FFFFFF',
        },
        orange: {
            main: '#CA4700',
            light: '#FFF3E0',
            dark: '#5F00E2',
            contrastText: '#FFFFFF',
        },
        purple: {
            main: '#5F00E2',
            light: '#F5F3FF',
            dark: '#5F00E2',
            contrastText: '#FFFFFF',
        },
        black: {
            main: '#000000',
            light: '#F9FAFB',
            dark: '#5F00E2',
            contrastText: '#FFFFFF',
        },
    },

    components: {
        MuiTableRow: {
            styleOverrides: {
                hover: {
                    background: 'rgba(42, 71, 116, 0.04)',
                },
                root: {
                    '&.Mui-selected': {
                        background: 'rgba(0,0,0,0.04)',
                    },
                    '&.Mui-selected:hover': {
                        background: 'rgba(0,0,0,0.04)',
                    },
                },
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    '& .MuiDataGrid-cell': {
                        color: 'rgba(0, 0, 0, 0.87)',
                        fontWeight: 300,
                    },
                    '& .MuiTypography-body1': {
                        color: 'rgba(0, 0, 0, 0.87)',
                    },
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    '& .MuiButtonBase-root': {
                        padding: '5px 10px',
                    },
                    '& .MuiListItemIcon-root': {
                        fontSize: '1.2rem',
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    color: 'rgba(0, 0, 0, 0.87)',
                },
            },
        },
        MuiCircularProgress: {
            styleOverrides: {
                colorPrimary: '#2E66FC',
            },
        },

        MuiButton: {
            defaultProps: {
                disableTouchRipple: true,
                disableFocusRipple: true,
                disableRipple: true,
                disableElevation: true,
            },
            variants: [
                {
                    props: { variant: 'plain' },
                    style: {
                        textTransform: 'none',
                        border: 0,
                        backgroundColor: 'white',
                    },
                },
                {
                    props: { variant: 'white' },
                    style: {
                        textTransform: 'none',
                        background: 'white',
                        fontWeight: 500,
                        borderRadius: 1,
                        width: 80,
                    },
                },
            ],
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    border: 0,
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'filled',
                size: 'small',
            },
            styleOverrides: {
                root: {
                    'font-size': '.875rem',
                    'line-height': '1.25rem',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    'font-size': '.875rem',
                    'line-height': '1.25rem',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                body1: {
                    'font-size': '.875rem',
                    'line-height': '1.25rem',
                },
                body2: {
                    'font-size': '.875rem',
                    'line-height': '1.25rem',
                },
                caption: {
                    'font-size': '.875rem',
                    'line-height': '1.25rem',
                },
                subtitle2: {
                    color: 'rgba(0, 0, 0, 0.87)',
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    'font-size': '.875rem',
                    'line-height': '1.25rem',
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    'font-size': '.875rem',
                    'line-height': '1.25rem',
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    'font-size': '.875rem',
                    'line-height': '1.25rem',
                },
            },
        },
    },

    typography: {
        fontSize: 14,
        button: {
            textTransform: 'none',
            fontWeight: 400,
        },
        caption: {
            color: 'var(--light-text-disabled, rgba(0, 0, 0, 0.38))',
        },
        subtitle2: {
            color: 'rgba(0, 0, 0, 0.6)',
        },
        body2: {
            color: 'rgba(0, 0, 0, 0.5)',
        },
    },
})

export interface RequestFilter {
    searchText: string
    requestType: string
    requestStatus: string
    type: string
    currentPage: number
    pageSize: number
}
const Main = () => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: 100000,
                cacheTime: 100000,
            },
        },
    })
    const [requestFilter, setRequestFilter] = useImmer({
        searchText: '',
        requestType: 'ALL',
        requestStatus: 'OPEN',
        type: 'ASSET_APPROVAL',
        currentPage: 0,
        pageSize: 15,
    })

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route
                errorElement={() => {
                    return (
                        <div className="w-full h-full justify-center items-center">
                            <h2>
                                We have encountered an unexpected error. Please
                                refresh the screen
                            </h2>
                        </div>
                    )
                }}
                element={<FeatureProvider />}
            >
                <Route element={<SecureApp />}>
                    <Route element={<App />}>
                        <Route
                            index
                            element={<DashboardOutlet></DashboardOutlet>}
                        />
                        <Route
                            path="/home"
                            element={<DashboardOutlet></DashboardOutlet>}
                        />
                        <Route path="/contacts" element={<Contacts />}></Route>
                        <Route
                            path="/contacts/:id"
                            element={<ContactDetails />}
                        />
                        <Route
                            path="/accounts/:id"
                            element={<AccountDetails />}
                        />
                        <Route path="/accounts" element={<Accounts />} />

                        <Route path="/search">
                            <Route
                                index
                                element={<SearchScreen location={'search'} />}
                            />
                            <Route
                                path=":id"
                                element={
                                    <SearchScreen location={'viewAsset'} />
                                }
                            ></Route>

                            <Route
                                path="viewAsset/:id"
                                element={
                                    <ViewAssetScreen location="viewAsset" />
                                }
                            ></Route>

                            <Route
                                path="viewAsset/:id/track"
                                element={
                                    <Feature name="asset_track_link">
                                        <AssetTrack tab={0} />
                                    </Feature>
                                }
                            />
                            <Route
                                path="viewAsset/:id/track/:trackId"
                                element={
                                    <Feature name="asset_track_link">
                                        <AssetTrack
                                            location={'track'}
                                            tab={0}
                                        />
                                    </Feature>
                                }
                            />
                            <Route
                                path="viewAsset/:id/visit"
                                element={
                                    <Feature name="asset_track_link">
                                        <AssetTrack tab={1} />
                                    </Feature>
                                }
                            />
                            <Route
                                path="viewAsset/:id/visit/:visitId"
                                element={
                                    <Feature name="asset_track_link">
                                        <AssetTrack
                                            location={'visit'}
                                            tab={1}
                                        />
                                    </Feature>
                                }
                            />
                            <Route path="assets" element={<MultipleUpload />} />
                        </Route>

                        <Route path="/approvals">
                            <Route
                                index
                                element={
                                    <ApprovalScreen
                                        filter={requestFilter}
                                        setFilter={setRequestFilter}
                                    />
                                }
                            />
                            <Route path=":id" element={<RequestDetails />} />
                        </Route>
                        <Route path="/requests">
                            <Route path="/requests" element={<RequestPage />} />
                            <Route path=":id" element={<RequestDetails />} />
                        </Route>
                        <Route path="/dashboard">
                            <Route
                                path=""
                                index
                                element={
                                    <Feature name="dashboard">
                                        <Dashboard></Dashboard>
                                    </Feature>
                                }
                            ></Route>

                            <Route
                                path="links"
                                element={
                                    <Feature name="dashboard">
                                        <DashboardLinks></DashboardLinks>
                                    </Feature>
                                }
                            ></Route>
                        </Route>
                        <Route
                            path="visits"
                            element={
                                <Feature name="dashboard">
                                    <DashboardVisits></DashboardVisits>
                                </Feature>
                            }
                        ></Route>
                        <Route
                            path="/sites"
                            element={
                                <Feature name="sites">
                                    <RenderSite></RenderSite>
                                </Feature>
                            }
                        >
                            <Route
                                path=""
                                index
                                element={<Sites></Sites>}
                            ></Route>
                            <Route
                                path=":id"
                                element={<SitesPage></SitesPage>}
                            ></Route>
                        </Route>
                        <Route path="/dealroom">
                            <Route
                                path=""
                                index
                                element={
                                    <Feature name="dealroom">
                                        <DealRoomSearch></DealRoomSearch>
                                    </Feature>
                                }
                            ></Route>
                            <Route
                                path=":id"
                                element={
                                    <Feature name="dealroom">
                                        <DealRoom></DealRoom>
                                    </Feature>
                                }
                            ></Route>
                        </Route>

                        <Route path="/settings" element={<Setting />}>
                            <Route index element={<Attributes />} />
                            <Route path="Attributes" element={<Attributes />} />
                            <Route
                                path="Integration"
                                element={<Integration />}
                            />
                            <Route
                                path="ManageUsers"
                                element={<ManageUsers />}
                            />
                            <Route
                                path="ManageGroups"
                                element={<ManageGroups />}
                            />
                            <Route path="workspace" element={<Workspace />} />
                        </Route>
                        <Route
                            path="/sites/preview/:id"
                            element={<SitePreview></SitePreview>}
                        ></Route>
                        <Route
                            path="/drive/uploads"
                            element={<DriveUploadPage />}
                        />
                    </Route>
                    <Route path="/workspace" element={<Workspace />} />
                    <Route path="/onboarding" element={<UserOnboarding />} />
                    <Route
                        path="/plugin/visits"
                        element={<OutlookSidePanel />}
                    ></Route>
                    <Route element={<PluginIndex />}>
                        <Route
                            path="/plugin"
                            handle={{ name: 'Select document' }}
                            element={<Search />}
                        />
                        <Route
                            path="/plugin/configuration"
                            handle={{ name: 'Insert link' }}
                            element={<Configuration />}
                        />
                        <Route
                            path="/plugin/upload"
                            handle={{ name: 'Uploading document' }}
                            element={<Upload />}
                        />
                        <Route
                            path="/plugin/drive/upload"
                            handle={{ name: 'Uploading document' }}
                            element={<PluginDriveUpload />}
                        />
                        <Route
                            path="/dealroom/share"
                            handle={{
                                name: 'Insert link',
                            }}
                            element={<PluginDealRoomShare />}
                        ></Route>
                    </Route>
                </Route>

                <Route element={<AuthHolder />}>
                    <Route
                        path="/plugin/welcome"
                        element={<OutlookWelcome />}
                    ></Route>
                    <Route path="/login" element={<Login />} />
                    <Route
                        path="/authentication/callback"
                        element={<AuthenticationCallback />}
                    />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/verify" element={<Verify />} />
                    <Route
                        path="/changePassword"
                        element={<ChangePassword />}
                    />
                    <Route
                        path="/forgotpassword"
                        element={<ForgotPassword />}
                    />
                    <Route
                        path="/confirmForgotPassword"
                        element={<ConfirmForgotPassword />}
                    />
                </Route>
            </Route>
        )
    )

    return (
        <StyledEngineProvider injectFirst>
            <CssBaseline />
            <ThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                    <ReactQueryDevtools initialIsOpen={false} />
                    <CssBaseline />
                    <Toaster />
                    <RouterProvider router={router} />
                </QueryClientProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    )
}
ReactDOM.render(<Main />, app)

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        plain: true
        white: true
    }
}
