import { useContext, useRef, useState } from 'react'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { Avatar, IconButton, Typography } from '@mui/material'
import AssetUploadInput from './AssetUploadInput'
import { AssetStatus } from './AssetUploadInput'
import { v4 as uuidv4 } from 'uuid'
import { MultipleUploadContext } from 'src/App'
interface AssetsDropZoneProps {
    onFiles: (files: File[]) => void
    bodyFull: boolean
}
const AssetsDropZone = ({ onFiles, bodyFull }: AssetsDropZoneProps) => {
    const [openAPI, setIsOpenAPI] = useState(false)
    const { assetsFiles, setAssetsFiles } = useContext(MultipleUploadContext)

    const fileInputRef: any = useRef()

    const preventDefault = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const dragOver = (e: any) => {
        preventDefault(e)
    }

    const dragEnter = (e: any) => {
        preventDefault(e)
    }

    const dragLeave = (e: any) => {
        preventDefault(e)
    }

    const fileDrop = (e: any) => {
        preventDefault(e)
        const files = e.dataTransfer.files
        if (files.length) {
            handleFiles(files)
        }
    }

    const fileSizeInMB = (file: File) => {
        return Math.round(file.size / 1024 / 1024)
    }
    const handleFiles = (filesList: FileList) => {
        const files = [...filesList]
        for (let i = 0; i < files.length; i++) {
            if (fileSizeInMB(filesList[i]) >= 10) {
                setIsOpenAPI(true)
            }
        }
        const filesArray = Array.from(files)
        setAssetsFiles((draft) => {
            filesArray.forEach((file) => {
                draft.push({
                    file,
                    status: AssetStatus.initial,
                    id: uuidv4(),
                })
            })
        })
    }
    return (
        <>
            <div
                className={`flex justify-center h-full`}
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}
            >
                <div className="text-center m-auto">
                    <Avatar className="mx-auto bg-avatarBackground mb-4">
                        <UploadFileIcon color="primary"></UploadFileIcon>
                    </Avatar>

                    <div className="flex">
                        <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer  m-auto"
                        >
                            <Typography
                                className="text-base"
                                sx={{ textDecoration: 'underline' }}
                                color={'primary.main'}
                            >
                                Click to upload
                            </Typography>

                            <AssetUploadInput
                                assetExists={undefined}
                                fileInputRef={fileInputRef}
                                multiple={true}
                            ></AssetUploadInput>
                        </label>

                        <p className="pl-1 text-textBlackColor text-base">
                            or drag and drop
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AssetsDropZone
