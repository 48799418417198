import Loading from '@Common/Components/Loading'
import PopoverComponent from '@Common/Components/PopoverComponent'
import RightSlideContainer from '@Common/Components/RightSlideContainer'
import { TabPanel } from '@Common/Components/TabPanel'
import useTitle from '@Common/hooks/useTitle'
import useGetAsset from '@Common/Queries/GetQueries/GetAsset'
import { Breadcrumbs, Button, Tab, Tabs, Typography } from '@mui/material'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useImmer } from 'use-immer'
import CreateTrackLink from './CreateTrackLink'
import { TrackLevelDetails } from './TrackLevelDetails'
import { TrackLink } from './TrackLink'
import { TrackVisit } from './TrackVisit'
import { VisitLevelDetails } from './VisitLevelDetails'
import AssetTrackTable from './AssetTrackTable'

const AssetTrack = (props?: { location?: string; tab: number }) => {
    const { id } = useParams()
    if (!id) {
        return <div>Asset ID is missing</div>
    }
    const [open, setOpen] = useImmer(false)
    const [tabs, setTabs] = useImmer(props?.tab != undefined ? props.tab : 0)

    const [trackOpen, setTrackOpen] = useImmer<{
        open: boolean
        anchorEl: HTMLElement | null
    }>({
        open: false,
        anchorEl: null,
    })
    const asset = useGetAsset({ id: id })
    useTitle(`${asset.isLoading ? 'Portal - LIFEX' : asset.data?.name}`)
    if (asset.isLoading) {
        return <Loading />
    }
    if (asset.isError) {
        return (
            <div className="flex h-full w-full items-center justify-center text-xxl">
                You don't have access to this page
            </div>
        )
    }

    return (
        <>
            <PopoverComponent state={trackOpen} setState={setTrackOpen}>
                <CreateTrackLink
                    assetId={id}
                    setOpen={setTrackOpen}
                    origin={'track'}
                ></CreateTrackLink>
            </PopoverComponent>
            <div className="w-full h-full flex overflow-hidden">
                <div className="w-full h-full flex flex-row overflow-hidden">
                    <div className="p-6 pt-2 h-full w-full flex-1 overflow-hidden">
                        <div className="flex justify-between">
                            <Breadcrumbs className="flex justify-center items-center">
                                <Link color="text.secondary" to="/search">
                                    Documents
                                </Link>
                                <Link
                                    color="text.secondary"
                                    to={`/search/viewAsset/${id}`}
                                    className="w-24 overflow-hidden text-ellipsis whitespace-nowrap"
                                >
                                    {asset.data?.name}
                                </Link>
                                <Typography color="text.primary">
                                    Links
                                </Typography>
                            </Breadcrumbs>
                            <Button
                                variant="contained"
                                onClick={(e) => {
                                    setTrackOpen((draft) => {
                                        draft.open = true
                                        draft.anchorEl = e.currentTarget
                                    })
                                }}
                            >
                                Create link
                            </Button>
                        </div>
                        <div className="pt-6 h-full flex flex-col">
                            <Tabs
                                value={tabs}
                                onChange={(e, newValue) => {
                                    setTabs(newValue)
                                    setOpen(false)
                                }}
                                sx={{
                                    borderBottom: 1,
                                    borderColor: 'rgba(0, 0, 0, 0.12)',
                                }}
                            >
                                <Tab
                                    label="Links"
                                    value={0}
                                    LinkComponent={Link}
                                    to={`/search/viewAsset/${id}/track`}
                                />
                                <Tab
                                    label="Visits"
                                    value={1}
                                    LinkComponent={Link}
                                    to={`/search/viewAsset/${id}/visit`}
                                />
                            </Tabs>

                            <TabPanel
                                index={0}
                                value={tabs}
                                className="w-full overflow-auto"
                            >
                                <AssetTrackTable
                                    setOpen={setOpen}
                                ></AssetTrackTable>
                            </TabPanel>

                            <TabPanel
                                index={1}
                                value={tabs}
                                className="w-full overflow-auto"
                            >
                                <TrackVisit setOpen={setOpen}></TrackVisit>
                            </TabPanel>
                        </div>
                    </div>
                    <RightSlideContainer
                        open={['track', 'visit'].includes(props?.location)}
                    >
                        {tabs === 1 ? (
                            <VisitLevelDetails
                                setOpen={setOpen}
                            ></VisitLevelDetails>
                        ) : (
                            <TrackLevelDetails
                                setOpen={setOpen}
                            ></TrackLevelDetails>
                        )}
                    </RightSlideContainer>
                </div>
            </div>
        </>
    )
}

export default AssetTrack
