import Typography from '@mui/material/Typography'
import DealRoomNewAsset from './DealRoomNewAsset'

import { useDeleteSection } from '@Common/Queries/Dealroom/DeleteSection'
import { useUpdateSectionName } from '@Common/Queries/Dealroom/UpdateSectionName'
import useDebounce from '@Common/hooks/useDebounce'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { Divider, IconButton, TextField } from '@mui/material'
import EditableComponent from 'src/ViewAssetScreen/Components/EditableComponent'
import { useImmer } from 'use-immer'
import DealRoomSectionAsset from './DealRoomSectionAsset'

import { useParams } from 'react-router-dom'
import { useRef } from 'react'
export const DealRoomSection = (props: {
    count: any
    access: string
    section: { name: string; DealRoomSectionAsset: Array<any>; id: string }
    thumbnail: Array<{ id: string; thumbnailUrl: string }>
}) => {
    const { id } = useParams()
    const deleteSection = useDeleteSection()

    const updateSectionName = useUpdateSectionName()
    const sectionNameRef = useRef()

    const [isVisible, setIsVisible] = useImmer<{
        [x: string]: { visible: boolean; anchor: any }
    }>({})
    const assets = props.section.DealRoomSectionAsset.filter(
        (e) => e.status !== 'DELETED'
    )
    const handleKeyDown = (event, column, value) => {
        if (event.key === 'Escape') {
            event.preventDefault()
            event.currentTarget.blur()
            event.target.value = value
            setIsVisible((draft) => {
                draft[column].visible = false
            })
        }
        if (event.key === 'Enter') {
            updateSectionName.mutate({
                id: id,
                data: {
                    name: sectionNameRef.current.value,
                    sectionId: props.section.id,
                },
            })
            event.currentTarget.blur()
            setIsVisible((draft) => {
                draft[column].visible = false
            })
        }
    }
    return (
        <div className="flex flex-col h-full px-6 gap-y-4">
            <div className="flex justify-between items-center w-full px-6">
                <div className="w-full">
                    {['EDIT', 'FULL_ACCESS'].includes(props.access) ? (
                        <EditableComponent
                            value={props.section.name === 'Untitled'? 'Add section title': props.section.name}
                            visible={isVisible}
                            setIsVisible={setIsVisible}
                            id={'name'}
                            defaultValue={props.section.name}
                            textClassName={'text-xl font-medium line-clamp-2 text-gray-400 py-2'}
                        >
                            <TextField
                                inputRef={sectionNameRef}
                                size="small"
                                variant="outlined"
                                defaultValue={props.section.name}
                                onKeyDown={(e) => {
                                    handleKeyDown(e, 'name', props.section.name)
                                }}
                            ></TextField>
                        </EditableComponent>
                    ) : (
                        <Typography className="flex-1 w-full h-full text-base font-medium">
                            {props.section.name}
                        </Typography>
                    )}
                </div>
                {props.access != 'VIEW' && props.count != 1 && (
                    <IconButton
                        onClick={() =>
                            deleteSection.mutate({
                                id: id,
                                sectionId: props.section.id,
                            })
                        }
                    >
                        <DeleteOutlineOutlinedIcon fontSize="small"></DeleteOutlineOutlinedIcon>
                    </IconButton>
                )}
            </div>
            <div className="flex flex-col">
                <div className="flex-1 px-6">
                    <div className="grid lg:grid-cols-3 md:grid-cols-1 xs:grid-cols-1 gap-x-16 gap-y-10">
                        {assets.map((asset, index) => (
                            <DealRoomSectionAsset
                                sectionId={props.section.id}
                                key={index}
                                sectionAsset={asset}
                                thumbnail={props.thumbnail}
                                access={props.access}
                            ></DealRoomSectionAsset>
                        ))}
                        {['EDIT', 'FULL_ACCESS'].includes(props.access) && (
                            <DealRoomNewAsset
                                sectionId={props.section.id}
                            ></DealRoomNewAsset>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
