import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import toast from 'react-hot-toast'
import { QueryClient, useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router'

export const useDeleteDealroom = () => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    return useMutation(
        (data: { dealRoomId: string }) =>
            axios.delete(`${API_URL}/dealroom/${data.dealRoomId}`),
        {
            onError: () => {
                toast.error('Error in deleting the dealroom')
            },
            onMutate: (data) => {
                const dealRoom = queryClient.getQueryData([
                    'dealroom',
                    data.dealRoomId,
                ])
                if (!dealRoom) {
                    return
                }
                const dealRoomUpdated = Object.assign({}, dealRoom)
                dealRoomUpdated.dealRoom.status = 'DELETED'

                queryClient.setQueriesData(
                    ['dealroom', 'search'],
                    (previous) => {
                        const prevCount = Object.assign({}, previous.count)
                        const dealRoom = Object.assign([], previous.dealRoom)
                        const dealRoomIndex = dealRoom.find((e) => {
                            return e.id === data.dealRoomId
                        })
                        if (dealRoomIndex == -1) {
                            return
                        }
                        dealRoom.splice(dealRoomIndex, 1)

                        return {
                            dealRoom: dealRoom,
                            count: prevCount - 1,
                        }
                    }
                )

                queryClient.setQueryData(['dealroom', data.dealRoomId], {
                    dealRoom: dealRoomUpdated.dealRoom,
                })
            },

            onSuccess: (data) => {
                toast.success('Dealroom successfully deleted')
                queryClient.invalidateQueries(['dealroom', data.data.id])
                queryClient.invalidateQueries(['dealroom', 'search'])
            },
        }
    )
}
