import { useQuery, useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'
import { updateSearchWithCustomAttributeAddition } from '@Search/utils/searchTableUpdater'
export interface AddCustomAttributeToAsset {
    assetId: string
    attribute: {
        id: string
        name: string
        type: string
    }
    attributeValue?: {
        id: string
        value: string
    }
    value?: string
}
export default function useAddCustomAttributeValuesToAsset(props?: {
    addCustomAttributeUpdaterFunction?: any
    onError?: any
    onSuccess?: any
}) {
    const queryClient = useQueryClient()
    return useMutation<any, Error, AddCustomAttributeToAsset>(
        (req: AddCustomAttributeToAsset) =>
            axios.post(
                `${APi_URL}/asset/${req?.assetId}/customAttributes`,
                {
                    attributeId: req.attribute.id,
                    attributeValueId: req.attributeValue?.id,
                    value: req.value,
                },
                { headers: { 'Content-Type': 'application/json' } }
            ),
        {
            onSuccess: (data: AddCustomAttributeToAsset, obj: any) => {
                if (props?.onSuccess) props.onSuccess(data, obj)
            },
            onMutate: async (data: AddCustomAttributeToAsset) => {
                if (!props.addCustomAttributeUpdaterFunction) {
                    return
                }
                //cancelling the updates of asset
                await queryClient.cancelQueries([
                    'assets',
                    data.assetId,
                    'details',
                ])

                queryClient.setQueryData(
                    ['assets', data.assetId, 'details'],
                    (old) =>
                        props.addCustomAttributeUpdaterFunction(
                            { ...old },
                            data
                        )
                )
                queryClient.setQueriesData(['search'], (old) => {
                    const prevData = { ...old }
                    return updateSearchWithCustomAttributeAddition(
                        prevData,
                        data
                    )
                })
            },
            onError: (err: any, data: any, context: any) => {
                queryClient.setQueryData(
                    ['assets', data.assetId, 'details'],
                    context.previousAsset
                )

                if (props.onError) props.onError(err, data)
            },
            onSettled: (data: any) => {
                queryClient.invalidateQueries([
                    'assets',
                    data.data.assetId,
                    'details',
                ])
                //queryClient.invalidateQueries(['search'])
            },
        }
    )
}
