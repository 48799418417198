import AvatarComponent from '@Common/Components/Avatar'
import { useCreateDealRoomSubComments } from '@Common/Queries/Dealroom/CreateDealRoomSubComment'
import useOnClickOutside from '@Common/hooks/useOnClickOutside'
import { Key } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import React, { useRef } from 'react'
import Editor from './Editor'
import Collapse from '@mui/material/Collapse'

const Comment = (props: {
    dealRoomId: string
    id: string
    comment: {
        updatedAt: string
        comment: string
        User: {
            name: string
        }
        DealRoomSession: {
            email: string
        }
        children: Array<{
            comment: string
            User: {
                name: string
            }
            DealRoomSession: {
                email: string
            }
            updatedAt: string
        }>
    }
    users: {
        data: Array<any>
        option: Array<any>
    }
    level?: number
}) => {
    const { users, level, dealRoomId } = props
    const commentData = props.comment

    const [expanded, setExpanded] = React.useState<boolean>(false)
    const [reply, setReply] = React.useState<boolean>(false)

    const createSubComment = useCreateDealRoomSubComments(dealRoomId)
    const viewRef = useRef<any>()

    return (
        <div className="w-full flex gap-2">
            <AvatarComponent
                name={
                    commentData.User
                        ? commentData.User.name
                        : commentData?.DealRoomSession?.email
                }
                sx={{
                    width: 32,
                    height: 32,
                }}
            ></AvatarComponent>
            <div className="w-full flex flex-col">
                <div className="flex flex-col">
                    <Typography
                        variant="subtitle2"
                        className="w-5/6 whitespace-nowrap overflow-hidden text-ellipsis"
                    >
                        {commentData.User
                            ? commentData.User.name
                            : commentData?.DealRoomSession?.email}
                    </Typography>
                    <Typography color="text.disabled" fontSize={12}>
                        {formatDistanceToNow(new Date(commentData.updatedAt), {
                            addSuffix: true,
                        })}
                    </Typography>
                </div>
                <Editor
                    editable={false}
                    content={commentData.comment}
                    users={users}
                ></Editor>
                {level === 0 && (
                    <div className="flex gap-2 items-center pb-1">
                        {commentData.children.length > 0 && (
                            <Button
                                sx={{
                                    padding: 0,
                                }}
                                onClick={() => {
                                    setExpanded(!expanded)
                                }}
                            >
                                {(expanded ? 'Hide ' : 'Show ') +
                                    `${commentData.children?.length}` +
                                    (commentData.children?.length === 1
                                        ? ' reply'
                                        : ' replies')}
                            </Button>
                        )}
                        <Button
                            variant="text"
                            size="small"
                            sx={{
                                padding: 0,
                            }}
                            onClick={() => {
                                setReply(!reply)
                            }}
                        >{`Reply`}</Button>
                    </div>
                )}

                <Collapse in={expanded && commentData.children.length > 0}>
                    <div>
                        {commentData?.children?.map((child, id) => (
                            <Comment
                                key={child.id}
                                users={users}
                                comment={child}
                            ></Comment>
                        ))}
                    </div>
                </Collapse>

                <Collapse in={reply}>
                    <div className="flex flex-col gap-2">
                        <Editor
                            editable={true}
                            ref={viewRef}
                            users={users.data}
                            style={{ border: '1px solid rgba(0, 0, 0, 0.23)' }}
                        ></Editor>
                        <div className="flex justify-end">
                            {/* cancel button might be be nedded later */}
                            {/* <Button
                                size="small"
                                className="mr-2"
                                onClick={() => {
                                    setReply(false)
                                    const state = viewRef.current.view.state
                                    const tr = state.tr.replaceWith(
                                        0,
                                        state.doc.content.size,
                                        [
                                            {
                                                type: 'paragraph',
                                                content: [
                                                    {
                                                        type: 'text',
                                                        text: '',
                                                    },
                                                ],
                                            },
                                        ]
                                    )
                                    viewRef.current?.view.dispatch(tr)
                                    viewRef.current?.view.hasFocus()
                                }}
                            >
                                Cancel
                            </Button> */}
                            <Button
                                size="small"
                                variant="contained"
                                onClick={() => {
                                    setReply(false)
                                    const state = viewRef?.current.view.state
                                    const tr = state.tr.replaceWith(
                                        0,
                                        state.doc.content.size,
                                        [
                                            {
                                                type: 'paragraph',

                                                content: [
                                                    {
                                                        type: 'text',
                                                        text: '',
                                                    },
                                                ],
                                            },
                                        ]
                                    )
                                    viewRef?.current.view.dispatch(tr)
                                    createSubComment.mutate({
                                        commentId: props.comment.id,
                                        comment: JSON.stringify(
                                            viewRef?.current.view.state
                                        ),
                                    })
                                }}
                            >
                                Post
                            </Button>
                        </div>
                    </div>
                </Collapse>
            </div>
        </div>
    )
}

export default Comment
