import AvatarComponent from '@Common/Components/Avatar'
import Loading from '@Common/Components/Loading'
import { Accounts } from '@Common/Components/Select/Accounts'
import { Users } from '@Common/Components/Select/Users'
import {
    AccessTimeOutlined,
    LinkOutlined,
    VisibilityOutlined,
} from '@mui/icons-material'
import { Divider, Link, TextField, Typography } from '@mui/material'
import { format } from 'date-fns'
import { Link as LinkComponent, useParams } from 'react-router-dom'
import { Editable } from 'src/ViewAssetScreen/Components/Editable'

import { Editable as FieldEditable } from 'src/Common/Components/Editable'

import { TextFieldThrottled } from '@Common/Components/TextFieldThrottled'
import useDebounce from '@Common/hooks/useDebounce'
import { useCallback, useEffect, useRef } from 'react'
import { Activity } from './Activity'
import { useContact } from './useContact'
import { useUpdateContact } from './useUpdateContact'

export function Field({ meta, updateContact, detail }) {
    const { editor, value } = useCallback(getEditable, [meta, updateContact])(
        meta,
        updateContact
    )

    return (
        <div key={meta.id} className="mb-6 flex gap-4 w-full items-center">
            <div className="w-24">
                <Typography variant="body2">{meta.columnName}</Typography>
            </div>
            <Editable
                value={value}
                editor={editor}
                meta={meta}
                updateContact={updateContact}
                detail={detail}
                className="h-8 flex items-center flex-1"
            />
        </div>
    )
}
export function ContactDetails() {
    let { id } = useParams()
    const contact = useContact(id)
    const updateContact = useUpdateContact()
    if (contact.isLoading) {
        return <Loading />
    }
    if (contact.isError) {
        return (
            <div>'Failed to get contact details. Please refesh the page.'</div>
        )
    }
    const contactColumn = contact.data.userTable.UserTableMeta.find(
        (meta) => meta.columnName === 'Contact'
    )
    return (
        <div className="w-full">
            <div className="flex gap-1 p-6">
                <Link component={LinkComponent} to="/contacts">
                    <Typography variant="body2">Contacts</Typography>
                </Link>
                /
                <Typography>{contact.data.detail[contactColumn.id]}</Typography>
            </div>
            <Divider />
            <div className="flex">
                <div className="flex-1">
                    <div className="p-4">
                        <Typography variant="subtitle1">Activity</Typography>
                    </div>
                    <div className="w-full items-center flex justify-center h-10 bg-gray-50">
                        <div className="flex gap-2 mr-9 items-center">
                            {' '}
                            <Typography
                                variant="body2"
                                className="flex items-center"
                            >
                                <LinkOutlined className="mr-2" />
                                Links:
                            </Typography>
                            <Typography>
                                {contact.data.contactsTrackData?.count}
                            </Typography>
                        </div>
                        <div className="flex gap-2  mr-9 items-center">
                            {' '}
                            <Typography
                                variant="body2"
                                className="flex items-center"
                            >
                                <VisibilityOutlined className="mr-2" />
                                Total Visits:
                            </Typography>
                            <Typography>
                                {contact.data.contactsTrackData?.visits}
                            </Typography>
                        </div>
                        <div className="flex gap-2  mr-9 items-center">
                            {' '}
                            <Typography
                                variant="body2"
                                className="flex items-center"
                            >
                                <AccessTimeOutlined className="mr-2" />
                                Last engagement:
                            </Typography>
                            <Typography>
                                {contact.data.contactsTrackData
                                    ?.lastEngagementTime &&
                                    format(
                                        new Date(
                                            contact.data.contactsTrackData?.lastEngagementTime
                                        ),
                                        'dd MMM yyyy'
                                    )}
                            </Typography>
                        </div>
                    </div>
                    <Activity contact={contact.data} />
                </div>
                <div className="w-1/3 p-4 bg-gray-50">
                    <div className="flex gap-2 mb-6 items-center">
                        <AvatarComponent
                            name={contact.data.detail[contactColumn.id]}
                        />
                        <div>
                            {/* this is the editable component for editing of contact name */}
                            {/* <FieldEditable
                                label={() => {
                                    return (
                                        <Typography variant="h6">
                                            {' '}
                                            {
                                                contact.data.detail[
                                                    contactColumn.id
                                                ]
                                            }
                                        </Typography>
                                    )
                                }}
                                value={contact.data.detail[contactColumn.id]}
                                editor={TextFieldThrottled}
                                editorProps={{
                                    value: contact.data.detail[
                                        contactColumn.id
                                    ],
                                    onDebouncedChange: (value) => {
                                        updateContact.mutate({
                                            recordId: contact.data.detail.id,
                                            columnId: contactColumn.id,
                                            value,
                                        })
                                    },
                                }}
                                className="h-8 flex items-center flex-1"
                            /> */}
                            <Typography variant="h6">
                                {' '}
                                {contact.data.detail[contactColumn.id]}
                            </Typography>

                            <Typography variant="body2">
                                Added By {contact.data.detail.createdby} on{' '}
                                {format(
                                    new Date(contact.data.detail.createdat),
                                    'dd MMM yyyy'
                                )}
                            </Typography>
                        </div>
                    </div>
                    {contact.data.userTable.UserTableMeta.filter(
                        (meta) => meta.id !== contactColumn.id
                    ).map((meta) => {
                        return (
                            <Field
                                meta={meta}
                                key={meta.id}
                                updateContact={updateContact}
                                detail={contact.data.detail}
                            />
                        )
                    })}
                    {contact.data.userTable.parentTable.map((table) => {
                        return (
                            <Field
                                meta={{
                                    ...table,
                                    columnName: table.name,
                                    columnType: 'Account',
                                }}
                                key={table.id}
                                updateContact={updateContact}
                                detail={contact.data.detail}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

const TextEditable = ({ detail, value, setValue, updateContact, meta }) => {
    const textField = useRef<HTMLInputElement>(null)
    const firstUpdate = useRef(true)

    const debouncedTextValue = useDebounce(value, 500, (value) => {
        updateContact.mutate({
            recordId: detail.id,
            columnId: meta.id,
            value: value,
        })
    })

    return (
        <TextField
            inputRef={textField}
            onChange={(e) => {
                setValue(e.target.value)
            }}
            value={value}
            autoFocus
            fullWidth={true}
            size="small"
        />
    )
}
function getEditable(meta, updateContact) {
    switch (meta.columnType) {
        case 'STRING':
            return {
                editor: TextEditable,
                value: ({ detail }) => (
                    <>
                        {detail[meta.id] && (
                            <Typography variant="body1">
                                {detail[meta.id] || 'Empty'}
                            </Typography>
                        )}
                        {!detail[meta.id] && (
                            <Typography variant="body2">{'Empty'}</Typography>
                        )}
                    </>
                ),
            }
        case 'USER':
            return {
                editor: ({ detail }) => (
                    <Users
                        label={meta.columnName}
                        onChange={(e) => {
                            updateContact.mutate({
                                recordId: detail.id,
                                columnId: meta.id,
                                value: e,
                            })
                        }}
                        autofocus
                        fullWidth={true}
                        size="small"
                        value={detail[meta.id]}
                    />
                ),
                value: ({ detail }) => (
                    <>
                        {detail[meta.id] && (
                            <Typography variant="body1">
                                {detail[`${meta.id}_data`] || 'Empty'}
                            </Typography>
                        )}
                        {!detail[meta.id] && (
                            <Typography variant="body2">{'Empty'}</Typography>
                        )}
                    </>
                ),
            }
        case 'Account':
            return {
                editor: ({ detail }) => (
                    <Accounts
                        label={meta.columnName}
                        onChange={(e) => {
                            updateContact.mutate({
                                recordId: detail.id,
                                columnId: meta.id,
                                value: e,
                            })
                        }}
                        autoFocus
                        fullWidth={true}
                        size="small"
                        value={detail[meta.id]}
                    />
                ),
                value: ({ detail }) => (
                    <>
                        {detail[meta.id] && (
                            <Typography variant="body1">
                                {detail[`${meta.id}_data`] || 'Empty'}
                            </Typography>
                        )}
                        {!detail[meta.id] && (
                            <Typography variant="body2">{'Empty'}</Typography>
                        )}
                    </>
                ),
            }
        default:
            return {
                editor: ({ detail }) => (
                    <TextField
                        onChange={(e) => {
                            updateContact.mutate({
                                recordId: detail.id,
                                columnId: meta.id,
                                value: e.target.value,
                            })
                        }}
                        autoFocus
                        fullWidth={true}
                        size="small"
                        defaultValue={detail[meta.id]}
                    />
                ),
                value: ({ detail }) => (
                    <>
                        {detail[meta.id] && (
                            <Typography variant="body1">
                                {detail[meta.id] || 'Empty'}
                            </Typography>
                        )}
                        {!detail[meta.id] && (
                            <Typography variant="body2">{'Empty'}</Typography>
                        )}
                    </>
                ),
            }
    }
}
