import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { v4 as uuid } from 'uuid'
export const useAddAssetToSection = (props?: {
    onSuccess?: any
    user: {
        id: string
        name: string
        thumbnailUrl: string
    }
}) => {
    const queryClient = useQueryClient()
    const id = uuid()
    return useMutation(
        (body: {
            dealRoomId: string
            sectionId: string
            asset: {
                id: string
                thumbnailUrl: string
            }
        }) =>
            axios.post(
                `${API_URL}/dealroom/${body.dealRoomId}/section/asset`,
                {
                    id: id,
                    assetId: body.asset.id,
                    sectionId: body.sectionId,
                },
                {
                    headers: {
                        'Content-type': 'application/json',
                    },
                }
            ),
        {
            onSuccess: (data) => {
                if (props?.onSuccess) {
                    props.onSuccess()
                }
                queryClient.invalidateQueries(['dealroom'])
            },
            // onMutate: (data) => {
            //     queryClient.cancelQueries(['dealroom'])
            //     const dealroomData:
            //         | {
            //               dealRoom: any
            //               thumbnail: Array<{ id: string; thumbnailUrl: string }>
            //           }
            //         | undefined = queryClient.getQueryData([
            //         'dealroom',
            //         data.dealRoomId,
            //     ])
            //     if (!dealroomData) {
            //         return
            //     }
            //     const dealroomSection = Object.assign(
            //         dealroomData.dealRoom.DealRoomSection,
            //         []
            //     )
            //     const dealroomSectionIndex =
            //         dealroomData.dealRoom.DealRoomSection.findIndex(
            //             (e) => e.id === data.sectionId
            //         )
            //     if (dealroomSectionIndex === -1) {
            //         return
            //     }
            //     const dealRoomSectionAsset =
            //         dealroomSection[dealroomSectionIndex].DealRoomSectionAsset

            //     dealRoomSectionAsset.push({
            //         id: id,
            //         dealRoomSectionId: data.sectionId,
            //         assetId: data.asset.id,
            //         addedBy: props?.user.id,
            //         createdAt: new Date(),
            //         updatedAt: new Date(),
            //         User: props?.user,
            //         Asset: data.asset,
            //     })
            //     dealroomSection[dealroomSectionIndex].DealRoomSectionAsset =
            //         dealRoomSectionAsset
            //     const thumbnail = Object.assign([], dealroomData.thumbnail)
            //     thumbnail.push({
            //         id: data.asset.id,
            //         thumbnailUrl: data.asset.imageUrl,
            //     })

            //     queryClient.setQueryData(['dealroom', data.dealRoomId], {
            //         dealRoom: {
            //             ...dealroomData.dealRoom,
            //             DealRoomSection: dealroomSection,
            //         },
            //         thumbnail: thumbnail,
            //     })
            // },
        }
    )
}
