import PrimaryButton from '@Common/Components/Button/PrimaryButton'
import makeIcon from '@Common/utils/MakeIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import usePreviewDownload from '@Common/Queries/GetQueries/usePreviewDownload'

import {
    ArrowLeftIcon,
    ArrowRightIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    XIcon,
} from '@heroicons/react/solid'
import Button from '@mui/material/Button'
import useMeasure from 'react-use-measure'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { useState } from 'react'
import { Document, Page } from 'react-pdf/dist/esm/entry.vite'
import Loading from '@Common/Components/Loading'

interface PreviewPdfProps {
    asset: Record<string, any>
    closePreview: () => void
    type: string
}
export default function PreviewPDF({
    asset,
    closePreview,
    type,
}: PreviewPdfProps) {
    const [numPages, setNumPages] = useState(0)
    const previewDownload = usePreviewDownload({ id: asset.fileId })
    const [ref, bounds] = useMeasure()
    function getScale(): number {
        if (scale.scaleCustom === true) {
            return scale.scale || 1
        }

        const widthRatio = pageWidth / (bounds.width - 16)
        const heightRatio = pageHeight / bounds.height
        if (scale.scaleType === 'width') {
            return 1 / heightRatio
        }
        if (scale.scaleType === 'height') {
            return 1 / widthRatio
        }
        return 1
    }
    function getPreviewURL() {
        if (type === 'pdf') {
            return asset.assetUrl
        }
        return previewDownload.data
    }
    const [pageNumber, setPageNumber] = useState(0)
    const [scale, setScale] = useState({
        scaleCustom: false,
        scale: 1,
        scaleType: 'height',
    })
    const [pageWidth, setPageWidth] = useState(0)
    const [pageHeight, setPageHeight] = useState(0)

    function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
        setNumPages(numPages)
        setPageNumber(1)
    }
    if (!asset || !asset.assetUrl) {
        return <div />
    }
    return (
        <div className="w-full h-full flex flex-col">
            <div className="flex  items-center shadow-sm z-10 h-10 pb-2">
                <div className="flex gap-8 flex-1    items-center">
                    <Button href={asset.assetUrl} variant="contained">
                        Download
                    </Button>
                    <div className="flex gap-2">
                        <FontAwesomeIcon
                            icon={makeIcon(asset.name).name}
                            size="lg"
                            color={makeIcon(asset.name).color}
                        />
                        <div className="flex justify-center items-center">
                            {asset.name}
                        </div>
                    </div>
                </div>

                <div className="flex gap-4 justify-center items-center">
                    <div className="flex gap-4">
                        <Button
                            disabled={pageNumber <= 1}
                            onClick={() => {
                                if (pageNumber <= 1) return
                                const newpage = pageNumber - 1
                                setPageNumber(newpage)
                            }}
                            className="disabled:text-gray-300"
                        >
                            <ChevronLeftIcon className="h-5 w-5 text-current" />
                        </Button>
                        <div className="flex items-center justify-center">
                            {' '}
                            Page {pageNumber} / {numPages}
                        </div>
                        <Button
                            disabled={pageNumber === numPages}
                            onClick={() => {
                                if (pageNumber === numPages) return
                                const newpage = pageNumber + 1
                                setPageNumber(newpage)
                            }}
                            className="disabled:text-gray-300"
                        >
                            <ChevronRightIcon className="h-5 w-5 text-current" />
                        </Button>
                    </div>
                </div>
                <div className="flex-1 gap-10    flex justify-end">
                    <div className="gap-4 flex text-2xl ">
                        <Button
                            onClick={() => {
                                if (getScale() + 0.25 > 5) {
                                    return
                                }

                                setScale({
                                    ...scale,
                                    scaleCustom: true,
                                    scale: getScale() + 0.25,
                                })
                            }}
                        >
                            +
                        </Button>
                        <input
                            className="bg-gray-100 border-gray-500 w-12 text-center p-1 text-sm "
                            defaultValue={
                                numPages <= 0
                                    ? ''
                                    : `${Math.round(getScale() * 100)}%`
                            }
                            onChange={(e) => {
                                setScale({
                                    ...scale,
                                    scaleCustom: true,
                                    scale: Number(
                                        e.target.value.split('%')[0] / 100
                                    ),
                                })
                            }}
                        ></input>
                        <Button
                            onClick={() => {
                                if (getScale() - 0.25 < 0.25) {
                                    return
                                }

                                setScale({
                                    ...scale,
                                    scaleCustom: true,
                                    scale: getScale() - 0.25,
                                })
                            }}
                        >
                            -
                        </Button>
                    </div>

                    <Button
                        onClick={(e) => {
                            e.stopPropagation()
                            closePreview()
                        }}
                    >
                        <XIcon className="h-5 w-5"></XIcon>
                    </Button>
                </div>
            </div>
            <div
                ref={ref}
                className="flex h-full p-2 w-full bg-gray-200 overflow-auto "
            >
                {previewDownload.isLoading && <Loading />}{' '}
                {previewDownload.isSuccess && (
                    <Document
                        file={getPreviewURL()}
                        className="mx-auto"
                        error="Preview generation in progress for this asset. Visit us in just a minute."
                        loading={() => {
                            return <Loading />
                        }}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Page
                            scale={getScale()}
                            renderAnnotationLayer={true}
                            renderTextLayer={true}
                            onLoadSuccess={(page) => {
                                setPageHeight(page.view[3])
                                setPageWidth(page.view[2])
                            }}
                            width={600}
                            pageNumber={pageNumber}
                        />
                    </Document>
                )}
            </div>
        </div>
    )
}
