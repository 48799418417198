import { useQuery, useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'

export default function useFile(props: any) {
    const queryClient = useQueryClient()
    return useMutation<any, Error, { file: File }>(
        (obj) =>
            axios.get(`${APi_URL}/files/upload/preSigned`, {
                params: { fileName: obj.file.name, type: obj.file.type },
            }),
        {
            onSuccess: (data: any, obj: any) => {
                queryClient.invalidateQueries({
                    queryKey: ['assets'],
                    refetchActive: true,
                })
                props.onSuccess(data, obj)
            },
            onError: props.onError,
        }
    )
}
