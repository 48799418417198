import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useQuery } from 'react-query'

export function useAccount(accountId: string) {
    return useQuery(
        ['account', accountId],
        async () => {
            const response = await axios.get(`${API_URL}/accounts/${accountId}`)
            return response.data
        },
        {
            keepPreviousData: true,
        }
    )
}
