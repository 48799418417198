import { APi_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
export interface UpdateTrackLink {
    trackId: string
    body: {
        id: string
        enabled: boolean
        type: string
        trackLinkProperties?: {
            name: string
            validTill: string | null
            allowDownload: boolean
            emailRequired: boolean
            passcodeRequired: boolean
            passcode: string
            emailAuthRequired: boolean
            allowEmail: string[]
            blockEmail: string[]
        }
    }
}
export default function useUpdateTrackLink(props?: any) {
    const queryClient = useQueryClient()
    return useMutation<any, any, UpdateTrackLink>(
        (obj: UpdateTrackLink) =>
            axios.put(`${APi_URL}/trackLink/${obj.trackId}`, obj.body, {
                headers: { 'Content-Type': 'application/json' },
            }),

        {
            onMutate: (data) => {
                queryClient.setQueriesData(
                    ['assets', 'trackLinks', data.body.id],
                    (old) => {
                        const index = old.links.findIndex(
                            (e) => e.id === data.trackId
                        )

                        const oldData = old.links[index]
                        oldData.enabled = data.body.enabled
                        oldData.name = data.body.trackLinkProperties
                            ? data.body.trackLinkProperties.name
                            : oldData.TrackLinkProperties.name
                        oldData.TrackLinkProperties.name = data.body
                            .trackLinkProperties
                            ? data.body.trackLinkProperties.name
                            : oldData.name
                        old.links[index] = oldData
                        return old
                    }
                )
            },
            onSettled: (data) => {
                queryClient.invalidateQueries([
                    'asset',
                    'trackLinks',
                    data.data.track.AssetTrackLink.assetId,
                ])
            },
            onSuccess: async (data: any, obj) => {
                if (props && props.onSuccess) props.onSuccess(data, obj)
                queryClient.invalidateQueries([
                    'asset',
                    'trackLinks',
                    data.data.track.AssetTrackLink.assetId,
                ])
            },
            onError: async (data: any) => {
                if (props && props.onError) props.onError()
            },
        }
    )
}
