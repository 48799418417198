import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'
interface statusFunction {
    (data: any, obj: any): void
}
export default function usePostContent(props?: {
    onSuccess?: statusFunction
    onMutate?: statusFunction
}) {
    const queryClient = useQueryClient()
    return useMutation<any, Error, object>(
        (obj: any) =>
            axios.post(`${APi_URL}/site/content`, obj, {
                headers: { 'Content-Type': 'application/json' },
            }),
        {
            onSuccess: (data, obj) => {
                queryClient.invalidateQueries(['sites', obj.siteId])
                if (props?.onSuccess) {
                    props.onSuccess(data, obj)
                }
            },
            onMutate(data) {
                if (props?.onMutate) {
                    props.onMutate(data, {})
                }
            },
        }
    )
}
