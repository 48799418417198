
import { PencilIcon, TrashIcon } from '@heroicons/react/outline'
interface openDropDown {
  isOpen: boolean,
  index: number
}

interface conformation {
  deleteFor: string,
  message: string,
  isOpen: boolean,
  data: object,
  type: string,
  approve: boolean
}

interface ManageGroups {
  isOpen: boolean,
  data: object,
  type: string,
}
interface IProps {
  openDropDown: openDropDown,
  i: number,
  setConformingDeletion: Function;
  conformingDeletion: conformation;
  data: object,
  setManageGroup: Function,
  manageGroup: ManageGroups
}

const GroupDropDown: React.FC<IProps> = ({ openDropDown, i, conformingDeletion, setConformingDeletion, data, setManageGroup, manageGroup }) => {

  // console.log("groupdrop",data)


  return (
    <div className="">
      <button className="p-2  rounded-md ">
        <span className="">...</span>
      </button>
      {
        openDropDown.isOpen && (i === openDropDown.index) &&
        <div className="absolute block py-2 mt-2 bg-white bg-gray-100 rounded-md shadow-xl w-44 right-10 h-max z-20">
          <div
            onClick={() => {
              manageGroup.isOpen = true
              manageGroup.type = "edit"
              manageGroup.data = data
              setManageGroup({ ...manageGroup })
            }}
            className="flex flex-row place-items-center hover:bg-gray-300 hover:text-white">
            <PencilIcon className="h-5 w-5 ml-5" />
            <span className="block px-4 py-2 text-sm  text-gray-700 ">Edit</span>
          </div>
          <div className="flex flex-row place-items-center hover:bg-gray-300 hover:text-white"
            onClick={() => {
              conformingDeletion.isOpen = true
              conformingDeletion.message = "Are you sure you want to delete this group?"
              conformingDeletion.data = data
              conformingDeletion.type = "delete"
              setConformingDeletion({ ...conformingDeletion })
            }}
          >
            <TrashIcon className="h-5 w-5 ml-5" />
            <span className="block px-4 py-2 text-sm  text-gray-700 ">Delete</span>
          </div>

        </div>
      }
    </div >

  )
}

export default GroupDropDown