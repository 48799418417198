import useInit from '@Common/Queries/GetQueries/useInit'
import SearchScreen from '@Search/components/SearchScreen'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import {
    Avatar,
    Button,
    Dialog,
    DialogContent,
    IconButton,
    Typography,
} from '@mui/material'
import Loading from '@Common/Components/Loading'
import { useParams } from 'react-router-dom'
import { useImmer } from 'use-immer'
import { useAddAssetToSection } from '@Common/Queries/Dealroom/AddAssetToSection'
import AddIcon from '@mui/icons-material/Add'

const DealRoomNewAsset = ({ sectionId }) => {
    const [searchOpen, setSearchOpen] = useImmer(false)
    const init = useInit()
    const { id } = useParams()
    if (init.isLoading) {
        return <Loading></Loading>
    }
    const addAssetToSection = useAddAssetToSection({
        user: init.data.user,
    })
    return (
        <>
            <div className="border rounded border-dashed divide-gray-400 h-56 flex justify-center items-center">
                <div className="flex flex-col">
                    <Button
                        variant="text"
                        size="small"
                        sx={{
                            color: 'action.disabled',
                        }}
                        startIcon={<AddIcon />}
                        onClick={() => {
                            setSearchOpen(true)
                        }}
                    >
                        Add a document
                    </Button>
                </div>
            </div>
            <Dialog
                open={searchOpen}
                onClose={() => {
                    setSearchOpen(false)
                }}
                maxWidth={'lg'}
                sx={{
                    top: 30,
                    left: 30,
                    zIndex: 10,
                }}
            >
                <DialogContent
                    sx={{
                        backgroundColor: 'white',
                        padding: 0,
                        minWidth: '500px',
                        minHeight: '500px',
                    }}
                >
                    <SearchScreen
                        showOptions={false}
                        onClickOfRow={(data) => {
                            addAssetToSection.mutate({
                                dealRoomId: id,
                                sectionId: sectionId,
                                asset: data,
                            })
                            setSearchOpen(false)
                        }}
                    ></SearchScreen>
                </DialogContent>
            </Dialog>
        </>
    )
}
export default DealRoomNewAsset
