import initials from 'initials'
import { twMerge } from 'tailwind-merge'
import uniqolor from 'uniqolor'

interface AvatarProps {
    src?: string
    name: string
    className?: string
}
export default function Avatar(props: AvatarProps) {
    if (props.src) {
        return (
            <img
                className="inline-block h-11 w-11 rounded-full ring-2 ring-white"
                src={props.src}
                alt=""
            />
        )
    }
    const color = uniqolor(props.name)
    return (
        <div
            className={twMerge(
                `inline-block h-11 w-11 bg-gray-200 p-4 flex justify-center items-center rounded-full   ${
                    color.isLight ? 'text-gray-800 ring-white' : ' '
                }`,
                props.className
            )}
        >
            {props.name && initials(props.name.toLowerCase()).toUpperCase()}
        </div>
    )
}
