import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useQuery } from 'react-query'

export const useTrackDetailsById = (props: {
    id: string
    onSuccess?: (data) => void
}) => {
    return useQuery(
        ['trackLink', props.id],
        async () => {
            const result = await axios.get(`${API_URL}/trackLink/track/${props.id}`)
            return result.data
        },
        {
            onSuccess: (data) => {
                if (props.onSuccess) {
                    props.onSuccess(data)
                }
            },
        }
    )
}
