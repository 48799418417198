import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'

export function useAddContacts(props: { onSuccess: () => void }) {
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: (request: any) => addContacts(request),
        onSuccess: () => {
            queryClient.invalidateQueries('contacts')
            props.onSuccess()
        },
    })
    return mutation
}
async function addContacts(request) {
    return await axios.post(`${API_URL}/contacts`, request)
}
