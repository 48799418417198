import { useQuery, useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'
import { updateAssetInSearch } from '@Search/utils/searchTableUpdater'

export default function useUpdateAsset(props?: any) {
    const queryClient = useQueryClient()
    return useMutation<any, Error, object>(
        (obj: any) =>
            axios.put(`${APi_URL}/assets/${obj.id}`, obj.body, {
                headers: { 'Content-Type': 'application/json' },
            }),
        {
            onSuccess: (data: any, obj: any) => {
                queryClient.invalidateQueries(['assets', obj.id, 'details'])
                queryClient.invalidateQueries([
                    'assets',
                    obj.id,
                    'auditHistory',
                ])
                queryClient.invalidateQueries(['search'])
                if (!props.onSuccess) {
                    return
                }
                props.onSuccess(data)
            },
            onMutate: async (data) => {
                if (!props.updateFunction) {
                    return
                }
                await queryClient.cancelQueries(['assets', data.id, 'details'])
                const previousAsset = queryClient.getQueryData([
                    'assets',
                    data.id,
                    'details',
                ])
                queryClient.setQueryData(
                    ['assets', data.id, 'details'],
                    (oldAsset) => {
                        const updatedAsset = props.updateFunction(
                            oldAsset,
                            data
                        )
                        return updatedAsset
                    }
                )
                if (data?.body?.name) {
                    queryClient.setQueriesData(['search'], (old) => {
                        const prevData = Object.assign([], old.data.assets)

                        const updatedSearch = updateAssetInSearch(
                            prevData,
                            data
                        )
                        return {
                            data: {
                                count: updatedSearch.length,
                                assets: updatedSearch,
                            },
                        }
                    })
                }
                return { previousAsset }
            },
            onError: (err: any, data: any, context: any) => {
                if (props.onError) props.onError(err, data)

                if (!props.updateFunction) {
                    return
                }
                queryClient.setQueryData(
                    ['assets', data.id, 'details'],
                    context.previousAsset
                )
            },
            onSettled: (data: any) => {
                queryClient.invalidateQueries(['assets', data.id, 'details'])
                queryClient.invalidateQueries([
                    'assets',
                    data.id,
                    'auditHistory',
                ])
                queryClient.invalidateQueries(['assets', data.id, 'versions'])
            },
        }
    )
}
