import Loading from '@Common/Components/Loading'
import ErrorModal from '@Common/Components/Modals/ErrorModal'
import ValidationModal from '@Common/Components/ValidationModal'
import useThirdParty from '@Common/Queries/GetQueries/ThirdPartyData'
import useThirdPartyCreate from '@Common/Queries/MutationQueries/CreateThirdParty'
import useThirdPartyDelete from '@Common/Queries/MutationQueries/DeleteThirdParty'
import useThirdPartyUpdate from '@Common/Queries/MutationQueries/UpdateThirdParty'
import React, { useEffect, useState } from 'react'

const ThirdPartyForm: React.FC = (props: any) => {
    const [data, setData] = useState<object>({})

    const [active, setActive] = useState<number>()
    const [addValue, setAddValue] = useState(false)
    const [showCreateOption, setShowCreateOption] = useState(false)
    const [conformingDeletion, setConformingDeletion] = useState({
        message: '',
        isOpen: false,
        data: {},
        type: '',
        approve: false,
    })
    const [thirdPartyDetails, setThirdPartyDetails] = useState({
        // name: "",
        url: '',
    })
    const [isOpen, setIsOpen] = useState(false)
    const [message, setMessage] = useState('')
    const {
        data: thirdPartyData,
        refetch: thirdParty,
        isLoading: thirdPartyLoading,
    } = useThirdParty()

    useEffect(() => {
        if (conformingDeletion.approve) {
            deleteThirdParty.mutate(data)
        }
    }, [conformingDeletion.approve])

    useEffect(() => {
        setThirdPartyDetails({
            // name: "",
            url: '',
        })
        setShowCreateOption(false)
        setActive(null)
    }, [props.newAttributeCreation])

    const deleteCustomAttrValue = (res: any) => {
        setIsOpen(true)
        setMessage('Something went wrong')
    }

    const createThirdPartySuccess = (res: any) => {
        setThirdPartyDetails({
            url: '',
        })
        setAddValue(!addValue)
        props.setShowForm(false)
    }

    const deleteThirdPartySuccess = () => {
        thirdPartyDetails.url = ''
        setThirdPartyDetails({ ...thirdPartyDetails })
    }

    const UpdateThirdPartySuccess = () => {
        setThirdPartyDetails({
            url: '',
        })
        props.setShowForm(false)
    }

    const createThirdPartyError = () => {
        setIsOpen(true)
        setMessage('Something went wrong')
    }

    const UpdateThirdPartyError = () => {
        setIsOpen(true)
        setMessage('Something went wrong')
    }

    const createNewCustomAttr = useThirdPartyCreate({
        onError: createThirdPartyError,
        onSuccess: createThirdPartySuccess,
    })
    const updateThirdParty = useThirdPartyUpdate({
        onSuccess: UpdateThirdPartySuccess,
        onError: UpdateThirdPartyError,
    })
    const deleteThirdParty = useThirdPartyDelete({
        onError: deleteCustomAttrValue,
        onSuccess: deleteThirdPartySuccess,
    })

    const onAttributeClicked = (customAttr: any, i: any) => {
        setData(customAttr)
        props.setShowForm(true)
        props.setAttr(false)
        // thirdPartyDetails.name = customAttr.name
        thirdPartyDetails.url = customAttr.payloadUrl
        setThirdPartyDetails({ ...thirdPartyDetails })
        setActive(i)
    }

    const valueManagement = () => {
        if (thirdPartyDetails.url === '') {
            conformingDeletion.isOpen = true
            conformingDeletion.message = 'Attribute URL is mandatory'
            conformingDeletion.data = {}
            conformingDeletion.type = 'nameValidate'
            setConformingDeletion({ ...conformingDeletion })
            return
        }
        if (!props.newAttributeCreation) {
            const body = {
                id: data.id,
                body: thirdPartyDetails,
            }
            updateThirdParty.mutate(body)
        } else {
            createNewCustomAttr.mutate(thirdPartyDetails)
        }
    }

    const loadingPage = () => {
        return (
            <div className="w h m-auto">
                <Loading />
            </div>
        )
    }

    return (
        <>
            {conformingDeletion.isOpen && (
                <ValidationModal
                    setConformingDeletion={setConformingDeletion}
                    conformingDeletion={conformingDeletion}
                />
            )}
            {isOpen && <ErrorModal isOpen={setIsOpen} message={message} />}
            {thirdPartyLoading ? (
                loadingPage()
            ) : (
                <div className="grid grid-cols-1 lg:grid-cols-4 gap-10 w-full h-full overflow-hidden mb-2">
                    {
                        <div className="lg:col-span-2 mt-4 overflow-auto">
                            {thirdPartyData?.map((customAttr: any, i: any) => (
                                <div
                                    onClick={() =>
                                        onAttributeClicked(customAttr, i)
                                    }
                                    className={`cursor-pointer ${
                                        i === active
                                            ? 'border-l-4 border-blue-600 bg-toggleBtn'
                                            : 'border-l-4 border-white'
                                    } h-15 py-3 self-center`}
                                >
                                    <p className="self-center pl-1 break-words">
                                        {customAttr.payloadUrl}
                                    </p>
                                </div>
                            ))}
                        </div>
                    }
                    {props.showForm && (
                        <div className="lg:col-span-2 mt-2 pr-3">
                            <div className="flex mt-4">
                                <span className="pr-4 whitespace-nowrap self-center text-sm">
                                    URL
                                </span>
                                <input
                                    onChange={(e) => {
                                        thirdPartyDetails.url = e.target.value
                                        setThirdPartyDetails({
                                            ...thirdPartyDetails,
                                        })
                                    }}
                                    className="border h-9 w-full ml-3 rounded pl-2"
                                    value={thirdPartyDetails.url}
                                />
                            </div>

                            <div className="mt-6 text-right">
                                <button
                                    className="py-1 px-5 bg-approveBtn text-white text-sm rounded-sm"
                                    onClick={() => valueManagement()}
                                >
                                    Save
                                </button>
                                <button
                                    className="py-1 px-4 ml-3  bg-white border border-approveBtn text-approveBtn text-sm rounded-sm"
                                    onClick={() => {
                                        if (props.newAttributeCreation) {
                                        } else {
                                            conformingDeletion.isOpen = true
                                            conformingDeletion.message =
                                                'Are you sure you want to delete attribute?'
                                            conformingDeletion.data = data
                                            conformingDeletion.type = 'delete'
                                            setConformingDeletion({
                                                ...conformingDeletion,
                                            })
                                        }
                                    }}
                                >
                                    {'Delete'}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

export default ThirdPartyForm

const style = {
    mainContainer:
        ' h-full flex flex-col overflow-hidden bg-bgColor px-4 pt-4 rounded-md font-Segoe',
    display: ' h-full flex flex-col overflow-hidden rounded-md ',
}
