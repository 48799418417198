import Loading from '@Common/Components/Loading'
import { useVisitDetailsById } from '@Common/Queries/Track/GetVisitDetails'
import { useNavigate, useParams } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import { Updater } from 'use-immer'
import { PageLevelDetails } from './Components/PageLevelDetails'

export const VisitLevelDetails = (props: { setOpen: Updater<boolean> }) => {
    const { id, visitId } = useParams()
    if (!visitId || !id) {
        return <div></div>
    }
    const navigate = useNavigate()
    const visit = useVisitDetailsById(visitId)
    if (visit.isLoading) {
        return <Loading></Loading>
    }
    return (
        <div className="px-6 h-full w-full overflow-y-auto">
            <div className="w-full py-2 flex justify-between">
                <IconButton
                    onClick={() => {
                        props.setOpen(false)
                        navigate(`/search/viewAsset/${id}/visit`)
                    }}
                    size="small"
                >
                    <KeyboardDoubleArrowRightIcon fontSize="small"></KeyboardDoubleArrowRightIcon>
                </IconButton>
            </div>
            <PageLevelDetails data={visit.data}></PageLevelDetails>
        </div>
    )
}
