import { API_URL } from '@Common/Types/configs/urlConfig'
import {
    Alert,
    AlertTitle,
    Button,
    Popover,
    TextField,
    Typography,
} from '@mui/material'
import logo from './assets/Portal.png'
import { checkPassword } from './signup'

import axios from 'axios'
import { useMutation } from 'react-query'
import { Controller, useForm } from 'react-hook-form'
import { authManager } from 'src/AuthManager'
import useTitle from '@Common/hooks/useTitle'
import {
    Link as LinkRoute,
    useNavigate,
    useSearchParams,
} from 'react-router-dom'
import { Link } from '@mui/material'
import { NewPasswordRequired } from '@Server/newPasswordRequired'
import pkceChallenge from 'pkce-challenge'

import { ajvResolver } from '@hookform/resolvers/ajv'
import Loading from '@Common/Components/Loading'
import { toast } from 'react-hot-toast'
import { AuthTemplate } from './AuthTemplate'
import useFeatureFlag from '@Common/hooks/useFeatureFlag'
import configuration from './config'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import { CancelOutlined, CheckCircleOutline } from '@mui/icons-material'
import { useImmer } from 'use-immer'
import { useRef } from 'react'

export function ChangePassword() {
    const { handleSubmit, control, setError, formState } = useForm()
    const sendGTMEvent = useGTMDispatch()
    const navigate = useNavigate()
    let [searchParams, setSearchParams] = useSearchParams()
    const email = searchParams.get('email')
    const passwordRef = useRef()
    const mutation = useMutation(
        (login: NewPasswordRequired) => {
            return axios.post(`${API_URL}/changePassword`, login)
        },
        {
            onSuccess: (result, login) => {
                if (result.data.newPasswordRequired) {
                    navigate('/changePassword')
                }
                localStorage.setItem(
                    'jwt',
                    JSON.stringify({
                        accessToken: result.data.accessToken.jwtToken,
                        identityToken: result.data.idToken.jwtToken,
                        refreshToken: result.data.refreshToken.token,
                    })
                )
                var params = new URLSearchParams(window.location.search),
                    previousPage = params.get('previousUrl')

                var baseurl = location.origin

                var destination = previousPage === null ? '/' : previousPage
                sendGTMEvent({
                    event: 'login',
                    user_email: login.username,
                })
                var landingPage = baseurl + destination
                window.location.href = landingPage
            },
            onError: (error, login) => {
                if (
                    error.response.data ===
                    'Password reset required for the user'
                ) {
                    navigate('/confirmForgotPassword?email=' + login.username)
                }
                toast.error(error.response.data)
            },
        }
    )
    const [signup, setSignup] = useImmer({
        passwordHelp: false,
        password: '',
    })
    const passwordValidation = checkPassword(signup.password)

    const onSubmit = (data) => {
        mutation.mutate({ ...data, password: signup.password, username: email })
    }
    useTitle('Change Password - Portal')
    return (
        <AuthTemplate
            title={'Sign in with your email and password'}
            onSubmit={(e) => {
                e.preventDefault()
                e.stopPropagation()
                handleSubmit(onSubmit)()
            }}
            body={
                <>
                    {(formState.errors.password ||
                        formState.errors.username) && (
                        <Alert severity="error">
                            <AlertTitle>Error</AlertTitle>
                            <div className="flex flex-col gap-2">
                                {formState.errors.username && (
                                    <div>Email is required</div>
                                )}
                                {formState.errors.password && (
                                    <div>Password is required</div>
                                )}
                            </div>
                        </Alert>
                    )}
                    <Controller
                        name="oldPassword"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                size="small"
                                type="password"
                                {...field}
                                label="Old password"
                                autoComplete="new-password"
                            />
                        )}
                    />
                    <Controller
                        name="password"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                inputRef={passwordRef}
                                size="small"
                                type="password"
                                onChange={(e) => {
                                    setSignup((draft) => {
                                        draft.password = e.target.value
                                    })
                                }}
                                value={signup.password}
                                inputProps={{
                                    onFocusCapture: () => {
                                        setSignup((draft) => {
                                            draft.passwordHelp = true
                                        })
                                    },
                                    onBlur: () => {
                                        setSignup((draft) => {
                                            draft.passwordHelp = false
                                        })
                                    },
                                }}
                                autoComplete="new-password"
                                label="Password"
                            />
                        )}
                    />
                    <Controller
                        name="repeatPassword"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                size="small"
                                type="password"
                                {...field}
                                label="Repeat"
                                autoComplete="new-password"
                            />
                        )}
                    />
                    <Link component={LinkRoute} to="/forgotpassword">
                        Forgot password?
                    </Link>
                    <Button
                        type="submit"
                        disabled={mutation.isLoading}
                        variant="contained"
                    >
                        {mutation.isLoading && (
                            <div className="mr-2">
                                <Loading size="1.25rem" />
                            </div>
                        )}
                        Change password
                    </Button>
                    <p>
                        Don’t have an account?{' '}
                        <Link component={LinkRoute} to="/signup">
                            Sign up
                        </Link>{' '}
                        instead
                    </p>
                    <Popover
                        id="mouse-over-popover"
                        sx={{
                            pointerEvents: 'none',
                        }}
                        open={signup.passwordHelp}
                        anchorEl={passwordRef.current}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        onClose={() => {
                            setSignup((draft) => {
                                draft.passwordHelp = false
                            })
                        }}
                        disableAutoFocus
                    >
                        <div className="p-4 text-sm flex flex-col gap-4">
                            <Typography className="">Requirements</Typography>
                            <div className="flex gap-2">
                                {' '}
                                {!passwordValidation.minimumEightCharacter && (
                                    <CancelOutlined className="text-red-500" />
                                )}{' '}
                                {passwordValidation.minimumEightCharacter && (
                                    <CheckCircleOutline className="text-green-500" />
                                )}
                                <Typography>
                                    At least 8 character long
                                </Typography>
                            </div>
                            <div className="flex gap-2">
                                {' '}
                                {!passwordValidation.atleastOneLowercase && (
                                    <CancelOutlined className="text-red-500" />
                                )}{' '}
                                {passwordValidation.atleastOneLowercase && (
                                    <CheckCircleOutline className="text-green-500" />
                                )}
                                <Typography>
                                    At least 1 lowercase character
                                </Typography>
                            </div>
                            <div className="flex gap-2">
                                {' '}
                                {!passwordValidation.atleastOneCapitalcase && (
                                    <CancelOutlined className="text-red-500" />
                                )}{' '}
                                {passwordValidation.atleastOneCapitalcase && (
                                    <CheckCircleOutline className="text-green-500" />
                                )}
                                <Typography>
                                    At least 1 uppercase character
                                </Typography>
                            </div>
                            <div className="flex gap-2">
                                {' '}
                                {!passwordValidation.atleastOneNumber && (
                                    <CancelOutlined className="text-red-500" />
                                )}{' '}
                                {passwordValidation.atleastOneNumber && (
                                    <CheckCircleOutline className="text-green-500" />
                                )}
                                <Typography>At least 1 number</Typography>
                            </div>
                            <div className="flex gap-2">
                                {' '}
                                {!passwordValidation.atleastOneSpecialCharacter && (
                                    <CancelOutlined className="text-red-500" />
                                )}{' '}
                                {passwordValidation.atleastOneSpecialCharacter && (
                                    <CheckCircleOutline className="text-green-500" />
                                )}
                                <Typography>
                                    At least 1 special character
                                </Typography>
                            </div>
                        </div>
                    </Popover>
                </>
            }
        />
    )
}
