import { API_URL } from "@Common/Types/configs/urlConfig"
import axios from "axios"
import { useQueries, useQuery } from "react-query"

export const useDealroomHistory = (id: any) => {
    return useQuery(['dealroom',id,'history'], async () => {
        const result = await axios.get(`${API_URL}/dealroom/history/${id}`, {
            headers: { 'Content-Type': 'application/json' },
        })
        return result;
    },{
        onError: () => {
           
        },
        onSuccess: () => {
        }
    })
}