import useFeatures from '@Common/Components/Feature/useFeatures'
import Loading from '@Common/Components/Loading'
import useFeatureFlag from '@Common/hooks/useFeatureFlag'
import useLocalStorage from '@Common/hooks/useLocalStorage'
import useTitle from '@Common/hooks/useTitle'
import useInit from '@Common/Queries/GetQueries/useInit'
import { redirectPage } from '@Common/utils/previousPageCheck'
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'
import { ISnippetsParams } from '@elgorditosalsero/react-gtm-hook/dist/models/GoogleTagManager'
import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { authManager } from 'src/AuthManager'
import useWorkspaces from './Onboard/query/useWorkspaces'

export default function SecureApp() {
    const workspace = useFeatureFlag('workspace_type')
    useTitle('Portal by LIFEX')
    const [userInit, setUserInit] = useLocalStorage(
        `init-${authManager._user?.sub}`,
        {}
    )

    const features = useFeatures()

    const init = useInit()
    const workspaces = useWorkspaces()
    const navigate = useNavigate()
    useEffect(() => {
        if (!authManager._token) {
            redirectPage()
        }
    })
    if (features.isLoading) {
        return <Loading />
    }
    if (init.isLoading) {
        return <Loading />
    }
    function getGTMEnvironment() {
        if (import.meta.env.VITE_GTM_AUTH) {
            return {
                environment: {
                    gtm_auth: import.meta.env.VITE_GTM_AUTH,
                    gtm_preview: import.meta.env.VITE_GTM_PREVIEW,
                },
            }
        }
        return {}
    }
    const gtmParams: ISnippetsParams = {
        id: import.meta.env.VITE_GTM_ID,
        ...getGTMEnvironment(),
        dataLayer: {
            user_id: init.data.user.email,
        },
        dataLayerName: 'user',
    }
    if (init.data.user.onBoarding === 'WORKSPACE') {
        if (!userInit.workspace) {
            if (init.data.user.tenantId) {
                setUserInit({
                    ...userInit,
                    workspace: init.data.user.tenantId,
                })
            } else {
                if (workspaces.isLoading) {
                    return <Loading />
                }
                if (workspaces.data.length > 0) {
                    setUserInit({
                        ...userInit,
                        workspace: workspaces.data[0],
                    })
                }
                if (window.location.pathname === '/workspace') {
                    return (
                        <GTMProvider state={gtmParams}>
                            <Outlet></Outlet>
                        </GTMProvider>
                    )
                }
                navigate('/workspace')
            }
        }

        if (window.location.pathname === '/onboarding') {
            navigate('/')
        }
        return (
            <GTMProvider state={gtmParams}>
                <Outlet></Outlet>
            </GTMProvider>
        )
    }
    if (window.location.pathname !== '/onboarding') {
        navigate('/onboarding')
        return <Loading />
    }

    return (
        <GTMProvider state={gtmParams}>
            <Outlet></Outlet>
        </GTMProvider>
    )
}
