import { useMutation, useQuery } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'

export default function useGetSite(props: { id: string }) {
    return useQuery<any, Error, string>(
        ['sites', props.id],
        async () => {
            const result = await axios.get(`${APi_URL}/site/${props.id}`, {
                headers: { 'Content-Type': 'application/json' },
            })
            return result.data
        },
        {
            onError: (e) => {
                console.log(e)
            },
        }
    )
}
