import { Updater, useImmer } from 'use-immer'
import FilterDropDowns from './common/FilterDropDowns'
import isEmpty from 'lodash.isempty'
import DateFilter from '@Search/Filter/DateFilter'
import TextFilter from '@Search/Filter/TextFilter'
import SelectFilter from '@Search/Filter/SelectFilter'
import NumberFilter from '@Search/Filter/NumberFilter'
import { format, isValid } from 'date-fns'
import OwnerFilter from '@Search/Filter/OwnerFilter'
import AssetTypeFilter from '@Search/Filter/AssetTypeFilter'
import CustomAttrSelectFilter from '@Search/Filter/CustomAttrSelectFilter'
import { Chip, ListItem } from '@mui/material'
import classNames from 'classnames'
import { KeyboardArrowDownOutlined } from '@mui/icons-material'
import DoneIcon from '@mui/icons-material/Done'

interface FilterBarProps {
    ownerId: string
    selected: any
    setSelected: any
    filter: Record<string, unknown>
    setFilter: Updater<Record<string, unknown>>
    columnOptions: any
    customAttributes: any
    users: any
    filterList: Record<string, unknown>
    setFilterList: Updater<Record<string, unknown>>
}
interface FilterProps {
    column: any
    value: any
    onClick: () => void
    filterList: Record<string, unknown>
    setFilterList: Updater<Record<string, unknown>>
    setFilter: Updater<Record<string, unknown>> | unknown
}

const FilterBar: React.FC<FilterBarProps> = (props: FilterBarProps) => {
    const { filterList, setFilterList, selected, setSelected, ownerId } = props
    const [anchorEl, setAnchorEl] = useImmer<{
        [x: string]: { anchorEl: any; isComponentVisible: boolean }
    }>({})
    const customAttributesMap: { [x: string]: any } =
        props.customAttributes.reduce(
            (customAttributesMap: { [x: string]: any }, data) => (
                (customAttributesMap[data.name] = data.option.map((e) => {
                    return { ...e, label: e.value, value: e.value }
                })),
                customAttributesMap
            ),
            {}
        )
    const handleClickAway = (label: string) => {
        setAnchorEl((draft) => {
            draft[label] = {
                anchorEl: null,
                isComponentVisible: false,
            }
        })
    }

    const { setFilter, filter } = props
    const renderSwitch = (columnData: any, index: number) => {
        switch (columnData.filterColumn.type) {
            case 'Date': {
                return (
                    <FilterDropDowns
                        key={index}
                        id={index.toString()}
                        target={(onClick) => (
                            <Filter
                                column={columnData.filterColumn}
                                value={columnData.value}
                                filterList={filterList}
                                onClick={onClick}
                                setFilterList={setFilterList}
                                setFilter={setFilter}
                            ></Filter>
                        )}
                        column={columnData.filterColumn}
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                    >
                        <DateFilter
                            columnData={columnData.filterColumn}
                            value={columnData.value}
                            setFilterList={setFilterList}
                            setFilter={setFilter}
                            data={columnData}
                            handleClickAway={handleClickAway}
                        ></DateFilter>
                    </FilterDropDowns>
                )
            }
            case 'Text': {
                return (
                    <FilterDropDowns
                        key={index}
                        id={index.toString()}
                        target={(onClick) => (
                            <Filter
                                column={columnData.filterColumn}
                                value={columnData.value}
                                filterList={filterList}
                                onClick={onClick}
                                setFilterList={setFilterList}
                                setFilter={setFilter}
                            ></Filter>
                        )}
                        column={columnData.filterColumn}
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                    >
                        <TextFilter
                            columnData={columnData.filterColumn}
                            value={columnData.value}
                            setFilter={setFilter}
                            setFilterList={setFilterList}
                            handleClickAway={handleClickAway}
                        ></TextFilter>
                    </FilterDropDowns>
                )
            }
            case 'Owner': {
                return (
                    <FilterDropDowns
                        key={index}
                        id={index.toString()}
                        target={(onClick) => (
                            <Filter
                                column={columnData.filterColumn}
                                value={columnData.value}
                                filterList={filterList}
                                onClick={onClick}
                                setFilterList={setFilterList}
                                setFilter={setFilter}
                            ></Filter>
                        )}
                        column={columnData.filterColumn}
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                    >
                        <OwnerFilter
                            options={props.users.option}
                            setFilter={setFilter}
                            columnData={columnData.filterColumn}
                            value={columnData.value}
                            setFilterList={setFilterList}
                            handleClickAway={handleClickAway}
                        ></OwnerFilter>
                    </FilterDropDowns>
                )
            }
            // case 'Asset Type': {
            //     return (
            //         <FilterDropDowns
            //             key={index}
            //             id={index.toString()}
            //             target={
            //                 <Filter
            //                     column={columnData.filterColumn}
            //                     value={columnData.value}
            //                     filterList={filterList}
            //                     setFilterList={setFilterList}
            //                     setFilter={setFilter}
            //                 ></Filter>
            //             }
            //             column={columnData.filterColumn}
            //             anchorEl={anchorEl}
            //             setAnchorEl={setAnchorEl}
            //         >
            //             <AssetTypeFilter
            //                 options={props.assetTypes.option}
            //                 setFilter={setFilter}
            //                 columnData={columnData.filterColumn}
            //                 value={columnData.value}
            //                 setFilterList={setFilterList}
            //                 handleClickAway={handleClickAway}
            //             ></AssetTypeFilter>
            //         </FilterDropDowns>
            //     )
            // }

            case 'Single-select':
            case 'Multi-select':
            case 'Tag': {
                return (
                    <FilterDropDowns
                        key={index}
                        id={index.toString()}
                        target={(onClick) => (
                            <Filter
                                column={columnData.filterColumn}
                                value={columnData.value}
                                filterList={filterList}
                                onClick={onClick}
                                setFilterList={setFilterList}
                                setFilter={setFilter}
                            ></Filter>
                        )}
                        column={columnData.filterColumn}
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                    >
                        <CustomAttrSelectFilter
                            options={
                                customAttributesMap[
                                    columnData.filterColumn.label
                                ]
                            }
                            setFilter={setFilter}
                            columnData={columnData.filterColumn}
                            value={columnData.value}
                            setFilterList={setFilterList}
                            handleClickAway={handleClickAway}
                        ></CustomAttrSelectFilter>
                    </FilterDropDowns>
                )
            }
            case 'Number': {
                return (
                    <FilterDropDowns
                        key={index}
                        id={index.toString()}
                        target={(onClick) => (
                            <Filter
                                column={columnData.filterColumn}
                                value={columnData.value}
                                filterList={filterList}
                                onClick={onClick}
                                setFilterList={setFilterList}
                                setFilter={setFilter}
                            ></Filter>
                        )}
                        column={columnData.filterColumn}
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                    >
                        <NumberFilter
                            value={columnData.value}
                            columnData={columnData.filterColumn}
                            setFilter={setFilter}
                            setFilterList={setFilterList}
                            handleClickAway={handleClickAway}
                        ></NumberFilter>
                    </FilterDropDowns>
                )
            }
        }
    }

    // const removeAllFilters = () => {
    //     setFilterList((draft) => (draft = []))
    //     setFilter((draft) => {
    //         return {
    //             searchTerm: '',
    //             filter: {
    //                 assetName: '',
    //                 projectId: [],
    //                 ownerId: [],
    //                 assetType: [],
    //                 createdAt: { startDate: '', endDate: '' },
    //                 updatedAt: { startDate: '', endDate: '' },
    //                 customAttributeFilter: {},
    //             },
    //             currentPage: 0,
    //             pageSize: 10,
    //         }
    //     })
    // }

    return (
        <div
            className={classNames(
                'flex overflow-auto flex-row relative gap-y-2 flex-wrap'
            )}
        >
            <div className="pr-4">
                <Chip
                    data-testid="searchpage-mydocuments-chip"
                    label="My documents"
                    size="medium"
                    sx={{
                        borderRadius: 1,
                        backgroundColor: selected.myDocument
                            ? 'blue.light'
                            : 'none',
                        border: selected.myDocument ? 'none' : 'default',
                    }}
                    color={selected.myDocument ? 'primary' : 'default'}
                    variant="outlined"
                    onClick={() => {
                        setFilter((draft) => {
                            if (!selected.myDocument) {
                                draft.filter.ownerId = [ownerId]
                                draft.currentPage = 0
                            } else {
                                draft.filter.ownerId = []
                                draft.currentPage = 0
                            }
                        })
                        setSelected((draft) => {
                            draft.myDocument = !selected.myDocument
                        })
                    }}
                    icon={selected.myDocument && <DoneIcon></DoneIcon>}
                    clickable
                />
            </div>
            {filterList?.map((e, ind) => renderSwitch(e, ind))}
        </div>
    )
}

export default FilterBar

export const Filter: React.FC<FilterProps> = (props: FilterProps) => {
    const { filterList, setFilterList, setFilter, value } = props

    const removeTheFilter = (column: any) => {
        const data = [...filterList]
        const index = data.findIndex(
            (x) => x.filterColumn.label === column.label
        )

        if (index != -1) {
            data.splice(index, 1)
            setFilterList((draft) => (draft = data))
            if (column.id === 'Updated date') {
                setFilter((draft) => {
                    draft.filter.updatedAt = {
                        startDate: '',
                        endDate: '',
                    }
                    draft.currentPage = 0
                })
            } else if (column.id === 'Uploaded date') {
                setFilter((draft) => {
                    draft.filter.createdAt = {
                        startDate: '',
                        endDate: '',
                    }
                    const data: [{ [x: string]: any }] = draft.selectedFilters
                    draft.currentPage = 0
                })
            } else if (column.id === 'Asset Name') {
                setFilter((draft) => {
                    draft.filter.assetName = ''
                    draft.currentPage = 0
                })
            } else if (column.id === 'Owner') {
                setFilter((draft) => {
                    draft.filter.ownerId = []
                    draft.currentPage = 0
                })
            } else if (column.id === 'Asset Type') {
                setFilter((draft) => {
                    draft.filter.assetType = []
                    draft.currentPage = 0
                })
            } else {
                setFilter((draft) => {
                    delete draft.filter.customAttributeFilter[column.id]
                    draft.currentPage = 0
                })
            }
        }
    }
    const formattedDate = (input: string) => {
        const parsedFormat = new Date(input)

        return isValid(parsedFormat)
            ? format(parsedFormat, 'MMM dd,yyyy')
            : input
    }
    const getDisplayName = (column: any, value: any) => {
        switch (column.type) {
            case 'Text': {
                if (!value || isEmpty(value)) {
                    return column.label
                }
                return column.label.concat(value ? ':' + value : '')
            }
            case 'Date': {
                if (!value || isEmpty(value)) {
                    return column.label
                }
                const fromDate = formattedDate(value.startDate)
                const toDate = formattedDate(value.endDate)
                return column.label.concat(':', fromDate, ' to ', toDate)
            }
            case 'Number': {
                if (!value || isEmpty(value)) {
                    return column.label
                }
                const from = value.from ? ' > ' + value.from : ''
                const to = value.to ? ' <= ' + value.to : ''
                return column.label.concat(': ', from, ' ', to)
            }
            case 'Owner':
            case 'Asset Type':
            case 'Single-select':
            case 'Multi-select':
            case 'Tag': {
                if (!value || isEmpty(value)) {
                    return column.label
                }
                const data = value.map((e) => e.label)
                return column.label.concat(': ', data.join(', '))
            }
            default: {
                return column.label
            }
        }
    }

    const filterSelected =
        (Array.isArray(value) && value.length != 0) ||
        (value && !isEmpty(value))

    return (
        <>
            <ListItem className="p-0 pr-4">
                <Chip
                    data-testid="searchpage-filter-chip"
                    className={classNames(
                        ' text-xs min-w-32 max-w-xs overflow-hidden text-ellipsis whitespace-nowrap'
                    )}
                    sx={{
                        borderRadius: 1,
                        backgroundColor: filterSelected ? 'blue.light' : 'none',
                        border: filterSelected ? 'none' : 'default',
                    }}
                    deleteIcon={<KeyboardArrowDownOutlined />}
                    onDelete={(e) => {
                        props.onClick()
                    }}
                    icon={filterSelected && <DoneIcon></DoneIcon>}
                    color={filterSelected ? 'primary' : 'default'}
                    variant="outlined"
                    label={getDisplayName(props.column, value)}
                ></Chip>
            </ListItem>
        </>
    )
}
