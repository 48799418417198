import { Transition, Dialog } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/solid'
import { Button } from '@mui/material'
import React, { Fragment } from 'react'

interface IProps {
    setConformingDeletion: any
    conformingDeletion: any
}

const ValidationModal: React.FC<IProps> = ({
    setConformingDeletion,
    conformingDeletion,
}) => {
    return (
        <Transition appear show={true} as={Fragment}>
            <Dialog
                as="div"
                className={style.dialogContainer}
                onClose={() => {}}
            >
                <div className={style.subContainer}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className={style.dialogOverlay} />
                    </Transition.Child>

                    <span className={style.span} aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className={`${style.dialogTitleContainer} `}>
                            <div className="flex ml-2">
                                <span className="text-textColor">
                                    {conformingDeletion.message}
                                </span>
                            </div>
                            <div className="flex justify-start  gap-2 mt-4">
                                {conformingDeletion.type === 'delete' ? (
                                    <>
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                conformingDeletion.isOpen =
                                                    false
                                                conformingDeletion.message = ''
                                                conformingDeletion.approve =
                                                    true
                                                setConformingDeletion({
                                                    ...conformingDeletion,
                                                })
                                            }}
                                        >
                                            Yes
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            onClick={() => {
                                                conformingDeletion.isOpen =
                                                    false
                                                conformingDeletion.message = ''
                                                conformingDeletion.data = {}
                                                conformingDeletion.approve =
                                                    false
                                                setConformingDeletion({
                                                    ...conformingDeletion,
                                                })
                                            }}
                                        >
                                            No
                                        </Button>
                                    </>
                                ) : (
                                    <Button
                                        type="button"
                                        variant="contained"
                                        onClick={() => {
                                            conformingDeletion.isOpen = false
                                            conformingDeletion.message = ''
                                            conformingDeletion.approve = false
                                            setConformingDeletion({
                                                ...conformingDeletion,
                                            })
                                        }}
                                    >
                                        Close
                                    </Button>
                                )}
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
}

export default ValidationModal

const style = {
    btnApprove:
        'py-2 px-6 bg-gradient-to-r from-primary to-gradient rounded-sm text-white text-sm font-bold ml-4 ',
    btnReject:
        'py-2 px-5 rounded-sm border text-primary border-gradient font-bold text-sm ',
    dialogContainer:
        'fixed inset-0 z-10 overflow-y-auto bg-opacity-50 bg-gray-500',
    subContainer: 'min-h-screen px-4 text-center',
    dialogOverlay: 'fixed inset-0',
    span: 'inline-block h-screen align-middle',
    dialogTitleContainer:
        'inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg',
    dialogTitle: 'text-lg font-medium leading-6 text-textColor',
}
