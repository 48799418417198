import Loading from '@Common/Components/Loading'
import useTrackLink from '@Common/Queries/GetQueries/GetTrackLinkByAsset'
import useInit from '@Common/Queries/GetQueries/useInit'
import useUpdateTrackLink from '@Common/Queries/MutationQueries/UpdateTrackLink'
import { API_URL, VIEWER_DOMAIN } from '@Common/Types/configs/urlConfig'
import { DeleteOutline } from '@mui/icons-material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import {
    Button,
    IconButton,
    Switch,
    TablePagination,
    Typography,
} from '@mui/material'
import { DataGridPro as DataGrid, GridColDef } from '@mui/x-data-grid-pro'
import axios from 'axios'
import prettyMilliseconds from 'pretty-ms'
import { ChangeEvent } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { Updater, useImmer } from 'use-immer'
import { formattedDate } from './../ViewAssetScreen/util'
import { TrackLinkProperties } from './Components/TrackProperties'
export interface TrackVisit {
    TrackSession: {
        sessionId: string
        AssetTrackSession: {
            email: string
        }
    }
    visitDuration: number
    downloaded: boolean
    updatedAt: Date
    TrackVisitPage: Array<{
        pageNumber: number
        viewTime: number
    }>
}
interface AssetTrackTableProps {
    assetId: string
    trackLinkData: Array<{
        id: string
        code: string
        type: string
        TrackLinkProperties: {
            name: string
        }
        AllowAccessToTrack: Array<any>
        RestrictAccessToTrack: Array<any>
        totalPages: number
        TrackSession: Array<any>
        createdAt: string
        enabled: boolean
        userId: string
        TrackVisit: Array<TrackVisit>
    }>
    access: string

    setSelectedTrack: Updater<{ id: string; code: string; enabled: boolean }>

    setOpen: Updater<boolean>
    trackLinkProps: {
        setTrackLinkProps: Updater<TrackLinkProperties>
        setShowAdvancedControls: Updater<boolean>
    }
}

const AssetTrackTable = (props: { setOpen: Updater<boolean> }) => {
    const { id } = useParams()

    const [hover, setHover] = useImmer(null)

    const [page, setPage] = useImmer(0)
    const pageSize = 10
    const init = useInit()

    if (!id) {
        return <div>Asset ID is missing</div>
    }

    const trackLink = useTrackLink(id, {
        currentPage: page,
        pageSize: pageSize,
    })

    const updateTrack = useUpdateTrackLink()
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const deleteTrackLink = useMutation(
        (trackLinkId) => {
            axios.delete(`${API_URL}/trackLink/${trackLinkId}`)
        },
        {
            onSuccess: (data, obj) => {
                queryClient.invalidateQueries(['assets', 'trackLinks', id])
                queryClient.invalidateQueries(['trackLink', obj])
                queryClient.invalidateQueries(['assets', 'visits', id])
            },
        }
    )
    if (init.isFetching || trackLink.isFetching) {
        return <Loading></Loading>
    }
    if (trackLink.isError) {
        return (
            <div className="flex h-full w-full items-center justify-center text-xxl">
                You don't have access to this page
            </div>
        )
    }
    const userId = init.data.user.id

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 350,
            flex: 1,

            renderCell: (cellValue) => {
                return (
                    <div className="w-full  flex items-center justify-between relative ">
                        <Typography color={'text.primary'}>
                            {cellValue.value}
                        </Typography>
                        {hover === cellValue.row.id && (
                            <div className="absolute right-1 flex gap-2">
                                <Button
                                    className="drop-shadow-md bg-white hover:drop-shadow-md"
                                    sx={{
                                        '&.MuiButtonBase-root:hover': {
                                            bgcolor: 'rgba(42, 71, 116, 0.04)',
                                        },
                                    }}
                                >
                                    Open
                                </Button>
                                <IconButton
                                    size="small"
                                    color="primary"
                                    className=" hover:bg-hoverBackground"
                                    onClick={(e) => {
                                        navigator.clipboard.writeText(
                                            `http://${VIEWER_DOMAIN}/${cellValue.row.code}`
                                        )
                                        e.stopPropagation()
                                    }}
                                >
                                    <ContentCopyIcon />
                                </IconButton>

                                <IconButton
                                    size="small"
                                    color="primary"
                                    className=" hover:bg-hoverBackground"
                                    onClick={(e) => {
                                        deleteTrackLink.mutate(cellValue.row.id)
                                        e.stopPropagation()
                                    }}
                                >
                                    <DeleteOutline />
                                </IconButton>
                            </div>
                        )}
                    </div>
                )
            },
        },
        {
            field: 'createdAt',
            headerName: 'Generated on',
            width: 152,
            renderCell: (cellValue) => {
                return (
                    <Typography className="font-light" color={'text.primary'}>
                        {cellValue.value}
                    </Typography>
                )
            },
        },
        {
            field: 'latestEngagement',
            headerName: 'Last Engagement',
            width: 152,
            renderCell: (cellValue) => {
                return (
                    <Typography className="font-light" color={'text.primary'}>
                        {cellValue.value.length > 0
                            ? formattedDate(cellValue.value[0].updatedAt)
                            : '-'}
                    </Typography>
                )
            },
        },
        {
            field: 'visits',
            headerName: 'Visits',
            width: 75,
            renderCell: (cellValue) => {
                return (
                    <Typography className="font-light" color={'text.primary'}>
                        {cellValue.formattedValue}
                    </Typography>
                )
            },
            valueFormatter: (params) => {
                return params.value
                    ? params.value.toLocaleString('en-US', {
                          minimumIntegerDigits: 2,
                          useGrouping: false,
                      })
                    : '00'
            },
        },
        {
            field: 'timeSpent',
            headerName: 'Time spent',
            width: 125,

            renderCell: (cellValue) => {
                return (
                    <Typography className="font-light" color={'text.primary'}>
                        {cellValue.formattedValue}
                    </Typography>
                )
            },
            valueFormatter: (params) => {
                const value = params.value
                return prettyMilliseconds(value, {
                    secondsDecimalDigits: 0,
                })
            },
        },
        {
            field: 'Enable/Disable',
            width: 152,
            renderCell: (cellValue) => {
                return (
                    <Switch
                        size="small"
                        checked={cellValue.row.enabled}
                        disabled={userId !== cellValue.row.ownerId}
                        onClick={(e) => disableTrackLink(e, cellValue.row.id)}
                    ></Switch>
                )
            },
        },
    ]

    const rows = trackLink.data.links.map((link) => {
        const stats = link.TrackVisit.reduce(
            function (accumulator, item) {
                accumulator['totalViewTime'] =
                    (accumulator['totalViewTime'] || 0) + item.visitDuration
                accumulator['downloads'] =
                    (accumulator['downloads'] || 0) + (item.downloaded ? 1 : 0)

                return accumulator
            },
            {
                totalViewTime: 0,
                downloads: 0,
            }
        )
        const visits = link.TrackVisit
        return {
            id: link.id,
            code: link.code,
            name: link.TrackLinkProperties.name,
            ownerId: link.userId,
            latestEngagement: link.TrackVisit,
            createdAt: formattedDate(link.createdAt),
            visits: visits.length,
            timeSpent: stats['totalViewTime'],
            downloads: stats['downloads'],
            enabled: link.enabled,
            totalPages: link.totalPages,
            trackVisit: visits,
            trackLinkProperties: link.TrackLinkProperties,
            allowAccessToTrack: link.AllowAccessToTrack,
            restrictAccessToTrack: link.RestrictAccessToTrack,
        }
    })
    const disableTrackLink = (
        event: ChangeEvent<HTMLInputElement>,
        trackId: string
    ) => {
        event.stopPropagation()
        updateTrack.mutate({
            trackId: trackId,
            body: {
                id: id,
                enabled: event.target.checked,
            },
        })
    }

    return (
        <div className="w-full h-full flex flex-col py-4">
            <DataGrid
                sx={{
                    border: 'none',
                    flex: 1,
                    '& .MuiDataGrid-row:hover': {
                        background: 'white',
                        cursor: 'pointer',
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 300,
                    },
                }}
                onRowClick={(params) => {
                    props.setOpen(true)
                    navigate(`/search/viewAsset/${id}/track/${params.row.id}`)
                }}
                rowHeight={64}
                rows={rows}
                columns={columns}
                componentsProps={{
                    row: {
                        onMouseEnter: (event) => {
                            const id = event.currentTarget.dataset.id
                            setHover(id)
                        },
                        onMouseLeave: () => setHover(null),
                    },
                }}
                hideFooter={true}
            />
            <TablePagination
                rowsPerPageOptions={[pageSize]}
                colSpan={3}
                component="div"
                className="text-xs text-lightTextDisabled border-none overflow-hidden"
                count={trackLink.data.count}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={(e, page) => {
                    setPage(page)
                }}
                backIconButtonProps={{
                    className: 'stroke-slate-50',
                }}
                nextIconButtonProps={{
                    className: 'stroke-slate-50',
                }}
            />
        </div>
    )
}
export default AssetTrackTable
