import { useQuery } from "react-query";
import axios from "axios";
import { APi_URL } from "@Common/Types/configs/urlConfig";


const getAssetList = async () => {
  const { data } = await axios.get(
    `${APi_URL}/projects`,
    { headers: { 'Content-Type': 'application/json' } }
  )
  return data;
};

export default function useAssetList() {
  return useQuery("Projects", getAssetList);
}
