import { useContext } from 'react'
import { useImmer } from 'use-immer'
import AssetsDropZone from './AssetsDropZone'
import { Link as LinkRoute } from 'react-router-dom'
import AssetUploadLoading from './AssetUploadLoading'
import IconButton from '@mui/material/IconButton'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import classNames from 'classnames'
import RightSlideContainer from '@Common/Components/RightSlideContainer'
import ViewAssetScreen from './../ViewAssetScreen/ViewAssetScreen'
import { MultipleUploadContext } from 'src/App'
const MultipleUpload = () => {
    const { assetsFiles, setAssetsFiles, handleFiles } = useContext(
        MultipleUploadContext
    )
    const [viewAssetOpen, setViewAssetOpen] = useImmer(false)
    const [asset, setAsset] = useImmer({
        assetId: '',
        fileId: '',
        data: {},
    })
    const handleFileRemove = (id: string) => {
        setAssetsFiles((draft) => {
            const fileIndex = draft.findIndex(
                (assetFile) => assetFile.id === id
            )
            draft.splice(fileIndex, 1)
        })
    }

    return (
        <>
            <div className="flex flex-row overflow-hidden w-full h-full">
                <div className="p-6 flex flex-col h-full flex-1">
                    <div role="presentation" className="flex w-full pb-6">
                        <Breadcrumbs className="my-auto">
                            <Link
                                underline="hover"
                                component={LinkRoute}
                                color="text.secondary"
                                to="/search"
                            >
                                Documents
                            </Link>
                            <Typography color="text.primary">Upload</Typography>
                        </Breadcrumbs>
                    </div>

                    <div className="h-full w-full pb-8">
                        <div
                            className={classNames(
                                'border border-dashed border-uploadBorderColor w-full pt-6',
                                {
                                    'h-32': assetsFiles.length > 0,
                                    'h-full': assetsFiles.length <= 0,
                                }
                            )}
                        >
                            <AssetsDropZone
                                onFiles={handleFiles}
                                bodyFull={true}
                            ></AssetsDropZone>
                        </div>
                        {assetsFiles.length > 0 && (
                            <>
                                <AssetUploadLoading
                                    assetFilesList={assetsFiles}
                                    setViewAssetOpen={setViewAssetOpen}
                                    setAsset={setAsset}
                                    onRemoveFile={handleFileRemove}
                                ></AssetUploadLoading>
                            </>
                        )}
                    </div>
                </div>

                <RightSlideContainer open={viewAssetOpen}>
                    <ViewAssetScreen
                        asset={asset}
                        setOpen={setViewAssetOpen}
                        location={''}
                        setAsset={setAsset}
                    ></ViewAssetScreen>
                </RightSlideContainer>
            </div>
        </>
    )
}
export default MultipleUpload
