import { APi_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'

interface AddSitePermission {
    siteId: string
    body: {
        members: {
            id: string
            type: 'Users' | 'Groups'
        }[]
        permission: string
    }
}
export function useSitePermission(props?: any) {
    const queryClient = useQueryClient()
    return useMutation<any, any, AddSitePermission>(
        async (obj) =>
            await axios.post(
                `${APi_URL}/site/${obj.siteId}/permission`,
                obj.body,
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            ),
        {
            onSuccess: async (data: any, obj) => {
                await queryClient.invalidateQueries(['sites', obj.siteId])
                if (props && props.onSuccess) props.onSuccess(data, obj)
            },
            onMutate: async (data) => {},
            onError: (data: any, obj: any, context: any) => {
                if (props.onError) props.onError(data, obj)
            },
            onSettled: (data: any, error, obj) => {},
        }
    )
}
