import { AvatarComponent } from '@Common/Components/Avatar'
import Loading from '@Common/Components/Loading'
import Modal from '@Common/Components/Modals/Modal'
import { Accounts } from '@Common/Components/Select/Accounts'
import {
    Add,
    AddOutlined,
    ArrowDownward,
    ArrowUpward,
    CallMadeRounded,
    DeleteOutlineOutlined,
    MoreVertOutlined,
} from '@mui/icons-material'
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    FormControlLabel,
    FormGroup,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Popover,
    TextField,
    Typography,
} from '@mui/material'
import {
    DataGridPro as DataGrid,
    DataGridPro,
    GridColDef,
    GridInputRowSelectionModel,
    GridRowsProp,
    GridSortModel,
} from '@mui/x-data-grid-pro'
import { format } from 'date-fns'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useImmer } from 'use-immer'
import { ActiveChip } from './ActiveChip'
import { Filter, FilterOptions } from './Filter'
import { useContacts } from './useContacts'
import { useAddContacts } from './useCreateContacts'
import { useDeleteContact } from './useDeleteContact'
import { emailRegex } from 'src/Track/Components/TrackProperties'
import toast from 'react-hot-toast'
import { checkEmail } from '@Common/ValidationCheck'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
interface Contact {
    email: string
    name: string
    account: string
}
const columnOrder = [
    'Contact',
    'Name',
    'Account',
    'Owner',
    'Last engaged',
    'Links shared',
    'Visits',
]
export function Contacts() {
    const [contact, setContact] = useImmer({
        createContact: false,
        filter: false,
        filterColumns: [] as { id: string; label: string; type: string }[],
        newContacts: [{} as Contact],
    })
    const [validationError, setValidationError] = useImmer([])
    const sendDataToGTM = useGTMDispatch()
    const addContacts = useAddContacts({
        onSuccess: () => {
            setContact((draft) => {
                draft.createContact = false
                draft.newContacts = [
                    {
                        email: '',
                        name: '',
                        account: null,
                    },
                ]
            })
            sendDataToGTM({
                event: 'contact_created',
            })
        },
    })

    return (
        <div className="flex-1 flex  flex-col overflow-hidden h-full">
            <div className="flex justify-between items-center p-5 w-full">
                <div>
                    <Typography variant="h5">Contacts</Typography>
                </div>
                <div className="flex h-8 gap-2">
                    <Button
                        variant="contained"
                        size="small"
                        startIcon={<AddOutlined></AddOutlined>}
                        onClick={() => {
                            setContact((draft) => {
                                draft.createContact = true
                            })
                        }}
                    >
                        <Typography>Add Contacts</Typography>
                    </Button>
                </div>
            </div>

            <Table
                addContact={() => {
                    setContact((draft) => {
                        draft.createContact = true
                    })
                }}
            />
            <Modal
                className="w-[800px] h-[600px]"
                onClose={() => {
                    setContact((draft) => {
                        draft.createContact = false
                        draft.newContacts = [
                            {
                                email: '',
                                name: '',
                                account: '',
                            },
                        ]
                    })
                    setValidationError((draft) => {
                        draft = []
                    })
                }}
                isOpen={contact.createContact}
                title="Add Contacts"
                footer={
                    <Button
                        disabled={addContacts.isLoading}
                        onClick={() => {
                            let validationMessage = false
                            contact.newContacts.map((value, index) => {
                                if (
                                    Object.keys(value).length === 0 ||
                                    !value.hasOwnProperty('email') ||
                                    value.email.length === 0 ||
                                    !checkEmail(value.email)
                                ) {
                                    setValidationError((draft) => {
                                        draft[index] = true
                                    })
                                    validationMessage = true
                                }
                            })
                            if (!validationMessage) {
                                addContacts.mutate({
                                    data: contact.newContacts,
                                })
                            }
                        }}
                        variant="contained"
                    >
                        {addContacts.isLoading && (
                            <div className="mr-2">
                                <Loading size="1.25rem" />
                            </div>
                        )}
                        Create Contacts
                    </Button>
                }
                body={
                    <div className="p-4">
                        <div className="mb-4 flex flex-col gap-4">
                            {contact.newContacts.map((contact, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="flex gap-4 justify-between"
                                    >
                                        <div className="w-full">
                                            <TextField
                                                label="Email (Required)"
                                                size="small"
                                                fullWidth
                                                value={contact.email}
                                                variant="outlined"
                                                onChange={(e) => {
                                                    setValidationError(
                                                        (draft) => {
                                                            draft[index] = false
                                                        }
                                                    )

                                                    setContact((draft) => {
                                                        draft.newContacts[
                                                            index
                                                        ].email =
                                                            e.target.value.toLowerCase()
                                                    })
                                                }}
                                                required
                                            ></TextField>
                                            {validationError[index] && (
                                                <Typography
                                                    color="error"
                                                    className="p-2"
                                                >
                                                    Valid email is required
                                                </Typography>
                                            )}
                                        </div>
                                        <div className="w-full">
                                            <TextField
                                                size="small"
                                                fullWidth
                                                variant="outlined"
                                                value={contact.name}
                                                onChange={(e) => {
                                                    setContact((draft) => {
                                                        draft.newContacts[
                                                            index
                                                        ].name = e.target.value
                                                    })
                                                }}
                                                label="Name"
                                            ></TextField>
                                        </div>
                                        <div className="w-full">
                                            <Accounts
                                                label="Account"
                                                fullWidth
                                                value={contact.account}
                                                onChange={(option) => {
                                                    setContact((draft) => {
                                                        draft.newContacts[
                                                            index
                                                        ].account =
                                                            option?.value
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <Button
                            startIcon={<AddOutlined></AddOutlined>}
                            onClick={() => {
                                setContact((draft) => {
                                    draft.newContacts.push({} as Contact)
                                })
                            }}
                        >
                            Add more
                        </Button>
                    </div>
                }
            />
        </div>
    )
}
interface QueryOption {
    sortModel: GridSortModel
}
function Table({ addContact }) {
    const [queryOptions, setQueryOptions] = useState<QueryOption>({
        sortModel: [],
    })
    const navigate = useNavigate()
    const [hover, setHover] = useImmer<string | null | undefined>(null)
    const [rowOptions, setRowOptions] = useImmer({
        targetEl: null as null | HTMLButtonElement,
        open: false,
        deleteModal: false,
        rowId: null as null | string,
    })
    const deleteContact = useDeleteContact({
        onSuccess: () => {
            setRowOptions((draft) => {
                draft.deleteModal = false
                draft.rowId = null
            })
        },
    })
    const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
        setQueryOptions({ sortModel: [...sortModel] })
    }, [])
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 15,
    })
    const [contact, setContact] = useImmer({
        filter: false,
        filterColumns: [] as { id: string; label: string; type: string }[],
        filterOptions: {} as { [key: string]: FilterOptions },
    })
    const [rowSelectionModel, setRowSelectionModel] =
        useState<GridInputRowSelectionModel>()

    const contacts = useContacts({
        ...paginationModel,
        ...queryOptions,
        filters: Object.keys(contact.filterOptions).reduce((acc, key) => {
            acc[key] = contact.filterOptions[key].values
            return acc
        }, {} as Record<string, string | string[]>),
    })
    const [rowCountState, setRowCountState] = useState(
        contacts?.data?.rows?.count || 0
    )
    const filterButton = useRef(null)

    useEffect(() => {
        setRowCountState((prevRowCountState) =>
            contacts?.data?.rows?.count !== undefined
                ? contacts?.data?.rows?.count
                : prevRowCountState
        )
    }, [contacts?.data?.rows?.count, setRowCountState])

    if (contacts.isLoading) {
        return <Loading />
    }
    const columns: GridColDef[] = contacts.data.userTable.UserTableMeta.map(
        (meta: any, index: number): GridColDef => {
            return {
                field: meta.columnType === 'USER' ? `${meta.id}_data` : meta.id,
                headerName: meta.columnName,
                width: 150,
                flex: 1,
                renderCell: (params) => {
                    return (
                        <div className="relative w-full h-full gap-2 flex items-center">
                            {index === 0 && hover === params.row.id && (
                                <div className="absolute top-3.5 -left-2">
                                    <Button
                                        sx={{
                                            padding: '0px',
                                            minWidth: '10px',
                                        }}
                                        onClick={(e) => {
                                            setRowOptions((draft) => {
                                                draft.targetEl = e.target
                                                draft.open = true
                                                draft.rowId = params.row.id
                                            })

                                            e.stopPropagation()
                                        }}
                                        size="small"
                                    >
                                        <MoreVertOutlined
                                            sx={{
                                                fontSize: 18,
                                                color: 'black',
                                            }}
                                        />
                                    </Button>
                                </div>
                            )}
                            {meta.columnName === 'Contact' && (
                                <div className="pl-4">
                                    {' '}
                                    <AvatarComponent
                                        sx={{
                                            width: 24,
                                            height: 24,
                                            fontSize: 12,
                                        }}
                                        name={params.value}
                                    />
                                </div>
                            )}
                            <div className={index === 0 ? 'font-normal' : ''}>
                                {params.value}
                            </div>
                            {index === 0 && hover === params.row.id && (
                                <div className="absolute top-3 bg-white right-0">
                                    <Button
                                        size="small"
                                        className="drop-shadow-md bg-white hover:drop-shadow-md"
                                        sx={{
                                            '&.MuiButtonBase-root:hover': {
                                                bgcolor:
                                                    'rgba(42, 71, 116, 0.04)',
                                            },
                                        }}
                                    >
                                        Open
                                    </Button>
                                </div>
                            )}
                        </div>
                    )
                },
                disableReorder: index === 0 ? true : false,
            }
        }
    )
    const linkColumns: GridColDef[] = contacts.data.userTable.parentTable.map(
        (table: any): GridColDef => {
            return {
                field: `${table.id}_data`,
                headerName: table.name,
                flex: 1,

                width: 150,
            }
        }
    )
    const statColumns: GridColDef[] = [
        {
            field: `lastEngagementTime`,
            headerName: 'Last engaged',
            width: 150,
            flex: 1,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div>
                        {params.value
                            ? format(new Date(params.value), 'dd MMM yyyy')
                            : '-'}
                    </div>
                )
            },
        },
        {
            field: `linkCount`,
            headerName: 'Links shared',
            flex: 1,
            width: 150,
            sortable: false,
        },
        {
            field: `visits`,
            headerName: 'Visits',
            flex: 1,
            width: 150,
            sortable: false,
        },
    ]
    columns.push(...linkColumns, ...statColumns)
    function getColumn(field: string) {
        const column = columns.find((column: any) => field === column.field)
        return column?.headerName
    }
    function getOrderedColumn() {
        return columnOrder.map((orderedColumn: string) => {
            return columns.find((column) => column.headerName === orderedColumn)
        })
    }
    const rows: GridRowsProp = contacts.data.rows.data.map(
        (contact: any, index: number) => {
            return { id: index, ...contact }
        }
    )
    return (
        <div className="flex-1 flex w-full flex-col h-full">
            <div className="w-full pb-2 pr-4 pl-5">
                <div className="flex gap-4">
                    {queryOptions.sortModel && queryOptions.sortModel[0] && (
                        <>
                            <ActiveChip
                                icon={
                                    queryOptions.sortModel[0] &&
                                    queryOptions.sortModel[0].sort ===
                                        'desc' ? (
                                        <ArrowDownward color="primary" />
                                    ) : (
                                        <ArrowUpward color="primary" />
                                    )
                                }
                                onClick={() => {
                                    setQueryOptions({
                                        ...queryOptions,
                                        sortModel: [
                                            {
                                                ...queryOptions.sortModel[0],
                                                sort:
                                                    queryOptions.sortModel[0]
                                                        .sort === 'desc'
                                                        ? 'asc'
                                                        : 'desc',
                                            },
                                        ],
                                    })
                                }}
                                variant="outlined"
                                label={`${getColumn(
                                    queryOptions.sortModel[0].field
                                )} : ${
                                    queryOptions.sortModel[0].sort === 'desc'
                                        ? 'Descending'
                                        : 'Ascending'
                                }`}
                                onDelete={() => {
                                    setQueryOptions({ sortModel: [] })
                                }}
                            ></ActiveChip>
                            <Divider
                                orientation="vertical"
                                className="my-2"
                                flexItem
                            />
                        </>
                    )}
                    {contact.filterColumns.length > 0 && (
                        <div className="flex  gap-2">
                            {contact.filterColumns.map((column) => {
                                return (
                                    <Filter
                                        onDelete={() => {
                                            setContact((draft) => {
                                                const index =
                                                    contact.filterColumns.findIndex(
                                                        (item) =>
                                                            item.id ===
                                                            column.id
                                                    )
                                                delete draft.filterOptions[
                                                    column.id
                                                ]
                                                draft.filterColumns.splice(
                                                    index,
                                                    1
                                                )
                                            })
                                        }}
                                        onApply={(option) => {
                                            setContact((draft) => {
                                                draft.filterOptions[column.id] =
                                                    option
                                            })
                                        }}
                                        options={
                                            contact.filterOptions[column.id]
                                        }
                                        columnName={column.label}
                                        columnId={column.id}
                                        columnType={column.type}
                                    />
                                )
                            })}
                        </div>
                    )}
                    <div>
                        <Button
                            ref={filterButton}
                            onClick={() => {
                                setContact((draft) => {
                                    draft.filter = true
                                })
                            }}
                            size="small"
                            startIcon={<Add />}
                            variant="plain"
                        >
                            Filter
                        </Button>
                    </div>
                    {(contacts.isLoading || contacts.isFetching) && (
                        <div>
                            <Loading size="1.25rem" />
                        </div>
                    )}
                </div>
                <Popover
                    id={'filter-popover'}
                    open={contact.filter}
                    anchorEl={filterButton.current}
                    onClose={() => {
                        setContact((draft) => {
                            draft.filter = false
                        })
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div className="p-4">
                        <FormGroup>
                            {contacts.data.userTable.UserTableMeta.map(
                                (meta: any) => {
                                    return (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        contact.filterColumns.findIndex(
                                                            (item) =>
                                                                item.id ===
                                                                meta.id
                                                        ) > -1
                                                    }
                                                    onChange={() => {
                                                        setContact((draft) => {
                                                            const index =
                                                                draft.filterColumns.findIndex(
                                                                    (item) =>
                                                                        item.id ===
                                                                        meta.id
                                                                )
                                                            if (index > -1) {
                                                                draft.filterColumns.splice(
                                                                    index,
                                                                    1
                                                                )

                                                                delete draft
                                                                    .filterOptions[
                                                                    meta.id
                                                                ]
                                                            } else {
                                                                draft.filterColumns.push(
                                                                    {
                                                                        id: meta.id,
                                                                        label: meta.columnName,
                                                                        type: meta.columnType,
                                                                    }
                                                                )
                                                            }
                                                            draft.filter = false
                                                        })
                                                    }}
                                                />
                                            }
                                            label={meta.columnName}
                                        />
                                    )
                                }
                            )}
                            {contacts.data.userTable.parentTable.map(
                                (meta: any) => {
                                    return (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        contact.filterColumns.findIndex(
                                                            (item) =>
                                                                item.id ===
                                                                meta.id
                                                        ) > -1
                                                    }
                                                    onChange={() => {
                                                        setContact((draft) => {
                                                            const index =
                                                                contact.filterColumns.findIndex(
                                                                    (item) =>
                                                                        item.id ===
                                                                        meta.id
                                                                )
                                                            if (index > -1) {
                                                                draft.filterColumns.splice(
                                                                    index,
                                                                    1
                                                                )
                                                            } else {
                                                                draft.filterColumns.push(
                                                                    {
                                                                        id: meta.id,
                                                                        label: meta.name,
                                                                        type: meta.name,
                                                                    }
                                                                )
                                                            }
                                                            draft.filter = false
                                                        })
                                                    }}
                                                />
                                            }
                                            label={meta.name}
                                        />
                                    )
                                }
                            )}
                        </FormGroup>
                    </div>
                </Popover>
            </div>
            <div className="w-full px-5 pb-5 overflow-auto h-5/6">
                <DataGrid
                    rows={rows}
                    columns={getOrderedColumn()}
                    loading={contacts.isLoading}
                    rowCount={rowCountState}
                    sortingMode="server"
                    paginationMode="server"
                    pagination
                    slots={{
                        noRowsOverlay: () => (
                            <div className="w-full flex justify-center p-4">
                                <Button onClick={addContact}>
                                    Click here to add your first contact
                                </Button>
                            </div>
                        ),
                    }}
                    disableColumnMenu
                    sx={{ border: 0 }}
                    sortModel={queryOptions.sortModel}
                    paginationModel={paginationModel}
                    pageSizeOptions={[]}
                    componentsProps={{
                        row: {
                            onMouseEnter: (event) => {
                                const id = event.currentTarget.dataset.id
                                if (rowOptions.open) return
                                setHover(id)
                            },
                            onMouseLeave: () => {
                                if (rowOptions.open) return
                                setHover(null)
                            },
                        },
                    }}
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        console.log(newRowSelectionModel)
                        navigate(`/contacts/${newRowSelectionModel[0]}`)
                        setRowSelectionModel(newRowSelectionModel)
                    }}
                    rowSelectionModel={rowSelectionModel}
                    onPaginationModelChange={setPaginationModel}
                    onSortModelChange={handleSortModelChange}
                />
            </div>
            <Popover
                id="mouse-over-popover"
                open={rowOptions.open}
                anchorEl={rowOptions.targetEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClose={() => {
                    setRowOptions((draft) => {
                        draft.open = false
                    })
                }}
                disableRestoreFocus
            >
                <MenuList>
                    <MenuItem
                        onClick={() => {
                            setRowOptions((draft) => {
                                draft.deleteModal = true
                                draft.open = false
                            })
                        }}
                    >
                        <ListItemIcon>
                            <DeleteOutlineOutlined />
                        </ListItemIcon>
                        <ListItemText>Delete</ListItemText>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            navigate(`/contacts/${rowOptions.rowId}`)
                        }}
                    >
                        <ListItemIcon>
                            <CallMadeRounded />
                        </ListItemIcon>
                        <ListItemText>Open</ListItemText>
                    </MenuItem>
                </MenuList>
            </Popover>
            <Dialog
                open={rowOptions.deleteModal}
                keepMounted
                onClose={() => {
                    setRowOptions((draft) => {
                        draft.deleteModal = false
                    })
                }}
            >
                <DialogTitle>Delete account</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Do you want to delete this contact permanently?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            if (!rowOptions.rowId) return
                            deleteContact.mutate({
                                id: rowOptions.rowId,
                            })
                        }}
                        disabled={deleteContact.isLoading}
                        startIcon={
                            deleteContact.isLoading && (
                                <Loading size="1.25rem" />
                            )
                        }
                        variant="contained"
                        color="error"
                    >
                        Delete
                    </Button>
                    <Button
                        onClick={() => {
                            setRowOptions((draft) => {
                                draft.deleteModal = false
                            })
                        }}
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
