import { useMutation, useQuery, useQueryClient } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'
import isEmpty from 'lodash.isempty'

export default function useGetAsset(props: {
    id: string
    onSuccess?: any
    data?: any
}) {
    return useQuery<any, Error, string>(
        ['assets', props.id, 'details'],
        async () => {
            const result = await axios.get(`${APi_URL}/assets/${props.id}`, {
                headers: { 'Content-Type': 'application/json' },
            })
            return result.data
        },
        {
            staleTime: 0,
            onError: (e) => {
                console.log(e)
            },
            onSuccess: (data) => {
                if (props.onSuccess) {
                    props.onSuccess(data)
                }
            },
            initialData: () => {
                if (props.data && !isEmpty(props.data)) {
                    return props.data
                }
            },
        }
    )
}
