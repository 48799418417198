import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'
import { UpdateUserAccount } from '@Server/updateUserAccount'
export default function useSaveUserAccount(props: { onSuccess: () => void }) {
    const queryClient = useQueryClient()
    return useMutation<any, Error, UpdateUserAccount>(
        (obj) =>
            axios.post(`${APi_URL}/user`, obj, {
                headers: { 'Content-Type': 'application/json' },
            }),
        {
            onSuccess: (data: any, obj: any) => {
                queryClient.invalidateQueries(['init'])
                props.onSuccess()
            },
        }
    )
}
