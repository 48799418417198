import { useQuery } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'
import { getColor } from '@Common/Color/colors'

const getCustomAttribute = async () => {
    const { data: customAttribute } = await axios.get(
        `${APi_URL}/customAttributes`,
        { headers: { 'Content-Type': 'application/json' } }
    )

    const finalData = customAttribute.map((ele: any) => {
        return {
            default: ele.default,
            description: ele.description,
            sensitive: ele.sensitive,
            id: ele.id,
            name: ele.name,
            typeId: ele.typeId,
            type: ele.CustomAttributeType.type,
            userId: ele.userId,
            option: ele.CustomAttributeValue.map(
                (customValues: any, id: number) => {
                    if (customValues.attributeId == ele.id) {
                        return {
                            ...customValues,
                            color: getColor(id),
                        }
                    }
                }
            ),
        }
    })

    const data = {
        data: finalData,
    }
    return data
}

export default function useCustomAttribute() {
    return useQuery('customAttribute', getCustomAttribute)
}
