import AddLinkIcon from '@mui/icons-material/AddLink'
import FormatBoldIcon from '@mui/icons-material/FormatBold'
import FormatItalicIcon from '@mui/icons-material/FormatItalic'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import RedoIcon from '@mui/icons-material/Redo'
import UndoIcon from '@mui/icons-material/Undo'
import ImageIcon from '@mui/icons-material/Image'
import {
    wrapInList,
    splitListItem,
    liftListItem,
    sinkListItem,
} from 'prosemirror-schema-list'

import {
    Button,
    Divider,
    Menu,
    MenuItem,
    Tab,
    Tabs,
    TextField,
    ToggleButton,
    Typography,
} from '@mui/material'
import { setBlockType, toggleMark, wrapIn } from 'prosemirror-commands'
import { redo, undo } from 'prosemirror-history'
import { EditorState } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import { Updater, useImmer } from 'use-immer'

import PopoverComponent from '@Common/Components/PopoverComponent'
import { FromTo } from 'prosemirror-autocomplete'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { MarkType, NodeType } from 'prosemirror-model'
import { LinkOutlined } from '@mui/icons-material'
import isEmpty from 'lodash.isempty'
import { TabPanel } from '@Common/Components/TabPanel'
import EditorImageUpload from './EditorImageUpload'

interface MenuListProps {
    editor: EditorState
    view: EditorView
    setPicker: Updater<{
        filter: string
        open: boolean
        current: number
        view: EditorView
        range: FromTo
        addThumbnail: boolean
        anchorEl: HTMLDivElement | null
    }>
}
export function CustomMenuBar(props: MenuListProps) {
    const { editor, view, setPicker } = props
    const schema = editor.schema
    const [heading, setHeading] = useImmer({
        open: false,
        anchorEl: null,
    })
    const [link, setLink] = useImmer({
        open: false,
        anchorEl: null,
    })
    const [image, setImage] = useImmer({
        open: false,
        anchorEl: null,
    })

    const [href, setHref] = useImmer(null)
    const [open, setOpen] = useImmer(false)
    const handleChange = (event) => {
        setHeading(event.target.value)
        if (event.target.value === 'text') {
            setBlockType(schema.nodes.text)(editor, view.dispatch)
        } else {
            setBlockType(schema.nodes.heading, {
                level: event.target.value as string,
            })(editor, view.dispatch)
        }
        setOpen(false)
    }
    function markItem(markType) {
        const { from, $from, to, empty } = editor.selection

        if (empty) return false
        else return editor.doc.rangeHasMark(from, to, markType)
    }
    function isOrderedList(state: EditorState) {
        if (!state) {
            return false
        }
        let { from, $from, to, empty } = state.selection
        console.log(state.doc.type)
    }
    function markActive(state: EditorState, type: MarkType) {
        if (!state) {
            return false
        }
        let { from, $from, to, empty } = state.selection
        if (empty) return !!type.isInSet(state.storedMarks || $from.marks())
        else return state.doc.rangeHasMark(from, to, type)
    }
    function isHeading(value) {
        const { from, $from, to, empty } = editor.selection

        if (empty) return false
        else
            return editor.doc.nodesBetween(
                from,
                to,
                (selectedNode, position) => {
                    const node = selectedNode
                    if (
                        node.type.name == 'heading' &&
                        node.attrs.level === value
                    ) {
                        return true
                    } else {
                        return false
                    }
                }
            )
    }
    const handleKeyDown = (event) => {
        setHref((draft) => {
            return event.target.value
        })
        if (['Enter', 'Tab'].includes(event.key)) {
            if (href != null) {
                toggleMark(view.state.schema.marks.link, {
                    href,
                    title: 'Link',
                })(view.state, view.dispatch)
            }
            setLink((draft) => {
                return {
                    open: false,
                    anchorEl: null,
                }
            })
        }
    }

    return (
        <>
            <PopoverComponent state={heading} setState={setHeading}>
                <MenuItem
                    value={'1'}
                    onClick={handleChange}
                    // selected={isHeading('1')}
                >
                    {'Heading 1'}
                </MenuItem>
                <MenuItem
                    value={'2'}
                    onClick={handleChange}
                    // selected={isHeading('2')}
                >
                    {'Heading 2'}
                </MenuItem>

                <MenuItem
                    value={'3'}
                    onClick={handleChange}
                    // selected={isHeading('3')}
                >
                    {'Heading 3'}
                </MenuItem>
                <MenuItem
                    value={'4'}
                    onClick={handleChange}
                    // selected={isHeading('4')}
                >
                    {'Heading 4'}
                </MenuItem>
                <MenuItem
                    value={'5'}
                    onClick={handleChange}
                    //selected={isHeading('5')}
                >
                    {'Heading 5'}
                </MenuItem>
                <MenuItem
                    value={'text'}
                    onClick={handleChange}
                    //selected={isHeading('text')}
                >
                    {'Text'}
                </MenuItem>
            </PopoverComponent>
            <PopoverComponent state={link} setState={setLink}>
                <div className="p-2 w-96">
                    <TextField
                        placeholder="Paste a link"
                        size="small"
                        onKeyDown={(e) => {
                            handleKeyDown(e)
                        }}
                        className="w-full"
                    ></TextField>
                </div>
            </PopoverComponent>
            <PopoverComponent state={image} setState={setImage}>
                <EditorImageUpload
                    view={view}
                    editor={editor}
                    setImage={setImage}
                ></EditorImageUpload>
            </PopoverComponent>

            <Divider></Divider>
            <div className="menubar gap-2 flex w-full align-center justify-center">
                <ToggleButton
                    value="undo"
                    size="small"
                    onClick={() => {
                        undo(editor, view.dispatch)
                    }}
                >
                    <UndoIcon></UndoIcon>
                </ToggleButton>
                <ToggleButton
                    value="redo"
                    size="small"
                    onClick={() => {
                        redo(editor, view.dispatch)
                    }}
                >
                    <RedoIcon></RedoIcon>
                </ToggleButton>
                <Button
                    value={heading}
                    onChange={handleChange}
                    size="small"
                    variant="plain"
                    onClick={(e) => {
                        setHeading((draft) => {
                            return { open: true, anchorEl: e.currentTarget }
                        })
                    }}
                    endIcon={
                        <ArrowDropDownIcon
                            fontSize="small"
                            color="action"
                        ></ArrowDropDownIcon>
                    }
                >
                    Heading
                </Button>
                <ToggleButton
                    size="small"
                    value="Bold"
                    selected={markItem(schema.marks.strong)}
                    onClick={() => {
                        toggleMark(schema.marks.strong)(editor, view.dispatch)
                    }}
                >
                    <FormatBoldIcon></FormatBoldIcon>
                </ToggleButton>
                <ToggleButton
                    value="Italics"
                    size="small"
                    selected={markItem(schema.marks.em)}
                    onClick={() => {
                        toggleMark(schema.marks.em)(editor, view.dispatch)
                    }}
                >
                    <FormatItalicIcon></FormatItalicIcon>
                </ToggleButton>
                <ToggleButton
                    onClick={(e) => {
                        setImage((draft) => {
                            return { open: true, anchorEl: e.currentTarget }
                        })
                    }}
                    value="Image"
                >
                    <ImageIcon />
                </ToggleButton>
                <ToggleButton
                    selected={markActive(editor, schema.marks.link)}
                    disabled={
                        view &&
                        view.state.selection.from === view.state.selection.to
                    }
                    onClick={(e) => {
                        const state = view.state
                        const markType = state.schema.marks.link
                        const dispatch = view.dispatch
                        if (markActive(state, markType)) {
                            toggleMark(markType)(state, dispatch)
                            return true
                        }
                        setLink((draft) => {
                            return {
                                open: true,
                                anchorEl: e.currentTarget,
                            }
                        })
                    }}
                    value="link"
                >
                    <LinkOutlined />
                </ToggleButton>

                <ToggleButton
                    value="Ordered List"
                    size="small"
                    onClick={() => {
                        wrapInList(schema.nodes.ordered_list)(
                            editor,
                            view.dispatch
                        )
                    }}
                >
                    <FormatListNumberedIcon></FormatListNumberedIcon>
                </ToggleButton>
                <ToggleButton
                    value="Bullet List"
                    size="small"
                    onClick={() => {
                        wrapInList(schema.nodes.bullet_list)(
                            editor,
                            view.dispatch
                        )
                    }}
                >
                    <FormatListBulletedIcon></FormatListBulletedIcon>
                </ToggleButton>
            </div>
            <Divider></Divider>
        </>
    )
}
