import { useQuery, useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'

export default function useSearch(props?: any) {
    const queryClient = useQueryClient()
    return useQuery(
        ['search', props],
        () =>
            axios.post(`${APi_URL}/search`, props, {
                headers: { 'Content-Type': 'application/json' },
            }),
        {
            onSuccess: (data: any) => {
                if (props.onSuccess) {
                    props.onSuccess(data)
                }
            },
            keepPreviousData : true
        }
    )
}
