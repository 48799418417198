import { useQuery, useMutation } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'

export default function useUploadFile(props: any) {
    return useMutation<any, Error, object>(
        (obj: any) => {
            const options = props.option(obj)
            return axios.create().post(
                obj?.url.url,
                { ...obj.url.fields, file: obj?.body },
                {
                    ...options,
                    headers: { ...options.headers, Accept: 'application/json' },
                }
            )
        },
        {
            onSuccess: props.onSuccess,
            onError: props.onError,
        }
    )
}
