import { useMutation, useQuery } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'

export default function useDomainAvailable(domain: string) {
    return useQuery<any, Error, string>(
        ['domain', 'available', domain],
        async () => {
            const result = await axios.get(
                `${APi_URL}/domain/available/${domain}`,
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            )
            return result.data
        }
    )
}
