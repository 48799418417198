import classNames from 'classnames'
import { ActionKind, defaultReducer, FromTo } from 'prosemirror-autocomplete'
import React, { useRef } from 'react'
import { EditorView } from 'prosemirror-view'

export const Suggestion: React.FC<{
    picker: {
        filter: string
        open: boolean
        current: number
        view: EditorView
        range: FromTo
    }
    schema: any
    item: { id: string; fileId: string }
    view: EditorView
    index: number
}> = ({ picker, schema, children, item, view, index }) => {
    const ref = useRef<HTMLDivElement>(null)
    const chooseSelection = () => {
        const node = view.state.schema.nodes.asset.create({
            id: item.id,
            fileId: item.fileId,
        })

        console.log('From index', index)

        const tr = view.state.tr
            .deleteRange(picker.range.from, picker.range.to)
            .insert(picker.range.from, node)
        view.dispatch(tr)
    }
    return (
        <div
            className={classNames(
                'p-2 hover:cursor-pointer active:bg-gray-200',
                {
                    'bg-gray-200': index === picker.current,
                }
            )}
            onClick={chooseSelection}
            ref={ref}
        >
            {children}
        </div>
    )
}
