import { Node as ProseMirrorNode } from 'prosemirror-model'
import { NodeView, EditorView } from 'prosemirror-view'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import AssetThumbnail from './AssetThumbnail'
export class AssetNodeView implements NodeView {
    public dom: HTMLElement

    constructor(public node: ProseMirrorNode, public view: EditorView) {
        const dom = document.createElement('div')
        this.dom = dom

        this.render()
    }

    destroy(): void {
        ReactDOM.unmountComponentAtNode(this.dom)
    }

    render = (): void => {
        const attrs = this.node.attrs
        const queryClient = new QueryClient()
        ReactDOM.render(
            <QueryClientProvider client={queryClient}>
                <AssetThumbnail
                    id={attrs.id}
                    fileId={attrs.fileId}
                    src={attrs.src}
                    view={this.view}
                />
            </QueryClientProvider>,
            this.dom
        )
    }
}
