import { format, isValid, parseISO } from 'date-fns'
import { QueryObserverIdleResult } from 'react-query'

export const formattedDate = (input: string) => {
    const parsedFormat = parseISO(input)

    return isValid(parsedFormat) ? format(parsedFormat, 'MMM dd, yyyy') : input
}

export const formattedDateWithTime = (input: string) => {
    const parsedFormat = parseISO(input)

    return isValid(parsedFormat)
        ? format(parsedFormat, 'MMM dd, yyyy HH:mm')
        : input
}

export const formatDateFromCalendar = (input: Date) => {
    const parsedFormat = format(input, 'MMM dd, yyyy')

    return parsedFormat
}

export const getUser = (
    userId: string,
    ownerData: { option: Array<{ value: string; name: string; label: string }> }
) => {
    if (ownerData && ownerData.option) {
        const user = ownerData.option.find((e) => e.value === userId)
        if (user !== undefined) {
            return user.label
        }
    }
    return 'Unknown user'
}

export const canDownloadAndView = (action: string) => {
    return ['FULL_ACCESS', 'EDIT', 'VIEW'].includes(action)
}
export const requestAccessForAsset = (action: string) => {
    return action === 'SEARCH'
}
export const formatDateWithTime = (input: string) => {
    const parsedFormat = parseISO(input)

    return isValid(parsedFormat) ? format(parsedFormat, 'PPpp') : input
}
export const canEdit = (action?: string) => {
    if (!action) {
        return false
    }
    return ['FULL_ACCESS', 'EDIT'].includes(action)
}

export const isFullAccess = (action?: string) => {
    if (!action) {
        return false
    }
    return action === 'FULL_ACCESS'
}

export const summaryEnabled = (asset) => {
    if (!asset || !canEdit(asset.action) || !asset.AssetProgress) {
        return {
            message: '',
            show: false,
            enabled: false,
        }
    }
    const contentGeneration = asset.AssetProgress.find(
        (e) => e.statusType === 'CONTENT'
    )
    if (
        contentGeneration &&
        ['INIT', 'INPROGRESS'].includes(contentGeneration.status)
    ) {
        return {
            message:
                'Content extraction under process. Feel free to work on other things while we are on it',
            show: true,
            enabled: false,
        }
    }
    const summaryGeneration = asset.AssetProgress.find(
        (e) => e.statusType === 'SUMMARY'
    )
    if (summaryGeneration) {
        switch (summaryGeneration.status) {
            case 'INIT':
            case 'INPROGRESS':
                return {
                    message:
                        'Summary generation under process. Feel free to work on other things while we are on it',
                    show: true,
                    enabled: false,
                }
            case 'COMPLETED':
                return {
                    message: '',
                    show: false,
                    enabled: false,
                }

            case 'ERROR':
                return {
                    message:
                        'Something went wrong while generating the summary.Please try again',
                    show: true,
                    enabled: true,
                }
            default:
                return {
                    message: '',
                    show: true,
                    enabled: true,
                }
        }
    }
    return {
        message: '',
        show: true,
        enabled: true,
    }
}

export const autoTagEnabled = (asset, customAttribute) => {
    if (
        !asset ||
        !canEdit(asset.action) ||
        !asset.AssetProgress ||
        !customAttribute ||
        customAttribute.length <= 0 ||
        !asset.AssetCustomAttribute ||
        asset.AssetCustomAttribute.length != 0
    ) {
        return {
            message: '',
            show: false,
            enabled: false,
        }
    }
    const contentGeneration = asset.AssetProgress.find(
        (e) => e.statusType === 'CONTENT'
    )
    if (
        contentGeneration &&
        ['INIT', 'INPROGRESS'].includes(contentGeneration.status)
    ) {
        return {
            message:
                'Content extraction under process. Feel free to work on other things while we are on it',
            show: true,
            enabled: false,
        }
    }
    const tagGeneration = asset.AssetProgress.find(
        (e) => e.statusType === 'AUTO_TAG'
    )
    if (tagGeneration) {
        switch (tagGeneration.status) {
            case 'INIT':
            case 'INPROGRESS':
                return {
                    message:
                        'Tag generation under process. Feel free to work on other things while we are on it',
                    show: true,
                    enabled: false,
                }
            case 'COMPLETED':
                return {
                    message: '',
                    show: false,
                    enabled: false,
                }

            case 'ERROR':
                return {
                    message:
                        'Something went wrong while generating the tags.Please try again',
                    show: true,
                    enabled: true,
                }
            default:
                return {
                    message: '',
                    show: true,
                    enabled: true,
                }
        }
    }
    return {
        message: '',
        show: true,
        enabled: true,
    }
}
export const canDelete = (action?: string) => {
    if (!action) {
        return false
    }
    return 'FULL_ACCESS' === action
}
