import { fontAwesomConfig } from '@Common/Types/configs/fontAwesomConfig'
import { faFile } from '@fortawesome/free-solid-svg-icons'
const makeIcon = (name: any) => {
    const ix = name.lastIndexOf('.')
    if (ix <= 0 && fontAwesomConfig.fsIconByName[name.toLowerCase()]) {
        return fontAwesomConfig.fsIconByName[name.toLowerCase()]
    } else if (
        ix > 0 &&
        fontAwesomConfig.iconByExtension[name.substring(ix + 1).toLowerCase()]
    ) {
        const ext = name.substring(ix + 1).toLowerCase()
        return fontAwesomConfig.iconByExtension[ext]
    } else {
        return { name: faFile, color: '#E0CFF3' }
    }
}

export default makeIcon
