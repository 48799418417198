import { useQuery } from "react-query";
import axios from "axios";
import { APi_URL } from "@Common/Types/configs/urlConfig";


const getRoles = async () => {
    const { data } = await axios.get(
        `${APi_URL}/roles`,
        { headers: { 'Content-Type': 'application/json' } }
    )
    return {
        data: data,
        options: data.map((role: any) => ({
            value: role.id,
            label: role.role,
            name: role.role,
            key: "role",
        }))
    };
};

export default function useRoles() {
    return useQuery("Roles", getRoles);
}
