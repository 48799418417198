import useAddCustomAttributeValuesToAsset, {
    AddCustomAttributeToAsset,
} from '@Common/Queries/MutationQueries/AddCustomAttributeToAsset'
import useRemoveCustomAttFromAsset from '@Common/Queries/MutationQueries/RemoveCustomAttributeFromAsset'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import { Autocomplete, styled, TextField } from '@mui/material'
import isEmpty from 'lodash.isempty'
import { FC } from 'react'

const SingleSelectAttribute: FC<{
    assetValue: {
        id: string
        value: string
    }
    attribute: {
        id: string
        name: string
        type: string
    }
    assetId: string
    assetName: string
    assetCustomAttribute: {
        id: string
        attributeValue: {
            id: string
            value: string
            attributeId: string
        }
    }
    options: Array<{
        id: string // this is the value id
        value: string
    }>
}> = ({
    assetValue,
    attribute,
    assetId,
    assetName,
    assetCustomAttribute,
    options,
}) => {
    const addCustomAttributeUpdaterFunction = (
        previousAsset: {
            AssetCustomAttribute: Array<{
                id: string
                name: string
                assetAttributeValues: Array<Record<string, unknown>>
            }>
            UserInputCustomAttributes: Array<{
                id: string
                name: string
                assetAttributeValues: Record<string, unknown>
            }>
        },
        currentValue: AddCustomAttributeToAsset
    ) => {
        let assetCustomAttribute: Array<Record<string, unknown>> =
            previousAsset.AssetCustomAttribute
        const index = assetCustomAttribute.findIndex(
            (e) => e.id === currentValue.attribute.id
        )
        let values: Array<Record<string, unknown>> = []
        if (index != -1) {
            values = Object.assign(
                [],
                assetCustomAttribute[index].assetAttributeValues
            )

            values[0] = {
                attributeValue: {
                    id: currentValue.attributeValue?.id,
                    value: currentValue.attributeValue?.value,
                    attributeId: currentValue.attribute.id,
                },
                id: '',
            }
            assetCustomAttribute[index] = {
                ...currentValue.attribute,
                assetAttributeValues: values,
            }
        } else {
            values.push({
                attributeValue: {
                    id: currentValue.attributeValue?.id,
                    value: currentValue.attributeValue?.value,
                    attributeId: currentValue.attribute.id,
                },
            })
            assetCustomAttribute = [
                ...assetCustomAttribute,
                {
                    ...currentValue.attribute,
                    assetAttributeValues: values,
                },
            ]
        }
        const finalModifiedAsset = previousAsset

        finalModifiedAsset.AssetCustomAttribute = assetCustomAttribute
        return finalModifiedAsset
    }
    const removeCustomAttributeUpdaterFunction = (
        previousAsset: {
            AssetCustomAttribute: Array<{
                id: string
                name: string
                assetAttributeValues: Array<Record<string, unknown>>
            }>
            UserInputCustomAttributes: Array<{
                id: string
                name: string
                assetAttributeValues: Record<string, unknown>
            }>
        },
        currentValue: AddCustomAttributeToAsset
    ) => {
        const assetCustomAttribute: Array<Record<string, unknown>> =
            previousAsset.AssetCustomAttribute
        const index = assetCustomAttribute.findIndex(
            (e) => e.id === currentValue.attribute.id
        )
        if (index != -1) {
            const values = assetCustomAttribute[index].assetAttributeValues
            if (values.length === 1) {
                assetCustomAttribute.splice(index, 1)
            } else {
                const valueIndex = values.find(
                    (e) =>
                        e.attributeValue.id === currentValue.attributeValue?.id
                )
                values.splice(valueIndex, 1)
            }
            assetCustomAttribute[index] = {
                ...currentValue,
                assetAttributeValues: values,
            }
            return assetCustomAttribute
        }
        return assetCustomAttribute
    }
    const sendDataToGTM = useGTMDispatch()
    const addCustomAttributeValuesToAsset = useAddCustomAttributeValuesToAsset({
        addCustomAttributeUpdaterFunction: addCustomAttributeUpdaterFunction,
        onSuccess: () => {
            sendDataToGTM({
                event: 'asset_edit',
                asset_id: assetId,
                asset_name: assetName,
                attribute_name: attribute.name,
            })
        },
    })
    const removeCustomAttributeValuesToAsset = useRemoveCustomAttFromAsset({
        removeCustomAttributeUpdaterFunction:
            removeCustomAttributeUpdaterFunction,
        onSuccess: () => {
            sendDataToGTM({
                event: 'asset_edit',
                asset_id: assetId,
                asset_name: assetName,
                attribute_name: attribute.name,
            })
        },
    })

    const handleChange = (
        event: React.SyntheticEvent,
        value: {
            id: string
            value: string
        },
        reason: string
    ) => {
        if (reason === 'selectOption') {
            addCustomAttributeValuesToAsset.mutate({
                assetId: assetId,
                attribute: attribute,
                attributeValue: value,
            })
        } else if (reason === 'clear') {
            removeCustomAttributeValuesToAsset.mutate({
                assetId: assetId,
                attribute: attribute,
                id: assetCustomAttribute.id,
                attributeValue: assetValue,
            })
        }
    }
    return (
        <Autocomplete
            open
            renderInput={(params) => (
                <TextField {...params} size="small" variant="filled" />
            )}
            freeSolo={false}
            disableCloseOnSelect={true}
            options={options}
            value={!isEmpty(assetValue) ? assetValue : null}
            filterSelectedOptions
            isOptionEqualToValue={(option, value) => {
                if (value) {
                    return option.value === value.value
                }
                return false
            }}
            ListboxProps={{
                style: {
                    fontSize: 14,
                },
            }}
            onChange={handleChange}
            getOptionLabel={(option) => {
                return option.value
            }}
        ></Autocomplete>
    )
}

export default SingleSelectAttribute
