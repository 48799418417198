import { useDeleteAssetSection } from '@Common/Queries/Dealroom/DeleteAssetSection'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useDealRoomColor } from 'src/Stores/DealRoomTheme'
import { formattedDate } from 'src/ViewAssetScreen/util'
import { useImmer } from 'use-immer'

const DealRoomSectionAsset = (props: {
    sectionId: string
    access: string
    sectionAsset: {
        id: string
        addedBy: string
        User: {
            id: string
            name: string
            updatedAt: string
        }
        Asset: {
            name: string
        }
        updatedAt: string
    }
    thumbnail: Array<{
        id: string
        thumbnailUrl: string
    }>
}) => {
    const [style, setStyle] = useImmer({
        visibility: 'hidden',
    })
    const color = useDealRoomColor((state) => state.color)
    const url = props.thumbnail?.filter(
        (e) => e.id === props.sectionAsset.Asset.id
    )
    const { id } = useParams()
    const deleteAssetSection = useDeleteAssetSection()
    const assetDetailstext = `Added on ${formattedDate(
        props.sectionAsset.updatedAt
    )}`
    return (
        <Box
            className="flex flex-col w-full h-56 2xl:h-64 3xl:h-72 4xl:h-80"
            sx={{
                '&:hover': {
                    cursor: 'pointer',
                    transform: 'scale(1.05)',
                },
            }}
        >
            <div
                className="flex gap-4 flex-col w-full h-full overflow-hidden"
                onMouseEnter={(e) => {
                    setStyle((draft) => {
                        draft.visibility = 'visible'
                    })
                }}
                onMouseLeave={(e) => {
                    setStyle((draft) => {
                        draft.visibility = 'hidden'
                    })
                }}
            >
                <div
                    className="h-36 2xl:h-44 3xl:h-52 4xl:h-60 border overflow-hidden"
                    style={{
                        objectFit: 'inherit',
                    }}
                >
                    <img
                        className="w-full"
                        src={url[0].thumbnailUrl}
                    ></img>
                </div>
                <div className="flex flex-row">
                    <div className="flex flex-col gap-1 flex-1 w-full overflow-hidden">
                        <Tooltip title={props.sectionAsset.Asset.name}>
                            <Typography className="whitespace-nowrap overflow-hidden text-ellipsis text-base">
                                {props.sectionAsset.Asset.name}
                            </Typography>
                        </Tooltip>
                        <Tooltip title={assetDetailstext}>
                            <Typography
                                color="text.secondary"
                                className="whitespace-nowrap overflow-hidden text-ellipsis text-xs"
                            >
                                {assetDetailstext}
                            </Typography>
                        </Tooltip>
                    </div>
                    <div>
                        {props.access != 'VIEW' && (
                            <IconButton
                                data-testid="dealroom-delete-asset-btn"
                                onClick={() => {
                                    deleteAssetSection.mutate({
                                        id: id,
                                        body: {
                                            sectionAssetId:
                                                props.sectionAsset.id,
                                            sectionId: props.sectionId,
                                        },
                                    })
                                }}
                            >
                                <DeleteOutlineOutlinedIcon
                                    color="disabled"
                                    style={style}
                                ></DeleteOutlineOutlinedIcon>
                            </IconButton>
                        )}
                    </div>
                </div>
            </div>
        </Box>
    )
}

export default DealRoomSectionAsset
