import AddUsersModal from '@Common/Components/Modals/AddUsersModal'
import useDebounce from '@Common/hooks/useDebounce'
import useRoles from '@Common/Queries/GetQueries/GetRoles'
import { APi_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useImmer } from 'use-immer'
import Users from './Users'

const ManageUsers: React.FC = () => {
    const [filter, setFilter] = useImmer({
        searchTerm: '',
        currentPage: 0,
        pageSize: 10,
    })
    const debouncedFilter = useDebounce(filter)
    const {
        isLoading: usersLoading,
        data: usersData,
        refetch: users,
    } = useQuery(['Users', debouncedFilter], async () => {
        const data = await axios.post(`${APi_URL}/users/search`, filter, {
            headers: { 'Content-Type': 'application/json' },
        })
        return data.data
    })
    const { data: rolesData, isLoading: isRolesLoading } = useRoles()
    const [addUser, setAddUser] = useState(false)

    return (
        <div className="w-full h-full flex flex-col">
            {!usersLoading && !isRolesLoading && addUser && (
                <AddUsersModal
                    roles={rolesData}
                    addUser={addUser}
                    setAddUser={setAddUser}
                />
            )}
            <div className="grid grid-cols-2 rounded-3px bg-white border-b pb-4">
                <div className="pt-5 pb-2 ml-4">
                    <div className="flex flex-row w-5/12  bg-searchBarColor backdrop-opacity-50 px-5 rounded-lg py-1">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            aria-hidden="true"
                            className="h-6 w-6  text-searchBarSvg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                            ></path>
                        </svg>
                        <input
                            type="text"
                            className="justify-center bg-searchBarColor w-full px-4 outline-none text-14px font-medium placeholder:text-searchPlaceholderColor placeholder:text-12px font-Segoe  text-themeColor "
                            placeholder="Search by email or name"
                            onChange={(e: any) =>
                                setFilter((draft) => {
                                    draft.searchTerm = e.target.value
                                    draft.currentPage = 0
                                })
                            }
                        />
                    </div>
                </div>
                <div className=" self-center pr-3 ml-auto">
                    <button
                        className="py-1 px-3 border rounded bg-addUser w-28"
                        onClick={() => {
                            setAddUser(true)
                        }}
                    >
                        <span className="text-sm text-white">Add Users</span>
                    </button>
                </div>
            </div>
            <Users
                filter={filter}
                setFilter={setFilter}
                refetch={users}
                usersLoading={usersLoading}
                usersData={usersData}
                rolesLoading={isRolesLoading}
                rolesData={rolesData}
            />
        </div>
    )
}

export default ManageUsers

const style = {
    mainContainer:
        ' h-full flex flex-col overflow-hidden bg-bgColor px-4 pt-4 rounded-md font-Segoe',
    display: ' h-full flex flex-col overflow-hidden rounded-md ',
    btnApprove:
        'py-2 px-6 bg-gradient rounded-sm text-white text-sm font-bold  ',
}
