import { useQuery } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'

export default function usePreviewDownload(props: { id: string }) {
    return useQuery<any, Error, string>(
        ['assets', 'preview', 'download', props.id],
        async () => {
            const result = await axios.get(
                `${APi_URL}/download/preview/${props.id}`,
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            )
            return result.data
        },
        {
            staleTime: 100000,
            cacheTime: 100000,
            refetchOnWindowFocus: false,
            onError: (e) => {
                console.log(e)
            },
        }
    )
}
