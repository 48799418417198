import CreateGroupModal from '@Common/Components/Modals/CreateGroupModal'
import useDebounce from '@Common/hooks/useDebounce'
import useInit from '@Common/Queries/GetQueries/useInit'
import { APi_URL } from '@Common/Types/configs/urlConfig'
import { Button } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useImmer } from 'use-immer'
import Groups from './Group'
// import Users from './Users'

const ManageGroups: React.FC = () => {
    const [open, setOpen] = useState(false)

    const [manageGroup, setManageGroup] = useState({
        isOpen: false,
        data: {},
        type: '',
    })
    const [filter, setFilter] = useImmer({
        searchTerm: '',
        currentPage: 0,
        pageSize: 10,
    })
    const debouncedFilter = useDebounce(filter)
    const {
        isLoading: groupsLoading,
        data: groupsData,
        refetch: groups,
    } = useQuery(['Groups', debouncedFilter], async () => {
        const data = await axios.post(`${APi_URL}/groups/search`, filter, {
            headers: { 'Content-Type': 'application/json' },
        })

        return data.data
    })

    const init = useInit()
    const userRoles = init.data.user.UserRole.map((userRole) =>
        userRole.role.role.toLowerCase()
    )
    const isAdmin =
        userRoles.includes(import.meta.env.VITE_SUPER_ADMIN_USER) ||
        userRoles.includes(import.meta.env.VITE_ADMIN_USER)

    return (
        <div className="w-full h-full flex flex-col">
            {manageGroup.isOpen && (
                <CreateGroupModal
                    setManageGroup={setManageGroup}
                    manageGroup={manageGroup}
                />
            )}
            <div className="grid grid-cols-2 rounded-3px bg-white border-b pb-4">
                <div className="pt-5 pb-2 ml-4">
                    <div className="flex flex-row w-5/12  bg-searchBarColor backdrop-opacity-50 px-5 rounded-lg py-1">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            aria-hidden="true"
                            className="h-6 w-6  text-searchBarSvg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                            ></path>
                        </svg>
                        <input
                            type="text"
                            className="justify-center bg-searchBarColor w-full px-4 outline-none text-14px font-medium placeholder:text-searchPlaceholderColor placeholder:text-12px font-Segoe  text-themeColor "
                            placeholder="Search for a group"
                            onChange={(e: any) =>
                                setFilter((draft) => {
                                    draft.searchTerm = e.target.value
                                    draft.currentPage = 0
                                })
                            }
                        />
                    </div>
                </div>
                <div className=" self-center pr-3 ml-auto">
                    {isAdmin && (
                        <Button
                            variant="contained"
                            onClick={() => {
                                manageGroup.isOpen = true
                                manageGroup.type = 'create'
                                setManageGroup({ ...manageGroup })
                            }}
                        >
                            Add Groups
                        </Button>
                    )}
                </div>
            </div>
            <Groups
                filter={filter}
                setFilter={setFilter}
                refetch={groups}
                groupsLoading={groupsLoading}
                groupsData={groupsData}
            />
        </div>
    )
}

export default ManageGroups

const style = {
    mainContainer:
        ' h-full flex flex-col overflow-hidden bg-bgColor px-4 pt-4 rounded-md font-Segoe',
    display: ' h-full flex flex-col overflow-hidden rounded-md ',
}
