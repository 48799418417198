import { formattedDate } from '@Request/components/common/Functions'
import {
    Card,
    CardContent,
    CardMedia,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import VisibilityIcon from '@mui/icons-material/Visibility'
import useDownload from '@Common/Queries/MutationQueries/getFileDownload'
import { useImmer } from 'use-immer'
import imageIcon from './../../Icons/image.svg'
import excelIcon from './../../Icons/excel.svg'
import pdfIcon from './../../Icons/pdf.svg'
import powerpointIcon from './../../Icons/powerpoint.svg'
import textIcon from './../../Icons/text.svg'
import wordIcon from './../../Icons/word.svg'
import { canDownloadAndView } from 'src/ViewAssetScreen/util'
import { useQueryClient } from 'react-query'
import { Asset } from 'src/ViewAssetScreen/Components/ViewAsset'
import { searchDataIntoViewAsset } from '@Search/utils/searchTableUpdater'
import useCustomAttribute from '@Common/Queries/GetQueries/GetCustomAttributes'

export const SearchCard = (props) => {
    const {
        row,
        preview,
        setPreview,
        showOptions,
        onClickOfRow,
        setAsset,
        usersData,
    } = props
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const customAttribute = useCustomAttribute()
    const [style, setStyle] = useImmer({
        visibility: 'hidden',
    })
    const previewData = useDownload({
        id: row.fileId,
    })
    function getIcon(fileName: string) {
        let icon = textIcon
        const extension = fileName.split('.').pop()
        switch (extension) {
            case 'ppt':
            case 'pptx':
                icon = powerpointIcon
                break
            case 'pdf':
                icon = pdfIcon
                break
            case 'rtf':
            case 'txt':
                icon = textIcon
                break
            case 'png':
            case 'jpeg':
            case 'jpg':
            case 'svg':
            case 'tiff':
                icon = imageIcon
                break
            case 'csv':
            case 'xml':
            case 'xls':
            case 'xlsx':
                icon = excelIcon
                break
            case 'doc':
            case 'docx':
                icon = wordIcon
                break
            default:
                icon = textIcon
        }
        return icon
    }
    function showPreview(fileName: string) {
        const extension = fileName.split('.').pop()
        const formats = ['csv', 'xml', 'xls', 'xlsx', 'rtf', 'txt']
        if (formats.includes(extension)) {
            return false
        }
        return true
    }
    return (
        <Card
            data-test-id="searchpage-card"
            sx={{
                padding: '8px',
                boxShadow: 'none',
                '&:hover': {
                    transform: 'scale(1.05)',
                    cursor: 'pointer',
                },
            }}
            className="border"
            onClick={(e) => {
                e.stopPropagation()
                if (onClickOfRow) {
                    onClickOfRow(row)
                } else {
                    setAsset((draft) => {
                        draft['fileId'] = row.fileId
                        draft['assetId'] = row.id
                    })
                    if (
                        !queryClient.getQueryData([
                            'assets',
                            row?.id,
                            'details',
                        ])
                    ) {
                        const data: Asset = searchDataIntoViewAsset(
                            row,
                            customAttribute.data,
                            usersData.data
                        )
                        setAsset((draft) => {
                            draft['data'] = data
                        })
                    }
                    navigate(`/search/${row.id}`)
                }
            }}
            onMouseEnter={() => {
                setStyle((draft) => {
                    draft.visibility = 'visible'
                })
            }}
            onMouseLeave={() => {
                setStyle((draft) => {
                    draft.visibility = 'hidden'
                })
            }}
        >
            <CardMedia
                sx={{
                    border: '1px solid #e4e4e7',
                    borderRadius: '4px',
                    overflow: 'hidden',
                    objectFit: 'inherit',
                }}
                className="h-36 2xl:h-44 3xl:h-56 4xl:h-60"
            >
                <img className="w-full" src={row.imageUrl}></img>
            </CardMedia>
            <CardContent
                sx={{
                    '&.MuiCardContent-root': {
                        paddingBottom: 1,
                    },
                }}
            >
                <div className="flex flex-row">
                    <div className="flex-col flex-1">
                        <div className="flex flex-row pb-2">
                            <Tooltip title={row.name}>
                                <Typography
                                    className="w-40 2xl:w-52 min-[1800px]:w-60 whitespace-nowrap truncate text-sm min-[1800px]:text-xl"
                                    color={
                                        style.visibility === 'hidden'
                                            ? 'default'
                                            : 'primary'
                                    }
                                >
                                    {row.name}
                                </Typography>
                            </Tooltip>
                        </div>
                        <div className="flex flex-row gap-1">
                            <div className="flex flex-row w-3 2xl:w-4">
                                <img src={getIcon(row.filename)} />
                            </div>
                            <div className="flex flex-row">
                                {style.visibility !== 'hidden' && (
                                    <Typography
                                        variant="body2"
                                        className="whitespace-nowrap truncate text-xs min-[1800px]:text-lg"
                                    >
                                        {'Updated on: '}
                                    </Typography>
                                )}
                                <Typography
                                    variant="body2"
                                    className="whitespace-nowrap truncate text-xs min-[1800px]:text-lg"
                                >
                                    {formattedDate(row.updatedAt)}
                                </Typography>
                            </div>
                        </div>
                    </div>
                    {showPreview(row.filename) &&
                        showOptions &&
                        canDownloadAndView(row.action) && (
                            <div className="flex justify-center m-auto w-11">
                                <Tooltip title="preview">
                                    <IconButton
                                        sx={{
                                            visibility: style.visibility,
                                        }}
                                        onClick={(event) => {
                                            setPreview((draft) => {
                                                ;(draft.show = true),
                                                    (draft.asset = {
                                                        ...row,
                                                        assetUrl:
                                                            previewData.data,
                                                    })
                                            })
                                            event.stopPropagation()
                                        }}
                                    >
                                        <VisibilityIcon
                                            color="disabled"
                                            fontSize="small"
                                        />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )}
                </div>
            </CardContent>
        </Card>
    )
}
