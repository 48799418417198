import logo from './assets/Portal.png'
import useTitle from '@Common/hooks/useTitle'
import { API_URL } from '@Common/Types/configs/urlConfig'
import { Button, Popover, TextField, Typography } from '@mui/material'
import { Login as LoginRequest } from '@Server/login'
import { ResendCode } from '@Server/resendCode'

import Loading from '@Common/Components/Loading'
import axios from 'axios'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useImmer } from 'use-immer'
import { useEffect, useRef } from 'react'
import { checkPassword } from './signup'
import { CancelOutlined, CheckCircleOutline } from '@mui/icons-material'
import { AuthTemplate } from './AuthTemplate'

export function ConfirmForgotPassword() {
    const navigate = useNavigate()
    const { handleSubmit, control, setError, formState } = useForm({})
    let [searchParams, setSearchParams] = useSearchParams()
    const email = searchParams.get('email')

    useEffect(() => {
        if (!email) {
            navigate('/forgotpassword')
        }
    })
    const mutation = useMutation(
        (login: LoginRequest) => {
            return axios.post(`${API_URL}/confirmForgotPassword`, login)
        },
        {
            onSuccess: (result) => {
                toast.success('Your password is reset')
                navigate(`/login`)
            },
            onError: (error) => {
                toast.error(error.data)
            },
        }
    )
    const [signup, setSignup] = useImmer({
        passwordHelp: false,
        password: '',
    })

    const onSubmit = (data) =>
        mutation.mutate({ ...data, username: email, password: signup.password })
    useTitle('Confirm forgot password - Portal')
    const passwordRef = useRef()
    const passwordValidation = checkPassword(signup.password)
    return (
        <AuthTemplate
            onSubmit={handleSubmit(onSubmit)}
            body={
                <>
                    {' '}
                    <Controller
                        name="code"
                        control={control}
                        render={({ field }) => (
                            <TextField size="small" {...field} label="Code" />
                        )}
                    />
                    <Controller
                        name="password"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                inputRef={passwordRef}
                                size="small"
                                type="password"
                                onChange={(e) => {
                                    setSignup((draft) => {
                                        draft.password = e.target.value
                                    })
                                }}
                                value={signup.password}
                                inputProps={{
                                    onFocusCapture: () => {
                                        setSignup((draft) => {
                                            draft.passwordHelp = true
                                        })
                                    },
                                    onBlur: () => {
                                        setSignup((draft) => {
                                            draft.passwordHelp = false
                                        })
                                    },
                                }}
                                autoComplete="new-password"
                                label="Password"
                            />
                        )}
                    />
                    <Controller
                        name="repeat"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                size="small"
                                type="password"
                                {...field}
                                label="Repeat"
                                autoComplete="new-password"
                            />
                        )}
                    />
                    <Button
                        disabled={mutation.isLoading}
                        type="submit"
                        variant="contained"
                    >
                        {mutation.isLoading && (
                            <div className="mr-2">
                                <Loading size="1.5rem" />
                            </div>
                        )}
                        Reset my password
                    </Button>{' '}
                    <Popover
                        id="mouse-over-popover"
                        sx={{
                            pointerEvents: 'none',
                        }}
                        open={signup.passwordHelp}
                        anchorEl={passwordRef.current}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        onClose={() => {
                            setSignup((draft) => {
                                draft.passwordHelp = false
                            })
                        }}
                        disableAutoFocus
                    >
                        <div className="p-4 text-sm flex flex-col gap-4">
                            <Typography className="">Requirements</Typography>
                            <div className="flex gap-2">
                                {' '}
                                {!passwordValidation.minimumEightCharacter && (
                                    <CancelOutlined className="text-red-500" />
                                )}{' '}
                                {passwordValidation.minimumEightCharacter && (
                                    <CheckCircleOutline className="text-green-500" />
                                )}
                                <Typography>
                                    At least 8 character long
                                </Typography>
                            </div>
                            <div className="flex gap-2">
                                {' '}
                                {!passwordValidation.atleastOneLowercase && (
                                    <CancelOutlined className="text-red-500" />
                                )}{' '}
                                {passwordValidation.atleastOneLowercase && (
                                    <CheckCircleOutline className="text-green-500" />
                                )}
                                <Typography>
                                    At least 1 lowercase character
                                </Typography>
                            </div>
                            <div className="flex gap-2">
                                {' '}
                                {!passwordValidation.atleastOneCapitalcase && (
                                    <CancelOutlined className="text-red-500" />
                                )}{' '}
                                {passwordValidation.atleastOneCapitalcase && (
                                    <CheckCircleOutline className="text-green-500" />
                                )}
                                <Typography>
                                    At least 1 uppercase character
                                </Typography>
                            </div>
                            <div className="flex gap-2">
                                {' '}
                                {!passwordValidation.atleastOneNumber && (
                                    <CancelOutlined className="text-red-500" />
                                )}{' '}
                                {passwordValidation.atleastOneNumber && (
                                    <CheckCircleOutline className="text-green-500" />
                                )}
                                <Typography>At least 1 number</Typography>
                            </div>
                            <div className="flex gap-2">
                                {' '}
                                {!passwordValidation.atleastOneSpecialCharacter && (
                                    <CancelOutlined className="text-red-500" />
                                )}{' '}
                                {passwordValidation.atleastOneSpecialCharacter && (
                                    <CheckCircleOutline className="text-green-500" />
                                )}
                                <Typography>
                                    At least 1 special character
                                </Typography>
                            </div>
                        </div>
                    </Popover>
                </>
            }
            title={
                ' We have sent a password reset code by email to your registered email address. Enter it below to reset your password.'
            }
        />
    )
}
