import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'

export function useAddAccounts(props: { onSuccess: () => void }) {
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: (request: any) => addAccounts(request),
        onSuccess: () => {
            queryClient.invalidateQueries('accounts')
            props.onSuccess()
        },
    })
    return mutation
}
async function addAccounts(request) {
    return await axios.post(`${API_URL}/accounts`, request)
}
