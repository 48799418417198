const domainRegex = /[\w\d\.-]+\.[\w\d\.-]+/
const emailRegex = /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/

export const checkEmail = (email: string) => {
    return emailRegex.test(email)
}

export const checkDomain = (domain: string) => {
    return domainRegex.test(domain)
}
