import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import toast from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'

export function useDeleteSection(props?: any) {
    const queryClient = useQueryClient()
    return useMutation(
        async (obj) =>
            await axios.delete(
                `${API_URL}/dealroom/${obj.id}/section/${obj.sectionId}`,
                obj
            ),
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries(['dealrooom', data.data.id])
            },
            onMutate: (data) => {
                const dealroomData = queryClient.getQueryData([
                    'dealroom',
                    data.id,
                ])
                if (!dealroomData) {
                    return
                }

                const dealRoomSection = dealroomData.dealRoom.DealRoomSection
                const dealRoomSectionIndex = dealRoomSection.findIndex(
                    (e) => e.id === data.sectionId
                )
                if (dealRoomSectionIndex === -1) {
                    return
                }

                dealRoomSection[dealRoomSectionIndex].status = 'DELETED'
                queryClient.setQueryData(['dealroom', data.id], {
                    dealRoom: {
                        ...dealroomData.dealRoom,
                        DealRoomSection: dealRoomSection,
                    },
                    thumbnail: dealroomData.thumbnail,
                })
            },
            onError: () => {
                toast.error('Error in deleting section')
            },
        }
    )
}
