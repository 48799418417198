import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { APi_URL } from "@Common/Types/configs/urlConfig";


export default function useCreateGroups(props: any) {
    const queryClient = useQueryClient()
    return useMutation<any, Error, object>((body: any) => axios.post(
        `${APi_URL}/groups`,
        body,
        { headers: { 'Content-Type': 'application/json' } }),
        {
            onSuccess: (data: any, obj: any) => {
                queryClient.invalidateQueries("Groups")
                props.onSuccess(data)
            },

            onError: props.onError
        });
}

