import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useQuery } from 'react-query'

export const useDealRoomComments = (id: string) => {
    return useQuery(['dealroom', id, 'comments'], async () => {
        const result = await axios.get(`${API_URL}/dealroom/${id}/comment`)
        return result.data
    })
}
