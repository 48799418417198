import makeIcon from '@Common/utils/MakeIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { XIcon } from '@heroicons/react/solid'
import Button from '@mui/material/Button'
import { useState } from 'react'

export default function PreviewPDF({ asset, closePreview }) {
    const [numPages, setNumPages] = useState(null as number | null)
    const [pageNumber, setPageNumber] = useState(1)
    const [scale, setScale] = useState(1)
    function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
        setNumPages(numPages)
    }
    if (!asset || !asset.assetUrl) {
        return <div />
    }
    return (
        <div className="w-full h-full  flex flex-col">
            <div className="flex  items-center shadow-sm z-10 h-10 pb-2	gap-5">
                <div className="flex gap-8 flex-1	 items-center">
                    <Button href={asset.assetUrl} variant="contained">
                        Download
                    </Button>
                    <div className="flex gap-2">
                        <FontAwesomeIcon
                            icon={makeIcon(asset.name).name}
                            size="lg"
                            color={makeIcon(asset.name).color}
                        />
                        <div className="flex justify-center items-center">
                            {asset.name}
                        </div>
                    </div>
                </div>

                <div className="flex gap-4 justify-center items-center"></div>
                <div className="flex-1 gap-10	 flex justify-end">
                    <Button
                        onClick={(e) => {
                            e.stopPropagation()
                            closePreview()
                        }}
                    >
                        <XIcon className="h-5 w-5"></XIcon>
                    </Button>
                </div>
            </div>
            <div className="justify-center items-center bg-gray-200 overflow-auto h-full">
                <img src={asset.assetUrl} alt={asset.name} />
            </div>
        </div>
    )
}
