import useCustomAttribute from '@Common/Queries/GetQueries/GetCustomAttributes'
import useUsers from '@Common/Queries/GetQueries/GetUsers'
import useDownload from '@Common/Queries/MutationQueries/getFileDownload'
import makeIcon from '@Common/utils/fabricIcons'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import { searchDataIntoViewAsset } from '@Search/utils/searchTableUpdater'
import { FC } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { Asset } from 'src/ViewAssetScreen/Components/ViewAsset'
import { Updater, useImmer } from 'use-immer'
import { canDownloadAndView } from '../../ViewAssetScreen/util'
import Modal from './common/modal/LockAssetModal'
import Preview from './preview'

const AssetNameCell: FC<{
    showOptions: boolean
    isHover: number
    row: {
        original: {
            action: string
            fileId: string
            name: string
            imageUrl: string
            filename: string
            AssetFile: {
                name: string
            }
        }
    }
    setAsset: Updater<{ fileId: string; assetId: string; data: Asset }>
}> = ({ showOptions, isHover, row, setAsset }) => {
    const [preview, setPreview] = useImmer({ show: false, asset: {} as any })
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const [open, setIsOpen] = useImmer(false)
    const expression = /\.([0-9a-z]+)(?:[\?#]|$)/
    const asset = row.original
    const users = useUsers()
    const customAttribute = useCustomAttribute()

    const filetypePattern = /\.([0-9a-z]+)(?:[\?#]|$)/
    const fileType = asset.filename.toLowerCase().match(filetypePattern)

    const supportedPreviewFormat = [
        'pdf',
        'png',
        'svg',
        'jpeg',
        'jpg',
        'ppt',
        'pptx',
        'doc',
        'docx',
    ]

    const {
        isSuccess,
        isError,
        isLoading: d,
        data,
    } = useDownload({ id: asset.fileId })
    const name = asset.name

    const url: string = data || ''
    const result = url.toLowerCase().match(expression)

    return (
        <>
            <Preview
                asset={preview.asset}
                closePreview={() => {
                    setPreview((draft) => {
                        draft.show = false
                        draft.asset = {}
                    })
                }}
                isOpen={preview.show}
            />

            {open && (
                <Modal
                    isOpen={setIsOpen}
                    NewAsset={false}
                    asset={asset}
                    modalTitle={'Request For Access'}
                    version={false}
                />
            )}

            <div className="flex items-center hover:cursor-pointer relative w-full">
                <div className="flex w-full relative">
                    <div className="flex w-full h-full gap-4">
                        {supportedPreviewFormat.includes(fileType[1]) ? (
                            <div
                                style={{
                                    backgroundImage:
                                        'url(' + asset.imageUrl + ')',
                                }}
                                className={`w-20 h-12 flex border bg-cover border-solid border-borderColor rounded`}
                            >
                                {isHover &&
                                    showOptions !== null &&
                                    showOptions != false &&
                                    canDownloadAndView(asset.action) &&
                                    result &&
                                    result[1] &&
                                    supportedPreviewFormat.findIndex(
                                        (format) => format === result[1]
                                    ) >= 0 && (
                                        <div
                                            className="text-sm h-full bg-transparentBackground text-white w-full flex items-center justify-center font-medium"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                setPreview((draft) => {
                                                    draft.show = true
                                                    draft.asset = {
                                                        ...asset,
                                                        assetUrl: data,
                                                    }
                                                })
                                            }}
                                        >
                                            Preview
                                        </div>
                                    )}
                            </div>
                        ) : (
                            <div className="w-20 h-12 flex bg-white border border-solid border-borderColor rounded justify-center items-center">
                                <span {...makeIcon(asset.filename)}></span>
                            </div>
                        )}
                        <Tooltip title={name} placement={'bottom-start'}>
                            <span
                                className={
                                    'text-sm items-center font-normal overflow-hidden whitespace-nowrap text-ellipsis flex flex-1'
                                }
                            >
                                {name}
                            </span>
                        </Tooltip>
                    </div>

                    {/* <div className="bg-previewButtonHover absolute right-0">
                        {isHover && requestAccessForAsset(asset.action) && (
                            <Button
                                color="error"
                                className="font-normal normal-case h-6 text-sm"
                                onClick={(e: any) => {
                                    e.stopPropagation()
                                    setIsOpen(true)
                                }}
                            >
                                Request
                            </Button>
                        )}
                    </div> */}

                    {isHover && showOptions !== null && showOptions !== false && (
                        <Button
                            className="drop-shadow-md bg-white hover:bg-white hover:drop-shadow-md absolute right-0 top-2 z-10"
                            size="small"
                            onClick={(e: any) => {
                                e.stopPropagation()
                                setAsset((draft) => {
                                    draft['fileId'] = row.original.fileId
                                    draft['assetId'] = row.original.id
                                })
                                if (
                                    !queryClient.getQueryData([
                                        'assets',
                                        row?.original?.id,
                                        'details',
                                    ])
                                ) {
                                    const data: Asset = searchDataIntoViewAsset(
                                        row.original,
                                        customAttribute.data,
                                        users.data
                                    )

                                    setAsset((draft) => {
                                        draft['data'] = data
                                    })
                                }
                                navigate(`/search/${row.original.id}`)
                            }}
                        >
                            Open
                        </Button>
                    )}
                </div>
            </div>
        </>
    )
}
export default AssetNameCell
