import { useQuery, useQueryClient } from 'react-query'
import axios from 'axios'
import { API_URL } from '@Common/Types/configs/urlConfig'

const getUserWorkspaces = async () => {
    const { data } = await axios.get(`${API_URL}/user/workspaces`)
    return data
}

export default function useUserWorkspaces() {
    const queryClient = useQueryClient()

    return useQuery(['user', 'workspaces'], () => getUserWorkspaces())
}
