import { schema } from 'prosemirror-schema-basic'
import { useProseMirror, ProseMirror } from 'use-prosemirror'
import './Editor.css'
import { addListNodes } from 'prosemirror-schema-list'
import { exampleSetup } from 'prosemirror-example-setup'
import {
    addMentionNodes,
    addTagNodes,
    getMentionsPlugin,
} from 'prosemirror-mentions'

import { Schema, Node } from 'prosemirror-model'
import { forwardRef } from 'react'

interface EditorProps {
    content?: string
    editable?: boolean
    users: {
        data: Record<string, unknown>[]
        option: { value: string; name: string; label: string }[]
    }
    style?: Record<string, unknown>
}
export default forwardRef(function CommentsEditor(props: EditorProps, ref) {
    const mySchema = new Schema({
        nodes: addTagNodes(
            addMentionNodes(
                addListNodes(schema.spec.nodes, 'paragraph block*', 'block')
            )
        ),
        marks: schema.spec.marks,
    })
    const getMentionSuggestionsHTML = (items) =>
        '<div class="suggestion-item-list">' +
        items
            .map((i) => '<div class="suggestion-item">' + i.name + '</div>')
            .join('') +
        '</div>'

    /**
     * IMPORTANT: outer div's "suggestion-item-list" class is mandatory. The plugin uses this class for querying.
     * IMPORTANT: inner div's "suggestion-item" class is mandatory too for the same reasons
     */
    const getTagSuggestionsHTML = (items) =>
        '<div class="suggestion-item-list">' +
        items
            .map((i) => '<div class="suggestion-item">' + i.tag + '</div>')
            .join('') +
        '</div>'
    const mentionPlugin = getMentionsPlugin({
        getSuggestions: (type, text, done) => {
            console.log(text)
            done(
                props.users.data?.filter(
                    (user) =>
                        user.name.toLowerCase().indexOf(text.toLowerCase()) >= 0
                ) || []
            )
        },
        getSuggestionsHTML: (items, type) => {
            if (type === 'mention') {
                return getMentionSuggestionsHTML(items)
            } else if (type === 'tag') {
                return getTagSuggestionsHTML(items)
            }
        },
    })

    function getConfig() {
        const plugins = exampleSetup({
            schema: mySchema,
            menuBar: props.editable,
        })
        plugins.unshift(mentionPlugin)
        if (!props.content) {
            return { schema: mySchema, plugins: plugins }
        }
        try {
            const value = JSON.parse(props.content)
            return {
                schema: mySchema,
                plugins: plugins,
                doc: Node.fromJSON(mySchema, value.doc),
            }
        } catch (e) {
            return {
                schema: mySchema,
                plugins: plugins,
                doc: Node.fromJSON(mySchema, {
                    type: 'doc',
                    content: [
                        {
                            type: 'paragraph',
                            attrs: { dir: null, ignoreBidiAutoUpdate: null },
                            content: [{ type: 'text', text: props.content }],
                        },
                    ],
                }),
            }
        }
    }
    const [state, setState] = useProseMirror({ ...getConfig() })

    return (
        <ProseMirror
            className={`${
                props.editable || props.editable === undefined
                    ? 'asset editable'
                    : 'noteditable'
            }`}
            state={state}
            style={props.style}
            ref={ref}
            editable={() => props.editable}
            onChange={setState}
        />
    )
})
