import Loading from '@Common/Components/Loading'
import { useDealRoomVisits } from '@Common/Queries/Dealroom/GetDealRoomVisit'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Divider,
    Typography,
} from '@mui/material'
import { useParams } from 'react-router-dom'
import { useImmer } from 'use-immer'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import Avatar from '@Common/Avatar'
import AvatarComponent from '@Common/Components/Avatar'
import { formattedDateWithTime } from 'src/ViewAssetScreen/util'
import { useDealRoom } from '@Common/Queries/Dealroom/GetDealRoom'
import prettyMilliseconds from 'pretty-ms'

const DealRoomVisits = () => {
    const { id } = useParams()
    const visits = useDealRoomVisits(id)
    const dealroom = useDealRoom({
        id: id,
    })
    if (visits.isLoading || dealroom.isLoading) {
        return <Loading></Loading>
    }
    const thumbnail = dealroom?.data?.thumbnail
    return (
        <div className="h-full flex flex-col">
            <div className="flex flex-col h-14">
                <Typography variant="subtitle2" className="p-4">
                    Visits
                </Typography>
                <Divider className="w-full"></Divider>
            </div>

            {visits.data.length == 0 ? (
                <Typography
                    className="flex h-full justify-center items-center"
                    color={'text.disabled'}
                >
                    No visits found
                </Typography>
            ) : (
                visits?.data?.map((visit, index) => {
                    const email =
                        visit.DealRoomSession.typeOfUser === 'EXTERNAL'
                            ? visit.DealRoomSession.email
                            : visit.DealRoomSession.User.email
                    return (
                        <Accordion
                            key={index}
                            sx={{
                                boxShadow: 'none',
                                margin: 0,
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreOutlinedIcon />}
                            >
                                <div className="flex gap-3">
                                    <AvatarComponent
                                        name={email}
                                        sx={{
                                            height: 32,
                                            width: 32,
                                        }}
                                    ></AvatarComponent>
                                    <div className="flex flex-col">
                                        <Typography fontSize={14}>
                                            {email}
                                        </Typography>
                                        <Typography
                                            fontSize={12}
                                            color={'text.disabled'}
                                        >
                                            {`Last engaged ${formattedDateWithTime(
                                                visit.updatedAt
                                            )}`}
                                        </Typography>
                                    </div>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                {visit.DealRoomAssetVisit.length === 0 && (
                                    <Typography color={'text.disabled'}>
                                        {`It appears that ${email} hasn't opened any documents yet`}
                                    </Typography>
                                )}
                                {visit.DealRoomAssetVisit.map(
                                    (assetVisit, index) => {
                                        const imgSrc = thumbnail?.find(
                                            (e) => e.id === assetVisit.assetId
                                        ).thumbnailUrl
                                        return (
                                            <div key={index}>
                                                <div className="w-full flex gap-4 py-2">
                                                    <div className="border rounded h-12 overflow-hidden">
                                                        <img
                                                            src={imgSrc}
                                                            className="w-20"
                                                        ></img>
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <Typography
                                                            fontSize={14}
                                                        >
                                                            {
                                                                assetVisit.Asset
                                                                    .name
                                                            }
                                                        </Typography>
                                                        <Typography
                                                            fontSize={12}
                                                            color="text.disabled"
                                                        >
                                                            {`Time spent: ${prettyMilliseconds(
                                                                assetVisit.timeSpent,
                                                                {
                                                                    secondsDecimalDigits: 0,
                                                                    millisecondsDecimalDigits: 0,
                                                                }
                                                            )}`}
                                                        </Typography>
                                                    </div>
                                                </div>

                                                <Divider />
                                            </div>
                                        )
                                    }
                                )}
                            </AccordionDetails>
                        </Accordion>
                    )
                })
            )}
        </div>
    )
}

export default DealRoomVisits
