import { FeatureContext } from '@Common/Components/Feature/FeatureProvider'
import { useContext } from 'react'
export default function useFeatureFlag(name: string) {
    const featureFlags = useContext(FeatureContext)

    if (!featureFlags) {
        return false
    }
    const feature = featureFlags[name]
    if (feature) {
        if (feature.enabled) {
            return true
        }
    }
    return false
}
