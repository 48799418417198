import AvatarComponent from '@Common/Components/Avatar'
import DialogComponent from '@Common/Components/DialogComponent'
import Loading from '@Common/Components/Loading'
import PopoverComponent from '@Common/Components/PopoverComponent'
import useDeleteSite from '@Common/Queries/GetQueries/DeleteSite'
import useGetSite from '@Common/Queries/GetQueries/GetSite'
import usePublishContent from '@Common/Queries/MutationQueries/PublishSiteContent'
import useUpdateSite from '@Common/Queries/MutationQueries/UpdateSite'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteIcon from '@mui/icons-material/Delete'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import LanguageIcon from '@mui/icons-material/Language'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import throttle from 'lodash.throttle'

import useAddTrackLink from '@Common/Queries/MutationQueries/CreateTrackLink'
import { VIEWER_DOMAIN } from '@Common/Types/configs/urlConfig'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import {
    Breadcrumbs,
    Button,
    IconButton,
    Menu,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import { useRef } from 'react'
import { toast } from 'react-hot-toast'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useImmer } from 'use-immer'
import { canDelete, canEdit } from '../ViewAssetScreen/util'
import Permission from './Components/Permission'
import SitesEditor from './Editor/SitesEditor'

const SitesPage = () => {
    const { id } = useParams()
    const sites = useGetSite({
        id: id,
    })
    const updateSite = useUpdateSite({
        updateFunction: (previousSite, data) => {
            return {
                ...previousSite,
                name: data.body.name,
            }
        },
    })
    const navigate = useNavigate()

    const [editOpen, setEditOpen] = useImmer({
        open: ['EDIT', 'FULL_ACCESS'].includes(sites?.data?.action),
        anchorEl: null,
    })
    const [editAnchor, setEditAnchor] = useImmer(null)
    const sendDataToGTM = useGTMDispatch()

    const publishContent = usePublishContent({
        onSuccess: () => {
            sendDataToGTM({
                event: 'site_published',
                site_id: id,
            })
            toast.success('Successfully published contents')
        },
    })

    const editorRef = useRef<HTMLDivElement>()

    const [savedStatus, setSavedStatus] = useImmer('Saved')
    const [menuOpen, setMenuOpen] = useImmer(false)
    const [dialogOpen, setDialogOpen] = useImmer(false)
    const [anchorEl, setAnchorEl] = useImmer<null | HTMLElement>(null)
    const siteDelete = useDeleteSite({
        onSuccess: () => {
            navigate('/sites')
        },
    })

    const deleteSite = () => {
        siteDelete.mutate(sites?.data?.id)
        setDialogOpen(false)
    }
    const addTrackLink = useAddTrackLink({
        onSuccess: (data) => {
            toast.success('Link copied successfully')
            sendDataToGTM({
                event: 'track_site_link_create',
                asset_id: id,
            })
            navigator.clipboard.writeText(
                `https://${VIEWER_DOMAIN}/${data.data.track.code}`
            )
        },
    })

    return (
        <>
            <PopoverComponent state={editOpen} setState={setEditOpen}>
                <div className="flex p-2">
                    {/* <Badge
                        anchorOrigin={{
                            vertical: 'bottom',
                        }}
                        overlap="circular"
                        badgeContent={<EditIcon fontSize="small"></EditIcon>}
                    >
                        <AvatarComponent sx={{ height: 40, width: 40 }} />
                    </Badge>
                    <Button>Remove</Button> */}
                    <TextField
                        size="small"
                        className="w-56"
                        defaultValue={sites?.data?.name}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                updateSite.mutate({
                                    id: sites?.data?.id,
                                    body: {
                                        name: e.target.value.trim(),
                                    },
                                })
                                setEditOpen((draft) => {
                                    return {
                                        open: false,
                                        anchorEl: null,
                                    }
                                })
                            }
                        }}
                        onChange={throttle((e) => {
                            updateSite.mutate({
                                id: sites?.data?.id,
                                body: {
                                    name: e.target.value.trim(),
                                },
                            })
                        }, 500)}
                    ></TextField>
                </div>
            </PopoverComponent>
            <Menu
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={() => {
                    setMenuOpen(false)
                }}
            >
                <MenuItem
                    className="text-sm"
                    onClick={() => {
                        setMenuOpen(false)
                        setDialogOpen(true)
                    }}
                >
                    <DeleteIcon
                        fontSize="small"
                        color="action"
                        className="mr-3"
                    ></DeleteIcon>
                    Delete
                </MenuItem>
            </Menu>
            <DialogComponent
                open={dialogOpen}
                setOpen={setDialogOpen}
                title={'Delete Site'}
                isLoading={siteDelete.isLoading}
                body={
                    <div>
                        <Typography>
                            {`Are you sure you want to delete this ${sites?.data?.name}?`}
                        </Typography>
                    </div>
                }
                success={{
                    text: 'Yes',
                }}
                cancel={{
                    text: 'No',
                }}
                handleChange={deleteSite}
            ></DialogComponent>
            {sites.isLoading || siteDelete.isLoading ? (
                <Loading></Loading>
            ) : (
                <div className="w-full h-full flex flex-col pt-4 overflow-hidden">
                    <div className="w-full flex justify-between pb-6 px-10">
                        <div className="flex gap-2 items-center">
                            <Breadcrumbs>
                                <Link color="text.secondary" to="/sites">
                                    Sites
                                </Link>
                                <div className="flex items-center">
                                    <AvatarComponent>
                                        {sites?.data?.imageUrl.trim() == '' && (
                                            <LanguageIcon></LanguageIcon>
                                        )}
                                    </AvatarComponent>
                                    <Typography
                                        title={sites?.data?.name}
                                        className="pl-1 w-56 text-ellipsis whitespace-nowrap overflow-hidden"
                                        onClick={(e) => {
                                            if (canEdit(sites?.data?.action)) {
                                                setEditOpen((draft) => {
                                                    return {
                                                        open: true,
                                                        anchorEl:
                                                            e.currentTarget,
                                                    }
                                                })
                                            }
                                        }}
                                    >
                                        {sites?.data?.name}
                                    </Typography>
                                </div>
                            </Breadcrumbs>
                        </div>

                        {/* <div className="flex items-center">
                            <Typography color="text.disabled">
                                Publish your site after you make changes
                            </Typography>
                        </div> */}

                        <div className="flex gap-3 items-center">
                            <Typography
                                sx={{
                                    color: '#2E7D32',
                                    paddingRight: 2,
                                }}
                            >
                                {savedStatus}
                            </Typography>
                            <Tooltip title="Share">
                                <IconButton
                                    className="p-0 my-auto"
                                    size="small"
                                    onClick={(e) => {
                                        addTrackLink.mutate({
                                            id: id,
                                            enabled: true,
                                            type: 'SITE',
                                        })
                                    }}
                                >
                                    {addTrackLink.isLoading ? (
                                        <Loading></Loading>
                                    ) : (
                                        <ContentCopyIcon fontSize="small"></ContentCopyIcon>
                                    )}
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Preview">
                                <Link
                                    to={`/sites/preview/${id}`}
                                    target="_blank"
                                >
                                    <IconButton
                                        size="small"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                        }}
                                    >
                                        <RemoveRedEyeOutlinedIcon fontSize="small"></RemoveRedEyeOutlinedIcon>
                                    </IconButton>
                                </Link>
                            </Tooltip>

                            {sites?.data?.action === 'FULL_ACCESS' && (
                                <Permission site={sites?.data}></Permission>
                            )}

                            {canDelete(sites?.data?.action) && (
                                <IconButton
                                    size="small"
                                    onClick={(e) => {
                                        setMenuOpen(true)
                                        setAnchorEl(e.currentTarget)
                                    }}
                                >
                                    <MoreVertOutlinedIcon fontSize="small"></MoreVertOutlinedIcon>
                                </IconButton>
                            )}

                            {canEdit(sites?.data?.action) && (
                                <Button
                                    variant="contained"
                                    size="small"
                                    disabled={publishContent.isLoading}
                                    onClick={(e) => {
                                        publishContent.mutate({
                                            content: {
                                                doc: editorRef?.current?.view
                                                    .state.doc,
                                            },
                                            siteId: sites?.data?.id,
                                        })
                                    }}
                                >
                                    <div className="pr-2">
                                        {publishContent.isLoading && (
                                            <Loading size="1rem" />
                                        )}
                                    </div>
                                    Publish Changes
                                </Button>
                            )}
                        </div>
                    </div>
                    <SitesEditor
                        ref={editorRef}
                        editable={['EDIT', 'FULL_ACCESS'].includes(
                            sites?.data?.action
                        )}
                        content={sites?.data?.SitesContent?.content}
                        setSavedStatus={setSavedStatus}
                        showMenu={true}
                        action={sites?.data?.action}
                    ></SitesEditor>
                    <div className="flex gap-2 items-center px-4 py-2">
                        <InfoOutlinedIcon
                            fontSize="small"
                            color="disabled"
                        ></InfoOutlinedIcon>
                        <Typography color={'text.disabled'}>
                            {'Type @ to search and add assets'}
                        </Typography>
                    </div>
                </div>
            )}
        </>
    )
}
export default SitesPage
