import useHover from '@Common/hooks/useHover'
import useInit from '@Common/Queries/GetQueries/useInit'
import LinearProgress from '@mui/material/LinearProgress'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { format } from 'date-fns'
import { FC } from 'react'
import { Updater } from 'use-immer'
import ViewAssetTopActions from '../ViewAssetScreen/Components/ViewAssetTopActions'
import { Asset, AssetStatus } from './AssetUploadInput'

const SingleUploadLoading: FC<{
    asset: Asset
    setOpen: Updater<boolean>
}> = ({ asset, setOpen }) => {
    const [hoverRef, isHover] = useHover()
    const { file, status, progress, id, error, assetDetails } = asset
    const init = useInit()
    const displayName = init.data.user.name
    const getFileSize = (size: any, status: AssetStatus, error: string) => {
        if (status === AssetStatus.error) {
            return error
        }
        if (size === 0) {
            return '0 Bytes'
        }
        const k = 1024
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
        const i = Math.floor(Math.log(size) / Math.log(k))
        return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
    }

    return (
        <>
            <div className="p-6 w-full h-full">
                <ViewAssetTopActions
                    disabled={status === AssetStatus.error ? false : true}
                    asset={asset}
                    downloadUrl={''}
                    setOpen={setOpen}
                ></ViewAssetTopActions>

                <Typography color={'text.disabled'} className="text-xs pb-3 ">
                    {`Created by ${displayName} on ${format(
                        new Date(),
                        'MMMM dd,yyyy'
                    )}`}
                </Typography>
                <div className="flex flex-col pb-4">
                    <Typography
                        className={
                            ' w-full h-full overflow-hidden text-ellipsis text-2xl pb-3'
                        }
                    >
                        {asset.file.name}
                    </Typography>
                    <Typography
                        color="text.disabled"
                        className={
                            'w-full h-full overflow-hidden text-ellipsis text-xs'
                        }
                    >
                        {'Add a description'}
                    </Typography>
                </div>

                {!asset.assetDetails && (
                    <>
                        <div
                            ref={hoverRef}
                            className="h-16 flex flex-col justify-between pb-7"
                        >
                            <LinearProgress
                                variant="determinate"
                                value={progress || 0}
                                color={
                                    status === AssetStatus.error
                                        ? 'error'
                                        : 'primary'
                                }
                            ></LinearProgress>
                            {isHover &&
                                (!error ? (
                                    <div className="flex justify-between">
                                        <Typography
                                            color="text.secondary"
                                            sx={{
                                                fontSize: 14,
                                            }}
                                        >
                                            {`Uploading ${
                                                progress ? progress : 0
                                            } %`}
                                        </Typography>
                                        <Typography
                                            color="text.secondary"
                                            sx={{
                                                fontSize: 14,
                                            }}
                                        >{` ${getFileSize(
                                            file.size,
                                            status,
                                            error
                                        )}`}</Typography>
                                    </div>
                                ) : (
                                    <div className="flex justify-between">
                                        <Typography>{`${error}`}</Typography>
                                    </div>
                                ))}
                        </div>
                        <div className="flex h-full w-full flex-col">
                            {[...Array(7)].map((e, i) => (
                                <Skeleton
                                    sx={{ marginBottom: 1 }}
                                    key={i}
                                    className="w-full"
                                    height={40}
                                    variant={'rounded'}
                                ></Skeleton>
                            ))}
                        </div>
                    </>
                )}
            </div>
        </>
    )
}
export default SingleUploadLoading
