import useFeatures from '@Common/Components/Feature/useFeatures'
import Loading from '@Common/Components/Loading'
import { Navigate } from 'react-router-dom'

export const DashboardOutlet = () => {
    const feature = useFeatures()
    if (feature.isLoading) {
        return <Loading></Loading>
    }
    return feature.data.dashboard && feature.data.dashboard.enabled ? (
        <Navigate to="/dashboard"></Navigate>
    ) : (
        <Navigate to="/search"></Navigate>
    )
}
