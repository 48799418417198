import { QueryClient, useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'
interface updateFunction {
    (data: any, obj: any): void
}
export default function useUpdateSite(props?: {
    onSuccess?: updateFunction
    updateFunction?: updateFunction
}) {
    const queryClient = useQueryClient()
    return useMutation<any, Error, object>(
        (obj: any) =>
            axios.put(`${APi_URL}/site/${obj.id}`, obj.body, {
                headers: { 'Content-Type': 'application/json' },
            }),
        {
            onMutate: (data) => {
                if (!props?.updateFunction) {
                    return
                }
                queryClient.cancelQueries(['sites', data.id])
                queryClient.cancelQueries(['sites', 'search'])
                queryClient.setQueryData(['sites', data.id], (oldSite) => {
                    const updatedSite = props?.updateFunction(oldSite, data)
                    return updatedSite
                })
                queryClient.setQueriesData(['sites', 'search'], (oldSites) => {
                    const index = oldSites.sites.findIndex(
                        (e) => data.id === e.id
                    )
                    const site = Object.assign({}, oldSites.sites[index])
                    site.name = data.body.name
                    const newSites = Object.assign([], oldSites)
                    newSites.sites[index] = site
                    return newSites
                })
            },
            onSuccess: (data, obj) => {
                if (props?.onSuccess) {
                    props.onSuccess(data, obj)
                }
                queryClient.invalidateQueries(['sites', data.id])
                queryClient.invalidateQueries(['sites', 'search'])
            },
        }
    )
}
