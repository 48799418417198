import { useEffect, useRef } from 'react'
import { useImmer } from 'use-immer'
export default function useOnClickOutside(
    initialIsVisible: any,
    callback?: () => void
) {
    const [isComponentVisible, setIsComponentVisible] =
        useImmer(initialIsVisible)
    const ref = useRef(null)

    const handleClickOutside = (event) => {
        if (
            ref.current &&
            !ref.current.contains(event.target) &&
            event.target.role !== 'option'
        ) {
            setIsComponentVisible('')
            if (callback) callback()
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOutside, true)
        }
    })

    return { ref, isComponentVisible, setIsComponentVisible }
}
