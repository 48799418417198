import {
    faFile,
    faFileAlt,
    faFileArchive,
    faFileAudio,
    faFileCode,
    faFileExcel,
    faFileImage,
    faFilePdf,
    faFilePowerpoint,
    faFileVideo,
    faFileWord,
} from '@fortawesome/free-regular-svg-icons'
import {
    faGear,
    faCube,
    faCog,
    faDatabase,
    faDesktop,
    faFont,
    faMicrochip,
    faVideo,
} from '@fortawesome/free-solid-svg-icons'
export class FontAwesomConfig {
    iconByExtension = {
        ai: {
            name: faFile,
            color: '#E0CFF3',
        },
        apk: {
            name: faFile,
            color: '#E0CFF3',
        },
        '3g2': {
            name: faVideo,
            color: '#3D3FA8',
        },
        '3gp': {
            name: faVideo,
            color: '#3D3FA8',
        },
        '3gpp': {
            name: faVideo,
            color: '#3D3FA8',
        },
        '3gpp2': {
            name: faVideo,
            color: '#3D3FA8',
        },
        pdf: {
            name: faFilePdf,
            color: '#FF3700',
        },
        doc: {
            name: faFileWord,
            color: '#4560DA',
        },
        docx: {
            name: faFileWord,
            color: '#4560DA',
        },
        docm: {
            name: faFileWord,
            color: '#4560DA',
        },
        dot: {
            name: faFileWord,
            color: '#4560DA',
        },
        dotx: {
            name: faFileWord,
            color: '#4560DA',
        },
        dotm: {
            name: faFileWord,
            color: '#4560DA',
        },
        docb: {
            name: faFileWord,
            color: '#4560DA',
        },
        xls: {
            name: faFileExcel,
            color: '#00A34F',
        },
        xlsx: {
            name: faFileExcel,
            color: '#00A34F',
        },
        xlsm: {
            name: faFileExcel,
            color: '#00A34F',
        },
        xlsb: {
            name: faFileExcel,
            color: '#00A34F',
        },
        xlt: {
            name: faFileExcel,
            color: '#00A34F',
        },
        xltx: {
            name: faFileExcel,
            color: '#00A34F',
        },
        xltm: {
            name: faFileExcel,
            color: '#00A34F',
        },
        xlam: {
            name: faFileExcel,
            color: '#00A34F',
        },
        xla: {
            name: faFileExcel,
            color: '#00A34F',
        },
        ppt: {
            name: faFilePowerpoint,
            color: '#F22900',
        },
        pptx: {
            name: faFilePowerpoint,
            color: '#F22900',
        },
        pptm: {
            name: faFilePowerpoint,
            color: '#F22900',
        },
        pot: {
            name: faFilePowerpoint,
            color: '#F22900',
        },
        potx: {
            name: faFilePowerpoint,
            color: '#F22900',
        },
        potm: {
            name: faFilePowerpoint,
            color: '#F22900',
        },
        ppam: {
            name: faFilePowerpoint,
            color: '#F22900',
        },
        pps: {
            name: faFilePowerpoint,
            color: '#F22900',
        },
        ppsx: {
            name: faFilePowerpoint,
            color: '#F22900',
        },
        ppsm: {
            name: faFilePowerpoint,
            color: '#F22900',
        },
        sldx: {
            name: faFilePowerpoint,
            color: '#F22900',
        },
        sldm: {
            name: faFilePowerpoint,
            color: '#F22900',
        },
        odt: {
            name: faFileWord,
            color: '#4560DA',
        },
        ods: {
            name: faFileExcel,
            color: '#00A34F',
        },
        odp: {
            name: faFilePowerpoint,
            color: '#F22900',
        },
        odg: {
            name: faFile,
            color: '#E0CFF3',
        },
        odc: {
            name: faFile,
            color: '#E0CFF3',
        },
        odf: {
            name: faFile,
            color: '#E0CFF3',
        },
        xll: {
            name: faFileExcel,
            color: '#00A34F',
        },
        xlw: {
            name: faFileExcel,
            color: '#00A34F',
        },
        rar: {
            name: faFileArchive,
            color: '#5E90C5',
        },
        rtf: {
            name: faFile,
            color: '#E0CFF3',
        },
        swf: {
            name: faFile,
            color: '#E0CFF3',
        },
        txt: {
            name: faFileAlt,
            color: '#E0CFF3',
        },
        wav: {
            name: faFileAudio,
            color: '#727C87',
        },
        wma: {
            name: faFileAudio,
            color: '#727C87',
        },
        wmv: {
            name: faFileAudio,
            color: '#727C87',
        },
        f4p: {
            name: faVideo,
            color: '#3D3FA8',
        },
        f4a: {
            name: faVideo,
            color: '#3D3FA8',
        },
        f4b: {
            name: faVideo,
            color: '#3D3FA8',
        },
        gif: {
            name: faFileImage,
            color: '#1D93E7',
        },
        jpg: {
            name: faFileImage,
            color: '#1D93E7',
        },
        jpeg: {
            name: faFileImage,
            color: '#1D93E7',
        },
        png: {
            name: faFileImage,
            color: '#1D93E7',
        },
        psd: {
            name: faFileImage,
            color: '#1D93E7',
        },
        tif: {
            name: faFileImage,
            color: '#1D93E7',
        },
        tiff: {
            name: faFileImage,
            color: '#1D93E7',
        },
        tga: {
            name: faFileImage,
            color: '#1D93E7',
        },
        bmp: {
            name: faFileImage,
            color: '#1D93E7',
        },
        ico: {
            name: faFileImage,
            color: '#1D93E7',
        },
        m2v: {
            name: faVideo,
            color: '#3D3FA8',
        },
        m2p: {
            name: faVideo,
            color: '#3D3FA8',
        },
        m2t: {
            name: faVideo,
            color: '#3D3FA8',
        },
        m2a: {
            name: faVideo,
            color: '#3D3FA8',
        },
        m2b: {
            name: faVideo,
            color: '#3D3FA8',
        },
        mp2: {
            name: faVideo,
            color: '#3D3FA8',
        },
        mp2v: {
            name: faVideo,
            color: '#3D3FA8',
        },
        mp4v: {
            name: faVideo,
            color: '#3D3FA8',
        },
        mpa: {
            name: faVideo,
            color: '#3D3FA8',
        },
        mpv: {
            name: faVideo,
            color: '#3D3FA8',
        },
        mpg4: {
            name: faVideo,
            color: '#3D3FA8',
        },
        ogg: {
            name: faFileVideo,
            color: '#252E80',
        },
        ogv: {
            name: faFileVideo,
            color: '#252E80',
        },
        webm: {
            name: faVideo,
            color: '#3D3FA8',
        },
        zip: {
            name: faFileArchive,
            color: '#5E90C5',
        },
        '7z': {
            name: faFileArchive,
            color: '#5E90C5',
        },
        gz: {
            name: faFileArchive,
            color: '#5E90C5',
        },
        tar: {
            name: faFileArchive,
            color: '#5E90C5',
        },
        bz2: {
            name: faFileArchive,
            color: '#5E90C5',
        },
        asx: {
            name: faFile,
            color: '#E0CFF3',
        },
        bin: {
            name: faFile,
            color: '#E0CFF3',
        },
        cdr: {
            name: faFile,
            color: '#E0CFF3',
        },
        chm: {
            name: faFile,
            color: '#E0CFF3',
        },
        dat: {
            name: faFile,
            color: '#E0CFF3',
        },
        eot: {
            name: faFile,
            color: '#E0CFF3',
        },
        eps: {
            name: faFile,
            color: '#E0CFF3',
        },
        exe: {
            name: faFile,
            color: '#E0CFF3',
        },
        fla: {
            name: faFile,
            color: '#E0CFF3',
        },
        indd: {
            name: faFile,
            color: '#E0CFF3',
        },
        iso: {
            name: faFile,
            color: '#E0CFF3',
        },
        jar: {
            name: faFile,
            color: '#E0CFF3',
        },
        mdb: {
            name: faFile,
            color: '#E0CFF3',
        },
        msi: {
            name: faFile,
            color: '#E0CFF3',
        },
        mswmm: {
            name: faFile,
            color: '#E0CFF3',
        },
        msword: {
            name: faFile,
            color: '#E0CFF3',
        },
        numbers: {
            name: faFile,
            color: '#E0CFF3',
        },
        otf: {
            name: faFile,
            color: '#E0CFF3',
        },
        pages: {
            name: faFile,
            color: '#E0CFF3',
        },
        conf: {
            name: faFile,
            color: '#E0CFF3',
        },
        cpp: {
            name: faFile,
            color: '#E0CFF3',
        },
        css: {
            name: faFile,
            color: '#E0CFF3',
        },
        csv: {
            name: faFile,
            color: '#E0CFF3',
        },
        dtd: {
            name: faFile,
            color: '#E0CFF3',
        },
        dwg: {
            name: faFile,
            color: '#E0CFF3',
        },
        dxf: {
            name: faFile,
            color: '#E0CFF3',
        },
        ics: {
            name: faFile,
            color: '#E0CFF3',
        },
        mp3: {
            name: faFileAudio,
            color: '#727C87',
        },
        odb: {
            name: faFile,
            color: '#E0CFF3',
        },
        a: {
            name: faFileArchive,
            color: '#5E90C5',
        },
        aac: {
            name: faFileAudio,
            color: '#727C87',
        },
        ac3: {
            name: faFileAudio,
            color: '#727C87',
        },
        adf: {
            name: faFile,
            color: '#E0CFF3',
        },
        adts: {
            name: faFileAudio,
            color: '#727C87',
        },
        aif: {
            name: faFileAudio,
            color: '#727C87',
        },
        aiff: {
            name: faFileAudio,
            color: '#727C87',
        },
        alac: {
            name: faFileAudio,
            color: '#727C87',
        },
        amr: {
            name: faFileAudio,
            color: '#727C87',
        },
        amv: {
            name: faFile,
            color: '#E0CFF3',
        },
        ape: {
            name: faFileAudio,
            color: '#727C87',
        },
        apng: {
            name: faFile,
            color: '#E0CFF3',
        },
        asf: {
            name: faFileVideo,
            color: '#252E80',
        },
        asf_o: {
            name: faFileVideo,
            color: '#252E80',
        },
        asf_stream: {
            name: faFileVideo,
            color: '#252E80',
        },
        ass: {
            name: faFile,
            color: '#E0CFF3',
        }, //faFileSubtitle
        ast: {
            name: faFile,
            color: '#E0CFF3',
        },
        au: {
            name: faFileAudio,
            color: '#727C87',
        },
        avi: {
            name: faFileVideo,
            color: '#252E80',
        },
        absinth: {
            name: faFile,
            color: '#E0CFF3',
        },
        avm2: {
            name: faFile,
            color: '#E0CFF3',
        },
        avs: {
            name: faFile,
            color: '#E0CFF3',
        },
        b: {
            name: faFileArchive,
            color: '#5E90C5',
        },
        bak: {
            name: faFile,
            color: '#E0CFF3',
        },
        bds: {
            name: faFile,
            color: '#E0CFF3',
        },
        bdt3: {
            name: faFile,
            color: '#E0CFF3',
        },
        bik: {
            name: faFile,
            color: '#E0CFF3',
        },
        bk2: {
            name: faFile,
            color: '#E0CFF3',
        },
        bkp: {
            name: faFile,
            color: '#E0CFF3',
        },
        brats: {
            name: faFileAudio,
            color: '#727C87',
        },
        brest: {
            name: faFileAudio,
            color: '#727C87',
        },
        c: {
            name: faFileArchive,
            color: '#5E90C5',
        },
        cab: {
            name: faFileArchive,
            color: '#5E90C5',
        },
        caf: {
            name: faFileAudio,
            color: '#727C87',
        },
        cavs: {
            name: faFile,
            color: '#E0CFF3',
        },
        cbz: {
            name: faFileArchive,
            color: '#5E90C5',
        },
        cc: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        cfg: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        cgm: {
            name: faFile,
            color: '#E0CFF3',
        },
        class: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        clpi: {
            name: faFile,
            color: '#E0CFF3',
        },
        cda: {
            name: faFileAudio,
            color: '#727C87',
        },
        divx: {
            name: faFileVideo,
            color: '#252E80',
        },
        dv: {
            name: faFileVideo,
            color: '#252E80',
        },
        'dvr-ms': {
            name: faFileVideo,
            color: '#252E80',
        },
        flv: {
            name: faFileVideo,
            color: '#252E80',
        },
        flv_1: {
            name: faFileVideo,
            color: '#252E80',
        },
        flv_2: {
            name: faFileVideo,
            color: '#252E80',
        },
        flv_3: {
            name: faFileVideo,
            color: '#252E80',
        },
        flv_4: {
            name: faFileVideo,
            color: '#252E80',
        },
        flv_5: {
            name: faFileVideo,
            color: '#252E80',
        },
        flv_6: {
            name: faFileVideo,
            color: '#252E80',
        },
        flv_7: {
            name: faFileVideo,
            color: '#252E80',
        },
        flv_8: {
            name: faFileVideo,
            color: '#252E80',
        },
        flv_9: {
            name: faFileVideo,
            color: '#252E80',
        },
        flv_10: {
            name: faFileVideo,
            color: '#252E80',
        },
        flv_11: {
            name: faFileVideo,
            color: '#252E80',
        },
        flv_12: {
            name: faFileVideo,
            color: '#252E80',
        },
        flv_13: {
            name: faFileVideo,
            color: '#252E80',
        },
        flv_14: {
            name: faFileVideo,
            color: '#252E80',
        },
        flv_15: {
            name: faFileVideo,
            color: '#252E80',
        },
        flv_16: {
            name: faFileVideo,
            color: '#252E80',
        },
        flv_17: {
            name: faFileVideo,
            color: '#252E80',
        },
        flv_18: {
            name: faFileVideo,
            color: '#252E80',
        },
        flv_19: {
            name: faFileVideo,
            color: '#252E80',
        },
        flv_20: {
            name: faFileVideo,
            color: '#252E80',
        },
        flv_21: {
            name: faFileVideo,
            color: '#252E80',
        },
        flv_22: {
            name: faFileVideo,
            color: '#252E80',
        },
        flv_23: {
            name: faFileVideo,
            color: '#252E80',
        },
        flv_24: {
            name: faFileVideo,
            color: '#252E80',
        },
        desktop: {
            name: faDesktop,
            color: '#00B5E3',
        },
        flac: {
            name: faFileAudio,
            color: '#727C87',
        },
        gzip: {
            name: faFile,
            color: '#E0CFF3',
        },
        h: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        hpp: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        hqx: {
            name: faFile,
            color: '#E0CFF3',
        },
        html: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        js: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        json: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        m4a: {
            name: faFileAudio,
            color: '#727C87',
        },
        m4v: {
            name: faFileVideo,
            color: '#252E80',
        },
        m4p: {
            name: faFileAudio,
            color: '#727C87',
        },
        m4b: {
            name: faFileAudio,
            color: '#727C87',
        },
        m4r: {
            name: faFileAudio,
            color: '#727C87',
        },
        mid: {
            name: faFileAudio,
            color: '#727C87',
        },
        midi: {
            name: faFileAudio,
            color: '#727C87',
        },
        mkv: {
            name: faFileVideo,
            color: '#252E80',
        },
        mka: {
            name: faFileAudio,
            color: '#727C87',
        },
        mks: {
            name: faFile,
            color: '#E0CFF3',
        },
        mov: {
            name: faFileVideo,
            color: '#252E80',
        },
        mp4: {
            name: faFileVideo,
            color: '#252E80',
        },
        m4u: {
            name: faFileVideo,
            color: '#252E80',
        },
        m4u8: {
            name: faFileVideo,
            color: '#252E80',
        },
        mpe: {
            name: faFileAudio,
            color: '#727C87',
        },
        mpg: {
            name: faFileVideo,
            color: '#252E80',
        },
        mpga: {
            name: faFileAudio,
            color: '#727C87',
        },
        dps: {
            name: faFile,
            color: '#E0CFF3',
        },
        dpt: {
            name: faFile,
            color: '#E0CFF3',
        },
        mxf: {
            name: faFile,
            color: '#E0CFF3',
        },
        nfo: {
            name: faFile,
            color: '#E0CFF3',
        },
        dvd: {
            name: faFile,
            color: '#E0CFF3',
        },
        eml: {
            name: faFile,
            color: '#E0CFF3',
        },
        f4v: {
            name: faFileVideo,
            color: '#252E80',
        },
        f4v_1: {
            name: faFileVideo,
            color: '#252E80',
        },
        f4v_2: {
            name: faFileVideo,
            color: '#252E80',
        },
        f4v_3: {
            name: faFileVideo,
            color: '#252E80',
        },
        f4v_4: {
            name: faFileVideo,
            color: '#252E80',
        },
        f4v_5: {
            name: faFileVideo,
            color: '#252E80',
        },
        f4v_6: {
            name: faFileVideo,
            color: '#252E80',
        },
        f4v_7: {
            name: faFileVideo,
            color: '#252E80',
        },
        f4v_8: {
            name: faFileVideo,
            color: '#252E80',
        },
        f4v_9: {
            name: faFileVideo,
            color: '#252E80',
        },
        f4v_10: {
            name: faFileVideo,
            color: '#252E80',
        },
        f4v_11: {
            name: faFileVideo,
            color: '#252E80',
        },
        f4v_12: {
            name: faFileVideo,
            color: '#252E80',
        },
        f4v_13: {
            name: faFileVideo,
            color: '#252E80',
        },
        f4v_14: {
            name: faFileVideo,
            color: '#252E80',
        },
        f4v_15: {
            name: faFileVideo,
            color: '#252E80',
        },
        f4v_16: {
            name: faFileVideo,
            color: '#252E80',
        },
        f4v_17: {
            name: faFileVideo,
            color: '#252E80',
        },
        f4v_18: {
            name: faFileVideo,
            color: '#252E80',
        },
        f4v_19: {
            name: faFileVideo,
            color: '#252E80',
        },
        f4v_20: {
            name: faFileVideo,
            color: '#252E80',
        },
        f4v_21: {
            name: faFileVideo,
            color: '#252E80',
        },
        f4v_22: {
            name: faFileVideo,
            color: '#252E80',
        },
        f4v_23: {
            name: faFileVideo,
            color: '#252E80',
        },
        f4v_24: {
            name: faFileVideo,
            color: '#252E80',
        },
        f4v_25: {
            name: faFileVideo,
            color: '#252E80',
        },
        f4v_26: {
            name: faFileVideo,
            color: '#252E80',
        },
        f4v_27: {
            name: faFileVideo,
            color: '#252E80',
        },
        f4v_28: {
            name: faFileVideo,
            color: '#252E80',
        },
        f4v_29: {
            name: faFileVideo,
            color: '#252E80',
        },
        f4v_30: {
            name: faFileVideo,
            color: '#252E80',
        },
        f4v_31: {
            name: faFileVideo,
            color: '#252E80',
        },
        f4v_32: {
            name: faFileVideo,
            color: '#252E80',
        },
        h264: {
            name: faFileVideo,
            color: '#252E80',
        },
        h265: {
            name: faFileVideo,
            color: '#252E80',
        },
        img: {
            name: faFileImage,
            color: '#1D93E7',
        },
        m2ts: {
            name: faFileVideo,
            color: '#252E80',
        },
        mpeg: {
            name: faFileVideo,
            color: '#252E80',
        },
        mts: {
            name: faFileVideo,
            color: '#252E80',
        },
        nef: {
            name: faFile,
            color: '#E0CFF3',
        },
        ogm: {
            name: faFile,
            color: '#E0CFF3',
        },
        pef: {
            name: faFile,
            color: '#E0CFF3',
        },
        pfb: {
            name: faFile,
            color: '#E0CFF3',
        },
        pfm: {
            name: faFile,
            color: '#E0CFF3',
        },
        qt: {
            name: faFileVideo,
            color: '#252E80',
        },
        rm: {
            name: faFileVideo,
            color: '#252E80',
        },
        arj: {
            name: faFileArchive,
            color: '#5E90C5',
        },
        asm: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        asp: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        aspx: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        bat: {
            name: faMicrochip,
            color: '#364750',
        },
        bz: {
            name: faFileArchive,
            color: '#5E90C5',
        },
        cbl: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        cfm: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        cgi: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        cmd: {
            name: faMicrochip,
            color: '#364750',
        },
        com: {
            name: faMicrochip,
            color: '#364750',
        },
        config: {
            name: faCog,
            color: '#5FF0FF',
        },
        cson: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        db: {
            name: faDatabase,
            color: '#4263D8',
        },
        dbf: {
            name: faDatabase,
            color: '#4263D8',
        },
        deb: {
            name: faFileArchive,
            color: '#5E90C5',
        },
        dmg: {
            name: faCube,
            color: '#FF828C',
        },
        f: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        fnt: {
            name: faFont,
            color: '#C1FD99',
        },
        fon: {
            name: faFont,
            color: '#C1FD99',
        },
        for: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        fs: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        gem: {
            name: faFileArchive,
            color: '#5E90C5',
        },
        go: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        gradle: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        groovy: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        hh: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        htm: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        ini: {
            name: faCog,
            color: '#5FF0FF',
        },
        java: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        jsp: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        less: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        log: {
            name: faDatabase,
            color: '#4263D8',
        },
        lua: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        mak: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        md: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        old: {
            name: faDatabase,
            color: '#4263D8',
        },
        pkg: {
            name: faFileArchive,
            color: '#5E90C5',
        },
        pl: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        ps: {
            name: faFileImage,
            color: '#1D93E7',
        },
        py: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        rb: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        rc: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        rpm: {
            name: faFileArchive,
            color: '#5E90C5',
        },
        sass: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        sav: {
            name: faDatabase,
            color: '#4263D8',
        },
        scss: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        sh: {
            name: faMicrochip,
            color: '#364750',
        },
        so: {
            name: faDatabase,
            color: '#4263D8',
        },
        sql: {
            name: faDatabase,
            color: '#4263D8',
        },
        svg: {
            name: faFileImage,
            color: '#1D93E7',
        },
        tcl: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        toast: {
            name: faCube,
            color: '#FF828C',
        },
        ts: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        ttf: {
            name: faFont,
            color: '#C1FD99',
        },
        vb: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        vcd: {
            name: faCube,
            color: '#FF828C',
        },
        vob: {
            name: faFileVideo,
            color: '#252E80',
        },
        woff: {
            name: faFont,
            color: '#C1FD99',
        },
        wpl: {
            name: faFileVideo,
            color: '#252E80',
        },
        wsf: {
            name: faMicrochip,
            color: '#364750',
        },
        xhtml: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        xml: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        xsd: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        xz: {
            name: faFileArchive,
            color: '#5E90C5',
        },
        yaml: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        yml: {
            name: faFileCode,
            color: '#CA5FE2',
        },
        z: {
            name: faFileArchive,
            color: '#5E90C5',
        },
        zzz: {
            name: faFile,
            color: '#E0CFF3',
        },
    }
    fsIconByName = {
        '.config': {
            name: faCog,
            color: '#5FF0FF',
        },
        '.dockerignore': {
            name: faFileCode,
            color: '#CA5FE2',
        },
        '.gitattributes': {
            name: faFileCode,
            color: '#CA5FE2',
        },
        '.gitignore': {
            name: faFileCode,
            color: '#CA5FE2',
        },
        '.gitconfig': {
            name: faFileCode,
            color: '#CA5FE2',
        },
        '.npmignore': {
            name: faFileCode,
            color: '#CA5FE2',
        },
        '.npmrc': {
            name: faFileCode,
            color: '#CA5FE2',
        },
        dockerfile: {
            name: faFileCode,
            color: '#CA5FE2',
        },
    }
}

export const fontAwesomConfig: any = new FontAwesomConfig()
