import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { FC } from 'react'

const TypographyComponent: FC<{
    value: string
    className?: string
    color: string
}> = ({ value, className, color }) => {
    return (
        <Tooltip title={value} arrow placement="top">
            <Typography
                className={`w-full h-full overflow-hidden text-ellipsis  ${className}`}
                color={color}
            >
                {value}
            </Typography>
        </Tooltip>
    )
}
export default TypographyComponent
