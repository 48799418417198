import { Items } from 'src/ViewAssetScreen/Components/SelectInfo'

const permissions: Items = [
    {
        info: 'Can edit and change permission ',
        value: 'FULL_ACCESS',
        label: 'Full access',
    },
    {
        info: 'Can edit but not change permission',
        value: 'EDIT',
        label: 'Can edit',
    },
    {
        info: 'Can view but not edit ',
        value: 'VIEW',
        label: 'Can view',
    },
]

export function getPermissions(
    type: 'member' | 'asset' | 'add',
    tenantType: string
) {
    const tenantPermissions = (permissions: Items) => {
        if (tenantType === 'ENTERPRISE') {
            return permissions
        }
        return permissions.filter((permission) => {
            return permission.value !== 'SEARCH'
        })
    }
    if (type === 'add') {
        return tenantPermissions(permissions)
    }
    if (type === 'member') {
        return tenantPermissions([
            ...permissions,
            {
                className: 'text-red-500',
                value: 'REMOVE',
                label: 'Remove',
            },
        ])
    }

    return tenantPermissions([
        ...permissions,
        {
            className: 'text-red-500',
            value: 'NO_ACCESS',
            label: 'No access',
        },
    ])
}
