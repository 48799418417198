import ClickAwayListener from '@mui/material/ClickAwayListener'
import isEmpty from 'lodash.isempty'
import SelectFilter from './SelectFilter'

const CustomAttrSelectFilter = ({
    setFilterList,
    setFilter,
    columnData,
    value,
    options,
    handleClickAway,
}) => {
    const handleCheckboxChange = (
        event: React.SyntheticEvent,
        value: T | Array<T>,
        reason: string,
        details?: string
    ): void => {
        if (reason === 'selectOption') {
            setFilterList((draft) => {
                const index = draft.findIndex(
                    (e) => e.filterColumn.label === columnData.label
                )

                const data =
                    draft[index].value && !isEmpty(draft[index].value)
                        ? draft[index].value
                        : []
                data.push(details.option)

                draft[index] = {
                    filterColumn: columnData,
                    value: data,
                }
            })

            setFilter((draft) => {
                const data = draft.filter.customAttributeFilter.hasOwnProperty(
                    details.option.attributeId
                )
                    ? draft.filter.customAttributeFilter[
                          details.option.attributeId
                      ]
                    : []
                data.push(details.option.id)
                draft.filter.customAttributeFilter[details.option.attributeId] =
                    data
                draft.currentPage = 0
            })
        } else if (reason === 'removeOption') {
            setFilterList((draft) => {
                const index = draft.findIndex(
                    (e) => e.filterColumn.label === columnData.label
                )

                const data =
                    draft[index].value && !isEmpty(draft[index].value)
                        ? draft[index].value
                        : []
                const dataIndex = data.findIndex(
                    (e) => e.value === details.option.value
                )
                data.splice(dataIndex, 1)

                draft[index] = {
                    filterColumn: columnData,
                    value: data,
                }
            })

            setFilter((draft) => {
                const data =
                    draft.filter.customAttributeFilter[
                        details.option.attributeId
                    ]
                const index = data.findIndex(
                    (id) => id === details.option.value
                )
                data.splice(index, 1)
                draft.filter.customAttributeFilter[details.option.attributeId] =
                    data
                draft.currentPage = 0
            })
        } else if (reason === 'clear') {
            setFilterList((draft) => {
                const index = draft.findIndex(
                    (e) => e.filterColumn.label === columnData.label
                )

                draft[index] = {
                    filterColumn: columnData,
                    value: [],
                }
            })
            setFilter((draft) => {
                delete draft.filter.customAttributeFilter[columnData.id]
                draft.currentPage = 0
            })
        }
    }
    return (
        <>
            <ClickAwayListener
                onClickAway={() => handleClickAway(columnData.label)}
            >
                <div className="pt-2">
                    <SelectFilter
                        options={options}
                        onChange={handleCheckboxChange}
                        value={value}
                    ></SelectFilter>
                </div>
            </ClickAwayListener>
        </>
    )
}
export default CustomAttrSelectFilter
