import logo from './assets/Portal.png'
import Loading from '@Common/Components/Loading'
import useTitle from '@Common/hooks/useTitle'
import { API_URL } from '@Common/Types/configs/urlConfig'
import { CancelOutlined, CheckCircleOutline } from '@mui/icons-material'
import {
    Button,
    Checkbox,
    FormControlLabel,
    Link,
    Popover,
    TextField,
    Typography,
} from '@mui/material'
import { Signup as LoginRequest } from '@Server/signup'
import axios from 'axios'
import { useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useMutation } from 'react-query'
import { Link as LinkRoute, useNavigate } from 'react-router-dom'
import { useImmer } from 'use-immer'
import { sign } from 'crypto'
import { AuthTemplate } from './AuthTemplate'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'

export function Signup() {
    const navigate = useNavigate()
    const { handleSubmit, control, setError, formState } = useForm({
        defaultValues: {
            username: '',
            password: '',
            repeat: '',
            referral: '',
        },
    })
    const mutation = useMutation(
        (login: LoginRequest) => {
            return axios.post(`${API_URL}/signup`, login)
        },
        {
            onSuccess: (result) => {
                navigate(`/verify/?email=${result.data.username}`)
                sendGTMEvent({
                    event: 'signup',
                    user_email: result.data.username,
                })
            },
            onError: (error) => {
                toast.error(error?.response?.data)
            },
        }
    )
    const condition = useRef()
    const onSubmit = (data) => {
        if (data.repeat !== signup.password) {
            toast.error('Password and repeat password does not match')
        }
        if (signup.conditon === false) {
            toast.error('Please agree to terms and condition before signing up')
        }
        mutation.mutate({ ...data, password: signup.password })
    }

    useTitle('Signup - Portal')
    const [signup, setSignup] = useImmer({
        passwordHelp: false,
        password: '',
        conditon: true,
    })
    const passwordRef = useRef()
    const sendGTMEvent = useGTMDispatch()

    const passwordValidation = checkPassword(signup.password)
    return (
        <AuthTemplate
            title={'Sign up'}
            body={
                <>
                    {' '}
                    <Controller
                        name="username"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                size="small"
                                {...field}
                                autoComplete="username"
                                label="Email"
                                variant='outlined'
                            />
                        )}
                    />
                    <Controller
                        name="password"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                inputRef={passwordRef}
                                size="small"
                                type="password"
                                variant='outlined'
                                onChange={(e) => {
                                    setSignup((draft) => {
                                        draft.password = e.target.value
                                    })
                                }}
                                value={signup.password}
                                inputProps={{
                                    onFocusCapture: () => {
                                        setSignup((draft) => {
                                            draft.passwordHelp = true
                                        })
                                    },
                                    onBlur: () => {
                                        setSignup((draft) => {
                                            draft.passwordHelp = false
                                        })
                                    },
                                }}
                                autoComplete="new-password"
                                label="Password"
                            />
                        )}
                    />
                    <Controller
                        name="repeat"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                size="small"
                                type="password"
                                label="Repeat"
                                variant='outlined'
                                autoComplete="new-password"
                            />
                        )}
                    />
                    <Controller
                        name="referral"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                size="small"
                                variant='outlined'
                                label="Referral code (optional)"
                            />
                        )}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                onClick={(e) => {
                                    setSignup((draft) => {
                                        draft.conditon = e.target.checked
                                    })
                                }}
                                ref={condition}
                                defaultChecked
                            />
                        }
                        label={
                            <p>
                                By signing up, I agree to the LIFEX Portal{' '}
                                <Link href="https://lifex.cloud/privacy-policy">
                                    Privacy Policy
                                </Link>{' '}
                                and{' '}
                                <Link href="https://lifex.cloud/terms-service">
                                    Terms of Service
                                </Link>
                                .
                            </p>
                        }
                    />
                    <Button
                        disabled={mutation.isLoading || !signup.conditon}
                        type="submit"
                        variant="contained"
                    >
                        {mutation.isLoading && (
                            <div className="mr-2">
                                <Loading size="1.5rem" />
                            </div>
                        )}
                        Sign up
                    </Button>
                    <p>
                        Already have an account?{' '}
                        <Link component={LinkRoute} to="/login">
                            Sign in
                        </Link>{' '}
                        instead
                    </p>
                    <Popover
                        id="mouse-over-popover"
                        sx={{
                            pointerEvents: 'none',
                        }}
                        open={signup.passwordHelp}
                        anchorEl={passwordRef.current}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        onClose={() => {
                            setSignup((draft) => {
                                draft.passwordHelp = false
                            })
                        }}
                        disableAutoFocus
                    >
                        <div className="p-4 text-sm flex flex-col gap-4">
                            <Typography className="">Requirements</Typography>
                            <div className="flex gap-2">
                                {' '}
                                {!passwordValidation.minimumEightCharacter && (
                                    <CancelOutlined className="text-red-500" />
                                )}{' '}
                                {passwordValidation.minimumEightCharacter && (
                                    <CheckCircleOutline className="text-green-500" />
                                )}
                                <Typography>
                                    At least 8 character long
                                </Typography>
                            </div>
                            <div className="flex gap-2">
                                {' '}
                                {!passwordValidation.atleastOneLowercase && (
                                    <CancelOutlined className="text-red-500" />
                                )}{' '}
                                {passwordValidation.atleastOneLowercase && (
                                    <CheckCircleOutline className="text-green-500" />
                                )}
                                <Typography>
                                    At least 1 lowercase character
                                </Typography>
                            </div>
                            <div className="flex gap-2">
                                {' '}
                                {!passwordValidation.atleastOneCapitalcase && (
                                    <CancelOutlined className="text-red-500" />
                                )}{' '}
                                {passwordValidation.atleastOneCapitalcase && (
                                    <CheckCircleOutline className="text-green-500" />
                                )}
                                <Typography>
                                    At least 1 uppercase character
                                </Typography>
                            </div>
                            <div className="flex gap-2">
                                {' '}
                                {!passwordValidation.atleastOneNumber && (
                                    <CancelOutlined className="text-red-500" />
                                )}{' '}
                                {passwordValidation.atleastOneNumber && (
                                    <CheckCircleOutline className="text-green-500" />
                                )}
                                <Typography>At least 1 number</Typography>
                            </div>
                            <div className="flex gap-2">
                                {' '}
                                {!passwordValidation.atleastOneSpecialCharacter && (
                                    <CancelOutlined className="text-red-500" />
                                )}{' '}
                                {passwordValidation.atleastOneSpecialCharacter && (
                                    <CheckCircleOutline className="text-green-500" />
                                )}
                                <Typography>
                                    At least 1 special character
                                </Typography>
                            </div>
                        </div>
                    </Popover>
                </>
            }
            onSubmit={handleSubmit(onSubmit)}
        />
    )
}

export function checkPassword(password: string) {
    const minimumEightCharacter = /.{8,}/.test(password)
    const atleastOneNumber = /(?=.*?[0-9])/.test(password)
    const atleastOneCapitalcase = /(?=.*?[A-Z])/.test(password)
    const atleastOneLowercase = /(?=.*?[a-z])/.test(password)
    const atleastOneSpecialCharacter = /(?=.*?[#?!@$%^&*\-_+;,.()=\\/])/.test(
        password
    )
    return {
        minimumEightCharacter,
        atleastOneCapitalcase,
        atleastOneLowercase,
        atleastOneNumber,
        atleastOneSpecialCharacter,
    }
}
