import {
    Avatar,
    AvatarGroup,
    Box,
    Paper,
    TablePagination,
    Tooltip,
    Typography,
} from '@mui/material'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined'
import { formatDistanceToNow } from 'date-fns'
import { formattedDate } from '@Request/components/common/Functions'
import { useDashboardVisits } from '@Common/Queries/Dashboard/DashboardVisits'
import Loading from '@Common/Components/Loading'
import { useImmer } from 'use-immer'

export const DocumentVisitsCard = () => {
    const pageSize = 10
    const [page, setPage] = useImmer(0)
    const visits = useDashboardVisits(page, pageSize)
    if (visits.isLoading) {
        return <Loading />
    }
    const getContacts = (emails) => {
        const contacts = emails.map((e) => {
            return e.email
        })
        return contacts
    }

    const getUserName = (username: string, city: string, country: string) => {
        if (username) {
            return username
        }

        return `Visitor from ${city} ${country}`
    }
    const getAllContactTooltip = (contact) => {
        return (
            <>
                {contact.slice(1).map((email) => {
                    return <div>{email}</div>
                })}
            </>
        )
    }
    const dashboardVisits = visits.data.visits.map((visit) => {
        return {
            id: visit.id,
            city: visit.TrackSession.AssetTrackSession.buyerDetails?.ipData
                ?.city,
            country:
                visit.TrackSession.AssetTrackSession.buyerDetails?.ipData
                    ?.country_name,
            contact:
                visit.TrackLink && visit.TrackLink.TrackLinkContact.length > 0
                    ? getContacts(visit.TrackLink.TrackLinkContact)
                    : [],
            assetName: visit.TrackLink.AssetTrackLink.Asset.name,
            userName: visit.TrackSession.AssetTrackSession.email,
            updatedAt: visit.updatedAt,
            trackName: visit.TrackLink.TrackLinkProperties.name,
        }
    })
    return (
        <div className="flex flex-col w-full h-full overflow-hidden pt-2">
            {visits.data.count !== 0 ? (
                <div className="flex flex-col flex-1 w-full overflow-auto">
                    {dashboardVisits.map((data) => {
                        return (
                            <Box
                                sx={{
                                    '& > :not(style)': {
                                        m: 1,
                                        width: '95%',
                                        padding: '5px',
                                        border: '1px solid #F3F4F6',
                                    },
                                }}
                                className="flex flex-col w-full"
                            >
                                <Paper
                                    elevation={0}
                                    sx={{
                                        borderRadius: 2,
                                        boxShadow:
                                            '0 1.6px 3.6px 0 rgba(0,0,0,.132)',
                                    }}
                                >
                                    <div className="flex flex-col p-3 gap-y-1">
                                        <Typography variant="body2">
                                            {`${formatDistanceToNow(
                                                new Date(data.updatedAt),
                                                {
                                                    addSuffix: true,
                                                }
                                            )}, 
                            ${formattedDate(data.updatedAt)}`}
                                        </Typography>
                                        <Typography className="flex flex-row gap-x-2">
                                            <Typography>
                                                {getUserName(
                                                    data.userName,
                                                    data.city,
                                                    data.country
                                                )}
                                            </Typography>
                                            <Typography variant="body2">
                                                visited
                                            </Typography>
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            className="flex flex-row gap-x-2"
                                        >
                                            <ArticleOutlinedIcon fontSize="small"></ArticleOutlinedIcon>
                                            <Tooltip title={data.assetName}>
                                                <Typography>
                                                    {data.assetName}
                                                </Typography>
                                            </Tooltip>
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            className="flex flex-row"
                                        >
                                            <div className="flex flex-row flex-1 gap-x-2">
                                                <LinkOutlinedIcon fontSize="small"></LinkOutlinedIcon>
                                                <Tooltip title={data.trackName}>
                                                    <Typography className="w-28 truncate">
                                                        {data.trackName}
                                                    </Typography>
                                                </Tooltip>
                                            </div>
                                            {data.contact &&
                                                data.contact.length !== 0 && (
                                                    <div className="flex flex-row gap-x-1">
                                                        <div className="flex m-auto">
                                                            Sent to:
                                                        </div>
                                                        <div>
                                                            <AvatarGroup
                                                                max={
                                                                    data.contact
                                                                        .length >=
                                                                    2
                                                                        ? 2
                                                                        : 1
                                                                }
                                                            >
                                                                {data.contact
                                                                    .length >=
                                                                    1 && (
                                                                    <Tooltip
                                                                        title={
                                                                            data
                                                                                .contact[0]
                                                                        }
                                                                    >
                                                                        <Avatar
                                                                            sx={{
                                                                                width: 18,
                                                                                height: 18,
                                                                                fontSize: 12,
                                                                                bgcolor:
                                                                                    '#E5E7EB',
                                                                                color: 'grey',
                                                                            }}
                                                                            alt="avatar-first"
                                                                        >
                                                                            {data.contact[0]
                                                                                .charAt(
                                                                                    0
                                                                                )
                                                                                .toUpperCase()}
                                                                        </Avatar>
                                                                    </Tooltip>
                                                                )}
                                                                {data.contact
                                                                    .length >=
                                                                    2 && (
                                                                    <Tooltip
                                                                        title={getAllContactTooltip(
                                                                            data.contact
                                                                        )}
                                                                    >
                                                                        <Avatar
                                                                            alt="avatar-second"
                                                                            sx={{
                                                                                width: 18,
                                                                                height: 18,
                                                                                fontSize: 12,
                                                                                bgcolor:
                                                                                    '#E5E7EB',
                                                                                color: 'grey',
                                                                            }}
                                                                        >
                                                                            +1
                                                                        </Avatar>
                                                                    </Tooltip>
                                                                )}
                                                            </AvatarGroup>
                                                        </div>
                                                    </div>
                                                )}
                                        </Typography>
                                    </div>
                                </Paper>
                            </Box>
                        )
                    })}
                </div>
            ) : (
                <div className="flex-1 w-full h-full">
                    <Typography
                        variant="body2"
                        className="flex h-full justify-center text-center items-center"
                    >
                        No visits found
                    </Typography>
                </div>
            )}
            <TablePagination
                sx={{
                    height: '50px',
                }}
                rowsPerPageOptions={[pageSize]}
                colSpan={3}
                component="div"
                className="text-xs text-lightTextDisabled border-none overflow-hidden"
                count={visits?.data?.count}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={(e, newPage) => setPage(newPage)}
                backIconButtonProps={{
                    className: 'stroke-slate-50',
                }}
                nextIconButtonProps={{
                    className: 'stroke-slate-50',
                }}
            />
        </div>
    )
}
