import Typography from '@mui/material/Typography'

interface TrackControlProps {
    icon?: JSX.Element
    text: string | JSX.Element
    control: any
    show: boolean
    children?: JSX.Element
}

const TrackControls = (props: TrackControlProps) => {
    return (
        <div className="flex flex-col">
            <div className="flex py-3 justify-between ">
                <div className="flex my-auto">
                    {props.icon ? props.icon : null}
                    <Typography color={'text.secondary'}>
                        {props.text}
                    </Typography>
                </div>
                {props.control}
            </div>
            {props.show && props.children}
        </div>
    )
}

export default TrackControls
