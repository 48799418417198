import Loading from '@Common/Components/Loading'
import RightSlideContainer from '@Common/Components/RightSlideContainer'
import TableComponent from '@Common/Components/TableComponent'
import useCustomAttribute from '@Common/Queries/GetQueries/GetCustomAttributes'
import useGetTableConfiguration from '@Common/Queries/GetQueries/GetTableConfiguration'
import useUsers from '@Common/Queries/GetQueries/GetUsers'
import useInit from '@Common/Queries/GetQueries/useInit'
import useDeafaultTableConfiguration from '@Common/Queries/MutationQueries/DefaultTableConfiguration'
import useDeleteDeafaultTableConfiguration from '@Common/Queries/MutationQueries/DeleteDefaultConfiguration'
import useDeleteUserTableConfiguration from '@Common/Queries/MutationQueries/DeleteUserConfiguration'
import useDownloadSearchResult from '@Common/Queries/MutationQueries/DownloadSearchResult'
import useUserTableConfiguration from '@Common/Queries/MutationQueries/UserTableConfiguration'
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import SearchIcon from '@mui/icons-material/Search'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import FilterListIcon from '@mui/icons-material/FilterList'
import GridViewIcon from '@mui/icons-material/GridView'
import { Grid, Tooltip } from '@mui/material'

import {
    Button,
    ButtonGroup,
    Chip,
    Fade,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    Stack,
    TablePagination,
    TextField,
    Typography,
} from '@mui/material'
import FilterBar from '@Search/components/FilterBar'
import {
    ColumnOrderState,
    ColumnSizingState,
    VisibilityState,
} from '@tanstack/react-table'
import classNames from 'classnames'
import { format, isValid, parseISO } from 'date-fns'
import isEmpty from 'lodash.isempty'
import React, { useContext, useRef, useState, useEffect } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { MultipleUploadContext } from 'src/App'
import { Updater, useImmer } from 'use-immer'
import ViewAssetScreen from '../../ViewAssetScreen/ViewAssetScreen'
import AssetUploadInput from './../../Upload/AssetUploadInput'
import SingleUploadLoading from './../../Upload/SingleUploadLoading'
import DownloadSearchResult from './common/modal/DownloadSearchResult'
import Modal from './common/modal/LockAssetModal'
import DriveUpload from '../../Integration/DriveUpload'
import FilterSelectColumns from './FilterSelectColumns'
import logo from '../../assets/cognition-purple.png'
import {
    FileUploadOutlined,
    KeyboardArrowDownOutlined,
    KeyboardArrowUpOutlined,
} from '@mui/icons-material'
import useFeatureFlag from '@Common/hooks/useFeatureFlag'
import FeedBackPopper from './FeedbackPopper'
import DoneIcon from '@mui/icons-material/Done'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import { SearchGridView } from './SearchGridView'

interface SearchPageProps {
    showOptions: boolean
    search: any
    filter: Record<string, unknown>
    setFilter: Updater<Record<string, unknown>>
    isModal?: boolean
    addSearchedAssset?: any
    location: string
    onClickOfRow?: any
    isCardView: boolean
}

export interface FeedbackState {
    open: boolean
    anchorEl: (EventTarget & HTMLButtonElement) | null
}

const SearchPage: React.FC<SearchPageProps> = (props) => {
    const { assetsFiles, setAssetsFiles } = useContext(MultipleUploadContext)

    const [selected, setSelected] = useImmer({
        myDocument: false,
        documentFilter: false,
    })
    const [isCardView, setIsCardView] = useImmer(props.isCardView)
    const currentRef: any = useRef()
    const fileInputRef: any = useRef()
    const init = useInit()
    const isEnterprise = init?.data.tenant?.TenantConfig?.type === 'ENTERPRISE'
    const [drawerOpen, setDrawerOpen] = useState(false)
    const userRoles = init.data.user.UserRole.map((userRole) =>
        userRole.role.role.toLowerCase()
    )
    const tableConfiguration = useGetTableConfiguration()
    const customAttribute = useCustomAttribute()
    const usersData = useUsers()

    const googleDrive = useFeatureFlag('google_drive')

    const isAdmin =
        userRoles.includes(import.meta.env.VITE_SUPER_ADMIN_USER) ||
        userRoles.includes(import.meta.env.VITE_ADMIN_USER)
    const [asset, setAsset] = useImmer({ fileId: '', assetId: '', data: {} })
    const formattedDate = (input: string) => {
        const parsedFormat = parseISO(input)

        return isValid(parsedFormat)
            ? format(parsedFormat, 'MMM dd, yyyy')
            : input
    }
    const constructColumn = () => {
        const columnObject = [
            {
                header: 'Asset name',
                id: 'Asset Name',
                accessorKey: 'name',
                type: 'Text',
                size: 384,
                isDraggable: false,
                className: 'frozen',
                headerClassName: 'frozen',
                enablePinning: true,
                enableHiding: false,
                enableResizing: true,
            },
            {
                header: 'Owner',
                id: 'Owner',
                size: 275,

                accessorFn: (row: any) => {
                    if (row.ownerId) {
                        const user = usersData?.data?.data.find(
                            (user) => user.id === row.ownerId
                        )
                        if (user != undefined) {
                            return user.name
                        } else {
                            return 'Unknown user'
                        }
                    }
                },
                type: 'Owner',
                isDraggable: true,
                enableResizing: true,
            },
            {
                header: 'Uploaded date',
                id: 'Uploaded date',
                size: 275,

                accessorFn: (row: any) => {
                    if (row.createdAt) {
                        return formattedDate(row.createdAt)
                    }
                },
                type: 'Date',
                isDraggable: true,
                enableResizing: true,
            },
            {
                header: 'Updated date',
                id: 'Updated date',
                size: 275,
                accessorFn: (row: any) => {
                    if (row.updatedAt) {
                        return formattedDate(row.updatedAt)
                    }
                },
                type: 'Date',
                isDraggable: true,
                enableResizing: true,
            },
        ]

        const customAttrColumns = customAttribute?.data?.data?.map(
            (ele: any) => {
                return {
                    header: ele.name,
                    id: ele.id,
                    size: 275,

                    attributeId: ele.id,
                    accessorFn: (row: any) => {
                        const customValues = row.customAttributes[ele.id]
                        if (Array.isArray(customValues)) {
                            const values = ele.option.filter(
                                (ele: any) =>
                                    ele !== undefined &&
                                    customValues.includes(ele.id)
                            )

                            return values.map((e) => {
                                return { value: e.value, color: e.color }
                            })
                        } else
                            return formattedDate(row.customAttributes[ele.id])
                    },
                    type: ele.type,
                    isDraggable: true,
                    enableResizing: true,
                }
            }
        )

        const columns = columnObject.concat(customAttrColumns)
        return columns
    }

    const columns = constructColumn()
    const searchData = props.search.data.data
    const { filter, setFilter } = props
    const navigate = useNavigate()

    let finalColumns: any[] = []

    if (
        tableConfiguration?.data &&
        !isEmpty(tableConfiguration?.data.configuration)
    ) {
        //sorting the original columns with the order from saved configuration
        //new set of custom attr will be added at last
        const configuration = tableConfiguration?.data.configuration
        const columnConfig: { hiddenColumns: []; columns: any } =
            configuration.columnConfig

        const sortedColumns = columns.slice().sort((a, b) => {
            const aIndex = columnConfig.columns.findIndex(
                (targetColumn) => targetColumn.id === a.id
            )
            const bIndex = columnConfig.columns.findIndex(
                (targetColumn) => targetColumn.id === b.id
            )
            return (
                (aIndex === -1 ? Number.MAX_VALUE : aIndex) -
                (bIndex === -1 ? Number.MAX_VALUE : bIndex)
            )
        })

        finalColumns = sortedColumns.map((e, id) => e)
    } else {
        finalColumns = columns
    }

    const [feedback, setFeedback] = useImmer<FeedbackState>({
        open: false,
        anchorEl: null,
    })

    useEffect(() => {
        const timer = setTimeout(() => {
            const popupClosedOrSubmitted = localStorage.getItem(
                'popupClosedOrSubmitted'
            )
            if (isEnterprise && !popupClosedOrSubmitted?.length) {
                setFeedback({
                    ...feedback,
                    anchorEl: currentRef.current,
                    open: true,
                })
            }
        }, 8000)

        return () => {
            clearTimeout(timer)
        }
    }, [])

    const [downloadErrorCsv, setDownloadErrorCsv] = useImmer(false)

    const [tableConfig, setTableConfig] = useImmer(
        tableConfiguration?.data &&
            !isEmpty(tableConfiguration?.data.configuration)
            ? { ...tableConfiguration?.data.configuration.columnConfig }
            : {
                  hiddenColumns: [],
                  columns: finalColumns,
              }
    )
    const [columnState, setColumnState] = useImmer<{
        columnVisibility: VisibilityState
        columnSizing: ColumnSizingState
        columnOrder: ColumnOrderState
    }>({
        columnVisibility: {},
        columnSizing: {},
        columnOrder: finalColumns,
    })
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const menuOpen = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const getFilters = (
        filters: Array<{
            value: any
            filterColumn: {
                id: string
            }
        }>
    ) => {
        return filters.filter((filter) => {
            if (
                ![
                    'Asset Name',
                    'Owner',
                    'Updated date',
                    'Uploaded date',
                ].includes(filter.filterColumn.id)
            ) {
                const index = customAttribute?.data?.data?.findIndex(
                    (element) => element.id === filter.filterColumn.id
                )
                if (index != -1) {
                    return filter
                }
            } else {
                return filter
            }
        })
    }
    const [filterList, setFilterList] = useImmer(
        tableConfiguration?.data &&
            tableConfiguration?.data.configuration &&
            tableConfiguration?.data.configuration.filters
            ? getFilters(tableConfiguration?.data.configuration.filters)
            : []
    )
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setFilter((draft) => {
            draft.currentPage = newPage
        })
    }

    const downloadSearchResult = useDownloadSearchResult()
    const onSuccessConfigPost = () => {
        toast.success('Configuration saved successfully')
    }

    const onErrorSavingConfig = () => {
        toast.error('Error in saving configuration')
    }
    const onSuccessConfigDelete = () => {
        const columns = constructColumn()
        setColumnState((draft) => {
            return {
                columnVisibility: {},
                columnSizing: {},
                columnOrder: columns,
            }
        })
        setFilterList((draft) => {
            return []
        })
        toast.success('Configuration deleted successfully')
    }
    const onErrorDeletingConfig = () => {
        toast.error('Error in deleting configuration')
    }
    const createDefaultConfiguration = useDeafaultTableConfiguration({
        onSuccess: onSuccessConfigPost,
        onError: onErrorSavingConfig,
    })
    const createUserConfiguration = useUserTableConfiguration({
        onSuccess: onSuccessConfigPost,
        onError: onErrorSavingConfig,
    })
    const setTableConfigState = (tableConfiguration) => {
        setTableConfig((draft) => (draft = tableConfiguration))
    }
    const deleteDefaultConfiguration = useDeleteDeafaultTableConfiguration({
        onSuccess: onSuccessConfigDelete,
        onError: onErrorDeletingConfig,
    })
    const deleteUserConfiguration = useDeleteUserTableConfiguration({
        onSuccess: onSuccessConfigDelete,
        onError: onErrorDeletingConfig,
    })

    const downloadCSV = (count: number) => {
        const download = downloadSearchResult.mutate(props.filter, {
            onSuccess: async (data) => {
                const url = window.URL.createObjectURL(new Blob([data.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'Search Result.csv')
                document.body.appendChild(link)
                link.click()
                if (count > 1000) {
                    setDownloadErrorCsv(true)
                }
            },
        })
    }

    const removeTheFilter = (column: any) => {
        if (column.id === 'Updated date') {
            setFilter((draft) => {
                draft.filter.updatedAt = {
                    startDate: '',
                    endDate: '',
                }
                draft.currentPage = 0
            })
        } else if (column.id === 'Uploaded date') {
            setFilter((draft) => {
                draft.filter.createdAt = {
                    startDate: '',
                    endDate: '',
                }
                const data: [{ [x: string]: any }] = draft.selectedFilters
                draft.currentPage = 0
            })
        } else if (column.id === 'Asset Name') {
            setFilter((draft) => {
                draft.filter.assetName = ''
                draft.currentPage = 0
            })
        } else if (column.id === 'Owner') {
            setFilter((draft) => {
                draft.filter.ownerId = []
                draft.currentPage = 0
            })
        } else if (column.id === 'Asset Type') {
            setFilter((draft) => {
                draft.filter.assetType = []
                draft.currentPage = 0
            })
        } else {
            setFilter((draft) => {
                delete draft.filter.customAttributeFilter[column.id]
                draft.currentPage = 0
            })
        }
    }
    const addComponent = (event, column) => {
        const data = [...filterList]
        const index = data.findIndex(
            (x) => x.filterColumn.label === column.header
        )
        if (index != -1) {
            data.splice(index, 1)
            setFilterList((draft) => (draft = data))
            removeTheFilter(column)
        } else {
            setFilterList((draft) => {
                draft.push({
                    filterColumn: {
                        label: column.header,
                        type: column.type,
                        id: column.id,
                    },
                    values: [],
                })
            })
        }
    }
    const handleUploadClick = () => {
        navigate('/search')
        fileInputRef.current.click()
    }

    const saveTableConfiguration = (role: string): void => {
        const body = {
            columnConfig: tableConfig,
            filters: filterList.map((e) => {
                return {
                    filterColumn: e.filterColumn,
                    value: {},
                }
            }),
        }
        console.log('Saving configuration', body)
        if (role === 'admin') {
            createDefaultConfiguration.mutate(body)
        } else {
            createUserConfiguration.mutate(body)
        }
    }
    const deleteTableConfiguration = (role: string): void => {
        if (role === 'admin') {
            deleteDefaultConfiguration.mutate({})
        } else {
            deleteUserConfiguration.mutate({})
        }
    }
    const driveProps = {
        maxUpload: 10,
        location: 'search',
    }
    return (
        <>
            {
                <FeedBackPopper
                    feedback={feedback}
                    setFeedback={setFeedback}
                ></FeedBackPopper>
            }
            <DownloadSearchResult
                isOpen={downloadErrorCsv}
                setIsOpen={setDownloadErrorCsv}
            ></DownloadSearchResult>
            <div className="w-full h-full flex flex-row">
                <div className="flex flex-col flex-1 overflow-hidden">
                    <div className="flex- flex-col px-6">
                        {!props.isModal && (
                            <>
                                <div className="flex justify-between pb-4 items-center">
                                    <div className="flex items-center">
                                        <div className="w-44">
                                            <img src={logo} />
                                        </div>
                                        <div className="flex gap-2">
                                            <Typography variant={'h5'}>
                                                |
                                            </Typography>
                                            <Typography variant={'h5'}>
                                                All Documents
                                            </Typography>
                                        </div>
                                    </div>
                                    <Stack direction="row">
                                        <div className="pr-4">
                                            <TextField
                                                data-testid="searchpage-searchbar"
                                                variant="outlined"
                                                placeholder="Search"
                                                value={filter.searchTerm}
                                                onChange={(event) => {
                                                    setFilter((draft) => {
                                                        draft.searchTerm =
                                                            event.target.value
                                                        draft.currentPage = 0
                                                    })
                                                }}
                                                InputProps={{
                                                    className: 'p-0',
                                                    autoComplete: 'off',

                                                    startAdornment: (
                                                        <InputAdornment
                                                            position="start"
                                                            className="p-3.5"
                                                        >
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                size="small"
                                            ></TextField>
                                        </div>
                                        <div>
                                            <AssetUploadInput
                                                fileInputRef={fileInputRef}
                                                multiple={true}
                                                drawerOpen={drawerOpen}
                                                setAsset={setAsset}
                                                setDrawerOpen={setDrawerOpen}
                                            ></AssetUploadInput>

                                            <ButtonGroup
                                                size="medium"
                                                variant="contained"
                                            >
                                                <Button
                                                    data-testid="searchpage-uploadexplorer-btn"
                                                    startIcon={
                                                        <FileUploadOutlined fontSize="small" />
                                                    }
                                                    onClick={() => {
                                                        handleUploadClick()
                                                    }}
                                                >
                                                    Upload
                                                </Button>
                                                {googleDrive && (
                                                    <DriveUpload
                                                        pickerParam={driveProps}
                                                    />
                                                )}
                                            </ButtonGroup>
                                        </div>
                                        <div>
                                            {props.showOptions && (
                                                <IconButton
                                                    size="small"
                                                    onClick={handleClick}
                                                    className="hover:cursor-pointer"
                                                    data-testid="searchpage-moreoption-iconbtn"
                                                >
                                                    <MoreVertIcon fontSize="small"></MoreVertIcon>
                                                </IconButton>
                                            )}

                                            <Menu
                                                anchorEl={anchorEl}
                                                open={menuOpen}
                                                onClose={handleClose}
                                                TransitionComponent={Fade}
                                                className="mt-3"
                                                data-testid="searchpage-moreoption-menu"
                                            >
                                                {isAdmin && (
                                                    <MenuItem
                                                        onClick={() => {
                                                            handleClose()
                                                            saveTableConfiguration(
                                                                'admin'
                                                            )
                                                        }}
                                                        className="text-sm"
                                                        data-testid="searchpage-savevieweveryone-menuitem"
                                                    >
                                                        Save view for everyone
                                                    </MenuItem>
                                                )}
                                                <MenuItem
                                                    onClick={() => {
                                                        handleClose()
                                                        saveTableConfiguration(
                                                            'user'
                                                        )
                                                    }}
                                                    className="text-sm"
                                                    data-testid="searchpage-saveview-menuitem"
                                                >
                                                    Save view
                                                </MenuItem>

                                                <MenuItem
                                                    onClick={() => {
                                                        handleClose()
                                                        deleteTableConfiguration(
                                                            'user'
                                                        )
                                                    }}
                                                    disabled={
                                                        tableConfiguration &&
                                                        tableConfiguration?.data
                                                            ?.view != 'user'
                                                    }
                                                    className="text-sm"
                                                    data-testid="searchpage-resetview-menuitem"
                                                >
                                                    Reset view
                                                </MenuItem>

                                                {isAdmin && (
                                                    <MenuItem
                                                        className=" text-sm"
                                                        disabled={
                                                            searchData.count <=
                                                            0
                                                        }
                                                        onClick={() => {
                                                            handleClose()
                                                            downloadCSV(
                                                                searchData.count
                                                            )
                                                        }}
                                                        data-testid="searchpage-downloadcsv-menuitem"
                                                    >
                                                        Download to CSV
                                                    </MenuItem>
                                                )}
                                            </Menu>
                                        </div>
                                    </Stack>
                                </div>
                                <div className="flex flex-row w-full pt-4">
                                    <div className="flex flex-row w-full">
                                        <FilterBar
                                            ownerId={init.data.user.id}
                                            selected={selected}
                                            setSelected={setSelected}
                                            filter={filter}
                                            setFilter={setFilter}
                                            columnOptions={columns}
                                            customAttributes={
                                                customAttribute?.data?.data
                                            }
                                            users={usersData?.data}
                                            tableConfiguration={
                                                tableConfiguration?.data
                                            }
                                            filterList={filterList}
                                            setFilterList={setFilterList}
                                        />
                                        <FilterSelectColumns
                                            selected={selected}
                                            setSelected={setSelected}
                                            options={columns}
                                            setFilter={setFilter}
                                            filterList={filterList}
                                            onChangeHandler={addComponent}
                                        ></FilterSelectColumns>
                                    </div>
                                    <div className="">
                                        <Tooltip
                                            title={
                                                isCardView
                                                    ? 'List View'
                                                    : 'Grid View'
                                            }
                                        >
                                            <IconButton
                                                onClick={() => {
                                                    setIsCardView(!isCardView)
                                                }}
                                                data-testid="searchpage-searchviewchange-iconbtn"
                                            >
                                                {isCardView ? (
                                                    <FormatListBulletedIcon fontSize="small" />
                                                ) : (
                                                    <GridViewIcon fontSize="small" />
                                                )}
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    {isCardView ? (
                        <div className="flex-1 w-full h-full overflow-auto pt-4 pl-4">
                            <SearchGridView
                                currentRef={currentRef}
                                feedback={feedback}
                                setFeedBack={setFeedback}
                                searchData={searchData}
                                filter={filter}
                                isEnterprise={isEnterprise}
                                showOptions={props.showOptions}
                                onClickOfRow={props.onClickOfRow}
                                setAsset={setAsset}
                                usersData={usersData}
                            />
                        </div>
                    ) : (
                        <div className="flex-1 w-full h-full flex-col overflow-auto px-4 pt-4">
                            <DndProvider backend={HTML5Backend}>
                                <TableComponent
                                    showOptions={props.showOptions}
                                    isModal={props.isModal}
                                    data={searchData.assets}
                                    columns={finalColumns}
                                    filter={filter}
                                    setFilter={setFilter}
                                    tableConfiguration={
                                        tableConfiguration?.data?.configuration
                                    }
                                    columnState={columnState}
                                    setColumnState={setColumnState}
                                    tableConfigCallback={setTableConfigState}
                                    filterList={filterList}
                                    addSearchedAssset={props.addSearchedAssset}
                                    onClickOfRow={props.onClickOfRow}
                                    asset={asset}
                                    setAsset={setAsset}
                                    usersData={usersData}
                                    noResults={
                                        isEnterprise ? (
                                            <div className="flex justify-center py-4">
                                                <Typography className="text-gray-700 text-xs text-Roboto">
                                                    Didn't find what you were
                                                    looking for?
                                                </Typography>
                                                <a
                                                    className="text-filterText text-xs text-Roboto hover:cursor-pointer"
                                                    onClick={() => {
                                                        setFeedback({
                                                            ...feedback,
                                                            open: true,
                                                            anchorEl:
                                                                currentRef.current,
                                                        })
                                                    }}
                                                >
                                                    Raise a request for an asset
                                                </a>
                                            </div>
                                        ) : (
                                            <div className="flex justify-center py-4">
                                                <p className="text-gray-700 text-xs text-Roboto">
                                                    No search results for
                                                    current query
                                                </p>
                                            </div>
                                        )
                                    }
                                ></TableComponent>
                            </DndProvider>
                        </div>
                    )}
                    <div className="w-full flex flex-col">
                        <div
                            className={classNames(
                                'flex w-full h-14 overflow-hidden justify-end',
                                {
                                    'justify-between':
                                        init?.data.tenant?.TenantConfig?.type
                                            ?.type === 'ENTERPRISE',
                                }
                            )}
                        >
                            {isEnterprise && (
                                <Button
                                    data-testid="searchpage-raiserequest-btn"
                                    ref={currentRef}
                                    className={`font-normal normal-case} ${
                                        feedback.open
                                            ? 'bg-blue-200'
                                            : 'bg-white'
                                    } )`}
                                    onClick={(event) => {
                                        setFeedback({
                                            ...feedback,
                                            anchorEl: event.currentTarget,
                                            open: !feedback.open,
                                        })
                                    }}
                                >
                                    <ConfirmationNumberOutlinedIcon
                                        color="primary"
                                        className="w-5 h-6 mr-2"
                                    ></ConfirmationNumberOutlinedIcon>
                                    Raise Request
                                </Button>
                            )}
                            <div className="flex gap-4">
                                {props.search.Loading && (
                                    <div>
                                        <Loading size="2rem" />
                                    </div>
                                )}
                                {searchData.count > 0 && (
                                    <TablePagination
                                        rowsPerPageOptions={[10]}
                                        colSpan={3}
                                        component="div"
                                        className="text-xs text-lightTextDisabled border-none overflow-hidden"
                                        count={searchData.count}
                                        rowsPerPage={12}
                                        page={filter.currentPage}
                                        onPageChange={handleChangePage}
                                        backIconButtonProps={{
                                            className: 'stroke-slate-50',
                                        }}
                                        nextIconButtonProps={{
                                            className: 'stroke-slate-50',
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <RightSlideContainer
                    open={props.location === 'viewAsset' || drawerOpen}
                >
                    {drawerOpen === true && (
                        <SingleUploadLoading
                            asset={assetsFiles[0]}
                            setOpen={setDrawerOpen}
                        ></SingleUploadLoading>
                    )}
                    {props.location === 'viewAsset' && (
                        <ViewAssetScreen
                            asset={asset}
                            location={'search'}
                        ></ViewAssetScreen>
                    )}
                </RightSlideContainer>
            </div>
        </>
    )
}

export default SearchPage
