import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'

export function useDeleteContact(props?: { onSuccess: () => void }) {
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationFn: (request: { id: string }) => deleteContact(request),
        onSuccess: () => {
            queryClient.invalidateQueries('contacts')
            props.onSuccess()
        },
    })
    return mutation
}
async function deleteContact(request: { id: string }) {
    return await axios.delete(`${API_URL}/contacts/${request.id}`)
}
