import React, { ChangeEvent, RefObject, useRef, useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import Paper from '@mui/material/Paper'
import {
    Avatar,
    ButtonGroup,
    debounce,
    Divider,
    FormControl,
    ListItemText,
    Menu,
    MenuItem,
    Select,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tabs,
    Tooltip,
    Typography,
} from '@mui/material'

import { QueryClient, QueryClientProvider } from 'react-query'
// import AssetFetch from './AssetFetch';
import useSearch from '@Common/Queries/MutationQueries/Search'
import useUsers from '@Common/Queries/GetQueries/GetUsers'
import { format, isValid, parseISO } from 'date-fns'
import useDebounce from '@Common/hooks/useDebounce'
import { useImmer } from 'use-immer'
import AvatarComponent from '@Common/Components/Avatar'
import Loading from '@Common/Components/Loading'
import { Link, useNavigate } from 'react-router-dom'
import { minWidth } from '@mui/system'
import useInit from '@Common/Queries/GetQueries/useInit'
import useUserWorkspaces from 'src/Onboard/query/useUserWorkspaces'
import useSwitchWorkspace from '@Common/Components/SideNav/useSwitchWorkspace'
import { Add, ArrowDropDown, ExpandMore } from '@mui/icons-material'
import { useUpload } from '.'
import { toast } from 'react-hot-toast'
import DriveUpload from 'src/Integration/DriveUpload'
import useFeatureFlag from '@Common/hooks/useFeatureFlag'
import { redirectPage } from '@Common/utils/previousPageCheck'
import { TabPanel } from '@Common/Components/TabPanel'
import { PluginDealRoomTab } from './PluginDealRoomTab'

interface Column {
    id: 'docimage' | 'docname' | 'docdate' | 'docowner' | 'filename'
    label: string
    minWidth?: number
}
const columns: readonly Column[] = [
    { id: 'docimage', label: 'docimage', minWidth: 100 },
    { id: 'docname', label: 'docname', minWidth: 250 },
    { id: 'docowner', label: 'docowner', minWidth: 100 },
    { id: 'docdate', label: 'docdate', minWidth: 100 },
]

interface Data {
    docid: string
    filename: string
    docimage: string
    docname: string
    docdate: string
    docowner: string
}
function createData(
    docid: string,
    filename: string,
    docimage: string,
    docname: string,
    docdate: string,
    docowner: string
): Data {
    return { docid, filename, docimage, docname, docdate, docowner }
}

const formattedDate = (input: string) => {
    const parsedFormat = parseISO(input)
    return isValid(parsedFormat) ? format(parsedFormat, 'MMM dd, yyyy') : input
}

export default function Search() {
    const navigate = useNavigate()
    const [page, setPage] = useState(0)
    const [tabs, setTabs] = useImmer(0)
    const rowsPerPage = 10
    const button = useRef<HTMLButtonElement>(null)
    const upload = useRef<HTMLInputElement>(null)
    const [tenantMenu, setTenantMenu] = useImmer({ show: false })

    const [searchParameter, setSearchParameter] = useImmer({
        searchTerm: '',
        filter: {
            assetName: '',
            projectId: [],
            ownerId: [],
            assetType: [],
            createdAt: { startDate: '', endDate: '' },
            updatedAt: { startDate: '', endDate: '' },
            customAttributeFilter: {},
        },
        currentPage: 0,
        pageSize: 10,
    })

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)

        setSearchParameter((draft) => {
            draft.currentPage = newPage
        })
    }

    const handleTextSearch = (e) => {
        const searchedText = e.target.value
        setSearchParameter((draft) => {
            draft.searchTerm = searchedText
            draft.currentPage = 0
        })
        setPage(0)
    }
    const debouncedFilter = useDebounce(searchParameter, 200)
    let searchData = useSearch(debouncedFilter)

    const userData = useUsers()
    const init = useInit()
    if (searchData.isLoading || userData.isLoading || init.isLoading) {
        return <Loading></Loading>
    }
    const { handleFileUpload } = useUpload()

    // have to update this part
    const usermap = new Map()
    userData.data?.data.map((value) => {
        usermap.set(value.id, value.name)
    })
    const formattedOwner = (input: string) => {
        if (usermap.has(input)) {
            return usermap.get(input)
        }
        return 'Unknown User'
    }

    const rows: Data[] = searchData.data.data.assets.map(
        (value: {
            id: string
            filename: string
            imageUrl: string
            name: string
            updatedAt: string
            ownerId: string
        }): Data => {
            return createData(
                value.id,
                value.filename,
                value.imageUrl,
                value.name,
                formattedDate(value.updatedAt),
                formattedOwner(value.ownerId)
            )
        }
    )

    const supportedTrackFormat = ['pdf', 'ppt', 'pptx', 'doc', 'docx']
    function allowTrack(filename: string) {
        const expression = /\.([0-9a-z]+)(?:[\?#]|$)/
        const result = filename.toLowerCase().match(expression)
        return (
            result &&
            result[1] &&
            supportedTrackFormat.findIndex((format) => format === result[1]) >=
                0
        )
    }

    function TenantIcon(props: {
        buttonRef: RefObject<HTMLButtonElement>
        tenantMenu: { show: boolean }
        handleClose: () => void
    }) {
        const init = useInit()
        const path = window.location.pathname.split('/')[1]
        let url = window.location.origin
        if (path !== 'null' && path !== 'undefined') {
            url = url + '/' + path
        }
        const userWorkspaces = useUserWorkspaces()
        const switchWorkspace = useSwitchWorkspace({
            onSuccess: () => {
                window.location.href = url
            },
        })
        if (props.tenantMenu.show && userWorkspaces.isLoading)
            return <Loading />
        if (!props.tenantMenu.show && userWorkspaces.isLoading) return <></>
        return (
            <Menu
                id="basic-menu"
                anchorEl={props.buttonRef.current}
                open={props.tenantMenu.show}
                onClose={props.handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <div className="pb-2 px-4 text-xs text-gray-500">
                    {init.data.user.name}
                </div>
                <Divider />
                {userWorkspaces.data.map((userWorkspace, ind) => {
                    return (
                        <MenuItem
                            key={ind}
                            onClick={() => {
                                switchWorkspace.mutate({
                                    workspaceId: userWorkspace.id,
                                })
                            }}
                            className="flex items-center gap-4"
                        >
                            <div>
                                <AvatarComponent
                                    className="text-gray-500"
                                    name={userWorkspace.name}
                                />
                            </div>
                            <div>
                                <div>{userWorkspace.name}</div>
                                <div className="text-gray-400">
                                    {userWorkspace.users} members
                                </div>
                            </div>
                        </MenuItem>
                    )
                })}
                <Divider />
                <MenuItem
                    onClick={() => {
                        localStorage.clear()
                        redirectPage()
                    }}
                    className="w-52"
                >
                    <ListItemText>Log Out</ListItemText>
                </MenuItem>
            </Menu>
        )
    }

    const googleDrive = useFeatureFlag('google_drive')

    const params = new URLSearchParams(window.location.search)
    const sourceOrigin = params.get('origin')
    const driveProps = {
        origin:
            sourceOrigin === 'outlook'
                ? 'https://outlook.office365.com/'
                : 'https://mail.google.com/',
        maxUpload: 1,
        location: 'plugin',
    }

    return (
        <div className="flex flex-col w-full h-full">
            <div className="flex w-full p-2 py-4 flex-col">
                <div className="flex flex-row">
                    <div className="flex-1 border-b">
                        <Tabs
                            value={tabs}
                            onChange={(event, newValue) => {
                                setTabs(newValue)
                            }}
                        >
                            <Tab value={0} label="All documents"></Tab>
                            <Tab value={1} label="Digital room"></Tab>
                        </Tabs>
                    </div>
                    <div className="flex h-full border-gray-300 items-center border-b">
                        <Typography
                            variant="caption"
                            className="whitespace-nowrap"
                        >
                            Select workspace:
                        </Typography>
                        <Button
                            ref={button}
                            onClick={() => {
                                setTenantMenu((draft) => {
                                    draft.show = true
                                })
                            }}
                            className="flex text-gray-600 p-2 justify-between"
                        >
                            <div className="flex items-center">
                                <div className="flex">
                                    <Tooltip
                                        title={init.data.tenant.name || ''}
                                    >
                                        <Typography
                                            variant="body2"
                                            className=" w-20 whitespace-nowrap truncate text-left"
                                        >
                                            {init.data.tenant.name}
                                        </Typography>
                                    </Tooltip>
                                    <Typography
                                        variant="body2"
                                        className="flex-1"
                                    >
                                        <ExpandMore />
                                    </Typography>
                                </div>
                            </div>
                        </Button>
                        <TenantIcon
                            buttonRef={button}
                            handleClose={() => {
                                setTenantMenu((draft) => {
                                    draft.show = false
                                })
                            }}
                            tenantMenu={tenantMenu}
                        />
                    </div>
                </div>
            </div>
            <TabPanel index={0} value={tabs} className='overflow-auto h-full w-full'>
                <div className='flex flex-col h-full w-full overflow-hidden'>
                    <div className="flex w-full py-2 px-2">
                        <div className="flex-1 px-2 content-center">
                            <TextField
                                variant="outlined"
                                value={searchParameter.searchTerm}
                                type="assetname"
                                onChange={handleTextSearch}
                                placeholder="Search document"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            ></TextField>
                        </div>
                        <div className="flex-1">
                            <div className="px-2 float-right">
                                <ButtonGroup variant="contained">
                                    <Button
                                        className=""
                                        onClick={() => {
                                            upload.current?.click()
                                        }}
                                        variant="contained"
                                    >
                                        Upload
                                    </Button>
                                    {googleDrive && (
                                        <DriveUpload pickerParam={driveProps} />
                                    )}
                                </ButtonGroup>
                                <input
                                    ref={upload}
                                    className="sr-only"
                                    onChange={(e) => {
                                        e.preventDefault()
                                        if (
                                            !e.currentTarget?.files ||
                                            e.currentTarget?.files?.length <= 0
                                        ) {
                                            return
                                        }
                                        const file = e.currentTarget.files[0]
                                        if (file) {
                                            handleFileUpload(file)
                                        }
                                    }}
                                    type="file"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full">
                        <div className="flex flex-1 flex-row font-medium ml-7 py-4">
                            <Typography
                                variant="body2"
                                className="flex-1 font-medium"
                            >
                                Document
                            </Typography>
                            <Typography
                                variant="body2"
                                className="flex-1 font-medium"
                            >
                                <p className="pl-20">Owner</p>
                            </Typography>
                            <Typography
                                variant="body2"
                                className="flex-1 font-medium"
                            >
                                <p className="pl-32">Last modified</p>
                            </Typography>
                        </div>
                    </div>
                    <div className="w-full h-full px-4 overflow-auto">
                        {searchData.data.data.count !== 0 ? (
                            <Paper
                                sx={{
                                    width: '100%',
                                    boxShadow: '5px 10px white',
                                }}
                                className="flex flex-col w-full h-full"
                            >
                                <TableContainer className="flex-1 w-full h-full">
                                    <Table className="flex-1 w-full">
                                        <TableBody className="flex flex-col w-full">
                                            {rows.map((row) => {
                                                var params =
                                                    new URLSearchParams()
                                                const rowid = row.docid
                                                params.append('fileId', rowid)
                                                const fileName = row.filename
                                                const configUrl =
                                                    '/plugin/configuration?' +
                                                    params.toString()

                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={row.docname}
                                                        className="flex w-full hover:cursor-pointer"
                                                        onClick={() => {
                                                            allowTrack(fileName)
                                                                ? navigate(
                                                                      configUrl
                                                                  )
                                                                : toast.error(
                                                                      'Cannot generate a link for this file format'
                                                                  )
                                                        }}
                                                    >
                                                        {columns.map(
                                                            (column) => {
                                                                const value =
                                                                    row[
                                                                        column
                                                                            .id
                                                                    ]

                                                                return (
                                                                    <>
                                                                        {(() => {
                                                                            switch (
                                                                                column.id
                                                                            ) {
                                                                                case 'docimage':
                                                                                    return (
                                                                                        <TableCell
                                                                                            key={
                                                                                                column.id
                                                                                            }
                                                                                            style={{
                                                                                                minWidth:
                                                                                                    column.minWidth,
                                                                                            }}
                                                                                            className="flex px-2 items-center"
                                                                                        >
                                                                                            <div className="flex-1 items-center">
                                                                                                <img
                                                                                                    src={
                                                                                                        value
                                                                                                    }
                                                                                                    className="w-20 border border-gray-400 rounded-sm h-12"
                                                                                                ></img>
                                                                                            </div>
                                                                                        </TableCell>
                                                                                    )
                                                                                case 'docowner':
                                                                                    return (
                                                                                        <Tooltip
                                                                                            title={
                                                                                                value ||
                                                                                                ''
                                                                                            }
                                                                                        >
                                                                                            <TableCell
                                                                                                key={
                                                                                                    column.id
                                                                                                }
                                                                                                style={{
                                                                                                    minWidth:
                                                                                                        column.minWidth,
                                                                                                }}
                                                                                                className="flex items-center px-2 w-full"
                                                                                            >
                                                                                                <div className="w-full flex items-center ">
                                                                                                    <Typography className="pl-1.5 item-center text-gray-500 w-24 whitespace-nowrap truncate">
                                                                                                        {
                                                                                                            value
                                                                                                        }
                                                                                                    </Typography>
                                                                                                </div>
                                                                                            </TableCell>
                                                                                        </Tooltip>
                                                                                    )
                                                                                case 'docname':
                                                                                    return (
                                                                                        <Tooltip
                                                                                            title={
                                                                                                value ||
                                                                                                ''
                                                                                            }
                                                                                        >
                                                                                            <TableCell
                                                                                                key={
                                                                                                    column.id
                                                                                                }
                                                                                                style={{
                                                                                                    minWidth:
                                                                                                        column.minWidth,
                                                                                                }}
                                                                                                className="flex items-center px-2 w-full"
                                                                                            >
                                                                                                <Typography className="flex-1 text-[#212121] w-60 whitespace-nowrap truncate font-medium">
                                                                                                    {
                                                                                                        value
                                                                                                    }
                                                                                                </Typography>
                                                                                            </TableCell>
                                                                                        </Tooltip>
                                                                                    )
                                                                                default:
                                                                                    return (
                                                                                        <Tooltip
                                                                                            title={
                                                                                                value ||
                                                                                                ''
                                                                                            }
                                                                                        >
                                                                                            <TableCell
                                                                                                key={
                                                                                                    column.id
                                                                                                }
                                                                                                style={{
                                                                                                    minWidth:
                                                                                                        column.minWidth,
                                                                                                }}
                                                                                                className="flex items-center px-2 w-full"
                                                                                            >
                                                                                                <Typography className="flex-1 text-center text-gray-500">
                                                                                                    {
                                                                                                        value
                                                                                                    }
                                                                                                </Typography>
                                                                                            </TableCell>
                                                                                        </Tooltip>
                                                                                    )
                                                                            }
                                                                        })()}
                                                                    </>
                                                                )
                                                            }
                                                        )}
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        ) : (
                            <div className="w-full text-center p-4 text-gray-400">
                                No results found. Try a different search term.
                            </div>
                        )}
                    </div>
                    <div className="h-12">
                        <TablePagination
                            component="div"
                            count={searchData.data.data.count}
                            rowsPerPageOptions={[]}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                        ></TablePagination>
                    </div>
                </div>
            </TabPanel>
            <TabPanel index={1} value={tabs} className="overflow-auto">
                <div className="flex-1 w-full h-full px-4">
                    <PluginDealRoomTab></PluginDealRoomTab>
                </div>
            </TabPanel>
        </div>
    )
}
