import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'
interface onSuccessFunction {
    (data: any, obj: any): void
}
export default function usePublishContent(props?: {
    onSuccess?: onSuccessFunction
}) {
    const queryClient = useQueryClient()
    return useMutation<any, Error, object>(
        (obj: any) =>
            axios.post(`${APi_URL}/site/content/publish`, obj, {
                headers: { 'Content-Type': 'application/json' },
            }),
        {
            onSuccess: (data, obj) => {
                if (props?.onSuccess) {
                    props.onSuccess(data, obj)
                }
            },
            onSettled: (data: any) => {
                queryClient.invalidateQueries(['sites', data.data.siteId])
            },
        }
    )
}
