import { useQuery, useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'

export default function useDownloadSearchResult() {
    const queryClient = useQueryClient()
    return useMutation<any, Error, object>(
        (body: any) =>
            axios.post(`${APi_URL}/search/download`, body, {
                headers: {
                    'Content-Type': 'application/json',
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                },
            }),
        {}
    )
}
