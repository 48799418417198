import Loading from '@Common/Components/Loading'
import useDriveUploadDetails from '@Common/Queries/GetQueries/GetDriveUploadDetails'
import { Button, Typography } from '@mui/material'
import React from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { useDriveStore } from 'src/Stores/DriveStore'

export function PluginDriveUpload() {
    const fileData = useDriveStore((state) => state.fileData)
    const accessToken = useDriveStore((state) => state.accessToken)
    const reset = useDriveStore((state) => state.reset)

    const navigate = useNavigate()
    let reload = fileData.length === 0
    let uploadResponse
    if (!reload) {
        uploadResponse = useDriveUploadDetails({
            fileDetails: fileData,
            accessToken: accessToken,
        })

        if (uploadResponse.isLoading) {
            return (
                <div className="flex flex-1 flex-col h-full w-full">
                    <div className="flex flex-1 flex-col items-center justify-center">
                        <div className="p-4">
                            <Loading />
                        </div>
                        <div className="p-4">
                            <Typography variant="body2">
                                File is being uploaded
                            </Typography>
                        </div>
                    </div>
                </div>
            )
        }
        if (uploadResponse.isError) {
            toast.error('Error in uploading from drive')
            navigate('/plugin')
        }
        if (uploadResponse.isSuccess) {
            const fileDetails = uploadResponse.data
            reset()
            if (fileDetails.data[0].status === 'error') {
                toast.error(fileDetails.data[0].error)
                navigate('/plugin')
            } else {
                var params = new URLSearchParams()
                const assetId = fileDetails.data[0].data.id
                params.append('fileId', assetId)
                const configUrl = '/plugin/configuration?' + params.toString()
                navigate(configUrl)
            }
        }
    }
    return (
        <div className="flex w-full h-full">
            <div className="flex m-auto">
                <Button
                    variant="contained"
                    onClick={() => {
                        navigate('/plugin')
                    }}
                >
                    Go back to homepage
                </Button>
            </div>
        </div>
    )
}
