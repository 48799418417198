import { useQuery } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'
import { UsersIcon } from '@heroicons/react/outline'

const getGroups = async () => {
    const { data } = await axios.get(`${APi_URL}/groups`, {
        headers: { 'Content-Type': 'application/json' },
    })
    return {
        data: data,
        option: data.map((group: any) => ({
            value: group.id,
            label: group.name,
            key: 'group',
            name: group.name,
        })),
    }
}

export default function useGroups() {
    return useQuery('Groups', getGroups)
}
