import Loading from '@Common/Components/Loading'
import { getOptions } from '@Common/Components/Select/Accounts'
import { useSearchForDealRoom } from '@Common/Queries/Dealroom/SearchDealRoom'
import useDebounce from '@Common/hooks/useDebounce'
import {
    Button,
    InputAdornment,
    Typography,
} from '@mui/material'
import {
    DataGridPro,
    FilterColumnsArgs,
    GetColumnForNewFilterArgs,
    GridColDef,
    GridFilterModel,
    GridLogicOperator,
    GridToolbarContainer,
    GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro'
import { useNavigate } from 'react-router-dom'
import { useAccounts } from 'src/Accounts/useAccounts'
import SearchIcon from '@mui/icons-material/Search'
import { useImmer } from 'use-immer'
import PopoverComponent from '@Common/Components/PopoverComponent'
import { CreateDealRoom } from 'src/DealRoom/Components/CreateDealRoom'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import useUsers from '@Common/Queries/GetQueries/GetUsers'
import { useCreateDealRoom } from '@Common/Queries/Dealroom/CreateDealRoom'

export const PluginDealRoomTab = () => {
    const navigate = useNavigate()
    const [filter, setFilter] = useImmer({
        page: 0,
        pageSize: 10,
        items: [],
        status: 'ACTIVE',
        logicOperator: GridLogicOperator.And,
        quickFilterLogicOperator: GridLogicOperator.Or,
        quickFilterValues: [],
    })

    const accounts = useAccounts({ pageSize: 10, page: 0, search: '' })
    const debouncedFilter = useDebounce(filter, 200)
    const dealRoomSearch = useSearchForDealRoom(debouncedFilter)
    const users = useUsers()

    const createDealRoomQuery = useCreateDealRoom({
        onSuccess: (data) => {
            window.open(`/dealroom/${data.data.id}`)
        },
    })

    if (dealRoomSearch.isLoading || accounts.isLoading) {
        return <Loading />
    }
    const accountOptions = getOptions(accounts)

    const dealRoomData = dealRoomSearch.data

    const columns: GridColDef[] = [
        {
            field: 'digitalroom',
            headerName: 'Digital Room',
            flex: 1,
            sortable: false,
            renderCell: (params) => {
                console.log(params)
                return (
                    <Typography>
                        {params.row.DealRoomProperties.name}
                    </Typography>
                )
            },
        },
        {
            field: 'account',
            headerName: 'Account',
            flex: 1,
            sortable: false,
            renderCell: (params) => {
                const accountId = params.row.DealRoomProperties.accountId
                if (accountId != null) {
                    const accountName = accountOptions.find((event) => {
                        return event.value === accountId
                    })
                    if (accountName !== undefined) {
                        return (
                            <Typography variant="body2">
                                {accountName.label}
                            </Typography>
                        )
                    }
                }
                return <Typography variant="body2">--</Typography>
            },
        },
        {
            field: 'createdBy',
            headerName: 'Owner',
            flex: 1,
            sortable: false,
            renderCell: (params) => {
                console.log(params)
                const owner = users.data?.data.find((e) => {
                    return e.id === params.value
                })
                if (owner === undefined) {
                    return <Typography variant="body2">Unknown user</Typography>
                }
                return <Typography variant="body2">{owner.name}</Typography>
            },
        },
    ]

    const onFilterChange = (filterModel: GridFilterModel) => {
        const items = filterModel.items.filter((e) => {
            if (e.value) {
                return true
            }
            return false
        })
        setFilter((draft) => {
            draft.logicOperator = filterModel.logicOperator
            draft.quickFilterLogicOperator =
                filterModel.quickFilterLogicOperator
            draft.quickFilterValues = filterModel.quickFilterValues
            draft.items = items
        })
    }
    const CustomToolbar = () => {
        return (
            <GridToolbarContainer className="w-full flex justify-between">
                <GridToolbarQuickFilter
                    placeholder="Search"
                    variant="outlined"
                    size="small"
                    data-testid="dealroom-search-input"
                    InputProps={{
                        autoComplete: 'off',
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                ></GridToolbarQuickFilter>
                <Button
                    startIcon={<AddOutlinedIcon></AddOutlinedIcon>}
                    onClick={(event) => {
                        createDealRoomQuery.mutate({
                            name: 'Untitled',
                            opportunity: null,
                            accountId: null
                        })
                    }}
                    disabled={createDealRoomQuery.isLoading}
                    variant="contained"
                >
                    Create room
                    <div className="pr-2 pt-1">
                        {createDealRoomQuery.isLoading && (
                            <Loading size="1rem" />
                        )}
                    </div>
                </Button>
            </GridToolbarContainer>
        )
    }
    return (
        <DataGridPro
            rows={dealRoomSearch?.data?.dealRoom}
            columns={columns}
            rowCount={dealRoomData.count}
            filterMode="server"
            onFilterModelChange={onFilterChange}
            paginationMode="server"
            pageSizeOptions={[10]}
            pagination={true}
            paginationModel={filter}
            onPaginationModelChange={(event) => {
                setFilter((draft) => {
                    draft.page = event.page
                })
            }}
            onRowClick={(params) => {
                const rowId = params.row.id
                const code = params.row.code
                const url = `/dealroom/share?id=${rowId}&code=${code}&source=plugin`
                navigate(url)
            }}
            initialState={{
                filter: {
                    filterModel: {
                        items: filter.items,
                        logicOperator:
                            filter.logicOperator === 'and'
                                ? GridLogicOperator.And
                                : GridLogicOperator.Or,
                        quickFilterLogicOperator:
                            filter.quickFilterLogicOperator === 'and'
                                ? GridLogicOperator.And
                                : GridLogicOperator.Or,
                        quickFilterValues: filter.quickFilterValues,
                    },
                },
            }}
            slots={{
                toolbar: CustomToolbar,
                noRowsOverlay: () => (
                    <div className="flex justify-center items-center p-4">
                        {filter.items.length === 0 &&
                        filter.quickFilterValues.length === 0
                            ? 'Create your first deal room.'
                            : 'No matching Dealroom.'}
                    </div>
                ),
                noResultsOverlay: () => (
                    <div className="flex justify-center items-center p-4">
                        No results found.
                    </div>
                ),
            }}
            sx={{
                border: 'none',
                '& .MuiDataGrid-row:hover': {
                    cursor: 'pointer',
                },
            }}
        ></DataGridPro>
    )
}
