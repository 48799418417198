import { Fab, Popover, Typography } from '@mui/material'
import { useRef } from 'react'
import { useImmer } from 'use-immer'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'

export default function HelpWidget() {
    const ref = useRef()
    const [help, setHelp] = useImmer({
        showOptions: false,
    })
    return (
        <>
            <Fab
                ref={ref}
                size="small"
                onClick={() => {
                    setHelp((draft) => {
                        draft.showOptions = true
                    })
                }}
                color="default"
                className="fixed bottom-16 right-5"
                aria-label="help"
            >
                <QuestionMarkIcon />
            </Fab>
            <Popover
                id={'help'}
                open={help.showOptions}
                anchorEl={ref.current}
                onClose={() => {
                    setHelp((draft) => {
                        draft.showOptions = false
                    })
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div className="p-4 flex flex-col gap-2">
                    <Typography variant="body2">Discover</Typography>
                    <a
                        href="https://docs.lifex.cloud/Getting%20started/Video%20tutorials"
                        target="_blank"
                    >
                        <Typography>Onboarding video</Typography>
                    </a>
                    <a href="https://docs.lifex.cloud" target="_blank">
                        <Typography>Product documentation</Typography>
                    </a>
                    <Typography variant="body2">Connect</Typography>
                    <a
                        href=" https://lifex-help.freshdesk.com/support/tickets/new"
                        target="_blank"
                    >
                        <Typography>Request support</Typography>
                    </a>
                    <a
                        href="https://outlook.office365.com/owa/calendar/Expertconnect@m365.lifex.cloud/bookings/"
                        target="_blank"
                    >
                        <Typography>Expert connect</Typography>
                    </a>
                </div>
            </Popover>
        </>
    )
}
