/* This example requires Tailwind CSS v2.0+ */
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
interface PaginationProps {
    total: number
    pageSize: number
    setFilter: any
    currentPage: number
    resultsPerPage: number
    setSelected: Updater<{
        selectAll: boolean
        selectGlobal: boolean
        selectedRow: never[]
    }>
}
import { usePagination } from 'react-use-pagination'
import classNames from 'classnames'
import { Updater } from 'use-immer'

export default function Pagination(props: PaginationProps) {
    const {
        currentPage,
        totalPages,
        setNextPage,
        setPage,
        setPreviousPage,
        nextEnabled,
        previousEnabled,
        startIndex,
        endIndex,
    } = usePagination({
        totalItems: props.total,
        initialPageSize: props.pageSize,
        initialPage: props.currentPage,
    })
    function getRange(start: number, end: number) {
        return Array(end - start + 1)
            .fill('')
            .map((v, i) => i + start)
    }

    function pagination(
        current: number,
        length: number,
        delta = 4
    ): (number | string)[] {
        const range = {
            start: Math.round(current - delta / 2),
            end: Math.round(current + delta / 2),
        }

        if (range.start - 1 === 1 || range.end + 1 === length) {
            range.start += 1
            range.end += 1
        }

        let pages =
            current > delta
                ? getRange(
                      Math.min(range.start, length - delta),
                      Math.min(range.end, length)
                  )
                : getRange(1, Math.min(length, delta + 1))

        const withDots = (value, pair) =>
            pages.length + 1 !== length ? pair : [value]

        if (pages[0] !== 1) {
            pages = withDots(1, [1, '...']).concat(pages)
        }

        if (pages[pages.length - 1] < length) {
            pages = pages.concat(withDots(length, ['...', length]))
        }

        return pages
    }
    return (
        <div className="bg-white px-4 py-3 flex items-center justify-between sm:px-6">
            <div className="flex-1 flex justify-between sm:hidden">
                {previousEnabled && (
                    <button
                        onClick={setPreviousPage}
                        className="relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-gray-400 bg-white hover:bg-gray-50"
                    >
                        Previous
                    </button>
                )}
                {nextEnabled && (
                    <button
                        onClick={setNextPage}
                        className="ml-3 relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-md text-gray-400 bg-white hover:bg-gray-50"
                    >
                        Next
                    </button>
                )}
            </div>
            <div className="flex-1 flex items-center justify-between">
                <div>
                    <nav
                        className="relative z-0 inline-flex rounded-md"
                        aria-label="Pagination"
                    >
                        <button
                            disabled={!previousEnabled}
                            onClick={() => {
                                setPreviousPage()
                                props.setFilter((draft) => {
                                    draft.currentPage = currentPage - 1
                                })
                                props.setSelected((draft) => {
                                    draft.selectAll = false
                                    draft.selectGlobal = false
                                    draft.selectedRow = []
                                })
                            }}
                            className={classNames(
                                'relative inline-flex items-center  px-2 py-2 rounded-l-md  bg-white text-sm font-medium text-gray-500 hover:bg-gray-50',
                                {
                                    hidden: !previousEnabled,
                                }
                            )}
                        >
                            <span className="sr-only">Previous</span>
                            <ChevronLeftIcon
                                className="h-5 w-5 mx-2"
                                aria-hidden="true"
                            />
                        </button>
                        {pagination(currentPage + 1, totalPages).map(
                            (page, index) => {
                                return (
                                    <button
                                        key={index}
                                        disabled={page === '...'}
                                        onClick={() => {
                                            setPage(page - 1)
                                            props.setFilter((draft) => {
                                                draft.currentPage = page - 1
                                            })
                                        }}
                                        aria-current="page"
                                        className={`z-10 ${
                                            currentPage === page - 1
                                                ? 'bg-blue-50  text-blue-600'
                                                : ''
                                        } relative inline-flex items-center py-2 text-sm font-medium px-4`}
                                    >
                                        {page}
                                    </button>
                                )
                            }
                        )}

                        <button
                            disabled={!nextEnabled}
                            onClick={() => {
                                setNextPage()
                                props.setFilter((draft) => {
                                    draft.currentPage = currentPage + 1
                                })
                                props.setSelected((draft) => {
                                    draft.selectAll = false
                                    draft.selectGlobal = false
                                    draft.selectedRow = []
                                })
                            }}
                            className={classNames(
                                'relative  inline-flex items-center px-2 py-2 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50',
                                {
                                    hidden: !nextEnabled,
                                }
                            )}
                        >
                            <span className="sr-only">Next</span>
                            <ChevronRightIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                            />
                        </button>
                    </nav>
                </div>
            </div>
        </div>
    )
}
