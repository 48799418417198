import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import toast from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'

export function useDeleteAssetSection(props?: any) {
    const queryClient = useQueryClient()
    return useMutation(
        async (obj) =>
            await axios.delete(
                `${API_URL}/dealroom/${obj.id}/section/asset/${obj.body.sectionAssetId}`,
                obj.body
            ),
        {
            onSuccess: async (data: any, obj) => {
                queryClient.invalidateQueries(['dealrooom', data.data.id])
                queryClient.invalidateQueries(['dealrooom', 'search'])
            },
            onMutate: (data) => {
                const dealroomData = queryClient.getQueryData([
                    'dealroom',
                    data.id,
                ])
                if (!dealroomData) {
                    return
                }

                const dealRoomSection = dealroomData.dealRoom.DealRoomSection
                const dealRoomSectionIndex = dealRoomSection.findIndex(
                    (e) => e.id === data.body.sectionId
                )
                if (dealRoomSectionIndex === -1) {
                    return
                }
                const dealRoomSectionAsset =
                    dealRoomSection[dealRoomSectionIndex].DealRoomSectionAsset
                const dealRoomSectionAssetIndex =
                    dealRoomSectionAsset.findIndex(
                        (e) => e.id === data.body.sectionAssetId
                    )
                if (dealRoomSectionAssetIndex === -1) {
                    return
                }
                dealRoomSectionAsset[dealRoomSectionAssetIndex].status =
                    'DELETED'
                dealRoomSection[dealRoomSectionIndex].DealRoomSectionAsset =
                    dealRoomSectionAsset
                queryClient.setQueryData(['dealroom', data.id], {
                    dealRoom: {
                        ...dealroomData.dealRoom,
                        DealRoomSection: dealRoomSection,
                    },
                    thumbnail: dealroomData.thumbnail,
                })
                
            },
            onError: (data: any, obj: any, context: any) => {
                toast.error('Error in deleting Asset')
            },
        }
    )
}
