import { useQuery, useMutation } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'

export default function useDownload(props: any) {
    return useQuery<any, Error, string>(
        ['assets', 'download', props.id],
        async () => {
            const result = await axios.get(`${APi_URL}/download/${props.id}`, {
                headers: { 'Content-Type': 'application/json' },
            })
            return result.data
        },
        {
            retry(failureCount, error) {
                if (error.response.status === 401) {
                    return false
                }
                if (error.response.status === 400) {
                    return false
                }
                return true
            },
            onSuccess: props.onSuccess,
            onError: (e) => {
                console.log(e)
            },
        }
    )
}
