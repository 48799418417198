import AvatarComponent from '@Common/Components/Avatar'
import Loading from '@Common/Components/Loading'
import {
    AccessTimeOutlined,
    LinkOutlined,
    VisibilityOutlined,
} from '@mui/icons-material'
import { Divider, Link, Typography } from '@mui/material'
import { format } from 'date-fns'
import { Editable as FieldEditable } from 'src/Common/Components/Editable'

import { Link as LinkComponent, useParams } from 'react-router-dom'
import { useAccount } from 'src/Accounts/useAccount'
import { Field } from 'src/Contacts/ContactDetails'
import { Activity } from '../Contacts/Activity'
import { useUpdateAccount } from './useUpdateAccount'
import { TextFieldThrottled } from '@Common/Components/TextFieldThrottled'

export function AccountDetails() {
    let { id } = useParams()
    const account = useAccount(id)
    const updateAccount = useUpdateAccount()
    if (account.isLoading) {
        return <Loading />
    }
    if (account.isError) {
        return (
            <div>'Failed to get account details. Please refesh the page.'</div>
        )
    }
    const contactColumn = account.data.userTable.UserTableMeta.find(
        (meta) => meta.columnName === 'Domain name'
    )

    return (
        <div className="w-full">
            <div className="flex gap-1 p-6">
                <Link component={LinkComponent} to="/accounts">
                    <Typography variant="body2">Accounts</Typography>
                </Link>
                /
                <Typography>{account.data.detail[contactColumn.id]}</Typography>
            </div>
            <Divider />
            <div className="flex">
                <div className="flex-1">
                    <div className="p-4">
                        <Typography variant="subtitle1">Activity</Typography>
                    </div>
                    <div className="w-full items-center flex justify-center h-10 bg-gray-50">
                        <div className="flex gap-2 mr-9">
                            {' '}
                            <Typography variant="body2">
                                <LinkOutlined className="mr-2" />
                                Links:
                            </Typography>
                            <Typography>
                                {account.data.accountTrackDetails?.linkCount}
                            </Typography>
                        </div>
                        <div className="flex gap-2  mr-9 items-center">
                            {' '}
                            <Typography variant="body2">
                                <VisibilityOutlined className="mr-2" />
                                Total Visits:
                            </Typography>
                            <Typography>
                                {account.data.accountTrackDetails?.visits}
                            </Typography>
                        </div>
                        <div className="flex gap-2  mr-9 items-center">
                            {' '}
                            <Typography
                                variant="body2"
                                className="flex items-center"
                            >
                                <AccessTimeOutlined className="mr-2" />
                                Last engagement:
                            </Typography>
                            <Typography>
                                {account.data.accountTrackDetails
                                    ?.lastEngagementTime &&
                                    format(
                                        new Date(
                                            account.data.accountTrackDetails?.lastEngagementTime
                                        ),
                                        'dd MMM yyyy'
                                    )}
                            </Typography>
                        </div>
                    </div>
                    <Activity contact={account.data} />
                </div>
                <div className="w-1/3 p-4 bg-gray-50">
                    <div className="flex gap-2 mb-6 items-center">
                        <AvatarComponent
                            name={account.data.detail[contactColumn.id]}
                        ></AvatarComponent>{' '}
                        <div>
                            {/* this is the editable component for editing of account name */}
                            {/* <FieldEditable
                                label={() => {
                                    return (
                                        <Typography variant="h6">
                                            {' '}
                                            {
                                                account.data.detail[
                                                    contactColumn.id
                                                ]
                                            }
                                        </Typography>
                                    )
                                }}
                                value={account.data.detail[contactColumn.id]}
                                editor={TextFieldThrottled}
                                editorProps={{
                                    value: account.data.detail[
                                        contactColumn.id
                                    ],
                                    onDebouncedChange: (value) => {
                                        updateAccount.mutate({
                                            recordId: account.data.detail.id,
                                            columnId: contactColumn.id,
                                            value,
                                        })
                                    },
                                }}
                                className="h-8 flex items-center flex-1"
                            /> */}
                            <Typography variant="h6">
                                {account.data.detail[contactColumn.id]}
                            </Typography>

                            <Typography variant="body2">
                                Added By {account.data.detail.createdby} on{' '}
                                {format(
                                    new Date(account.data.detail.createdat),
                                    'dd MMM yyyy'
                                )}
                            </Typography>
                        </div>
                    </div>
                    {account.data.userTable.UserTableMeta.filter(
                        (meta) => meta.id !== contactColumn.id
                    ).map((meta) => {
                        return (
                            <Field
                                meta={meta}
                                key={meta.id}
                                updateContact={updateAccount}
                                detail={account.data.detail}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
