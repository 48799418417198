import Loading from '@Common/Components/Loading'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import { Button, TextField } from '@mui/material'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import useCreateAndJoinWorkspace from './query/useCreateAndJoinWorkspace'

export default function CreateWorkspace({ cancel }: { cancel: () => void }) {
    const workspaceName = useRef<HTMLInputElement>(null)
    const navigate = useNavigate()
    const sendDataToGTM = useGTMDispatch()

    const createAndJoinWorkspace = useCreateAndJoinWorkspace({
        onSuccess: () => {
            sendDataToGTM({
                event: 'workspace_created',
            })
        },
    })

    return (
        <div className="flex bg-gray-50 justify-center items-center w-full h-full flex-col">
            <div className="absolute top-4 right-3">
                <Button onClick={cancel}>Cancel</Button>
            </div>
            <div className="text-xl  font-medium">Create a team workspace</div>
            <div className="p-6 text-gray-400">
                A team workspace is a platform to collaborate and work with your
                team
            </div>
            <TextField
                helperText="The name of your business or institution"
                placeholder="Enter workspace name"
                className="mb-8"
                disabled={createAndJoinWorkspace.isLoading}
                inputRef={workspaceName}
            />
            <Button
                variant="contained"
                className="px-12"
                onClick={() => {
                    if (!workspaceName.current?.value) {
                        return
                    }
                    createAndJoinWorkspace.mutate({
                        name: workspaceName.current.value,
                    })
                }}
                disabled={
                    createAndJoinWorkspace.isLoading ||
                    createAndJoinWorkspace.isSuccess
                }
            >
                {createAndJoinWorkspace.isLoading && (
                    <div className="mr-2">
                        <Loading size="1.5rem" />
                    </div>
                )}
                Create
            </Button>
        </div>
    )
}
