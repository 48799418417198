import { useQuery } from 'react-query'
import axios from 'axios'
import { API_URL } from '@Common/Types/configs/urlConfig'
import { authManager } from 'src/AuthManager'
const getInit = async (props: { tenantId?: string } = {}) => {
    const { data } = await axios.post(`${API_URL}/init`, props)
    return data
}

export default function useInit() {
    let tenantId: string | null = null

    const jwt = authManager._user

    const initRaw = localStorage.getItem(`init-${jwt?.sub}`)
    const init = initRaw ? JSON.parse(initRaw) : {}
    tenantId = init.workspace

    return useQuery(
        ['init', tenantId],
        () => getInit(tenantId ? { tenantId } : {}),
        {
            refetchOnWindowFocus: false,
            onError: (error) => {
                console.log(JSON.stringify(error))
            },
        }
    )
}
