import { useQuery } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'

const getGroups = async (filter) => {
    const { data } = await axios.post(`${APi_URL}/groups/search`, filter, {
        headers: { 'Content-Type': 'application/json' },
    })
    return {
        data: data,
        option: data.groups.map((group: any) => ({
            value: group.id,
            label: group.name,
            key: 'group',
            name: group.name,
        })),
    }
}

export default function useSearchGroups(filter = { searchTerm: '' }) {
    return useQuery(['Groups', filter], () => getGroups(filter))
}
