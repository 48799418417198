import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'
import { JoinWorkspace } from '@Server/joinWorkspace'
import useLocalStorage from '@Common/hooks/useLocalStorage'
import { authManager } from 'src/AuthManager'

export default function useJoinWorkspace(props?: { onSuccess: () => void }) {
    const queryClient = useQueryClient()

    const [init, setInit] = useLocalStorage(
        `init-${authManager._user?.sub}`,
        {}
    )
    return useMutation<any, Error, JoinWorkspace>(
        (obj) =>
            axios.post(`${APi_URL}/workspace/join`, obj, {
                headers: { 'Content-Type': 'application/json' },
            }),
        {
            onSuccess: (result) => {
                setInit({
                    workspace:
                        result.data?.currentTenantUser?.tenantId ||
                        result.data.tenantUser.tenantId,
                })
                queryClient.invalidateQueries(['init'])
                props?.onSuccess()
                window.location.href = '/'
            },
        }
    )
}
