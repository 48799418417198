import { TabPanel } from '@Common/Components/TabPanel'
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined'
import { Tab, Tabs } from '@mui/material'
import Divider from '@mui/material/Divider'
import { useImmer } from 'use-immer'
import DealRoomComments from './DealRoomComments'
import DealRoomSettings from './DealRoomSettings'
import DealRoomVisits from './DealRoomVisits'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DealRoomEditRoom from './DealRoomEditRoom'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

const DealRoomPanel = () => {
    const [tab, setTab] = useImmer(0)
    return (
        <>
            <Divider orientation="vertical" className="h-full"></Divider>
            <div className="w-96">
                <div className="h-full flex flex-row-reverse">
                    <Tabs
                        orientation="vertical"
                        value={tab}
                        onChange={(e, newValue) => {
                            setTab(newValue)
                        }}
                        indicatorColor=""
                        sx={{
                            '& .MuiButtonBase-root': {
                                'min-width': '50px',
                                'max-width': '50px',
                            },
                        }}
                    >
                        <Tab
                            data-testid="dealroom-settings-tab"
                            value={0}
                            icon={<SettingsOutlinedIcon></SettingsOutlinedIcon>}
                        ></Tab>
                        <Tab
                            data-testid="dealroom-info-tab"
                            value={1}
                            icon={<InfoOutlinedIcon></InfoOutlinedIcon>}
                        ></Tab>
                        <Tab
                            data-testid="dealroom-comments-tab"
                            value={2}
                            icon={
                                <ChatBubbleOutlineOutlinedIcon></ChatBubbleOutlineOutlinedIcon>
                            }
                        ></Tab>
                        <Tab
                            data-testid="dealroom-visits-tab"
                            value={3}
                            icon={
                                <TrendingUpOutlinedIcon></TrendingUpOutlinedIcon>
                            }
                        ></Tab>
                    </Tabs>
                    <Divider
                        className="h-full w-1"
                        orientation="vertical"
                    ></Divider>
                    <TabPanel
                        value={tab}
                        index={0}
                        className="w-72 h-full flex-1"
                    >
                        <DealRoomEditRoom></DealRoomEditRoom>
                    </TabPanel>
                    <TabPanel
                        value={tab}
                        index={1}
                        className="w-72 h-full flex-1"
                    >
                        <DealRoomSettings></DealRoomSettings>
                    </TabPanel>
                    <TabPanel
                        value={tab}
                        index={2}
                        className="w-72 h-full flex-1"
                    >
                        <DealRoomComments></DealRoomComments>
                    </TabPanel>
                    <TabPanel
                        value={tab}
                        index={3}
                        className="w-72 h-full flex-1 overflow-auto"
                    >
                        <DealRoomVisits></DealRoomVisits>
                    </TabPanel>
                </div>
            </div>
        </>
    )
}

export default DealRoomPanel
