import { useQuery } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'

const sitesSearch = async (body: any) => {
    const { data } = await axios.post(`${APi_URL}/site/search`, body, {
        headers: { 'Content-Type': 'application/json' },
    })
    return data
}

export default function useSite(props: {
    body: {
        searchTerm: string
        sort: string
    }
}) {
    return useQuery(['sites', 'search', props.body], () =>
        sitesSearch(props.body)
    )
}
