import { Typography } from '@mui/material'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import logo from './assets/Portal.png'

export function AuthTemplate(props: {
    title: string
    body: ReactNode
    onSubmit: (e: any) => void
}) {
    return (
        <div className="w-full h-full flex flex-col justify-between pt-20 lg:bg-gray-200">
            <form
                onSubmit={props.onSubmit}
                className="w-full flex justify-center"
            >
                <div className="flex flex-col gap-6 bg-white w-[350px] px-4 py-6">
                    <div className="flex flex-col w-full items-center gap-6">
                        <Link to="/">
                            <img src={logo} />
                        </Link>
                        <p className="text-center">{props.title}</p>
                    </div>
                    {props.body}
                </div>
            </form>
            <div className="flex w-full justify-center gap-4 mb-10 sm:invisible md:invisible lg:visible">
                <Typography variant="body2">
                    <Link to="/">LIFEX Portal</Link>
                </Typography>
                <Typography variant="body2">|</Typography>
                <Typography variant="body2">
                    <a
                        href="https://lifex-help.freshdesk.com/support/tickets/new"
                        target="_blank"
                    >
                        Contact support
                    </a>
                </Typography>
                <Typography variant="body2">|</Typography>
                <Typography variant="body2">
                    <a href="https://docs.lifex.cloud/" target="_blank">
                        Product guide{' '}
                    </a>
                </Typography>
                <Typography variant="body2">|</Typography>
                <Typography variant="body2">
                    <a
                        href="https://lifex.cloud/privacy-policy"
                        target="_blank"
                    >
                        Privacy policy
                    </a>
                </Typography>
                <Typography variant="body2">|</Typography>
                <Typography variant="body2">
                    <a href="https://lifex.cloud/terms-service" target="_blank">
                        Terms of use
                    </a>
                </Typography>
            </div>
        </div>
    )
}
