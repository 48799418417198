import { API_URL } from '@Common/Types/configs/urlConfig'
import { Alert, AlertTitle, Button, TextField } from '@mui/material'
import logo from './assets/Portal.png'
import axios from 'axios'
import { useMutation } from 'react-query'
import { Controller, useForm } from 'react-hook-form'
import { authManager } from 'src/AuthManager'
import useTitle from '@Common/hooks/useTitle'
import { Link as LinkRoute, useNavigate } from 'react-router-dom'
import { Link } from '@mui/material'
import { Login as LoginRequest } from '@Server/login'
import pkceChallenge from 'pkce-challenge'

import Loading from '@Common/Components/Loading'
import { toast } from 'react-hot-toast'
import { AuthTemplate } from './AuthTemplate'
import useFeatureFlag from '@Common/hooks/useFeatureFlag'
import configuration from './config'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import { useEffect } from 'react'

export function Login() {
    const { handleSubmit, control, setError, formState } =
        useForm<LoginRequest>()
    const sendGTMEvent = useGTMDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        window.addEventListener('message', function greetingHandler(event) {
            if (
                !event.data ||
                !event.data.message ||
                !event.data.message.message
            ) {
                return
            }
            localStorage.setItem(
                'jwt',
                JSON.stringify(event.data.message.message)
            )

            var params = new URLSearchParams(window.location.search),
                previousPage = params.get('previousUrl')
            var baseurl = location.origin
            var destination = previousPage === null ? '/' : previousPage

            var landingPage = baseurl + destination
            window.location.href = landingPage
            console.log('credentials', event)
        })
        window.parent.postMessage(
            {
                name: 'loginInit',
            },
            '*'
        )
    }, [])
    const mutation = useMutation(
        (login: LoginRequest) => {
            return axios.post(`${API_URL}/login`, login)
        },
        {
            onSuccess: (result, login) => {
                if (result.data.newPasswordRequired) {
                    navigate('/changePassword?email=' + login.username)
                    return
                }
                localStorage.setItem(
                    'jwt',
                    JSON.stringify({
                        accessToken: result.data.accessToken.jwtToken,
                        identityToken: result.data.idToken.jwtToken,
                        refreshToken: result.data.refreshToken.token,
                    })
                )
                window.parent.postMessage(
                    {
                        name: 'login',
                        token: {
                            accessToken: result.data.accessToken.jwtToken,
                            identityToken: result.data.idToken.jwtToken,
                            refreshToken: result.data.refreshToken.token,
                        },
                    },
                    '*'
                )
                var params = new URLSearchParams(window.location.search),
                    previousPage = params.get('previousUrl')
                var baseurl = location.origin
                var destination = previousPage === null ? '/' : previousPage

                sendGTMEvent({
                    event: 'login',
                    user_email: login.username,
                })

                var landingPage = baseurl + destination
                window.location.href = landingPage
            },
            onError: (error, login) => {
                if (
                    error.response.data ===
                    'Password reset required for the user'
                ) {
                    navigate('/confirmForgotPassword?email=' + login.username)
                }
                toast.error(error.response.data)
            },
        }
    )
    const workspace = useFeatureFlag('workspace_type')
    if (!workspace) {
        const challenge = pkceChallenge()
        localStorage.setItem('verifier', challenge.code_verifier)
        window.location.href = `${
            import.meta.env.VITE_COGNITO_DOMAIN
        }/oauth2/authorize?response_type=code&code_challenge_method=S256&client_id=${
            import.meta.env.VITE_COGNITO_CLIENT_ID
        }&redirect_uri=${configuration.redirect_uri}&identity_provider=${
            import.meta.env.VITE_COGNITO_IDENTITY_PROVIDER
        }&code_challenge=${challenge.code_challenge}`
        return <Loading />
    }
    const onSubmit = (data) => {
        mutation.mutate(data)
    }
    useTitle('Login - Portal')
    return (
        <AuthTemplate
            title={'Sign in with your email and password'}
            onSubmit={(e) => {
                e.preventDefault()
                e.stopPropagation()
                handleSubmit(onSubmit)()
            }}
            body={
                <>
                    {(formState.errors.password ||
                        formState.errors.username) && (
                        <Alert severity="error">
                            <AlertTitle>Error</AlertTitle>
                            <div className="flex flex-col gap-2">
                                {formState.errors.username && (
                                    <div>Email is required</div>
                                )}
                                {formState.errors.password && (
                                    <div>Password is required</div>
                                )}
                            </div>
                        </Alert>
                    )}

                    <Controller
                        name="username"
                        control={control}
                        render={({ field }) => (
                            <TextField size="small" {...field} label="Email" variant="outlined" />
                        )}
                    />
                    <Controller
                        name="password"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                size="small"
                                type="password"
                                label="Password"
                                variant="outlined"
                            />
                        )}
                    />
                    <Link component={LinkRoute} to="/forgotpassword">
                        Forgot password?
                    </Link>
                    <Button
                        type="submit"
                        disabled={mutation.isLoading}
                        variant="contained"
                    >
                        {mutation.isLoading && (
                            <div className="mr-2">
                                <Loading size="1.25rem" />
                            </div>
                        )}
                        Sign in
                    </Button>
                    <p>
                        Don’t have an account?{' '}
                        <Link component={LinkRoute} to="/signup">
                            Sign up
                        </Link>{' '}
                        instead
                    </p>
                </>
            }
        />
    )
}

function TextFieldForm(props: { name: string }) {
    return (
        <Controller
            name="firstName"
            control={control}
            render={({ field }) => <Input {...field} />}
        />
    )
}
