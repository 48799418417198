import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useMutation, useQuery } from 'react-query'

export const useGenerateTags = (props: any) => {
    return useMutation(
        (assetId: string) => {
            axios.post(`${API_URL}/ai/tags?assetId=${assetId}`)
        },
        {
            onMutate: (variables) => {
                props.mutate(variables)
            },
        }
    )
}
