import Loading from '@Common/Components/Loading'
import useTitle from '@Common/hooks/useTitle'
import useGetSite from '@Common/Queries/GetQueries/GetSite'
import { useRef } from 'react'
import { useParams } from 'react-router-dom'
import SitesEditor from './Editor/SitesEditor'

const SitePreview = () => {
    const { id } = useParams()
    const sites = useGetSite({
        id: id,
    })
    const editorRef = useRef<HTMLDivElement>()
    useTitle('Sites-Preview')

    return (
        <>
            {sites.isLoading ? (
                <Loading></Loading>
            ) : (
                <SitesEditor
                    ref={editorRef}
                    editable={false}
                    showMenu={false}
                    content={sites?.data?.SitesContent?.content}
                ></SitesEditor>
            )}
        </>
    )
}
export default SitePreview
