import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import Pagination from '@Common/Pagination'
import { format } from 'date-fns'
import Switch from 'react-switch'
import { useImmer } from 'use-immer'
import useDisableUser from '@Common/Queries/MutationQueries/DisableUser'
import useEnableUser from '@Common/Queries/MutationQueries/EnableUser'
import EditUserModal from '@Common/Components/Modals/EditUserModal'
import { PencilIcon, TrashIcon } from '@heroicons/react/outline'
import Loading from '@Common/Components/Loading'

const Users: React.FC = (props: any) => {
    const [userStatus, setUserStatus] = useImmer({})
    const [userData, setUserData] = useImmer({
        isOpen: false,
        data: {},
    })

    const loadingPage = () => {
        return <Loading />
    }
    const usersData = props.usersData
    const disableUser = useDisableUser()
    const enableUser = useEnableUser()
    function handleChange(event, userId) {
        if (event == true) {
            enableUser.mutate(userId)
        } else {
            disableUser.mutate(userId)
        }
        setUserStatus((draft) => {
            draft[userId] = event
        })
    }

    return (
        <>
            {props.usersLoading || props.rolesLoading ? (
                loadingPage()
            ) : (
                <div className={`${style.tableContainer} `}>
                    <div className={style.tableHead}>
                        <div className={`${style.tableRowCol} col-span-2`}>
                            <h6 className={style.contentRowCol}>Name</h6>
                        </div>
                        <div className={`${style.tableRowCol} col-span-2`}>
                            <h6 className={style.contentRowCol}>Email</h6>
                        </div>
                        <div className={style.tableRowCol}>
                            <h6 className={`${style.contentRowCol}`}>Role</h6>
                        </div>
                        <div className={`${style.tableRowCol}`}>
                            <h6 className={style.contentRowCol}>
                                User activate
                            </h6>
                        </div>
                    </div>

                    <div className={`${style.bodyContainer} `}>
                        {usersData && usersData.count == 0 && (
                            <div className="text-red-500">
                                No Users found matching the criteria
                            </div>
                        )}
                        {usersData &&
                            usersData.users.map((user: any, i: any) => (
                                <div
                                    className={`${style.tableRow} ${
                                        i % 2 === 0 && 'bg-tableBackgroundColor'
                                    }`}
                                    key={i}
                                    onClick={() => {
                                        setUserData((draft) => {
                                            draft.isOpen = true

                                            const roles = user.UserRole.map(
                                                (e) => {
                                                    return {
                                                        label: e.role.role,
                                                        name: e.role.role,
                                                        value: e.role.id,
                                                    }
                                                }
                                            )
                                            draft.data = {
                                                name: user.name,
                                                id: user.id,
                                                role: roles,
                                            }
                                        })
                                    }}
                                >
                                    <div
                                        className={`${style.nameContainer} col-span-2 `}
                                    >
                                        <span className="lg:truncate xl:text-ellipsis">
                                            {user.name}
                                        </span>
                                    </div>
                                    <div
                                        className={`${style.assetType} col-span-2`}
                                        onClick={() => {}}
                                    >
                                        <span className="lg:truncate xl:text-ellipsis">
                                            {user.email}
                                        </span>
                                    </div>

                                    <div
                                        className={style.ownerName}
                                        onClick={() => {}}
                                    >
                                        {' '}
                                        <span className="lg:truncate xl:text-ellipsis">
                                            {user.UserRole.map(
                                                (e) => e.role.role
                                            ).join(',')}
                                        </span>
                                    </div>
                                    <div className={style.statusContainer}>
                                        {user.active == 'PENDING' ? (
                                            <Switch
                                                checked={true}
                                                onColor="#96db9a"
                                                onHandleColor="#3CB043"
                                                width={40}
                                                height={20}
                                                uncheckedIcon={false}
                                                onChange={(e) => {}}
                                                checkedIcon={false}
                                                disabled={true}
                                            />
                                        ) : (
                                            <Switch
                                                onChange={(e) =>
                                                    handleChange(e, user.id)
                                                }
                                                checked={
                                                    userStatus[user.id] ||
                                                    user.active == 'ACTIVE'
                                                        ? true
                                                        : false
                                                }
                                                offColor="#f7a7a7"
                                                onColor="#96db9a"
                                                onHandleColor="#3CB043"
                                                offHandleColor="#ED3232"
                                                width={40}
                                                height={20}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                            />
                                        )}
                                    </div>
                                </div>
                            ))}
                    </div>
                    <Pagination
                        currentPage={props.filter.currentPage}
                        setFilter={props.setFilter}
                        total={usersData?.count}
                        pageSize={10}
                    />

                    <ReactTooltip place="bottom" effect="solid" />
                </div>
            )}
            {userData.isOpen && (
                <EditUserModal
                    setUserData={setUserData}
                    userData={userData}
                    rolesData={props.rolesData}
                />
            )}
        </>
    )
}

export default Users

const style = {
    mainContainer:
        ' h-full flex flex-col overflow-hidden bg-bgColor px-4 pt-4 rounded-md font-Segoe',
    display: ' h-full flex flex-col overflow-hidden rounded-md ',
    // mainContainer: "flex flex-col px-2 h-full overflow-hidden ",
    subContainer: ' h-full px-4 sm:-mx-6 lg:-mx-8',
    tableMainContainer:
        'align-middle inline-block min-w-full h-full sm:px-4 lg:px-4',
    tableHeader: ' h-full  sm:rounded-lg',
    tableContainer: 'min-w-full flex flex-col h-full overflow-hidden',
    head: 'px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider',
    tableDiv: 'px-6 py-2  cursor-pointer',
    individualContentContainer: 'flex items-center pl-3',
    img: 'flex-shrink-0 h-26px w-30px cursor-pointer pl-3',
    name: ' font-Segoe font-semibold  text-themeColor cursor-pointer truncate',
    type: ' font-Segoe whitespace-nowrap font-normal text-themeColor cursor-pointer',
    common: 'px-6 py-2  font-Segoe font-normal text-themeColor cursor-pointer',
    download: 'px-6 py-2  text-right  cursor-pointer',
    aTag: 'text-indigo-600 hover:text-indigo-900 pr-2 cursor-pointer',
    didnot: 'text-12px font-Segoe font-normal ml-12 text-didnt cursor-pointer',
    cloudIcon: 'w-4 h-4 text-green-600',
    lockIcon: 'w-4 h-4 text-red-600',
    p: 'text-base text-red-600 mt-4 ml-3',

    tableHead:
        'h-5 mb-1 pb-8  grid grid-cols-6 gap-3 bg-white py-3 rounded-3px border-b',
    tableRowCol: 'columns-md text-left w-full',
    contentRowCol:
        'text-14px font-normal text-lightTextColor capitalize tracking-wider pl-1',
    main: 'flex flex-col px-10 mt-6 bg-white ',
    subMain: '-my-2  sm:-mx-6 lg:-mx-8',
    contentContainer: '  border-b border-gray-200 sm:rounded-lg',
    discloser: 'flex flex-row justify-around pb-3 w-full ',
    paragraph: 'text-sm text-gray-700 font-medium',
    statusContainer: 'pl-10 w-full py-2 flex flex-row',
    indicator: 'w-4 h-4  rounded-xl mr-3',
    disclosurePanel:
        'px-4 pt-4 pb-2 bg-toggleBgColor   grid grid-cols-2  flex flex-row justify-start rounded',
    requestTypeTxt: 'text-sm  font-medium',
    disclosurePanelContainer: 'mr-10',
    addNoteLabel: 'text-gray-400 mb-2 text-sm',
    addNoteInput: 'bg-white border rounded outline-none h-20 w-full px-2',
    btnContainer: 'flex flex-row mt-8',
    btnApprove:
        'py-1 px-3 text-white bg-gradient-to-r from-primary to-gradient  rounded-sm text-sm font-semibold ',
    btnReject:
        'py-1 px-4 rounded-sm border bg-white text-primary border-gradient font-semibold text-sm ml-2',
    caseDetailsContainer: 'px-16 ',
    caseSeatilsTxt: 'text-gray-400 text-sm',
    caseDetailsContent:
        'mt-3  overflow-auto text-justify h-20 text-sm text-gray-600',
    title: 'text-sm text-gray-600 mt-3',

    bodyContainer: 'bg-white mb-2  w-full flex-none h-2/3 overflow-auto',
    tableRow:
        'grid grid-cols-6 gap-3  tracking-wider  w-full bg-white mb-2  w-full flex-1 overflow-auto',
    nameContainer:
        ' text-left w-full lg:truncate xl:text-ellipsis xl:overflow-hidden py-1 pl-3',
    assetName:
        ' font-Segoe font-semibold  text-themeColor cursor-pointer  xl:text-ellipsis xl:overflow-hidden',
    assetType:
        ' xl:whitespace-nowrap text-left w-full pl-1 py-2  font-Segoe whitespace-nowrap font-normal text-themeColor cursor-pointer',
    createdDate:
        'xl:whitespace-nowrap text-left w-full pl-2 py-2  font-Segoe whitespace-nowrap font-normal text-themeColor cursor-pointer',
    ownerName:
        'xl:whitespace-nowrap text-left w-full py-2  font-Segoe text-themeColor cursor-pointer pl-1  text-themeColor cursor-pointer',
    lockRDownload: 'xl:whitespace-nowrap text-center w-full  py-2',

    loading:
        'w-full h-full flex  justify-center items-center bg-white shadow-md mb-4',
    searchTable:
        'w-full h-full flex flex-col overflow-hidden pt-2 bg-white shadow-md mb-4 rounded-3px',
    select: 'basic-multi-select border-none outline-none whitespace-nowrap text-14px font-normal font-Segoe  max-w-max rounded-3px',
    totalSearch:
        'text-searchPlaceholderColor whitespace-nowrap  pt-3 pb-2 text-14px font-Segoe font-normal self-center ml-auto',
}
