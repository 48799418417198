import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import Menu from '@mui/material/Menu'
import Fade from '@mui/material/Fade'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import classNames from 'classnames'
import { useState } from 'react'
import { Button, Chip, Tooltip } from '@mui/material'
import { Updater } from 'use-immer'
import { KeyboardArrowDownOutlined } from '@mui/icons-material'
import FilterListIcon from '@mui/icons-material/FilterList'

interface SelectProps {
    options: Array<any>
    onChangeHandler?: any
    setFilter: Updater<Record<string, unknown>>
    filterList: any
    selected: any
    setSelected: any
}

const FilterSelectColumns = (props: SelectProps) => {
    const { options, onChangeHandler, filterList, selected, setSelected } =
        props
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" color="inherit" />
    const checkedIcon = <CheckBoxIcon fontSize="small" />
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const menuOpen = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <div>
                <div
                    className="w-full flex hover:cursor-pointer"
                    onClick={handleClick}
                >
                    <Tooltip title="Filters">
                        <Button
                            data-testid="searchpage-openfilter-btn"
                            variant="outlined"
                            color="primary"
                            size="small"
                            sx={{
                                borderColor: 'text.disabled',
                                ':hover': {
                                    borderColor: 'text.disabled',
                                },
                            }}
                            onClick={() => {
                                setSelected((draft) => {
                                    draft.documentFilter =
                                        !selected.documentFilter
                                })
                            }}
                        >
                            <FilterListIcon color="disabled" />
                            <KeyboardArrowDownOutlined color="disabled" />
                        </Button>
                    </Tooltip>
                </div>
            </div>
            {options.length > 0 && (
                <Menu
                    anchorEl={anchorEl}
                    open={menuOpen}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                    PaperProps={{
                        className: 'max-h-56 w-56 px-3 ',
                    }}
                    data-testid="searchpage-filteroptions-menu"
                >
                    {options.map((option: any, ind: number) => (
                        <MenuItem
                            key={ind}
                            className="flex text-xs p-0 hover:cursor-pointer"
                            onClick={(e) => onChangeHandler(e, option)}
                            data-testid="searchpage-filteroptions-menuitem"
                        >
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                color="primary"
                                size="small"
                                checked={
                                    filterList.findIndex(
                                        (x) =>
                                            x.filterColumn.label ===
                                            option.header
                                    ) != -1
                                }
                                data-testid="searchpage-filterselect-checkbox"
                            ></Checkbox>
                            <label
                                className={classNames(
                                    'text-sm hover:cursor-pointer'
                                )}
                            >
                                {option.header}
                            </label>
                        </MenuItem>
                    ))}
                </Menu>
            )}
        </>
    )
}
export default FilterSelectColumns
