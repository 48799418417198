import Paper from '@mui/material/Paper'
import Drawer from '@mui/material/Drawer'
import Slide from '@mui/material/Slide'
interface RightSlideContainerProps {
    open: boolean
    children: any
}
const RightSlideContainer = (props: RightSlideContainerProps) => {
    return (
        <Slide
            direction="left"
            className="h-full fixed shadow-md z-10 right-0"
            in={props.open}
            mountOnEnter
            unmountOnExit
        >
            <Paper
                elevation={0}
                variant="outlined"
                sx={{
                    width: 433,
                    borderTop: 0,
                    borderRight: 0,
                    borderBottom: 0,
                }}
                className="p-0 h-full"
            >
                {props.open && props.children}
            </Paper>
        </Slide>
    )
}
export default RightSlideContainer
