import useFeatureFlag from '@Common/hooks/useFeatureFlag'
import { InfoOutlined } from '@mui/icons-material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import DownloadIcon from '@mui/icons-material/Download'
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined'
import InfoIcon from '@mui/icons-material/Info'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined'
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Updater, useImmer } from 'use-immer'
import TrackControls from './TrackControls'
import { Contacts } from '@Common/Components/Select/Contacts'
export interface TrackLinkProperties {
    name: string
    expires: boolean
    validTill: string | null
    allowDownload: boolean
    emailRequired: boolean
    passcodeRequired: boolean
    passcode: string | null
    emailAuthRequired: boolean
    restrictAccess: boolean
    accessAllow?: boolean
    accessBlock?: boolean
    contacts: { value: string; label: string }[]
    allowEmail: string[]
    blockEmail: string[]
}
interface TrackLinkPropertiesProps {
    trackLinkProps: {
        trackLinkProps: TrackLinkProperties
        setTrackLinkProps: Updater<TrackLinkProperties>
        showAdvancedControls: boolean
        setShowAdvancedControls: Updater<boolean>
    }
}
export const emailRegex = /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/

const TrackProperties = (props: TrackLinkPropertiesProps) => {
    const [error, setError] = useImmer<boolean>(false)
    const {
        trackLinkProps,
        setTrackLinkProps,
        showAdvancedControls,
        setShowAdvancedControls,
    } = props.trackLinkProps

    const domainRegex = /[\w\d\.-]+\.[\w\d\.-]+/

    const changeSwitchStatus = (fieldName: string, checked: boolean) => {
        setTrackLinkProps((draft) => {
            draft[fieldName] = checked
        })
    }
    const handleBlockEmail = (
        event: React.SyntheticEvent,
        value: T | Array<T>,
        reason: string,
        details: { option: string }
    ) => {
        const email = details.option.trim()
        const test = emailRegex.test(email) || domainRegex.test(email)
        let newSelectedItem = [...trackLinkProps.blockEmail]
        if (test && reason === 'createOption') {
            const duplicatedValues = newSelectedItem.indexOf(email)
            if (duplicatedValues === -1) {
                newSelectedItem.push(email)
            }
        } else if (reason === 'removeOption') {
            const dataIndex = newSelectedItem.findIndex((e) => e === email)
            newSelectedItem.splice(dataIndex, 1)
        } else if (reason === 'clear') {
            newSelectedItem = []
        }
        setTrackLinkProps((draft) => {
            draft.blockEmail = newSelectedItem
        })
    }
    const handleAllowEmail = (
        event: React.SyntheticEvent,
        value: T | Array<T>,
        reason: string,
        details: { option: string }
    ) => {
        const email = details.option.trim()
        const test = emailRegex.test(email) || domainRegex.test(email)
        let newSelectedItem = [...trackLinkProps.allowEmail]
        if (test && reason === 'createOption') {
            const duplicatedValues = newSelectedItem.indexOf(email)
            if (duplicatedValues === -1) {
                newSelectedItem.push(email)
            }
        } else if (reason === 'removeOption') {
            const dataIndex = newSelectedItem.findIndex((e) => e === email)
            newSelectedItem.splice(dataIndex, 1)
        } else if (reason === 'clear') {
            newSelectedItem = []
        }
        setTrackLinkProps((draft) => {
            draft.allowEmail = newSelectedItem
        })
    }

    const handleChange = (event, prevValue, field: string) => {
        const value = (event.target as HTMLInputElement).value
        if (
            (event.key === 'Enter' || event.type === 'blur') &&
            value.length > 0
        ) {
            if (field === 'name') {
                setError(false)
            }

            setTrackLinkProps((draft) => {
                draft[field] = value
            })
        } else if (event.key === 'Escape') {
            event.preventDefault()
            event.currentTarget.blur()
            event.target.value = prevValue
            setTrackLinkProps((draft) => {
                draft[field] = prevValue
            })
        }
    }
    const contacts = useFeatureFlag('contacts')

    return (
        <div className="py-4 ">
            {contacts && (
                <div className="flex mb-4 flex-col gap-3">
                    <div className="flex gap-2 items-center">
                        <Tooltip
                            title={
                                'Users are able to generate links that can be shared with others outside the LIFEX Portal pplatform as well. These links provide engagement stats of how the receiver of the link interacted with contents in the link.'
                            }
                        >
                            <InfoOutlined
                                fontSize="small"
                                color="disabled"
                            ></InfoOutlined>
                        </Tooltip>
                        <Typography className="mr-2">
                            Contact name{' '}
                            <Typography className="inline" variant="body2">
                                (Required)
                            </Typography>
                        </Typography>
                    </div>
                    <Contacts
                        multiple={true}
                        freeSolo={true}
                        value={trackLinkProps.contacts}
                        onChange={(newValue, reason) => {
                            if (reason === 'createOption' && newValue) {
                                const contats = newValue
                                    .map(
                                        (email: string | { value: string }) => {
                                            if (!email.value) {
                                                if (emailRegex.test(email)) {
                                                    return email
                                                }
                                            }
                                            if (email.value) {
                                                return email
                                            }
                                        }
                                    )
                                    .filter((e) => e != undefined)
                                setTrackLinkProps((draft) => {
                                    draft.contacts = contats
                                })
                            } else {
                                setTrackLinkProps((draft) => {
                                    draft.contacts = newValue
                                })
                            }
                        }}
                    />
                </div>
            )}
            {trackLinkProps.contacts.map((value, index) => {
                if (value.status === 'DELETED') {
                    return (
                        <Typography
                            key={index}
                            color="error"
                            className="p-0.5"
                        >{`${value.label} has been deleted`}</Typography>
                    )
                }
            })}
            <div className="flex flex-col gap-3">
                <div className="flex gap-2 items-center">
                    <Tooltip
                        title={
                            'Users are able to generate links that can be shared with others outside the LIFEX Portal pplatform as well. These links provide engagement stats of how the receiver of the link interacted with contents in the link.'
                        }
                    >
                        <InfoOutlined
                            fontSize="small"
                            color="disabled"
                        ></InfoOutlined>
                    </Tooltip>
                    <Typography className="mr-2">Link name</Typography>
                </div>
                <TextField
                    size="small"
                    variant="outlined"
                    className="w-full"
                    onChange={(event) => {
                        if (error) {
                            setError(false)
                        }
                        setTrackLinkProps((draft) => {
                            draft.name = (
                                event.target as HTMLInputElement
                            ).value
                        })
                    }}
                    onKeyDown={(event) =>
                        handleChange(event, trackLinkProps.name, 'name')
                    }
                    onBlur={(event) =>
                        handleChange(event, trackLinkProps.name, 'name')
                    }
                    value={trackLinkProps.name}
                    placeholder="Give a name Ex: Accounts,Email,Person."
                    inputProps={{
                        sx: {
                            fontSize: 14,
                        },
                    }}
                ></TextField>
            </div>
            {error && (
                <Typography color={'error'} className={'text-xs'}>
                    Name cannot be empty
                </Typography>
            )}
            <div className="pb-2">
                <TrackControls
                    icon={
                        <AccessTimeIcon
                            fontSize="small"
                            color="action"
                            className="mr-2"
                        ></AccessTimeIcon>
                    }
                    text={'Expires'}
                    control={
                        <Switch
                            size="small"
                            checked={trackLinkProps.expires}
                            onChange={(event) =>
                                setTrackLinkProps((draft) => {
                                    draft['expires'] = event.target.checked
                                    if (!event.target.checked) {
                                        draft['validTill'] = null
                                    }
                                })
                            }
                        ></Switch>
                    }
                    show={trackLinkProps.expires}
                >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                            value={trackLinkProps.validTill}
                            onChange={(newValue: string) => {
                                setTrackLinkProps((draft) => {
                                    draft.validTill = newValue
                                })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    inputProps={{
                                        ...params.inputProps,
                                        placeholder: 'mm/dd/yyyy hh:mm (am|pm)',
                                    }}
                                    variant="outlined"
                                    size="small"
                                ></TextField>
                            )}
                        ></DateTimePicker>
                    </LocalizationProvider>
                </TrackControls>
                <TrackControls
                    icon={
                        <DownloadIcon
                            fontSize="small"
                            color="action"
                            className="mr-2"
                        ></DownloadIcon>
                    }
                    text={'Allow Download'}
                    control={
                        <Switch
                            size="small"
                            checked={trackLinkProps.allowDownload}
                            onChange={(event) =>
                                changeSwitchStatus(
                                    'allowDownload',
                                    event.target.checked
                                )
                            }
                        ></Switch>
                    }
                    show={trackLinkProps.allowDownload}
                ></TrackControls>
            </div>
            <div className="pb-3">
                <TrackControls
                    text={'Advanced tracking'}
                    control={
                        <Switch
                            id="10"
                            size="small"
                            checked={showAdvancedControls}
                            onChange={(e) => {
                                setShowAdvancedControls(e?.target.checked)
                                if (!e.target.checked) {
                                    setTrackLinkProps((draft) => {
                                        draft['emailRequired'] = false
                                        draft['emailAuthRequired'] = false
                                        draft['passcodeRequired'] = false
                                        draft['passcode'] = null
                                        draft['restrictAccess'] = false
                                        draft['accessAllow'] = false
                                        draft['accessBlock'] = false
                                        draft['allowEmail'] = []
                                        draft['blockEmail'] = []
                                    })
                                }
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                        ></Switch>
                    }
                    show={showAdvancedControls}
                >
                    <div>
                        <TrackControls
                            icon={
                                <MailOutlineIcon
                                    fontSize="small"
                                    color="action"
                                    className="mr-2"
                                ></MailOutlineIcon>
                            }
                            text={'Requires E-mail to View'}
                            control={
                                <Switch
                                    size="small"
                                    checked={trackLinkProps.emailRequired}
                                    onChange={(event) =>
                                        changeSwitchStatus(
                                            'emailRequired',
                                            event.target.checked
                                        )
                                    }
                                ></Switch>
                            }
                            show={trackLinkProps.emailRequired}
                        ></TrackControls>
                        <TrackControls
                            icon={
                                <VerifiedUserOutlinedIcon
                                    fontSize="small"
                                    color="action"
                                    className="mr-2"
                                ></VerifiedUserOutlinedIcon>
                            }
                            text={'Email authentication'}
                            control={
                                <Switch
                                    size="small"
                                    checked={trackLinkProps.emailAuthRequired}
                                    onChange={(event) =>
                                        changeSwitchStatus(
                                            'emailAuthRequired',
                                            event.target.checked
                                        )
                                    }
                                ></Switch>
                            }
                            show={trackLinkProps.emailAuthRequired}
                        ></TrackControls>
                        <TrackControls
                            icon={
                                <HttpsOutlinedIcon
                                    fontSize="small"
                                    color="action"
                                    className="mr-2"
                                ></HttpsOutlinedIcon>
                            }
                            text={'Passcode Protected'}
                            control={
                                <Switch
                                    size="small"
                                    checked={trackLinkProps.passcodeRequired}
                                    onChange={(event) =>
                                        setTrackLinkProps((draft) => {
                                            draft['passcodeRequired'] =
                                                event.target.checked
                                            if (!event.target.checked) {
                                                draft['passcode'] = null
                                            }
                                        })
                                    }
                                ></Switch>
                            }
                            show={trackLinkProps.passcodeRequired}
                        >
                            <TextField
                                variant="outlined"
                                size="small"
                                className="w-full px-2"
                                value={trackLinkProps.passcode}
                                onChange={(event) => {
                                    setTrackLinkProps((draft) => {
                                        draft.passcode = (
                                            event.target as HTMLInputElement
                                        ).value
                                    })
                                }}
                                onKeyDown={(event) =>
                                    handleChange(
                                        event,
                                        trackLinkProps.passcode,
                                        'passcode'
                                    )
                                }
                                onBlur={(event) =>
                                    handleChange(
                                        event,
                                        trackLinkProps.passcode,
                                        'passcode'
                                    )
                                }
                            ></TextField>
                        </TrackControls>
                        <TrackControls
                            icon={
                                <AdminPanelSettingsOutlinedIcon
                                    fontSize="small"
                                    color="action"
                                    className="mr-2"
                                ></AdminPanelSettingsOutlinedIcon>
                            }
                            text={
                                <div>
                                    Restrict Access
                                    <Tooltip
                                        title={
                                            'Users are able to restrict preview to receivers with specific email or domains. '
                                        }
                                    >
                                        <InfoIcon
                                            fontSize="small"
                                            color="disabled"
                                        ></InfoIcon>
                                    </Tooltip>
                                </div>
                            }
                            control={
                                <Switch
                                    size="small"
                                    checked={trackLinkProps.restrictAccess}
                                    onChange={(event) =>
                                        setTrackLinkProps((draft) => {
                                            draft['restrictAccess'] =
                                                event.target.checked
                                            if (!event.target.checked) {
                                                draft['accessAllow'] = false
                                                draft['accessBlock'] = false
                                                draft['allowEmail'] = []
                                                draft['blockEmail'] = []
                                            }
                                        })
                                    }
                                ></Switch>
                            }
                            show={trackLinkProps.restrictAccess}
                        >
                            <div className="px-2">
                                <div className="pb-2 flex flex-col">
                                    <FormControlLabel
                                        disabled={trackLinkProps.accessBlock}
                                        control={
                                            <Checkbox
                                                size="small"
                                                checked={
                                                    trackLinkProps.accessAllow
                                                }
                                                onChange={(e) =>
                                                    setTrackLinkProps(
                                                        (draft) => {
                                                            draft.accessAllow =
                                                                e.target.checked
                                                            if (
                                                                !e.target
                                                                    .checked
                                                            ) {
                                                                draft.allowEmail =
                                                                    []
                                                            }
                                                        }
                                                    )
                                                }
                                            />
                                        }
                                        label="Allow"
                                    />
                                    <Autocomplete
                                        multiple
                                        disabled={
                                            trackLinkProps.accessBlock ||
                                            !trackLinkProps.accessAllow
                                        }
                                        options={[]}
                                        size="small"
                                        freeSolo
                                        value={trackLinkProps.allowEmail}
                                        onChange={handleAllowEmail}
                                        renderTags={(value, getTagProps) => {
                                            return (
                                                <>
                                                    {value.map(
                                                        (option, index) => (
                                                            <Chip
                                                                {...getTagProps(
                                                                    {
                                                                        index,
                                                                    }
                                                                )}
                                                                key={index}
                                                                label={option}
                                                                size="small"
                                                                className="whitespace-nowrapoverflow-hidden text-ellipsis "
                                                            />
                                                        )
                                                    )}
                                                </>
                                            )
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                placeholder="Enter email or domain (ex: acme.com). Hit enter"
                                            />
                                        )}
                                    />
                                </div>
                                <div className="pb-2 flex flex-col">
                                    <FormControlLabel
                                        disabled={trackLinkProps.accessAllow}
                                        control={
                                            <Checkbox
                                                size="small"
                                                checked={
                                                    trackLinkProps.accessBlock
                                                }
                                                onChange={(e) =>
                                                    setTrackLinkProps(
                                                        (draft) => {
                                                            draft.accessBlock =
                                                                e.target.checked
                                                            if (
                                                                !e.target
                                                                    .checked
                                                            ) {
                                                                draft.blockEmail =
                                                                    []
                                                            }
                                                        }
                                                    )
                                                }
                                            />
                                        }
                                        label="Block"
                                    />
                                    <Autocomplete
                                        multiple
                                        disabled={
                                            trackLinkProps.accessAllow ||
                                            !trackLinkProps.accessBlock
                                        }
                                        size="small"
                                        options={[]}
                                        freeSolo
                                        value={trackLinkProps.blockEmail}
                                        onChange={handleBlockEmail}
                                        renderTags={(value, getTagProps) => {
                                            return (
                                                <>
                                                    {value.map(
                                                        (option, index) => (
                                                            <Chip
                                                                {...getTagProps(
                                                                    {
                                                                        index,
                                                                    }
                                                                )}
                                                                key={index}
                                                                label={option}
                                                                size="small"
                                                                className="whitespace-nowrapoverflow-hidden text-ellipsis "
                                                            />
                                                        )
                                                    )}
                                                </>
                                            )
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                placeholder="Enter email or domain (ex: acme.com). Hit enter"
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </TrackControls>
                    </div>
                </TrackControls>
            </div>
        </div>
    )
}
export default TrackProperties
