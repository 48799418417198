import Loading from '@Common/Components/Loading'
import RightSlideContainer from '@Common/Components/RightSlideContainer'
import { useDashboardVisits } from '@Common/Queries/Dashboard/DashboardVisits'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {
    Button,
    Divider,
    TablePagination,
    Tooltip,
    Typography,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import classNames from 'classnames'
import { formatDistanceToNow } from 'date-fns'
import prettyMilliseconds from 'pretty-ms'
import { Link, useNavigate } from 'react-router-dom'
import {
    PageDetailsProps,
    PageLevelDetails,
} from 'src/Track/Components/PageLevelDetails'
import { useImmer } from 'use-immer'
import { getVisitorName, NameDisplay, displayAvatarBasedOnName } from 'src/BuyerInsightsUtil'

const DashboardVisits = () => {
    const [page, setPage] = useImmer(0)
    const navigate = useNavigate()
    const [open, setOpen] = useImmer(false)
    const [pageDetails, setPageDetails] = useImmer<PageDetailsProps>({
        updatedAt: null,
        downloaded: false,
        totalPagesViewed: 0,
        visitDuration: 0,
        TrackVisitPage: [],
        assetId: null,
        TrackLink: {
            totalPages: 0,
        },
        TrackSession: {
            AssetTrackSession: {
                email: null,
                buyerDetails: null,
            },
        },
    })
    const pageSize = 10
    const visits = useDashboardVisits(page, pageSize)
    if (visits.isLoading) {
        return <Loading></Loading>
    }

    const columns: GridColDef[] = [
        {
            headerName: 'Visited by',
            field: 'userName',
            disableColumnMenu: true,
            flex: 1,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div className="flex w-full overflow-hidden gap-2">
                        {displayAvatarBasedOnName(params.value, params.row.index)}
                        <div className="flex-1 flex flex-col w-1/2">
                            <NameDisplay
                                city={params.row.city}
                                name={params.value}
                                country={params.row.country}
                            ></NameDisplay>
                            <div className="flex gap-2 flex-1 w-full">
                                <Tooltip title={params.row.trackName}>
                                    <Typography
                                        color="text.secondary"
                                        variant="caption"
                                        sx={{
                                            maxWidth:
                                                params.row.contact != ''
                                                    ? '33%'
                                                    : '80%',
                                        }}
                                        className={
                                            'overflow-hidden whitespace-nowrap text-ellipsis'
                                        }
                                    >
                                        {params.row.trackName}
                                    </Typography>
                                </Tooltip>
                                {params.row.contact != '' && (
                                    <>
                                        <Divider
                                            className="h-full w-1"
                                            orientation="vertical"
                                        ></Divider>
                                        <Tooltip title={params.row.contact}>
                                            <Typography
                                                color="text.secondary"
                                                variant="caption"
                                                className="overflow-hidden whitespace-nowrap text-ellipsis"
                                            >
                                                {params.row.contact}
                                            </Typography>
                                        </Tooltip>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                )
            },
        },
        {
            headerName: 'Visited asset',
            field: 'assetName',
            flex: 1,
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div className="flex items-center gap-2">
                        <div
                            className={`w-20 h-11 overflow-hidden border border-solid border-borderColor rounded`}
                        >
                            <img
                                src={params.row.thumbnailUrl}
                                loading="eager"
                                className="w-full h-auto"
                            ></img>
                        </div>
                        <Typography color="text.secondary" fontWeight={300}>
                            {params.row.assetName}
                        </Typography>
                    </div>
                )
            },
        },
        {
            headerName: 'Time spent',
            field: 'timeSpent',
            disableColumnMenu: true,
            flex: 0.5,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Typography color="text.secondary" fontWeight={300}>
                        {params.formattedValue}
                    </Typography>
                )
            },
            valueFormatter: (params) => {
                return prettyMilliseconds(params.value)
            },
        },
        {
            headerName: 'Visited time',
            field: 'updatedAt',
            disableColumnMenu: true,
            flex: 0.5,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Typography color="text.secondary" fontWeight={300}>
                        {params.formattedValue}
                    </Typography>
                )
            },
            valueFormatter: (params) => {
                return formatDistanceToNow(new Date(params.value), {
                    addSuffix: true,
                })
            },
        },
    ]
    const rows = visits.data.visits.map((visit, id) => {
        return {
            id: visit.id,
            index: id,
            assetId: visit.TrackLink.AssetTrackLink.assetId,
            trackName: visit.TrackLink.TrackLinkProperties.name,
            updatedAt: visit.updatedAt,
            timeSpent: visit.visitDuration,
            assetName: visit.TrackLink.AssetTrackLink.Asset.name,
            userName: getVisitorName(visit.TrackSession.AssetTrackSession.email, visit.TrackSession.AssetTrackSession.buyerDetails as object),
            thumbnailUrl: visit.thumbnailUrl,
            downloaded: visit.downloaded,
            totalPages: visit.TrackLink.totalPages,
            totalPagesViewed: visit.totalPagesViewed,
            visitDuration: visit.visitDuration,
            TrackVisitPage: visit.TrackVisitPage,
            TrackSession: visit.TrackSession,
            TrackLink: visit.TrackLink,
            contact:
                visit.TrackLink && visit.TrackLink.TrackLinkContact.length > 0
                    ? visit.TrackLink.TrackLinkContact.map((e) => e.email).join(
                        ','
                    )
                    : '',

            city: visit.TrackSession.AssetTrackSession.buyerDetails?.ipData?.city,
            country: visit.TrackSession.AssetTrackSession.buyerDetails?.ipData?.country_name
        }
    })

    return (
        <div className="w-full h-full flex overflow-hidden">
            <div className="w-full h-full flex flex-row">
                <div className="px-6 pt-4  h-full w-full flex-1 flex flex-col overflow-hidden">
                    <div className="h-8 w-full flex items-center">
                        <Typography variant="h5">Visits</Typography>
                    </div>
                    <div className="w-full h-full pt-4 overflow-auto">
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            rowHeight={64}
                            hideFooter={true}
                            onRowClick={(params) => {
                                setPageDetails((draft) => params.row)
                                setOpen(true)
                            }}
                            sx={{
                                border: 'none',
                                flex: 1,
                                '& .MuiDataGrid-row:hover': {
                                    background: 'white',
                                    cursor: 'pointer',
                                },
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    fontWeight: 300,
                                },
                            }}
                        ></DataGrid>
                    </div>
                    <TablePagination
                        rowsPerPageOptions={[pageSize]}
                        colSpan={3}
                        component="div"
                        className="text-xs text-lightTextDisabled border-none overflow-hidden"
                        count={visits.data.count}
                        rowsPerPage={pageSize}
                        page={page}
                        onPageChange={(e, page) => setPage(page)}
                        backIconButtonProps={{
                            className: 'stroke-slate-50',
                        }}
                        nextIconButtonProps={{
                            className: 'stroke-slate-50',
                        }}
                    />
                </div>
                {/* if route is needed add a new component inside slide and then call all data inside that component and from there call page details */}
                <RightSlideContainer open={open}>
                    <div className="px-6 h-full w-full">
                        <div className="w-full py-2 flex justify-between">
                            <IconButton
                                onClick={() => {
                                    setOpen(false)
                                }}
                                size="small"
                            >
                                <KeyboardDoubleArrowRightIcon fontSize="small"></KeyboardDoubleArrowRightIcon>
                            </IconButton>
                            <Button
                                data-testid="dashboard-assetvisit-btn"
                                LinkComponent={Link}
                                endIcon={<OpenInNewIcon></OpenInNewIcon>}
                                onClick={() => {
                                    navigate(
                                        `/search/viewAsset/${pageDetails.assetId}/visit`
                                    )
                                }}
                            >
                                Go to document
                            </Button>
                        </div>
                        <PageLevelDetails
                            data={[pageDetails]}
                        ></PageLevelDetails>
                    </div>
                </RightSlideContainer>
            </div>
        </div>
    )
}
export default DashboardVisits
