import PageHeader from '@Common/Components/PageHeader/PageHeader'
import useRequest from '@Request/query/useRequest'
import { useParams } from 'react-router-dom'

import Comment from '@Common/Components/Comment'
import useAddComments from '@Request/query/useAddComment'
import useComments from '@Request/query/useComments'

import Loading from '@Common/Components/Loading'
import useLocalStorage from '@Common/hooks/useLocalStorage'
import useTitle from '@Common/hooks/useTitle'
import useUsers from '@Common/Queries/GetQueries/GetUsers'
import useInit from '@Common/Queries/GetQueries/useInit'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import { Button, Typography } from '@mui/material'
import { useRef } from 'react'
import AccessRequest from './AccessRequest'
import ApproveRequestDetails from './ApproveRequest'
import Editor from './Editor'
import NewAssetRequest from './NewAssetRequest'
import { useImmer } from 'use-immer'
import useOnClickOutside from '@Common/hooks/useOnClickOutside'
import AvatarComponent from '@Common/Components/Avatar'

interface RequestDetailsProps {}

export interface AssetApproveRequestDetails {
    requestId: string
    fileId: string
    assetId: string
    assetName: string
}

export default function RequestDetails(props: RequestDetailsProps) {
    const { id } = useParams()

    const [isVisible, setIsVisible] = useImmer(false)
    if (!id) {
        return <div>Request ID is missing</div>
    }
    const { status, data } = useRequest(id)
    const editorRef = useRef(null)
    const init = useInit()
    const viewRef = useRef()
    const mutation = useAddComments()
    const sendDataToGTM = useGTMDispatch()
    const usersQuery = useUsers()

    const comments = useComments(id)
    function isSameUser(): boolean {
        return data.requestedUserId === init.data.user.id
    }
    const [theme, setTheme] = useLocalStorage('theme', {
        tenant: {
            name: '',
        },
    })
    useTitle(
        `${theme?.tenant?.name ? theme?.tenant?.name : ''} - Request Details `
    )
    if (init.isLoading) {
        return <Loading />
    }

    const role: string = init.data.user.UserRole.map((userRole) =>
        userRole.role.role.toLowerCase()
    ).join(',')
    function getRequestDetailsByType(type: string) {
        switch (type) {
            case 'Asset Approval': {
                return (
                    <ApproveRequestDetails
                        data={data}
                        isAdmin={
                            role
                                .split(',')
                                .includes(
                                    import.meta.env.VITE_SUPER_ADMIN_USER
                                ) ||
                            role
                                .split(',')
                                .includes(import.meta.env.VITE_ADMIN_USER)
                        }
                        isSameUser={isSameUser()}
                    />
                )
            }
            case 'Asset Required': {
                return (
                    <NewAssetRequest
                        data={data}
                        isAdmin={
                            role
                                .split(',')
                                .includes(
                                    import.meta.env.VITE_SUPER_ADMIN_USER
                                ) ||
                            role
                                .split(',')
                                .includes(import.meta.env.VITE_ADMIN_USER)
                        }
                        isSameUser={isSameUser()}
                    />
                )
            }
            case 'Asset Access': {
                return (
                    <AccessRequest
                        data={data}
                        isAdmin={
                            role
                                .split(',')
                                .includes(
                                    import.meta.env.VITE_SUPER_ADMIN_USER
                                ) ||
                            role
                                .split(',')
                                .includes(import.meta.env.VITE_ADMIN_USER)
                        }
                        isSameUser={isSameUser()}
                    />
                )
            }
        }
    }
    function getRequestType() {
        if (data.NewAssetRequests) {
            return 'Asset Required'
        }
        if (data.AssetPermissionRequests) {
            return 'Asset Access'
        }
        if (data.AssetApproveRequest) {
            return 'Asset Approval'
        }
        return 'Unknown'
    }

    if (status !== 'success') {
        return (
            <div className="h-full flex flex-1 flex-col">
                <PageHeader title="Request" />
                <main className={style.mainContainer}>
                    <Loading />
                </main>
            </div>
        )
    }
    const requestType = getRequestType()

    const isAssetApproveRequest = requestType === 'Asset Approval'
    const assetRequestDetails = {} as AssetApproveRequestDetails
    if (isAssetApproveRequest) {
        assetRequestDetails.assetId = data.AssetApproveRequest.assetId
        assetRequestDetails.fileId = data.AssetApproveRequest.asset.fileId
        assetRequestDetails.requestId = data.id
        assetRequestDetails.assetName = data.AssetApproveRequest.asset.name
    }
    return (
        <div className="h-full flex flex-1 flex-col request-comments">
            <PageHeader title="Request" />
            <main className={style.mainContainer}>
                <div className={style.container}>
                    <div className="py-1">
                        <span className="font-bold text-18px">
                            {getRequestType()}
                        </span>
                    </div>
                    {getRequestDetailsByType(getRequestType())}
                </div>
                <div className={style.container}>
                    <div className="w-full flex max-w-[672px] py-4  overflow-hidden gap-3 justify-center items-baseline">
                        <AvatarComponent
                            name={init.data.user.name}
                        ></AvatarComponent>
                        <div
                            className={`flex-1 h-full hover:cursor-pointer mb-4 overflow-hidden max-w-[634px]`}
                            onClick={(e) => setIsVisible(true)}
                        >
                            {isVisible ? (
                                <>
                                    <Editor users={usersQuery} ref={viewRef} />
                                    <div className="py-5 gap-2 flex">
                                        <Button
                                            variant="contained"
                                            className="w-24"
                                            onClick={() => {
                                                const state =
                                                    viewRef.current.view.state
                                                const tr = state.tr.replaceWith(
                                                    0,
                                                    state.doc.content.size,
                                                    [
                                                        {
                                                            type: 'paragraph',

                                                            content: [
                                                                {
                                                                    type: 'text',
                                                                    text: '',
                                                                },
                                                            ],
                                                        },
                                                    ]
                                                )
                                                viewRef.current.view.dispatch(
                                                    tr
                                                )
                                                sendDataToGTM({
                                                    event: 'request_action',
                                                    action: 'add_comment',
                                                })
                                                mutation.mutate({
                                                    comment: JSON.stringify(
                                                        viewRef.current.view
                                                            .state
                                                    ),
                                                    requestsId: data.id,
                                                })
                                                setIsVisible(false)
                                            }}
                                        >
                                            Post
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                setIsVisible(false)
                                                const state =
                                                    viewRef.current.view.state

                                                const tr = state.tr.replaceWith(
                                                    0,
                                                    state.doc.content.size,
                                                    [
                                                        {
                                                            type: 'paragraph',

                                                            content: [
                                                                {
                                                                    type: 'text',
                                                                    text: '',
                                                                },
                                                            ],
                                                        },
                                                    ]
                                                )
                                                viewRef.current.view.dispatch(
                                                    tr
                                                )
                                                viewRef.current?.view.hasFocus()
                                            }}
                                        >
                                            Cancel
                                        </Button>{' '}
                                    </div>
                                </>
                            ) : (
                                <Typography color={'text.disabled'}>
                                    Add a comment
                                </Typography>
                            )}
                        </div>
                    </div>
                    {comments.isLoading && <Loading />}

                    {!comments.isLoading && (
                        <Comment
                            entityOption={{ name: 'requests', id }}
                            isAssetApprove={isAssetApproveRequest}
                            assetRequestDetails={assetRequestDetails}
                            comments={comments.data}
                        />
                    )}
                </div>
            </main>
        </div>
    )
}

const style = {
    container: 'w-full  outline-none px-2 rounded-3px bg-white  mb-5',

    mainContainer: ' h-full  px-4 py-4 overflow-auto',
}
