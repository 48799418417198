import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useQuery, useQueryClient } from 'react-query'

export default function useDriveUploadDetails(props: any) {
    const queryClient = useQueryClient()
    return useQuery(
        ['uploads', props],
        async () => {
            return await axios.post(`${API_URL}/drive/upload`, props)
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['search'])
            },
        }
    )
}
