import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { AnyMxRecord } from 'dns'
import { toast } from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router'

export const useActiveDealroom = () => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    return useMutation(
        (data: { dealRoomId: string }) =>
            axios.put(`${API_URL}/dealroom/active/${data.dealRoomId}`, data, {
                headers: {
                    'Content-type': 'application/json',
                },
            }),
        {
            onMutate: (data) => {
                const dealRoom = queryClient.getQueryData([
                    'dealroom',
                    data.dealRoomId,
                ])
                if (!dealRoom) {
                    return
                }
                queryClient.setQueryData(['dealroom', data.dealRoomId], {
                    dealRoom: {
                        ...dealRoom.dealRoom,
                        status: 'ACTIVE',
                    },
                    thumbnail: dealRoom.thumbnail,
                })
            },
            onSuccess: (data) => {
                toast.success('Dealroom successfully actived')
                queryClient.invalidateQueries(['dealroom', data.data.id])
                queryClient.invalidateQueries(['dealroom', 'search'])
            },
            onError: (data) => {
                toast.error('Error in activing the dealroom')
            },
        }
    )
}
