import Loading from '@Common/Components/Loading'
import useSearch from '@Common/Queries/MutationQueries/Search'
import Paper from '@mui/material/Paper'
import { Popper } from '@mui/material'
import Typography from '@mui/material/Typography'
import { AutocompleteAction, FromTo } from 'prosemirror-autocomplete'
import { MutableRefObject, useEffect, useRef } from 'react'
import { Updater, useImmer } from 'use-immer'
import { Suggestion } from './Suggestion'
import { EditorView } from 'prosemirror-view'
import { Schema } from 'prosemirror-model'
import { UseQueryResult } from 'react-query'
interface SuggestionProps {
    editorRef: MutableRefObject<HTMLDivElement | undefined>
    picker: {
        filter: string
        open: boolean
        current: number
        view: EditorView
        range: FromTo
    }
    schema: Schema
    result: UseQueryResult<any, unknown>
}
export const Suggestions = (props: SuggestionProps) => {
    const { editorRef, picker, schema, result } = props
    const paperRef = useRef<HTMLDivElement>(null)

    const view = editorRef.current?.view
    const autocomplete = view?.dom.querySelector('.autocomplete')

    return (
        <Popper
            open={picker.open}
            anchorEl={picker.anchorEl ? picker.anchorEl : autocomplete}
            placement="top-start"
            sx={{
                width: 384,
                maxHeight: 220,
                overflowY: 'auto',
                boxShadow:
                    '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
            }}
            onResize={undefined}
            onResizeCapture={undefined}
        >
            <Paper
                ref={paperRef}
                id="suggestion"
                elevation={8}
                classes="suggestion"
            >
                {result.isLoading ? (
                    <div>
                        <Loading></Loading>
                    </div>
                ) : result?.data?.assets?.length === 0 ? (
                    <div>{'No data available for your search'}</div>
                ) : (
                    result?.data?.assets?.map((item, index: number) => {
                        return (
                            <Suggestion
                                key={index}
                                index={index}
                                picker={picker}
                                schema={schema}
                                item={item}
                                view={view}
                            >
                                <Typography>{item.name}</Typography>
                            </Suggestion>
                        )
                    })
                )}
            </Paper>
        </Popper>
    )
}
