import { NodeSpec } from 'prosemirror-model'
export const assetNode: Record<string, NodeSpec> = {
    asset: {
        group: 'inline',
        inline: true,
        selectable: false,
        draggable: false,
        attrs: {
            id: { default: '' },
            fileId: { default: '' },
        },

        toDOM: (node) => {
            const { id, fileId } = node.attrs
            return ['div', { class: 'asset', asset: id, fileId: fileId }, 0]
        },
        parseDOM: [
            {
                tag: 'div[asset]',
                getAttrs(dom: HTMLElement) {
                    return {
                        id: dom.getAttribute('asset'),
                    }
                },
            },
        ],
    },
}
