import { useEffect, useRef } from 'react'

export interface UseTitleOptions {
    restoreOnUnmount?: boolean
}

const DEFAULT_USE_TITLE_OPTIONS: UseTitleOptions = {
    restoreOnUnmount: true,
}

function useTitle(
    title: string,
    options: UseTitleOptions = DEFAULT_USE_TITLE_OPTIONS
) {
    useEffect(() => {
        if (document.title !== title) document.title = title
    }, [title])
}

export default typeof document !== 'undefined'
    ? useTitle
    : (_title: string) => {}
