import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import {
    Button,
    IconButton,
    InputAdornment,
    Popover,
    TextField,
    Tooltip,
} from '@mui/material'
import { useRef } from 'react'
import { useImmer } from 'use-immer'
import { AddMember } from './AddMember'

import useLocalStorage from '@Common/hooks/useLocalStorage'
import useInit from '@Common/Queries/GetQueries/useInit'
import useDeleteSitePermission from '@Common/Queries/MutationQueries/DeleteSitePermission'
import useUpdateSite from '@Common/Queries/MutationQueries/UpdateSite'
import { useUpdateSitePermission } from '@Common/Queries/MutationQueries/UpdateSitePermission'
import Search from '@mui/icons-material/Search'
import { getPermissions } from './../permission'
import { SelectInfo } from '../../ViewAssetScreen/Components/SelectInfo'
interface PermissionProps {
    disabled: boolean
    site: Site
}
export interface Site {
    name: string
    id: string
    access: string
    SitePermission: Array<{
        permission: string
        SiteUserPermission: {
            User: {
                name: string
            }
            userId: string
        }
        SiteGroupPermission: {
            Group: {
                name: string
            }
            groupId: string
        }
    }>
}
function isSingleFullAccess(site: Site) {
    let fullPermissions = 0
    if (site.access === 'FULL_ACCESS') {
        fullPermissions = fullPermissions + 1
    }
    const permissions = site.SitePermission.reduce((acc, permission) => {
        if (permission.permission === 'FULL_ACCESS') {
            return acc + 1
        }
        return acc
    }, fullPermissions)
    return permissions === 1
}
export default function Permission({ disabled, site }: PermissionProps) {
    const permissionButton = useRef(null)
    const [permission, setPermission] = useImmer({ show: false })
    const [addMember, setAddMember] = useImmer({
        enabed: false,
    })
    const init = useInit()
    const [theme] = useLocalStorage('theme', [])
    const tenantType: string = init?.data.tenant?.TenantConfig?.type
    const memberPermissions = getPermissions('member', tenantType)
    const permissions = getPermissions('asset', tenantType)
    const updateAsset = useUpdateSite({
        updateFunction: (site, data) => {
            return {
                ...site,
                access: data.body.access,
            }
        },
    })
    const disableFullAccess = isSingleFullAccess(site)
    const deletePermission = useDeleteSitePermission()
    const updateSitePermission = useUpdateSitePermission()
    return (
        <>
            <Tooltip title="Member access">
                <IconButton
                    onClick={() => {
                        setPermission((draft) => {
                            draft.show = true
                        })
                    }}
                    disabled={disabled}
                    ref={permissionButton}
                    size="small"
                >
                    <PersonOutlineOutlinedIcon fontSize="small"></PersonOutlineOutlinedIcon>
                </IconButton>
            </Tooltip>
            <Popover
                open={permission.show}
                anchorEl={permissionButton.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClose={() => {
                    setPermission((draft) => {
                        draft.show = false
                    })
                }}
            >
                <div className="p-6 w-96 flex flex-col gap-5">
                    <div className="text-gray-400 font-medium ">
                        Access & Permission
                    </div>
                    {!addMember.enabed && (
                        <>
                            <div className="flex items-center justify-between">
                                <TextField
                                    size="small"
                                    onClick={() => {
                                        setAddMember((draft) => {
                                            draft.enabed = true
                                        })
                                    }}
                                    className="text-sm w-64"
                                    placeholder="Add members"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Search />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Button variant="contained">Invite</Button>
                            </div>

                            <div className="flex flex-col gap-2">
                                <div
                                    className={`flex justify-between ${
                                        disableFullAccess &&
                                        site.access === 'FULL_ACCESS'
                                            ? 'text-gray-300'
                                            : ''
                                    }`}
                                >
                                    <div className="flex items-center">
                                        Everyone
                                    </div>
                                    <SelectInfo
                                        className="w-28"
                                        disabled={
                                            disableFullAccess &&
                                            site.access === 'FULL_ACCESS'
                                        }
                                        value={site.access}
                                        onChange={(value) => {
                                            updateAsset.mutate({
                                                body: {
                                                    access: value.target.value,
                                                },
                                                id: site.id,
                                            })
                                        }}
                                        items={permissions}
                                    ></SelectInfo>
                                </div>
                                {site.SitePermission.map(
                                    (permission, id: number) => {
                                        const isDisabled =
                                            disableFullAccess &&
                                            permission.permission ===
                                                'FULL_ACCESS'
                                        return (
                                            <div
                                                className={`flex justify-between ${
                                                    isDisabled
                                                        ? 'text-gray-300 cursor-not-allowed'
                                                        : ''
                                                }`}
                                                key={id}
                                            >
                                                <div className="flex items-center">
                                                    {permission.SiteUserPermission
                                                        ? permission
                                                              .SiteUserPermission
                                                              .User.name
                                                        : permission
                                                              .SiteGroupPermission
                                                              .Group.name}
                                                </div>
                                                <SelectInfo
                                                    className="w-28 "
                                                    disabled={isDisabled}
                                                    value={
                                                        permission.permission
                                                    }
                                                    onChange={(e) => {
                                                        if (
                                                            e.target.value ===
                                                            'REMOVE'
                                                        ) {
                                                            deletePermission.mutate(
                                                                {
                                                                    siteId: site.id,
                                                                    sitePermissionId:
                                                                        permission.id,
                                                                }
                                                            )
                                                            return
                                                        }
                                                        updateSitePermission.mutate(
                                                            {
                                                                siteId: site.id,
                                                                permissionId:
                                                                    permission.id,
                                                                body: {
                                                                    permission:
                                                                        e.target
                                                                            .value,
                                                                },
                                                            }
                                                        )
                                                    }}
                                                    items={memberPermissions}
                                                ></SelectInfo>
                                            </div>
                                        )
                                    }
                                )}
                            </div>
                        </>
                    )}
                    {addMember.enabed && (
                        <AddMember
                            site={site}
                            onClose={() => {
                                setAddMember((draft) => {
                                    draft.enabed = false
                                })
                            }}
                        ></AddMember>
                    )}{' '}
                </div>
            </Popover>
        </>
    )
}
