import { useQuery } from 'react-query'
import axios from 'axios'
const APi_URL = import.meta.env.VITE_REACT_APP_API_URL

export default function useGetAsset(props: { id: string; onSuccess?: any }) {
    return useQuery<any, Error, string>(
        ['assets', props.id, 'details'],
        async () => {
            const result = await axios.get(`${APi_URL}/assets/${props.id}`, {
                headers: { 'Content-Type': 'application/json' },
            })
            if (result.status === 200) {
                return result.data
            } else {
                return result.status
            }
        },
        {
            retry(failureCount, error) {
                if (error.response.status === 404) {
                    return false
                }
                return true
            },
            onError: (e) => {
                console.log(e)
            },
            onSuccess: (data) => {
                if (props.onSuccess) {
                    props.onSuccess(data)
                }
            },
        }
    )
}
