import Typography from '@mui/material/Typography'
import logo from '../../../assets/cognition-purple.png'

export default function PageHeader({ title }: any) {
    return (
        <div className="flex  items-center px-6 pb-4">
            <div className="w-44">
                <img src={logo} />
            </div>
            <div className="flex gap-2">
                <Typography variant={'h5'}>|</Typography>
                <header className="flex items-center text-2xl">
                    <h1 className="  font-normal text-2xl">{title}</h1>
                </header>
            </div>
        </div>
    )
}
