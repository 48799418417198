import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'
export default function useRemoveUser(props: { onSuccess: () => void }) {
    const queryClient = useQueryClient()
    return useMutation<any, Error, string>(
        (obj) => axios.post(`${APi_URL}/user/${obj}/remove`),
        {
            onSuccess: (data: any, obj: any) => {
                queryClient.invalidateQueries(['Users'])
                props.onSuccess()
            },
        }
    )
}
