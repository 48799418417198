import Loading from '@Common/Components/Loading'
import Modal from '@Common/Components/Modals/Modal'
import useInit from '@Common/Queries/GetQueries/useInit'
import useUpdateUser from '@Common/Queries/MutationQueries/UpdateUser'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import { Cancel, CheckCircle } from '@mui/icons-material'
import {
    Autocomplete,
    Button,
    Chip,
    CircularProgress,
    TextField,
    Typography,
} from '@mui/material'
import { useRef } from 'react'
import { useImmer } from 'use-immer'
import useUpdateWorkspace from './updateWorkspace'
import useDeleteWorkspace from './useDeleteWorkspace'
import useDomainAvailable from './useDomainAvailable'
import useLeaveWorkspace from './useLeaveWorkspace'
import useWorkspaceDomains from './useWorkspaceDomains'

export default function Workspace() {
    const init = useInit()

    const userRoles = init.data.user.UserRole.map((userRole) =>
        userRole.role.role.toLowerCase()
    )
    const isAdmin =
        userRoles.includes(import.meta.env.VITE_SUPER_ADMIN_USER) ||
        userRoles.includes(import.meta.env.VITE_ADMIN_USER)

    const name = useRef<HTMLInputElement>()
    const domain = useRef<HTMLInputElement>()

    const [workspace, setWorkspace] = useImmer({
        userDomain: '',
        allowedDomain: [] as string[],
        error: '',
        showDelete: false,
    })
    const sendDataToGTM = useGTMDispatch()

    const deleteWorspace = useDeleteWorkspace({
        onSuccess: () => {
            sendDataToGTM({
                event: 'workspace_deleted',
            })
        },
    })
    const workspaceDomains = useWorkspaceDomains()
    const updateWorkspace = useUpdateWorkspace()
    const leaveWorkspace = useLeaveWorkspace()
    const domainAvailable = useDomainAvailable(workspace.userDomain)
    if (workspaceDomains.isLoading) {
        return <Loading />
    }
    return (
        <div className="p-6">
            <div className="flex flex-col gap-4 mb-6">
                <Typography variant="subtitle2">Name</Typography>
                {isAdmin ? (
                    <TextField
                        inputRef={name}
                        variant="outlined"
                        defaultValue={init.data.tenant.name}
                        size="small"
                    ></TextField>
                ) : (
                    <Typography variant="body2">
                        {init.data.tenant.name}
                    </Typography>
                )}
            </div>

            <div className="flex flex-col gap-4 mb-6">
                <Typography variant="subtitle2">
                    Allowed email domains
                </Typography>
                <Typography variant="body2">
                    Your workspace is accessible to anyone with an email address
                    at these domains.
                </Typography>
                {isAdmin ? (
                    <Autocomplete
                        multiple
                        id="tags-filled"
                        defaultValue={init.data.tenant.Domain.map(
                            (tenantDomain) => tenantDomain.domain
                        )}
                        options={workspaceDomains.data?.domains.map(
                            (option) => option.domain
                        )}
                        noOptionsText="Enter a email domain from users already added to the workspace   "
                        onChange={(event, value, reason) => {
                            if (reason === 'selectOption') {
                                setWorkspace((draft) => {
                                    draft.allowedDomain = value
                                })
                            }
                            if (
                                reason === 'removeOption' ||
                                reason === 'clear'
                            ) {
                                setWorkspace((draft) => {
                                    draft.allowedDomain = value
                                })
                            }
                        }}
                        renderTags={(value: readonly string[], getTagProps) =>
                            value?.map((option: string, index: number) => (
                                <Chip
                                    variant="outlined"
                                    label={option}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                size="small"
                                placeholder="Domains"
                            />
                        )}
                    />
                ) : (
                    <Typography>
                        {init.data.tenant.Domain.map((tenantDomain) => {
                            return <Chip label={tenantDomain.domain} />
                        })}
                    </Typography>
                )}
            </div>
            <div className="flex flex-col gap-4 mb-6">
                <Typography variant="subtitle2">Danger zone</Typography>
                <div className="flex gap-2">
                    <Button
                        variant="outlined"
                        onClick={() => {
                            leaveWorkspace.mutate()
                        }}
                        disabled={leaveWorkspace.isLoading}
                    >
                        {leaveWorkspace.isLoading && (
                            <div className="mr-3">
                                <Loading size="1.5rem" />
                            </div>
                        )}
                        Leave workspace
                    </Button>
                    {isAdmin && (
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setWorkspace((draft) => {
                                    draft.showDelete = true
                                })
                            }}
                            color="error"
                        >
                            Delete entire workspace
                        </Button>
                    )}
                </div>
            </div>
            <div className="text-red-500 mb-10">{workspace.error}</div>
            {isAdmin && (
                <Button
                    disabled={
                        (!!workspace.userDomain.trim() &&
                            workspace.userDomain.trim() !==
                                init.data.tenant.domain &&
                            domainAvailable.isLoading) ||
                        (!!workspace.userDomain.trim() &&
                            workspace.userDomain.trim() !==
                                init.data.tenant.domain &&
                            !domainAvailable.data?.available) ||
                        updateWorkspace.isLoading
                    }
                    variant="contained"
                    onClick={() => {
                        if (!name.current?.value) {
                            setWorkspace((draft) => {
                                draft.error = 'Name cannot be blank'
                            })
                            return
                        }
                        /*if (!domain.current?.value) {
                        setWorkspace((draft) => {
                            draft.error = 'Domain cannot be blank'
                        })
                        return
                    }*/
                        setWorkspace((draft) => {
                            draft.error = ''
                        })
                        updateWorkspace.mutate({
                            allowedDomains: workspace.allowedDomain,
                            name: name.current?.value,
                            domain: domain.current?.value,
                            id: init.data.tenant.id,
                        })
                    }}
                >
                    <div className="flex gap-2">
                        {updateWorkspace.isLoading && <Loading size="1.5rem" />}
                        Update
                    </div>
                </Button>
            )}
            <Modal
                isOpen={workspace.showDelete}
                className="w-96 h-auto"
                onClose={() => {
                    setWorkspace((draft) => {
                        draft.showDelete = false
                    })
                }}
                body={
                    <div>
                        {' '}
                        <p className="mb-4">
                            This action cannot be undone. This will permanently
                            delete all your assets and files associated with it
                        </p>
                        <div className="flex flex-col gap-2">
                            <Button
                                color="error"
                                onClick={() => {
                                    deleteWorspace.mutate()
                                }}
                                variant="outlined"
                            >
                                Permanently delete workspace
                            </Button>
                            <Button
                                onClick={() => {
                                    setWorkspace((draft) => {
                                        draft.showDelete = false
                                    })
                                }}
                                variant="outlined"
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                }
            />
        </div>
    )
}
