import Loading from '@Common/Components/Loading'
import Modal from '@Common/Components/Modals/Modal'
import useInit from '@Common/Queries/GetQueries/useInit'
import { Button, Checkbox, TextField, Typography } from '@mui/material'
import { useRef } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { useImmer } from 'use-immer'
import useChangePassword from './useChangePassword'
import useSaveUserAccount from './useSaveUserAccount'

export default function MyAccount() {
    const init = useInit()
    const [password, setPassword] = useImmer({
        show: false,
        showSuccess: false,
        error: '',
    })
    const [account, setAccount] = useImmer({
        error: '',
    })
    const changePassword = useChangePassword({
        onSuccess: () => {
            setPassword((draft) => {
                draft.show = false
            })
            toast.success('Password Successfully Changed!')
        },
        onError: (message) => {
            toast.error(message)
        },
    })
    const saveUserAccount = useSaveUserAccount({
        onSuccess: () => {},
    })
    const oldPassword = useRef<HTMLInputElement>()
    const newPassword = useRef<HTMLInputElement>()
    const name = useRef<HTMLInputElement>()
    const [weeklyDigest, setWeeklyDigest] = useImmer(
        init.data.user.weeklyDigest
    )

    const repeatNewPassword = useRef<HTMLInputElement>()
    return (
        <div className="p-6">
            <div className="flex flex-col gap-4 mb-6">
                <Typography variant="subtitle2">Preferred name</Typography>
                <TextField
                    inputRef={name}
                    variant="outlined"
                    defaultValue={init.data.user.name}
                    size="small"
                ></TextField>
            </div>
            <div className="flex flex-col gap-4 mb-6">
                <Typography variant="subtitle2">Email</Typography>
                <Typography variant="body2">{init.data.user.email}</Typography>
            </div>
            <div className="flex flex-col gap-4 mb-6">
                <Typography variant="subtitle2">Password</Typography>
                <Button
                    onClick={() => {
                        setPassword((draft) => {
                            draft.show = true
                        })
                    }}
                    variant="outlined"
                    className="w-48"
                >
                    Change Password
                </Button>
            </div>
            <div className="flex flex-col gap-4 mb-6">
                <Typography variant="subtitle2">Notifications</Typography>
                <div className="flex items-center">
                    <Checkbox
                        checked={weeklyDigest}
                        onChange={(e) => setWeeklyDigest(e.target.checked)}
                    ></Checkbox>
                    <Typography> Weekly digest</Typography>
                </div>
            </div>

            <Modal
                isOpen={password.show}
                className="w-96 h-50"
                onClose={() => {
                    setPassword((draft) => {
                        draft.show = false
                    })
                }}
                body={
                    <div>
                        <div className="flex flex-col gap-4 mb-6">
                            <Typography variant="subtitle2">
                                Old password
                            </Typography>
                            <TextField
                                type="password"
                                variant="outlined"
                                inputRef={oldPassword}
                                size="small"
                            ></TextField>
                        </div>
                        <div className="flex flex-col gap-4 mb-6">
                            <Typography variant="subtitle2">
                                New password
                            </Typography>
                            <TextField
                                type="password"
                                variant="outlined"
                                size="small"
                                inputRef={newPassword}
                            ></TextField>
                        </div>
                        <div className="flex flex-col gap-4 mb-6">
                            <Typography variant="subtitle2">
                                Repeat new password
                            </Typography>
                            <TextField
                                type="password"
                                variant="outlined"
                                size="small"
                                inputRef={repeatNewPassword}
                            ></TextField>
                        </div>
                        <div className="text-red-500 mb-2">
                            {password.error}
                        </div>
                        <Button
                            variant="contained"
                            disabled={changePassword.isLoading}
                            onClick={() => {
                                const newPasswordValue =
                                    newPassword.current?.value
                                const oldPasswordValue =
                                    oldPassword.current?.value
                                const repeatNewPasswordValue =
                                    repeatNewPassword.current?.value
                                if (!newPasswordValue || !oldPasswordValue) {
                                    setPassword((draft) => {
                                        draft.error =
                                            'Please provide old password and new password'
                                    })
                                    return
                                }
                                if (
                                    repeatNewPasswordValue !== newPasswordValue
                                ) {
                                    setPassword((draft) => {
                                        draft.error =
                                            'New passwords does not match.'
                                    })
                                    return
                                }
                                setPassword((draft) => {
                                    draft.error = ''
                                })
                                changePassword.mutate({
                                    newPassword: newPasswordValue,
                                    oldPassword: oldPasswordValue,
                                })
                            }}
                        >
                            {changePassword.isLoading && (
                                <div className="w-7 pr-2">
                                    <Loading size="1.25rem" />
                                </div>
                            )}
                            Change Password
                        </Button>
                    </div>
                }
            ></Modal>
            <div className="text-red-500">{account.error}</div>
            <Button
                disabled={saveUserAccount.isLoading}
                variant="contained"
                className="mt-10"
                onClick={() => {
                    if (!name.current?.value) {
                        setAccount((draft) => {
                            draft.error = 'Name cannot be empty'
                        })
                        return
                    }
                    setAccount((draft) => {
                        draft.error = ''
                    })
                    saveUserAccount.mutate({
                        name: name.current?.value,
                        weeklyDigest: weeklyDigest,
                    })
                }}
            >
                {saveUserAccount.isLoading && (
                    <div className="w-7 pr-2">
                        <Loading size="1.25rem" />
                    </div>
                )}
                Update
            </Button>
            <Toaster />
        </div>
    )
}
