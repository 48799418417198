import { Grid, Typography } from '@mui/material'
import Preview from './preview'
import { useImmer } from 'use-immer'
import { SearchCard } from './SearchCard'

export const SearchGridView = (props) => {
    const {
        searchData,
        filter,
        isEnterprise,
        feedback,
        setFeedBack,
        currentRef,
        showOptions,
        onClickOfRow,
        usersData,
        setAsset,
    } = props
    const [preview, setPreview] = useImmer({ show: false, asset: {} as any })

    function showSearchResult() {
        if (!filter.currentPage && !filter.searchTerm) {
            return (
                <Typography className="text-gray-700 text-xs text-Roboto">
                    Upload your first document
                </Typography>
            )
        } else if (isEnterprise) {
            return (
                <Typography className="text-gray-700 text-xs text-Roboto">
                    Didn't find what you were looking for?
                    <a
                        className="text-filterText text-xs text-Roboto hover:cursor-pointer"
                        onClick={() => {
                            setFeedBack({
                                ...feedback,
                                open: true,
                                anchorEl: currentRef.current,
                            })
                        }}
                    >
                        Raise a request for an asset
                    </a>
                </Typography>
            )
        }
        return (
            <Typography className="text-gray-700 text-xs text-Roboto">
                No search results for current query
            </Typography>
        )
    }
    return (
        <div className="w-full h-full flex overflow-auto pr-4 flex-col">
            {searchData.count === 0 ? (
                <div className="flex justify-center py-4">
                    {showSearchResult()}
                </div>
            ) : (
                <Grid item container spacing={3}>
                    {searchData.assets.map((value) => {
                        return (
                            <Grid item xs={4} md={3}>
                                <SearchCard
                                    setAsset={setAsset}
                                    usersData={usersData}
                                    preview={preview}
                                    setPreview={setPreview}
                                    row={value}
                                    showOptions={showOptions}
                                    onClickOfRow={onClickOfRow}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            )}

            <Preview
                asset={preview.asset}
                closePreview={() => {
                    setPreview((draft) => {
                        draft.show = false
                        draft.asset = {}
                    })
                }}
                isOpen={preview.show}
            />
        </div>
    )
}
