import { Chip, ChipProps } from '@mui/material'

export const ActiveChip = (props: ChipProps) => {
    return (
        <Chip
            {...props}
            sx={{
                color: 'primary.main',
            }}
        ></Chip>
    )
}
