import Loading from '@Common/Components/Loading'
import { Button, Input, TextField } from '@mui/material'
import { last } from 'lodash'
import { useRef } from 'react'
import useUpdateName from './query/useUpdateName'

export default function ProvideName() {
    const firstName = useRef<HTMLInputElement>(null)
    const lastName = useRef<HTMLInputElement>(null)
    const updateName = useUpdateName()
    return (
        <div className="flex justify-center bg-gray-50 items-center w-full h-full flex-col">
            <div className="text-xl  font-medium">Welcome to Portal</div>
            <div className="p-6 text-gray-400">
                Let's get your account setup. It will take less than a minute
            </div>
            <div className="flex flex-col gap-4 mb-4">
                <TextField
                    variant="outlined"
                    inputRef={firstName}
                    disabled={updateName.isLoading}
                    className="w-64"
                    placeholder="First name"
                />
                <TextField
                    variant="outlined"
                    className="w-64"
                    disabled={updateName.isLoading}
                    inputRef={lastName}
                    placeholder="Last name"
                />
            </div>
            <Button
                variant="contained"
                onClick={() => {
                    const firstname = firstName.current?.value
                    const lastname = lastName.current?.value
                    if (!firstname || !lastname) {
                        return
                    }
                    updateName.mutate({
                        firstname,
                        lastname,
                    })
                }}
                disabled={updateName.isLoading}
            >
                {updateName.isLoading && (
                    <div className="mr-2">
                        <Loading size="1.5rem" />
                    </div>
                )}
                Continue
            </Button>
        </div>
    )
}
