import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import useInit from '../GetQueries/useInit'

export const useCreateDealRoomComments = (dealroomId: string) => {
    const queryClient = useQueryClient()
    const init = useInit()
    return useMutation(
        (data: { comment: string }) =>
            axios.post(`${API_URL}/dealroom/${dealroomId}/comment`, data, {
                headers: {
                    'Content-type': 'application/json',
                },
            }),
        {
            onMutate: (data) => {
                queryClient.cancelQueries(['dealroom', dealroomId, 'comments'])
                const comments: Array<any> = queryClient.getQueryData([
                    'dealroom',
                    dealroomId,
                    'comments',
                ])
                if (!comments) return
                comments.push({
                    User: {
                        name: init.data.user.name,
                        id: init.data.user.id,
                    },
                    updatedAt: new Date(),
                    comment: JSON.parse(data.comment),
                    children: [],
                    parentId: null,
                })
            },

            onSuccess: (data) => {
                queryClient.invalidateQueries([
                    'dealroom',
                    data.data.id,
                    'comments',
                ])
                queryClient.invalidateQueries(['dealroom', 'search'])
            },
        }
    )
}
