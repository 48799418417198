import { Updater, useImmer } from 'use-immer'

import TrackCreate from './Components/TrackCreate'
import TrackLinkConfirmation from './Components/TrackLinkConfirmation'

interface TrackProps {
    assetId: string
    setOpen: Updater<{ open: boolean; anchorEl: HTMLElement }>
    origin: string
}
const CreateTrackLink = (props: TrackProps) => {
    const [confirmation, setConfirmation] = useImmer({
        open: false,
        code: '',
    })
    return (
        <>
            {!confirmation.open ? (
                <div className="w-96">
                    <div className="px-6">
                        <TrackCreate
                            assetId={props.assetId}
                            setConfirmation={setConfirmation}
                        ></TrackCreate>
                    </div>
                </div>
            ) : (
                <TrackLinkConfirmation
                    code={confirmation.code}
                    setOpen={props.setOpen}
                ></TrackLinkConfirmation>
            )}
        </>
    )
}

export default CreateTrackLink
