import Loading from '@Common/Components/Loading'
import { useDealRoom } from '@Common/Queries/Dealroom/GetDealRoom'
import { Box, Button, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'

import { useCreateSection } from '@Common/Queries/Dealroom/CreateSection'
import useInit from '@Common/Queries/GetQueries/useInit'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { useDealRoomColor } from 'src/Stores/DealRoomTheme'
import { DealRoomSection } from './DealRoomSections'

const DealRoomPage = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    if (!id) {
        navigate('/dealroom')
    }
    const dealroom = useDealRoom({ id: id })
    const init = useInit()
    const color = useDealRoomColor((state) => state.color)
    const setColor = useDealRoomColor((state) => state.setColor)

    if (dealroom.isLoading || init.isLoading) {
        return <Loading></Loading>
    }
    const createSection = useCreateSection({ userId: init.data.user.id })
    const properties = dealroom.data?.dealRoom.DealRoomProperties
    const sections = dealroom.data?.dealRoom.DealRoomSection.filter(
        (e) => e.status != 'DELETED'
    )

    return (
        <Box className="flex-1 w-full relative overflow-auto">
            {dealroom.data.dealRoom.theme != 'none' && (
                <div
                    style={{
                        backgroundImage:
                            'linear-gradient(180deg, rgba(217, 217, 217, 0.00) 0%, #FFF 100%)',
                    }}
                    className="flex h-2/5 bg-cover overflow-hidden"
                >
                    <img
                        className="flex w-full -z-10 bg-cover"
                        src={dealroom.data?.dealRoom.theme}
                    ></img>
                </div>
            )}
            <div
                className={`${
                    dealroom.data.dealRoom.theme === 'none' ? 'pt-8' : ''
                } flex flex-col justify-center items-center align-middle gap-y-3`}
            >
                <Typography
                    variant="h4"
                    className="align-middle text-center font-medium"
                >
                    {properties.name}
                </Typography>
                <Typography className="flex w-9/12 text-center justify-center">
                    {properties.description}
                </Typography>
            </div>
            <div className="w-full flex flex-col z-10 relative overflow-auto gap-y-6 pt-6">
                {sections.map((section, index) => (
                    <DealRoomSection
                        count={sections.length}
                        section={section}
                        key={index}
                        thumbnail={dealroom.data?.thumbnail}
                        access={dealroom.data?.dealRoom.action}
                    ></DealRoomSection>
                ))}
                {['FULL_ACCESS', 'EDIT'].includes(
                    dealroom?.data?.dealRoom.action
                ) && (
                    <Button
                        startIcon={<AddOutlinedIcon></AddOutlinedIcon>}
                        data-testid="dealroom-section-add-btn"
                        onClick={() => {
                            createSection.mutate(id)
                        }}
                    >
                        Add a section
                    </Button>
                )}
            </div>
        </Box>
    )
}

export default DealRoomPage
