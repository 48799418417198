import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { DealRoomProps } from 'src/DealRoom/Components/DealRoom'

interface UpdateDealroomPermission {
    dealroomId: string
    dealroompermissionId: string
    body: {
        permission: string
    }
    onSuccess?: any
    onError?: any
}
export function useUpdateDealroomPermission(props?: UpdateDealroomPermission) {
    const queryClient = useQueryClient()
    return useMutation(
        async (obj) =>
            await axios.put(
                `${API_URL}/dealroom/${obj.dealroomId}/permission/${obj.dealroompermissionId}`,
                obj.body,
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            ),
        {
            onSuccess: async (data: any, obj) => {
                if (props && props.onSuccess) props.onSuccess(data, obj)
                queryClient.invalidateQueries([
                    'dealroom',
                    data.data.id,
                ])
            },
            onError: (data: any, obj: any, context: any) => {
                if (props.onError) props.onError(data, obj)
            },
            onMutate: async (obj) => {
                queryClient.cancelQueries(['dealroom', obj.dealroomId])
                queryClient.setQueryData(
                    ['dealroom', obj.dealroomId],
                    (old) => {
                        const permissionIndex =
                            old.dealRoom.DealRoomPermission.findIndex(
                                (permission) => {
                                    return (
                                        permission.id ===
                                        obj.dealroompermissionId
                                    )
                                }
                            )
                        const permission =
                            old.dealRoom.DealRoomPermission[permissionIndex]
                        permission.permission = obj.body.permission
                        old.dealRoom.DealRoomPermission[permissionIndex] =
                            permission
                        return old
                    }
                )
            },
        }
    )
}
