import useInit from '@Common/Queries/GetQueries/useInit'
import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useImmer } from 'use-immer'
import CustomAttributeList from './CustomAttributeList'
import CustomAttributeUpdateForm from './CustomAttributeList'

const Attributes: React.FC = () => {
    const [newAttr, setAttr] = useState(false)
    const [newCustomAttrValueDraft, setNewCustomAttrValueDraft] = useImmer({
        data: [],
    })
    const [showCreateOption, setShowCreateOption] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const init = useInit()

    const userRoles = init.data.user.UserRole.map((userRole) =>
        userRole.role.role.toLowerCase()
    )
    const isAdmin =
        userRoles.includes(import.meta.env.VITE_SUPER_ADMIN_USER) ||
        userRoles.includes(import.meta.env.VITE_ADMIN_USER)

    return (
        <div className="w-full h-full flex flex-col overflow-hidden">
            <div>
                {isAdmin && (
                    <Button
                        variant="contained"
                        onClick={() => {
                            setAttr(true)
                            setShowForm(true)
                            setShowCreateOption(false)
                            setNewCustomAttrValueDraft((draft) => {
                                draft.data = []
                            })
                        }}
                    >
                        <span className="text-sm text-white">
                            Add a new attribute
                        </span>
                    </Button>
                )}
            </div>
            <div className="pt-5 ">
                <span className="text-base">Custom Attributes</span>
                <div className="w-8 h-1 bg-blue-600 mt-1"></div>
            </div>
            <CustomAttributeList
                newAttributeCreation={newAttr}
                setAttr={setAttr}
                setNewCustomAttrValueDraft={setNewCustomAttrValueDraft}
                newCustomAttrValueDraft={newCustomAttrValueDraft}
                setShowForm={setShowForm}
                showForm={showForm}
                setShowCreateOption={setShowCreateOption}
                showCreateOption={showCreateOption}
            />
        </div>
    )
}

export default Attributes

const style = {
    mainContainer:
        ' h-full flex flex-col overflow-hidden bg-bgColor px-4 pt-4 rounded-md font-Segoe',
    display: ' h-full flex flex-col overflow-hidden rounded-md ',
}
