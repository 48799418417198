import { create } from 'zustand'

const initialState = {
    fileData: [],
    accessToken: null,
}

export const useDriveStore = create((set) => ({
    ...initialState,
    fileData: [],
    accessToken: null,
    addFileData: (data) =>
        set((state) => ({
            fileData: [data, ...state.fileData],
        })),
    setAccessToken: (data) =>
        set((state) => ({
            accessToken: data,
        })),
    reset: () => set(initialState),
}))
