import Modal from '@Common/Components/Modals/Modal'
import { lazy } from 'react'

import PreviewImage from './PreviewImage'
import PreviewPDF from './PreviewPDF'
import { useMemo } from 'react'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'

export default function Preview({ asset, closePreview, isOpen }) {
    const sendDataToGTM = useGTMDispatch()
    function getComponent() {
        const expression = /\.([0-9a-z]+)(?:[\?#]|$)/
        if (!asset || !asset.assetUrl) {
            return <></>
        }
        const url: string = asset.assetUrl
        const result = url.toLowerCase().match(expression)
        if (!result) {
            return <></>
        }
        const fileType = result[1]
        sendDataToGTM({
            event: 'asset_preview',
            asset_id: asset?.id,
            asset_name: asset?.name,
        })
        if (
            result[1] === 'pdf' ||
            fileType === 'ppt' ||
            fileType === 'pptx' ||
            fileType === 'doc' ||
            fileType === 'docx'
        ) {
            return (
                <PreviewPDF
                    asset={asset}
                    type={fileType}
                    closePreview={closePreview}
                />
            )
        }
        if (
            fileType === 'png' ||
            fileType === 'svg' ||
            fileType === 'jpg' ||
            fileType === 'jpeg'
        ) {
            return <PreviewImage asset={asset} closePreview={closePreview} />
        }
    }
    return (
        <Modal
            isOpen={isOpen}
            body={useMemo(getComponent, [asset.assetUrl])}
            onClose={(e) => {
                closePreview()
                e.stopPropagation()
            }}
            className={''}
        />
    )
}
