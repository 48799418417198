import { AssetStatus } from 'src/Upload/AssetUploadInput'
import Loading from '@Common/Components/Loading'
import makeIcon from '@Common/utils/MakeIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, LinearProgress } from '@mui/material'
import classNames from 'classnames'
import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUpload } from '.'
export const fileSize = (size: any) => {
    if (size === 0) {
        return '0 Bytes'
    }
    const k = 1024
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    const i = Math.floor(Math.log(size) / Math.log(k))
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
}
export function Upload() {
    const { upload, handleFileUpload } = useUpload()
    const navigate = useNavigate()
    
    useEffect(() => {
        if (!upload.file) {
            navigate('/plugin')
        }
    }, [])
    function getStatus(status: AssetStatus) {
        if (status === AssetStatus.error) {
            return 'Error'
        }
        if (status === AssetStatus.uploaded) {
            return 'Creating Asset'
        }
        return 'Uploading'
    }
    const uploadInput = useRef<HTMLInputElement>(null)
    if (!upload.file) {
        return <Loading />
    }
    const file = upload.file
    const fileName = file?.name
    return (
        <div className="w-full h-full p-5">
            <div
                className={`flex w-full items-center gap-5 ${
                    upload.status === AssetStatus.error && 'text-red-500'
                }`}
            >
                <div className=" flex justify-center items-center bg-slate-300 w-10 h-10 rounded-full ">
                    <FontAwesomeIcon
                        icon={makeIcon(fileName).name}
                        color={makeIcon(fileName).color}
                        size="2x"
                    />
                </div>
                <div className="flex-1 flex flex-col">
                    <div>{fileName}</div>

                    <div className="flex gap-4">
                        <div>{fileSize(upload.file?.size)}</div> -
                        <div>{getStatus(upload.status)}</div>
                    </div>
                    <LinearProgress
                        variant="determinate"
                        value={upload.progress || 0}
                        color={
                            upload.status === AssetStatus.error
                                ? 'error'
                                : 'primary'
                        }
                        className={classNames('h-1 mt-4', {
                            hidden: upload.status === AssetStatus.completed,
                        })}
                    />
                </div>
            </div>
            <div className="w-full flex justify-center pt-10">
                {upload.status === AssetStatus.error && (
                    <Button
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            uploadInput.current?.click()
                        }}
                    >
                        Click here to reupload the file
                    </Button>
                )}
                <input
                    ref={uploadInput}
                    className="sr-only"
                    onChange={(e) => {
                        e.preventDefault()
                        if (
                            !e.currentTarget?.files ||
                            e.currentTarget?.files?.length <= 0
                        ) {
                            return
                        }
                        const file = e.currentTarget.files[0]
                        if (file) {
                            handleFileUpload(file)
                        }
                    }}
                    type="file"
                />
            </div>
        </div>
    )
}
