import { useQuery } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'

const useSearchData = async (body: any) => {
    const { data } = await axios.post(`${APi_URL}/search`, body, {
        headers: { 'Content-Type': 'application/json' },
    })
    return data
}

export default function useSearch(props: any) {
    return useQuery(
        ['site', 'asset', 'search', props.body.searchTerm],
        () => useSearchData(props.body),
        {
            onSuccess: (data) => {
                if (props?.onSuccess) props?.onSuccess(data)
            },
        }
    )
}
