import Loading from '@Common/Components/Loading'
import axios from 'axios'
import { useEffect } from 'react'
import { useMutation } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import { Token, tokenKey } from './AuthManager'
import configuration from './config'
export function AuthenticationCallback() {
    const [searchParams, setSearchParams] = useSearchParams()
    const params = new URLSearchParams()
    const verifier = localStorage.getItem('verifier')
    params.append('grant_type', 'authorization_code')
    params.append('code', searchParams.get('code') || '')
    params.append('code_verifier', verifier)
    params.append('redirect_uri', configuration.redirect_uri)
    params.append('client_id', configuration.client_id)
    const token = useMutation(
        () => {
            return axios.post(
                `${configuration.metadata.token_endpoint}`,
                params
            )
        },
        {
            onSuccess: (response) => {
                const _token: Token = {
                    accessToken: response.data.access_token,
                    identityToken: response.data.id_token,
                    refreshToken: response.data.refresh_token,
                }
                localStorage.removeItem('verifier')
                localStorage.setItem(tokenKey, JSON.stringify(_token))
                window.location.href = '/'
            },
        }
    )
    useEffect(() => {
        token.mutate()
    }, [])
    return <Loading />
}
