import { useQuery } from "react-query";
import axios from "axios";
import { APi_URL } from "@Common/Types/configs/urlConfig";

const getAuditHistory = async (Id: any) => {
  const { data } = await axios.get(
    `${APi_URL}/assets/${Id}/audit`,
  );
  return data;
};

export default function useAuditHistory(Id: any) {
  return useQuery(["assets", Id, "auditHistory"], () => getAuditHistory(Id));
}
