import * as DateCSS from 'react-date-range/dist/theme/default.css' // theme css file
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import { useImmer } from 'use-immer'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import TextField from '@mui/material/TextField'

const DateFilter = ({
    setFilter,
    data = {},
    value,
    columnData,
    setFilterList,
    handleClickAway,
}) => {
    const [dateRange, setDateRange] = useImmer([new Date(), new Date()])
    const handleOnChange = (
        e: any,
        data: { filterColumn: any; values: any }
    ) => {
        const startDate = e != null ? e[0] : ''
        const endDate = e != null ? e[1] : ''
        setDateRange(e)
        setFilterList((draft) => {
            const index = draft.findIndex(
                (e) => e.filterColumn.label === columnData.label
            )
            if (e != null) {
                draft[index] = {
                    filterColumn: columnData,
                    value: {
                        startDate: startDate,
                        endDate: endDate,
                    },
                }
            } else {
                draft[index] = {
                    filterColumn: columnData,
                    value: [],
                }
            }
        })
        if (data.filterColumn.id === 'Updated date') {
            setFilter((draft) => {
                draft.filter.updatedAt = {
                    startDate: startDate,
                    endDate: endDate,
                }
                draft.currentPage = 0
            })
        } else if (data.filterColumn.id === 'Uploaded date') {
            setFilter((draft) => {
                draft.filter.createdAt = {
                    startDate: startDate,
                    endDate: endDate,
                }
                draft.currentPage = 0
            })
        } else {
            setFilter((draft) => {
                const customFilter = draft.filter.customAttributeFilter
                if (e == null) {
                    delete draft.filter.customAttributeFilter[columnData.id]
                } else {
                    customFilter[columnData.id] = {
                        from: startDate,
                        to: endDate,
                    }
                    draft.filter.customAttributeFilter = customFilter
                }
                draft.currentPage = 0
            })
        }
    }
    return (
        <>
            <ClickAwayListener
                onClickAway={() => handleClickAway(columnData.label)}
            >
                <div className="absolute h-80 pt-2">
                    <DateRangePicker
                        onChange={(e: any) => handleOnChange(e, data)}
                        renderInput={(params) => <TextField {...params} />}
                        value={
                            value ? [value.startDate, value.endDate] : dateRange
                        }
                        autoFocus
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        direction="horizontal"
                        fixedHeight={true}
                    />
                </div>
            </ClickAwayListener>
        </>
    )
}

export default DateFilter
