import { FC, useContext, useEffect } from 'react'
import useFile from '@Common/Queries/MutationQueries/file'
import useUploadFile from '@Common/Queries/MutationQueries/uploadFile'
import useUpdateAsset from '@Common/Queries/MutationQueries/UpdateAsset'
import useAddAsset from '@Common/Queries/MutationQueries/AddAsset'
import Snackbar from '@mui/material/Snackbar'
import CircularProgress from '@mui/material/CircularProgress'
import { Updater, useImmer } from 'use-immer'
import { Permission } from '@Common/Permission'
import { useLocation, useNavigate } from 'react-router-dom'
import { searchUpdaterFunctionForUpload } from '@Search/utils/searchTableUpdater'
import { useQueryClient } from 'react-query'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import { MultipleUploadContext } from 'src/App'

export interface Asset {
    file: File
    fileDetails?: FileDetails
    status: AssetStatus
    assetDetails?: any
    error?: string
    id: string
    progress?: number
}
export interface FileDetails {
    fileName: string
    fileId: string
}
export enum AssetStatus {
    'initial',
    'uploading',
    'uploaded',
    'error',
    'completed',
}
const AssetUploadInput: FC<{
    fileInputRef: any
    multiple: boolean
    drawerOpen?: boolean
    setDrawerOpen?: any
    setAsset: Updater<{ fileId: ''; assetId: '' }>
}> = ({ fileInputRef, multiple, drawerOpen, setDrawerOpen, setAsset }) => {
    const { assetsFiles, setAssetsFiles, handleFiles } = useContext(
        MultipleUploadContext
    )
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const sendDataToGTM = useGTMDispatch()

    const onSaveAsset = async (data: any, val: any) => {
        const file = val.file
        const formData = new FormData()

        formData.append('file', file)
        formData.append('fields', data.data.file)
        const obj = {
            url: data?.data?.preSignedUrl,
            body: file,
            data: data.data.file,
            file: file,
            id: val.id,
        }

        if (data.data.preSignedUrl) {
            uploadFile(obj)
        }
    }

    const onAddAssetSuccess = (res: any, variables: { id: string }) => {
        setAssetsFiles((draft) => {
            const asset = draft.find((asset) => asset.id === variables.id)
            if (asset) {
                asset.status = AssetStatus.completed
                asset.assetDetails = res.data
            }
        })
        if (setDrawerOpen) {
            setDrawerOpen(false)
        }

        setAsset((draft) => {
            return {
                assetId: res.data.id,
                fileId: res.data.fileId,
            }
        })

        if (assetsFiles.length === 1) {
            navigate(`/search/${res.data.id}`)
        }
    }
    const onErrorAddAsset = (err: any, variables: any) => {
        setAssetsFiles((draft) => {
            const asset = draft.find((asset) => asset.id === variables.id)
            if (asset) {
                asset.status = AssetStatus.error
                asset.error = 'Create asset not successful'
            }
        })
    }

    const onUploadSuccess = (res: any, variables: any) => {
        setAssetsFiles((draft) => {
            const asset = draft.find((asset) => asset.id === variables.id)
            if (asset) {
                asset.status = AssetStatus.uploaded
                asset.fileDetails = variables.data.id
            }
        })

        const body = {
            name: variables?.file?.name,
            shareable: false,
            fileId: variables?.data?.id,
            assetTypeId: '1',
            access: Permission.VIEW,
        }
        sendDataToGTM({
            event: 'asset_add',
            asset_id: res.data.id,
            asset_name: res.data.name,
        })
        addAsset({
            ...variables,
            fileDetails: variables.data.id,
            body: body,
        })
    }

    const onErrorAsset = (err: any, variables: any) => {
        console.log(JSON.stringify(err))
        setAssetsFiles((draft) => {
            const asset = draft.find((asset) => asset.id === variables.id)
            if (asset) {
                asset.status = AssetStatus.error
                asset.error =
                    err.response.status === 422
                        ? 'File Type not supported'
                        : 'Create asset not successful'
            }
        })
    }
    const option = (obj: any) => {
        return {
            onUploadProgress: (progressEvent: { loaded: any; total: any }) => {
                const { loaded, total } = progressEvent
                var percent = Math.floor((loaded * 100) / total)
                setAssetsFiles((draft) => {
                    const asset = draft.find((asset) => asset.id === obj.id)
                    if (asset) {
                        asset.progress = percent
                    }
                })
            },
            headers: { 'Content-Type': 'multipart/form-data' },
        }
    }
    const onUploadError = (err: any, variables: any) => {
        setAssetsFiles((draft) => {
            const asset = draft.find((asset) => asset.id === variables.id)
            if (asset) {
                asset.status = AssetStatus.error
                if (err.response.data.search('EntityTooLarge') >= 0) {
                    asset.error = 'File exceeded the 50MB size limit'
                    return
                }
                asset.error = 'Upload file not successful'
            }
        })
    }
    const {
        mutateAsync: saveFile,
        isSuccess: isSuccessSaveFile,
        isError: isErrorSaveFile,
        isLoading: isLoadingSaveFile,
        data: saveFileData,
    } = useFile({ onSuccess: onSaveAsset, onError: onErrorAsset })
    const {
        mutate: uploadFile,
        isSuccess: isSuccessUploadFile,
        isError: isErrorUploadFile,
        isLoading: isLoadingUploadFile,
        data: UpdateUploadFile,
    } = useUploadFile({
        onSuccess: onUploadSuccess,
        option: option,
        onError: onUploadError,
    })
    const {
        mutate: addAsset,
        isSuccess: isSuccessUpdateAsset,
        isError: isErrorUpdateAsset,
        isLoading: isLoadingUpdateAsset,
        data: UpdateAssetData,
    } = useAddAsset({ onSuccess: onAddAssetSuccess, onError: onErrorAddAsset })

    useEffect(() => {
        async function uploadFiles() {
            const unUploadedFiles = assetsFiles.filter(
                (assetFile) => assetFile.status === AssetStatus.initial
            )
            if (unUploadedFiles.length === 1 && setDrawerOpen) {
                setDrawerOpen(true)
            } else if (unUploadedFiles.length > 1) {
                navigate(`/search/assets`)
            }
            setAssetsFiles((draft) => {
                draft.forEach((assetFile) => {
                    if (assetFile.status === AssetStatus.initial) {
                        assetFile.status = AssetStatus.uploading
                    }
                })
            })
            const saveFiles = unUploadedFiles.map((assetFile) => {
                return saveFile(assetFile)
            })
            await Promise.all(saveFiles)
        }

        uploadFiles()
    }, [assetsFiles])

    const filesSelected = () => {
        if (fileInputRef.current.files.length) {
            handleFiles(fileInputRef.current.files)
        }
    }
    return (
        <>
            <input
                id="file-upload"
                name="file-upload"
                type="file"
                className="hidden"
                ref={fileInputRef}
                multiple={multiple}
                onChange={filesSelected}
            />
        </>
    )
}

export default AssetUploadInput
