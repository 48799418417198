import { Avatar, Breadcrumbs, Link, Typography } from '@mui/material'
import { Link as LinkRoute } from 'react-router-dom'
import { useImmer } from 'use-immer'
import { ErrorOutlineOutlined, FileUploadOutlined } from '@mui/icons-material'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import useDriveUploadDetails from '@Common/Queries/GetQueries/GetDriveUploadDetails'
import { useDriveStore } from 'src/Stores/DriveStore'
import RightSlideContainer from '@Common/Components/RightSlideContainer'
import ViewAssetScreen from 'src/ViewAssetScreen/ViewAssetScreen'
import Loading from '@Common/Components/Loading'
import toast from 'react-hot-toast'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'

export const DriveUploadPage = () => {
    const fileData = useDriveStore((state) => state.fileData)
    const accessToken = useDriveStore((state) => state.accessToken)

    if (fileData.length === 0) {
        return (
            <Typography
                variant="body2"
                className="flex flex-1 w-full h-full items-center justify-center text-lg"
            >
                <ErrorOutlineOutlinedIcon />
                <p className="pl-2">You do not have access to this page.</p>
            </Typography>
        )
    }
    const [viewAssetOpen, setViewAssetOpen] = useImmer(false)
    const [asset, setAsset] = useImmer({
        assetId: '',
        fileId: '',
        data: {},
    })
    const uploadResponse = useDriveUploadDetails({
        fileDetails: fileData,
        accessToken: accessToken,
    })

    if (uploadResponse.isLoading) {
        return (
            <div className="flex flex-1 flex-col h-full w-full">
                <div className="flex flex-1 flex-col items-center justify-center">
                    <div className="p-4">
                        <Loading />
                    </div>
                    <div className="p-4">
                        <Typography variant="body2">
                            Files are being uploaded
                        </Typography>
                    </div>
                </div>
            </div>
        )
    }
    if (uploadResponse.isError) {
        return toast.error('Error occured while fetching file details')
    }

    const getFileSize = (size: any, status: string, error?: string) => {
        if (status === 'error') {
            return error
        }
        if (size === 0) {
            return '0 Bytes'
        }
        const k = 1024
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
        const i = Math.floor(Math.log(size) / Math.log(k))
        return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
    }

    return (
        <div className="flex flex-row w-full h-full">
            <div className="flex flex-col flex-1 p-6">
                <div role="presentation" className="flex w-full pb-6">
                    <Breadcrumbs className="my-auto">
                        <Link
                            underline="hover"
                            component={LinkRoute}
                            color="text.secondary"
                            to="/search"
                        >
                            Documents
                        </Link>
                        <Typography color="text.primary">Upload</Typography>
                    </Breadcrumbs>
                </div>
                {uploadResponse?.data?.data?.map((value) => {
                    const isError = value.status == 'error'
                    const color = value.status
                    return (
                        <div
                            className="flex hover:bg-hoverBackground hover:cursor-pointer w-full"
                            onClick={() => {
                                setAsset((draft) => {
                                    draft.assetId = value.data.id
                                    draft.fileId = value.data.fileId
                                    draft.data = value.data
                                })
                                setViewAssetOpen(true)
                            }}
                        >
                            <Avatar
                                className="bg-avatarBackground m-4"
                                sx={{
                                    height: 40,
                                    width: 40,
                                }}
                            >
                                <FileUploadOutlined
                                    color={color}
                                ></FileUploadOutlined>
                            </Avatar>
                            <div className="flex h-full w-full items-center">
                                <div className="flex flex-col w-full">
                                    <Typography color={color}>
                                        {value.data.name}
                                    </Typography>
                                    <div className="flex w-full">
                                        <Typography
                                            variant="body2"
                                            color={color}
                                            className="flex pr-1"
                                        >
                                            {getFileSize(
                                                value.size,
                                                value.status,
                                                value.error
                                            )}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color={color}
                                        >
                                            .
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color={color}
                                            className="flex pl-1"
                                        >
                                            {isError ? 'Failed' : 'Completed'}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                            {!isError && (
                                <div className="flex items-center pr-4">
                                    <CheckCircleOutlineOutlinedIcon color="success" />
                                </div>
                            )}
                        </div>
                    )
                })}
            </div>
            <RightSlideContainer open={viewAssetOpen}>
                <ViewAssetScreen
                    asset={asset}
                    setOpen={setViewAssetOpen}
                    location={''}
                    setAsset={setAsset}
                ></ViewAssetScreen>
            </RightSlideContainer>
        </div>
    )
}
