import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import toast from 'react-hot-toast'
import { QueryClient, useMutation, useQueryClient } from 'react-query'

export const useUpdateSectionName = () => {
    const queryClient = useQueryClient()
    return useMutation(
        (data) =>
            axios.put(`${API_URL}/dealroom/section/${data.id}`, data.data),
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries(['dealroom', data.data.id])
            },
            onMutate: (data) => {
                const dealRoom = queryClient.getQueryData(['dealroom', data.id])
                if (!dealRoom) {
                    return
                }
                const dealroomSectionData = dealRoom.dealRoom.DealRoomSection
                const dealRoomSectionIndex = dealroomSectionData.findIndex(
                    (obj) => {
                        return obj.id === data.data.sectionId
                    }
                )
                dealroomSectionData[dealRoomSectionIndex].name = data.data.name
                queryClient.setQueryData(['dealroom', data.id], {
                    dealRoom: {
                        ...dealRoom.dealRoom,
                        DealRoomSection: dealroomSectionData,
                    },
                    thumbnail: dealRoom.thumbnail,
                })
            },
            onError: () => {
                toast.error('Error in name update')
            },
        }
    )
}
