import { APi_URL } from '@Common/Types/configs/urlConfig'
import { redirectPage } from '@Common/utils/previousPageCheck'
import axios from 'axios'
import Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'

export const tokenKey = 'jwt'
export interface Token {
    accessToken: string
    identityToken: string
    refreshToken: string
}
class AuthenticationManager {
    _token: Token | null = null
    _user: { exp: number; sub: string } | null = null
    _userChangeReg = []
    userChanged: ((ref: AuthenticationManager) => void) | null = null

    tokenUpdated(newToken: Token) {
        this._token = newToken
        if (this.userChanged) {
            this.userChanged(this)
        }
    }

    constructor() {
        var token = window.localStorage.getItem(tokenKey)
        if (token) {
            const rawToken = window.localStorage.getItem(tokenKey)
            if (!rawToken || rawToken === 'undefined' || rawToken === 'null') {
                return
            }
            token = JSON.parse(rawToken)
        }
        this._processToken(token)
            .then((done) => {})
            .catch((error) => {})
        window.addEventListener(
            'storage',
            async (event) => {
                if (event.key === tokenKey) {
                    var newToken = event.newValue
                    if (newToken) {
                        newToken = JSON.parse(newToken)
                    }

                    await this._processToken(newToken)
                    this.tokenUpdated(newToken)
                }
            },
            false
        )

        setInterval(() => this.checkTokenExpiry(this), 60000)
    }

    checkTokenExpiry(_this?: AuthenticationManager) {
        if (!_this) {
            _this = this
        }
        if (_this._user != null) {
            let expired =
                _this._user.exp - 120 < (Date.now() + 1000 * 60 * 1) / 1000
            if (expired) {
                axios
                    .post(`${APi_URL}/refresh`, {
                        username: _this._user.sub,
                        refreshToken: _this._token?.refreshToken,
                    })
                    .then((response) => {
                        const result = response
                        this.updateToken({
                            accessToken: result.data.accessToken.jwtToken,
                            identityToken: result.data.idToken.jwtToken,
                            refreshToken: result.data.refreshToken.token,
                        })
                    })
                    .catch((error) => {
                        localStorage.clear()
                        redirectPage()
                    })
                console.log('Token Expired')
            }
        }
        return Promise.resolve()
    }

    async _processToken(token: Token | null) {
        if (!token) {
            return
        }
        this._token = token
        this._user = null
        try {
            this._user = jwt_decode(token.accessToken)
            await this.checkTokenExpiry(this)
            Cookies.set(tokenKey, JSON.stringify(token), {
                secure: true,
                domain: 'lifex.cloud',
            })
        } catch (error) {
            console.log(error)
            alert(error.message)
        }
    }

    async updateToken(token: Token | null) {
        this._token = token
        await this._processToken(token)
        if (token) {
            window.localStorage.setItem(tokenKey, JSON.stringify(token))
        } else {
            window.localStorage.removeItem(tokenKey)
        }
    }

    getAccessToken() {
        let result = window.localStorage.getItem(tokenKey)
        if (!result) {
            return ''
        }
        return JSON.parse(result).accessToken
    }

    getRefreshToken() {
        let result = window.localStorage.getItem(tokenKey)
        if (!result) {
            return ''
        }
        return JSON.parse(result).refreshToken
    }

    logout() {
        this.updateToken(null)
    }
}
const authManager = new AuthenticationManager()
export { authManager }
