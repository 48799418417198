import { useQuery } from 'react-query'
import axios from 'axios'
import { API_URL } from '@Common/Types/configs/urlConfig'

const getCommentsByRequestId = async (requestId: string) => {
    const { data } = await axios.get(
        `${API_URL}/comments/requests/${requestId}`
    )
    return data
}

export default function useComments(requestId: string) {
    return useQuery(['comments', 'requests', requestId], () =>
        getCommentsByRequestId(requestId)
    )
}
