import useFeatureFlag from '@Common/hooks/useFeatureFlag'
import useLocalStorage from '@Common/hooks/useLocalStorage'
import useInit from '@Common/Queries/GetQueries/useInit'
import useThemeFromDb from '@Common/Queries/GetQueries/useTheme'
import { default as AvatarComponent } from '@Common/Avatar'
import {
    Add,
    CorporateFareOutlined,
    KeyboardArrowDownOutlined,
    Person,
    PersonOutline,
} from '@mui/icons-material'
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import GridViewIcon from '@mui/icons-material/GridView'
import LanguageIcon from '@mui/icons-material/LanguageOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { alpha, Menu, Typography, useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import { forwardRef, RefObject, useMemo, useRef } from 'react'
import { toast } from 'react-hot-toast'
import { Link, NavLink, RouterProps } from 'react-router-dom'
import useUserWorkspaces from 'src/Onboard/query/useUserWorkspaces'
import LinkIcon from '@mui/icons-material/Link'
import { useImmer } from 'use-immer'
import logo from '../../../assets/mathco_purple.png'
import Loading from '../Loading'
import useSwitchWorkspace from './useSwitchWorkspace'
import Avatar from '../Avatar'
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined'
import ChecklistIcon from '@mui/icons-material/Checklist'
interface ListItemLinkProps {
    dataTestId?: string
    icon?: React.ReactElement
    primary: string
    to: string
}

function ListItemLink(props: ListItemLinkProps) {
    const { dataTestId, icon, primary, to } = props
    const theme = useTheme()

    const renderLink = useMemo(
        () =>
            forwardRef<HTMLAnchorElement, Omit<RouterProps, 'to'>>(
                function Link(itemProps, ref) {
                    return (
                        <NavLink
                            data-testid={dataTestId}
                            to={to}
                            style={({ isActive }) =>
                                isActive
                                    ? {
                                          background: '#EBF1FF',
                                          color: '#2E66FC',
                                      }
                                    : {
                                          color: 'rgba(0, 0, 0, 0.54)',
                                      }
                            }
                            ref={ref}
                            {...itemProps}
                            role={undefined}
                        />
                    )
                }
            ),
        [to]
    )

    return (
        <li className="px-1">
            <ListItem component={renderLink} dense className="px-4">
                {icon ? icon : null}
                <ListItemText
                    primary={primary}
                    className="pl-5"
                    color="text.secondary"
                />
            </ListItem>
        </li>
    )
}
export const PermanentLeftNav = () => {
    const themeFromDb = useThemeFromDb({
        onSuccess: (data) => {
            setCurrentTheme(data || {})
        },
    })
    const workspaceFlag = useFeatureFlag('workspace_type')
    const sites = useFeatureFlag('sites')
    const contacts = useFeatureFlag('contacts')
    const dashboard = useFeatureFlag('dashboard')
    const dealRoom = useFeatureFlag('dealroom')
    const [currentTheme, setCurrentTheme] = useLocalStorage('theme', {})
    const buttonRef = useRef<HTMLButtonElement>(null)
    const [tenantMenu, setTenantMenu] = useImmer({ show: false })
    const init = useInit()

    if (init.isLoading) {
        return <Loading></Loading>
    }
    if (init.isError) {
        return toast.error('Error occured while fetching details')
    }
    const authTokenGetter = async () => {
        let tokenData = localStorage.getItem('jwt')
        if (tokenData) {
            const parsedData = JSON.parse(tokenData)
            return parsedData?.identityToken
        }
    }

    return (
        <>
            <Box className="flex z-0">
                <Drawer
                    variant="permanent"
                    anchor="left"
                    className="w-52"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                    }}
                    PaperProps={{
                        className: 'w-52 box-border bg-navColor ',
                    }}
                >
                    {!workspaceFlag && (
                        <div className="m-5 py-2">
                            <img src={logo} />
                        </div>
                    )}
                    {workspaceFlag && (
                        <Button
                            data-testid="nav-workspace-btn"
                            ref={buttonRef}
                            onClick={() => {
                                setTenantMenu((draft) => {
                                    draft.show = !draft.show
                                })
                            }}
                            className="flex text-gray-600 p-5 justify-between"
                        >
                            <div className="flex items-center flex-1 gap-2">
                                <Avatar
                                    variant="rounded"
                                    className="mr-2"
                                    sx={{
                                        backgroundColor: '#2E66FC',
                                        height: 32,
                                        width: 32,
                                    }}
                                    name={init.data.tenant.name}
                                ></Avatar>
                                <div>{init.data.tenant.name}</div>
                            </div>
                            <KeyboardArrowDownOutlined />
                        </Button>
                    )}
                    <TenantIcon
                        buttonRef={buttonRef}
                        tenantMenu={tenantMenu}
                        handleClose={() => {
                            setTenantMenu((draft) => {
                                draft.show = false
                            })
                        }}
                    />
                    <List className="p-0 pt-3">
                        {dashboard && (
                            <ListItemLink
                                dataTestId="nav-dashboard"
                                primary="Dashboard"
                                icon={<GridViewIcon className="w-5 h-5" />}
                                to="/dashboard"
                            ></ListItemLink>
                        )}
                        <ListItemLink
                            dataTestId="nav-settings"
                            primary="Settings"
                            icon={<SettingsOutlinedIcon className="w-5 h-5" />}
                            to="/settings"
                        ></ListItemLink>
                        <Typography
                            color="text.disabled"
                            className="px-5 pt-8 pb-2"
                            fontSize={12}
                        >
                            CONTENT
                        </Typography>
                        <ListItemLink
                            dataTestId="nav-documents"
                            primary="Documents"
                            icon={
                                <DescriptionOutlinedIcon className="w-5 h-5" />
                            }
                            to="/search"
                        ></ListItemLink>
                        {init?.data.tenant?.TenantConfig?.type ===
                            'ENTERPRISE' && (
                            <ListItemLink
                                dataTestId="nav-requests"
                                primary="Requests"
                                icon={
                                    <ConfirmationNumberOutlinedIcon className="w-5 h-5" />
                                }
                                to="/requests"
                            ></ListItemLink>
                        )}
                        {init?.data.tenant?.TenantConfig?.type ===
                            'ENTERPRISE' && (
                            <ListItemLink
                                dataTestId="nav-approvals"
                                primary="Approvals"
                                icon={<ChecklistIcon className="w-5 h-5" />}
                                to="/approvals"
                            ></ListItemLink>
                        )}

                        {(dashboard || contacts) && (
                            <Typography
                                color="text.disabled"
                                className="px-5 pt-8 pb-2"
                                fontSize={12}
                            >
                                SALES
                            </Typography>
                        )}
                        {dashboard && (
                            <ListItemLink
                                dataTestId="nav-linkvisits"
                                primary="Link visits"
                                icon={<LinkIcon className="w-5 h-5"></LinkIcon>}
                                to="/visits"
                            ></ListItemLink>
                        )}
                        {contacts && (
                            <ListItemLink
                                dataTestId="nav-contacts"
                                primary="Contacts"
                                icon={<PersonOutline className="w-5 h-5" />}
                                to="/contacts"
                            ></ListItemLink>
                        )}
                        {contacts && (
                            <ListItemLink
                                dataTestId="nav-accounts"
                                primary="Accounts"
                                icon={
                                    <CorporateFareOutlined className="w-5 h-5" />
                                }
                                to="/accounts"
                            ></ListItemLink>
                        )}
                        {dealRoom && (
                            <ListItemLink
                                dataTestId="nav-dealroom"
                                primary="Digital room"
                                icon={
                                    <MeetingRoomOutlinedIcon className="w-5 h-5" />
                                }
                                to="/dealroom"
                            ></ListItemLink>
                        )}
                        {sites && (
                            <ListItemLink
                                dataTestId="nav-sites"
                                primary="Sites"
                                icon={<LanguageIcon className="w-5 h-5" />}
                                to="/sites"
                            ></ListItemLink>
                        )}
                    </List>
                    <minerva-wc
                        consumer_access_key="88630ebe-0c2b-4d36-b7d2-40e7e980cc22"
                        trigger_button_variant="float"
                        theme_values='{"contrastColor":"#2e66fc"}'
                        load_from_cache={true}
                        ref={(minervaEle) => {
                            if (minervaEle) {
                                minervaEle.auth_token_getter = authTokenGetter
                            }
                        }}
                    />
                </Drawer>
            </Box>
        </>
    )
}

export default function TenantIcon(props: {
    buttonRef: RefObject<HTMLButtonElement>
    tenantMenu: { show: boolean }
    handleClose: () => void
}) {
    const init = useInit()
    const userWorkspaces = useUserWorkspaces()
    const switchWorkspace = useSwitchWorkspace({
        onSuccess: () => {
            window.location.href = '/'
        },
    })

    if (init.isError || userWorkspaces.isError) {
        return toast.error('Error occured while fetching details')
    }
    if (props.tenantMenu.show && userWorkspaces.isLoading) return <Loading />
    if (!props.tenantMenu.show && userWorkspaces.isLoading) return <></>
    return (
        <Menu
            id="basic-menu"
            anchorEl={props.buttonRef.current}
            open={props.tenantMenu.show}
            onClose={props.handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            <div className="pb-2 px-4 text-xs text-gray-500">
                {init.data.user.name}
            </div>
            <Divider />
            {(init.isLoading || userWorkspaces.isLoading) && (
                <MenuItem>
                    {' '}
                    <Loading size="1.25rem"></Loading>
                </MenuItem>
            )}
            {!(init.isLoading || userWorkspaces.isLoading) &&
                userWorkspaces.data.map((userWorkspace, ind) => {
                    return (
                        <MenuItem
                            key={ind}
                            onClick={() => {
                                switchWorkspace.mutate({
                                    workspaceId: userWorkspace.id,
                                })
                            }}
                            className="flex items-center  gap-4"
                        >
                            <div>
                                <AvatarComponent name={userWorkspace.name} />
                            </div>
                            <div>
                                <div>{userWorkspace.name}</div>
                                <div className="text-gray-400">
                                    {userWorkspace.users} members
                                </div>
                            </div>
                        </MenuItem>
                    )
                })}
            <Divider />
            <Link className="flex h-full justify-center " to={'/workspace'}>
                <MenuItem>
                    <Add className="h-4" />
                    Join or Create Workspace
                </MenuItem>
            </Link>
            <Divider />
            <MenuItem
                onClick={() => {
                    localStorage.clear()
                    window.location.href = '/login'
                }}
                className="w-52"
            >
                <ListItemText data-testid="nav-logout">Log Out</ListItemText>
            </MenuItem>
        </Menu>
    )
}
