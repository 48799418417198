import { useQuery } from 'react-query'
import { APi_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { SearchGroup } from '@Server/searchGroup'
export default function useUsers(debouncedFilter: SearchGroup) {
    const users = useQuery(
        ['Users', debouncedFilter],
        async () => {
            const data = await axios.post(
                `${APi_URL}/users/search`,
                debouncedFilter,
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            )
            return data.data
        },
        {
            keepPreviousData: true,
        }
    )
    return users
}
