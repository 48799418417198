import {
    Autocomplete,
    Button,
    Checkbox,
    Chip,
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent,
    Switch,
    TextField,
    Typography,
} from '@mui/material'

import Loading from '@Common/Components/Loading'
import useGetAsset from '@Common/Queries/GetQueries/GetAsset'
import useUsers from '@Common/Queries/GetQueries/GetUsers'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import InfoIcon from '@mui/icons-material/Info'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { format, isValid, parseISO } from 'date-fns'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useImmer } from 'use-immer'
import TrackProperties, {
    emailRegex,
} from 'src/Track/Components/TrackProperties'
import { string } from 'prop-types'
import useAddTrackLink from '@Common/Queries/MutationQueries/CreateTrackLink'
import { Updater } from 'use-immer'
import { VIEWER_DOMAIN } from '@Common/Types/configs/urlConfig'
import TrackLinkConfirmation from 'src/Track/Components/TrackLinkConfirmation'
import { toast } from 'react-hot-toast'
import { AccountCircleOutlined, InfoOutlined } from '@mui/icons-material'
import { Contacts } from '@Common/Components/Select/Contacts'
import { checkEmail } from '@Common/ValidationCheck'

interface ConfigurationPageProperties {
    contacts: {
        value: string
        label: string
    }[]
    name: string
    expires: boolean
    validTill: string | null
    allowDownload: boolean
    emailRequired: boolean
    passcodeRequired: boolean
    passcode: string | null
    emailAuthRequired: boolean
    restrictAccess: boolean
    accessAllow?: boolean
    allowEmail: string[]
    accessBlock?: boolean
    blockEmail: string[]
    advancedControlsAuth?: boolean
}

export default function Configuration() {
    const [error, setError] = useImmer({
        contact: false,
        link: false,
    })

    const [currAuthConfig, setCurrAuthConfig] =
        useImmer<ConfigurationPageProperties>({
            contacts: [],
            name: '',
            expires: false,
            validTill: null,
            allowDownload: false,
            emailRequired: false,
            passcodeRequired: false,
            passcode: '',
            advancedControlsAuth: false,
            emailAuthRequired: false,
            allowEmail: [],
            blockEmail: [],
            restrictAccess: false,
            accessAllow: false,
            accessBlock: false,
        })

    const domainRegex = /[\w\d\.-]+\.[\w\d\.-]+/

    const handleAllowEmail = (
        event: React.SyntheticEvent,
        value: T | Array<T>,
        reason: string,
        details: { option: string }
    ) => {
        const email = details.option.trim()
        const test = emailRegex.test(email) || domainRegex.test(email)
        let newSelectedItem = [...currAuthConfig.allowEmail]
        if (test && reason === 'createOption') {
            const duplicatedValues = newSelectedItem.indexOf(email)
            if (duplicatedValues === -1) {
                newSelectedItem.push(email)
            }
        } else if (reason === 'removeOption') {
            const dataIndex = newSelectedItem.findIndex((e) => e === email)
            newSelectedItem.splice(dataIndex, 1)
        } else if (reason === 'clear') {
            newSelectedItem = []
        }
        setCurrAuthConfig((draft) => {
            draft.allowEmail = newSelectedItem
        })
    }
    const handleBlockEmail = (
        event: React.SyntheticEvent,
        value: T | Array<T>,
        reason: string,
        details: { option: string }
    ) => {
        const email = details.option.trim()
        const test = emailRegex.test(email) || domainRegex.test(email)
        let newSelectedItem = [...currAuthConfig.blockEmail]
        if (test && reason === 'createOption') {
            const duplicatedValues = newSelectedItem.indexOf(email)
            if (duplicatedValues === -1) {
                newSelectedItem.push(email)
            }
        } else if (reason === 'removeOption') {
            const dataIndex = newSelectedItem.findIndex((e) => e === email)
            newSelectedItem.splice(dataIndex, 1)
        } else if (reason === 'clear') {
            newSelectedItem = []
        }
        setCurrAuthConfig((draft) => {
            draft.blockEmail = newSelectedItem
        })
    }
    const params = new URLSearchParams(window.location.search)
    const fileId = params.get('fileId')
    const assetDetails = useGetAsset({
        id: fileId,
    })
    const formattedDate = (input: string) => {
        const parsedFormat = parseISO(input)
        return isValid(parsedFormat)
            ? format(parsedFormat, 'MMM dd, yyyy')
            : input
    }
    const userData = useUsers()

    const addTrackLink = useAddTrackLink()

    if (addTrackLink.isSuccess) {
        const code = addTrackLink.data.data.track.code
    }
    const getNameWithExtension = (name: string, filename: string) => {
        const extension = filename.split('.').pop()
        const finalName = name + '.' + extension
        return finalName
    }
    function CreateTrackLink() {
        if (currAuthConfig.contacts.length === 0) {
            setError((draft) => {
                draft.contact = true
            })
        } else if (currAuthConfig.name.length === 0) {
            setError((draft) => {
                draft.link = true
            })
        } else {
            const trackLinkProperties = { ...currAuthConfig }
            delete trackLinkProperties?.accessAllow
            delete trackLinkProperties?.accessBlock
            delete trackLinkProperties?.advancedControlsAuth
            addTrackLink.mutate(
                {
                    id: fileId,
                    enabled: true,
                    type: 'ASSET',
                    trackLinkProperties,
                },
                {
                    onSuccess: (response) => {
                        const code = response.data.track.code

                        window.parent.postMessage(
                            {
                                name: 'link',
                                link: `https://${VIEWER_DOMAIN}/${code}`,
                                assetDetails: {
                                    name: getNameWithExtension(
                                        assetDetails.data?.name,
                                        assetDetails.data.LatestFile.fileName
                                    ),
                                    description: assetDetails.data?.description,
                                    imageUrl: assetDetails.data?.imageUrl,
                                },
                            },
                            '*'
                        )
                    },
                }
            )
        }
    }

    const usermap = new Map()
    userData.data?.data.map((value: { id: any; name: any }) => {
        usermap.set(value.id, value.name)
    })
    const formattedOwner = (input: string) => {
        if (usermap.has(input)) {
            return usermap.get(input)
        }
        return 'Unknown User'
    }

    if (assetDetails.isLoading || userData.isLoading) {
        return <Loading></Loading>
    }
    if (assetDetails.isError || userData.isError) {
        return toast.error('Error occured while fetching data')
    }

    const filename = assetDetails?.data?.name
    const ownername = formattedOwner(assetDetails?.data?.ownerId)
    const updatedDate = formattedDate(assetDetails.data.updatedAt)
    const imagethumbmail = assetDetails.data.imageUrl

    return (
        <div className="flex flex-col w-full h-full">
            <div className="flex-1 h-full overflow-auto">
                <div className="flex flex-row h-full overflow-auto">
                    <div className="flex flex-col w-72 pt-5 h-full">
                        <div className="flex flex-col w-full items-center pb-3">
                            <div className="h-36 overflow-hidden border border-gray-400">
                                <img
                                    src={imagethumbmail}
                                    className="w-64"
                                ></img>
                            </div>
                        </div>
                        <div className="flex flex-col w-full items-center">
                            <Typography className="flex flex-col w-64 py-0.5 break-words text-xl font-medium">
                                {filename}
                            </Typography>
                            <Typography
                                className="flex flex-col w-64 items-start py-0.5 body2"
                                variant="body2"
                            >
                                {ownername}
                            </Typography>
                            <Typography
                                className="flex flex-col w-64 items-start py-0.5"
                                variant="body2"
                            >
                                {updatedDate}
                            </Typography>
                        </div>
                    </div>
                    <div className="flex-1 w-full  border-l border-gray-300 p-3 pt-3 overflow-auto">
                        <div className="flex flex-col w-full p-3">
                            <div className="flex flex-row h-10 w-full items-center">
                                <div className="flex">
                                    <div className="flex text-gray-600 pr-2">
                                        <AccountCircleOutlined fontSize="small" />
                                    </div>
                                    <div className="flex-1 w-full text-gray-600">
                                        Contact name (Required)
                                    </div>
                                </div>
                            </div>
                            <div className="pb-2">
                                <Contacts
                                    multiple={true}
                                    freeSolo={true}
                                    defaultValue=""
                                    onChange={(newValue, reason) => {
                                        setError((draft) => {
                                            draft.contact = false
                                        })
                                        if (
                                            reason === 'createOption' &&
                                            newValue
                                        ) {
                                            const contacts = newValue
                                                .map(
                                                    (
                                                        email:
                                                            | string
                                                            | { value: string }
                                                    ) => {
                                                        if (!email.value) {
                                                            if (
                                                                emailRegex.test(
                                                                    email
                                                                )
                                                            ) {
                                                                return email
                                                            }
                                                        }
                                                        if (email.value) {
                                                            return email
                                                        }
                                                    }
                                                )
                                                .filter((e) => e != undefined)
                                            setCurrAuthConfig((draft) => {
                                                draft.contacts = contacts
                                            })
                                        } else {
                                            setCurrAuthConfig((draft) => {
                                                draft.contacts = newValue
                                            })
                                        }
                                    }}
                                />
                                {error.contact && (
                                    <Typography color="error" className="p-1">
                                        Contact is required
                                    </Typography>
                                )}
                            </div>
                            <div className="flex flex-row h-10 w-full items-center">
                                <div className="flex">
                                    <div className="flex text-gray-600 pr-2">
                                        <InfoOutlined fontSize="small" />
                                    </div>
                                    <div className="flex-1 w-full text-gray-600">
                                        Link Name
                                    </div>
                                </div>
                            </div>
                            <div className="pb-2">
                                <TextField
                                    required
                                    size="small"
                                    fullWidth
                                    placeholder="Give a name Ex: Accounts, email, person"
                                    variant="outlined"
                                    onChange={(e) => {
                                        setError((draft) => {
                                            draft.link = false
                                        })
                                        setCurrAuthConfig((draft) => {
                                            draft.name = e.target.value
                                        })
                                    }}
                                ></TextField>
                                {error.link && (
                                    <Typography color="error" className="p-1">
                                        Name is required
                                    </Typography>
                                )}
                            </div>
                            <div className="flex w-full h-10 items-center">
                                <div className="text-gray-600 pr-2">
                                    <AccessTimeIcon fontSize="small" />
                                </div>
                                <div className="flex-1 text-gray-600">
                                    Expires
                                </div>
                                <Switch
                                    checked={currAuthConfig.expires}
                                    size="small"
                                    onChange={(event) => {
                                        setCurrAuthConfig((draft) => {
                                            draft.expires = event.target.checked
                                        })
                                    }}
                                />
                            </div>
                            {currAuthConfig.expires ? (
                                <div className="flex w-full h-10 items-center pb-2">
                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                    >
                                        <DateTimePicker
                                            value={currAuthConfig.validTill}
                                            onChange={(
                                                newExpiryDate: string
                                            ) => {
                                                setCurrAuthConfig((draft) => {
                                                    draft.validTill =
                                                        newExpiryDate
                                                })
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        placeholder:
                                                            'mm/dd/yyyy hh:mm (am|pm)',
                                                    }}
                                                ></TextField>
                                            )}
                                        ></DateTimePicker>
                                    </LocalizationProvider>
                                </div>
                            ) : null}

                            <div className="flex w-full h-10 items-center">
                                <div className="text-gray-600 pr-2">
                                    <FileDownloadIcon fontSize="small" />
                                </div>
                                <div className="flex-1 text-gray-600">
                                    Allow Downloads
                                </div>
                                <Switch
                                    checked={currAuthConfig.allowDownload}
                                    size="small"
                                    onChange={(event) => {
                                        setCurrAuthConfig((draft) => {
                                            draft.allowDownload =
                                                event.target.checked
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col w-full p-3">
                            <div className="flex w-full h-10 items-center">
                                <div className="flex-1 text-gray-400">
                                    Advanced controls
                                </div>
                                <Switch
                                    checked={
                                        currAuthConfig.advancedControlsAuth
                                    }
                                    size="small"
                                    onChange={(event) => {
                                        setCurrAuthConfig((draft) => {
                                            draft.advancedControlsAuth =
                                                event.target.checked
                                        })
                                    }}
                                />
                            </div>
                            {currAuthConfig.advancedControlsAuth && (
                                <div className="flex flex-col w-full">
                                    <div className="flex w-full h-10 items-center">
                                        <div className="text-gray-600 pr-2">
                                            <MailOutlineIcon fontSize="small" />
                                        </div>
                                        <div className="flex-1 text-gray-600">
                                            Requires E-mail to view
                                        </div>
                                        <Switch
                                            checked={
                                                currAuthConfig.emailRequired
                                            }
                                            size="small"
                                            disabled={
                                                !currAuthConfig.advancedControlsAuth
                                            }
                                            onChange={(event) => {
                                                setCurrAuthConfig((draft) => {
                                                    draft.emailRequired =
                                                        event.target.checked
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="flex w-full h-10 items-center">
                                        <div className="text-gray-600 pr-2">
                                            <VerifiedUserOutlinedIcon fontSize="small" />
                                        </div>
                                        <div className="flex-1 text-gray-600">
                                            E-mail Authentication
                                        </div>
                                        <Switch
                                            checked={
                                                currAuthConfig.emailAuthRequired
                                            }
                                            size="small"
                                            disabled={
                                                !currAuthConfig.advancedControlsAuth
                                            }
                                            onChange={(event) => {
                                                setCurrAuthConfig((draft) => {
                                                    draft.emailAuthRequired =
                                                        event.target.checked
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="flex-1 w-full h-full items-center">
                                        <div className="flex w-full h-10  items-center">
                                            <div className="flex text-gray-600 pr-2">
                                                <LockOutlinedIcon fontSize="small" />
                                            </div>
                                            <div className="flex-1 w-full text-gray-600">
                                                Passcode Protected
                                            </div>
                                            <Switch
                                                checked={
                                                    currAuthConfig.passcodeRequired
                                                }
                                                size="small"
                                                disabled={
                                                    !currAuthConfig.advancedControlsAuth
                                                }
                                                onChange={(event) => {
                                                    setCurrAuthConfig(
                                                        (draft) => {
                                                            draft.passcodeRequired =
                                                                event.target.checked
                                                        }
                                                    )
                                                }}
                                            />
                                        </div>
                                        {currAuthConfig.passcodeRequired && (
                                            <div className="flex w-[314px] pb-2">
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    variant="outlined"
                                                    placeholder="Enter Passcode"
                                                    disabled={
                                                        !currAuthConfig.advancedControlsAuth ||
                                                        !currAuthConfig.passcodeRequired
                                                    }
                                                    onChange={(e) => {
                                                        setCurrAuthConfig(
                                                            (draft) => {
                                                                draft.passcode =
                                                                    e.target.value
                                                            }
                                                        )
                                                    }}
                                                ></TextField>
                                            </div>
                                        )}
                                    </div>
                                    <div className="flex w-full h-10 items-center">
                                        <div className="text-gray-600 pr-2">
                                            <AdminPanelSettingsOutlinedIcon fontSize="small" />
                                        </div>
                                        <div className="flex text-gray-600">
                                            Restrict Access
                                        </div>
                                        <div className="flex-1 text-gray-600 pl-2">
                                            <InfoIcon fontSize="small" />
                                        </div>
                                        <Switch
                                            checked={
                                                currAuthConfig.restrictAccess
                                            }
                                            size="small"
                                            disabled={
                                                !currAuthConfig.advancedControlsAuth
                                            }
                                            onChange={(event) => {
                                                setCurrAuthConfig((draft) => {
                                                    draft.restrictAccess =
                                                        event.target.checked
                                                })
                                            }}
                                        />
                                    </div>
                                    {currAuthConfig.restrictAccess && (
                                        <div className="flex flex-col w-full">
                                            <div className="flex w-full h-10 item-center">
                                                <Checkbox
                                                    checked={
                                                        currAuthConfig.accessAllow
                                                    }
                                                    disabled={
                                                        !currAuthConfig.advancedControlsAuth ||
                                                        !currAuthConfig.restrictAccess ||
                                                        currAuthConfig.accessBlock
                                                    }
                                                    onClick={() => {
                                                        setCurrAuthConfig(
                                                            (draft) => {
                                                                draft.accessAllow =
                                                                    draft.accessAllow
                                                                        ? false
                                                                        : true
                                                            }
                                                        )
                                                    }}
                                                />
                                                <div className="flex text-gray-600 items-center">
                                                    Allow
                                                </div>
                                            </div>
                                            <div className="flex w-full h-10 item-center">
                                                <Autocomplete
                                                    multiple
                                                    options={[]}
                                                    size="small"
                                                    freeSolo
                                                    fullWidth
                                                    value={
                                                        currAuthConfig.allowEmail
                                                    }
                                                    onChange={handleAllowEmail}
                                                    disabled={
                                                        !currAuthConfig.advancedControlsAuth ||
                                                        !currAuthConfig.restrictAccess ||
                                                        currAuthConfig.accessBlock ||
                                                        !currAuthConfig.accessAllow
                                                    }
                                                    renderTags={(
                                                        value,
                                                        getTagsProps
                                                    ) => {
                                                        return (
                                                            <>
                                                                {value.map(
                                                                    (
                                                                        option,
                                                                        index
                                                                    ) => (
                                                                        <Chip
                                                                            {...getTagsProps(
                                                                                {
                                                                                    index,
                                                                                }
                                                                            )}
                                                                            key={
                                                                                index
                                                                            }
                                                                            label={
                                                                                option
                                                                            }
                                                                            size="small"
                                                                            className="whitespace-nowrapoverflow-hidden text-ellipsis"
                                                                        ></Chip>
                                                                    )
                                                                )}
                                                            </>
                                                        )
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            label="Enter e-mail/domain"
                                                        />
                                                    )}
                                                />
                                            </div>
                                            <div className="flex w-full h-10 item-center">
                                                <Checkbox
                                                    checked={
                                                        currAuthConfig.accessBlock
                                                    }
                                                    disabled={
                                                        !currAuthConfig.advancedControlsAuth ||
                                                        !currAuthConfig.restrictAccess ||
                                                        currAuthConfig.accessAllow
                                                    }
                                                    onClick={() => {
                                                        setCurrAuthConfig(
                                                            (draft) => {
                                                                draft.accessBlock =
                                                                    draft.accessBlock
                                                                        ? false
                                                                        : true
                                                            }
                                                        )
                                                    }}
                                                />
                                                <div className="flex text-gray-600 items-center">
                                                    Block
                                                </div>
                                            </div>
                                            <div className="flex w-full h-10 item-center">
                                                <Autocomplete
                                                    multiple
                                                    options={[]}
                                                    size="small"
                                                    freeSolo
                                                    fullWidth
                                                    value={
                                                        currAuthConfig.blockEmail
                                                    }
                                                    disabled={
                                                        !currAuthConfig.advancedControlsAuth ||
                                                        !currAuthConfig.restrictAccess ||
                                                        currAuthConfig.accessAllow ||
                                                        !currAuthConfig.accessBlock
                                                    }
                                                    onChange={handleBlockEmail}
                                                    renderTags={(
                                                        value,
                                                        getTagsProps
                                                    ) => {
                                                        return (
                                                            <>
                                                                {value.map(
                                                                    (
                                                                        option,
                                                                        index
                                                                    ) => (
                                                                        <Chip
                                                                            {...getTagsProps(
                                                                                {
                                                                                    index,
                                                                                }
                                                                            )}
                                                                            key={
                                                                                index
                                                                            }
                                                                            label={
                                                                                option
                                                                            }
                                                                            size="small"
                                                                            className="whitespace-nowrapoverflow-hidden text-ellipsis"
                                                                        ></Chip>
                                                                    )
                                                                )}
                                                            </>
                                                        )
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            label="Enter e-mail/domain"
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex w-full justify-between  h-16 border-t border-gray-300 items-center p-3">
                <div>
                    <Link to="/plugin">
                        <Button>Back</Button>
                    </Link>
                </div>
                <div className="">
                    <Button
                        variant="contained"
                        disabled={addTrackLink.isLoading}
                        onClick={CreateTrackLink}
                    >
                        {addTrackLink.isLoading && (
                            <div className="mr-2">
                                <Loading size="1.25rem" />
                            </div>
                        )}
                        Create & insert link
                    </Button>
                </div>
            </div>
        </div>
    )
}
