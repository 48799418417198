export const iconsByExtension = {
    xls: {
        class: 'ms-Icon ms-Icon--ExcelDocument',
        style: {
            color: '#00A34F',
            'font-weight': '300',
            width: '20px',
        },
    },
    xlsx: {
        class: 'ms-Icon ms-Icon--ExcelDocument',
        style: {
            color: '#00A34F',
            'font-weight': '300',
            width: '20px',
        },
    },
    csv: {
        class: 'ms-Icon ms-Icon--ExcelDocument',
        style: {
            color: '#00A34F',
            'font-weight': '300',
            width: '20px',
        },
    },
    xlsm: {
        class: 'ms-Icon ms-Icon--ExcelDocument',
        style: {
            color: '#00A34F',
            'font-weight': '300',
            width: '20px',
        },
    },
    xlsb: {
        class: 'ms-Icon ms-Icon--ExcelDocument',
        style: {
            color: '#00A34F',
            'font-weight': '300',
            width: '20px',
        },
    },
    xlt: {
        class: 'ms-Icon ms-Icon--ExcelDocument',
        style: {
            color: '#00A34F',
            'font-weight': '300',
            width: '20px',
        },
    },
    xltx: {
        class: 'ms-Icon ms-Icon--ExcelDocument',
        style: {
            color: '#00A34F',
            'font-weight': '300',
            width: '20px',
        },
    },
    xltm: {
        class: 'ms-Icon ms-Icon--ExcelDocument',
        style: {
            color: '#00A34F',
            'font-weight': '300',
            width: '20px',
        },
    },
    xlam: {
        class: 'ms-Icon ms-Icon--ExcelDocument',
        style: {
            color: '#00A34F',
            'font-weight': '300',
            width: '20px',
        },
    },
    xla: {
        class: 'ms-Icon ms-Icon--ExcelDocument',
        style: {
            color: '#00A34F',
            'font-weight': '300',
            width: '20px',
        },
    },
    ppt: {
        class: 'ms-Icon ms-Icon--PowerPointDocument',
        style: {
            color: '#D04423',
            'font-weight': '300',
            width: '20px',
        },
    },
    pptx: {
        class: 'ms-Icon ms-Icon--PowerPointDocument',
        style: {
            color: '#D04423',
            'font-weight': '300',
            width: '20px',
        },
    },
    pptm: {
        class: 'ms-Icon ms-Icon--PowerPointDocument',
        style: {
            color: '#D04423',
            'font-weight': '300',
            width: '20px',
        },
    },
    pot: {
        class: 'ms-Icon ms-Icon--PowerPointDocument',
        style: {
            color: '#D04423',
            'font-weight': '300',
            width: '20px',
        },
    },
    potx: {
        class: 'ms-Icon ms-Icon--PowerPointDocument',
        style: {
            color: '#D04423',
            'font-weight': '300',
            width: '20px',
        },
    },
    potm: {
        class: 'ms-Icon ms-Icon--PowerPointDocument',
        style: {
            color: '#D04423',
            'font-weight': '300',
            width: '20px',
        },
    },
    ppam: {
        class: 'ms-Icon ms-Icon--PowerPointDocument',
        style: {
            color: '#D04423',
            'font-weight': '300',
            width: '20px',
        },
    },
    pps: {
        class: 'ms-Icon ms-Icon--PowerPointDocument',
        style: {
            color: '#D04423',
            'font-weight': '300',
            width: '20px',
        },
    },
    ppsx: {
        class: 'ms-Icon ms-Icon--PowerPointDocument',
        style: {
            color: '#D04423',
            'font-weight': '300',
            width: '20px',
        },
    },
    ppsm: {
        class: 'ms-Icon ms-Icon--PowerPointDocument',
        style: {
            color: '#D04423',
            'font-weight': '300',
            width: '20px',
        },
    },
    sldx: {
        class: 'ms-Icon ms-Icon--PowerPointDocument',
        style: {
            color: '#D04423',
            'font-weight': '300',
            width: '20px',
        },
    },
    sldm: {
        class: 'ms-Icon ms-Icon--PowerPointDocument',
        style: {
            color: '#D04423',
            'font-weight': '300',
            width: '20px',
        },
    },
    odt: {
        class: 'ms-Icon ms-Icon--TextDocument',
        style: {
            color: '#000000',
            'font-weight': '300',
            width: '20px',
        },
    },
    ods: {
        class: 'ms-Icon ms-Icon--ExcelDocument',
        style: {
            color: '#00A34F',
            'font-weight': '300',
            width: '20px',
        },
    },
    odp: {
        class: 'ms-Icon ms-Icon--PowerPointDocument',
        style: {
            color: '#D04423',
            'font-weight': '300',
            width: '20px',
        },
    },
    odg: {
        class: 'ms-Icon ms-Icon--TextDocument',
        color: '#E0CFF3',
        'font-weight': '300',
        width: '20px',
    },
    odc: {
        class: 'ms-Icon ms-Icon--TextDocument',
        color: '#E0CFF3',
        'font-weight': '300',
        width: '20px',
    },
    odf: {
        class: 'ms-Icon ms-Icon--TextDocument',
        color: '#E0CFF3',
        'font-weight': '300',
        width: '20px',
    },
    xll: {
        class: 'ms-Icon ms-Icon--ExcelDocument',
        style: {
            color: '#00A34F',
            'font-weight': '300',
            width: '20px',
        },
    },
    xlw: {
        class: 'ms-Icon ms-Icon--ExcelDocument',
        style: {
            color: '#00A34F',
            'font-weight': '300',
            width: '20px',
        },
    },
    pdf: {
        class: 'ms-Icon ms-Icon--PDF',
        style: {
            color: '#f40f02',
            'font-weight': '300',
            width: '20px',
        },
    },
    doc: {
        class: 'ms-Icon ms-Icon--WordDocument',
        style: {
            color: '#285396',
            'font-weight': '300',
            width: '20px',
        },
    },
    docx: {
        class: 'ms-Icon ms-Icon--WordDocument',
        style: {
            color: '#285396',
            'font-weight': '300',
            width: '20px',
        },
    },
    docm: {
        class: 'ms-Icon ms-Icon--WordDocument',
        style: {
            color: '#285396',
            'font-weight': '300',
            width: '20px',
        },
    },
    dot: {
        class: 'ms-Icon ms-Icon--WordDocument',
        style: {
            color: '#285396',
            'font-weight': '300',
            width: '20px',
        },
    },
    dotx: {
        class: 'ms-Icon ms-Icon--WordDocument',
        style: {
            color: '#285396',
            'font-weight': '300',
            width: '20px',
        },
    },
    dotm: {
        class: 'ms-Icon ms-Icon--WordDocument',
        style: {
            color: '#285396',
            'font-weight': '300',
            width: '20px',
        },
    },
    docb: {
        class: 'ms-Icon ms-Icon--WordDocument',
        style: {
            color: '#285396',
            'font-weight': '300',
            width: '20px',
        },
    },
    gif: {
        class: 'ms-Icon ms-Icon--FileImage',
        style: {
            color: '#1D93E7',
            'font-weight': '300',
            width: '20px',
        },
    },
    jpg: {
        class: 'ms-Icon ms-Icon--FileImage',
        style: {
            color: '#1D93E7',
            'font-weight': '300',
            width: '20px',
        },
    },
    jpeg: {
        class: 'ms-Icon ms-Icon--FileImage',
        style: {
            color: '#1D93E7',
            'font-weight': '300',
            width: '20px',
        },
    },
    png: {
        class: 'ms-Icon ms-Icon--FileImage',
        style: {
            color: '#1D93E7',
            'font-weight': '300',
            width: '20px',
        },
    },
    psd: {
        class: 'ms-Icon ms-Icon--FileImage',
        style: {
            color: '#1D93E7',
            'font-weight': '300',
            width: '20px',
        },
    },
    tif: {
        class: 'ms-Icon ms-Icon--FileImage',
        style: {
            color: '#1D93E7',
            'font-weight': '300',
            width: '20px',
        },
    },
    tiff: {
        class: 'ms-Icon ms-Icon--FileImage',
        style: {
            color: '#1D93E7',
            'font-weight': '300',
            width: '20px',
        },
    },
    tga: {
        class: 'ms-Icon ms-Icon--FileImage',
        style: {
            color: '#1D93E7',
            'font-weight': '300',
            width: '20px',
        },
    },
    bmp: {
        class: 'ms-Icon ms-Icon--FileImage',
        style: {
            color: '#1D93E7',
            'font-weight': '300',
            width: '20px',
        },
    },
    ico: {
        class: 'ms-Icon ms-Icon--FileImage',
        style: {
            color: '#1D93E7',
            'font-weight': '300',
            width: '20px',
        },
    },
    default: {
        class: 'ms-Icon ms-Icon--TextDocument',
        style: {
            color: '#000000',
            'font-weight': '300',
            width: '20px',
        },
    },
}
