import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useQueries, useQuery } from 'react-query'

export const useDealRoomVisits = (id: any) => {
    return useQuery(
        ['dealroom', id, 'visit'],
        async () => {
            const result = await axios.get(`${API_URL}/dealroom/visit/${id}`, {
                headers: { 'Content-Type': 'application/json' },
            })
            return result.data
        },
        {
            onError: () => {},
            onSuccess: () => {},
        }
    )
}
