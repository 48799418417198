import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import {
    Button,
    IconButton,
    InputAdornment,
    Popover,
    TextField,
    Tooltip,
} from '@mui/material'
import { useRef } from 'react'
import { useImmer } from 'use-immer'
import { Asset, AssetPermission } from '../ViewAsset'
import { AddMember } from './AddMember'

import useDeletePermission from '@Common/Queries/MutationQueries/DeletePermission'
import useUpdateAsset from '@Common/Queries/MutationQueries/UpdateAsset'
import { APi_URL } from '@Common/Types/configs/urlConfig'
import Search from '@mui/icons-material/Search'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { SelectInfo } from '../SelectInfo'
import { getPermissions } from './permissions'
import useLocalStorage from '@Common/hooks/useLocalStorage'
import useInit from '@Common/Queries/GetQueries/useInit'
import Loading from '@Common/Components/Loading'
import useAssetPermission from '@Common/Queries/GetQueries/GetAssetPermission'
interface PermissionProps {
    disabled: boolean
    asset: Asset
}

export default function Permission({ disabled, asset }: PermissionProps) {
    const permissionButton = useRef(null)
    const [permission, setPermission] = useImmer({ show: false })
    const [addMember, setAddMember] = useImmer({
        enabed: false,
    })
    const permissionData = useAssetPermission(asset.id)
    const [theme] = useLocalStorage('theme', [])
    const init = useInit()
    const tenantType: string = init?.data.tenant?.TenantConfig?.type
    const memberPermissions = getPermissions('member', tenantType)
    const permissions = getPermissions('asset', tenantType)
    const updateAsset = useUpdateAsset({
        updateFunction: (previousAsset, data) => {
            return {
                ...previousAsset,
                access: data.body.access,
            }
        },
    })
    function isSingleFullAccess(
        asset: Asset,
        assetPermission: AssetPermission[]
    ) {
        let fullPermissions = 0
        if (asset.access === 'FULL_ACCESS') {
            fullPermissions = fullPermissions + 1
        }
        const permissions = assetPermission?.reduce((acc, assetPermission) => {
            if (assetPermission.permission === 'FULL_ACCESS') {
                return acc + 1
            }
            return acc
        }, fullPermissions)
        return permissions === 1
    }
    const disableFullAccess = isSingleFullAccess(asset, permissionData.data)
    const deletePermission = useDeletePermission()
    const updateAssetPermission = useUpdateAssetPermission()
    return (
        <>
            <Tooltip title="Member access">
                <IconButton
                    onClick={() => {
                        setPermission((draft) => {
                            draft.show = true
                        })
                    }}
                    disabled={disabled}
                    ref={permissionButton}
                    size="small"
                >
                    <PersonOutlineOutlinedIcon fontSize="small"></PersonOutlineOutlinedIcon>
                </IconButton>
            </Tooltip>
            <Popover
                open={permission.show}
                anchorEl={permissionButton.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClose={() => {
                    setPermission((draft) => {
                        draft.show = false
                    })
                }}
            >
                {permissionData.isLoading ? (
                    <Loading></Loading>
                ) : (
                    <div className="p-6 w-96 flex flex-col gap-5">
                        <div className="text-gray-400 font-medium ">
                            Access & Permission
                        </div>
                        {!addMember.enabed && (
                            <>
                                <div className="flex items-center justify-between">
                                    <TextField
                                        size="small"
                                        onClick={() => {
                                            setAddMember((draft) => {
                                                draft.enabed = true
                                            })
                                        }}
                                        className="text-sm w-64"
                                        placeholder="Add members"
                                        variant='outlined'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Search />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <Button variant="contained">Invite</Button>
                                </div>

                                <div className="flex flex-col gap-2">
                                    <div
                                        className={`flex justify-between ${
                                            disableFullAccess &&
                                            asset.access === 'FULL_ACCESS'
                                                ? 'text-gray-300'
                                                : ''
                                        }`}
                                    >
                                        <div className="flex items-center">
                                            Everyone
                                        </div>
                                        <SelectInfo
                                            className="w-28"
                                            disabled={
                                                disableFullAccess &&
                                                asset.access === 'FULL_ACCESS'
                                            }
                                            value={asset.access}
                                            onChange={(value) => {
                                                updateAsset.mutate({
                                                    body: {
                                                        access: value.target
                                                            .value,
                                                    },
                                                    id: asset.id,
                                                })
                                            }}
                                            items={permissions}
                                        ></SelectInfo>
                                    </div>
                                    {permissionData?.data?.map(
                                        (permission, id: number) => {
                                            const isDisabled =
                                                disableFullAccess &&
                                                permission.permission ===
                                                    'FULL_ACCESS'
                                            return (
                                                <div
                                                    className={`flex justify-between ${
                                                        isDisabled
                                                            ? 'text-gray-300 cursor-not-allowed'
                                                            : ''
                                                    }`}
                                                    key={id}
                                                >
                                                    <div className="flex items-center">
                                                        {permission.AssetUserPermission
                                                            ? permission
                                                                  .AssetUserPermission
                                                                  .User.name
                                                            : permission
                                                                  .AssetGroupPermission
                                                                  .group.name}
                                                    </div>
                                                    <SelectInfo
                                                        className="w-28 "
                                                        disabled={isDisabled}
                                                        value={
                                                            permission.permission
                                                        }
                                                        onChange={(e) => {
                                                            if (
                                                                e.target
                                                                    .value ===
                                                                'REMOVE'
                                                            ) {
                                                                deletePermission.mutate(
                                                                    {
                                                                        assetId:
                                                                            asset.id,
                                                                        assetPermissionId:
                                                                            permission.id,
                                                                    }
                                                                )
                                                                return
                                                            }
                                                            updateAssetPermission.mutate(
                                                                {
                                                                    assetId:
                                                                        asset.id,
                                                                    permissionId:
                                                                        permission.id,
                                                                    body: {
                                                                        permission:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    },
                                                                }
                                                            )
                                                        }}
                                                        items={
                                                            memberPermissions
                                                        }
                                                    ></SelectInfo>
                                                </div>
                                            )
                                        }
                                    )}
                                </div>
                            </>
                        )}
                        {addMember.enabed && (
                            <AddMember
                                asset={asset}
                                onClose={() => {
                                    setAddMember((draft) => {
                                        draft.enabed = false
                                    })
                                }}
                            ></AddMember>
                        )}{' '}
                    </div>
                )}
            </Popover>
        </>
    )
}

interface UpdateAssetPermission {
    assetId: string
    permissionId: string
    body: {
        permission: string
    }
}
export function useUpdateAssetPermission(props?: any) {
    const queryClient = useQueryClient()
    return useMutation<any, any, UpdateAssetPermission>(
        async (obj) =>
            await axios.post(
                `${APi_URL}/assets/${obj.assetId}/permission/${obj.permissionId}`,
                obj.body,
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            ),
        {
            onSuccess: async (data: any, obj) => {
                if (props && props.onSuccess) props.onSuccess(data, obj)
            },
            onMutate: async (obj) => {
                queryClient.cancelQueries([
                    'assets',
                    obj.assetId,
                    'permissions',
                ])
                queryClient.setQueryData(
                    ['assets', obj.assetId, 'permissions'],
                    (oldData: Array<AssetPermission>) => {
                        const permissionIndex = oldData.findIndex(
                            (permission) => {
                                return permission.id === obj.permissionId
                            }
                        )
                        const permission = oldData[permissionIndex]
                        permission.permission = obj.body.permission
                        oldData[permissionIndex] = permission
                        return oldData
                    }
                )
            },
            onError: (data: any, obj: any, context: any) => {
                if (props.onError) props.onError(data, obj)
            },
            onSettled: async (data: any, error, obj) => {
                await queryClient.invalidateQueries([
                    'assets',
                    obj.assetId,
                    'permissions',
                ])
            },
        }
    )
}
