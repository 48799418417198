import { useQuery, useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'
import { updateSearchWithCustomAttributeRemoval } from '@Search/utils/searchTableUpdater'
export interface RemoveCustomAttributeFromAsset {
    id: string
    assetId: string
    attribute: {
        id: string
        name: string
        type: string
    }
    attributeValue?: {
        id: string
        value: string
    }
    value?: string
}
export default function useRemoveCustomAttFromAsset(props?: {
    removeCustomAttributeUpdaterFunction?: any
    onError?: any
    onSuccess?: any
}) {
    const queryClient = useQueryClient()
    return useMutation<any, Error, RemoveCustomAttributeFromAsset>(
        (req: RemoveCustomAttributeFromAsset) =>
            axios.delete(`${APi_URL}/asset/customAttributes/${req.id}`, {
                headers: { 'Content-Type': 'application/json' },
            }),
        {
            onSuccess: (data: RemoveCustomAttributeFromAsset, obj: any) => {
                if (props?.onSuccess) props.onSuccess(data, obj)
            },
            onMutate: async (data: RemoveCustomAttributeFromAsset) => {
                if (!props.removeCustomAttributeUpdaterFunction) {
                    return
                }
                //cancelling the updates of asset
                await queryClient.cancelQueries([
                    'assets',
                    data.assetId,
                    'details',
                ])
                //previous asset value
                const previousAsset: {
                    AssetCustomAttribute: Array<{
                        id: string
                        name: string
                        assetAttributeValues: Array<Record<string, unknown>>
                    }>
                } = queryClient.getQueryData([
                    'assets',
                    data.assetId,
                    'details',
                ])
                if (
                    data.attribute.type === 'Single-select' ||
                    data.attribute.type === 'Multi-select' ||
                    data.attribute.type === 'Tag'
                ) {
                    queryClient.setQueryData(
                        ['assets', data.assetId, 'details'],
                        (old: any) => {
                            const assetCustomAttribute =
                                props.removeCustomAttributeUpdaterFunction(
                                    old,
                                    data
                                )
                            return {
                                ...old,
                                AssetCustomAttribute: assetCustomAttribute,
                            }
                        }
                    )
                } else if (data.attribute.type === 'Date') {
                    queryClient.setQueryData(
                        ['assets', data.assetId, 'details'],
                        (old: any) => {
                            const assetCustomAttribute =
                                props.removeCustomAttributeUpdaterFunction(
                                    old,
                                    data
                                )
                            return {
                                ...old,
                                UserInputCustomAttributes: assetCustomAttribute,
                            }
                        }
                    )
                }
                queryClient.setQueriesData(['search'], (old) => {
                    const prevData = { ...old }
                    return updateSearchWithCustomAttributeRemoval(
                        prevData,
                        data
                    )
                })
                return { previousAsset }
            },
            onError: (err: any, data: any, context: any) => {
                queryClient.setQueryData(
                    ['assets', data.assetId, 'details'],
                    context.previousAsset
                )

                if (props.onError) props.onError(err, data)
            },
            onSettled: (data: any) => {
                queryClient.invalidateQueries([
                    'assets',
                    data.data.assetId,
                    'details',
                ])
                queryClient.invalidateQueries(['search'])
            },
        }
    )
}
