import React, { useState } from 'react'
import ThirdPartyForm from './ThirdPartyForm'

const Integration: React.FC = () => {
    const [newAttr, setAttr] = useState(false)
    const [showForm, setShowForm] = useState(false)
    return (
        <div className="w-full h-full flex flex-col">
            <button className="py-1 px-3 border rounded bg-approveBtn w-44" onClick={() => {
                setAttr(true)
                setShowForm(true)
            }}>
                <span className="text-sm text-white">Add a new integration</span>
            </button>
            <div className="pt-5 ">
                <span className="text-base">Configured Integrations</span>
                <div className="w-8 h-1 bg-blue-600 mt-1"></div>
            </div>
            <ThirdPartyForm newAttributeCreation={newAttr} setAttr={setAttr} showForm={showForm} setShowForm={setShowForm} />
        </div>

    )
}

export default Integration

const style = {
    mainContainer: " h-full flex flex-col overflow-hidden bg-bgColor px-4 pt-4 rounded-md font-Segoe",
    display: " h-full flex flex-col overflow-hidden rounded-md "

}


