import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { APi_URL } from "@Common/Types/configs/urlConfig";


export default function useAddUsersToGroups(props: any) {
    const queryClient = useQueryClient()
    return useMutation<any, Error, object>((obj: any) => axios.post(
        `${APi_URL}/groups/user`,
        obj,
        { headers: { 'Content-Type': 'application/json' } }),
        {
            onSuccess: (data: any, obj: any) => {
                props.onSuccess(data)
                queryClient.invalidateQueries("Groups")
            },
        });
}