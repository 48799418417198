import { Button, Menu, MenuItem } from '@mui/material'
import { useImmer } from 'use-immer'
import { KeyboardArrowDownOutlined } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import DriveSvg from '../Icons/google-drive.svg'
import { useDriveStore } from 'src/Stores/DriveStore'
import React from 'react'
import useDrivePicker from 'src/google-picker'
import { mimeTypeMap } from './AllowedFileTypes'
import useDriveUploadDetails from '@Common/Queries/GetQueries/GetDriveUploadDetails'
import Loading from '@Common/Components/Loading'

export default function DriveUpload(props?: any) {
    const pickerParam = props?.pickerParam
    const navigate = useNavigate()
    const [openPicker, authResponse] = useDrivePicker()
    const [open, setOpen] = useImmer(false)

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const addFileData = useDriveStore((state) => state.addFileData)
    const setAccessToken = useDriveStore((state) => state.setAccessToken)
    const reset = useDriveStore((state) => state.reset)

    const CLIENT_ID = import.meta.env.VITE_PICKER_CLIENT_ID
    const API_KEY = import.meta.env.VITE_PICKER_API_KEY

    // const SCOPES = 'https://www.googleapis.com/auth/drive.file' // same scope used in open picker

    let access_token = authResponse?.access_token
    setAccessToken(access_token)
    const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
        setOpen(true)
    }

    const addExtension = (fileName: string, mimetype: string) => {
        const fileWithExtension = fileName + '.' + mimeTypeMap.get(mimetype)
        return fileWithExtension
    }
    const getFileName = (fileName: string, mimeType: string) => {
        const extension = fileName.split('.').pop()
        if (!mimeTypeMap.has(mimeType)) {
            return fileName
        }
        if (!fileName.includes('.')) {
            return addExtension(fileName, mimeType)
        }
        if (extension != mimeTypeMap.get(mimeType)) {
            return addExtension(fileName, mimeType)
        }
        return fileName
    }
    const handleAuthClick = () => {
        reset()

        openPicker({
            clientId: CLIENT_ID,
            developerKey: API_KEY,
            multiselect: true,
            viewMimeTypes:
                'image/png,image/jpeg,image/svg+xml,application/pdf,application/rtf,image/tiff,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/csv,text/plain,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,video/x-msvideo,audio/mpegvideo/mp4,video/mpeg,application/pdf',

            count: pickerParam?.maxUpload,
            setOrigin: pickerParam.origin,

            callbackFunction: (data) => {
                if (data.action === google.picker.Action.PICKED) {
                    const document = data[google.picker.Response.DOCUMENTS]
                    document.map((value) => {
                        addFileData({
                            id: value.id,
                            name: getFileName(value.name, value.mimeType),
                            mimeType: value.mimeType,
                            size: value.sizeBytes,
                        })
                    })

                    if (pickerParam.location === 'plugin') {
                        navigate('/plugin/drive/upload')
                    } else {
                        navigate('/drive/uploads')
                    }
                }
            },
        })
    }

    return (
        <>
            <Button
                size="small"
                onClick={onClick}
                data-testid="searchpage-uploaddrive-btn"
            >
                <KeyboardArrowDownOutlined fontSize="small" />
            </Button>
            <Menu
                onClick={() => {
                    setOpen(false)
                }}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
            >
                <MenuItem
                    onClick={() => handleAuthClick()}
                    data-testid="searchpage-googledriveupload-menu"
                >
                    <img src={DriveSvg} className="pr-1"></img>Upload from
                    Google Drive
                </MenuItem>
            </Menu>
        </>
    )
}
