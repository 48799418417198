import Loading from '@Common/Components/Loading'
import useAddTrackLink from '@Common/Queries/MutationQueries/CreateTrackLink'
import useFeatureFlag from '@Common/hooks/useFeatureFlag'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import Button from '@mui/material/Button'
import { toast } from 'react-hot-toast'
import { Updater, useImmer } from 'use-immer'
import TrackProperties, { TrackLinkProperties } from './TrackProperties'
import { FeatureContext } from '@Common/Components/Feature/FeatureProvider'
import { useContext } from 'react'

interface TrackCreateProps {
    assetId: string
    trackData?: {
        id: string
        code: string
        enabled: boolean
    }
    setConfirmation: Updater<{
        open: boolean
        code: string
    }>
}
export const emailRegex = /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/

const TrackCreate = (props: TrackCreateProps) => {
    const { assetId } = props

    const [showAdvancedControls, setShowAdvancedControls] = useImmer(false)

    const [trackLinkProps, setTrackLinkProps] = useImmer<TrackLinkProperties>({
        name: '',
        contacts: [],
        expires: false,
        validTill: null,
        allowDownload: false,
        emailRequired: false,
        passcodeRequired: false,
        passcode: '',
        emailAuthRequired: false,
        restrictAccess: false,
        accessAllow: false,
        accessBlock: false,
        allowEmail: [],
        blockEmail: [],
    })
    const sendDataToGTM = useGTMDispatch()
    const contacts = useFeatureFlag('contacts')
    const addTrackLink = useAddTrackLink({
        onSuccess: (data) => {
            props.setConfirmation({
                open: true,
                code: data.data.track.code,
            })
            sendDataToGTM({
                event: 'track_asset_link_create',
                asset_id: assetId,
            })
        },
    })

    const createOrUpdateTrackLink = () => {
        if (trackLinkProps.name.length === 0) {
            toast.error('Name cannot be empty')
        } else if (
            trackLinkProps.expires === true &&
            trackLinkProps.validTill === null
        ) {
            // this will make the text field in red which the user will know is error
            setTrackLinkProps((draft) => {
                draft.validTill = ''
            })
        } else if (
            trackLinkProps.passcodeRequired &&
            (!trackLinkProps.passcode ||
                trackLinkProps.passcode.trim().length <= 0)
        ) {
            toast.error('Passcode cannot be empty')
        } else if (
            trackLinkProps.accessAllow &&
            trackLinkProps.allowEmail.length <= 0
        ) {
            toast.error('Allow email cannot be empty')
        } else if (
            trackLinkProps.accessBlock &&
            trackLinkProps.blockEmail.length <= 0
        ) {
            toast.error('Block email cannot be empty')
        } else if (
            contacts &&
            (!trackLinkProps.contacts || trackLinkProps.contacts.length === 0)
        ) {
            toast.error('Contact is required')
        } else {
            const trackLinkProperties = { ...trackLinkProps }
            delete trackLinkProperties?.accessAllow
            delete trackLinkProperties?.accessBlock

            addTrackLink.mutate({
                id: props.assetId,
                enabled: true,
                type: 'ASSET',
                trackLinkProperties,
            })
        }
    }

    return (
        <>
            <div className="h-full w-full flex flex-col justify-between">
                <TrackProperties
                    trackLinkProps={{
                        trackLinkProps: trackLinkProps,
                        setTrackLinkProps: setTrackLinkProps,
                        showAdvancedControls: showAdvancedControls,
                        setShowAdvancedControls: setShowAdvancedControls,
                    }}
                ></TrackProperties>
                <div className="gap-4 flex justify-end w-full p-2 border-t-2 border-gray-50">
                    <Button
                        variant="contained"
                        className="bg-primaryButton"
                        onClick={() => createOrUpdateTrackLink()}
                        disabled={addTrackLink.isLoading}
                    >
                        <div className="pr-2 pt-1">
                            {addTrackLink.isLoading && <Loading size="1rem" />}
                        </div>
                        Create Link
                    </Button>
                </div>
            </div>
        </>
    )
}
export default TrackCreate
