import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import prettyMilliseconds from 'pretty-ms'
import { formatDateWithTime } from 'src/ViewAssetScreen/util'
import { getVisitorName, displayAvatarBasedOnName } from 'src/BuyerInsightsUtil'

export interface PageDetailsProps {
    updatedAt: string | null
    downloaded: boolean
    totalPagesViewed: number
    visitDuration: number
    assetId: null
    TrackSession: {
        AssetTrackSession: {
            buyerDetails: {
                ipData: object
                isMobile: boolean
                browserName: string
                operatingSystem: string

            }
            email: string | null
        }
    }
    TrackVisitPage: Array<{
        pageNumber: number
        viewTime: number
    }>
    TrackLink: {
        totalPages: 0
    }
}
export const PageLevelDetails = (props: {
    data: PageDetailsProps[]
    totalPages?: number
}) => {
    const stats = props.data.reduce(
        function (accumulator, item) {
            accumulator['totalViewTime'] =
                (accumulator['totalViewTime'] || 0) + item.visitDuration
            accumulator['downloaded'] =
                (accumulator['downloaded'] || 0) + (item.downloaded ? 1 : 0)

            return accumulator
        },
        {
            totalViewTime: 0,
            downloaded: 0,
        }
    )


    return (
        <div className="h-full w-full">
            {stats?.totalViewTime === 0 ? (
                <div className="my-auto">
                    Recipient has not opened the file yet.
                </div>
            ) : (
                props.data.map((visit, index) => {
                    const buyerDetails = visit.TrackSession.AssetTrackSession?.buyerDetails;
                    const email = visit.TrackSession.AssetTrackSession.email;
                    return (
                        <div
                            key={index}
                            className="py-2 max-w-96 max-h-[418px] flex flex-col overflow-hidden"
                        >
                            <div className="py-2 flex justify-between">
                                <div className="flex ">
                                    {displayAvatarBasedOnName(getVisitorName(email, buyerDetails),"page-detail-avatar")}
                                    <div className="flex flex-col pl-2">
                                        <Typography>
                                            {getVisitorName(email, buyerDetails)}
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            color={'text.disabled'}
                                        >
                                            {`Last Engagement: ${formatDateWithTime(
                                                visit.updatedAt
                                            )}`}
                                        </Typography>
                                        <div className='flex flex-row justify-start'>
                                            {
                                                buyerDetails?.ipData?.city && buyerDetails?.ipData?.country_name && (
                                                    <Typography variant="caption" color={'text.disabled'}>{`${buyerDetails?.ipData?.city}, ${buyerDetails?.ipData?.country_name}`}</Typography>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Divider></Divider>
                            <div className="w-full grid grid-cols-3 justify-between py-4">
                                <div className="flex flex-col gap-2">
                                    <Typography className="text-center">
                                        {visit.downloaded ? '01' : '-'}
                                    </Typography>
                                    <Typography
                                        className="text-xs text-center"
                                        color="text.disabled"
                                    >
                                        Download status
                                    </Typography>
                                </div>
                                <div className="flex flex-col gap-2">
                                    <Typography className="text-center">
                                        {
                                            visit.TrackVisitPage.filter(
                                                (page) => page.viewTime > 0
                                            ).length
                                        }
                                        /
                                        {visit.TrackLink
                                            ? visit.TrackLink.totalPages
                                            : props.totalPages}
                                    </Typography>
                                    <Typography
                                        className="text-xs text-center"
                                        color="text.disabled"
                                    >
                                        Page Viewed
                                    </Typography>
                                </div>
                                <div className="flex flex-col gap-2">
                                    <Typography className="text-center">
                                        {prettyMilliseconds(visit.visitDuration, {
                                            secondsDecimalDigits: 0,
                                        })}
                                    </Typography>
                                    <Typography
                                        className="text-xs text-center"
                                        color="text.disabled"
                                    >
                                        View Time
                                    </Typography>
                                </div>
                            </div>
                            <Divider></Divider>
                            <div className="flex-1 flex-col overflow-auto">
                                <div className="py-4 grid grid-cols-7">
                                    <Typography
                                        className="text-xs"
                                        color="text.disabled"
                                    >
                                        Pages
                                    </Typography>
                                    <Typography
                                        className="w-full text-xs col-span-5 text-center"
                                        color="text.disabled"
                                    >
                                        View Time
                                    </Typography>
                                </div>
                                {visit.TrackVisitPage.map(
                                    (page, id) =>
                                        page.viewTime != 0 && (
                                            <div
                                                key={id}
                                                className="flex p-2 gap-x-4"
                                            >
                                                <div className="w-9">
                                                    {page.pageNumber.toLocaleString(
                                                        'en-US',
                                                        {
                                                            minimumIntegerDigits: 2,
                                                            useGrouping: false,
                                                        }
                                                    )}
                                                </div>
                                                <div className="px-4 w-full flex-1">
                                                    <div className="bg-searchBarColor mx-auto">
                                                        <div
                                                            data-tip={`${(
                                                                (page.viewTime /
                                                                    visit.visitDuration) *
                                                                100
                                                            ).toFixed(2)}%`}
                                                            data-delay-show={'500'}
                                                            style={{
                                                                width: `${(
                                                                    (page.viewTime /
                                                                        visit.visitDuration) *
                                                                    100
                                                                ).toFixed(2)}%`,
                                                            }}
                                                            className="bg-primaryButton h-5"
                                                        ></div>
                                                    </div>
                                                </div>
                                                <Typography className="w-16">
                                                    {prettyMilliseconds(
                                                        page.viewTime,
                                                        {
                                                            secondsDecimalDigits: 0,
                                                        }
                                                    )}
                                                </Typography>
                                            </div>
                                        )
                                )}
                            </div>
                            <Divider className="py-2"></Divider>
                        </div>
                    )
                })
            )}
        </div>
    )
}
