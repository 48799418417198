import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useQuery } from 'react-query'

export const useSearchForDealRoom = (props) => {
    return useQuery(
        ['dealroom', 'search', props],
        async () => {
            const result = await axios.post(
                `${API_URL}/dealroom/search`,
                props,
                {
                    headers: {
                        'Content-type': 'application/json',
                    },
                }
            )
            return result.data
        },
        {
            keepPreviousData: true,
        }
    )
}
