import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { toast } from 'react-hot-toast'
import { useMutation, useQuery, useQueryClient } from 'react-query'

export const useAddDealroomPermission = (
    props?: { onSuccess: any } | undefined
) => {
    const queryClient = useQueryClient()
    return useMutation(
        async (data) =>
            await axios.post(
                `${API_URL}/dealroom/${data.id}/permission`,
                data.body,
                {
                    headers: {
                        'Content-type': 'application/json',
                    },
                }
            ),
        {
            onSuccess: (data) => {
                if (props && props.onSuccess) props.onSuccess(data)
                queryClient.invalidateQueries(['dealroom', data.data.id])
            },
            onMutate: (data) => {},
            onError: (data) => {
                toast.error('Error in adding permission')
            },
        }
    )
}
