import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import { Updater } from 'use-immer'

interface DialogComponentProps {
    open: boolean
    setOpen: Updater<boolean>
    title?: string
    body: any
    handleChange?: any
    cancel?: {
        text?: string
        color?: any
        variant?: any
    }
    success?: {
        text?: string
        color?: any
        variant?: any
    }

    isLoading?: boolean
}
const DialogComponent = (props: DialogComponentProps) => {
    const {
        open,
        setOpen,
        title,
        body,
        handleChange,
        cancel,
        success,
        isLoading,
    } = props
    const handleCancel = () => {
        setOpen(false)
    }
    return (
        <Dialog open={open}>
            {title && <DialogTitle>{title}</DialogTitle>}
            <DialogContent>{body}</DialogContent>
            <DialogActions>
                <Button
                    color={cancel?.color || 'inherit'}
                    onClick={handleCancel}
                    variant={cancel?.variant || 'outlined'}
                >
                    {cancel?.text || 'Cancel'}
                </Button>
                {success && (
                    <Button
                        onClick={handleChange}
                        color={success.color || 'primary'}
                        variant={success?.variant || 'contained'}
                    >
                        {success.text || 'Ok'}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}

export default DialogComponent
