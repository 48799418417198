import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'

export const useAddDealRoomTrackEmail = (id: string) => {
    const queryClient = useQueryClient()
    return useMutation(
        (data: { email: string }) =>
            axios.post(`${API_URL}/dealroom/${id}/trackPermission`, data, {
                headers: {
                    'Content-type': 'application/json',
                },
            }),
        {
            onMutate: (data) => {
                queryClient.cancelQueries(['dealroom', id, 'track', 'email'])
                const email: Array<{
                    permission: string
                    DealRoomGuestEmailPermission: {
                        email: string
                    }
                }> = queryClient.getQueryData([
                    'dealroom',
                    id,
                    'track',
                    'email',
                ])

                email.push({
                    permission: 'VIEW',
                    DealRoomGuestEmailPermission: {
                        email: data.email,
                    },
                })
                queryClient.setQueryData(
                    ['dealroom', id, 'track', 'email'],
                    email
                )
            },
            onSuccess: () => {
                queryClient.invalidateQueries([
                    'dealroom',
                    id,
                    'track',
                    'email',
                ])
            },
        }
    )
}
