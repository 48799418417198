import { useQuery, useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'

export default function useDeleteUserTableConfiguration(props: any) {
    const queryClient = useQueryClient()
    return useMutation<any, Error, object>(
        () => axios.delete(`${APi_URL}/user/table-configuration`),
        {
            onSuccess: (data: any, obj: any) => {
                queryClient.invalidateQueries(['tableConfiguration'])
                props.onSuccess(data)
            },
            onError: props.onError,
        }
    )
}
