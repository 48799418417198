import useUsers from '@Common/Queries/GetQueries/GetUsers'
import useDeleteUserFromGroup from '@Common/Queries/MutationQueries/DeleteUserFromGroup'
import useAddUsersToGroups from '@Common/Queries/MutationQueries/AddUserToGroup'
import useCreateGroups from '@Common/Queries/MutationQueries/CreateGroups'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import Select from 'react-select'
import { useImmer } from 'use-immer'
import ValidationModal from '../ValidationModal'
import ErrorModal from './ErrorModal'
import useUpdateGroup from '@Common/Queries/MutationQueries/UpdateGroup'
import PrimaryButton from '../Button/PrimaryButton'
import OutlineSecondaryButton from '../Button/OutlineSecondary'
import toast from 'react-hot-toast'
import { Button } from '@mui/material'

interface ManageGroups {
    isOpen: boolean
    data: object
    type: string
}
interface IProps {
    setManageGroup: Function
    manageGroup: ManageGroups
}

const CreateGroupModal: React.FC<IProps> = ({
    setManageGroup,
    manageGroup,
}) => {
    const [inputData, setInputData] = useImmer({
        name: manageGroup.data?.name || '',
        id: manageGroup.data?.id,
        description: manageGroup.data?.description || '',
        users:
            manageGroup?.data?.GroupUsers?.map((users: any) => ({
                value: users?.user?.id,
                label: users?.user?.name,
                name: users?.user?.name,
            })) || [],
        isOpen: false,
        message: '',
        type: 'alert',
    })

    const [users, setUsers] = useImmer({
        data: [] as string[],
    })
    const [isOpen, setIsOpen] = useState(false)
    const [message, setMessage] = useState('')

    const onCreateGroupsSuccess = (data: any) => {
        const body = {
            groupId: data.data.id,
            userIds: users.data,
        }
        addUserToGroups.mutate(body)
        toast.success('Group created successfully')
        manageGroup.isOpen = false
        setManageGroup({ ...manageGroup })
    }
    const onUpdateGroupsSuccess = (data: any) => {
        if (inputData.users && inputData.users.length != 0) {
            const body = {
                groupId: data.data.id,
                userIds: users.data,
            }
            addUserToGroups.mutate(body)
            manageGroup.isOpen = false
            setManageGroup({ ...manageGroup })
        }
    }

    const onAddedUsersSuccess = () => {}
    const onDeleteUserSuccess = () => {}

    const onCreateGroupsError = () => {
        setIsOpen(true)
        toast.error('Something went wrong')
    }

    const addUserToGroups = useAddUsersToGroups({
        onSuccess: onAddedUsersSuccess,
    })
    const deleteUserFromGroups = useDeleteUserFromGroup({
        onSuccess: onDeleteUserSuccess,
    })
    const createGroup = useCreateGroups({
        onSuccess: onCreateGroupsSuccess,
        onError: onCreateGroupsError,
    })
    const updateGroup = useUpdateGroup({ onSuccess: onUpdateGroupsSuccess })

    const { data: usersData, isLoading: isAssetTypeLoading } = useUsers()

    const updateGroupData = () => {
        if (inputData.name === '') {
            setInputData((draft) => {
                return {
                    message: 'Name field cannot be empty',
                    isOpen: true,
                }
            })
        } else if (inputData.description === '') {
            setInputData((draft) => {
                return {
                    message: 'Description field cannot be empty',
                    isOpen: true,
                }
            })
        } else {
            const data = {
                groupId: manageGroup.data.id,
                body: {
                    name: inputData.name,
                    description: inputData.description,
                },
            }
            updateGroup.mutate(data)
        }
    }
    const addGroups = () => {
        if (inputData.name === '') {
            setInputData((draft) => {
                return {
                    message: 'Name field cannot be empty',
                    isOpen: true,
                }
            })
        } else if (inputData.description === '') {
            setInputData((draft) => {
                return {
                    message: 'Description field cannot be empty',
                    isOpen: true,
                }
            })
        } else {
            const body = {
                name: inputData.name,
                description: inputData.description,
            }
            createGroup.mutate(body)
        }
    }
    console.log(usersData?.data)

    const userAdditionToGroups = (newValue: any, actionMeta: any) => {
        if (actionMeta.action === 'select-option') {
            console.log('selected--', actionMeta.option)
            setUsers((draft) => {
                draft.data.push(actionMeta.option.value)
            })
            const user = {
                value: actionMeta.option.value,
                name: actionMeta.option.name,
                label: actionMeta.option.label,
            }
            setInputData((draft) => {
                draft.users.push(user)
            })
        } else if (actionMeta.action === 'remove-value') {
            const body = {
                groupId: manageGroup.data.id,
                userId: actionMeta.removedValue.value,
            }
            setUsers((draft) => {
                const assetIndex = draft.data.findIndex(
                    (assetId) => assetId === actionMeta.removedValue.value
                )
                draft.data.splice(assetIndex, 1)
            })
            setInputData((draft) => {
                const userIndex = draft.users.findIndex(
                    (user) => user.value === actionMeta.removedValue.value
                )
                draft.users.splice(userIndex, 1)
            })
            deleteUserFromGroups.mutate(body)
        }
    }

    const colourStyles = {
        menuList: (styles: any, { isDisabled }: any) => {
            return {
                ...styles,
                height: '105px',
            }
        },
    }

    const groupCURD = () => {
        if (manageGroup.type === 'create') {
            return (
                <>
                    <span className="font-Segoe text-xs font-light text-searchFilterColor">
                        <i>
                            Groups are a collection of users. Adding a group to
                            an asset gives the group users permissions to
                            download the asset.
                        </i>{' '}
                    </span>
                    <div className="flex mt-5 w-full">
                        <span className="text-groupLabel  font-Segoe w-1/3">
                            Group Name
                        </span>
                        <input
                            onChange={(e) => {
                                setInputData((draft) => {
                                    draft.name = e.target.value
                                })
                            }}
                            className="border border-searchFilterColor rounded-sm pl-1 py-1 w-2/3 "
                        />
                    </div>

                    <div className="flex mt-5 w-full">
                        <span className="text-groupLabel  font-Segoe w-1/3">
                            Group Description
                        </span>
                        <textarea
                            onChange={(e) => {
                                setInputData((draft) => {
                                    draft.description = e.target.value
                                })
                            }}
                            className="border border-searchFilterColor rounded-sm p-1 resize-none w-2/3"
                        />
                    </div>

                    <div className="flex mt-5 w-full">
                        <span className="text-groupLabel  font-Segoe whitespace-nowrap w-1/3">
                            Group Users
                        </span>
                        <Select
                            isMulti
                            isClearable={false}
                            value={inputData.users}
                            onChange={userAdditionToGroups}
                            name="permissionGroup"
                            options={usersData?.option}
                            styles={colourStyles}
                            placeholder="Search and add Collaborators ..."
                            className="basic-multi-select bg-white rounded-md focus:outline-none focus:ring-gray-200 focus:border-gray-500 sm:text-sm w-2/3"
                        />
                    </div>

                    <div className={style.btnContainer}>
                        <Button variant="contained" onClick={() => addGroups()}>
                            Save
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                manageGroup.isOpen = false
                                setManageGroup({ ...manageGroup })
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <span className="font-Segoe text-xs font-light text-searchFilterColor">
                        <i>
                            Groups are a collection of users. Adding a group to
                            an asset gives the group users permissions to
                            download the asset.
                        </i>{' '}
                    </span>
                    <div className="flex w-full mt-5">
                        <span className="text-groupLabel  font-Segoe w-1/3">
                            Group Name
                        </span>
                        <input
                            value={inputData.name}
                            onChange={(e) => {
                                setInputData((draft) => {
                                    draft.name = e.target.value
                                })
                            }}
                            className="border border-searchFilterColor rounded-sm pl-1 py-1 w-2/3 "
                        />
                    </div>

                    <div className="flex  w-full mt-5">
                        <span className="text-groupLabel  font-Segoe w-1/3">
                            Group Description
                        </span>
                        <textarea
                            value={inputData.description}
                            onChange={(e) => {
                                setInputData((draft) => {
                                    draft.description = e.target.value
                                })
                            }}
                            className="border border-searchFilterColor rounded-sm p-1 resize-none w-2/3"
                        />
                    </div>

                    <div className="flex w-full mt-5">
                        <span className="text-groupLabel  font-Segoe whitespace-nowrap w-1/3">
                            Group Users
                        </span>
                        <Select
                            isMulti
                            isClearable={false}
                            name="permissionGroup"
                            value={inputData.users}
                            onChange={userAdditionToGroups}
                            options={usersData?.option}
                            styles={colourStyles}
                            placeholder="Search and add Collaborators ..."
                            className="basic-multi-select bg-white rounded-md   focus:outline-none focus:ring-gray-200 focus:border-gray-500 sm:text-sm w-2/3"
                        />
                    </div>

                    <div className={style.btnContainer}>
                        <Button
                            variant="contained"
                            onClick={() => {
                                updateGroupData()
                            }}
                        >
                            Save
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                manageGroup.isOpen = false
                                setManageGroup({ ...manageGroup })
                                // setOpen(!open)
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                </>
            )
        }
    }

    return (
        <>
            {inputData.isOpen && (
                <ValidationModal
                    setConformingDeletion={setInputData}
                    conformingDeletion={inputData}
                />
            )}
            {isOpen && <ErrorModal isOpen={setIsOpen} message={message} />}
            <Transition appear show={true} as={Fragment}>
                <Dialog
                    as="div"
                    className={style.dialogContainer}
                    onClose={() => {}}
                >
                    <div className={style.subContainer} style={{}}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className={style.dialogOverlay} />
                        </Transition.Child>

                        <span className={style.span} aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className={style.dialogTitleContainer}>
                                <Dialog.Title
                                    as="h2"
                                    className={style.dialogTitle}
                                >
                                    {manageGroup.type === 'create'
                                        ? 'Create Group'
                                        : 'Edit Group'}
                                </Dialog.Title>
                                {groupCURD()}
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default CreateGroupModal

const style = {
    dialogContainer: 'fixed inset-0  overflow-y-auto bg-opacity-50 bg-gray-500',
    subContainer: 'min-h-screen px-6 text-center',
    dialogOverlay: 'fixed inset-0',
    span: 'inline-block h-screen align-middle',
    dialogTitleContainer:
        'inline-block w-full max-w-lg p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl max-h-full',
    dialogTitle: 'text-lg font-medium leading-6 text-textColor',
    closeBtn:
        'inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500',
    btnCancel:
        'py-2 px-7 rounded-sm border text-approveBtn border-activeBorderColor font-bold text-sm ml-2',
    btnRaise:
        'py-2 px-8 bg-approveBtn rounded-sm text-white text-sm font-bold ml-3',
    pTag: 'text-xs text-gray-400',
    assetName: 'text-sm text-gray-500',
    assetNm: 'text-textColor text-base mt-3',
    descriptionContainer: 'text-sm text-gray-500 mt-3',
    input: 'h-20 border-2 outline-none w-full mt-1 p-2',
    btnContainer: 'mt-8 mb-8 flex flex-row gap-4',
    select: 'basic-multi-select border-none outline-none whitespace-nowrap text-14px font-normal font-Segoe  max-w-max',
    btnApprove:
        'py-2 px-6 bg-gradient rounded-sm text-white text-sm font-bold  ',
    btnReject:
        'py-2 px-5 rounded-sm border text-primary border-gradient font-bold text-sm ml-4',
}
