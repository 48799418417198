import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useQuery } from 'react-query'

export const useDealRoom = (props: {
    id: string | undefined
    onSuccess?: any
}) => {
    return useQuery(
        ['dealroom', props.id],
        async () => (await axios.get(`${API_URL}/dealroom/${props.id}`)).data,
        {
            onSuccess: (data) => {
                if (props.onSuccess) {
                    props.onSuccess(data)
                }
            },
        }
    )
}
