import useAddUsers from '@Common/Queries/MutationQueries/AddUsers';
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import toast from 'react-hot-toast';
import Select from 'react-select'
import { useImmer } from 'use-immer';
import OutlineSecondaryButton from '../Button/OutlineSecondary';
import PrimaryButton from '../Button/PrimaryButton';


const AddUsersModal: React.FC = ({ roles, addUser, setAddUser }) => {


    const [emails, setEmails] = useImmer({
        inputValue: "",
        items: [],
        roleId: "",
        error: ""
    })
    const onSuccessAddUser = () => {
        toast.success('Users are successfully invited');
        setAddUser(false);
    }
    const { mutate: createUsers,
        isSuccess: isSuccessUpdateWorkspace, } = useAddUsers({ onSuccess: onSuccessAddUser });


    function isValidEmail(email) {
        let error = "";
        if (emails.items.length >= 5) {
            error = "Only 5 emails can be added.";
        }
        else if (emails.items.includes(email)) {
            error = `${email} has already been added.`;
        }

        const emailTest = /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
        if (!emailTest) {
            error = `${email} is not a valid email address.`
        }
        if (error) {
            setEmails(draft => { draft.error = error })
            return false;
        }
        return true;
    }
    function handleKeyDown(event) {
        if (["Enter", "Tab", ","].includes(event.key)) {
            event.preventDefault();

            if (emails.inputValue && isValidEmail(emails.inputValue)) {
                setEmails(draft => {
                    draft.items = [...draft.items, emails.inputValue];
                    draft.inputValue = "";
                })
            }
        }
    }
    function handleDelete(item) {
        setEmails(draft => {
            draft.items = draft.items.filter(i => i !== item)
            draft.error = ""
        });
    }
    function handleInvite() {
        const defaultRole = roles.options.find(e => e.label == "User")
        const body = {
            emails: emails.items,
            roleId: emails.roleId == "" ? defaultRole.value : emails.roleId
        }
        createUsers(body);

    }
    return (
        <>
            {addUser && <Transition appear show={true} as={Fragment}>
                <Dialog
                    as="div"
                    className={style.dialogContainer}
                    onClose={() => { }}
                >
                    <div className={style.subContainer} style={{}}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className={style.dialogOverlay} />
                        </Transition.Child>

                        <span className={style.span} aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className={style.dialogTitleContainer}>
                                <Dialog.Title
                                    as="h2"
                                    className={style.dialogTitle}
                                >{"Add Users"}

                                </Dialog.Title>
                                <span className='font-Segoe text-xs font-light text-searchFilterColor'><i>Add email of users seprated by comma, select role and Invite.</i> </span>
                                <div className='flex flex-col'>
                                    <div className='mt-5  w-full'>
                                        <Select
                                            isClearable={false}
                                            name="roles"
                                            options={roles.options}
                                            defaultValue={roles.options.filter(e => e.label == "User")}
                                            placeholder="Roles"
                                            onChange={(event) => {
                                                setEmails((draft) => {
                                                    draft.roleId = event.value
                                                })
                                            }}
                                            className="basic-multi-select bg-white focus:outline-none  text-sm w-52"
                                        />

                                        <input className="focus:outline-none w-5/6 h-10 border mt-6 rounded-md pl-2"
                                            type="email"
                                            value={emails.inputValue}
                                            placeholder="Type email addresses and press `Enter`..."
                                            onKeyDown={handleKeyDown}
                                            onChange={(event) => {
                                                setEmails(draft => {
                                                    draft.inputValue = event.target.value;
                                                    draft.error = ""
                                                })
                                            }}

                                        />
                                        {emails.error && <p className="text-red-400 p-2">{emails.error}</p>}
                                        {emails.items.map(item => (
                                            <div className="bg-gray-100 rounded-md inline-block mr-3 mt-3 focus:border-gray-500 py-2" key={item}>
                                                <span className='text-sm px-2 font-Segoe font-normal'>{item}</span>
                                                <button
                                                    type="button"
                                                    className="px-2"
                                                    onClick={() => { handleDelete(item) }}
                                                >
                                                    &times;
                                                </button>
                                            </div>
                                        ))}

                                    </div>
                                    <div className={style.btnContainer}>
                                        <PrimaryButton

                                            onClick={handleInvite}
                                        >
                                            Invite
                                        </PrimaryButton>
                                        <OutlineSecondaryButton

                                            onClick={(draft) => {
                                                setAddUser(false)
                                            }}
                                        >
                                            Cancel
                                        </OutlineSecondaryButton>
                                    </div>
                                </div>
                            </div>

                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
            }
        </>
    )
}

export default AddUsersModal

const style = {
    dialogContainer:
        'fixed inset-0  overflow-y-auto bg-opacity-50 bg-gray-500',
    subContainer: 'min-h-screen px-6 text-center',
    dialogOverlay: 'fixed inset-0',
    span: 'inline-block h-screen align-middle',
    dialogTitleContainer:
        'inline-block w-full max-w-lg p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl max-h-full',
    dialogTitle: 'text-lg font-medium leading-6 text-textColor',
    closeBtn:
        'inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500',
    btnCancel:
        'py-2 px-7 rounded-sm border text-approveBtn border-activeBorderColor font-bold text-sm ml-2',
    btnRaise:
        'py-2 px-8 bg-approveBtn rounded-sm text-white text-sm font-bold ml-3',
    pTag: 'text-xs text-gray-400',
    assetName: 'text-sm text-gray-500',
    assetNm: 'text-textColor text-base mt-3',
    descriptionContainer: 'text-sm text-gray-500 mt-3',
    input: 'h-20 border-2 outline-none w-full mt-1 p-2',
    btnContainer: 'mt-16  flex flex-row gap-4',
    select: 'basic-multi-select border-none outline-none whitespace-nowrap text-14px font-normal font-Segoe  max-w-max',
    btnApprove: "py-2 px-6 bg-gradient rounded-sm text-white text-sm font-bold  ",
    btnReject: "py-2 px-5 rounded-sm border text-primary border-gradient font-bold text-sm ml-4",
}
