interface ButtonProps {
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    children: React.ReactNode;
    disabled?: boolean;
    type?: "button" | "submit" | "reset" | undefined
}
export default function PrimaryButton(props: ButtonProps) {

    return <button
        type={props.type ? props.type : "button"}
        onClick={props.onClick}
        disabled={props.disabled || false}
        className={`flex cursor-pointer items-center justify-center px-6 h-9  font-bold rounded ${props.disabled ? "bg-gray-200" : "hover:bg-theme-300 bg-theme-500"}  text-white`}
    >
        {props.children}
    </button>
}