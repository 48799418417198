import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import classNames from 'classnames'
import { FC } from 'react'
import { formattedDate, getUser } from '../util'
import { AssetFile } from './ViewAsset'
import DownloadIcon from '@mui/icons-material/Download'
import { IconButton } from '@mui/material'
import useDownload from '@Common/Queries/MutationQueries/getFileDownload'
import Loading from '@Common/Components/Loading'

const ViewVersionHistory: FC<{
    assetFiles: Array<AssetFile>
    isLoading: boolean
    owner: {
        data: Record<string, unknown>[]
        option: { value: string; name: string; label: string }[]
    }
}> = ({ assetFiles, owner, isLoading }) => {
    return (
        <div className=" w-80 max-h-96">
            {isLoading ? (
                <Loading></Loading>
            ) : (
                <>
                    <div
                        className={classNames(
                            'w-full sticky top-0 bg-white z-10 h-12 shadow'
                        )}
                    >
                        <Typography
                            className="text-sm p-4"
                            color="text.secondary"
                        >
                            Older Versions
                        </Typography>
                    </div>
                    <div className="w-full overflow-auto">
                        <List className="w-full text-sm">
                            {assetFiles.map((file, id) => (
                                <VersionListItem
                                    key={id}
                                    file={file}
                                    owner={owner}
                                ></VersionListItem>
                            ))}
                        </List>
                    </div>
                </>
            )}
        </div>
    )
}
const VersionListItem = ({ file, owner }) => {
    const {
        isSuccess,
        isError,
        isLoading: d,
        data,
    } = useDownload({ id: file.fileId })
    const onDownloadSuccess = () => {
        if (!data) {
            return
        }
        const link = document.createElement('a')
        link.setAttribute('download', 'download')
        link.setAttribute('href', data)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }
    return (
        <ListItem
            secondaryAction={
                <IconButton
                    size="small"
                    onClick={(e) => {
                        onDownloadSuccess()
                    }}
                >
                    <DownloadIcon fontSize="small"></DownloadIcon>
                </IconButton>
            }
        >
            <ListItemText
                primary={formattedDate(file.createdAt)}
                secondary={getUser(file.file.userId, owner)}
            ></ListItemText>
        </ListItem>
    )
}

export default ViewVersionHistory
