import { useQuery } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'

const getAssetPermission = async (id: any) => {
    const { data } = await axios.get(`${APi_URL}/assets/${id}/permission`)
    return data
}

export default function useAssetPermission(id: any) {
    return useQuery(['assets', id, 'permissions'], () => getAssetPermission(id))
}
