import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useQuery } from 'react-query'

export function useContact(contactId: string) {
    return useQuery(
        ['contact', contactId],
        async () => {
            const response = await axios.get(`${API_URL}/contacts/${contactId}`)
            return response.data
        },
        {
            keepPreviousData: true,
        }
    )
}
