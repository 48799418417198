import useUsers from '@Common/Queries/GetQueries/GetUsers'
import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import { Fragment } from 'react'
interface UserDropDownProps {
    label: string
    value?:
        | string
        | {
              value: string
              label: string
          }
    size?: 'small' | 'medium'
    autofocus?: boolean
    fullWidth?: boolean
    multiple?: boolean
    defaultValue?: {
        value: string
        label: string
    }
    onChange?: (value: string | null) => void
}
export function Users(props: UserDropDownProps) {
    const users = useUsers()
    function getValue() {
        if (typeof props.value === 'string') {
            const option = users.data?.option.find(
                (item) => item.value === props.value
            )
            return option ? option : props.multiple ? [] : null
        }
        return props.value || (props.multiple ? [] : null)
    }
    return (
        <Autocomplete
            loading={users.isLoading}
            options={users.data?.option}
            getOptionLabel={(option) => option.label}
            defaultValue={
                props.defaultValue
                    ? props.multiple
                        ? [props.defaultValue]
                        : props.defaultValue
                    : props.multiple
                    ? []
                    : null
            }
            multiple={props.multiple}
            value={getValue()}
            size={props.size}
            fullWidth={props.fullWidth}
            onChange={(e, newValue: string | null) => {
                if (props.onChange) {
                    props.onChange(newValue)
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.label}
                    autoFocus={props.autofocus}
                    variant='outlined'
                    fullWidth={props.fullWidth}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <Fragment>
                                {users.isLoading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </Fragment>
                        ),
                    }}
                />
            )}
        />
    )
}
