import { XIcon } from '@heroicons/react/outline'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import makeIcon from '@Common/utils/MakeIcon'
import { Asset } from '@AddAssets/index'
import { AssetStatus } from '@AddAssets/index'
import { useImmer } from 'use-immer'
interface AssetsListProps {
    setFileDetails: any
    assetFilesList: Asset[]
    onRemoveFile: (id: string) => void
    fileDetails: string
    setCustomAttributeValues: any
}
export default function AssetsList(props: AssetsListProps) {
    const fileSize = (size: any) => {
        if (size === 0) {
            return '0 Bytes'
        }
        const k = 1024
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
        const i = Math.floor(Math.log(size) / Math.log(k))
        return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
    }

    const setFileFormData = (file: Asset) => {
        if (file.status === AssetStatus.error) {
            return
        }
        props.setCustomAttributeValues({})
        props.setFileDetails(file.assetDetails.id)
    }

    function getStatusClass(status: AssetStatus) {
        if (status === AssetStatus.completed) {
            return 'bg-green-50  '
        }
        if (status === AssetStatus.error) {
            return 'bg-red-50 '
        }
        return 'bg-slate-200 animate-pulse '
    }
    const { fileDetails } = props
    return (
        <>
            <div className="bg-white h-full  overflow-auto">
                {props.assetFilesList.map((assetFile: Asset) => {
                    const { file, status, progress, id, error, assetDetails } =
                        assetFile
                    return (
                        <ul
                            key={id}
                            className={`flex flex-start items-center rounded-3px mt-0.5  ${
                                fileDetails === assetDetails?.id
                                    ? 'bg-blue-50 border-blue-500 border-l-4  transition-transform duration-200 transform scale-100'
                                    : getStatusClass(status)
                            } ${
                                fileDetails === assetDetails?.id &&
                                'bg-blue-100 border-blue-500 border-l-4  transition-transform duration-200 transform scale-100  '
                            }`}
                        >
                            <li
                                className={
                                    'flex-1 px-1 py-3 whitespace-nowrap ' +
                                    (status === AssetStatus.error
                                        ? 'cursor-not-allowed  '
                                        : 'cursor-pointer')
                                }
                                onClick={() =>
                                    AssetStatus.completed &&
                                    setFileFormData(assetFile)
                                }
                            >
                                <div className="flex items-center  text-left text-xs font-medium text-gray-500 ">
                                    <div className="flex-shrink-0  w-10 ">
                                        <FontAwesomeIcon
                                            icon={makeIcon(file.name).name}
                                            color={makeIcon(file.name).color}
                                            size="3x"
                                        />
                                    </div>
                                    <div className="ml-4 ">
                                        <div className="font-medium text-gray-900 ">
                                            <div
                                                title={file.name}
                                                className="w-56 truncate"
                                            >
                                                {file.name}
                                            </div>
                                            <span
                                                className={
                                                    'text-12px' +
                                                    (status ===
                                                    AssetStatus.error
                                                        ? ' text-red-400'
                                                        : status ===
                                                          AssetStatus.completed
                                                        ? ' text-green-400'
                                                        : ' text-blue-400')
                                                }
                                            >
                                                {status === AssetStatus.error
                                                    ? ` (Failed) ${error}`
                                                    : status ===
                                                      AssetStatus.completed
                                                    ? ' (Uploaded)'
                                                    : status ===
                                                      AssetStatus.uploaded
                                                    ? ' (Creating Asset...)'
                                                    : ' (Uploading...)'}
                                            </span>
                                        </div>
                                        <div className="text-sm text-gray-500">
                                            {fileSize(file.size)}
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li className="flex h-full w-40 px-6">
                                <div
                                    className="h-2 w-full rounded-lg bg-gray-300"
                                    style={{
                                        display:
                                            status === AssetStatus.uploading ||
                                            status === AssetStatus.uploaded
                                                ? 'block'
                                                : 'none',
                                    }}
                                >
                                    <div
                                        style={{
                                            width: `${progress}% `,
                                        }}
                                        className={`h-full rounded-lg ${
                                            progress || 0 < 50
                                                ? 'bg-red-600'
                                                : progress || 0 < 99
                                                ? 'bg-yellow-600'
                                                : status === AssetStatus.error
                                                ? 'bg-red-500'
                                                : 'bg-green-600'
                                        }`}
                                    ></div>
                                </div>
                            </li>
                            <li className="flex-none ">
                                <span className="grid h-full items-center px-2">
                                    <XIcon
                                        className={`h-5 w-5 cursor-pointer  scale-up-center ${
                                            status === AssetStatus.error
                                                ? 'text-gray-600'
                                                : 'text-crossMarkColor'
                                        }`}
                                        aria-hidden="true"
                                        onClick={() => props.onRemoveFile(id)}
                                    />
                                </span>
                            </li>
                        </ul>
                    )
                })}
            </div>

            {/* below code is only for modal*/}
        </>
    )
}
