import useAddCustomAttributeValuesToAsset, {
    AddCustomAttributeToAsset,
} from '@Common/Queries/MutationQueries/AddCustomAttributeToAsset'
import useRemoveCustomAttFromAsset from '@Common/Queries/MutationQueries/RemoveCustomAttributeFromAsset'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'
import { FC } from 'react'

const MultiSelectAttribute: FC<{
    assetValue: Array<{
        id: string
        value: string
    }>
    attribute: {
        id: string
        name: string
        type: string
    }
    assetCustomAttribute: Array<{
        id: string
        attributeValue: {
            id: string
            value: string
            attributeId: string
        }
    }>
    assetId: string
    assetName: string
    options: Array<{
        id: string
        value: string
        attributeId: string
    }>
    isVisible: Record<string, unknown>
}> = ({
    assetValue,
    attribute,
    assetCustomAttribute,
    assetId,
    assetName,
    options,
    isVisible,
}) => {
    const addCustomAttributeUpdaterFunction = (
        previousAsset: {
            AssetCustomAttribute: Array<{
                id: string
                name: string
                assetAttributeValues: Array<Record<string, unknown>>
            }>
            UserInputCustomAttributes: Array<{
                id: string
                name: string
                assetAttributeValues: Record<string, unknown>
            }>
        },
        currentValue: AddCustomAttributeToAsset
    ) => {
        let assetCustomAttribute: Array<Record<string, unknown>> =
            previousAsset.AssetCustomAttribute
        const index = assetCustomAttribute.findIndex(
            (e) => e.id === currentValue.attribute.id
        )
        let values: Array<Record<string, unknown>> = []
        if (index != -1) {
            values = Object.assign(
                [],
                assetCustomAttribute[index].assetAttributeValues
            )

            values = [
                ...values,
                {
                    attributeValue: {
                        id: currentValue.attributeValue?.id,
                        value: currentValue.attributeValue?.value,
                        attributeId: currentValue.attribute.id,
                    },
                    id: '',
                },
            ]
            assetCustomAttribute[index] = {
                ...currentValue.attribute,
                assetAttributeValues: values,
            }
        } else {
            values.push({
                attributeValue: {
                    id: currentValue.attributeValue?.id,
                    value: currentValue.attributeValue?.value,
                    attributeId: currentValue.attribute.id,
                },
            })
            assetCustomAttribute = [
                ...assetCustomAttribute,
                {
                    ...currentValue.attribute,
                    assetAttributeValues: values,
                },
            ]
        }
        const finalModifiedAsset = previousAsset

        finalModifiedAsset.AssetCustomAttribute = assetCustomAttribute
        return finalModifiedAsset
    }

    const removeCustomAttributeUpdaterFunction = (
        previousAsset: {
            AssetCustomAttribute: Array<{
                id: string
                name: string
                assetAttributeValues: Array<Record<string, unknown>>
            }>
            UserInputCustomAttributes: Array<{
                id: string
                name: string
                assetAttributeValues: Record<string, unknown>
            }>
        },
        currentValue: AddCustomAttributeToAsset
    ) => {
        const assetCustomAttribute: Array<Record<string, unknown>> =
            Object.assign([], previousAsset.AssetCustomAttribute)
        const index = assetCustomAttribute.findIndex(
            (e) => e.id === currentValue.attribute.id
        )
        if (index != -1) {
            const values = Object.assign(
                [],
                assetCustomAttribute[index].assetAttributeValues
            )

            const valueIndex = values.findIndex(
                (e) => e.attributeValue.id === currentValue.attributeValue?.id
            )
            values.splice(valueIndex, 1)

            assetCustomAttribute[index] = {
                ...currentValue,
                id: currentValue.attribute.id,
                assetAttributeValues: values,
            }
            return assetCustomAttribute
        }
        return assetCustomAttribute
    }
    const sendDataToGTM = useGTMDispatch()

    const addCustomAttributeValuesToAsset = useAddCustomAttributeValuesToAsset({
        addCustomAttributeUpdaterFunction: addCustomAttributeUpdaterFunction,
        onSuccess: () => {
            sendDataToGTM({
                event: 'asset_edit',
                asset_id: assetId,
                asset_name: assetName,
                attribute_name: attribute.name,
            })
        },
    })
    const removeCustomAttributeValuesToAsset = useRemoveCustomAttFromAsset({
        removeCustomAttributeUpdaterFunction:
            removeCustomAttributeUpdaterFunction,
        onSuccess: () => {
            sendDataToGTM({
                event: 'asset_edit',
                asset_id: assetId,
                asset_name: assetName,
                attribute_name: attribute.name,
            })
        },
    })
    const handleChange = (
        event: React.SyntheticEvent,
        value: Array<{
            id: string
            value: string
            attributeId: string
        }>,
        reason: string,
        details: {
            option: {
                id: string
                value: string
                attributeId: string
            }
        }
    ) => {
        if (reason === 'selectOption') {
            addCustomAttributeValuesToAsset.mutate({
                assetId: assetId,
                attribute: attribute,
                attributeValue: details.option,
            })
        } else if (reason === 'createOption') {
            addCustomAttributeValuesToAsset.mutate({
                assetId: assetId,
                attribute: attribute,
                attributeValue: {
                    value: details.option,
                },
                value: details.option,
            })
        } else if (reason === 'clear') {
            assetValue.map((eachValue) =>
                removeCustomAttributeValuesToAsset.mutate({
                    id: assetCustomAttribute.find(
                        (e) => e.attributeValue.id === eachValue.id
                    ).id,
                    assetId: assetId,
                    attribute: attribute,
                    attributeValue: eachValue,
                })
            )
        } else if (reason === 'removeOption') {
            removeCustomAttributeValuesToAsset.mutate({
                id: assetCustomAttribute.find(
                    (e) => e.attributeValue.id === details.option.id
                ).id,
                assetId: assetId,
                attribute: attribute,
                attributeValue: details.option,
            })
        }
    }
    return (
        <Autocomplete
            open={true}
            freeSolo={attribute.type === 'Tag'}
            autoSelect
            limitTags={1}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="filled"
                    size="small"
                    className="z-10 h-full overflow-y-scroll"
                />
            )}
            disableCloseOnSelect={true}
            options={options}
            isOptionEqualToValue={(option, value) => {
                if (value) {
                    return option.value === value.value
                }
                return false
            }}
            getOptionSelected={(option, value) => option.value === value.value}
            renderTags={(value, getTagProps) => {
                return (
                    <>
                        {value.map((option, index) => (
                            <Chip
                                {...getTagProps({ index })}
                                key={index}
                                label={option.value}
                                size="small"
                                className="whitespace-nowrap overflow-hidden text-ellipsis"
                            />
                        ))}
                    </>
                )
            }}
            renderOption={(props, option, { selected }) => (
                <li
                    {...props}
                    className="p-0 w-full overflow-hidden whitespace-nowrap text-ellipsis px-2 text-sm"
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selected}
                                size="small"
                                color="primary"
                            />
                        }
                        label={option.value}
                    />
                </li>
            )}
            getOptionLabel={(option) => option.value}
            onChange={handleChange}
            multiple
            value={assetValue}
        ></Autocomplete>
    )
}

export default MultiSelectAttribute
