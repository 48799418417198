import Loading from '@Common/Components/Loading'
import { Accounts } from '@Common/Components/Select/Accounts'
import useGetStatus from '@Common/Queries/Dealroom/GetAllStatus'
import { useDealRoom } from '@Common/Queries/Dealroom/GetDealRoom'
import { useUpdateDealRoom } from '@Common/Queries/Dealroom/UpdateDealRoom'
import {
    Autocomplete,
    Divider,
    Switch,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import { useParams } from 'react-router-dom'
import { useDealRoomColor } from 'src/Stores/DealRoomTheme'
import { useImmer } from 'use-immer'

const DealRoomSettings = () => {
    const { id } = useParams()
    if (!id) {
        return
    }
    const dealroom = useDealRoom({ id: id })
    const status = useGetStatus()

    const updateDealRoom = useUpdateDealRoom()

    const setColor = useDealRoomColor((state) => state.setColor)

    const [settings, setSettings] = useImmer({
        opportunity: dealroom?.data?.dealRoom?.DealRoomProperties.opportunity,
        accountId: dealroom?.data?.dealRoom?.DealRoomProperties.accountId,
        dealStatus: dealroom?.data?.dealRoom?.dealRoomStatus,
        download: dealroom?.data?.dealRoom?.DealRoomProperties.download,
    })

    if (dealroom.isLoading || status.isLoading) {
        return <Loading></Loading>
    }

    const getDealStatus = (dealStatus: any) => {
        return status.data.option.find((e) => {
            if (e.value === dealStatus) {
                return e.label
            }
        })
    }
    return (
        <div className="flex flex-col w-full h-full gap-5">
            <div className="flex flex-col">
                <Typography variant="subtitle2" className="p-4">
                    Settings
                </Typography>
                <Divider className="w-full"></Divider>
            </div>
            <div className="w-full gap-5 px-5 flex flex-col">
                <Accounts
                    label="Account"
                    fullWidth
                    disabled={dealroom.data.dealRoom.action === 'VIEW'}
                    value={settings.accountId}
                    onChange={(option) => {
                        if (option != null) {
                            setSettings((draft) => {
                                draft.accountId = option?.value
                            })
                        } else {
                            setSettings((draft) => {
                                draft.accountId = null
                            })
                        }
                        updateDealRoom.mutate({
                            id: id,
                            data: {
                                accountId: option?.value,
                            },
                        })
                    }}
                />
                <TextField
                    className="w-full"
                    placeholder="Opportunity"
                    variant="outlined"
                    multiline
                    data-testid="dealroom-opportunity-input"
                    maxRows={2}
                    defaultValue={
                        dealroom?.data?.dealRoom?.DealRoomProperties.opportunity
                    }
                    value={settings.opportunity}
                    onChange={(e) => {
                        setSettings((draft) => {
                            draft.opportunity = e.target.value
                        })
                    }}
                    onBlur={(event) => {
                        updateDealRoom.mutate({
                            id: id,
                            data: {
                                opportunity: event.target.value,
                            },
                        })
                    }}
                    disabled={dealroom.data.dealRoom.action === 'VIEW'}
                ></TextField>
                <Autocomplete
                    loading={status.isLoading}
                    options={status.data?.option}
                    data-testid="dealroom-status-input"
                    getOptionLabel={(option) => option.label}
                    value={getDealStatus(settings.dealStatus)}
                    fullWidth
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Deal status"
                            disabled={dealroom.data.dealRoom.action === 'VIEW'}
                        />
                    )}
                    onChange={(e, newValue) => {
                        if (newValue != null) {
                            setSettings((draft) => {
                                draft.dealStatus = newValue.value
                            })
                        } else {
                            setSettings((draft) => {
                                draft.dealStatus = null
                            })
                        }

                        updateDealRoom.mutate({
                            id: id,
                            data: {
                                dealStatus: newValue.value,
                            },
                        })
                    }}
                    disabled={dealroom.data.dealRoom.action === 'VIEW'}
                />
            </div>
        </div>
    )
}

export default DealRoomSettings
