import Loading from '@Common/Components/Loading'
import useVisits from '@Common/Queries/Track/GetVisitsForAsset'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import TablePagination from '@mui/material/TablePagination'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import prettyMilliseconds from 'pretty-ms'
import { useNavigate, useParams } from 'react-router-dom'
import { formattedDate } from 'src/ViewAssetScreen/util'
import { Updater, useImmer } from 'use-immer'
import { getVisitorName, NameDisplay, displayAvatarBasedOnName } from 'src/BuyerInsightsUtil'

export const TrackVisit = (props: { setOpen: Updater<boolean> }) => {
    const { id } = useParams()
    if (!id) {
        return <div>Asset ID is missing</div>
    }
    const [hover, setHover] = useImmer(null)
    const navigate = useNavigate()
    const [page, setPage] = useImmer(0)
    const pageSize = 10
    const visit = useVisits(id, {
        pageSize: pageSize,
        currentPage: page,
    })

    if (visit.isFetching) {
        return <Loading />
    }
    if (visit.isError) {
        return (
            <div className="flex h-full w-full items-center justify-center text-xxl">
                You don't have access to this page
            </div>
        )
    }
    const recentVisitDataCol: GridColDef[] = [
        {
            headerName: 'Visitor/link name',
            field: 'visitorName',
            disableColumnMenu: true,
            flex: 1,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div className="w-full flex justify-between">
                        <div className="flex gap-2 w-full">
                            {displayAvatarBasedOnName(params.value, params.row.index)}
                            <div className="flex flex-col w-full flex-1">
                                <NameDisplay
                                    city={params.row.city}
                                    name={params.value}
                                    country={params.row.country}
                                ></NameDisplay>
                                <div className="flex gap-2 flex-1 w-full">
                                    <Tooltip title={params.row.linkName}>
                                        <Typography
                                            color="text.secondary"
                                            variant="caption"
                                            sx={{
                                                maxWidth:
                                                    params.row.contact != ''
                                                        ? '33%'
                                                        : '80%',
                                            }}
                                            className="overflow-hidden whitespace-nowrap text-ellipsis"
                                        >
                                            {params.row.linkName}
                                        </Typography>
                                    </Tooltip>
                                    {params.row.contact != '' && (
                                        <>
                                            <Divider
                                                className="h-full w-1"
                                                orientation="vertical"
                                            ></Divider>
                                            <Tooltip title={params.row.contact}>
                                                <Typography
                                                    sx={{
                                                        maxWidth: '60%',
                                                    }}
                                                    color="text.secondary"
                                                    variant="caption"
                                                    className="overflow-hidden whitespace-nowrap text-ellipsis"
                                                >
                                                    {params.row.contact}
                                                </Typography>
                                            </Tooltip>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        {hover === params.row.id && (
                            <Button
                                className="drop-shadow-md bg-white hover:drop-shadow-md"
                                sx={{
                                    '&.MuiButtonBase-root:hover': {
                                        bgcolor: 'rgba(42, 71, 116, 0.04)',
                                    },
                                }}
                            >
                                Open
                            </Button>
                        )}
                    </div>
                )
            },
        },
        {
            headerName: 'Documents',
            field: 'assetName',
            disableColumnMenu: true,
            flex: 1,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <div className="flex items-center gap-3 py-3">
                        <div
                            className={`w-20 h-11 overflow-hidden border border-solid border-borderColor rounded`}
                        >
                            <img
                                src={params.row.thumbnailUrl}
                                loading="eager"
                                className="w-full h-auto"
                            ></img>
                        </div>
                        <Typography color="text.secondary" fontWeight={300}>
                            {params.value}
                        </Typography>
                    </div>
                )
            },
        },
        {
            headerName: 'Time spent',
            field: 'timeSpent',
            disableColumnMenu: true,
            sortable: false,
            width: 144,
            filterable: false,
            renderCell: (params) => {
                return (
                    <Typography color={'text.disabled'} fontWeight={300}>
                        {params.formattedValue}
                    </Typography>
                )
            },
            valueFormatter: (params) => {
                return prettyMilliseconds(params.value)
            },
        },
        {
            headerName: 'Last engaged date',
            field: 'lastEngagement',
            disableColumnMenu: true,
            sortable: false,
            filterable: false,
            width: 144,
            renderCell: (params) => {
                return (
                    <Typography color={'text.disabled'} fontWeight={300}>
                        {params.formattedValue}
                    </Typography>
                )
            },
            valueFormatter: (params) => {
                return params.value ? formattedDate(params.value) : '--'
            },
        },
    ]
    const rows = visit.data.visits.map((eachVisit, index) => {
        return {
            id: eachVisit.id,
            index: index,
            assetId: eachVisit.TrackLink.AssetTrackLink.assetId,
            visitorName: getVisitorName(eachVisit.TrackSession.AssetTrackSession.email, eachVisit.TrackSession.AssetTrackSession.buyerDetails as object),
            linkName: eachVisit.TrackLink.TrackLinkProperties.name,
            assetName: eachVisit.TrackLink.AssetTrackLink.Asset.name,
            thumbnailUrl: eachVisit.thumbnailUrl,
            timeSpent: eachVisit.visitDuration,
            lastEngagement: eachVisit.visitTime,
            contact:
                eachVisit.TrackLink && eachVisit.TrackLink.TrackLinkContact
                    ? eachVisit.TrackLink.TrackLinkContact.map(
                        (e) => e.email
                    ).join(',')
                    : '',
            city: eachVisit.TrackSession.AssetTrackSession.buyerDetails?.ipData?.city,
            country: eachVisit.TrackSession.AssetTrackSession.buyerDetails?.ipData?.country_name
        }
    })
    return (
        <div className="w-full h-full flex flex-col py-4">
            <DataGrid
                sx={{
                    border: 'none',
                    flex: 1,
                    '& .MuiDataGrid-row:hover': {
                        background: 'white',
                        cursor: 'pointer',
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 300,
                    },
                }}
                rowHeight={64}
                rows={rows}
                onRowClick={(params) => {
                    props.setOpen(true)
                    navigate(
                        `/search/viewAsset/${params.row.assetId}/visit/${params.row.id}`
                    )
                }}
                columns={recentVisitDataCol}
                componentsProps={{
                    row: {
                        onMouseEnter: (event) => {
                            const id = event.currentTarget.dataset.id
                            setHover(id)
                        },
                        onMouseLeave: () => setHover(null),
                    },
                }}
                hideFooter={true}
            />
            <TablePagination
                rowsPerPageOptions={[pageSize]}
                colSpan={3}
                component="div"
                className="text-xs text-lightTextDisabled border-none overflow-hidden"
                count={visit.data.count}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={(e, page) => setPage(page)}
                backIconButtonProps={{
                    className: 'stroke-slate-50',
                }}
                nextIconButtonProps={{
                    className: 'stroke-slate-50',
                }}
            />
        </div>
    )
}
