import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import useInit from '../GetQueries/useInit'
import { v4 as uuid } from 'uuid'

export const useCreateDealRoom = (props: { onSuccess: any }) => {
    const queryClient = useQueryClient()
    return useMutation(
        (data: {
            name: string
            opportunity: string | null
            accountId: string | null
        }) =>
            axios.post(`${API_URL}/dealroom`, data, {
                headers: {
                    'Content-type': 'application/json',
                },
            }),
        {
            onSuccess: (data) => {
                if (props.onSuccess) {
                    props.onSuccess(data)
                }
                queryClient.invalidateQueries(['dealroom', 'search'])
            },
            onMutate: (data) => {},
        }
    )
}
