import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useQuery } from 'react-query'
import { GetContact } from '@Server/getContact'
export function useAccounts(options: any) {
    return useQuery(
        ['accounts', options],
        async () => {
            const response = await axios.post(
                `${API_URL}/accounts/search`,
                options
            )
            return response.data
        },
        {
            keepPreviousData: true,
        }
    )
}
