import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useMutation, useQuery } from 'react-query'

export default function useSaveSiteImageUsingUrl(props?: any) {
    return useMutation(
        (obj: any) =>
            axios.get(`${API_URL}/site/upload/image/url`, {
                params: {
                    url: obj,
                },
            }),
        {
            onSuccess(data, variables, context) {
                if (props.onSuccess) {
                    props?.onSuccess(data, variables)
                }
            },
        }
    )
}
