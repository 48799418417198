import { Grid } from '@mui/material'

const DealRoomThemeGrid = ({ id, updateDealRoom }) => {
    const themes = [
        'https://dealroom-themes.s3.amazonaws.com/None+Pattern.png',
        'https://dealroom-themes.s3.amazonaws.com/Blue+pattern.jpeg',
        'https://dealroom-themes.s3.amazonaws.com/Gold+pattern.jpeg',
        'https://dealroom-themes.s3.amazonaws.com/Multi+colored+wave.jpeg',
        'https://dealroom-themes.s3.amazonaws.com/Multi+colored.jpeg',
        'https://dealroom-themes.s3.amazonaws.com/Pink+purple+pattern.jpeg',
        'https://dealroom-themes.s3.amazonaws.com/Purple+pattern.jpg',
        'https://dealroom-themes.s3.amazonaws.com/Purple+wave.jpeg',
        'https://dealroom-themes.s3.amazonaws.com/Twisted+glass.jpeg',

        'https://dealroom-themes.s3.amazonaws.com/Workshop.png',
        'https://dealroom-themes.s3.amazonaws.com/White_patterns.png',
        'https://dealroom-themes.s3.amazonaws.com/Warehouse.png',
        'https://dealroom-themes.s3.amazonaws.com/Shopping_center.png',
        'https://dealroom-themes.s3.amazonaws.com/Shipyard.png',
        'https://dealroom-themes.s3.amazonaws.com/Report.png',
        'https://dealroom-themes.s3.amazonaws.com/Pattern02.png',
        'https://dealroom-themes.s3.amazonaws.com/Pattern01.png',
        'https://dealroom-themes.s3.amazonaws.com/Oil_gas.png',
        'https://dealroom-themes.s3.amazonaws.com/Merchendize.png',
        'https://dealroom-themes.s3.amazonaws.com/City_scape.png',
        'https://dealroom-themes.s3.amazonaws.com/App.png',

        'https://dealroom-themes.s3.amazonaws.com/Blue+BG.png',
        'https://dealroom-themes.s3.amazonaws.com/Black+BG.png',
        'https://dealroom-themes.s3.amazonaws.com/Green+BG.png',
        'https://dealroom-themes.s3.amazonaws.com/Orange+BG.png',
        'https://dealroom-themes.s3.amazonaws.com/Pink+BG.png',
        'https://dealroom-themes.s3.amazonaws.com/Purple+BG.png',
    ]

    return (
        <div className="flex pb-3">
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <div
                        className="h-12 overflow-hidden border border-gray-200 rounded hover:cursor-pointer"
                        onClick={() => {
                            updateDealRoom.mutate({
                                id: id,
                                data: {
                                    theme: 'none',
                                },
                            })
                        }}
                    >
                        {' '}
                        <img src={themes[0]}></img>
                    </div>
                </Grid>
                {themes.map((value, index) => {
                    if (index !== 0) {
                        return (
                            <Grid item xs={4}>
                                <div
                                    className="h-12 overflow-hidden border border-gray-200 rounded hover:cursor-pointer"
                                    onClick={() => {
                                        updateDealRoom.mutate({
                                            id: id,
                                            data: {
                                                theme: themes[index],
                                            },
                                        })
                                    }}
                                >
                                    <img src={themes[index]}></img>
                                </div>
                            </Grid>
                        )
                    }
                })}
            </Grid>
        </div>
    )
}
export default DealRoomThemeGrid
