const colors = [
    '#FFE0B2',
    '#F8BBD0',
    '#C8E6C9',
    '#FECDD2',
    '#E1BEE7',
    '#B2EBF2',
    '#B2DFDB',
    '#BBDEFB',
]
export const getColor = (id: number) => {
    return colors[id % colors.length]
}
export const getDefaultColor = () => {
    return '#E9E9E9'
}
export const getRandomColor = () => {
    return colors[Math.floor(Math.random() * 8)]
}
