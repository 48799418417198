import { VIEWER_DOMAIN } from '@Common/Types/configs/urlConfig'
import { Alert, Button, Typography } from '@mui/material'
import { FC } from 'react'
import { Updater } from 'use-immer'

const TrackLinkConfirmation: FC<{
    code: string
    setOpen: Updater<{ open: boolean; anchorEl: HTMLElement }>
}> = ({ code, setOpen }) => {
    return (
        <div className="h-full w-96 p-6 text-center flex flex-col gap-6">
            <Typography fontSize={24}>Link created successfully!</Typography>
            <Alert severity="info">
                {
                    'Copy and share this link to get insights on page views, page view time and other such analytics'
                }
            </Alert>
            <Typography>{`Link: https://${VIEWER_DOMAIN}/${code}`}</Typography>
            <Button
                variant="contained"
                onClick={() => {
                    navigator.clipboard.writeText(
                        `https://${VIEWER_DOMAIN}/${code}`
                    )
                    setOpen((draft) => {
                        return { open: false, anchorEl: null }
                    })
                }}
            >
                Copy link
            </Button>
        </div>
    )
}
export default TrackLinkConfirmation
