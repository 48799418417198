import { useQuery } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'

const getUsers = async () => {
    const { data } = await axios.get(`${APi_URL}/users`, {
        headers: { 'Content-Type': 'application/json' },
    })
    return {
        data: data,
        option: data.map((user: any) => ({
            value: user.id,
            label: user.name,
        })),
    }
}

export default function useUsers() {
    return useQuery('owners', getUsers)
}
