import Loading from '@Common/Components/Loading'
import { useCreateDealRoomComments } from '@Common/Queries/Dealroom/CreateDealRoomComment'
import { useDealRoomComments } from '@Common/Queries/Dealroom/GetComments'
import { useDealRoom } from '@Common/Queries/Dealroom/GetDealRoom'
import useUsers from '@Common/Queries/GetQueries/GetUsers'
import { Button, Divider, Typography } from '@mui/material'
import { useRef } from 'react'
import { useParams } from 'react-router-dom'
import Comment from './Comment'
import Editor from './Editor'
import AvatarComponent from '@Common/Components/Avatar'
import useOnClickOutside from '@Common/hooks/useOnClickOutside'

const DealRoomComments = () => {
    const { id } = useParams()
    if (!id) {
        return
    }
    const users = useUsers()
    const comments = useDealRoomComments(id)
    const viewRef = useRef<any>()

    const dealroom = useDealRoom({ id: id })
    const createComment = useCreateDealRoomComments(id)
    const { ref, isComponentVisible, setIsComponentVisible } =
        useOnClickOutside('')
    if (dealroom.isLoading || users.isLoading || comments.isLoading) {
        return <Loading></Loading>
    }
    return (
        <div className="flex flex-col w-full h-full gap-4">
            <div className="flex flex-col h-14">
                <Typography variant="subtitle2" className="p-4">
                    Comments
                </Typography>
                <Divider className="w-full"></Divider>
            </div>

            <div className="p-4 flex flex-col flex-1 overflow-y-auto overflow-x-hidden w-full">
                {comments.data.map((comment) => (
                    <Comment
                        key={comment.id}
                        comment={comment}
                        users={users.data}
                        level={0}
                        dealRoomId={id}
                    ></Comment>
                ))}
            </div>
            <div className="p-4 flex  gap-2">
                <AvatarComponent
                    name={dealroom.data?.dealRoom.User.email}
                ></AvatarComponent>
                <div
                    ref={ref}
                    className={`flex flex-col flex-1 h-full hover:cursor-pointer overflow-hidden justify-center`}
                    onClick={(e) => setIsComponentVisible(true)}
                >
                    {isComponentVisible ? (
                        <>
                            <Editor
                                editable={true}
                                ref={viewRef}
                                users={users.data}
                                style={{
                                    border: '1px solid rgba(0, 0, 0, 0.23)',
                                }}
                            ></Editor>
                            <div className="flex justify-end gap-2 mt-2">
                                {/* cancel button might be be nedded later */}
                                {/* <Button
                                    size="small"
                                    className="mr-2"
                                    onClick={() => {
                                        const state = viewRef.current.view.state
                                        const tr = state.tr.replaceWith(
                                            0,
                                            state.doc.content.size,
                                            [
                                                {
                                                    type: 'paragraph',
                                                    content: [
                                                        {
                                                            type: 'text',
                                                            text: '',
                                                        },
                                                    ],
                                                },
                                            ]
                                        )
                                        viewRef.current?.view.dispatch(tr)
                                        viewRef.current?.view.hasFocus()
                                    }}
                                    sx={{
                                        padding: 0,
                                    }}
                                >
                                    Cancel
                                </Button> */}
                                <Button
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                        const state =
                                            viewRef?.current.view.state
                                        if (state.doc.content.size <= 2) {
                                            return
                                        }
                                        const tr = state.tr.replaceWith(
                                            0,
                                            state.doc.content.size,
                                            [
                                                {
                                                    type: 'paragraph',

                                                    content: [
                                                        {
                                                            type: 'text',
                                                            text: '',
                                                        },
                                                    ],
                                                },
                                            ]
                                        )
                                        viewRef.current.view.dispatch(tr)
                                        createComment.mutate({
                                            comment: JSON.stringify(
                                                viewRef?.current.view.state
                                            ),
                                        })
                                    }}
                                >
                                    Post
                                </Button>
                            </div>
                        </>
                    ) : (
                        <Typography
                            color={'text.disabled'}
                            className="items-center"
                        >
                            Add a comment
                        </Typography>
                    )}
                </div>
            </div>
        </div>
    )
}

export default DealRoomComments
