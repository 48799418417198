import { useQuery } from 'react-query'
import axios from 'axios'
import { API_URL } from '@Common/Types/configs/urlConfig'
export function clearUserData() {
    Object.keys(localStorage).forEach((key) => {
        if (key.search('init') >= 0) {
            localStorage.removeItem(key)
        }
    })
    localStorage.removeItem('theme')
}
const getFeatures = async () => {
    const { data } = await axios.get(`${API_URL}/features`)
    return data
}

export default function useFeatures() {
    return useQuery(['features'], () => getFeatures(), {
        onError: (error) => {
            if (error.response.data === 'Invalid Tenant') {
                clearUserData()
                window.location.href = '/'
            }
        },
    })
}
