import { API_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useQuery } from 'react-query'

export const useDealRoomTrackEmails = (id: string) => {
    return useQuery(['dealroom', id, 'track', 'email'], async () => {
        const result = await axios.get(
            `${API_URL}/dealroom/${id}/trackPermission`
        )
        return result.data
    })
}
