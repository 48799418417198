import { useEffect, useRef, useState } from 'react'
import AssetDetailsForm from './Components/AssetDetailsForm/AssetDetailsForm'
import AssetsListDropZone from './Components/DragDrop/AssetsListDropZone'
import { useImmer } from 'use-immer'
import AssetsList from './Components/DragDrop/AssetsList'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import CommonModal from '@Common/Components/Modals/CommonModal'
import FileValidationModal from '@Common/Components/Modals/FileValidationModal'
import { BASE_URL } from '../../config'
import UploadFormLoader from '@Common/Components/Loaders/UploadFormLoader'
import DuplicateFileList from '@Common/Components/Modals/DuplicateFileList'
import { BadgeCheckIcon, ExclamationIcon } from '@heroicons/react/outline'
import { API_URL } from '@Common/Types/configs/urlConfig'
import useViewAsset from '@Common/Queries/GetQueries/ViewAssetDetails'
import useAddAsset from '@Common/Queries/MutationQueries/AddAsset'
import PrimaryButton from '@Common/Components/Button/PrimaryButton'
import { AssetStatus, Asset } from '.'
import { CircularProgress } from '@mui/material'
import Loading from '@Common/Components/Loading'

const AssetUpload = (props: any) => {
    const { setFileDetails, fileDetails } = props
    const [customAttributeValues, setCustomAttributeValues] = useState<object>(
        {}
    )
    return (
        <>
            <div
                className={` h-full w-full `}
                /* ${
                //     props.isOpen ? '' : 'absolute'
                // }`}*/
            >
                <div className="lg:col-span-3 overflow-auto lg:min-h-full rounded-3px bg-white ">
                    <div className="flex flex-col h-full lg:col-span-3 rounded-3px shadow-2xl">
                        <div className="flex-none px-6 pt-3 pb-2 text-left text-base  font-semibold">
                            Assests
                        </div>
                        <div className="flex-none ml-6 w-10 h-1 bg-blue-600"></div>
                        <div className="rounded-lg flex-1 p-3 overflow-auto mb-auto">
                            <div className="min-w-full bg-white ">
                                <AssetsList
                                    assetFilesList={props.assetsFiles}
                                    onRemoveFile={props.onRemoveFile}
                                    setFileDetails={setFileDetails}
                                    fileDetails={fileDetails}
                                    setCustomAttributeValues={
                                        setCustomAttributeValues
                                    }
                                />
                            </div>
                        </div>
                        <div className="w-full pt-2">
                            <AssetsListDropZone
                                onFiles={props.onFiles}
                                bodyFull={false}
                                isOpen={props.isOpen}
                            />
                        </div>

                        {props.isSearch && (
                            <div className="w-full pt-2">
                                <PrimaryButton
                                    onClick={() => {
                                        const assetsSuccess =
                                            props.assetsFiles.filter(
                                                (asset: Asset) => {
                                                    return (
                                                        asset.status ===
                                                        AssetStatus.completed
                                                    )
                                                }
                                            )
                                        props.setSuggestedAssets(
                                            assetsSuccess.map(
                                                (assetFile: {
                                                    assetDetails: any
                                                }) => assetFile.assetDetails
                                            )
                                        )
                                    }}
                                >
                                    Done
                                </PrimaryButton>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
export default AssetUpload
