import { APi_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { UpdateWorkspace } from '@Server/updateWorkspace'
export default function useUpdateWorkspace() {
    const queryClient = useQueryClient()

    return useMutation<any, Error, UpdateWorkspace>(
        (obj) =>
            axios.post(
                `${APi_URL}/workspace`,
                {
                    ...obj,
                },
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['init'])
            },
        }
    )
}
