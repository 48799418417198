import useDebounce from '@Common/hooks/useDebounce'
import useAddCustomAttributeValuesToAsset, {
    AddCustomAttributeToAsset,
} from '@Common/Queries/MutationQueries/AddCustomAttributeToAsset'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import { TextField } from '@mui/material'
import { FC, useEffect } from 'react'
import { useImmer } from 'use-immer'

const TextFieldAttribute: FC<{
    value: string
    attribute: {
        id: string
        name: string
        type: string
    }
    assetId: string
    assetName: string
    type: string
    handleKeyDown: any
}> = ({ value, attribute, assetId, type, handleKeyDown, assetName }) => {
    const addCustomAttributeUpdaterFunction = (
        previousAsset: {
            AssetCustomAttribute: Array<{
                id: string
                name: string
                assetAttributeValues: Array<Record<string, unknown>>
            }>
            UserInputCustomAttributes: Array<{
                id: string
                name: string
                assetAttributeValues: Record<string, unknown>
            }>
        },
        currentValue: AddCustomAttributeToAsset
    ) => {
        let userCustomAttribute: Array<{
            assetAttributeValues: {
                assetId: string
                attributeId: string
                createdAt: Date
                value: string | undefined
            }
        }> = previousAsset.UserInputCustomAttributes
        const index = userCustomAttribute.findIndex(
            (e) => e.id === currentValue.attribute.id
        )
        let values: {
            assetId: string
            attributeId: string
            createdAt: Date
            value: string | undefined
        } = {
            assetId: currentValue.assetId,
            attributeId: currentValue.attribute.id,
            value: currentValue?.value,
            createdAt: new Date(),
        }
        if (index != -1) {
            values = {
                ...userCustomAttribute[index].assetAttributeValues,
                value: currentValue.value,
            }
            userCustomAttribute[index] = {
                ...userCustomAttribute[index],
                assetAttributeValues: values,
            }
        } else {
            userCustomAttribute = [
                ...userCustomAttribute,
                { ...currentValue.attribute, assetAttributeValues: values },
            ]
        }
        const finalModifiedAsset = previousAsset

        finalModifiedAsset.UserInputCustomAttributes = userCustomAttribute

        return finalModifiedAsset
    }
    const sendDataToGTM = useGTMDispatch()
    const addCustomAttributeValuesToAsset = useAddCustomAttributeValuesToAsset({
        addCustomAttributeUpdaterFunction: addCustomAttributeUpdaterFunction,
        onSuccess: () => {
            sendDataToGTM({
                event: 'asset_edit',
                asset_id: assetId,
                asset_name: assetName,
                attribute_name: attribute.name,
            })
        },
    })
    const [textAttr, setTextAttr] = useImmer({
        attributeId: attribute.id,
        value: value || '',
    } as Record<string, unknown>)
    const handleChange = (event) => {
        const value = event.target.value.trim()
        setTextAttr(value)
        addCustomAttributeValuesToAsset.mutate({
            assetId: assetId,
            attribute: attribute,
            value: value,
        })
    }
    return (
        <TextField
            type={type === 'Number' ? 'number' : 'text'}
            autoFocus
            size="small"
            variant="filled"
            defaultValue={textAttr.value}
            onBlur={(event) => {
                handleChange(event)
            }}
            onKeyDown={(e) =>
                handleKeyDown(e, attribute.id, textAttr.value, handleChange)
            }
            className="w-full"
        ></TextField>
    )
}
export default TextFieldAttribute
