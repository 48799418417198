import AvatarComponent from '@Common/Components/Avatar'
import Loading from '@Common/Components/Loading'
import { useAddDealRoomTrackEmail } from '@Common/Queries/Dealroom/AddTrackEmail'
import { useDeleteDealRoomTrackEmail } from '@Common/Queries/Dealroom/DeleteTrackEmail'
import { useDealRoomTrackEmails } from '@Common/Queries/Dealroom/GetTrackEmails'
import { Alert, Button, Switch, TextField, Typography } from '@mui/material'
import { Updater, useImmer } from 'use-immer'
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined'
import toast from 'react-hot-toast'
import { VIEWER_DOMAIN } from '@Common/Types/configs/urlConfig'
import { useUpdateDealRoom } from '@Common/Queries/Dealroom/UpdateDealRoom'
import { useDealRoom } from '@Common/Queries/Dealroom/GetDealRoom'
import classNames from 'classnames'

const DealRoomShare = (props: {
    id: string
    code: string
    setOpen?: Updater<any>
    source?: string
    dealroom: any
}) => {
    const { id, dealroom, source } = props

    if (!id) {
        return null
    }
    const [email, setEmail] = useImmer({
        email: '',
        error: null,
    })

    const dealRoomTrack = useDealRoomTrackEmails(id)
    const addDealRoomTrackEmail = useAddDealRoomTrackEmail(id)
    const deleteDealRoomTrackEmail = useDeleteDealRoomTrackEmail(id)
    const updateDealRoom = useUpdateDealRoom()

    const [shareFlag, setShareFlag] = useImmer(
        dealroom.DealRoomProperties.public
    )

    const [download, setDownload] = useImmer(
        dealroom.DealRoomProperties.download
    )

    if (dealRoomTrack.isLoading) {
        return <Loading></Loading>
    }

    function isValidEmail(email) {
        let error: string | null = null
        const emailTest = /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email)
        if (!emailTest) {
            error = `${email} is not a valid email address.`
        }
        if (error) {
            setEmail((draft) => {
                draft.error = error
            })
            return false
        }
        return true
    }
    return (
        <div
            className={classNames('p-4 flex gap-3 flex-col', {
                'w-full': source === 'plugin',
                'w-80': source !== 'plugin',
            })}
        >
            <Typography variant="subtitle2">Share</Typography>

            <div className="flex justify-between items-center">
                <Typography>{'Allow Download'}</Typography>
                <Switch
                    size="small"
                    disabled={dealroom.action === 'VIEW'}
                    checked={download}
                    onChange={(e) => {
                        setDownload(e.target.checked)
                        updateDealRoom.mutate({
                            id: id,
                            data: {
                                download: e.target.checked,
                            },
                        })
                    }}
                ></Switch>
            </div>
            <div className="flex flex-row">
                <div className="flex flex-col flex-1">
                    <Typography>Anyone with link can view</Typography>
                    <Typography variant="body2">
                        Anyone on the internet with the link can view
                    </Typography>
                </div>
                <div>
                    <Switch
                        size="small"
                        disabled={dealroom.action === 'VIEW'}
                        checked={shareFlag}
                        onChange={(event) => {
                            updateDealRoom.mutate({
                                id: id,
                                data: {
                                    flag: event?.target.checked,
                                },
                            })
                            setShareFlag(event.target.checked)
                        }}
                    />
                </div>
            </div>
            {!shareFlag && (
                <>
                    <div>
                        <Alert severity="info">
                            <Typography variant="subtitle2">
                                Access is restricted
                            </Typography>
                            <Typography variant="caption">
                                Only added emails can view the link
                            </Typography>
                        </Alert>
                    </div>
                    <TextField
                        variant="outlined"
                        placeholder="Type email and hit enter"
                        onChange={(e) => {
                            setEmail((draft) => {
                                draft.email = e.target.value.trim()
                                draft.error = null
                            })
                        }}
                        value={email.email}
                        onKeyDown={(e) => {
                            if (['Enter', 'Tab'].includes(e.key)) {
                                e.preventDefault()

                                if (isValidEmail(email.email)) {
                                    setEmail((draft) => {
                                        return {
                                            email: '',
                                            error: null,
                                        }
                                    })
                                    addDealRoomTrackEmail.mutate({
                                        email: email.email,
                                    })
                                } else {
                                    setEmail((draft) => {
                                        draft.error = 'Enter a valid email'
                                    })
                                }
                            }
                        }}
                    ></TextField>
                    {email.error && (
                        <Typography color="error">{email.error}</Typography>
                    )}

                    {dealRoomTrack.data.length > 0 && (
                        <>
                            <Typography color={'text.disabled'}>
                                Add allowed visitors
                            </Typography>
                            {dealRoomTrack.data.map((track) => (
                                <div key={track.id} className="flex gap-3">
                                    <div className="flex-1 flex flex-row item-center">
                                        <div className="flex gap-3">
                                            <AvatarComponent
                                                name={
                                                    track
                                                        .DealRoomGuestEmailPermission
                                                        .email
                                                }
                                            ></AvatarComponent>
                                        </div>
                                        <div className="flex p-2">
                                            <Typography>
                                                {
                                                    track
                                                        .DealRoomGuestEmailPermission
                                                        .email
                                                }
                                            </Typography>
                                        </div>
                                    </div>
                                    <div>
                                        <Button
                                            onClick={() => {
                                                deleteDealRoomTrackEmail.mutate(
                                                    track.id
                                                )
                                            }}
                                        >
                                            remove
                                        </Button>
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                </>
            )}
            {source !== 'plugin' && (
                <Button
                    variant="contained"
                    className="w-full"
                    onClick={() => {
                        navigator.clipboard.writeText(
                            `https://${VIEWER_DOMAIN}/dealroom/${props.code}`
                        )
                        toast.success('link copied successfully')
                        props.setOpen(false)
                    }}
                >
                    Copy link
                </Button>
            )}
        </div>
    )
}
export default DealRoomShare
