import { APi_URL } from '@Common/Types/configs/urlConfig'
import { UpdateWorkspace } from '@Server/updateWorkspace'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
import { authManager } from 'src/AuthManager'
export default function useDeleteWorkspace(props: { onSuccess: () => void }) {
    const queryClient = useQueryClient()

    return useMutation<any, Error, UpdateWorkspace>(
        () => axios.delete(`${APi_URL}/workspace`),
        {
            onSuccess: () => {
                const initRaw = localStorage.removeItem(
                    `init-${authManager?._user?.sub}`
                )
                props.onSuccess()
                window.location.href = '/'
                queryClient.invalidateQueries(['init'])
            },
        }
    )
}
