import useFile from '@Common/Queries/MutationQueries/file'
import useUpdateAsset from '@Common/Queries/MutationQueries/UpdateAsset'
import useUploadFile from '@Common/Queries/MutationQueries/uploadFile'
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import CircularProgress from '@mui/material/CircularProgress'
import Snackbar from '@mui/material/Snackbar'
import { FC, useEffect } from 'react'
import { useImmer } from 'use-immer'
import { v4 as uuidv4 } from 'uuid'

export interface Asset {
    file: File
    fileDetails?: FileDetails
    status: AssetStatus
    assetDetails?: any
    error?: string
    id: string
    progress?: number
}
export interface FileDetails {
    fileName: string
    fileId: string
}
export enum AssetStatus {
    'initial',
    'uploading',
    'uploaded',
    'error',
    'completed',
}
const AssetVersionUpdate: FC<{
    assetExists: {
        id: string
        name: string
    }
    fileInputRef: any
    multiple: boolean
}> = ({ assetExists, fileInputRef, multiple }) => {
    const [assetsFiles, setAssetsFiles] = useImmer<
        {
            file: File
            status: AssetStatus
            id: string
        }[]
    >([])
    const handleFiles = (files: File[]) => {
        const filesArray = Array.from(files)
        const fileData = [...assetsFiles]
        fileData.splice(0, fileData.length)
        filesArray.forEach((file) => {
            fileData.push({
                file,
                status: AssetStatus.initial,
                id: uuidv4(),
            })
        })
        setAssetsFiles((draft) => {
            return fileData
        })
    }
    const sendDataToGTM = useGTMDispatch()
    const updateAsset = useUpdateAsset({
        updaterFunction: {},
        onSuccess: () => {
            sendDataToGTM({
                event: 'asset_version_update',
                asset_id: assetExists?.id,
                asset_name: assetExists?.name,
            })
        },
    })
    const [updateFile, setUpdateFile] = useImmer<{
        file: File
        status: AssetStatus
        id: string
    }>({})

    const onSaveAsset = async (data: any, val: any) => {
        const file = val.file
        const formData = new FormData()
        formData.append('file', file)
        formData.append('fields', data.data.file)
        const obj = {
            url: data?.data?.preSignedUrl,
            body: file,
            data: data.data.file,
            file: file,
            id: val.id,
        }

        if (data.data.preSignedUrl) {
            uploadFile(obj)
        }
    }

    const onUploadSuccess = (res: any, variables: any) => {
        setAssetsFiles((draft) => {
            const asset = draft.find((asset) => asset.id === variables.id)
            if (asset) {
                asset.status = AssetStatus.uploaded
                asset.fileDetails = variables.data.id
            }
        })

        setUpdateFile((draft) => {
            draft.status = AssetStatus.completed
        })
        const body = {
            fileId: variables?.data?.id,
        }
        updateAsset.mutate({ id: assetExists.id, body: body })
    }
    const onErrorAsset = (err: any, variables: any) => {
        console.log(JSON.stringify(err))
        setAssetsFiles((draft) => {
            const asset = draft.find((asset) => asset.id === variables.id)
            if (asset) {
                asset.status = AssetStatus.error
                asset.error =
                    err.response.status === 422
                        ? 'File Type not supported'
                        : 'Create asset not successful'
            }
        })
    }
    const option = (obj: any) => {
        return {
            onUploadProgress: (progressEvent: { loaded: any; total: any }) => {
                const { loaded, total } = progressEvent
                var percent = Math.floor((loaded * 100) / total)
                setAssetsFiles((draft) => {
                    const asset = draft.find((asset) => asset.id === obj.id)
                    if (asset) {
                        asset.progress = percent
                    }
                })
            },
            headers: { 'Content-Type': 'multipart/form-data' },
        }
    }
    const onUploadError = (err: any, variables: any) => {
        setAssetsFiles((draft) => {
            const asset = draft.find((asset) => asset.id === variables.id)
            if (asset) {
                asset.status = AssetStatus.error
                if (err.response.data.search('EntityTooLarge') >= 0) {
                    asset.error = 'File exceeded the 50MB size limit'
                    return
                }
                asset.error = 'Upload file not successful'
            }
        })
    }
    const {
        mutateAsync: saveFile,
        isSuccess: isSuccessSaveFile,
        isError: isErrorSaveFile,
        isLoading: isLoadingSaveFile,
        data: saveFileData,
    } = useFile({ onSuccess: onSaveAsset, onError: onErrorAsset })
    const {
        mutate: uploadFile,
        isSuccess: isSuccessUploadFile,
        isError: isErrorUploadFile,
        isLoading: isLoadingUploadFile,
        data: UpdateUploadFile,
    } = useUploadFile({
        onSuccess: onUploadSuccess,
        option: option,
        onError: onUploadError,
    })

    useEffect(() => {
        async function uploadFiles() {
            const unUploadedFiles = assetsFiles.filter(
                (assetFile) => assetFile.status === AssetStatus.initial
            )
            setAssetsFiles((draft) => {
                draft.forEach((assetFile) => {
                    if (assetFile.status === AssetStatus.initial) {
                        assetFile.status = AssetStatus.uploading
                    }
                })
            })
            const saveFiles = unUploadedFiles.map((assetFile) => {
                return saveFile(assetFile)
            })
            await Promise.all(saveFiles)
        }
        uploadFiles()
    }, [assetsFiles])

    const filesSelected = () => {
        if (fileInputRef.current.files.length) {
            handleFiles(fileInputRef.current.files)
        }
    }
    const action = (
        <CircularProgress
            variant="indeterminate"
            color="primary"
            className="stroke-primaryButton"
        />
    )
    return (
        <>
            <input
                id={'file-update'}
                type="file"
                className="hidden"
                ref={fileInputRef}
                multiple={multiple}
                onChange={filesSelected}
            />

            <Snackbar
                open={
                    assetsFiles.length != 0 &&
                    updateFile.status !== AssetStatus.completed
                }
                message={'Updating version'}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                action={action}
            />
            <Snackbar
                open={
                    updateAsset.isLoading != undefined &&
                    updateFile.status === AssetStatus.completed
                }
                message={'Successfully updated version'}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                autoHideDuration={2000}
            />
        </>
    )
}

export default AssetVersionUpdate
