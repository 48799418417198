import { APi_URL } from '@Common/Types/configs/urlConfig'
import axios from 'axios'
import { useMutation, useQueryClient } from 'react-query'
export interface CreateTrackLink {
    id: string
    enabled: boolean
    type: string
    trackLinkProperties: {
        name: string
        validTill: string | null
        allowDownload: boolean
        emailRequired: boolean
        passcodeRequired: boolean
        passcode: string
        emailAuthRequired: boolean
        allowEmail: string[]
        blockEmail: string[]
    }
}
export default function useAddTrackLink(props?: any) {
    const queryClient = useQueryClient()
    return useMutation<any, any, CreateTrackLink>(
        (obj: CreateTrackLink) =>
            axios.post(`${APi_URL}/trackLink`, obj, {
                headers: { 'Content-Type': 'application/json' },
            }),
        {
            onSuccess: async (data: any, obj) => {
                queryClient.invalidateQueries(['assets', 'trackLinks', obj.id])

                if (props && props.onSuccess) props.onSuccess(data, obj)
            },
        }
    )
}
