import { useQuery, useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'
import { Navigate } from 'react-router-dom'
import { deleteAssetFromSearch } from '@Search/utils/searchTableUpdater'

export default function useDeleteAsset(props?: any) {
    const queryClient = useQueryClient()
    return useMutation<any, Error, string>(
        (val: string) =>
            axios.delete(`${APi_URL}/assets/${val}`, {
                headers: { 'Content-Type': 'application/json' },
            }),
        {
            onSuccess: (data: any, obj: any) => {
                if (props?.onSuccess) {
                    props.onSuccess()
                }
            },
            onError: (data: any, obj: any) => {
                props.onError(data, obj)
            },
            onMutate: (data: any) => {
                queryClient.setQueriesData(['search'], (previous) => {
                    const prevData = Object.assign({}, previous.data)
                    const assets = Object.assign([], previous.data.assets)
                    const updatedSearch = deleteAssetFromSearch(assets, data)
                    return {
                        data: {
                            assets: updatedSearch,
                            count: prevData.count - 1,
                        },
                    }
                })
            },
        }
    )
}
