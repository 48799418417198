import { PropsWithChildren, useContext } from 'react'
import { FeatureContext } from './FeatureProvider'
interface FeatureProps {
    name: string
}
const Feature = ({ name, children }: PropsWithChildren<FeatureProps>) => {
    const flags = useContext(FeatureContext)
    if (!flags) {
        return null
    }
    const feature = flags[name]

    if (feature) {
        if (feature.enabled) {
            return <>{children}</>
        }
    }

    return <div className="mx-auto my-auto"></div>
}

export default Feature
