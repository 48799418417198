import { createContext, FC } from 'react'
import { Outlet } from 'react-router-dom'
import Loading from '../Loading'
import useFeatures from './useFeatures'
type Features = Record<string, { enabled: boolean }>
export const FeatureContext = createContext({} as Features)

const FeatureProvider: FC = ({ children }) => {
    const features = useFeatures()

    if (features.isLoading) {
        return <Loading />
    }

    return (
        <FeatureContext.Provider value={features.data}>
            <Outlet />
        </FeatureContext.Provider>
    )
}
export default FeatureProvider
