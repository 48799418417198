import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import { Fragment, useEffect } from 'react'
import { UseQueryResult } from 'react-query'
import { useAccounts } from 'src/Accounts/useAccounts'
import { useImmer } from 'use-immer'
interface UserDropDownProps {
    label: string
    value?: string | string[]
    autoFocus?: boolean
    fullWidth?: boolean
    multiple?: boolean
    defaultValue?: string
    size?: 'small' | 'medium'
    disabled?: boolean
    onChange?: (value: string | null) => void
}
export function getOptions(accounts: UseQueryResult<any, unknown>) {
    if (accounts.isLoading) {
        return []
    }
    const userTableMeta = accounts.data.userTable.UserTableMeta
    const primary = userTableMeta.find(
        (column) => column.columnName === 'Account name'
    )
    const options = accounts.data.rows.data.map((row) => ({
        value: row.id,
        label: row[primary.id],
    }))
    return options
}

export function Accounts(props: UserDropDownProps) {
    const [inputValue, setInputValue] = useImmer('')
    useEffect(() => {
        setInputValue('')
    }, [props.value])
    const accounts = useAccounts({ pageSize: 10, page: 0, search: inputValue })
    function getValue() {
        if (typeof props.value === 'string') {
            const option = getOptions(accounts).find(
                (item) => item.value === props.value
            )

            return option ? option : props.multiple ? [] : null
        }
        return props.value || (props.multiple ? [] : null)
    }
    return (
        <Autocomplete
            size={props.size || 'small'}
            loading={accounts.isLoading}
            value={getValue()}
            disabled={props.disabled === undefined ? false : props.disabled}
            multiple={props.multiple}
            fullWidth={props.fullWidth || true}
            isOptionEqualToValue={(option, value) =>
                option.value === value.value
            }
            defaultValue={
                getOptions(accounts).find(
                    (option) => option.value === props.defaultValue
                ) || null
            }
            options={getOptions(accounts)}
            onChange={(e, newValue) => {
                if (props.onChange) {
                    props.onChange(newValue)
                }
            }}
            onInputChange={(e, newInputValue) => {
                setInputValue(newInputValue)
            }}
            filterOptions={(x) => x}
            renderInput={(params) => (
                <TextField
                    {...params}
                    size={props.size || 'small'}
                    autoFocus={props.autoFocus}
                    variant="outlined"
                    label={props.label}
                    fullWidth={props.fullWidth || true}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <Fragment>
                                {accounts.isLoading || accounts.isFetching ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </Fragment>
                        ),
                    }}
                />
            )}
        />
    )
}
