export const mimeTypeMap = new Map([
    ['image/png', 'png'],

    ['image/jpeg', 'jpeg'],

    ['image/svg+xml', 'svg'],
    ['application/pdf', 'pdf'],
    ['application/rtf', 'rtf'],
    ['image/tiff', 'tiff'],
    ['application/vnd.ms-powerpoint', 'ppt'],
    [
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'pptx',
    ],
    ['application/msword', 'doc'],
    [
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'docx',
    ],
    ['text/csv', 'csv'],
    ['text/plain', 'txt'],

    ['application/vnd.ms-excel', 'xls'],
    [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'xlsx',
    ],
    ['video/x-msvideo', 'avi'],
    ['audio/mpeg', 'mpga'],
    ['video/mpeg', 'mpeg'],
    ['video/mp4', 'mp4'],
])
