import { Popper } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useRef } from 'react'

interface FilterDropDownProps {
    id: string
    column?: any
    target: any //How the drop down should like
    children: any //what should be available when u open the drop down
    anchorEl: any
    setAnchorEl: any
}
const StyledPopper = styled(Popper)(({ theme }) => ({
    boxShadow: `0 8px 24px rgba(0, 0, 0, 0.12)`,
    borderRadius: 4,
    width: 220,
    zIndex: theme.zIndex.modal,
    fontSize: 12,
    backgroundColor: '#FFFFFF',
}))

const FilterDropDowns = (props: FilterDropDownProps) => {
    const divRef = useRef()
    const { column, target, children, anchorEl, setAnchorEl, id } = props
    const handleClick = (ref, label) => {
        setAnchorEl((draft) => {
            draft[label] = {
                anchorEl: ref.current,
                isComponentVisible: true,
            }
        })
    }

    return (
        <>
            <div
                ref={divRef}
                id={id}
                onClick={(e) => {
                    handleClick(divRef, column.label)
                }}
                className="relative"
            >
                {target(() => handleClick(divRef, column.label))}
                {anchorEl[column.label] &&
                    anchorEl[column.label].anchorEl &&
                    anchorEl[column.label].anchorEl != null && (
                        <StyledPopper
                            open={anchorEl[column.label].isComponentVisible}
                            anchorEl={anchorEl[column.label].anchorEl}
                            placement="bottom-start"
                        >
                            {children}
                        </StyledPopper>
                    )}
            </div>
        </>
    )
}
export default FilterDropDowns
