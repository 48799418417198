import useOnClickOutside from '@Common/hooks/useOnClickOutside'
import { ReactNode, useState, useMemo } from 'react'
import { twMerge } from 'tailwind-merge'
import { useImmer } from 'use-immer'

interface EditableProps {
    value: ReactNode
    editor: ReactNode
    detail: any
    meta: any
    updateContact: any
    className?: string
}
export function Editable(props: EditableProps) {
    const [editing, setEditing] = useImmer(false)
    const { ref } = useOnClickOutside(false, () => {
        setEditing(false)
    })
    const [value, setValue] = useImmer(props.detail[props.meta.id])
    const Editor = props.editor
    const Value = props.value
    return (
        <div
            className={twMerge('w-full', props.className)}
            ref={ref}
            onClick={() => {
                if (editing === false) setEditing(true)
            }}
        >
            {editing ? (
                <Editor
                    detail={props.detail}
                    value={value}
                    meta={props.meta}
                    updateContact={props.updateContact}
                    setValue={setValue}
                />
            ) : (
                <Value detail={props.detail} />
            )}
        </div>
    )
}
