import { TabPanel } from '@Common/Components/TabPanel'
import {
    IconButton,
    Tab,
    Tabs,
    Tooltip,
} from '@mui/material'
import { useImmer } from 'use-immer'
import { DocumentVisitsCard } from './DocumentVisitsCard'
import { DealRoomVisitsCard } from './DealRoomVisitsCard'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

export const OutlookSidePanel = () => {
    const [tabs, setTabs] = useImmer(0)
    return (
        <div className="flex h-full w-full flex-col bg-gray-50">
            <div className="px-1">
                <Tooltip title="Take me to Portal">
                    <IconButton
                        sx={{
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        }}
                        onClick={() =>
                            window.open('https://portal.lifex.cloud/')
                        }
                    >
                        <OpenInNewIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>
            <Tabs
                value={tabs}
                onChange={(event, newValue) => {
                    setTabs(newValue)
                }}
                className="border-b"
            >
                <Tab value={0} label="Documents"></Tab>
                <Tab value={1} label="Digital room"></Tab>
            </Tabs>

            <TabPanel index={0} value={tabs} className="overflow-hidden">
                <DocumentVisitsCard></DocumentVisitsCard>
            </TabPanel>
            <TabPanel index={1} value={tabs} className="overflow-hidden">
                <DealRoomVisitsCard></DealRoomVisitsCard>
            </TabPanel>
        </div>
    )
}
