import { useMutation, useQuery } from 'react-query'
import axios from 'axios'
import { APi_URL } from '@Common/Types/configs/urlConfig'

export default function useWorkspaceDomains() {
    return useQuery<any, Error, string>(['workspace', 'domains'], async () => {
        const result = await axios.get(`${APi_URL}/workspace/domains`, {
            headers: { 'Content-Type': 'application/json' },
        })
        return result.data
    })
}
