import { ClickAwayListener, List, ListItem, ListItemText } from '@mui/material'
import Typography from '@mui/material/Typography'
import classNames from 'classnames'
import { FC } from 'react'
import { formattedDate } from '../util'

const AuditTrail: FC<{
    auditHistory: {
        action: string
        user: {
            name: string
        }
    }[]
}> = ({ auditHistory }) => {
    return (
        <div className=" w-80 max-h-96">
            <div
                className={classNames(
                    'w-full sticky top-0 bg-white z-10 h-12 shadow'
                )}
            >
                <Typography className="text-sm p-4" color="text.secondary">
                    Audit Trail
                </Typography>
            </div>
            <div className="w-full overflow-auto">
                <List className="w-full text-sm">
                    {auditHistory.map((history: any, i: number) => (
                        <ListItem
                            key={i}
                            secondaryAction={
                                <Typography
                                    className="text-sm"
                                    color="text.disabled"
                                >
                                    {history.action}
                                </Typography>
                            }
                        >
                            <ListItemText
                                primary={formattedDate(history.createdAt)}
                                secondary={history.user.name}
                            ></ListItemText>
                        </ListItem>
                    ))}
                </List>
            </div>
        </div>
    )
}

export default AuditTrail
